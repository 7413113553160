import { queryEye3 } from "api";

async function APIEye3BalanceHistoryProfile({ count, offset }) {
  try {
    const response = await queryEye3({
      method: "profile.balance_history",
      get: { count: count, offset: offset },
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3BalanceHistoryProfile;
