import React from "react";
import { ModalPage, ModalPageHeader, List, Avatar, SimpleCell } from "@vkontakte/vkui";

import { declOfNum } from "helpers";

const MutualFriend = (props) => {
  const modalParam = props.modalParam;
  const header = modalParam !== null ? modalParam.header : "";
  const mutualFriends = modalParam !== null ? modalParam.friends : [];
  const commonStrFormat = declOfNum(mutualFriends.length, ["общий", "общих", "общих"]);
  const friendStrFormat = declOfNum(mutualFriends.length, ["друг", "друга", "друзей"]);

  const renderMutualFriend = () => {
    return mutualFriends.map((friend, i) => {
      const name = friend.name;
      const photo = friend.photo_url;

      return (
        <SimpleCell key={i} disabled before={<Avatar size={40} src={photo} />}>
          {name}
        </SimpleCell>
      );
    });
  };

  return (
    <ModalPage
      id={props.id}
      header={
        <ModalPageHeader>
          {header !== "" ? (
            <>
              <span
                style={{
                  fontWeight: "500",
                  fontSize: "13px",
                  lineHeight: "16px",
                  letterSpacing: "0.2px",
                  textTransform: "uppercase",
                  color: "#818C99",
                }}
              >
                {header}
              </span>
            </>
          ) : (
            <span
              style={{
                fontWeight: "500",
                fontSize: "13px",
                lineHeight: "16px",
                letterSpacing: "0.2px",
                textTransform: "uppercase",
                color: "#818C99",
              }}
            >
              {mutualFriends.length + " " + commonStrFormat + " " + friendStrFormat}
            </span>
          )}
        </ModalPageHeader>
      }
      settlingHeight={80}
    >
      <List>{mutualFriends ? renderMutualFriend() : ""}</List>
    </ModalPage>
  );
};

export default MutualFriend;
