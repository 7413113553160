import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Panel } from "@vkontakte/vkui";
import AOtherUserList from "./components/AOtherUserList/AOtherUserList";
import { withRouter } from "hoc";

const PanelAOtherUser = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Активности</PanelHeaderWithPath>
      <AOtherUserList
        openView={props.openView}
        openModal={props.openModal}
        closeModal={props.closeModal}
      />
    </Panel>
  );
};

export default withRouter(PanelAOtherUser);
