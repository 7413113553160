import { queryEye3 } from "api";

async function APIEye3SetNotificationTrackActive({ userId, isNotification }) {
  try {
    const response = await queryEye3({
      method: "trackactive.set_notification",
      get: {
        user_id: userId,
        is_notification: isNotification,
      },
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3SetNotificationTrackActive;
