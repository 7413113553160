import {
  ACTIVITY__USER_REQUEST,
  ACTIVITY__USER_SUCCESS,
  ACTIVITY__USER_ERROR,
  ACTIVITY__USER_SHOW_MORE,
  ACTIVITY__USER_SEARCH_REQUEST,
  ACTIVITY__USER_SEARCH_SUCCESS,
  ACTIVITY__USER_SEARCH_ERROR,
  ACTIVITY__USER_FILTER_REQUEST,
  ACTIVITY__USER_FILTER_SUCCESS,
  ACTIVITY__USER_FILTER_ERROR,
  ACTIVITY__OTHER_USER_REQUEST,
  ACTIVITY__OTHER_USER_SUCCESS,
  ACTIVITY__OTHER_USER_ERROR,
  ACTIVITY__OTHER_USER_SHOW_MORE,
  ACTIVITY__OTHER_USER_SEARCH_REQUEST,
  ACTIVITY__OTHER_USER_SEARCH_SUCCESS,
  ACTIVITY__OTHER_USER_SEARCH_ERROR,
  ACTIVITY__OTHER_USER_FILTER_REQUEST,
  ACTIVITY__OTHER_USER_FILTER_SUCCESS,
  ACTIVITY__OTHER_USER_FILTER_ERROR,
  ACTIVITY__USER_INFO_REQUEST,
  ACTIVITY__USER_INFO_PROGRESS,
  ACTIVITY__USER_INFO_SUCCESS,
  ACTIVITY__USER_INFO_ERROR,
  ACTIVITY__USER_INFO_SET_PERIOD,
  ACTIVITY__USER_INFO_TABS,
  ACTIVITY__OTHER_USER_INFO_REQUEST,
  ACTIVITY__OTHER_USER_INFO_SUCCESS,
  ACTIVITY__OTHER_USER_INFO_PROGRESS,
  ACTIVITY__OTHER_USER_INFO_ERROR,
  ACTIVITY__OTHER_USER_INFO_SET_PERIOD,
  ACTIVITY__OTHER_USER_INFO_TABS,
  ACTIVITY__SET_OTHER_USER_PAGE,
  ACTIVITY__USER_INFO_FILTER,
  ACTIVITY__USER_INFO_SEARCH,
  ACTIVITY__OTHER_USER_ACTIVE_REQUEST,
  ACTIVITY__OTHER_USER_ACTIVE_SUCCESS,
  ACTIVITY__OTHER_USER_ACTIVE_PROGRESS,
  ACTIVITY__OTHER_USER_ACTIVE_ERROR,
  ACTIVITY__OTHER_USER_ACTIVE_SET_PERIOD,
  ACTIVITY__USER_INFO_CANCEL,
  ACTIVITY__OTHER_USER_INFO_CANCEL,
  ACTIVITY__OTHER_USER_ACTIVE_CANCEL,
  ACTIVITY__OTHER_USER_INFO_FILTER,
  ACTIVITY__OTHER_USER_INFO_SEARCH,
} from "../../constType";

import { delay } from "helpers";

import { calcAge } from "helpers";

import { getPeriod } from "template/popouts/DateChangePeriod/actions";

import { APIEye3ActiveAllPageTrackActive, APIEye3ActiveInPageTrackActive } from "api/eye3";
import { getSearchFilterFriendsV2 } from "helpers/actions";
import { APIVkGetByIdGroups, APIVkGetUsers } from "api/vk/apiMethods";

import { getFilterFriends, getSearchFriends } from "helpers/actions";
import { openModal } from "../page";
import { checkEYE3ApiError, defaultErrorProperty } from "api/eye3/responseHandler";

export function fetchUserActivity() {
  return async (dispatch, getState) => {
    try {
      dispatch(request());
      const state = getState().toolActivity;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.users.search,
        filter: state.users.filter,
        cache: state.users.cachePayload,
      });

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: ACTIVITY__USER_REQUEST };
  }

  function success(payload) {
    return { type: ACTIVITY__USER_SUCCESS, payload };
  }

  function error(error) {
    return { type: ACTIVITY__USER_ERROR, error };
  }
}

export function showMoreUserActivity() {
  return { type: ACTIVITY__USER_SHOW_MORE };
}

export function filterUserActivity(filter = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(request(filter));
      const state = getState().toolActivity;
      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.users.search,
        filter: state.users.filter,
        cache: state.users.cachePayload,
      });

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(filter) {
    return { type: ACTIVITY__USER_FILTER_REQUEST, filter };
  }

  function success(payload) {
    return { type: ACTIVITY__USER_FILTER_SUCCESS, payload };
  }

  function error(error) {
    return { type: ACTIVITY__USER_FILTER_ERROR, error };
  }
}

export function searchUserActivity(search = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(request(search));
      const state = getState().toolActivity;
      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.users.search,
        filter: state.users.filter,
        cache: state.users.cachePayload,
      });

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(search) {
    return { type: ACTIVITY__USER_SEARCH_REQUEST, search };
  }

  function success(payload) {
    return { type: ACTIVITY__USER_SEARCH_SUCCESS, payload };
  }

  function error(error) {
    return { type: ACTIVITY__USER_SEARCH_ERROR, error };
  }
}

export function fetchOtherUserActivity() {
  return async (dispatch, getState) => {
    try {
      dispatch(request());
      const state = getState().toolActivity;
      const otherUsers = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.otherUsers.search,
        filter: state.otherUsers.filter,
        cache: state.otherUsers.cachePayload,
      });

      dispatch(success(otherUsers));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: ACTIVITY__OTHER_USER_REQUEST };
  }

  function success(payload) {
    return { type: ACTIVITY__OTHER_USER_SUCCESS, payload };
  }

  function error(error) {
    return { type: ACTIVITY__OTHER_USER_ERROR, error };
  }
}

export function showMoreOtherUserActivity() {
  return { type: ACTIVITY__OTHER_USER_SHOW_MORE };
}

export function filterOtherUserActivity(filter = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(request(filter));
      const state = getState().toolActivity;
      const otherUsers = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.otherUsers.search,
        filter: state.otherUsers.filter,
        cache: state.otherUsers.cachePayload,
      });

      dispatch(success(otherUsers));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(filter) {
    return { type: ACTIVITY__OTHER_USER_FILTER_REQUEST, filter };
  }

  function success(payload) {
    return { type: ACTIVITY__OTHER_USER_FILTER_SUCCESS, payload };
  }

  function error(error) {
    return { type: ACTIVITY__OTHER_USER_FILTER_ERROR, error };
  }
}

export function searchOtherUserActivity(search = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(request(search));
      const state = getState().toolActivity;
      const otherUsers = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.otherUsers.search,
        filter: state.otherUsers.filter,
        cache: state.otherUsers.cachePayload,
      });

      dispatch(success(otherUsers));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(search) {
    return { type: ACTIVITY__OTHER_USER_SEARCH_REQUEST, search };
  }

  function success(payload) {
    return { type: ACTIVITY__OTHER_USER_SEARCH_SUCCESS, payload };
  }

  function error(error) {
    return { type: ACTIVITY__OTHER_USER_SEARCH_ERROR, error };
  }
}

export function setDataOtherUserPage() {
  return (dispatch, getState) => {
    const state = getState().toolActivity;
    const pageParam = getState().page.param;

    const id = pageParam ? pageParam.activeUserId : state.otherUserPage.activeUserId;
    const title = pageParam ? pageParam.activeUserTitle : state.otherUserPage.activeUserTitle;
    const description = pageParam
      ? pageParam.activeUserDescription
      : state.otherUserPage.activeUserDescription;
    const photo = pageParam ? pageParam.activeUserPhoto : state.otherUserPage.activeUserPhoto;
    const sex = pageParam ? pageParam.activeUserSex : state.otherUserPage.activeUserSex;

    dispatch(exec(id, title, description, photo, sex));
  };

  function exec(id, title, description, photo, sex) {
    return { type: ACTIVITY__SET_OTHER_USER_PAGE, id, title, description, photo, sex };
  }
}

export function fetchUserInfo() {
  return async (dispatch, getState) => {
    try {
      const state = getState().toolActivity;
      const pageParam = getState().page.param;

      if (getState().toolActivity.userInfo.loading) return;

      const id = pageParam ? pageParam.activeUserId : state.userInfo.activeUserId;
      const title = pageParam ? pageParam.activeUserTitle : state.userInfo.activeUserTitle;
      const description = pageParam
        ? pageParam.activeUserDescription
        : state.userInfo.activeUserDescription;
      const photo = pageParam ? pageParam.activeUserPhoto : state.userInfo.activeUserPhoto;
      const sex = pageParam ? pageParam.activeUserSex : state.userInfo.activeUserSex;

      const period = state.userInfo.period;
      const tabs = state.userInfo.tabs;

      /*console.log('tabs: ', tabs);
            console.log('period: ', period);*/

      dispatch(request(id, title, description, photo, sex));

      const { atMin, atMax } = getPeriod(period);

      async function process() {
        try {
          if (!getState().toolActivity.userInfo.loading) return;

          const activity = await APIEye3ActiveAllPageTrackActive({
            userId: id,
            activeType: tabs,
            timeMin: atMin,
            timeMax: atMax,
          });

          let result = [];

          // Проверка ошибок в API
          const errorAPI = checkEYE3ApiError(activity);
          if (errorAPI !== false) {
            errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
            dispatch(error({ ...errorAPI }));
            return;
          }

          //console.log('activity: ', activity);

          let activityList = [];
          let joinUserIds = "";
          let joinGroupIds = "";

          activity.result.forEach((a) => {
            let isGroupId = false; // Если id относится к группе

            if (a.object_owner_id > 0) {
              joinUserIds += a.object_owner_id + ",";
            } else {
              joinGroupIds += Math.abs(a.object_owner_id) + ",";
              isGroupId = true;
            }

            activityList.push({
              vk_id: a.vk_id,
              sex: sex,
              type: a.type,
              _at: a._at,
              info_link: a.info_link,
              info_photo: a.info_photo,
              info_text: a.info_text,
              owner_id: a.object_owner_id,
              is_group_owner_id: isGroupId,
            });
          });

          let usersItems = [];
          let groupsItems = [];

          if (joinUserIds !== "") {
            const userInfo = await APIVkGetUsers({
              userIds: joinUserIds,
              fields: ["photo_100", "bdate", "city", "country", "sex"].join(","),
            });

            usersItems = userInfo.response.hasOwnProperty("items")
              ? userInfo.response.items
              : userInfo.response;
          }

          //console.log('joinGroupIds: ', joinGroupIds);

          if (joinGroupIds !== "") {
            const groupInfo = await APIVkGetByIdGroups({
              groupIds: joinGroupIds,
            });

            groupsItems = groupInfo.response;
          }

          for (let activityItem of activityList) {
            if (activityItem.is_group_owner_id) {
              // Если id группы
              for (let group of groupsItems) {
                if (group.id === Math.abs(activityItem.owner_id)) {
                  result.push({
                    ...activityItem,
                    owner_name: group.name,
                    owner_age: null,
                    owner_sex: null,
                    owner_city: null,
                    owner_city_id: null,
                    owner_photo_url: group.photo_100 ? group.photo_100 : "",
                    owner_country: null,
                    owner_country_id: null,
                  });

                  break;
                }
              }
            } else {
              for (let user of usersItems) {
                if (user.id === activityItem.owner_id) {
                  result.push({
                    ...activityItem,
                    owner_name: user.first_name + " " + user.last_name,
                    owner_age: user.bdate ? calcAge(user.bdate) : null, // Расчет возраста по дате рождения
                    owner_sex: user.sex ? user.sex.toString() : null,
                    owner_city: user.city ? user.city.title : null, // Город
                    owner_city_id: user.city ? user.city.id.toString() : null, // Город id
                    owner_photo_url: user.photo_100 ? user.photo_100 : "", // Фото
                    owner_country: user.country ? user.country.title : null, // Страна
                    owner_country_id: user.country ? user.country.id.toString() : null, // Страна id
                  });

                  break;
                }
              }
            }
          }

          //console.log('result: ', result);

          result = getFilterFriends({
            data: result,
            filter: getState().toolActivity.userInfo.filter,
            template: [
              {
                first: "owner_sex",
                compare: "===",
                two: "sex",
              },
              {
                first: "owner_age",
                compare: ">=",
                two: "age_from",
              },
              {
                first: "owner_age",
                compare: "<=",
                two: "age_to",
              },
              {
                first: "owner_country_id",
                compare: "===",
                two: "country_id",
              },
              {
                first: "owner_city_id",
                compare: "===",
                two: "city_id",
              },
            ],
          });

          result = getSearchFriends({
            data: result,
            search: getState().toolActivity.userInfo.search,
            isSearchVkId: false,
            field: "owner_name",
          });

          if (activity.status_task === "work") {
            dispatch(progress(activity.percent, result));
            await delay(1000);
            await process();
          } else if (activity.status_task === "success") {
            dispatch(success(result));
          } else if (activity.status_task === "error") {
            dispatch(error({ ...defaultErrorProperty, message: "" }));
          }

          return;
        } catch (e) {
          dispatch(error({ ...defaultErrorProperty, message: e }));
        }
      }

      await process();
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(id, title, description, photo, sex) {
    return { type: ACTIVITY__USER_INFO_REQUEST, id, title, description, photo, sex };
  }

  function progress(progressValue, payload) {
    return { type: ACTIVITY__USER_INFO_PROGRESS, progressValue, payload };
  }

  function success(payload) {
    return { type: ACTIVITY__USER_INFO_SUCCESS, payload };
  }

  function error(error) {
    return { type: ACTIVITY__USER_INFO_ERROR, error };
  }
}

export function cancelUserInfo() {
  return { type: ACTIVITY__USER_INFO_CANCEL };
}

export function filterUserInfo(filter = "") {
  return async (dispatch, getState) => {
    await dispatch(exec(filter));

    if (!getState().toolActivity.userInfo.loading) await dispatch(fetchUserInfo());
  };

  function exec(filter) {
    return { type: ACTIVITY__USER_INFO_FILTER, filter };
  }
}

export function searchUserInfo(search = "") {
  return async (dispatch, getState) => {
    await dispatch(exec(search));

    if (!getState().toolActivity.userInfo.loading) await dispatch(fetchUserInfo());
  };

  function exec(search) {
    return { type: ACTIVITY__USER_INFO_SEARCH, search };
  }
}

export function setPeriodUserInfo(name, atMin = null, atMax = null) {
  return { type: ACTIVITY__USER_INFO_SET_PERIOD, name, atMin, atMax };
}

export function tabsUserInfo(tabs) {
  return { type: ACTIVITY__USER_INFO_TABS, tabs };
}

// otherUserInfo

export function fetchOtherUserInfo() {
  return async (dispatch, getState) => {
    try {
      const state = getState().toolActivity;
      const pageParam = getState().page.param;

      if (state.otherUserInfo.loading) return;

      const id = pageParam ? pageParam.activeUserId : state.otherUserPage.activeUserId;
      const sex = pageParam ? pageParam.activeUserSex : state.otherUserPage.activeUserSex;

      const period = state.otherUserInfo.period;
      const tabs = state.otherUserInfo.tabs;

      dispatch(request());

      const { atMin, atMax } = getPeriod(period);

      async function process() {
        try {
          if (!getState().toolActivity.otherUserInfo.loading) return;

          let result = [];

          const activity = await APIEye3ActiveInPageTrackActive({
            userId: id,
            resultType: "active",
            activeType: tabs,
            timeMin: atMin,
            timeMax: atMax,
          });

          // Проверка ошибок в API
          const errorAPI = checkEYE3ApiError(activity);
          if (errorAPI !== false) {
            errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
            dispatch(error({ ...errorAPI }));
            return;
          }

          //console.log('activity: ', activity);

          let activityList = [];
          let joinUserIds = "";

          activity.result.forEach((a) => {
            joinUserIds += a.vk_id + ",";

            activityList.push({
              vk_id: a.vk_id,
              sex: sex,
              type: a.type,
              _at: a._at,
              info_link: a.info_link,
              info_photo: a.info_photo,
              info_text: a.info_text,
              owner_id: a.object_owner_id,
            });
          });

          const userInfo = await APIVkGetUsers({
            userIds: joinUserIds,
            fields: ["photo_100", "bdate", "city", "country", "sex"].join(","),
          });
          let usersItems = userInfo.response.hasOwnProperty("items")
            ? userInfo.response.items
            : userInfo.response;

          for (let activityUser of activityList) {
            for (let user of usersItems) {
              if (user.id === activityUser.vk_id) {
                result.push({
                  ...activityUser,
                  name: user.first_name + " " + user.last_name,
                  age: user.bdate ? calcAge(user.bdate) : null, // Расчет возраста по дате рождения
                  sex: user.sex ? user.sex.toString() : null,
                  city: user.city ? user.city.title : null, // Город
                  city_id: user.city ? user.city.id.toString() : null, // Город id
                  photo_url: user.photo_100 ? user.photo_100 : "", // Фото
                  country: user.country ? user.country.title : null, // Страна
                  country_id: user.country ? user.country.id.toString() : null, // Страна id
                });

                break;
              }
            }
          }

          //console.log('result: ', result);

          result = getFilterFriends({
            data: result,
            filter: getState().toolActivity.otherUserInfo.filter,
          });

          result = getSearchFriends({
            data: result,
            search: getState().toolActivity.otherUserInfo.search,
            isSearchVkId: false,
          });

          if (activity.status_task === "work") {
            dispatch(progress(activity.percent, result));
            await delay(1000);
            await process();
          } else if (activity.status_task === "success") {
            dispatch(success(result));
          } else if (activity.status_task === "error") {
            dispatch(error({ ...defaultErrorProperty, message: "" }));
          }

          return;
        } catch (e) {
          dispatch(error({ ...defaultErrorProperty, message: e }));
        }
      }

      await process();
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: ACTIVITY__OTHER_USER_INFO_REQUEST };
  }

  function progress(progressValue, payload) {
    return { type: ACTIVITY__OTHER_USER_INFO_PROGRESS, progressValue, payload };
  }

  function success(payload) {
    return { type: ACTIVITY__OTHER_USER_INFO_SUCCESS, payload };
  }

  function error(error) {
    return { type: ACTIVITY__OTHER_USER_INFO_ERROR, error };
  }
}

export function cancelOtherUserInfo() {
  return { type: ACTIVITY__OTHER_USER_INFO_CANCEL };
}

export function filterOtherUserInfo(filter = "") {
  return async (dispatch, getState) => {
    await dispatch(exec(filter));

    if (!getState().toolActivity.otherUserInfo.loading) await dispatch(fetchOtherUserInfo());
  };

  function exec(filter) {
    return { type: ACTIVITY__OTHER_USER_INFO_FILTER, filter };
  }
}

export function searchOtherUserInfo(search = "") {
  return async (dispatch, getState) => {
    await dispatch(exec(search));

    if (!getState().toolActivity.otherUserInfo.loading) await dispatch(fetchOtherUserInfo());
  };

  function exec(search) {
    return { type: ACTIVITY__OTHER_USER_INFO_SEARCH, search };
  }
}

export function setPeriodOtherUserInfo(name, atMin = null, atMax = null) {
  return { type: ACTIVITY__OTHER_USER_INFO_SET_PERIOD, name, atMin, atMax };
}

export function tabsOtherUserInfo(tabs) {
  return { type: ACTIVITY__OTHER_USER_INFO_TABS, tabs };
}

// otherUserActive

export function fetchOtherUserActive() {
  return async (dispatch, getState) => {
    try {
      const state = getState().toolActivity;
      const pageParam = getState().page.param;

      if (state.otherUserInfo.loading) return;

      const id = pageParam ? pageParam.activeUserId : state.otherUserPage.activeUserId;
      const period = state.otherUserActive.period;

      dispatch(request());

      const { atMin, atMax } = getPeriod(period);

      async function process() {
        if (!getState().toolActivity.otherUserActive.loading) return;

        try {
          const activity = await APIEye3ActiveInPageTrackActive({
            userId: id,
            resultType: "users",
            timeMin: atMin,
            timeMax: atMax,
          });

          //console.log('activity: ', activity);

          let result = [];

          // Проверка ошибок в API
          const errorAPI = checkEYE3ApiError(activity);
          if (errorAPI !== false) {
            errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
            dispatch(error({ ...errorAPI }));
            return;
          }

          let activityList = [];
          let joinUserIds = "";

          activity.result.forEach((a) => {
            joinUserIds += a.vk_id + ",";

            activityList.push({
              vk_id: a.vk_id,
              count: a.count,
            });
          });

          const userInfo = await APIVkGetUsers({
            userIds: joinUserIds,
            fields: ["photo_100"].join(","),
          });
          let usersItems = userInfo.response.hasOwnProperty("items")
            ? userInfo.response.items
            : userInfo.response;

          for (let activityUser of activityList) {
            for (let user of usersItems) {
              if (user.id === activityUser.vk_id) {
                result.push({
                  ...activityUser,
                  name: user.first_name + " " + user.last_name,
                  photo_url: user.photo_100 ? user.photo_100 : "", // Фото
                });

                break;
              }
            }
          }

          //console.log('result: ', result);

          if (activity.status_task === "work") {
            dispatch(progress(activity.percent, result));
            await delay(1000);
            await process();
          } else if (activity.status_task === "success") {
            dispatch(success(result));
          } else if (activity.status_task === "error") {
            dispatch(error({ ...defaultErrorProperty, message: "" }));
          }

          return;
        } catch (e) {
          dispatch(error({ ...defaultErrorProperty, message: e }));
        }
      }

      await process();
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: ACTIVITY__OTHER_USER_ACTIVE_REQUEST };
  }

  function progress(progressValue, payload) {
    return { type: ACTIVITY__OTHER_USER_ACTIVE_PROGRESS, progressValue, payload };
  }

  function success(payload) {
    return { type: ACTIVITY__OTHER_USER_ACTIVE_SUCCESS, payload };
  }

  function error(error) {
    return { type: ACTIVITY__OTHER_USER_ACTIVE_ERROR, error };
  }
}

export function cancelOtherUserActive() {
  return { type: ACTIVITY__OTHER_USER_ACTIVE_CANCEL };
}

export function setPeriodOtherUserActive(name, atMin = null, atMax = null) {
  return { type: ACTIVITY__OTHER_USER_ACTIVE_SET_PERIOD, name, atMin, atMax };
}
