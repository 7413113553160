import { queryEye3 } from "api";

async function APIEye3PostSearchTool({
  userId,
  timeMin = null,
  timeMax = null,
  count = null,
  offset = null,
}) {
  try {
    const response = await queryEye3({
      method: "tool.post_search",
      get: { user_id: userId, time_min: timeMin, time_max: timeMax, count: count, offset: offset },
      access: { isBotConnect: true, isLicense: true, isLimit: true },
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3PostSearchTool;
