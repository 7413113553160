import { VkBridgeSend } from "api/vk";
import { STORY_EDUCATION, STORY_GUEST } from "stories/constStories";
import { PANEL_STEP1, PANEL_STEP6, PANEL_STEP7 } from "views/education/panels/constPanels";
import { MODAL_CARD_VISITED } from "views/guest/modals/constModals";
import { PANEL_BOT_CONNECT } from "views/guest/panels/constPanels";

import {
  PAGE_OPEN_STORY,
  PAGE_OPEN_VIEW,
  PAGE_GO_BACK,
  PAGE_OPEN_MODAL,
  PAGE_CLOSE_MODAL,
  PAGE_GO_BACK_MODAL,
  PAGE_OPEN_POPOUT,
  PAGE_CLOSE_POPOUT,
  PAGE_GO_BACK_POPOUT,
  PAGE_ON_TRANSITION,
  PAGE_CLEAR_HISTORY,
} from "../constType";

export function openStory({ story = null, panel = null, param = null }) {
  return (dispatch, getState) => {
    if (story === null) {
      console.error("openStory: Not Found story");
      return;
    } else if (param !== null && typeof param !== "object") {
      console.error("openStory: Parameters must be an object");
      return;
    }

    story = story.toString();

    if (panel !== null) {
      panel = panel.toString();
    }

    const state = getState().page;

    window.history.pushState(null, null);

    let viewsHistory = state.viewsHistory[story] || [story];

    let storiesHistory = state.storiesHistory;

    let activeView = viewsHistory[viewsHistory.length - 1];
    let panelsHistory =
      panel !== null
        ? [panel]
        : panel === null && state.panelsHistory.hasOwnProperty(activeView)
        ? state.panelsHistory[activeView]
        : ["main"];

    let activePanel = panelsHistory[panelsHistory.length - 1];

    if (story === state.activeStory) {
      if (panelsHistory.length > 1) {
        let firstPanel = panelsHistory.shift();
        panelsHistory = [firstPanel];

        activePanel = panelsHistory[panelsHistory.length - 1];
      } else if (viewsHistory.length > 1) {
        let firstView = viewsHistory.shift();
        viewsHistory = [firstView];

        activeView = viewsHistory[viewsHistory.length - 1];
        panelsHistory = state.panelsHistory[activeView];
        activePanel = panelsHistory[panelsHistory.length - 1];
      }
    }

    const storiesIndexInHistory = storiesHistory.indexOf(story);

    if (
      storiesIndexInHistory === -1 ||
      (storiesHistory[0] === story && storiesHistory[storiesHistory.length - 1] !== story)
    ) {
      storiesHistory = [...storiesHistory, story];
    }

    // ------- param (сохранение параметров между панелями)
    let setParam =
      param === null &&
      state.paramHistory.hasOwnProperty(story + "/" + activeView + "/" + activePanel)
        ? state.paramHistory[story + "/" + activeView + "/" + activePanel]
        : param;
    let paramHistory = state.paramHistory[setParam] || null;
    // -------

    dispatch(
      exec(
        story,
        activeView,
        activePanel,
        panelsHistory,
        viewsHistory,
        storiesHistory,
        setParam,
        paramHistory
      )
    );
  };

  function exec(
    activeStory,
    activeView,
    activePanel,
    panelsHistory,
    viewsHistory,
    storiesHistory,
    setParam,
    paramHistory
  ) {
    return {
      type: PAGE_OPEN_STORY,
      activeStory,
      activeView,
      activePanel,
      panelsHistory,
      viewsHistory,
      storiesHistory,
      setParam,
      paramHistory,
    };
  }
}

export function openView({ view = null, panel = null, param = null }) {
  return (dispatch, getState) => {
    if (view === null) {
      console.error("openView: Not Found view");
      return;
    } else if (panel === null) {
      console.error("openView: Not Found panel");
      return;
    } else if (param !== null && typeof param !== "object") {
      console.error("openView: Parameters must be an object");
      return;
    }

    const state = getState().page;

    let View = view.toString();
    let Panel = panel.toString();
    // ------- param (сохранение параметров между панелями)
    let Param = param;
    // -------

    window.history.pushState(null, null);

    let panelsHistory = state.panelsHistory[View] || [];
    let viewsHistory = state.viewsHistory[state.activeStory] || [];
    // ------- param (сохранение параметров между панелями)
    let paramHistory = state.paramHistory[Param] || null;
    // -------

    const viewIndexInHistory = viewsHistory.indexOf(View);

    if (viewIndexInHistory !== -1) {
      viewsHistory.splice(viewIndexInHistory, 1);
    }

    let indexPanel = panelsHistory.indexOf(Panel);

    // Если панель не найдена добавить в историю
    if (panelsHistory.indexOf(Panel) === -1) {
      panelsHistory = [...panelsHistory, Panel];
    }
    // Если панель найдена удалить историю панелей после неё
    else if (indexPanel !== -1 && panelsHistory.length > 1 && Panel === panelsHistory[indexPanel]) {
      panelsHistory.splice(indexPanel + 1);
    }

    // Включить свайпы если панелей в истории больше чем 1
    if (panelsHistory.length > 1) {
      // VkBridgeSend("VKWebAppEnableSwipeBack");
    }

    /*console.log('View: ', View);
		console.log('Panel: ', Panel);
		console.log('ViewsHistory: ', viewsHistory);
		console.log('PanelsHistory: ', panelsHistory);*/

    dispatch(exec(View, Panel, viewsHistory, panelsHistory, param, paramHistory));
  };

  function exec(View, Panel, viewsHistory, panelsHistory, param, paramHistory) {
    return {
      type: PAGE_OPEN_VIEW,
      View,
      Panel,
      viewsHistory,
      panelsHistory,
      param,
      paramHistory,
    };
  }
}

// Условия закрытия приложения по goBack
function ifGoBackCloseApp(state) {
  // Если открылась страница с подключением бота или если открылась модалка с подключением тарифа гостей или при открытии окна обучения
  if (
    (state.activeStory === STORY_GUEST && state.activePanel === PANEL_BOT_CONNECT) ||
    (state.activeStory === STORY_EDUCATION && state.activePanel === PANEL_STEP1) ||
    (state.activeStory === STORY_EDUCATION && state.activePanel === PANEL_STEP6) ||
    (state.activeStory === STORY_EDUCATION && state.activePanel === PANEL_STEP7) ||
    (state.activeStory === STORY_GUEST &&
      state.activeModal[state.activeView] === MODAL_CARD_VISITED)
  ) {
    VkBridgeSend("VKWebAppClose", {
      status: "success",
    });

    return true;
  }
}

export function goBack() {
  return (dispatch, getState) => {
    const state = getState().page;

    if (ifGoBackCloseApp(state)) {
      return;
    }

    let setView = state.activeView;
    let setPanel = state.activePanel;
    let setStory = state.activeStory;

    // popout

    let popoutsData = state.popouts;

    if (popoutsData[setView]) {
      popoutsData[setView] = null;
      return dispatch(goBackPopout(popoutsData));
    }

    // modal

    let viewModalHistory = state.modalHistory[setView];

    if (viewModalHistory !== undefined && viewModalHistory.length !== 0) {
      let activeModal = viewModalHistory[viewModalHistory.length - 2] || null;

      if (activeModal === null) {
        viewModalHistory = [];
      } else if (viewModalHistory.indexOf(activeModal) !== -1) {
        viewModalHistory = viewModalHistory.splice(0, viewModalHistory.indexOf(activeModal) + 1);
      } else {
        viewModalHistory.push(activeModal);
      }

      return dispatch(goBackModal(setView, activeModal, viewModalHistory));
    }

    // panel

    let panelsHistory = state.panelsHistory[setView] || [];
    let viewsHistory = state.viewsHistory[state.activeStory] || [];
    let storiesHistory = state.storiesHistory;

    if (panelsHistory.length > 1) {
      panelsHistory.pop();

      setPanel = panelsHistory[panelsHistory.length - 1];
    } else if (viewsHistory.length > 1) {
      viewsHistory.pop();

      setView = viewsHistory[viewsHistory.length - 1];
      let panelsHistoryNew = state.panelsHistory[setView];

      setPanel = panelsHistoryNew[panelsHistoryNew.length - 1];
    } else if (storiesHistory.length > 1) {
      storiesHistory.pop();

      setStory = storiesHistory[storiesHistory.length - 1];
      setView = state.viewsHistory[setStory][state.viewsHistory[setStory].length - 1];

      let panelsHistoryNew = state.panelsHistory[setView];

      if (panelsHistoryNew.length > 1) {
        setPanel = panelsHistoryNew[panelsHistoryNew.length - 1];
      } else {
        setPanel = panelsHistoryNew[0];
      }
    } else {
      VkBridgeSend("VKWebAppClose");
    }

    // ------- param
    let setParam = state.paramHistory[setStory + "/" + setView + "/" + setPanel] || null;
    let paramHistory = state.paramHistory[setParam] || null;
    // -------

    // Выключить свайпы если панелей в истории меньше чем 1
    if (panelsHistory.length <= 1) {
      // VkBridgeSend("VKWebAppDisableSwipeBack");
    }

    return dispatch(
      goBack(
        setView,
        setPanel,
        setStory,
        setParam,
        panelsHistory,
        viewsHistory,
        storiesHistory,
        paramHistory
      )
    );
  };

  function goBack(
    setView,
    setPanel,
    setStory,
    setParam,
    panelsHistory,
    viewsHistory,
    storiesHistory,
    paramHistory
  ) {
    return {
      type: PAGE_GO_BACK,
      setView,
      setPanel,
      setStory,
      setParam,
      panelsHistory,
      viewsHistory,
      storiesHistory,
      paramHistory,
    };
  }

  function goBackModal(setView, activeModal, modalHistory) {
    return { type: PAGE_GO_BACK_MODAL, setView, activeModal, modalHistory };
  }

  function goBackPopout(popouts) {
    return { type: PAGE_GO_BACK_POPOUT, popouts };
  }
}

export function openModal({ id = null, param = null }) {
  return (dispatch, getState) => {
    if (id === null) {
      console.error("openModal: Not Found id");
      return;
    }

    const state = getState().page;

    window.history.pushState(null, null);

    let activeModal = id.toString() || null;
    let modalHistory = state.modalHistory[state.activeView]
      ? [...state.modalHistory[state.activeView]]
      : [];

    if (activeModal === null) {
      modalHistory = [];
    } else if (modalHistory.indexOf(activeModal) !== -1) {
      modalHistory = modalHistory.splice(0, modalHistory.indexOf(activeModal) + 1);
    } else {
      modalHistory.push(activeModal);
    }

    dispatch(exec(activeModal, modalHistory, param));
  };

  function exec(activeModal, modalHistory, modalParam) {
    return { type: PAGE_OPEN_MODAL, activeModal, modalHistory, modalParam };
  }
}

export function closeModal() {
  return (dispatch, getState) => {
    const state = getState().page;

    if (state.modalHistory.length === 0) {
      console.error("closeModal: Cannot close non-existent modal window");
      return;
    }

    let activeModal =
      state.modalHistory[state.activeView][state.modalHistory[state.activeView].length - 2] || null;
    let modalHistory = state.modalHistory[state.activeView]
      ? [...state.modalHistory[state.activeView]]
      : [];

    if (activeModal === null) {
      modalHistory = [];
    } else if (modalHistory.indexOf(activeModal) !== -1) {
      modalHistory = modalHistory.splice(0, modalHistory.indexOf(activeModal) + 1);
    } else {
      modalHistory.push(activeModal);
    }

    dispatch(exec(activeModal, modalHistory));
  };

  function exec(activeModal, modalHistory) {
    return { type: PAGE_CLOSE_MODAL, activeModal, modalHistory };
  }
}

export function openPopout({ popout = null }) {
  return (dispatch) => {
    if (popout === null) {
      console.error("popout: Not Found popout");
      return;
    }

    window.history.pushState(null, null);

    dispatch(exec(popout));
  };

  function exec(popout) {
    return { type: PAGE_OPEN_POPOUT, popout };
  }
}

export function closePopout() {
  return { type: PAGE_CLOSE_POPOUT };
}

export function onTransitionPage() {
  return { type: PAGE_ON_TRANSITION };
}

export function clearHistory() {
  return { type: PAGE_CLEAR_HISTORY };
}
