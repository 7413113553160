import React from "react";

import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import FIAUserList from "./components/FIAUserList/FIAUserList";
import { Cell, Group, Panel } from "@vkontakte/vkui";
import Icon16Chevron from "@vkontakte/icons/dist/16/chevron";
import { VIEW_TOOL } from "../../../constViews";
import Icon28ServicesOutline from "@vkontakte/icons/dist/28/services_outline";
import FIAIcon from "assets/img/tool/friendsInApp/banner/FIA.png";

import { PANEL_FIA__APPS_FROM_FRIENDS } from "../constPanels";
import ToolBanner from "template/components/ToolBanner/ToolBanner";
import { withRouter } from "hoc";

const PanelFriendsInApp = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Друзья в приложении</PanelHeaderWithPath>
      <ToolBanner
        tool="toolFriendsInApp"
        icon={FIAIcon}
        text="Узнавайте в каких приложениях VK авторизованы ваши друзья"
      />
      <Group separator="hide">
        <Cell
          before={<Icon28ServicesOutline />}
          multiline={true}
          asideContent={<Icon16Chevron fill="#B8C1CC" />}
          onClick={() => props.openView({ view: VIEW_TOOL, panel: PANEL_FIA__APPS_FROM_FRIENDS })}
        >
          Приложения у друзей
        </Cell>
      </Group>
      <FIAUserList openView={props.openView} />
    </Panel>
  );
};

export default withRouter(PanelFriendsInApp);
