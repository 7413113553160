import {
  DOWNLOAD_HISTORY_REQUEST,
  DOWNLOAD_HISTORY_SUCCESS,
  DOWNLOAD_HISTORY_ERROR,
  DOWNLOAD_HISTORY_SEARCH_REQUEST,
  DOWNLOAD_HISTORY_SEARCH_SUCCESS,
  DOWNLOAD_HISTORY_SEARCH_ERROR,
  DOWNLOAD_HISTORY__INFO_REQUEST,
  DOWNLOAD_HISTORY__INFO_SUCCESS,
  DOWNLOAD_HISTORY__INFO_ERROR,
} from "../../constType";

const initialState = {
  stories: {
    payload: null,
    error: null,
    loading: false,
    isOnce: false,
    search: "",
  },
  storiesSearch: {
    payload: null,
    error: null,
  },
  downloads: {
    activeId: null,
    activeStoryType: null,
    payload: null,
    loading: false,
    error: null,
  },
};

export default function downloadHistoryReducer(state = initialState, action) {
  switch (action.type) {
    case DOWNLOAD_HISTORY_REQUEST:
      return {
        ...state,
        stories: {
          ...state.stories,
          payload: null,
          loading: true,
          error: null,
          isOnce: true,
          progressValue: 0,
        },
      };
    case DOWNLOAD_HISTORY_SUCCESS:
      return {
        ...state,
        stories: {
          ...state.stories,
          loading: false,
          payload: action.payload,
        },
      };
    case DOWNLOAD_HISTORY_ERROR:
      return {
        ...state,
        stories: {
          ...state.stories,
          loading: false,
          error: action.error,
        },
      };
    case DOWNLOAD_HISTORY_SEARCH_REQUEST:
      return {
        ...state,
        stories: {
          ...state.stories,
          search: action.search,
          loading: true,
        },
      };
    case DOWNLOAD_HISTORY_SEARCH_SUCCESS:
      return {
        ...state,
        stories: {
          ...state.stories,
          loading: false,
        },
        storiesSearch: {
          ...state.storiesSearch,
          payload: action.payload,
        },
      };
    case DOWNLOAD_HISTORY_SEARCH_ERROR:
      return {
        ...state,
        stories: {
          ...state.stories,
          loading: false,
        },
        storiesSearch: {
          ...state.storiesSearch,
          error: action.error,
        },
      };
    case DOWNLOAD_HISTORY__INFO_REQUEST:
      return {
        ...state,
        downloads: {
          ...state.downloads,
          loading: true,
          payload: null,
          activeId: action.id,
          activeStoryType: action.storyType,
        },
      };
    case DOWNLOAD_HISTORY__INFO_SUCCESS:
      return {
        ...state,
        downloads: {
          ...state.downloads,
          loading: false,
          payload: action.payload,
        },
      };
    case DOWNLOAD_HISTORY__INFO_ERROR:
      return {
        ...state,
        downloads: {
          ...state.downloads,
          loading: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}
