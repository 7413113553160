import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Group, Placeholder, Tabs, TabsItem } from "@vkontakte/vkui";

import { PANEL_GIFT__INFO } from "../../../../constPanels";

import { fetchInfoGift, tabsInfoGift, setPeriodInfoGift } from "store/actions/tool/gift";

import SeparateFilter from "template/components/SeparateFilter/SeparateFilter";

import GInfoItem from "../GInfoItem/GInfoItem";
import GInfoTopPanel from "../GInfoTopPanel/GInfoTopPanel";
import { convertMonthDay, unixTimeToDate, convertTime } from "helpers";

import { renderPeriodV2 } from "template/popouts/DateChangePeriod/views";
import PopoutTemplateDateChangePeriod from "template/popouts/DateChangePeriod/DateChangePeriod";
import { VIEW_TOOL } from "views/constViews";
import { TABS_IN, TABS_OUT, TABS_ALL } from "../../constTabs";
import Icon56GiftOutline from "@vkontakte/icons/dist/56/gift_outline";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";

const GInfoList = (props) => {
  const dispatch = useDispatch();
  const activeUserId = useSelector((state) => state.toolGift.gifts.activeUserId);
  const activeUserTitle = useSelector((state) => state.toolGift.gifts.activeUserTitle);
  const activeUserDescription = useSelector((state) => state.toolGift.gifts.activeUserDescription);
  const activeUserPhoto = useSelector((state) => state.toolGift.gifts.activeUserPhoto);
  const activeUserSex = useSelector((state) => state.toolGift.gifts.activeUserSex);
  const loading = useSelector((state) => state.toolGift.gifts.loading);
  const error = useSelector((state) => state.toolGift.gifts.error);
  const period = useSelector((state) => state.toolGift.gifts.period);
  const tabs = useSelector((state) => state.toolGift.gifts.tabs);
  const gifts = useSelector((state) => state.toolGift.gifts.payload);
  const handleFetchInfoGift = useCallback(() => dispatch(fetchInfoGift()), [dispatch]);
  const handleSetPeriodInfoGift = useCallback(
    (name, atMin = null, atMax = null) => dispatch(setPeriodInfoGift(name, atMin, atMax)),
    [dispatch]
  );
  const handleTabsInfoGift = useCallback((tabs) => dispatch(tabsInfoGift(tabs)), [dispatch]);

  let lastAt = new Date();

  useEffect(() => {
    handleFetchInfoGift();
  }, [handleFetchInfoGift, period]);

  const renderUser = () => {
    return (
      <GInfoTopPanel
        id={activeUserId}
        title={activeUserTitle}
        photo={activeUserPhoto}
        description={activeUserDescription}
        openView={props.openView}
      />
    );
  };

  const renderGift = () => {
    return gifts.length > 0 ? (
      gifts.map((gift, i) => {
        const id = gift.vk_id;
        const name = gift.name;
        const type = gift.type;
        const text = gift.text;
        const photoUser = gift.photo_user_url;
        const photoGift = gift.photo_gift_url;
        const date = unixTimeToDate(gift.date);
        const sex = activeUserSex;

        let separate = <SeparateFilter>{convertMonthDay(date)}</SeparateFilter>;

        if (lastAt.getDate() === date.getDate() && i !== 0) {
          separate = "";
        }

        lastAt = date;

        return (
          <div key={i}>
            {separate}
            <GInfoItem
              key={i}
              time={convertTime(date)}
              id={id}
              title={name}
              type={type}
              sex={sex}
              text={text}
              photoUser={photoUser}
              photoGift={photoGift}
            />
          </div>
        );
      })
    ) : (
      <Placeholder icon={<Icon56GiftOutline />}>Подарки не найдены</Placeholder>
    );
  };

  const setTabsGift = (tabs) => {
    if (!loading) {
      handleTabsInfoGift(tabs);
      handleFetchInfoGift();
    }
  };

  const handlePeriod = () => {
    if (loading) return false;

    props.openPopout({
      popout: (
        <PopoutTemplateDateChangePeriod
          openView={props.openView}
          period={period} // Период из redux store
          setPeriod={handleSetPeriodInfoGift} // action из redux
          backPanel={PANEL_GIFT__INFO}
          backView={VIEW_TOOL}
          panelTitle={"Подарки"}
          closePopout={props.closePopout}
        />
      ),
    });
  };

  return (
    <>
      {renderPeriodV2({ title: "Подарки", period: period, handler: handlePeriod })}
      {error === null && <Group separator="hide">{renderUser()}</Group>}
      {error === null && (
        <Tabs>
          <TabsItem
            disabled={loading}
            onClick={() => setTabsGift(TABS_ALL)}
            selected={tabs === TABS_ALL}
          >
            Все
          </TabsItem>
          <TabsItem
            disabled={loading}
            onClick={() => setTabsGift(TABS_OUT)} // Отправленный
            selected={tabs === TABS_OUT}
          >
            Отправленные
          </TabsItem>
          <TabsItem
            disabled={loading}
            onClick={() => setTabsGift(TABS_IN)} // Полученный
            selected={tabs === TABS_IN}
          >
            Полученные
          </TabsItem>
        </Tabs>
      )}
      <Group style={{ marginBottom: "20px" }}>
        {gifts !== null && error === null ? renderGift() : ""}
      </Group>
      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchInfoGift,
      })}
    </>
  );
};

export default GInfoList;
