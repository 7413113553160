import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { PANEL_T__CONVERT_LINK, PANEL_T__SELF_LINK_DONE } from "../constPanels";

import { Cell, Group, Panel } from "@vkontakte/vkui";
import TrapIcon from "assets/img/tool/trap/banner/trap.png";
import Icon16Chevron from "@vkontakte/icons/dist/16/chevron";
import { VIEW_TOOL } from "views/constViews";
import Icon28ChainOutline from "@vkontakte/icons/dist/28/chain_outline";
import Icon28UserCircleOutline from "@vkontakte/icons/dist/28/user_circle_outline";

import TWhoCrossedOverList from "./components/TWhoCrossedOverList/TWhoCrossedOverList";
import ToolBanner from "template/components/ToolBanner/ToolBanner";
import { withRouter } from "hoc";

const PanelTrap = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Ловушка</PanelHeaderWithPath>
      <ToolBanner
        tool="toolTrap"
        icon={TrapIcon}
        text="Узнайте кто переходит по ссылкам из вашего профиля"
      />
      <Group style={{ marginBottom: "20px" }} separator={"hide"}>
        <Cell
          before={<Icon28ChainOutline />}
          asideContent={<Icon16Chevron fill="#B8C1CC" />}
          onClick={() => props.openView({ view: VIEW_TOOL, panel: PANEL_T__CONVERT_LINK })}
        >
          Преобразовать ссылку
        </Cell>
        <Cell
          before={<Icon28UserCircleOutline />}
          asideContent={<Icon16Chevron fill="#B8C1CC" />}
          onClick={() => props.openView({ view: VIEW_TOOL, panel: PANEL_T__SELF_LINK_DONE })}
        >
          Создать ссылку на мою страницу
        </Cell>
      </Group>

      <TWhoCrossedOverList openView={props.openView} />
    </Panel>
  );
};

export default withRouter(PanelTrap);
