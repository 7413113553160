import React from "react";
import { Placeholder, Link } from "@vkontakte/vkui";
import BotIcon from "assets/img/bot.png";
import { getSearchObject } from "api";
import { DESKTOP_WEB } from "api/vk/constPlatform";

const ConnectNotifiacation = () => {
  return (
    <Placeholder
      icon={<img src={BotIcon} width={238} height={260} alt=""></img>}
      header="Необходимо подключение к чат-боту"
    >
      Для использования приложения необходимо подключение к боту. Для этого перейдите в чат и
      напишите «старт» <br />
      {getSearchObject().vk_platform === DESKTOP_WEB ? (
        <Link href={"https://vk.com/im?sel=-194197472"} target="_blank">
          Перейти в чат
        </Link>
      ) : (
        <Link href={"https://vk.com/im?sel=-194197472"}>Перейти в чат</Link>
      )}
    </Placeholder>
  );
};

export default ConnectNotifiacation;
