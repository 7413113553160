import React from "react";
import { Avatar, Group, Placeholder, SimpleCell } from "@vkontakte/vkui";
import WLFCommonAudioWithItem from "../WLFCommonAudioWithItem/WLFCommonAudioWithItem";
import HeadTitle from "template/components/HeadTitle/HeadTitle";
import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { fetchCommonAudioWith, showMoreCommonAudioWith } from "store/actions/tool/whoListenFriends";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Icon28ChevronRightOutline, Icon56UsersOutline } from "@vkontakte/icons";
import { useShowMore } from "hooks";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import { PANEL_PROFILE } from "views/profile/panels/constPanels";
import { VIEW_TOOL } from "views/constViews";

const WLFCommonAudioWithList = (props) => {
  const dispatch = useDispatch();
  const activeUserId = useSelector(
    (state) => state.toolWhoListenFriends.commonAudioWith.activeUserId
  );
  const activeUserTitle = useSelector(
    (state) => state.toolWhoListenFriends.commonAudioWith.activeUserTitle
  );
  const activeUserPhoto = useSelector(
    (state) => state.toolWhoListenFriends.commonAudioWith.activeUserPhoto
  );
  const activeUserDescription = useSelector(
    (state) => state.toolWhoListenFriends.commonAudioWith.activeUserDescription
  );

  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const showCount = useSelector((state) => state.toolWhoListenFriends.commonAudioWith.showCount);
  const loading = useSelector((state) => state.toolWhoListenFriends.commonAudioWith.loading);
  const error = useSelector((state) => state.toolWhoListenFriends.commonAudioWith.error);
  const friends = useSelector((state) => state.toolWhoListenFriends.commonAudioWith.payload);

  const handleFetchCommonAudioWith = useCallback(() => {
    dispatch(fetchCommonAudioWith());
  }, [dispatch]);

  const handleShowMoreCommonAudioWith = useCallback(
    () => dispatch(showMoreCommonAudioWith()),
    [dispatch]
  );

  useEffect(() => {
    handleFetchCommonAudioWith();
  }, [handleFetchCommonAudioWith]);

  useShowMore({
    items: friends,
    showCount: showCount,
    maxLength: friends !== null ? friends.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMoreCommonAudioWith,
  });

  const renderFriends = () => {
    return friends.length > 0 ? (
      friends.map((friend, i) => {
        const id = friend.vk_id;
        const photo = friend.photo_url;
        const name = friend.name;
        const commonCount = friend.common_count;
        const commonMusic = friend.common_music;

        if (i < showCount) {
          return (
            <WLFCommonAudioWithItem
              key={i}
              id={id}
              title={name}
              photo={photo}
              commonCount={commonCount}
              commonMusic={commonMusic}
              openModal={props.openModal}
            />
          );
        }

        return false;
      })
    ) : (
      <Placeholder icon={<Icon56UsersOutline />}>Друзья не найдены</Placeholder>
    );
  };

  return (
    <>
      <Group separator="show">
        <HeadTitle>Общие аудио с</HeadTitle>
        <SimpleCell
          id={activeUserId}
          onClick={() =>
            props.openView({
              view: VIEW_TOOL,
              panel: PANEL_PROFILE,
              param: { activeProfileId: activeUserId },
            })
          }
          before={<Avatar size={72} src={activeUserPhoto} />}
          description={activeUserDescription}
          after={<Icon28ChevronRightOutline fill="#B8C1CC" />}
        >
          {activeUserTitle}
        </SimpleCell>
      </Group>

      <Group style={{ marginBottom: "20px" }}>
        {friends !== null && error === null ? renderFriends() : ""}
      </Group>
      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchCommonAudioWith,
      })}
    </>
  );
};

export default WLFCommonAudioWithList;
