import { queryEye3 } from "api";

async function APIEye3GetFans({ timeMin, timeMax }) {
  try {
    const response = await queryEye3({
      method: "fans.get",
      get: { time_min: timeMin, time_max: timeMax },
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3GetFans;
