import { Icon16Add, Icon16Cancel } from "@vkontakte/icons";
import { Avatar, Link, SimpleCell } from "@vkontakte/vkui";
import React from "react";
import {
  NF_ACTION_ADD,
  NF_ACTION_OUT,
  NF_FOLLOWER,
  NF_FRIEND,
  NF_SUBSCRIPTION,
} from "../../constFriend";
import styles from "./NFInfoItem.module.css";

const NFInfoItem = (props) => {
  const getBefore = () => {
    switch (props.action) {
      case NF_ACTION_ADD:
        return (
          <Avatar size={48} src={props.photo}>
            <div className={styles.add}>
              <Icon16Add width={12} height={12} fill="var(--white)" />
            </div>
          </Avatar>
        );
      case NF_ACTION_OUT:
        return (
          <Avatar size={48} src={props.photo}>
            <div className={styles.out}>
              <Icon16Cancel width={12} height={12} fill="var(--white)" />
            </div>
          </Avatar>
        );
      default:
        return "";
    }
  };

  const getLink = () => {
    return (
      <Link href={"https://www.vk.com/id" + props.id} target="_blank">
        {props.title}
      </Link>
    );
  };

  const getTitle = () => {
    let str = "";

    if (props.action === NF_ACTION_ADD && props.type === NF_FRIEND)
      str = props.activeUserSex === "1" ? "Добавила в друзья " : "Добавил в друзья ";
    else if (props.action === NF_ACTION_OUT && props.type === NF_FRIEND)
      str = props.activeUserSex === "1" ? "Удалила из друзей " : "Удалил из друзей ";
    else if (props.action === NF_ACTION_ADD && props.type === NF_FOLLOWER)
      str = props.activeUserSex === "1" ? "Добавила в подписчики " : "Добавил в подписчики ";
    else if (props.action === NF_ACTION_OUT && props.type === NF_FOLLOWER)
      str = props.activeUserSex === "1" ? "Удалила из подписчиков " : "Удалил из подписчиков ";
    else if (props.action === NF_ACTION_ADD && props.type === NF_SUBSCRIPTION)
      str = props.sex === "1" ? " подписалась" : " подписался";
    else if (props.action === NF_ACTION_OUT && props.type === NF_SUBSCRIPTION)
      str = props.sex === "1" ? " отписалась" : " отписался";

    return str;
  };

  return (
    <SimpleCell disabled before={getBefore()} description={"в " + props.time} multiline>
      {props.type === NF_SUBSCRIPTION ? (
        <>
          {getLink()}
          <span style={{ color: "var(--text_secondary)" }}>{getTitle()}</span>
        </>
      ) : (
        <>
          <span style={{ color: "var(--text_secondary)" }}>{getTitle()}</span>
          {getLink()}
        </>
      )}
    </SimpleCell>
  );
};

export default NFInfoItem;
