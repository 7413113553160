import { APIEye3FriendsPopularAudioTool, APIEye3SharedAudioTool } from "api/eye3";
import { checkEYE3ApiError, defaultErrorProperty } from "api/eye3/responseHandler";
import {
  WHO_LISTEN_FRIENDS_POPULAR_AUDIO_REQUEST,
  WHO_LISTEN_FRIENDS_POPULAR_AUDIO_SUCCESS,
  WHO_LISTEN_FRIENDS_POPULAR_AUDIO_ERROR,
  WHO_LISTEN_FRIENDS_POPULAR_AUDIO_SHOW_MORE,
  WHO_LISTEN_FRIENDS_POPULAR_AUDIO_INFO_REQUEST,
  WHO_LISTEN_FRIENDS_POPULAR_AUDIO_INFO_SUCCESS,
  WHO_LISTEN_FRIENDS_POPULAR_AUDIO_INFO_ERROR,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_REQUEST,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_SUCCESS,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_ERROR,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_SHOW_MORE,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_SEARCH_REQUEST,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_SEARCH_SUCCESS,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_SEARCH_ERROR,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_FILTER_REQUEST,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_FILTER_SUCCESS,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_FILTER_ERROR,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_FOR_ME_REQUEST,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_FOR_ME_SUCCESS,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_FOR_ME_ERROR,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_FOR_ME_SHOW_MORE,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_WITH_REQUEST,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_WITH_SUCCESS,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_WITH_ERROR,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_WITH_SHOW_MORE,
} from "../../constType";

import { delayMethodGetUsersVKApi, getSearchObject } from "api";
import { openModal } from "../page";
import { getSearchFilterFriendsV2 } from "helpers/actions";

const secToMin = (time) => {
  let min = Math.floor(time / 60);
  let sec = Math.floor(time % 60);

  let secStr = sec < 10 ? "0" + sec : sec;
  let minStr = min < 10 ? "0" + min : min;

  let result = minStr + ":" + secStr;

  return result;
};

export function fetchPopularAudio() {
  return async (dispatch, getState) => {
    try {
      dispatch(request());

      let result = [];

      const popularAudio = await APIEye3FriendsPopularAudioTool({
        userId: getSearchObject().vk_user_id,
      });

      // Проверка ошибок в API
      const errorAPI = checkEYE3ApiError(popularAudio);
      if (errorAPI !== false) {
        errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
        dispatch(error({ ...errorAPI }));
        return;
      }

      if (!popularAudio.status) {
        dispatch(success(result));
        return result;
      }

      const popularAudioItems = popularAudio.musics;

      if (popularAudioItems.length === 0) {
        dispatch(success(result));
        return result;
      }

      for (let popularAudioItem of popularAudioItems) {
        result.push({
          vk_id: popularAudioItem.id,
          title: popularAudioItem.title.title,
          artist: popularAudioItem.artist.artist,
          duration: secToMin(popularAudioItem.duration),
          listening_friends: popularAudioItem.listening_friends,
        });
      }

      // console.log("result: ", result);

      dispatch(success(result));

      return result;
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: WHO_LISTEN_FRIENDS_POPULAR_AUDIO_REQUEST };
  }

  function success(payload) {
    return { type: WHO_LISTEN_FRIENDS_POPULAR_AUDIO_SUCCESS, payload };
  }

  function error(error) {
    return { type: WHO_LISTEN_FRIENDS_POPULAR_AUDIO_ERROR, error };
  }
}

export function showMorePopularAudio() {
  return { type: WHO_LISTEN_FRIENDS_POPULAR_AUDIO_SHOW_MORE };
}

export function fetchPopularAudioInfo(id) {
  return async (dispatch, getState) => {
    try {
      const state = getState().toolWhoListenFriends;
      const popularAudio = state.popularAudio.payload;

      dispatch(request());

      let result = [];
      const audio = popularAudio.find((item) => {
        if (item.vk_id === id) {
          return item;
        }

        return null;
      });

      const listeningFriendsIds = audio.listening_friends;
      // console.log("listeningFriendsIds: ", listeningFriendsIds);

      const userInfo = await delayMethodGetUsersVKApi({
        userIds: listeningFriendsIds,
        fields: ["photo_100"],
      });

      for (let user of userInfo) {
        result.push({
          vk_id: user.id,
          name: user.first_name + " " + user.last_name,
          photo_url: user.photo_100,
        });
      }

      // console.log("userInfo: ", userInfo);

      dispatch(success(result));

      return result;
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: WHO_LISTEN_FRIENDS_POPULAR_AUDIO_INFO_REQUEST };
  }

  function success(payload) {
    return { type: WHO_LISTEN_FRIENDS_POPULAR_AUDIO_INFO_SUCCESS, payload };
  }

  function error(error) {
    return { type: WHO_LISTEN_FRIENDS_POPULAR_AUDIO_INFO_ERROR, error };
  }
}

export function fetchCommonAudio() {
  return async (dispatch, getState) => {
    try {
      dispatch(request());
      const state = getState().toolWhoListenFriends;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.commonAudio.search,
        filter: state.commonAudio.filter,
        cache: state.commonAudio.cachePayload,
      });

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: WHO_LISTEN_FRIENDS_COMMON_AUDIO_REQUEST };
  }

  function success(payload) {
    return { type: WHO_LISTEN_FRIENDS_COMMON_AUDIO_SUCCESS, payload };
  }

  function error(error) {
    return { type: WHO_LISTEN_FRIENDS_COMMON_AUDIO_ERROR, error };
  }
}

export function showMoreCommonAudio() {
  return { type: WHO_LISTEN_FRIENDS_COMMON_AUDIO_SHOW_MORE };
}

export function searchCommonAudio(search = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(request(search));
      const state = getState().toolWhoListenFriends;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.commonAudio.search,
        filter: state.commonAudio.filter,
        cache: state.commonAudio.cachePayload,
      });

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(search) {
    return { type: WHO_LISTEN_FRIENDS_COMMON_AUDIO_SEARCH_REQUEST, search };
  }

  function success(payload) {
    return { type: WHO_LISTEN_FRIENDS_COMMON_AUDIO_SEARCH_SUCCESS, payload };
  }

  function error(error) {
    return { type: WHO_LISTEN_FRIENDS_COMMON_AUDIO_SEARCH_ERROR, error };
  }
}

export function filterCommonAudio(filter = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(request(filter));
      const state = getState().toolWhoListenFriends;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.commonAudio.search,
        filter: state.commonAudio.filter,
        cache: state.commonAudio.cachePayload,
      });

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(filter) {
    return { type: WHO_LISTEN_FRIENDS_COMMON_AUDIO_FILTER_REQUEST, filter };
  }

  function success(payload) {
    return { type: WHO_LISTEN_FRIENDS_COMMON_AUDIO_FILTER_SUCCESS, payload };
  }

  function error(error) {
    return { type: WHO_LISTEN_FRIENDS_COMMON_AUDIO_FILTER_ERROR, error };
  }
}

async function getCommonAudio(dispatch, success, error, userId) {
  try {
    let result = [];
    let prepareCommonMusic = [];
    const sharedAudio = await APIEye3SharedAudioTool({ userId: userId });
    // console.log("sharedAudio: ", sharedAudio);

    // Проверка ошибок в API
    const errorAPI = checkEYE3ApiError(sharedAudio);
    if (errorAPI !== false) {
      errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
      dispatch(error({ ...errorAPI }));
      return;
    }

    if (!sharedAudio.status) {
      dispatch(success(result));
      return result;
    }

    const popularAudioItems = sharedAudio.musics;

    if (popularAudioItems.length === 0) {
      dispatch(success(result));
      return result;
    }

    for (let user of popularAudioItems) {
      let musics = [];

      for (let audio of user.common_music) {
        musics.push({
          vk_id: audio.id,
          title: audio.title.title,
          artist: audio.artist.artist,
          duration: secToMin(audio.duration),
        });
      }

      prepareCommonMusic.push({
        vk_id: user.id,
        common_count: user.common_count,
        common_music: musics,
      });

      musics = [];
    }

    // console.log("prepareCommonMusic: ", prepareCommonMusic);

    const userInfo = await delayMethodGetUsersVKApi({
      userIds: popularAudioItems.map((music) => music.id),
      fields: ["photo_100"],
    });

    // console.log("userInfo: ", userInfo);

    for (let audio of prepareCommonMusic) {
      for (let user of userInfo) {
        if (audio.vk_id === user.id) {
          result.push({
            vk_id: user.id,
            name: user.first_name + " " + user.last_name,
            photo_url: user.photo_100,
            common_music: audio.common_music,
            common_count: audio.common_count,
          });
          break;
        }
      }
    }

    dispatch(success(result));
    return result;
  } catch (e) {
    throw e;
  }
}

export function fetchCommonAudioForMe() {
  return async (dispatch, getState) => {
    try {
      dispatch(request());
      await getCommonAudio(dispatch, success, error, getSearchObject().vk_user_id);
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: WHO_LISTEN_FRIENDS_COMMON_AUDIO_FOR_ME_REQUEST };
  }

  function success(payload) {
    return { type: WHO_LISTEN_FRIENDS_COMMON_AUDIO_FOR_ME_SUCCESS, payload };
  }

  function error(error) {
    return { type: WHO_LISTEN_FRIENDS_COMMON_AUDIO_FOR_ME_ERROR, error };
  }
}

export function showMoreCommonAudioForMe() {
  return { type: WHO_LISTEN_FRIENDS_COMMON_AUDIO_FOR_ME_SHOW_MORE };
}

export function fetchCommonAudioWith() {
  return async (dispatch, getState) => {
    try {
      const state = getState().toolWhoListenFriends;
      const pageParam = getState().page.param;

      const id = pageParam ? pageParam.activeUserId : state.commonAudioWith.activeUserId;
      const title = pageParam ? pageParam.activeUserTitle : state.commonAudioWith.activeUserTitle;
      const description = pageParam
        ? pageParam.activeUserDescription
        : state.commonAudioWith.activeUserDescription;
      const photo = pageParam ? pageParam.activeUserPhoto : state.commonAudioWith.activeUserPhoto;

      dispatch(request(id, title, description, photo));
      await getCommonAudio(dispatch, success, error, id);
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(id, title, description, photo) {
    return { type: WHO_LISTEN_FRIENDS_COMMON_AUDIO_WITH_REQUEST, id, title, description, photo };
  }

  function success(payload) {
    return { type: WHO_LISTEN_FRIENDS_COMMON_AUDIO_WITH_SUCCESS, payload };
  }

  function error(error) {
    return { type: WHO_LISTEN_FRIENDS_COMMON_AUDIO_WITH_ERROR, error };
  }
}

export function showMoreCommonAudioWith() {
  return { type: WHO_LISTEN_FRIENDS_COMMON_AUDIO_WITH_SHOW_MORE };
}
