import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Group, Search, Placeholder } from "@vkontakte/vkui";
import Icon24Filter from "@vkontakte/icons/dist/24/filter";
import RegistrationDateItem from "../RDItem/RDItem";
import Icon56UsersOutline from "@vkontakte/icons/dist/56/users_outline";

import { MODAL_PAGE_TEMPLATE_FILTER_SEX_AGE_CITY } from "template/modals/constModals";

import {
  searchUsersRegistrationDate,
  showMoreUsersRegistrationDate,
  filterUsersRegistrationDate,
} from "store/actions/tool/registrationDate";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import { useDebounceSearch, useShowMore } from "hooks";
import SecondaryTitle from "template/components/SecondaryTitle/SecondaryTitle";

const RDList = (props) => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const loading = useSelector((state) => state.toolRegistrationDate.friends.loading);
  const filter = useSelector((state) => state.toolRegistrationDate.friends.filter);
  const error = useSelector((state) => state.toolRegistrationDate.friends.error);
  const showCount = useSelector((state) => state.toolRegistrationDate.friends.showCount);
  const search = useSelector((state) => state.toolRegistrationDate.friends.search);
  const friends = useSelector((state) => state.toolRegistrationDate.friends.payload);

  const [searchTerm, setSearchTerm] = useState(search);

  const handleSearchUsersRegistrationDate = useCallback(
    (search) => dispatch(searchUsersRegistrationDate(search)),
    [dispatch]
  );
  const handleFilterUsersRegistrationDate = useCallback(
    (filter) => dispatch(filterUsersRegistrationDate(filter)),
    [dispatch]
  );
  const handleShowMoreUsersRegistrationDate = useCallback(
    () => dispatch(showMoreUsersRegistrationDate()),
    [dispatch]
  );

  useShowMore({
    items: friends,
    showCount: showCount,
    maxLength: friends !== null ? friends.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMoreUsersRegistrationDate,
  });

  useDebounceSearch({
    search: search,
    searchTerm: searchTerm,
    handler: handleSearchUsersRegistrationDate,
  });

  const renderRegList = () => {
    return friends.length > 0 ? (
      friends.map((friend, i) => {
        const id = friend.vk_id;
        const name = friend.name;
        const regDateShow = friend.reg_date_show ? friend.reg_date_show : "";
        const regAgoTime = friend.reg_ago_time ? friend.reg_ago_time : "";
        const photo = friend.photo_url ? friend.photo_url : "";

        if (i < showCount) {
          return (
            <RegistrationDateItem
              key={i}
              id={id}
              title={name}
              description={regDateShow}
              bottomContent={regAgoTime}
              photo={photo}
            />
          );
        }

        return false;
      })
    ) : (
      <Placeholder icon={<Icon56UsersOutline />}>Друзья не найдены</Placeholder>
    );
  };

  const handleFilter = () => {
    if (loading) return;

    props.openModal({
      id: MODAL_PAGE_TEMPLATE_FILTER_SEX_AGE_CITY,
      param: {
        filter: filter,
        closeModal: props.closeModal,
        applyFilter: handleFilterUsersRegistrationDate,
      },
    });
  };

  return (
    <>
      <SecondaryTitle style={{ marginTop: "20px", fontSize: "13px", textTransform: "uppercase" }}>
        ДРУГИЕ ПОЛЬЗОВАТЕЛИ
      </SecondaryTitle>
      <SecondaryTitle style={{ marginTop: "13px" }}>
        Выберите пользователя из списка друзей или воспользуйтесь поиском.
      </SecondaryTitle>

      <Search
        onIconClick={handleFilter}
        value={searchTerm}
        icon={<Icon24Filter />}
        placeholder={"Имя или ссылка на страницу"}
        onChange={(e) => {
          if (loading) return;

          setSearchTerm(e.target.value);
        }}
      />

      <Group style={{ marginBottom: "20px" }}>
        {friends !== null && error === null ? renderRegList() : ""}
      </Group>
      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
      })}
    </>
  );
};

export default RDList;
