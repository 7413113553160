import {
  PAIR_REQUEST,
  PAIR_SUCCESS,
  PAIR_ERROR,
  PAIR_SHOW_MORE,
  PAIR_SEARCH_REQUEST,
  PAIR_SEARCH_SUCCESS,
  PAIR_SEARCH_ERROR,
  PAIR_FILTER_REQUEST,
  PAIR_FILTER_SUCCESS,
  PAIR_FILTER_ERROR,
  PAIR__INFO_REQUEST,
  PAIR__INFO_SUCCESS,
  PAIR__INFO_ERROR,
} from "../../constType";

import { getSearchFilterFriendsV2 } from "helpers/actions";
import { APIEye3GetPairTool } from "api/eye3";
import { delayMethodGetUsersVKApi } from "api";
import { openModal } from "../page";
import { calcAge } from "helpers";
import { checkEYE3ApiError, defaultErrorProperty } from "api/eye3/responseHandler";

export function fetchFriendPair() {
  return async (dispatch, getState) => {
    try {
      dispatch(request());

      const state = getState().toolPair;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.friends.search,
        filter: state.friends.filter,
        cache: state.friends.cachePayload,
      });

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: PAIR_REQUEST };
  }

  function success(payload) {
    return { type: PAIR_SUCCESS, payload };
  }

  function error(error) {
    return { type: PAIR_ERROR, error };
  }
}

export function showMoreFriendPair() {
  return { type: PAIR_SHOW_MORE };
}

export function searchFriendPair(search = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(request(search));
      const state = getState().toolPair;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.friends.search,
        filter: state.friends.filter,
        cache: state.friends.cachePayload,
      });

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(search) {
    return { type: PAIR_SEARCH_REQUEST, search };
  }

  function success(payload) {
    return { type: PAIR_SEARCH_SUCCESS, payload };
  }

  function error(error) {
    return { type: PAIR_SEARCH_ERROR, error };
  }
}

export function filterFriendPair(filter = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(request(filter));
      const state = getState().toolPair;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.friends.search,
        filter: state.friends.filter,
        cache: state.friends.cachePayload,
      });

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(filter) {
    return { type: PAIR_FILTER_REQUEST, filter };
  }

  function success(payload) {
    return { type: PAIR_FILTER_SUCCESS, payload };
  }

  function error(error) {
    return { type: PAIR_FILTER_ERROR, error };
  }
}

export function fetchInfoPair() {
  return async (dispatch, getState) => {
    try {
      const state = getState().toolPair;
      const pageParam = getState().page.param;

      // Задаем pageParam в зависимости от его наличия (когда загружен новый пользователь при изменении периода используются значения из state.groups)
      const id = pageParam ? pageParam.activeUserId : state.pairs.activeUserId;
      const title = pageParam ? pageParam.activeUserTitle : state.pairs.activeUserTitle;
      const description = pageParam
        ? pageParam.activeUserDescription
        : state.pairs.activeUserDescription;
      const photo = pageParam ? pageParam.activeUserPhoto : state.pairs.activeUserPhoto;
      const sex = pageParam ? pageParam.activeUserSex : state.pairs.activeUserSex;

      dispatch(request(id, title, description, photo, sex));

      let result = {
        items: [],
        self_relation: null,
      };

      const pair = await APIEye3GetPairTool({ userId: id });
      //console.log(pair);

      // Проверка ошибок в API
      const errorAPI = checkEYE3ApiError(pair);
      if (errorAPI !== false) {
        errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
        dispatch(error({ ...errorAPI }));
        return;
      }

      if (!pair.status) {
        dispatch(error({ ...defaultErrorProperty, message: "" }));
        return;
      }

      if (pair.self_relation.length !== 0) {
        result.self_relation = pair.self_relation;
      }

      const pairItems = pair.items;

      if (pairItems.length !== 0) {
        const joinUsers = pairItems.map((user) => user.vk_id);

        const userInfo = await delayMethodGetUsersVKApi({
          userIds: joinUsers,
          fields: ["photo_100", "bdate", "city", "country", "sex"],
        });

        const userItems = userInfo;
        //console.log('userItems: ', userItems);

        userItems.forEach((user) => {
          pairItems.forEach((pair) => {
            if (user.id === pair.vk_id) {
              result.items.push({
                vk_id: user.id,
                name: user.first_name + " " + user.last_name,
                age: user.bdate ? calcAge(user.bdate) : null, // Расчет возраста по дате рождения
                city: user.city ? user.city.title : null, // Город
                city_id: user.city ? user.city.id.toString() : null, // Город id
                photo_url: user.photo_100 ? user.photo_100 : "", // Фото
                country: user.country ? user.country.title : null, // Страна
                country_id: user.country ? user.country.id.toString() : null, // Страна id
                sex: user.sex.toString(),
                relation: pair.relation,
              });
            }
          });
        });
      }

      //console.log('result: ', result);

      dispatch(success(result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(id, title, description, photo, sex) {
    return { type: PAIR__INFO_REQUEST, id, title, description, photo, sex };
  }

  function success(payload) {
    return { type: PAIR__INFO_SUCCESS, payload };
  }

  function error(error) {
    return { type: PAIR__INFO_ERROR, error };
  }
}
