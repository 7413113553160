import { VkBridgeSend } from "api/vk";
import {
  VK_GET_STORAGE_REQUEST,
  VK_GET_STORAGE_SUCCESS,
  VK_GET_STORAGE_ERROR,
  VK_SET_STORAGE_REQUEST,
  VK_SET_STORAGE_SUCCESS,
  VK_SET_STORAGE_ERROR,
} from "../constType";

import { delay } from "helpers";

export function resetVKStorage({ keys }) {
  try {
    keys.forEach(async (key) => {
      await VkBridgeSend("VKWebAppStorageSet", {
        key: key,
        value: "",
      });

      await delay(300);
    });

    console.log("resetVKStorage: Complete");
  } catch (e) {
    throw new Error("resetVKStorage::: " + JSON.stringify(e));
  }
}

export function getVKStorage() {
  return async (dispatch) => {
    try {
      dispatch(request());

      const allKey = await VkBridgeSend("VKWebAppStorageGetKeys", {
        count: 1000,
        offset: 0,
      });

      //console.log('allKey: ', allKey);

      const storage = await VkBridgeSend("VKWebAppStorageGet", {
        keys: allKey.keys,
      });

      let storageObj = {};

      storage.keys.forEach((s) => {
        storageObj[s.key] = JSON.parse(s.value);
      });

      //resetVKStorage({keys: allKey.keys});

      dispatch(success(storageObj));
    } catch (e) {
      dispatch(error(e));
    }
  };

  function request() {
    return { type: VK_GET_STORAGE_REQUEST };
  }

  function success(payload) {
    return { type: VK_GET_STORAGE_SUCCESS, payload };
  }

  function error(e) {
    return { type: VK_GET_STORAGE_ERROR, error: e };
  }
}

export function setVKStorage({ key, value }) {
  return async (dispatch, getState) => {
    try {
      dispatch(request());

      const state = getState().vkStorage;

      await VkBridgeSend("VKWebAppStorageSet", {
        key: key,
        value: typeof value === "object" ? JSON.stringify(value) : value,
      });

      let storageObj = {
        ...state.payload,
        [key]: value,
      };

      dispatch(success(storageObj));
    } catch (e) {
      dispatch(error(e));
    }
  };

  function request() {
    return { type: VK_SET_STORAGE_REQUEST };
  }

  function success(payload) {
    return { type: VK_SET_STORAGE_SUCCESS, payload };
  }

  function error(e) {
    return { type: VK_SET_STORAGE_ERROR, error: e };
  }
}
