import React from "react";
import { Div, Button, Placeholder, Spinner } from "@vkontakte/vkui";
import Icon56ErrorOutline from "@vkontakte/icons/dist/56/error_outline";
import { VkBridgeSend } from "api/vk";

export function renderShowMoreBtn({ error, loading, items, showCount, maxLength, handler }) {
  return error === null && items !== null && !loading ? (
    <Div style={{ textAlign: "center" }}>
      <Button
        style={{ marginTop: "5px", display: !(maxLength > showCount) ? "none" : "inline" }}
        onClick={handler}
      >
        Показать еще
      </Button>
    </Div>
  ) : (
    ""
  );
}

export function renderLoadMoreBtn({ error, loading, items, offset, maxLength, handler }) {
  return error === null && items !== null && !loading ? (
    <Div style={{ textAlign: "center" }}>
      <Button
        style={{ marginTop: "5px", display: !(maxLength > offset) ? "none" : "inline" }}
        onClick={handler}
      >
        Загрузить еще
      </Button>
    </Div>
  ) : (
    ""
  );
}

export function renderLoader({ loading, size = "medium", style = { margin: "20px 0" } }) {
  return loading && <Spinner size={size} style={style} />;
}

export function renderErrorPlaceholder({
  loading,
  error,
  isTryAgainHandler = null,
  defaultIcon = <Icon56ErrorOutline />,
  defaultMessage = "Ошибка при загрузке данных",
}) {
  return error !== null && typeof error === "object" && !loading ? (
    <Placeholder
      icon={error.icon !== null ? error.icon : defaultIcon}
      action={
        isTryAgainHandler !== null && error.isTryAgain ? (
          <Button size="l" mode="tertiary" onClick={isTryAgainHandler}>
            Повторить попытку
          </Button>
        ) : null
      }
    >
      <div>
        <p>{error.isVisibleMessage ? error.message : defaultMessage}</p>
        {!error.isVisibleMessage ? (
          <>
            <p>Присылайте данные лога в тех поддержку</p>
            <textarea
              style={{ resize: "none", width: "100%", height: "120px" }}
              defaultValue={error.message}
              readOnly={true}
              onClick={(e) => {
                VkBridgeSend("VKWebAppCopyText", { text: e.target.value });
              }}
            ></textarea>
          </>
        ) : (
          ""
        )}
      </div>
    </Placeholder>
  ) : (
    ""
  );
}
