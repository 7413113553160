import { queryEye3 } from "api";

async function APIEye3GetV2Guest({ type = null, dateFrom = null, dateTo = null }) {
  try {
    const response = await queryEye3({
      method: "guest.get_v2",
      get: { type: type, date_from: dateFrom, date_to: dateTo },
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3GetV2Guest;
