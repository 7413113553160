import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Group, Search, Placeholder } from "@vkontakte/vkui";
import WhoHiddenAnotherUserItem from "../WhoHiddenAnotherUserItem/WhoHiddenAnotherUserItem";
import Icon24Filter from "@vkontakte/icons/dist/24/filter";
import Icon56UsersOutline from "@vkontakte/icons/dist/56/users_outline";

import {
  fetchWhoHiddenAnotherUser,
  searchWhoHiddenAnotherUser,
  filterWhoHiddenAnotherUser,
  showMoreWhoHiddenAnotherUser,
} from "store/actions/tool/whoHidden";

import { declOfNum } from "helpers";

import { MODAL_PAGE_TEMPLATE_FILTER_SEX_AGE_CITY } from "template/modals/constModals";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import { useDebounceSearch, useShowMore } from "hooks";
import HeadTitle from "template/components/HeadTitle/HeadTitle";
import SecondaryTitle from "template/components/SecondaryTitle/SecondaryTitle";

const WhoHiddenAnotherUserList = (props) => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const loading = useSelector((state) => state.toolWhoHidden.whoHiddenAnotherUser.loading);
  const isOnce = useSelector((state) => state.toolWhoHidden.whoHiddenAnotherUser.isOnce);
  const search = useSelector((state) => state.toolWhoHidden.whoHiddenAnotherUser.search);
  const filter = useSelector((state) => state.toolWhoHidden.whoHiddenAnotherUser.filter);
  const error = useSelector((state) => state.toolWhoHidden.whoHiddenAnotherUser.error);
  const showCount = useSelector((state) => state.toolWhoHidden.whoHiddenAnotherUser.showCount);
  const users = useSelector((state) => state.toolWhoHidden.whoHiddenAnotherUser.payload);

  const [searchTerm, setSearchTerm] = useState(search);

  const handleFetchWhoHiddenAnotherUser = useCallback(
    () => dispatch(fetchWhoHiddenAnotherUser()),
    [dispatch]
  );
  const handleSearchWhoHiddenAnotherUser = useCallback(
    (search) => dispatch(searchWhoHiddenAnotherUser(search)),
    [dispatch]
  );
  const handleFilterWhoHiddenAnotherUser = useCallback(
    (filter) => dispatch(filterWhoHiddenAnotherUser(filter)),
    [dispatch]
  );
  const handleShowMoreWhoHiddenAnotherUser = useCallback(
    () => dispatch(showMoreWhoHiddenAnotherUser()),
    [dispatch]
  );

  useShowMore({
    items: users,
    showCount: showCount,
    maxLength: users !== null ? users.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMoreWhoHiddenAnotherUser,
  });

  useEffect(() => {
    if (!isOnce) handleFetchWhoHiddenAnotherUser();
  }, [handleFetchWhoHiddenAnotherUser, isOnce]);

  useDebounceSearch({
    search: search,
    searchTerm: searchTerm,
    handler: handleSearchWhoHiddenAnotherUser,
  });

  const handleFilter = () => {
    if (loading) return;

    props.openModal({
      id: MODAL_PAGE_TEMPLATE_FILTER_SEX_AGE_CITY,
      param: {
        filter: filter,
        closeModal: props.closeModal,
        applyFilter: handleFilterWhoHiddenAnotherUser,
      },
    });
  };

  const renderUserList = () => {
    return users.length > 0 ? (
      users.map((user, i) => {
        const id = user.vk_id;
        const name = user.name;
        const city = user.city ? user.city : "";
        const age = user.age ? user.age : "";
        const photo = user.photo_url ? user.photo_url : "";
        const ageStrFormat = age ? age + " " + declOfNum(age, ["год", "года", "лет"]) : "";
        const comma = ageStrFormat && city ? ", " : "";

        if (i < showCount) {
          return (
            <WhoHiddenAnotherUserItem
              key={i}
              id={id}
              title={name}
              description={ageStrFormat + comma + city}
              photo={photo}
              openView={props.openView}
            />
          );
        }

        return false;
      })
    ) : (
      <Placeholder icon={<Icon56UsersOutline />}>Друзья не найдены</Placeholder>
    );
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      <HeadTitle>Кто скрыл другого пользователя</HeadTitle>
      <SecondaryTitle>
        Выберите пользователя из списка друзей или воспользуйтесь поиском.
      </SecondaryTitle>
      <Search
        value={searchTerm}
        icon={<Icon24Filter />}
        placeholder={"Имя или ссылка на страницу"}
        onIconClick={handleFilter}
        onChange={(e) => {
          if (loading) return;

          setSearchTerm(e.target.value);
        }}
      />

      <Group>
        {users !== null && error === null ? renderUserList() : ""}
        {renderLoader({ loading: loading })}
      </Group>
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchWhoHiddenAnotherUser,
      })}
    </div>
  );
};

export default WhoHiddenAnotherUserList;
