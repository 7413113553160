import { queryEye3 } from "api";

async function APIEye3AccessProfile() {
  try {
    const response = await queryEye3({
      method: "profile.access",
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3AccessProfile;
