import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import AllCommentList from "./components/AllCommentList/AllCommentList";
import { Panel } from "@vkontakte/vkui";
import { withRouter } from "hoc";

const PanelAllComment = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Профиль</PanelHeaderWithPath>
      <AllCommentList />
    </Panel>
  );
};

export default withRouter(PanelAllComment);
