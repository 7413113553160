import React from "react";

import PanelHeaderWitchoutPath from "template/components/PanelHeaderWitchoutPath/PanelHeaderWitchoutPath";
import ProfileInfo from "./components/ProfileInfo/ProfileInfo";
import SettingsApp from "./components/SettingsApp/SettingsApp";
import SettingsConnect from "./components/SettingsConnect/SettingsConnect";
import ProfileBanner from "./components/ProfileBanner/ProfileBanner";
import { Panel } from "@vkontakte/vkui";
import AffiliateProgram from "./components/AffiliateProgram/AffiliateProgram";
import { withRouter } from "hoc";

const PanelMain = (props) => {
  return (
    <Panel id={props.id}>
      <div style={{ marginBottom: "20px" }}>
        <PanelHeaderWitchoutPath>Мой профиль</PanelHeaderWitchoutPath>
        <ProfileInfo />
        <AffiliateProgram openView={props.openView} openModal={props.openModal} />
        <SettingsApp />
        <SettingsConnect openView={props.openView} openModal={props.openModal} />
        <ProfileBanner />
      </div>
    </Panel>
  );
};

export default withRouter(PanelMain);
