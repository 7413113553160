import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";

import { Panel } from "@vkontakte/vkui";
import DHIcon from "assets/img/tool/downloadHistory/downloadHistory.png";
import ToolBanner from "template/components/ToolBanner/ToolBanner";
import DHUserList from "./components/DHUserList/DHUserList";
import { withRouter } from "hoc";

const PanelDownloadHistory = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Скачивание историй</PanelHeaderWithPath>
      <ToolBanner
        tool="toolDownloadHistory"
        icon={DHIcon}
        text="Скачивайте истории, которые опубликовали ваши друзья или сообщества"
      />
      <DHUserList openView={props.openView} />
    </Panel>
  );
};

export default withRouter(PanelDownloadHistory);
