import { PERIOD_MONTH } from "template/popouts/DateChangePeriod/constPeriod";
import { TABS_ALL } from "views/tool/panels/gift/GInfo/constTabs";

import {
  GIFT_REQUEST,
  GIFT_SUCCESS,
  GIFT_ERROR,
  GIFT_SHOW_MORE,
  GIFT_SEARCH_REQUEST,
  GIFT_SEARCH_SUCCESS,
  GIFT_SEARCH_ERROR,
  GIFT_FILTER_REQUEST,
  GIFT_FILTER_SUCCESS,
  GIFT_FILTER_ERROR,
  GIFT__INFO_SET_PERIOD,
  GIFT__INFO_TABS,
  GIFT__INFO_REQUEST,
  GIFT__INFO_SUCCESS,
  GIFT__INFO_ERROR,
} from "../../constType";

const initialState = {
  friends: {
    payload: null,
    showCount: 20,
    cachePayload: null,
    search: "",
    filter: "",
    isOnce: false,
    loading: false,
    error: null,
  },
  gifts: {
    payload: null,
    activeUserId: null, // Параметры для сохранения пользователя
    activeUserPhoto: null, //
    activeUserTitle: null, //
    activeUserDescription: null, //
    activeUserSex: null,
    loading: false,
    error: null,
    tabs: TABS_ALL,
    period: {
      name: PERIOD_MONTH,
      atMin: null,
      atMax: null,
    },
  },
};

export default function giftReducer(state = initialState, action) {
  switch (action.type) {
    case GIFT_REQUEST:
      return {
        ...state,
        friends: {
          ...state.friends,
          showCount: 20,
          payload: null,
          cachePayload:
            state.friends.search === "" && state.friends.filter === ""
              ? null // Если поле поиска и фильтра пустое то очистить кэш
              : state.friends.cachePayload,
          isOnce: true,
          loading: true,
          error: null,
        },
      };
    case GIFT_SUCCESS:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: action.payload,
          loading: false,
          cachePayload:
            state.friends.search === "" && state.friends.filter === ""
              ? action.payload // Если поле поиска и фильтра пустое то сохранить результат
              : state.friends.cachePayload,
        },
      };
    case GIFT_ERROR:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          error: action.error,
        },
      };
    case GIFT_SHOW_MORE:
      return {
        ...state,
        friends: {
          ...state.friends,
          showCount: parseInt(state.friends.showCount) + 20,
        },
      };
    case GIFT_SEARCH_REQUEST:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: null,
          showCount: 20,
          search: action.search,
          loading: true,
          error: null,
        },
      };
    case GIFT_SEARCH_SUCCESS:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: [...action.payload],
          loading: false,
        },
      };
    case GIFT_SEARCH_ERROR:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          error: action.error,
        },
      };
    case GIFT_FILTER_REQUEST:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: null,
          showCount: 20,
          filter: action.filter,
          loading: true,
          error: null,
        },
      };
    case GIFT_FILTER_SUCCESS:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          payload: [...action.payload],
        },
      };
    case GIFT_FILTER_ERROR:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          error: action.error,
        },
      };
    case GIFT__INFO_REQUEST:
      return {
        ...state,
        gifts: {
          ...state.gifts,
          payload: null,
          activeUserId: action.id,
          activeUserDescription: action.description,
          activeUserTitle: action.title,
          activeUserPhoto: action.photo,
          activeUserSex: action.sex,
          loading: true,
          error: null,
        },
      };
    case GIFT__INFO_SUCCESS:
      return {
        ...state,
        gifts: {
          ...state.gifts,
          payload: action.payload,
          loading: false,
        },
      };
    case GIFT__INFO_ERROR:
      return {
        ...state,
        gifts: {
          ...state.gifts,
          loading: false,
          error: action.error,
        },
      };
    case GIFT__INFO_TABS:
      return {
        ...state,
        gifts: {
          ...state.gifts,
          tabs: action.tabs,
        },
      };
    case GIFT__INFO_SET_PERIOD:
      return {
        ...state,
        gifts: {
          ...state.gifts,
          period: {
            ...state.gifts.period,
            name: action.name,
            atMin: action.atMin,
            atMax: action.atMax,
          },
        },
      };
    default:
      return state;
  }
}
