import { queryEye3 } from "api";

async function APIEye3VkApiUser({ method, params, post = true }) {
  try {
    const response = await queryEye3({
      method: "user.vk_api",
      post: { method, params: JSON.stringify(params), post },
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3VkApiUser;
