import React from "react";
import { Avatar, Switch, SimpleCell } from "@vkontakte/vkui";

const TSSettingNotificationItem = (props) => {
  return (
    <SimpleCell
      disabled
      before={<Avatar size={48} src={props.photo} />}
      after={
        <Switch
          defaultChecked={props.isNotification}
          onClick={() => props.handleSetNotification(props.id, props.isNotification ? false : true)}
        />
      }
      description={props.description}
    >
      {props.title}
    </SimpleCell>
  );
};

export default TSSettingNotificationItem;
