import React, { useState } from "react";
import {
  ModalPage,
  ModalPageHeader,
  PanelHeaderButton,
  FormLayout,
  Checkbox,
  FormLayoutGroup,
  Button,
  Div,
} from "@vkontakte/vkui";
import Icon28CancelOutline from "@vkontakte/icons/dist/28/cancel_outline";

const TrackingOption = (props) => {
  const paramModal = props.modalParam;
  const settings = paramModal ? paramModal.settings : null;

  const [selectedOption, setSelectedOption] = useState({
    // Объект фильтров
    likes: settings ? settings.likes : true,
    comments: settings ? settings.comments : true,
    friends: settings ? settings.friends : true,
    groups: settings ? settings.groups : true,
    relation: settings ? settings.relation : true,
    gift: settings ? settings.gift : true,
    online: settings ? settings.online : true,
    info: settings ? settings.info : true,
  });

  const onChangeOption = (e, type) => {
    //console.log(e.target.checked);
    const typeObj = {};
    typeObj[type] = e.target.checked;

    setSelectedOption({
      ...selectedOption,
      ...typeObj,
    });

    //console.log(selectedOption);
  };

  const showResult = () => {
    if (
      paramModal.hasOwnProperty("call") &&
      paramModal.hasOwnProperty("id") &&
      paramModal.call === "start_tracking"
    ) {
      // Если вызванно из тотальной слежки
      paramModal.setTracking(paramModal.id, true, selectedOption);
    } else {
      paramModal.setTracking(true, selectedOption);
    }

    paramModal.closeModal();
  };

  return (
    <ModalPage
      id={props.id}
      header={
        <ModalPageHeader
          separator={false}
          right={
            <PanelHeaderButton onClick={() => paramModal.closeModal()}>
              <Icon28CancelOutline />
            </PanelHeaderButton>
          }
        >
          Опции отслеживания
        </ModalPageHeader>
      }
    >
      <FormLayout>
        <FormLayoutGroup top="Выберите какие действия вы хотите отслеживать">
          <Checkbox
            defaultChecked={selectedOption.likes}
            onChange={(e) => onChangeOption(e, "likes")}
          >
            Лайки
          </Checkbox>
          <Checkbox
            defaultChecked={selectedOption.comments}
            onChange={(e) => onChangeOption(e, "comments")}
          >
            Комментарии
          </Checkbox>
          <Checkbox
            defaultChecked={selectedOption.friends}
            onChange={(e) => onChangeOption(e, "friends")}
          >
            Добавление/удаление друзей
          </Checkbox>
          <Checkbox
            defaultChecked={selectedOption.groups}
            onChange={(e) => onChangeOption(e, "groups")}
          >
            Вступление/выход из сообществ
          </Checkbox>
          <Checkbox
            defaultChecked={selectedOption.relation}
            onChange={(e) => onChangeOption(e, "relation")}
          >
            Семейное положение
          </Checkbox>
          <Checkbox
            defaultChecked={selectedOption.gift}
            onChange={(e) => onChangeOption(e, "gift")}
          >
            Подарки
          </Checkbox>
          <Checkbox
            defaultChecked={selectedOption.online}
            onChange={(e) => onChangeOption(e, "online")}
          >
            Статистика времени онлайн
          </Checkbox>
          <Checkbox
            defaultChecked={selectedOption.info}
            onChange={(e) => onChangeOption(e, "info")}
          >
            Изменение блока информации
          </Checkbox>
        </FormLayoutGroup>
      </FormLayout>
      <Div>
        <Button size="l" style={{ width: "100%" }} onClick={showResult}>
          Начать отслеживание
        </Button>
      </Div>
    </ModalPage>
  );
};

export default TrackingOption;
