import { queryEye3 } from "api";

async function APIEye3GetAppTool() {
  try {
    const response = await queryEye3({
      method: "tool.get_app",
      access: { isBotConnect: true, isLicense: true, isLimit: true },
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3GetAppTool;
