import { queryEye3 } from "api";

async function APIEye3MenuListStat() {
  try {
    const response = await queryEye3({
      method: "stat.menu_list",
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3MenuListStat;
