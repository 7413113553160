import { queryEye3 } from "api";

async function APIEye3SearchRelationTool({
  countryId,
  cityIds,
  relationOn,
  sex,
  periodDayCount,
  relationFrom = null,
  ageFrom = null,
  ageTo = null,
}) {
  try {
    const response = await queryEye3({
      method: "tool.search_relation",
      get: {
        country_id: countryId,
        city_ids: cityIds,
        relation_on: relationOn,
        sex: sex,
        period_day_count: periodDayCount,
        relation_from: relationFrom,
        age_from: ageFrom,
        age_to: ageTo,
      },
      access: { isBotConnect: true, isLicense: true, isLimit: true },
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3SearchRelationTool;
