import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import WLFPopularAudioList from "./WLFPopularAudioList/WLFPopularAudioList";
import { Panel } from "@vkontakte/vkui";
import { withRouter } from "hoc";

const PanelWLFPopularAudio = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Что слушают друзья</PanelHeaderWithPath>
      <WLFPopularAudioList
        openModal={props.openModal}
        closeModal={props.closeModal}
        openPopout={props.openPopout}
        closePopout={props.closePopout}
      />
    </Panel>
  );
};

export default withRouter(PanelWLFPopularAudio);
