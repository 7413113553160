import { queryEye3 } from "api";

async function APIEye3SearchRelationSettingsTool() {
  try {
    const response = await queryEye3({
      method: "tool.search_relation_settings",
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3SearchRelationSettingsTool;
