import React from "react";
import { View, Panel, Placeholder, Button } from "@vkontakte/vkui";
import Icon56ErrorOutline from "@vkontakte/icons/dist/56/error_outline";

const AuthView = (props) => {
  return (
    <View activePanel="auth">
      <Panel id="auth">
        <Placeholder
          action={
            <Button size="l" mode="tertiary" onClick={props.handleRepeat}>
              Попробовать снова
            </Button>
          }
          icon={<Icon56ErrorOutline />}
          stretched
        >
          Возникла ошибка при авторизации приложения
        </Placeholder>
      </Panel>
    </View>
  );
};

AuthView.defaultProps = {
  handleRepeat: null,
};

export default AuthView;
