import React from "react";

import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Panel } from "@vkontakte/vkui";
import DFUserList from "./components/DFUserList/DFUserList";
import ToolBanner from "template/components/ToolBanner/ToolBanner";
import DeleteIcon from "assets/img/tool/deletedFriends/banner/DF.png";
import { withRouter } from "hoc";

const PanelDeletedFriends = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Удаленные друзья</PanelHeaderWithPath>
      <ToolBanner
        tool="toolDeletedFriends"
        icon={DeleteIcon}
        text="Узнавайте кого из ваших друзей удалили/забанили"
      />
      <DFUserList
        openView={props.openView}
        openModal={props.openModal}
        closeModal={props.closeModal}
        openPopout={props.openPopout}
        closePopout={props.closePopout}
      />
    </Panel>
  );
};

export default withRouter(PanelDeletedFriends);
