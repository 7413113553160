import { queryEye3 } from "api";

async function APIEye3SharedAudioTool({ userId = null } = {}) {
  try {
    const response = await queryEye3({
      method: "tool.shared_audio",
      get: {
        user_id: userId,
      },
      access: { isBotConnect: true, isLicense: true, isLimit: true },
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3SharedAudioTool;
