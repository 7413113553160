import React from "react";
import { useSelector } from "react-redux";
import GraphSex from "./components/GraphSex/GraphSex";
import GraphAge from "./components/GraphAge/GraphAge";
import GraphGeo from "./components/GraphGeo/GraphGeo";
import { Div, Group, Header, Placeholder } from "@vkontakte/vkui";
import TopList from "./components/TopList/TopList";
import { Icon28ViewOutline } from "@vkontakte/icons";

const SpectatorTab = (props) => {
  const stats = useSelector((state) => state.toolHistoryStatistics.stats.payload);
  const publish = stats !== null ? stats.publish : 0;
  const views = stats !== null ? stats.views : 0;
  const likes = stats !== null ? stats.likes : 0;
  const spectators = stats !== null ? stats.spectators : null;
  const sex = spectators !== null ? spectators.sex : { male_percent: 0, female_percent: 0 };
  const age14_24 =
    spectators !== null ? spectators.age14_24 : { male_percent: 0, female_percent: 0 };
  const age25_34 =
    spectators !== null ? spectators.age25_34 : { male_percent: 0, female_percent: 0 };
  const age35_44 =
    spectators !== null ? spectators.age35_44 : { male_percent: 0, female_percent: 0 };
  const age45_54 =
    spectators !== null ? spectators.age45_54 : { male_percent: 0, female_percent: 0 };
  const age55 = spectators !== null ? spectators.age55 : { male_percent: 0, female_percent: 0 };
  const geo = spectators !== null ? spectators.geo : {};
  const topView = spectators !== null ? spectators.topView : [];
  const topLike = spectators !== null ? spectators.topLike : [];

  return (
    <>
      {publish !== 0 && views !== 0 ? (
        <div style={{ marginBottom: "20px" }}>
          {sex.male_percent !== 0 || sex.female_percent !== 0 ? (
            <GraphSex male={sex.male_percent} female={sex.female_percent} />
          ) : (
            ""
          )}
          {age14_24.male_percent !== 0 ||
          age14_24.female_percent !== 0 ||
          age25_34.male_percent !== 0 ||
          age25_34.female_percent !== 0 ||
          age35_44.male_percent !== 0 ||
          age35_44.female_percent !== 0 ||
          age45_54.male_percent !== 0 ||
          age45_54.female_percent !== 0 ||
          age55.male_percent !== 0 ||
          age55.female_percent !== 0 ? (
            <Group separator="show">
              <Div style={{ paddingBottom: "0px" }}>
                {age14_24.male_percent !== 0 || age14_24.female_percent !== 0 ? (
                  <GraphAge
                    title="14 - 24"
                    male={age14_24.male_percent}
                    female={age14_24.female_percent}
                  />
                ) : (
                  ""
                )}
                {age25_34.male_percent !== 0 || age25_34.female_percent !== 0 ? (
                  <GraphAge
                    title="25 - 34"
                    male={age25_34.male_percent}
                    female={age25_34.female_percent}
                  />
                ) : (
                  ""
                )}
                {age35_44.male_percent !== 0 || age35_44.female_percent !== 0 ? (
                  <GraphAge
                    title="35 - 44"
                    male={age35_44.male_percent}
                    female={age35_44.female_percent}
                  />
                ) : (
                  ""
                )}
                {age45_54.male_percent !== 0 || age45_54.female_percent !== 0 ? (
                  <GraphAge
                    title="45 - 54"
                    male={age45_54.male_percent}
                    female={age45_54.female_percent}
                  />
                ) : (
                  ""
                )}
                {age55.male_percent !== 0 || age55.female_percent !== 0 ? (
                  <GraphAge title="> 55" male={age55.male_percent} female={age55.female_percent} />
                ) : (
                  ""
                )}
              </Div>
            </Group>
          ) : (
            ""
          )}
          {Object.values(geo).length !== 0 ? (
            <Group header={<Header mode="secondary">Гео</Header>} separator="show">
              <Div style={{ paddingBottom: "0px" }}>
                {Object.entries(geo).map(([key, value]) => {
                  return <GraphGeo key={value.percent} title={key} percent={value.percent} />;
                })}
              </Div>
            </Group>
          ) : (
            ""
          )}
          {views !== 0 && (
            <TopList
              header="Топ по просмотрам"
              type="views"
              items={topView}
              openView={props.openView}
            />
          )}
          {likes !== 0 && (
            <TopList
              header="Топ по лайкам"
              type="likes"
              items={topLike}
              openView={props.openView}
            />
          )}
        </div>
      ) : (
        <Placeholder icon={<Icon28ViewOutline width={56} height={56} />}>
          Зрители не найдены
        </Placeholder>
      )}
    </>
  );
};

export default SpectatorTab;
