import React from "react";
import Icon16Dropdown from "@vkontakte/icons/dist/16/dropdown";
import { Div, Link } from "@vkontakte/vkui";
import { PERIOD_WEEK, PERIOD_TODAY, PERIOD_MONTH, PERIOD_CUSTOM, PERIOD_ALL } from "./constPeriod";

export const renderPeriodV2 = ({ title, period, handler }) => {
  let str = "";

  if (period) {
    switch (period.name) {
      case PERIOD_MONTH:
        str = "За месяц";
        break;
      case PERIOD_TODAY:
        str = "За сегодня";
        break;
      case PERIOD_WEEK:
        str = "За неделю";
        break;
      case PERIOD_ALL:
        str = "За всё время";
        break;
      case PERIOD_CUSTOM:
        str = "Пользовательский";
        break;
      default:
        str = "";
        break;
    }
  }

  return (
    <Div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span style={{ fontWeight: "bold", fontSize: "20px", color: "var(--text_primary)" }}>
          {title}
        </span>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Link onClick={handler} style={{ marginRight: "2px", whiteSpace: "nowrap" }}>
            {str}
          </Link>
          <Icon16Dropdown fill="var(--color-custom-link)" />
        </div>
      </div>
    </Div>
  );
};
