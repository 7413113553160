import {
  PROFILE_ALL_COMMENT_REQUEST,
  PROFILE_ALL_COMMENT_SUCCESS,
  PROFILE_ALL_COMMENT_ERROR,
  PROFILE_ALL_COMMENT_PROGRESS,
  PROFILE_ALL_COMMENT_CANCEL,
  PROFILE_ALL_COMMENT_SHOW_MORE,
} from "../../constType";

import { delay } from "helpers";

import { APIEye3ActiveAllPageTrackActive } from "api/eye3";
import { openModal } from "../page";
import { checkEYE3ApiError, defaultErrorProperty } from "api/eye3/responseHandler";

export function fetchAllComment() {
  return async (dispatch, getState) => {
    try {
      const state = getState().profileAllComment;
      const page = getState().page;
      const profile = getState().profile;

      const id = profile.history[page.activeStory].user.id;

      // Если предыдущий открытый профиль равен текущему открытому профилю
      if (
        state.activeUserId === profile.history[page.activeStory].user.id &&
        !state.isStopLoading
      ) {
        return false;
      }

      dispatch(request(id));

      async function process() {
        try {
          if (!getState().profileAllComment.loading) return;

          let result = [];

          const allComments = await APIEye3ActiveAllPageTrackActive({
            userId: id,
            activeType: "comment",
          });

          //console.log('allComments: ', allComments);

          // Проверка ошибок в API
          const errorAPI = checkEYE3ApiError(allComments);
          if (errorAPI !== false) {
            errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
            dispatch(error({ ...errorAPI }));
            return;
          }

          allComments.result.forEach((comment) => {
            result.push({
              vk_id: comment.vk_id,
              type: comment.type,
              _at: comment._at,
              info_link: comment.info_link,
              info_photo: comment.info_photo,
              info_text: comment.info_text,
            });
          });

          if (allComments.status_task === "work") {
            dispatch(progress(allComments.percent, result));
            await delay(2000);
            await process();
          } else if (allComments.status_task === "success") {
            dispatch(success(result));
          } else if (allComments.status_task === "error") {
            dispatch(error({ ...defaultErrorProperty, message: "", isTryAgain: false }));
          }

          return;
        } catch (e) {
          dispatch(error({ ...defaultErrorProperty, message: e, isTryAgain: false }));
        }
      }

      await process();
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(id) {
    return { type: PROFILE_ALL_COMMENT_REQUEST, id };
  }

  function progress(progressValue, payload) {
    return { type: PROFILE_ALL_COMMENT_PROGRESS, progressValue, payload };
  }

  function success(payload) {
    return { type: PROFILE_ALL_COMMENT_SUCCESS, payload };
  }

  function error(error) {
    return { type: PROFILE_ALL_COMMENT_ERROR, error };
  }
}

export function cancelAllComment() {
  return { type: PROFILE_ALL_COMMENT_CANCEL };
}

export function showMoreAllComment() {
  return { type: PROFILE_ALL_COMMENT_SHOW_MORE };
}
