import React, { useCallback, useEffect } from "react";
import { Button, Div, Group, Placeholder } from "@vkontakte/vkui";
import Icon56UsersOutline from "@vkontakte/icons/dist/56/users_outline";
import SearchResultItem from "../SFSearchResultItem/SFSearchResultItem";

import { VIEW_TOOL } from "../../../../../../constViews";
import { PANEL_SUBSCRIBER_FILTER } from "../../../../constPanels";
import {
  fetchSubscribersFilter,
  showMoreSubscribersFilter,
} from "store/actions/tool/subscribersFilter";
import { useDispatch, useSelector } from "react-redux";
import { declOfNum } from "helpers";
import { renderErrorPlaceholder, renderLoader, renderShowMoreBtn } from "helpers/views";

const SFSearchResultList = (props) => {
  const dispatch = useDispatch();
  const searchResult = useSelector((state) => state.toolSubscribersFilter.searchResult.payload);
  const showCount = useSelector((state) => state.toolSubscribersFilter.searchResult.showCount);
  const loading = useSelector((state) => state.toolSubscribersFilter.searchResult.loading);
  const error = useSelector((state) => state.toolSubscribersFilter.searchResult.error);
  const handleFetchSubscribersFilter = useCallback(
    () => dispatch(fetchSubscribersFilter()),
    [dispatch]
  );
  const handleShowMoreSubscribersFilter = useCallback(
    () => dispatch(showMoreSubscribersFilter()),
    [dispatch]
  );

  useEffect(() => {
    handleFetchSubscribersFilter();
  }, [handleFetchSubscribersFilter]);

  const renderSearchResult = () => {
    return searchResult.length > 0 ? (
      searchResult.map((user, i) => {
        const id = user.vk_id;
        const name = user.name;
        const sex = user.sex;
        const city = user.city ? user.city : "";
        const age = user.age ? user.age : "";
        const photo = user.photo_url ? user.photo_url : "";
        const ageStrFormat = age ? age + " " + declOfNum(age, ["год", "года", "лет"]) : "";
        const comma = ageStrFormat && city ? ", " : "";

        if (i < showCount) {
          return (
            <SearchResultItem
              key={i}
              id={id}
              title={name}
              sex={sex}
              description={ageStrFormat + comma + city}
              photo={photo}
              openView={props.openView}
            />
          );
        }

        return false;
      })
    ) : (
      <Placeholder icon={<Icon56UsersOutline />}>Подписчики не найдены</Placeholder>
    );
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      <Group>
        {searchResult !== null && error === null ? (
          <Div>
            {searchResult.length !== 0 && (
              <div style={{ color: "#818C99", fontSize: "13px", textTransform: "uppercase" }}>
                Найдено {searchResult.length}{" "}
                {declOfNum(searchResult, ["пользователь", "пользователя", "пользователей"])}
              </div>
            )}
          </Div>
        ) : (
          ""
        )}
        {searchResult !== null && error === null ? renderSearchResult() : ""}
        {renderShowMoreBtn({
          error: error,
          loading: loading,
          items: searchResult,
          showCount: showCount,
          maxLength: searchResult !== null ? searchResult.length : 0,
          handler: handleShowMoreSubscribersFilter,
        })}
      </Group>
      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchSubscribersFilter,
      })}
      {!loading && (
        <Div>
          <Button
            style={{ width: "100%" }}
            disabled={loading}
            onClick={() => props.openView({ view: VIEW_TOOL, panel: PANEL_SUBSCRIBER_FILTER })}
            size="xl"
            stretched
          >
            Изменить параметры фильтра
          </Button>
        </Div>
      )}
    </div>
  );
};

export default SFSearchResultList;
