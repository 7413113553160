import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import PSBanner from "./components/PSBanner/PSBanner";
import PSUserList from "./components/PSUserList/PSUserList";
import { Panel } from "@vkontakte/vkui";
import { withRouter } from "hoc";

const PanelPopularSubscribers = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Популярные подписчики</PanelHeaderWithPath>
      <PSBanner />
      <PSUserList openView={props.openView} />
    </Panel>
  );
};

export default withRouter(PanelPopularSubscribers);
