import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Group, Placeholder, Search } from "@vkontakte/vkui";
import Icon24Filter from "@vkontakte/icons/dist/24/filter";
import PUserItem from "../PUserItem/PUserItem";
import Icon56UsersOutline from "@vkontakte/icons/dist/56/users_outline";

import {
  fetchPosting,
  showMorePosting,
  searchPosting,
  filterPosting,
} from "store/actions/tool/posting";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import { declOfNum } from "helpers";
import { useDebounceSearch, useShowMore } from "hooks";
import { MODAL_PAGE_TEMPLATE_FILTER_SEX_AGE_CITY } from "template/modals/constModals";
import SecondaryTitle from "template/components/SecondaryTitle/SecondaryTitle";

const PUserList = (props) => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const friends = useSelector((state) => state.toolPosting.friends.payload);
  const isOnce = useSelector((state) => state.toolPosting.friends.isOnce);
  const loading = useSelector((state) => state.toolPosting.friends.loading);
  const error = useSelector((state) => state.toolPosting.friends.error);
  const showCount = useSelector((state) => state.toolPosting.friends.showCount);
  const search = useSelector((state) => state.toolPosting.friends.search);
  const filter = useSelector((state) => state.toolPosting.friends.filter);

  const [searchTerm, setSearchTerm] = useState(search);

  const handleFetchPosting = useCallback(() => dispatch(fetchPosting()), [dispatch]);
  const handleShowMorePosting = useCallback(() => dispatch(showMorePosting()), [dispatch]);
  const handleSearchPosting = useCallback((search) => dispatch(searchPosting(search)), [dispatch]);
  const handleFilterPosting = useCallback((filter) => dispatch(filterPosting(filter)), [dispatch]);

  useShowMore({
    items: friends,
    showCount: showCount,
    maxLength: friends !== null ? friends.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMorePosting,
  });

  useEffect(() => {
    if (!isOnce) handleFetchPosting();
  }, [handleFetchPosting, isOnce]);

  useDebounceSearch({
    search: search,
    searchTerm: searchTerm,
    handler: handleSearchPosting,
  });

  const renderFriendList = () => {
    return friends.length > 0 ? (
      friends.map((friend, i) => {
        const id = friend.vk_id;
        const name = friend.name;
        const sex = friend.sex;
        const photo = friend.photo_url ? friend.photo_url : "";
        const postCount = parseInt(friend.post_count);
        const postCountStrFormat = postCount
          ? postCount + " " + declOfNum(postCount, ["пост", "поста", "постов"])
          : "0 постов";

        if (i < showCount) {
          return (
            <PUserItem
              key={i}
              id={id}
              title={name}
              sex={sex}
              description={postCountStrFormat}
              photo={photo}
              openView={props.openView}
            />
          );
        }

        return false;
      })
    ) : (
      <Placeholder icon={<Icon56UsersOutline />}>Друзья не найдены</Placeholder>
    );
  };

  const handleFilter = () => {
    if (loading || error !== null) return;

    props.openModal({
      id: MODAL_PAGE_TEMPLATE_FILTER_SEX_AGE_CITY,
      param: {
        filter: filter,
        closeModal: props.closeModal,
        applyFilter: handleFilterPosting,
      },
    });
  };

  return (
    <>
      <SecondaryTitle style={{ marginTop: "5px" }}>
        Выберите пользователя из списка друзей или воспользуйтесь поиском.
      </SecondaryTitle>

      <Search
        value={searchTerm}
        icon={<Icon24Filter />}
        placeholder={"Имя или ссылка на страницу"}
        onIconClick={handleFilter}
        onChange={(e) => {
          if (loading || error !== null) return;

          setSearchTerm(e.target.value);
        }}
      />

      <Group style={{ marginBottom: "20px" }}>
        {friends !== null && error === null ? renderFriendList() : ""}
      </Group>
      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchPosting,
      })}
    </>
  );
};

export default PUserList;
