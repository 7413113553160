import React from "react";
import { ModalPage, ModalPageHeader } from "@vkontakte/vkui";

const Multi = (props) => {
  const modalParam = props.modalParam;
  const header = modalParam !== null ? modalParam.header : "";
  const content = modalParam !== null ? modalParam.content : "";

  return (
    <ModalPage
      id={props.id}
      header={<ModalPageHeader>{header}</ModalPageHeader>}
      settlingHeight={80}
    >
      {content}
    </ModalPage>
  );
};

export default Multi;
