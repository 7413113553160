import React from "react";
import { Div } from "@vkontakte/vkui";
import styles from "./SecondaryTitle.module.css";

const SecondaryTitle = (props) => {
  return (
    <Div style={{ paddingBottom: "0px", paddingTop: "0px" }}>
      <div className={styles.title} style={props.style}>
        {props.children}
      </div>
    </Div>
  );
};

SecondaryTitle.defaultProps = {
  style: null,
};

export default SecondaryTitle;
