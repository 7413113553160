import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Group, Placeholder } from "@vkontakte/vkui";
import Icon56RecentOutline from "@vkontakte/icons/dist/56/recent_outline";
import TOTrackingUserInfoItem from "../TOTrackingUserInfoItem/TOTrackingUserInfoItem";
import TOTrackingUserInfoTopPanel from "../TOTrackingUserInfoTopPanel/TOTrackingUserInfoTopPanel";
import SeparateFilter from "template/components/SeparateFilter/SeparateFilter";
import { unixTimeToDate, convertMonthDay, calcTimeBetween, convertTime } from "helpers";

import {
  fetchTrackingUserInfo,
  setTrackingUserInfo,
  setPeriodTrackingUserInfo,
  showMoreTrackingUserInfo,
} from "store/actions/tool/timeOnline";

import { PANEL_TO__TRACKING_USER_INFO } from "../../../../constPanels";

import { renderPeriodV2 } from "template/popouts/DateChangePeriod/views";
import PopoutTemplateDateChangePeriod from "template/popouts/DateChangePeriod/DateChangePeriod";
import { VIEW_TOOL } from "views/constViews";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import { useShowMore } from "hooks";

const TOTrackingUserInfoList = (props) => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const handleFetchTrackingUserInfo = useCallback(
    () => dispatch(fetchTrackingUserInfo()),
    [dispatch]
  );
  const handleShowMoreTrackingUserInfo = useCallback(
    () => dispatch(showMoreTrackingUserInfo()),
    [dispatch]
  );
  const handleSetTrackingUserInfo = useCallback(
    (id, isEnable) => dispatch(setTrackingUserInfo(id, isEnable)),
    [dispatch]
  );
  const handleSetPeriodTrackingUserInfo = useCallback(
    (name, atMin = null, atMax = null) => dispatch(setPeriodTrackingUserInfo(name, atMin, atMax)),
    [dispatch]
  );
  const activeUserId = useSelector((state) => state.toolTimeOnline.trackingUserInfo.activeUserId);
  const activeUserIsTracking = useSelector(
    (state) => state.toolTimeOnline.trackingUserInfo.activeUserIsTracking
  );
  const activeUserTypeTracking = useSelector(
    (state) => state.toolTimeOnline.trackingUserInfo.activeUserTypeTracking
  );
  const activeUserTitle = useSelector(
    (state) => state.toolTimeOnline.trackingUserInfo.activeUserTitle
  );
  const activeUserDescription = useSelector(
    (state) => state.toolTimeOnline.trackingUserInfo.activeUserDescription
  );
  const activeUserPhoto = useSelector(
    (state) => state.toolTimeOnline.trackingUserInfo.activeUserPhoto
  );
  const period = useSelector((state) => state.toolTimeOnline.trackingUserInfo.period);
  const trackingUserInfo = useSelector((state) => state.toolTimeOnline.trackingUserInfo.payload);
  const loading = useSelector((state) => state.toolTimeOnline.trackingUserInfo.loading);
  const error = useSelector((state) => state.toolTimeOnline.trackingUserInfo.error);
  const showCount = useSelector((state) => state.toolTimeOnline.trackingUserInfo.showCount);

  let lastAt = new Date();

  useShowMore({
    items: trackingUserInfo,
    showCount: showCount,
    maxLength: trackingUserInfo !== null ? trackingUserInfo.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMoreTrackingUserInfo,
  });

  useEffect(() => {
    handleFetchTrackingUserInfo();
  }, [handleFetchTrackingUserInfo, period]);

  const renderUser = () => {
    return (
      <TOTrackingUserInfoTopPanel
        isTracking={activeUserIsTracking}
        error={error}
        typeTracking={activeUserTypeTracking}
        openView={props.openView}
        openPopout={props.openPopout}
        closePopout={props.closePopout}
        id={activeUserId}
        title={activeUserTitle}
        description={activeUserDescription}
        photo={activeUserPhoto}
        handleTracking={handleSetTrackingUserInfo}
      />
    );
  };

  const timeSecToMinHourStr = (time) => {
    let minutes = Math.round(time / 60);
    return minutes + " мин ";
  };

  const renderTrackingUserInfo = () => {
    return trackingUserInfo.length > 0 ? (
      trackingUserInfo.map((user, i) => {
        const periodStart = unixTimeToDate(user.period_start);
        const periodEnd = unixTimeToDate(user.period_end);
        const periodBetween = calcTimeBetween(periodStart, periodEnd);
        const periodIsMobile = user.period_is_mobile;
        const periodSecMsg = user.period_sec_msg;
        const periodSecContent = user.period_sec_content;

        let separate = <SeparateFilter>{convertMonthDay(periodEnd)}</SeparateFilter>;

        if (lastAt.getDate() === periodEnd.getDate() && i !== 0) {
          separate = "";
        }

        lastAt = periodEnd;

        if (i < showCount) {
          return (
            <div key={i}>
              {separate}
              <TOTrackingUserInfoItem
                key={i}
                periodBetween={periodBetween}
                isOnlineMobile={periodIsMobile}
                periodMsg={timeSecToMinHourStr(periodSecMsg)}
                periodContent={timeSecToMinHourStr(periodSecContent)}
                widthMsgLine={Math.round((periodSecMsg / 60) * 2)}
                widthContentLine={Math.round((periodSecContent / 60) * 2)}
              >
                {convertTime(periodStart) + " – " + convertTime(periodEnd)}
              </TOTrackingUserInfoItem>
            </div>
          );
        }

        return false;
      })
    ) : (
      <Placeholder icon={<Icon56RecentOutline />}>
        Время онлайн за данный период отсутствует
      </Placeholder>
    );
  };

  const handlePeriod = () => {
    if (loading) return false;

    props.openPopout({
      popout: (
        <PopoutTemplateDateChangePeriod
          openView={props.openView}
          period={period} // Период из redux store
          setPeriod={handleSetPeriodTrackingUserInfo} // action из redux
          backView={VIEW_TOOL}
          backPanel={PANEL_TO__TRACKING_USER_INFO}
          panelTitle={"Время онлайн"}
          closePopout={props.closePopout}
        />
      ),
    });
  };

  return (
    <div>
      {renderPeriodV2({ title: "Время онлайн", period: period, handler: handlePeriod })}
      {renderUser()}
      <Group style={{ marginBottom: "20px" }}>
        {trackingUserInfo !== null && error === null ? renderTrackingUserInfo() : ""}
        {renderLoader({ loading: loading })}
      </Group>
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchTrackingUserInfo,
      })}
    </div>
  );
};

export default TOTrackingUserInfoList;
