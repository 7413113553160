import React, { useCallback } from "react";
import { useSelector } from "react-redux";

import TrackingOptionModal from "template/modals/TrackingOption/TrackingOption";
import TariffWorkModal from "template/modals/TariffWork/TariffWork";
import TariffLimitModal from "template/modals/TariffLimit/TariffLimit";
import FilterSexAgeCity from "template/modals/FilterSexAgeCity/FilterSexAgeCity";
import FilterAgeCity from "template/modals/FilterAgeCity/FilterAgeCity";
import MutualFriend from "template/modals/MutualFriend/MutualFriend";
import MutualCommunity from "template/modals/MutualCommunity/MutualCommunity";
import Multi from "template/modals/Multi/Multi";

import {
  MODAL_PAGE_TEMPLATE_FILTER_SEX_AGE_CITY,
  MODAL_PAGE_TEMPLATE_FILTER_AGE_CITY,
  MODAL_PAGE_TEMPLATE_TRACKING_OPTION,
  MODAL_CARD_TEMPLATE_TARIFF_WORK,
  MODAL_CARD_TEMPLATE_TARIFF_LIMIT,
  MODAL_PAGE_TEMPLATE_MUTUAL_FRIEND,
  MODAL_PAGE_TEMPLATE_MUTUAL_COMMUNITY,
  MODAL_PAGE_TEMPLATE_MULTI,
} from "template/modals/constModals";

import {
  MODAL_CARD_TOOL_LIST_COMMUNITY,
  MODAL_CARD_TOOL_LIST_FRIENDS,
  MODAL_CARD_TOOL_LIST_HISTORIES,
  MODAL_CARD_TOOL_LIST_MY_ACCOUNT,
  MODAL_CARD_TOOL_LIST_SUBSCRIBERS,
} from "./constModals";

import MenuToolListMyAccountModal from "./MenuToolListMyAccountModal/MenuToolListMyAccountModal";
import MenuToolListFriendsModal from "./MenuToolListFriendsModal/MenuToolListFriendsModal";
import MenuToolListSubscribersModal from "./MenuToolListSubscribersModal/MenuToolListSubscribersModal";
import MenuToolListCommunityModal from "./MenuToolListCommunityModal/MenuToolListCommunityModal";
import MenuToolListHistoriesModal from "./MenuToolListHistoriesModal/MenuToolListHistoriesModal";

import { ModalRoot } from "@vkontakte/vkui";

// Профиль
import ProfileMutualFriendModal from "views/profile/modals/ProfileMutualFriendModal/ProfileMutualFriendModal";
import ProfileMutualCommunityModal from "views/profile/modals/ProfileMutualCommunityModal/ProfileMutualCommunityModal";
import HoroscopeDateBirthModal from "views/profile/modals/HoroscopeDateBirthModal/HoroscopeDateBirthModal";

import {
  MODAL_PAGE_MUTUAL_FRIEND,
  MODAL_PAGE_MUTUAL_COMMUNITY,
  MODAL_CARD_HOROSCOPE_DATE_BIRTH,
} from "views/profile/modals/constModals";
//

import { useDispatch } from "react-redux";
import { closeModal, openStory, openView } from "store/actions/page";

const ModalList = () => {
  const dispatch = useDispatch();
  const handleOpenStory = useCallback(
    ({ story, panel }) => dispatch(openStory({ story: story, panel: panel })),
    [dispatch]
  );
  const handleCloseModal = useCallback(() => dispatch(closeModal()), [dispatch]);
  const handleOpenView = useCallback(
    ({ view, panel, param = null }) =>
      dispatch(openView({ view: view, panel: panel, param: param })),
    [dispatch]
  );
  const modalParam = useSelector((state) => state.page.modalParam);
  const activeModal = useSelector((state) =>
    state.page.activeModal[state.page.activeView] === undefined
      ? null
      : state.page.activeModal[state.page.activeView]
  );

  return (
    <ModalRoot activeModal={activeModal} onClose={handleCloseModal}>
      <TrackingOptionModal id={MODAL_PAGE_TEMPLATE_TRACKING_OPTION} modalParam={modalParam} />
      <FilterSexAgeCity id={MODAL_PAGE_TEMPLATE_FILTER_SEX_AGE_CITY} modalParam={modalParam} />
      <FilterAgeCity id={MODAL_PAGE_TEMPLATE_FILTER_AGE_CITY} modalParam={modalParam} />
      <MutualFriend id={MODAL_PAGE_TEMPLATE_MUTUAL_FRIEND} modalParam={modalParam} />
      <MutualCommunity id={MODAL_PAGE_TEMPLATE_MUTUAL_COMMUNITY} modalParam={modalParam} />
      <Multi id={MODAL_PAGE_TEMPLATE_MULTI} modalParam={modalParam} />

      <MenuToolListMyAccountModal
        openView={handleOpenView}
        id={MODAL_CARD_TOOL_LIST_MY_ACCOUNT}
        modalParam={modalParam}
        handleCloseModal={handleCloseModal}
      />
      <MenuToolListHistoriesModal
        openView={handleOpenView}
        id={MODAL_CARD_TOOL_LIST_HISTORIES}
        modalParam={modalParam}
        handleCloseModal={handleCloseModal}
      />
      <MenuToolListFriendsModal
        openView={handleOpenView}
        id={MODAL_CARD_TOOL_LIST_FRIENDS}
        modalParam={modalParam}
        handleCloseModal={handleCloseModal}
      />
      <MenuToolListSubscribersModal
        openView={handleOpenView}
        id={MODAL_CARD_TOOL_LIST_SUBSCRIBERS}
        modalParam={modalParam}
        handleCloseModal={handleCloseModal}
      />
      <MenuToolListCommunityModal
        openView={handleOpenView}
        id={MODAL_CARD_TOOL_LIST_COMMUNITY}
        modalParam={modalParam}
        handleCloseModal={handleCloseModal}
      />
      <TariffWorkModal
        id={MODAL_CARD_TEMPLATE_TARIFF_WORK}
        openStory={handleOpenStory}
        handleCloseModal={handleCloseModal}
        modalParam={modalParam}
      />
      <TariffLimitModal
        id={MODAL_CARD_TEMPLATE_TARIFF_LIMIT}
        openStory={handleOpenStory}
        handleCloseModal={handleCloseModal}
        modalParam={modalParam}
      />

      {/* Профиль */}

      <ProfileMutualFriendModal id={MODAL_PAGE_MUTUAL_FRIEND} />
      <ProfileMutualCommunityModal id={MODAL_PAGE_MUTUAL_COMMUNITY} />
      <HoroscopeDateBirthModal
        id={MODAL_CARD_HOROSCOPE_DATE_BIRTH}
        modalParam={modalParam}
        handleCloseModal={handleCloseModal}
      />

      {/* /Профиль */}
    </ModalRoot>
  );
};

export default ModalList;
