import React from "react";
import { addVKScope, checkVKMethodError } from "api";
import Icon56LockOutline from "@vkontakte/icons/dist/56/lock_outline";
import {
  DOWNLOAD_HISTORY_REQUEST,
  DOWNLOAD_HISTORY_SUCCESS,
  DOWNLOAD_HISTORY_ERROR,
  DOWNLOAD_HISTORY__INFO_REQUEST,
  DOWNLOAD_HISTORY__INFO_SUCCESS,
  DOWNLOAD_HISTORY__INFO_ERROR,
  DOWNLOAD_HISTORY_SEARCH_REQUEST,
  DOWNLOAD_HISTORY_SEARCH_SUCCESS,
  DOWNLOAD_HISTORY_SEARCH_ERROR,
} from "../../constType";
import { APIVkGetStories, APIVkGetUsers } from "api/vk/apiMethods";
import { defaultErrorProperty } from "api/eye3/responseHandler";
import { STORY_TYPE_SEARCH } from "views/tool/panels/downloadHistory/constStoryType";

function getPreviewUrl(s) {
  if (s.hasOwnProperty("photo"))
    return s.photo.sizes
      .filter((size) => size.type === "j")
      .map((size) => size.url)
      .join("");
  else if (s.hasOwnProperty("video"))
    return s.video.image
      .filter((size) => size.width === 160)
      .map((size) => size.url)
      .join("");
  else return "";
}

function getLink(s) {
  if (s.hasOwnProperty("photo"))
    return s.photo.sizes
      .filter((size) => size.type === "w")
      .map((size) => size.url)
      .join("");
  else if (s.hasOwnProperty("video")) {
    if (s.video.files.hasOwnProperty("mp4_1080")) return s.video.files.mp4_1080;
    else if (s.video.files.hasOwnProperty("mp4_720")) return s.video.files.mp4_720;
    else if (s.video.files.hasOwnProperty("mp4_480")) return s.video.files.mp4_480;
    else if (s.video.files.hasOwnProperty("mp4_360")) return s.video.files.mp4_360;
    else if (s.video.files.hasOwnProperty("mp4_240")) return s.video.files.mp4_240;
    else if (s.video.files.hasOwnProperty("mp4_144")) return s.video.files.mp4_144;
    else return null;
  }
}

/*async function getStoriesFriends(dispatch, getState) {
    try {
        let result = [];

        const friends = await getFriendsComparePopular({
            friends: getState().friend.payload,
            fields: ['photo_100'],
            isSelf: true
        });

        const friendsItems = friends.response.hasOwnProperty('items') ? 
        friends.response.items : friends.response;

        //console.log('friendsItems: ', friendsItems);

        let friendsIds = friendsItems.map((friend) => friend.id);
        //console.log('friendsIds: ', friendsIds);

        let loadLength = friendsIds.length;
        let loadDone = 0;
        let minCounter = 0;
        let maxCounter = friendsIds.length < 24 ? friendsIds.length : 24;

        async function process() {
            if (!getState().toolDownloadHistory.stories.loading)
                return result;

            let chunkItems = [];

            for (let i = minCounter; i < maxCounter; i++) {
                chunkItems.push(friendsIds[i]);
                minCounter++;
                loadDone++;
            }

            maxCounter += 24;

            if (maxCounter >= friendsIds.length)
                maxCounter = friendsIds.length;

            console.log('minCounter: ', minCounter);
            console.log('maxCounter: ', maxCounter);

            const executeStories = await APIVkExecute({
                code: `
                    var ids = [${chunkItems}];
                    var result = [];
                    var i = 0;
                    while (i < ids.length) {
                        var method = API.stories.get({
                            owner_id: ids[i]
                        });

                        result.push(method);
        
                        i = i + 1;
                    }
        
                    return result;
                `
            });

            console.log('executeStories: ', executeStories);

            if (executeStories.hasOwnProperty('execute_errors'))
                return result
            else if (!executeStories.hasOwnProperty('response'))
                return result;
            else if (executeStories.response === null)
                return result;

            let stories = [];

            for (let storyObj of executeStories.response) {

                if (storyObj === false) {
                    return result;
                }

                for (let story of storyObj.items) {
                    stories.push(...story.stories);
                }
            }

            //console.log('stories: ', stories);
            
            for (let friend of friendsItems) {

                let storiesFriend = [];

                for (let story of stories) {
                    if (story.owner_id === friend.id) {
                        storiesFriend.push({
                            id: story.id,
                            type: story.type,
                            photo_url: getPreviewUrl(story),
                            link: getLink(story)
                        });
                    }
                }

                if (storiesFriend.length !== 0) {
                    result.push({
                        vk_id: friend.id,
                        name: friend.first_name + ' ' + friend.last_name,
                        photo_url: friend.photo_100,
                        count: storiesFriend.length,
                        stories: storiesFriend
                    });
                }
            }

            const progressValue = (loadDone * 100) / loadLength;

            dispatch(progress(Math.round(progressValue)));

            if (minCounter < friendsIds.length) {
                await delay(400);
                await process();
            }
        }

        await process();

        return result;
    }
    catch (e) {
        throw new Error('getStoriesFriends::: ', e);
    }
}*/

/*async function getStoriesGroups(dispatch, getState) {
    try {

        let result = [];

        const groups = await APIVkGetGroups({userId: getSearchObject().vk_user_id});

        const groupsItems = groups.response.hasOwnProperty('items') ? 
        groups.response.items : groups.response;
        //console.log('groupsItems: ', groupsItems);

        let groupsIds = groupsItems.map((group) => group.id);
        //console.log('groupsIds: ', groupsIds);

        let loadLength = groupsIds.length;
        let loadDone = 0;
        let minCounter = 0;
        let maxCounter = groupsIds.length < 24 ? groupsIds.length : 24;

        async function process() {
            if (!getState().toolDownloadHistory.stories.loading)
                return result;

            let chunkItems = [];

            for (let i = minCounter; i < maxCounter; i++) {
                chunkItems.push(groupsIds[i]);
                minCounter++;
                loadDone++;
            }

            maxCounter += 24;

            if (maxCounter >= groupsIds.length)
                maxCounter = groupsIds.length;

            console.log('minCounter: ', minCounter);
            console.log('maxCounter: ', maxCounter);

            const executeStories = await APIVkExecute({
                code: `
                    var ids = [${chunkItems}];
                    var result = [];
                    var i = 0;
                    while (i < ids.length) {
                        var method = API.stories.get({
                            owner_id: -ids[i]
                        });

                        result.push(method);
        
                        i = i + 1;
                    }
        
                    return result;
                `
            });

            console.log('executeStories: ', executeStories);

            if (executeStories.hasOwnProperty('execute_errors'))
                return result
            else if (!executeStories.hasOwnProperty('response'))
                return result;
            else if (executeStories.response === null)
                return result;

            let stories = [];

            for (let storyObj of executeStories.response) {

                if (storyObj === false) {
                    return result;
                }

                for (let story of storyObj.items) {
                    stories.push(...story.stories);
                }
            }

            //console.log('stories: ', stories);
            
            for (let group of groupsItems) {

                let storiesGroup = [];

                for (let story of stories) {
                    if (Math.abs(story.owner_id) === group.id) {
                        storiesGroup.push({
                            id: story.id,
                            type: story.type,
                            photo_url: getPreviewUrl(story),
                            link: getLink(story)
                        });
                    }
                }

                if (storiesGroup.length !== 0) {
                    result.push({
                        vk_id: group.id,
                        name: group.name,
                        photo_url: group.photo_100,
                        count: storiesGroup.length,
                        stories: storiesGroup
                    });
                }
            }

            const progressValue = (loadDone * 100) / loadLength;

            dispatch(progress(Math.round(progressValue)));

            if (minCounter < groupsIds.length) {
                await delay(400);
                await process();
            }
        }

        await process();

        return result;
    }
    catch (e) {
        throw new Error('getStoriesGroups::: ', e);
    }
}*/

function getStoryData(type, entities, stories) {
  let result = [];

  for (let entity of entities) {
    let storiesEntity = [];

    for (let story of stories) {
      const ownerId = type === "groups" ? Math.abs(story.owner_id) : story.owner_id;

      if (ownerId === entity.id) {
        storiesEntity.push({
          id: story.id,
          type: story.type,
          photo_url: getPreviewUrl(story),
          link: getLink(story),
        });
      }
    }

    if (storiesEntity.length !== 0) {
      const name = type === "groups" ? entity.name : entity.first_name + " " + entity.last_name;

      result.push({
        vk_id: entity.id,
        name: name,
        photo_url: entity.photo_100,
        count: storiesEntity.length,
        stories: storiesEntity,
      });
    }
  }

  return result;
}

async function getStoriesFriendsAndGroups() {
  try {
    let result = {
      friends: [],
      groups: [],
    };

    const storiesAPI = await APIVkGetStories({
      fields: ["photo_100"].join(","),
      extended: 1,
    });

    //console.log('storiesAPI: ', storiesAPI);

    if (checkVKMethodError(storiesAPI)) {
      return result;
    }

    const groups = storiesAPI.response.groups;
    let stories = [];
    const items = storiesAPI.response.items.filter((story) => story.type === "stories");
    const friends = storiesAPI.response.profiles;

    for (let story of items) {
      stories.push(...story.stories);
    }

    /*console.log('groups: ', groups);
        console.log('friends: ', friends);
        console.log('stories: ', stories);*/

    result.friends = getStoryData("friends", friends, stories);
    result.groups = getStoryData("groups", groups, stories);
    // console.log('result: ', result);

    return result;
  } catch (e) {
    throw new Error("getStoriesFriendsAndGroups::: ", e);
  }
}

function checkSearchURL(search) {
  const searchRegExp = /vk\.com\/id(\d+)|vk\.com\/([a-zA-Z0-9._]+)/;
  let searchValue = "";

  if (search !== "" && searchRegExp.test(search)) {
    let matchValues = search.match(searchRegExp);

    if (matchValues[1] !== null && matchValues[1] !== undefined) {
      searchValue = matchValues[1];
    } else if (matchValues[2] !== null && matchValues[2] !== undefined) {
      searchValue = matchValues[2];
    }
  }

  return searchValue;
}

async function getStoriesSearch(search) {
  try {
    let result = [];

    let searchValue = checkSearchURL(search);
    console.log("searchValue:", searchValue);

    if (searchValue === "") return result;

    let userItem = null;
    const userInfo = await APIVkGetUsers({
      userIds: searchValue,
      fields: ["photo_100"].join(","),
    });

    console.log("userInfo: ", userInfo);

    if (checkVKMethodError(userInfo)) {
      return result;
    }

    userItem = userInfo.response[0];

    console.log("userItem: ", userItem);

    if (userItem !== null) {
      const storiesAPI = await APIVkGetStories({
        ownerId: userItem.id,
        fields: ["photo_100"].join(","),
        extended: 1,
      });

      console.log("storiesAPI: ", storiesAPI);

      if (checkVKMethodError(storiesAPI)) {
        return result;
      }

      let stories = [];
      const items = storiesAPI.response.items.filter((story) => story.type === "stories");
      const friends = storiesAPI.response.profiles;

      for (let story of items) {
        stories.push(...story.stories);
      }

      result = getStoryData("friends", friends, stories);
    }

    console.log("result: ", result);

    return result;
  } catch (e) {
    throw new Error("getStoriesSearch::: " + e);
  }
}

export function fetchDownloadHistory() {
  return async (dispatch, getState) => {
    try {
      await dispatch(request());

      if (!(await addVKScope("stories"))) {
        // Проверка на разрешение stories
        dispatch(
          error({
            ...defaultErrorProperty,
            message: "Для скачивания требуется доступ к историям",
            icon: <Icon56LockOutline />,
            isVisibleMessage: true,
          })
        );

        return;
      }

      let result = await getStoriesFriendsAndGroups();

      await dispatch(success(result));

      /*const storiesFriends = await getStoriesFriends(dispatch, getState);
            console.log('storiesFriends: ', storiesFriends);
            result.friends = storiesFriends;

            const storiesGroups = await getStoriesGroups(dispatch, getState);
            console.log('storiesGroups: ', storiesGroups);
            result.groups = storiesGroups;*/

      /*if (getState().toolDownloadHistory.stories.error === null)
                await dispatch(success(result));*/
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: DOWNLOAD_HISTORY_REQUEST };
  }

  function success(payload) {
    return { type: DOWNLOAD_HISTORY_SUCCESS, payload };
  }

  function error(error) {
    return { type: DOWNLOAD_HISTORY_ERROR, error };
  }
}

export function searchDownloadHistory(search) {
  return async (dispatch, getState) => {
    try {
      dispatch(request(search));

      const result = await getStoriesSearch(search);

      dispatch(success(result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(search) {
    return { type: DOWNLOAD_HISTORY_SEARCH_REQUEST, search };
  }

  function success(payload) {
    return { type: DOWNLOAD_HISTORY_SEARCH_SUCCESS, payload };
  }

  function error(error) {
    return { type: DOWNLOAD_HISTORY_SEARCH_ERROR, error };
  }
}

export function fetchInfoDownload() {
  return async (dispatch, getState) => {
    try {
      const state = getState().toolDownloadHistory;
      const pageParam = getState().page.param;

      const id = pageParam ? pageParam.activeId : state.downloads.activeId;
      const storyType = pageParam ? pageParam.activeStoryType : state.downloads.activeStoryType;

      dispatch(request(id, storyType));

      if (storyType === STORY_TYPE_SEARCH) {
        const storyData = getState().toolDownloadHistory.storiesSearch.payload;
        const stories = [...storyData[0].stories];
        dispatch(success(stories));
      } else {
        const storyData = getState().toolDownloadHistory.stories.payload[storyType].filter(
          (story) => story.vk_id === id
        );
        const stories = [...storyData[0].stories];
        dispatch(success(stories));
      }
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(id, storyType) {
    return { type: DOWNLOAD_HISTORY__INFO_REQUEST, id, storyType };
  }

  function success(payload) {
    return { type: DOWNLOAD_HISTORY__INFO_SUCCESS, payload };
  }

  function error(error) {
    return { type: DOWNLOAD_HISTORY__INFO_ERROR, error };
  }
}
