import React, { useState } from "react";
import { Button, Panel } from "@vkontakte/vkui";
import CrushImg from "assets/img/education/step1/crush.png";
import { PANEL_STEP2 } from "../constPanels";
import { VIEW_EDUCATION } from "views/constViews";
import { withRouter } from "hoc";
import EducationStepWithLogo from "views/education/components/EducationStepWithLogo/EducationStepWithLogo";
import { CSSTransition } from "react-transition-group";
import styles from "./step1.module.scss";
import { useEffect } from "react";

const PanelStep1 = (props) => {
  const [inAnimation, setInAnimation] = useState(false);

  const next = () => {
    props.openView({ view: VIEW_EDUCATION, panel: PANEL_STEP2 });
  };

  useEffect(() => {
    setInAnimation(true);
  }, []);

  return (
    <Panel id={props.id}>
      <EducationStepWithLogo
        header={
          <CSSTransition
            in={inAnimation}
            timeout={200}
            classNames={{
              enterActive: styles.enterActive,
              enterDone: styles.enterDone,
            }}
          >
            <div>Добро пожаловать в приложение КрашеLove!</div>
          </CSSTransition>
        }
        description={
          <CSSTransition
            in={inAnimation}
            timeout={500}
            classNames={{
              enterActive: styles.enterActive,
              enterDone: styles.enterDone,
            }}
          >
            <div>
              Пройди короткое обучение<br></br> и получи 50 монет
            </div>
          </CSSTransition>
        }
        icon={<img src={CrushImg} width={200} height={234} alt=""></img>}
        action={
          <CSSTransition
            in={inAnimation}
            timeout={1000}
            classNames={{
              enterActive: styles.enterActive,
              enterDone: styles.enterDone,
            }}
          >
            <Button mode="primary" size="xl" style={{ width: "100%" }} onClick={next}>
              Пройти обучение
            </Button>
          </CSSTransition>
        }
      />
    </Panel>
  );
};

export default withRouter(PanelStep1);
