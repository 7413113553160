import React, { useCallback } from "react";
import { View } from "@vkontakte/vkui";
import { useDispatch, useSelector } from "react-redux";

import { PANEL_MAIN, PANEL_BOT_CONNECT, PANEL_NOT_SUPPORTED } from "./panels/constPanels";

import { onTransitionPage } from "store/actions/page";

import PanelMain from "./panels/main";
import PanelBotConnect from "./panels/botConnect/";
import PanelNotSupported from "./panels/notSupported/";

import { PANEL_TEMPLATE_DATE_CHANGE_PERIOD } from "template/panels/constPanels";

import PanelTemplateDateChangePeriod from "../../template/panels/DateChangePeriod/DateChangePeriod";

import {
  PANEL_PROFILE,
  PANEL_PROFILE_VIEW_PAGE,
  PANEL_PROFILE_VIEW_STORIES,
  PANEL_PROFILE_LIKE,
  PANEL_PROFILE_COMMENT,
  PANEL_PROFILE_TIME_ONLINE,
  PANEL_PROFILE_INFO_HISTORY,
  PANEL_PROFILE_MARITAL_STATUS,
  PANEL_PROFILE_FRIEND_HISTORY,
  PANEL_PROFILE_JOIN_COMMUNITY,
  PANEL_PROFILE_ALL_COMMENT,
  PANEL_PROFILE_ALL_LIKE,
  PANEL_PROFILE_GIFT,
  PANEL_PROFILE_HOROSCOPE,
  PANEL_PROFILE_PSYCHOTYPE,
} from "views/profile/panels/constPanels";

import PanelProfile from "views/profile/panels/profile";
import PanelProfileViewPage from "views/profile/panels/viewPage";
import PanelProfileViewStories from "views/profile/panels/viewStories";
import PanelProfileLike from "views/profile/panels/like";
import PanelProfileComment from "views/profile/panels/comment";
import PanelProfileTimeOnline from "views/profile/panels/timeOnline";
import PanelProfileInfoHistory from "views/profile/panels/infoHistory";
import PanelProfileMaritalStatus from "views/profile/panels/maritalStatus";
import PanelProfileFriendHistory from "views/profile/panels/friendHistory";
import PanelProfileJoinCommunity from "views/profile/panels/joinCommunity";
import PanelProfileAllComment from "views/profile/panels/allComment";
import PanelProfileAllLike from "views/profile/panels/allLike";
import PanelProfileGift from "views/profile/panels/gift";
import PanelProfileHoroscope from "views/profile/panels/horoscope";
import PanelProfilePsychotype from "views/profile/panels/psychotype";
import ModalList from "./modals";

const GuestView = (props) => {
  const dispatch = useDispatch();

  const activePanel = useSelector((state) => state.page.activePanel);
  const history = useSelector((state) =>
    state.page.panelsHistory[state.page.activeView] === undefined
      ? [state.page.activeView]
      : state.page.panelsHistory[state.page.activeView]
  );

  const handleOnTransitionPage = useCallback(
    (param) => dispatch(onTransitionPage(param)),
    [dispatch]
  );

  return (
    <View
      id={props.id}
      activePanel={activePanel}
      history={history}
      onTransition={handleOnTransitionPage}
      modal={<ModalList />}
    >
      {/* Профиль */}

      <PanelProfile id={PANEL_PROFILE} />
      <PanelProfileViewPage id={PANEL_PROFILE_VIEW_PAGE} />
      <PanelProfileViewStories id={PANEL_PROFILE_VIEW_STORIES} />
      <PanelProfileLike id={PANEL_PROFILE_LIKE} />
      <PanelProfileComment id={PANEL_PROFILE_COMMENT} />
      <PanelProfileTimeOnline id={PANEL_PROFILE_TIME_ONLINE} />
      <PanelProfileInfoHistory id={PANEL_PROFILE_INFO_HISTORY} />
      <PanelProfileMaritalStatus id={PANEL_PROFILE_MARITAL_STATUS} />
      <PanelProfileFriendHistory id={PANEL_PROFILE_FRIEND_HISTORY} />
      <PanelProfileJoinCommunity id={PANEL_PROFILE_JOIN_COMMUNITY} />
      <PanelProfileAllComment id={PANEL_PROFILE_ALL_COMMENT} />
      <PanelProfileAllLike id={PANEL_PROFILE_ALL_LIKE} />
      <PanelProfileGift id={PANEL_PROFILE_GIFT} />
      <PanelProfileHoroscope id={PANEL_PROFILE_HOROSCOPE} />
      <PanelProfilePsychotype id={PANEL_PROFILE_PSYCHOTYPE} />

      {/* /Профиль */}

      <PanelMain id={PANEL_MAIN} />
      <PanelBotConnect id={PANEL_BOT_CONNECT} />
      <PanelNotSupported id={PANEL_NOT_SUPPORTED} />
      <PanelTemplateDateChangePeriod id={PANEL_TEMPLATE_DATE_CHANGE_PERIOD} />
    </View>
  );
};

export default GuestView;
