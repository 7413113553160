import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Div, Group, Placeholder } from "@vkontakte/vkui";
import TOUserItem from "../TOUserItem/TOUserItem";
import Icon24AddOutline from "@vkontakte/icons/dist/24/add_outline";

import SmileIcon from "assets/img/tool/timeOnline/smile1.png";

import { fetchTrackingUser } from "store/actions/tool/timeOnline";

import { declOfNum } from "helpers";

import { unixTimeToDate, calcLastOnlineTime } from "helpers";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import { PANEL_TO__START_TRACKING } from "views/tool/panels/constPanels";
import { VIEW_TOOL } from "views/constViews";

const TOUserList = (props) => {
  const dispatch = useDispatch();

  const handleFetchTrackingUser = useCallback(() => dispatch(fetchTrackingUser()), [dispatch]);
  const trackingUser = useSelector((state) => state.toolTimeOnline.trackingUser.payload);
  const loading = useSelector((state) => state.toolTimeOnline.trackingUser.loading);
  const error = useSelector((state) => state.toolTimeOnline.trackingUser.error);

  useEffect(() => {
    handleFetchTrackingUser();
  }, [handleFetchTrackingUser]);

  const renderTrackingUser = () => {
    return trackingUser.length > 0 ? (
      trackingUser.map((user, i) => {
        const id = user.vk_id;
        const name = user.name;
        const type = user.type;
        const isOnline = user.online === 1 ? "онлайн" : "";
        const lastSeen = user.last_seen ? user.last_seen : {};
        const lastSeenPlatform = lastSeen.hasOwnProperty("platform") ? lastSeen.platform : 7;
        const lastSeenTime = lastSeen.hasOwnProperty("time")
          ? calcLastOnlineTime(unixTimeToDate(lastSeen.time))
          : "давно не был(а)";
        const photo = user.photo_url ? user.photo_url : "";
        const city = user.city ? user.city : "";
        const age = user.age ? user.age : "";
        const ageStrFormat = age ? age + " " + declOfNum(age, ["год", "года", "лет"]) : "";
        const comma = ageStrFormat && city ? ", " : "";

        return (
          <TOUserItem
            key={i}
            id={id}
            title={name}
            info={ageStrFormat + comma + city}
            description={isOnline ? isOnline : lastSeenTime}
            photo={photo}
            type={type}
            online={user.online}
            platform={lastSeenPlatform}
            openView={props.openView}
          />
        );
      })
    ) : (
      <Placeholder icon={<img src={SmileIcon} width={50} height={50} alt=""></img>}>
        Вы не добавили ни одного пользователя для отслеживания времени онлайн
      </Placeholder>
    );
  };

  return (
    <Group style={{ marginBottom: "20px" }}>
      {trackingUser !== null && error === null ? renderTrackingUser() : ""}
      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchTrackingUser,
      })}
      {!loading && error === null && (
        <Div>
          <Button
            size="l"
            onClick={() => props.openView({ view: VIEW_TOOL, panel: PANEL_TO__START_TRACKING })}
            style={{ width: "100%" }}
            before={<Icon24AddOutline />}
          >
            Добавить на отслеживание
          </Button>
        </Div>
      )}
    </Group>
  );
};

export default TOUserList;
