import {
  DELETED_FRIENDS_REQUEST,
  DELETED_FRIENDS_SUCCESS,
  DELETED_FRIENDS_ERROR,
  DELETED_FRIENDS_SHOW_MORE,
  DELETED_FRIENDS_SEARCH_REQUEST,
  DELETED_FRIENDS_SEARCH_SUCCESS,
  DELETED_FRIENDS_SEARCH_ERROR,
  DELETED_FRIENDS_FILTER_REQUEST,
  DELETED_FRIENDS_FILTER_SUCCESS,
  DELETED_FRIENDS_FILTER_ERROR,
} from "../../constType";

const initialState = {
  friends: {
    payload: null,
    cachePayload: null,
    showCount: 20,
    filter: "",
    isOnce: false,
    search: "",
    error: null,
    loading: false,
  },
};

export default function deletedFriendsReducer(state = initialState, action) {
  switch (action.type) {
    case DELETED_FRIENDS_REQUEST:
      return {
        ...state,
        friends: {
          ...state.friends,
          showCount: 20,
          payload: null,
          cachePayload:
            state.friends.search === "" && state.friends.filter === ""
              ? null
              : state.friends.cachePayload,
          isOnce: true,
          loading: true,
        },
      };
    case DELETED_FRIENDS_SUCCESS:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: action.payload,
          cachePayload:
            state.friends.search === "" && state.friends.filter === ""
              ? action.payload // Если поле поиска пустое то сохранить результат
              : state.friends.cachePayload,
          loading: false,
        },
      };
    case DELETED_FRIENDS_ERROR:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          error: action.error,
        },
      };
    case DELETED_FRIENDS_SHOW_MORE:
      return {
        ...state,
        friends: {
          ...state.friends,
          showCount: parseInt(state.friends.showCount) + 20,
        },
      };
    case DELETED_FRIENDS_SEARCH_REQUEST:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: null,
          showCount: 20,
          search: action.search,
          loading: true,
          error: null,
        },
      };
    case DELETED_FRIENDS_SEARCH_SUCCESS:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: action.payload,
          loading: false,
        },
      };
    case DELETED_FRIENDS_SEARCH_ERROR:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          error: action.error,
        },
      };
    case DELETED_FRIENDS_FILTER_REQUEST:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: null,
          showCount: 20,
          filter: action.filter,
          loading: true,
          error: null,
        },
      };
    case DELETED_FRIENDS_FILTER_SUCCESS:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          payload: action.payload,
        },
      };
    case DELETED_FRIENDS_FILTER_ERROR:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}
