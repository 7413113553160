import React, { useEffect, useCallback, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeadTitle from "template/components/HeadTitle/HeadTitle";
import TOCompareTimeInfoTopPanel from "../TOCompareTimeInfoTopPanel/TOCompareTimeInfoTopPanel";
import TOCompareTimeInfoItem from "../TOCompareTimeInfoItem/TOCompareTimeInfoItem";
import {
  fetchCompareTimeInfo,
  setPeriodCompareTimeInfo,
  showMoreCompareTimeInfo,
} from "store/actions/tool/timeOnline";
import { Div, Link, Placeholder } from "@vkontakte/vkui";
import SmileIcon from "assets/img/tool/timeOnline/smile1.png";

import {
  PERIOD_WEEK,
  PERIOD_TODAY,
  PERIOD_MONTH,
  PERIOD_CUSTOM,
  PERIOD_ALL,
} from "template/popouts/DateChangePeriod/constPeriod";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import { PANEL_TO__COMPARE_TIME_INFO } from "views/tool/panels/constPanels";
import PopoutTemplateDateChangePeriod from "template/popouts/DateChangePeriod/DateChangePeriod";
import { VIEW_TOOL } from "views/constViews";
import { useShowMore } from "hooks";
import { convertMonthDay, unixTimeToDate } from "helpers";
import SeparateFilter from "template/components/SeparateFilter/SeparateFilter";

const TOCompareTimeInfoList = (props) => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const handleFetchCompareTimeInfo = useCallback(
    () => dispatch(fetchCompareTimeInfo()),
    [dispatch]
  );
  const handleShowMoreCompareTimeInfo = useCallback(
    () => dispatch(showMoreCompareTimeInfo()),
    [dispatch]
  );
  const handleSetPeriodCompareTimeInfo = useCallback(
    (name, atMin = null, atMax = null) => dispatch(setPeriodCompareTimeInfo(name, atMin, atMax)),
    [dispatch]
  );
  const stats = useSelector((state) => state.toolTimeOnline.compareTimeInfo.payload);
  const compareUsers = useSelector((state) => state.toolTimeOnline.compareTimeInfo.compareUsers);
  const loading = useSelector((state) => state.toolTimeOnline.compareTimeInfo.loading);
  const showCount = useSelector((state) => state.toolTimeOnline.compareTimeInfo.showCount);
  const error = useSelector((state) => state.toolTimeOnline.compareTimeInfo.error);
  const period = useSelector((state) => state.toolTimeOnline.compareTimeInfo.period);

  let lastAt = new Date();

  useShowMore({
    items: stats,
    showCount: showCount,
    maxLength: stats !== null ? stats.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMoreCompareTimeInfo,
  });

  useEffect(() => {
    handleFetchCompareTimeInfo();
  }, [handleFetchCompareTimeInfo, period]);

  const renderPeriod = () => {
    let str = "";

    if (period) {
      switch (period.name) {
        case PERIOD_MONTH:
          str = "За месяц";
          break;
        case PERIOD_TODAY:
          str = "За сегодня";
          break;
        case PERIOD_WEEK:
          str = "За неделю";
          break;
        case PERIOD_ALL:
          str = "За всё время";
          break;
        case PERIOD_CUSTOM:
          str = "Пользовательский";
          break;
        default:
          str = "";
          break;
      }
    }

    return (
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "21px" }}>
        <div
          style={{
            fontSize: "13px",
            fontWeight: "bold",
            textTransform: "uppercase",
            color: "var(--text_secondary)",
          }}
        >
          {str}
        </div>
        <Link
          onClick={handlePeriod}
          style={{
            fontSize: "15px",
            fontWeight: "normal",
            color: "var(color-link)",
            whiteSpace: "nowrap",
          }}
        >
          Выбрать
        </Link>
      </div>
    );
  };

  const renderCompareUsers = () => {
    return <TOCompareTimeInfoTopPanel compareUsers={compareUsers} />;
  };

  const renderCompareTime = () => {
    return stats.length > 0 ? (
      stats.map((item, i) => {
        const periodUser1 = item.period_user_1;
        const periodUser1IsMobile = item.period_user_1_is_mobile
          ? item.period_user_1_is_mobile
          : false;
        const periodUser2 = item.period_user_2;
        const periodUser2IsMobile = item.period_user_2_is_mobile
          ? item.period_user_2_is_mobile
          : false;
        const periodCompare = item.period_compare;

        const periodCompareDateEnd = unixTimeToDate(item.period_compare_end);

        let separate = (
          <SeparateFilter style={{ paddingTop: "20px" }}>
            {convertMonthDay(periodCompareDateEnd)}
          </SeparateFilter>
        );

        if (lastAt.getDate() === periodCompareDateEnd.getDate() && i !== 0) {
          separate = "";
        }

        lastAt = periodCompareDateEnd;

        if (i < showCount) {
          return (
            <Fragment key={i}>
              {separate}
              <TOCompareTimeInfoItem
                periodUser1={periodUser1}
                periodUser1IsMobile={periodUser1IsMobile}
                periodUser2IsMobile={periodUser2IsMobile}
                periodUser2={periodUser2}
                periodCompare={periodCompare}
              />
            </Fragment>
          );
        }

        return false;
      })
    ) : (
      <Placeholder icon={<img src={SmileIcon} width={50} height={50} alt=""></img>}>
        Пользователи не были онлайн в одно время
      </Placeholder>
    );
  };

  const handlePeriod = () => {
    if (loading) return false;

    props.openPopout({
      popout: (
        <PopoutTemplateDateChangePeriod
          openView={props.openView}
          period={period} // Период из redux store
          setPeriod={handleSetPeriodCompareTimeInfo} // action из redux
          backView={VIEW_TOOL}
          backPanel={PANEL_TO__COMPARE_TIME_INFO}
          panelTitle={"Время онлайн"}
          closePopout={props.closePopout}
        />
      ),
    });
  };

  return (
    <>
      <HeadTitle>Сравнение времени онлайн</HeadTitle>
      <Div style={{ paddingTop: "4px" }}>
        {renderPeriod()}
        {error === null && renderCompareUsers()}
        {stats !== null && error === null && renderCompareTime()}
        {renderLoader({ loading: loading })}
      </Div>
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchCompareTimeInfo,
      })}
    </>
  );
};

export default TOCompareTimeInfoList;
