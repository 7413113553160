import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Panel } from "@vkontakte/vkui";
import TOCompareTimeList from "./components/TOCompareTimeList/TOCompareTimeList";
import { withRouter } from "hoc";

const PanelTOCompareTime = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Время онлайн</PanelHeaderWithPath>
      <TOCompareTimeList openView={props.openView} />
    </Panel>
  );
};

export default withRouter(PanelTOCompareTime);
