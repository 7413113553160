import React, { useEffect, useState } from "react";
import { Panel, Button } from "@vkontakte/vkui";
import { VIEW_EDUCATION } from "views/constViews";
import { PANEL_STEP3 } from "../constPanels";
import { withRouter } from "hoc";
import EducationStep from "views/education/components/EducationStep/EducationStep";
import lessonGIF from "assets/img/education/step2/1.gif";
import { CSSTransition } from "react-transition-group";
import styles from "./step2.module.scss";
import { getSearchObject } from "api";
import { DESKTOP_WEB } from "api/vk/constPlatform";

const PanelStep2 = (props) => {
  const [inAnimation, setInAnimation] = useState(false);

  const next = () => {
    props.openView({ view: VIEW_EDUCATION, panel: PANEL_STEP3 });
  };

  useEffect(() => {
    setInAnimation(true);
  }, []);

  return (
    <Panel id={props.id}>
      <EducationStep
        description={
          <CSSTransition
            in={inAnimation}
            timeout={500}
            classNames={{
              enterActive: styles.enterActive,
              enterDone: styles.enterDone,
            }}
          >
            <div>Здесь ты узнаешь: кто заходит на твою страничку ВК</div>
          </CSSTransition>
        }
        icon={
          getSearchObject().vk_platform === DESKTOP_WEB ? (
            <img src={lessonGIF} width={226} height={290} alt=""></img>
          ) : (
            <div className={styles.imgCnt}>
              <img src={lessonGIF} width="100%" height="100%" alt=""></img>
            </div>
          )
        }
        action={
          <CSSTransition
            in={inAnimation}
            timeout={2000}
            classNames={{
              enterActive: styles.enterActive,
              enterDone: styles.enterDone,
            }}
          >
            <Button mode="primary" size="xl" style={{ width: "100%" }} onClick={next}>
              Далее
            </Button>
          </CSSTransition>
        }
      />
    </Panel>
  );
};

export default withRouter(PanelStep2);
