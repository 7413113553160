import { queryEye3 } from "api";

async function APIEye3GetCompareChanges2TrackOnline({
  userId1,
  userId2,
  startAt = null,
  endAt = null,
}) {
  try {
    const response = await queryEye3({
      method: "trackonline.get_compare_changes_2",
      get: {
        user_id_1: userId1,
        user_id_2: userId2,
        start_at: startAt,
        end_at: endAt,
      },
      access: { isBotConnect: true, isLicense: true, isLimit: true },
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3GetCompareChanges2TrackOnline;
