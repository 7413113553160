import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import ViewStoriesList from "./components/ViewStoriesList/ViewStoriesList";
import { Panel } from "@vkontakte/vkui";
import { withRouter } from "hoc";

const PanelViewStories = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Профиль</PanelHeaderWithPath>
      <ViewStoriesList />
    </Panel>
  );
};

export default withRouter(PanelViewStories);
