import { queryEye3 } from "api";

async function APIEye3GetMention({ ownerId, count = 30, offset = 0 }) {
  try {
    const response = await queryEye3({
      method: "tool.api_get_mention",
      get: { owner_id: ownerId, offset: offset, count: count },
      access: { isBotConnect: true, isLicense: true, isLimit: true },
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3GetMention;
