import React from "react";
import gImg from "assets/img/shop/guest.png";
import tImg from "assets/img/shop/premium.png";
import styles from "./ShopItemBlank.module.css";
import { Button, Div } from "@vkontakte/vkui";

const ShopItemBlank = (props) => {
  return (
    <Div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
      <div className={styles.item}>
        <div className={styles.title}>Тариф «{props.tariffCaption}»</div>
        <div className={styles.subtitle}>{props.tariffSubtitle}</div>
        <div className={styles.itemCnt}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              opacity: "0.5",
            }}
          >
            <div className={styles.itemLeft}>
              <div className={styles.img}>
                {props.tariffType === "guest" ? (
                  <img width={48} height={48} src={gImg} alt="" />
                ) : props.tariffType === "tools" ? (
                  <img width={48} height={48} src={tImg} alt="" />
                ) : (
                  <></>
                )}
              </div>
              <div>
                <div className={styles.money}>{props.sum} монет</div>
                <div className={styles.day}>{props.dayCount} дней</div>
              </div>
            </div>
            <div className={styles.itemRight}>
              <Button mode="commerce" disabled={true}>
                Подключить
              </Button>
            </div>
          </div>
          <div className={styles.tariffWarn}>
            ❗️Для доступа к тарифу набери <span>100 подписчиков</span> на своём аккаунте
          </div>
        </div>
      </div>
    </Div>
  );
};

ShopItemBlank.defaultProps = {
  tariffCaption: "", // Заголовок тарифа
  tariffSubtitle: "", // Подзаголовок
  timeCaption: "", // Заголовок количество месяцев/дней
  sum: 0, // Сумма тарифа
  tariffType: "", // Тип тарифа guest/tools
  dayCount: 0, // Количество дней
  autopayment: false, // Автоплатеж
  openModal: null, // Функция вызова модального окна
  isEnable: false, // Если тариф подключен
  loading: false, // Если идет подключение тарифа
};

export default ShopItemBlank;
