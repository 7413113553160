import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Placeholder, SimpleCell } from "@vkontakte/vkui";
import SeparateFilter from "template/components/SeparateFilter/SeparateFilter";
import AllCommentItem from "../AllCommentItem/AllCommentItem";
import Icon28CommentOutline from "@vkontakte/icons/dist/28/comment_outline";

import { unixTimeToDate, convertMonthDay, convertTime } from "helpers";

import {
  fetchAllComment,
  cancelAllComment,
  showMoreAllComment,
} from "store/actions/profile/allComment";
import { declOfNum } from "helpers";
import LoaderWithProgressBar from "template/components/LoaderWithProgressBar/LoaderWithProgressBar";
import { renderErrorPlaceholder } from "helpers/views";
import { useShowMore } from "hooks";
import HeadTitle from "template/components/HeadTitle/HeadTitle";

const AllCommentList = () => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const activeStory = useSelector((state) => state.page.activeStory);
  const profileHistory = useSelector((state) => state.profile.history);

  const allComment = useSelector((state) => state.profileAllComment.payload);
  const progressValue = useSelector((state) => state.profileAllComment.progressValue);
  const error = useSelector((state) => state.profileAllComment.error);
  const loading = useSelector((state) => state.profileAllComment.loading);
  const showCount = useSelector((state) => state.profileAllComment.showCount);

  const handleFetchAllComment = useCallback(() => dispatch(fetchAllComment()), [dispatch]);
  const handleCancelAllComment = useCallback(() => dispatch(cancelAllComment()), [dispatch]);
  const handleShowMoreAllComment = useCallback(() => dispatch(showMoreAllComment()), [dispatch]);

  let lastAt = new Date();

  useEffect(() => {
    return () => {
      handleCancelAllComment();
    };
  }, [handleCancelAllComment]);

  useEffect(() => {
    handleFetchAllComment();
  }, [handleFetchAllComment]);

  useShowMore({
    items: allComment,
    showCount: showCount,
    maxLength: allComment !== null ? allComment.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMoreAllComment,
  });

  const renderTopPanel = () => {
    const user = profileHistory[activeStory].user;
    const name = user.name;
    const city = user.city ? user.city : "";
    const age = user.age ? user.age : "";
    const photo = user.photo_url ? user.photo_url : "";
    const ageStrFormat = age ? age + " " + declOfNum(age, ["год", "года", "лет"]) : "";
    const comma = ageStrFormat && city ? ", " : "";

    return (
      <SimpleCell
        disabled
        before={<Avatar size={72} src={photo} />}
        description={ageStrFormat + comma + city}
      >
        {name}
      </SimpleCell>
    );
  };

  const renderAllComment = () => {
    return allComment.map((comment, i) => {
      const id = comment.vk_id;
      const at = unixTimeToDate(comment._at);
      const type = comment.type;
      const infoText = comment.info_text;
      const infoPhoto = comment.info_photo;
      const infoLink = comment.info_link;
      const sex = profileHistory[activeStory].user.sex;

      let separate = <SeparateFilter>{convertMonthDay(at)}</SeparateFilter>;

      if (lastAt.getDate() === at.getDate() && i !== 0) {
        separate = "";
      }

      lastAt = at;

      if (i < showCount) {
        return (
          <div key={i}>
            {separate}
            <AllCommentItem
              id={id}
              time={convertTime(at)}
              type={type}
              infoText={infoText}
              infoPhoto={infoPhoto}
              infoLink={infoLink}
              sex={sex}
            />
          </div>
        );
      }

      return false;
    });
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      <HeadTitle>Все комментарии</HeadTitle>
      {renderTopPanel()}
      {loading ? <LoaderWithProgressBar progressValue={progressValue} /> : ""}
      {allComment !== null && error === null ? renderAllComment() : ""}
      {!loading &&
      allComment !== null &&
      Object.prototype.toString.call(allComment) === "[object Array]" &&
      error === null &&
      allComment.length <= 0 ? (
        <Placeholder icon={<Icon28CommentOutline width={56} height={56} />}>
          Комментарии не найдены
        </Placeholder>
      ) : (
        ""
      )}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchAllComment,
      })}
    </div>
  );
};

export default AllCommentList;
