import React, { useState, useCallback, useEffect } from "react";
import {
  ModalPage,
  ModalPageHeader,
  Radio,
  Select,
  FormLayout,
  FormLayoutGroup,
  Div,
  PanelHeaderButton,
  Button,
} from "@vkontakte/vkui";

import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "store/actions/page";
import { APIVkGetCountriesDatabase, APIVkGetCitiesDatabase } from "api/vk/apiMethods";

import { filterGuestV2 } from "store/actions/guestV2";

import Icon28ArrowLeftOutline from "@vkontakte/icons/dist/28/arrow_left_outline";
import DatePickerMobile from "template/components/DatePickerMobile/DatePickerMobile";

const FilterModal = (props) => {
  const dispatch = useDispatch();

  const [isOpenDateFrom, setIsOpenDateFrom] = useState(false);
  const [isOpenDateTo, setIsOpenDateTo] = useState(false);

  const [cities, setCities] = useState("");
  const [countries, setCountries] = useState("");

  const filter = useSelector((state) => state.guestV2.filter);

  const [selectedFilters, setSelectedFilters] = useState({
    // Объект фильтров
    date_from: filter.date_from ? filter.date_from : "",
    date_to: filter.date_to ? filter.date_to : "",
    sex: filter.sex ? filter.sex : "",
    age_from: filter.age_from ? filter.age_from : "",
    age_to: filter.age_to ? filter.age_to : "",
    country_id: filter.country_id ? filter.country_id : "",
    city_id: filter.city_id ? filter.city_id : "",
  });

  const handleCloseModal = useCallback(() => dispatch(closeModal()), [dispatch]);
  const handleFilter = useCallback((filter) => dispatch(filterGuestV2(filter)), [dispatch]);

  useEffect(() => {
    APIVkGetCountriesDatabase().then(async (country) => {
      setCountries(country.response);

      if (selectedFilters.country_id !== "") {
        const cities = await APIVkGetCitiesDatabase({
          countryId: selectedFilters.country_id,
        });
        setCities(cities.response);
      }
    });
  }, [selectedFilters.country_id]);

  const getAges = () => {
    let ages = [];
    for (let i = 14; i <= 80; i++) {
      ages.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }

    return ages;
  };

  const onChangeAgeFrom = (e) => {
    //console.log(e.target.value);
    setSelectedFilters({
      ...selectedFilters,
      age_from: e.target.value,
    });
  };

  const onChangeAgeTo = (e) => {
    //console.log(e.target.value);
    setSelectedFilters({
      ...selectedFilters,
      age_to: e.target.value,
    });
  };

  const onChangeSex = (e) => {
    //console.log(e.target.value);
    setSelectedFilters({
      ...selectedFilters,
      sex: e.target.value,
    });
  };

  const onChangeCountry = async (e) => {
    setCities("");

    setSelectedFilters({
      ...selectedFilters,
      city_id: null,
      country_id: e.target.value,
    });

    if (e.target.value !== "") {
      const cities = await APIVkGetCitiesDatabase({
        countryId: e.target.value,
      });
      setCities(cities.response);
    }
  };

  const onChangeCity = (e) => {
    //console.log(e.target.value);

    setSelectedFilters({
      ...selectedFilters,
      city_id: e.target.value,
    });
  };

  const showResult = () => {
    handleFilter(selectedFilters);
    handleCloseModal();
    //console.log(selectedFilters);
  };

  const reset = () => {
    handleFilter("");
    handleCloseModal();
  };

  const handleSelectDateFrom = (time) => {
    setSelectedFilters({
      ...selectedFilters,
      date_from: time,
    });
    setIsOpenDateFrom(false);
  };

  const handleSelectDateTo = (time) => {
    setSelectedFilters({
      ...selectedFilters,
      date_to: time,
    });
    setIsOpenDateTo(false);
  };

  return (
    <ModalPage
      id={props.id}
      header={
        <ModalPageHeader
          separator={false}
          left={
            <PanelHeaderButton onClick={handleCloseModal}>
              <Icon28ArrowLeftOutline />
            </PanelHeaderButton>
          }
          right={<PanelHeaderButton onClick={reset}>Очистить</PanelHeaderButton>}
        >
          Фильтры
        </ModalPageHeader>
      }
    >
      <FormLayout>
        <FormLayoutGroup
          top="Дата посещения"
          style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}
        >
          <Div style={{ paddingTop: "0px", paddingBottom: "0px", width: "100%" }}>
            <DatePickerMobile
              value={selectedFilters.date_from}
              isOpen={isOpenDateFrom}
              placeholderText={"От"}
              handleIsOpen={(isOpen) => setIsOpenDateFrom(isOpen)}
              handleSelectDate={(time) => handleSelectDateFrom(time)}
            />
          </Div>
          <Div style={{ paddingTop: "0px", paddingBottom: "0px", width: "100%", margin: "0px" }}>
            <DatePickerMobile
              value={selectedFilters.date_to}
              isOpen={isOpenDateTo}
              placeholderText={"До"}
              handleIsOpen={(isOpen) => setIsOpenDateTo(isOpen)}
              handleSelectDate={(time) => handleSelectDateTo(time)}
            />
          </Div>
        </FormLayoutGroup>

        <FormLayoutGroup top="Пол">
          <Radio
            name="sex"
            value={""}
            defaultChecked={selectedFilters.sex === "" ? true : false}
            onChange={onChangeSex}
          >
            Любой
          </Radio>
          <Radio
            name="sex"
            value={1}
            defaultChecked={selectedFilters.sex === "1" ? true : false}
            onChange={onChangeSex}
          >
            Женский
          </Radio>
          <Radio
            name="sex"
            value={2}
            defaultChecked={selectedFilters.sex === "2" ? true : false}
            onChange={onChangeSex}
          >
            Мужской
          </Radio>
        </FormLayoutGroup>

        <FormLayoutGroup
          top="Возраст"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            maxHeight: "44px",
          }}
        >
          <Select
            placeholder="От"
            style={{ width: "100%" }}
            value={selectedFilters.age_from}
            onChange={onChangeAgeFrom}
          >
            {getAges()}
          </Select>
          <Select
            placeholder="До"
            style={{ width: "100%" }}
            value={selectedFilters.age_to}
            onChange={onChangeAgeTo}
          >
            {getAges()}
          </Select>
        </FormLayoutGroup>
        <Select
          top="Страна"
          placeholder="Выбрать страну"
          defaultValue={selectedFilters.country_id}
          onChange={onChangeCountry}
        >
          {countries !== "" &&
            countries.items.map((country, i) => {
              return (
                <option key={i} value={country.id}>
                  {" "}
                  {country.title}{" "}
                </option>
              );
            })}
        </Select>
        <Select
          top="Город"
          placeholder="Выбрать город"
          defaultValue={selectedFilters.city_id}
          onChange={onChangeCity}
          disabled={cities !== "" ? false : true}
        >
          {cities !== "" &&
            cities.items.map((city, i) => {
              return (
                <option key={i} value={city.id}>
                  {" "}
                  {city.title}{" "}
                </option>
              );
            })}
        </Select>
      </FormLayout>

      <Div>
        <Button size="xl" stretched onClick={showResult}>
          Показать результаты
        </Button>
      </Div>
    </ModalPage>
  );
};

export default FilterModal;
