import { queryEye3 } from "api";

async function APIEye3NotificationProfile({ isNotification }) {
  try {
    const response = await queryEye3({
      method: "profile.notification",
      get: { is_notification: isNotification },
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3NotificationProfile;
