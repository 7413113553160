import { APIEye3MutualGroupsTool } from "api/eye3";
import { checkSearchURL, getSearchFilterFriendsV2 } from "helpers/actions";
import {
  COMMON_COMMUNITY_REQUEST,
  COMMON_COMMUNITY_SUCCESS,
  COMMON_COMMUNITY_ERROR,
  COMMON_COMMUNITY_CANCEL,
  COMMON_COMMUNITY_SHOW_MORE,
  COMMON_COMMUNITY_SEARCH_REQUEST,
  COMMON_COMMUNITY_SEARCH_SUCCESS,
  COMMON_COMMUNITY_SEARCH_ERROR,
  COMMON_COMMUNITY_FILTER_REQUEST,
  COMMON_COMMUNITY_FILTER_SUCCESS,
  COMMON_COMMUNITY_FILTER_ERROR,
} from "../../constType";
import { openModal } from "../page";
import { delayMethodGetByIdGroupsVKApi } from "api";
import { checkEYE3ApiError, defaultErrorProperty } from "api/eye3/responseHandler";
import { calcAge } from "helpers";

async function getCallbackResult(usersItems, dispatch, getState, search = "") {
  try {
    let result = [];
    let prepareResult = [];
    let allGroupsIds = [];
    // Проверка на vk URL в поиске
    const isCheckSearchUrl = checkSearchURL({ search: search });

    const groups = await APIEye3MutualGroupsTool({
      userId: isCheckSearchUrl.isSearchVkId ? isCheckSearchUrl.searchValue : null,
    });

    // console.log("groups: ", groups);

    // Проверка ошибок в API
    const errorAPI = checkEYE3ApiError(groups);
    if (errorAPI !== false) {
      errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
      dispatch(error({ ...errorAPI }));
      return result;
    }

    if (!groups.status) return result;

    const groupsItems = groups.users.length !== 0 ? groups.users : [];

    if (groupsItems.length === 0) return result;

    usersItems.forEach((user) => {
      if (!user.hasOwnProperty("deactivated")) {
        for (let i = 0; i < groupsItems.length; i++) {
          if (user.id === groupsItems[i].id) {
            prepareResult.push({
              vk_id: user.id,
              name: user.first_name + " " + user.last_name,
              age: user.bdate ? calcAge(user.bdate) : null, // Расчет возраста по дате рождения
              sex: user.sex ? user.sex.toString() : null,
              city: user.city ? user.city.title : null, // Город
              city_id: user.city ? user.city.id.toString() : null, // Город id
              photo_url: user.photo_100 ? user.photo_100 : "", // Фото
              country: user.country ? user.country.title : null, // Страна
              country_id: user.country ? user.country.id.toString() : null, // Страна id
              common_groups_ids: groupsItems[i].common_groups,
              common_count: groupsItems[i].common_count,
            });

            allGroupsIds.push(...groupsItems[i].common_groups);

            break;
          }
        }
      }
    });

    /*console.log("prepareResult: ", prepareResult);
    console.log("allGroupsIds: ", allGroupsIds);*/

    if (prepareResult.length === 0) return result;

    const allGroupsById = await delayMethodGetByIdGroupsVKApi({
      groupIds: allGroupsIds,
    });

    const allGroupsByIdItems = allGroupsById;
    // console.log("allGroupsByIdItems: ", allGroupsByIdItems);

    if (allGroupsByIdItems.length === 0) return result;

    for (let prepareItem of prepareResult) {
      let commonGroups = [];
      const groupsIds = prepareItem.common_groups_ids;

      for (let i = 0; i < groupsIds.length; i++) {
        for (let j = 0; j < allGroupsByIdItems.length; j++) {
          if (allGroupsByIdItems[j].id === groupsIds[i]) {
            commonGroups.push({
              vk_id: allGroupsByIdItems[j].id,
              name: allGroupsByIdItems[j].name,
              photo_url: allGroupsByIdItems[j].photo_100,
            });
            break;
          }
        }
      }

      result.push({
        ...prepareItem,
        common_groups: commonGroups,
      });
    }

    result = result.sort((a, b) => b.common_count - a.common_count);

    //console.log("result: ", result);

    return result;
  } catch (e) {
    throw new Error("getCallbackResult::: " + e);
  }

  function error(error) {
    return { type: COMMON_COMMUNITY_ERROR, error };
  }
}

export function fetchCommonCommunity() {
  return async (dispatch, getState) => {
    try {
      dispatch(request());

      const state = getState().toolCommonCommunity;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.search,
        filter: state.filter,
        cache: state.cachePayload,
        asyncCallbackResult: (usersItems) => {
          return getCallbackResult(usersItems, dispatch, getState);
        },
      });

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: COMMON_COMMUNITY_REQUEST };
  }

  function success(payload) {
    return { type: COMMON_COMMUNITY_SUCCESS, payload };
  }

  function error(error) {
    return { type: COMMON_COMMUNITY_ERROR, error };
  }
}

export function showMoreCommonCommunity() {
  return { type: COMMON_COMMUNITY_SHOW_MORE };
}

export function cancelCommonCommunity() {
  return { type: COMMON_COMMUNITY_CANCEL };
}

export function searchCommonCommunity(search) {
  return async (dispatch, getState) => {
    try {
      dispatch(request(search));

      const state = getState().toolCommonCommunity;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.search,
        filter: state.filter,
        cache: state.cachePayload,
        asyncCallbackResult: (usersItems) => {
          return getCallbackResult(usersItems, dispatch, getState, search);
        },
      });

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(search) {
    return { type: COMMON_COMMUNITY_SEARCH_REQUEST, search };
  }

  function success(payload) {
    return { type: COMMON_COMMUNITY_SEARCH_SUCCESS, payload };
  }

  function error(error) {
    return { type: COMMON_COMMUNITY_SEARCH_ERROR, error };
  }
}

export function filterCommonCommunity(filter) {
  return async (dispatch, getState) => {
    try {
      dispatch(request(filter));

      const state = getState().toolCommonCommunity;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.search,
        filter: state.filter,
        cache: state.cachePayload,
        asyncCallbackResult: (usersItems) => {
          return getCallbackResult(usersItems, dispatch, getState);
        },
      });

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(filter) {
    return { type: COMMON_COMMUNITY_FILTER_REQUEST, filter };
  }

  function success(payload) {
    return { type: COMMON_COMMUNITY_FILTER_SUCCESS, payload };
  }

  function error(error) {
    return { type: COMMON_COMMUNITY_FILTER_ERROR, error };
  }
}
