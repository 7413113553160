import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Group, Header, Div, Button, SimpleCell } from "@vkontakte/vkui";
import { Icon28InfoOutline, Icon28DocumentOutline } from "@vkontakte/icons";
import Icon16Chevron from "@vkontakte/icons/dist/16/chevron";

import { setLicenseRenew } from "store/actions/myProfile";

import {
  PANEL_TEMPLATE_PRIVACY_POLICY,
  PANEL_TEMPLATE_USER_AGREEMENT,
} from "template/panels/constPanels";
import { unixTimeToDate } from "helpers";
import { declOfNum } from "helpers";
import { VIEW_MY_PROFILE } from "views/constViews";
import { MODAL_CARD_DISABLE_AUTO_PAYMENT } from "views/myProfile/modals/constModals";

const SettingsConnect = (props) => {
  const dispatch = useDispatch();
  const license = useSelector((state) => state.myProfile.license.payload);
  const userTariff = license !== null ? license.user : [];

  const handleSetLicenseRenew = useCallback(
    (id, isRenew) => {
      dispatch(setLicenseRenew(id, isRenew));
    },
    [dispatch]
  );

  const convertDay = (expireAt) => {
    const date = unixTimeToDate(expireAt);
    const currentDate = new Date();
    const resDate = Math.ceil(
      Math.abs(date.getTime() - currentDate.getTime()) / (1000 * 3600 * 24)
    );
    return resDate;
  };

  const renew = (id, isRenew) => {
    if (isRenew) {
      props.openModal({
        id: MODAL_CARD_DISABLE_AUTO_PAYMENT,
        param: {
          tariffId: id,
        },
      });
    } else {
      handleSetLicenseRenew(id, true);
    }
  };

  const renderUserTariff = () => {
    return userTariff.length > 0 ? (
      userTariff.map((tariff, i) => {
        const id = tariff.id;
        const name = tariff.name;
        const isRenew = tariff.is_renew;
        const expireAt = convertDay(tariff.expire_at);

        return (
          <SimpleCell
            key={i}
            disabled
            description={
              "Истекает через " + expireAt + declOfNum(expireAt, [" день", " дня", " дней"])
            }
            after={
              <Button mode="commerce" onClick={() => renew(id, isRenew)}>
                {isRenew ? "Откл. автопродление" : "Вкл. автопродление"}
              </Button>
            }
          >
            «{name}»
          </SimpleCell>
        );
      })
    ) : (
      <Div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ maxWidth: "200px" }}>
            <span
              style={{
                color: "var(--text_secondary)",
                fontSize: "13px",
                lineHeight: "16px",
                letterSpacing: "0.2px",
              }}
            >
              У вас нет подключенных тарифов
            </span>
          </div>
        </div>
      </Div>
    );
  };

  return (
    <>
      <Group header={<Header mode="secondary">Подключенные тарифы</Header>}>
        {renderUserTariff()}
      </Group>
      <Group separator="show">
        {/*<SimpleCell
          before={<Icon28HelpCircleOutline />}
          multiline
          href="https://vk.com/im?sel=-194197472"
          target="_blank"
          description="Мы отправим инструкции тебе в личные сообщения, а после ты получишь бесплатный доступ к приложению КрашеLove"
          after={<Icon16Chevron fill="#B8C1CC" />}
          onClick={async () => {
            try {
              const res = await VKWebAppAllowMessagesFromGroup({
                groupId: 194197472,
                key: "123",
              });

              if (res.hasOwnProperty("result") && res.result) {
                await APIEye3EducationProfile({ education: 1 });

                let a = document.createElement('a');
                            a.target = '_blank';
                            a.href = 'https://vk.com/im?sel=-194197472';
                            a.click();
              }

              //console.log(res);
            } catch (e) {
              //console.log(e);
            }
            //handleOpenModal(MODAL_CARD_EDUCATION_READY);
          }}
        >
          Пройти ознакомительные уроки
        </SimpleCell>*/}
        <SimpleCell
          before={<Icon28InfoOutline />}
          after={<Icon16Chevron fill="#B8C1CC" />}
          onClick={() =>
            props.openView({ view: VIEW_MY_PROFILE, panel: PANEL_TEMPLATE_PRIVACY_POLICY })
          }
        >
          Политика конфиденциальности
        </SimpleCell>
        <SimpleCell
          before={<Icon28DocumentOutline />}
          onClick={() =>
            props.openView({ view: VIEW_MY_PROFILE, panel: PANEL_TEMPLATE_USER_AGREEMENT })
          }
          after={<Icon16Chevron fill="#B8C1CC" />}
        >
          Пользовательское соглашение
        </SimpleCell>
      </Group>
    </>
  );
};

export default SettingsConnect;
