import {
  TIME_ONLINE_TRACKING_USER_REQUEST,
  TIME_ONLINE_TRACKING_USER_SUCCESS,
  TIME_ONLINE_TRACKING_USER_ERROR,
  TIME_ONLINE__TRACKING_USER_INFO_REQUEST,
  TIME_ONLINE__TRACKING_USER_INFO_SUCCESS,
  TIME_ONLINE__TRACKING_USER_INFO_SHOW_MORE,
  TIME_ONLINE__TRACKING_USER_INFO_ERROR,
  TIME_ONLINE__TRACKING_USER_INFO_SET_PERIOD,
  TIME_ONLINE__SET_TRACKING_REQUEST,
  TIME_ONLINE__SET_TRACKING_SUCCESS,
  TIME_ONLINE__SET_TRACKING_ERROR,
  TIME_ONLINE__START_TRACKING_REQUEST,
  TIME_ONLINE__START_TRACKING_SUCCESS,
  TIME_ONLINE__START_TRACKING_ERROR,
  TIME_ONLINE__START_TRACKING_SHOW_MORE,
  TIME_ONLINE__START_TRACKING_CHECKED,
  TIME_ONLINE__START_TRACKING_SEARCH_REQUEST,
  TIME_ONLINE__START_TRACKING_SEARCH_SUCCESS,
  TIME_ONLINE__START_TRACKING_SEARCH_ERROR,
  TIME_ONLINE__START_TRACKING_FILTER_REQUEST,
  TIME_ONLINE__START_TRACKING_FILTER_SUCCESS,
  TIME_ONLINE__START_TRACKING_FILTER_ERROR,
  TIME_ONLINE__SET_TRACKING_USER_INFO_REQUEST,
  TIME_ONLINE__SET_TRACKING_USER_INFO_SUCCESS,
  TIME_ONLINE__SET_TRACKING_USER_INFO_ERROR,
  TIME_ONLINE__COMPARE_TIME_REQUEST,
  TIME_ONLINE__COMPARE_TIME_SUCCESS,
  TIME_ONLINE__COMPARE_TIME_ERROR,
  TIME_ONLINE__COMPARE_TIME_INFO_REQUEST,
  TIME_ONLINE__COMPARE_TIME_INFO_SUCCESS,
  TIME_ONLINE__COMPARE_TIME_INFO_ERROR,
  TIME_ONLINE__COMPARE_TIME_INFO_SET_PERIOD,
  TIME_ONLINE__COMPARE_TIME_INFO_SHOW_MORE,
} from "../../constType";

import { PERIOD_MONTH } from "template/popouts/DateChangePeriod/constPeriod";

const initialState = {
  startTracking: {
    payload: null,
    selected: null,
    cachePayload: null,
    showCount: 20,
    filter: "",
    search: "",
    loading: false,
    error: null,
  },
  trackingUser: {
    payload: null,
    allowCount: 0,
    loading: false,
    error: null,
  },
  compareTime: {
    payload: null,
    cachePayload: null,
    activeUserId: null,
    activeUserPhoto: null,
    activeUserTitle: null,
    activeUserDescription: null,
    showCount: 20,
    filter: "",
    search: "",
    loading: false,
    error: null,
  },
  compareTimeInfo: {
    compareUsers: null,
    payload: null,
    loading: false,
    showCount: 20,
    error: null,
    period: {
      name: PERIOD_MONTH,
      atMin: null,
      atMax: null,
    },
  },
  trackingUserInfo: {
    payload: null,
    activeUserId: null,
    activeUserPhoto: null,
    activeUserTitle: null,
    activeUserDescription: null,
    activeUserIsTracking: null,
    activeUserTypeTracking: null,
    showCount: 20,
    period: {
      name: PERIOD_MONTH,
      atMin: null,
      atMax: null,
    },
    loading: false,
    error: null,
  },
};

export default function timeOnlineReducer(state = initialState, action) {
  switch (action.type) {
    case TIME_ONLINE_TRACKING_USER_REQUEST:
      return {
        ...state,
        trackingUser: {
          ...state.trackingUser,
          error: null,
          allowCount: 0,
          payload: null,
          loading: true,
        },
      };
    case TIME_ONLINE_TRACKING_USER_SUCCESS:
      return {
        ...state,
        trackingUser: {
          ...state.trackingUser,
          payload: action.payload,
          allowCount: action.allowCount,
          loading: false,
        },
      };
    case TIME_ONLINE_TRACKING_USER_ERROR:
      return {
        ...state,
        trackingUser: {
          ...state.trackingUser,
          loading: false,
          error: action.error,
        },
      };
    case TIME_ONLINE__TRACKING_USER_INFO_REQUEST:
      return {
        ...state,
        trackingUserInfo: {
          ...state.trackingUserInfo,
          payload: null,
          loading: true,
          error: null,
          showCount: 20,
          activeUserId: action.id,
          activeUserDescription: action.description,
          activeUserTitle: action.title,
          activeUserPhoto: action.photo,
          activeUserIsTracking: action.isTracking,
          activeUserTypeTracking: action.typeTracking,
        },
      };
    case TIME_ONLINE__TRACKING_USER_INFO_SUCCESS:
      return {
        ...state,
        trackingUserInfo: {
          ...state.trackingUserInfo,
          payload: action.payload,
          loading: false,
        },
      };
    case TIME_ONLINE__TRACKING_USER_INFO_SHOW_MORE:
      return {
        ...state,
        trackingUserInfo: {
          ...state.trackingUserInfo,
          showCount: parseInt(state.trackingUserInfo.showCount) + 20,
        },
      };
    case TIME_ONLINE__TRACKING_USER_INFO_ERROR:
      return {
        ...state,
        trackingUserInfo: {
          ...state.trackingUserInfo,
          error: action.error,
          loading: false,
        },
      };
    case TIME_ONLINE__TRACKING_USER_INFO_SET_PERIOD:
      return {
        ...state,
        trackingUserInfo: {
          ...state.trackingUserInfo,
          period: {
            ...state.trackingUserInfo.period,
            name: action.name,
            atMin: action.atMin,
            atMax: action.atMax,
          },
        },
      };
    case TIME_ONLINE__START_TRACKING_REQUEST:
      return {
        ...state,
        startTracking: {
          ...state.startTracking,
          loading: true,
          showCount: 20,
          allowCount: 0,
          error: null,
          payload: null,
          selected: null,
          cachePayload:
            state.startTracking.search === "" && state.startTracking.filter === ""
              ? null // Если поле поиска и фильтра пустое то очистить кэш
              : state.startTracking.cachePayload,
        },
      };
    case TIME_ONLINE__START_TRACKING_SUCCESS:
      return {
        ...state,
        startTracking: {
          ...state.startTracking,
          loading: false,
          payload: action.payload,
          selected: action.selected,
          cachePayload:
            state.startTracking.search === "" && state.startTracking.filter === ""
              ? action.payload // Если поле поиска и фильтра пустое то сохранить результат
              : state.startTracking.cachePayload,
        },
      };
    case TIME_ONLINE__START_TRACKING_ERROR:
      return {
        ...state,
        startTracking: {
          ...state.startTracking,
          loading: false,
          error: action.error,
        },
      };
    case TIME_ONLINE__START_TRACKING_SHOW_MORE:
      return {
        ...state,
        startTracking: {
          ...state.startTracking,
          showCount: parseInt(state.startTracking.showCount) + 20,
        },
      };
    case TIME_ONLINE__START_TRACKING_SEARCH_REQUEST:
      return {
        ...state,
        startTracking: {
          ...state.startTracking,
          loading: true,
          payload: null,
          error: null,
          showCount: 20,
          search: action.search,
        },
      };
    case TIME_ONLINE__START_TRACKING_SEARCH_SUCCESS:
      return {
        ...state,
        startTracking: {
          ...state.startTracking,
          loading: false,
          payload: action.payload,
          selected: action.selected,
        },
      };
    case TIME_ONLINE__START_TRACKING_SEARCH_ERROR:
      return {
        ...state,
        startTracking: {
          ...state.startTracking,
          loading: false,
          error: action.error,
        },
      };
    case TIME_ONLINE__START_TRACKING_CHECKED:
      return {
        ...state,
        startTracking: {
          ...state.startTracking,
          selected: action.checked
            ? [...state.startTracking.selected, action.value]
            : [...state.startTracking.selected.filter((item) => item !== action.value)],
        },
      };
    case TIME_ONLINE__START_TRACKING_FILTER_REQUEST:
      return {
        ...state,
        startTracking: {
          ...state.startTracking,
          payload: null,
          showCount: 20,
          loading: true,
          error: null,
          filter: action.filter,
        },
      };
    case TIME_ONLINE__START_TRACKING_FILTER_SUCCESS:
      return {
        ...state,
        startTracking: {
          ...state.startTracking,
          loading: false,
          payload: action.payload,
          selected: action.selected,
        },
      };
    case TIME_ONLINE__START_TRACKING_FILTER_ERROR:
      return {
        ...state,
        startTracking: {
          ...state.startTracking,
          loading: false,
          error: action.error,
        },
      };
    case TIME_ONLINE__SET_TRACKING_REQUEST:
      return {
        ...state,
      };
    case TIME_ONLINE__SET_TRACKING_SUCCESS:
      return {
        ...state,
      };
    case TIME_ONLINE__SET_TRACKING_ERROR:
      return {
        ...state,
      };
    case TIME_ONLINE__SET_TRACKING_USER_INFO_REQUEST:
      return {
        ...state,
        trackingUserInfo: {
          ...state.trackingUserInfo,
          loading: true,
          error: null,
          payload: null,
        },
      };
    case TIME_ONLINE__SET_TRACKING_USER_INFO_SUCCESS:
      return {
        ...state,
        trackingUserInfo: {
          ...state.trackingUserInfo,
          loading: false,
          activeUserIsTracking: action.isEnable,
        },
      };
    case TIME_ONLINE__SET_TRACKING_USER_INFO_ERROR:
      return {
        ...state,
        trackingUserInfo: {
          ...state.trackingUserInfo,
          loading: false,
          error: action.error,
        },
      };
    case TIME_ONLINE__COMPARE_TIME_REQUEST:
      return {
        ...state,
        compareTime: {
          ...state.compareTime,
          payload: null,
          loading: true,
          error: null,
          activeUserId: action.id,
          activeUserDescription: action.description,
          activeUserTitle: action.title,
          activeUserPhoto: action.photo,
        },
      };
    case TIME_ONLINE__COMPARE_TIME_SUCCESS:
      return {
        ...state,
        compareTime: {
          ...state.compareTime,
          loading: false,
          payload: action.payload,
        },
      };
    case TIME_ONLINE__COMPARE_TIME_ERROR:
      return {
        ...state,
        compareTime: {
          ...state.compareTime,
          loading: false,
          error: action.error,
        },
      };
    case TIME_ONLINE__COMPARE_TIME_INFO_REQUEST:
      return {
        ...state,
        compareTimeInfo: {
          ...state.compareTimeInfo,
          showCount: 20,
          payload: null,
          loading: true,
          error: null,
          compareUsers: action.compareUsers,
        },
      };
    case TIME_ONLINE__COMPARE_TIME_INFO_SUCCESS:
      return {
        ...state,
        compareTimeInfo: {
          ...state.compareTimeInfo,
          loading: false,
          payload: action.payload,
        },
      };
    case TIME_ONLINE__COMPARE_TIME_INFO_ERROR:
      return {
        ...state,
        compareTimeInfo: {
          ...state.compareTimeInfo,
          loading: false,
          error: action.error,
        },
      };
    case TIME_ONLINE__COMPARE_TIME_INFO_SET_PERIOD:
      return {
        ...state,
        compareTimeInfo: {
          ...state.compareTimeInfo,
          period: {
            ...state.compareTimeInfo.period,
            name: action.name,
            atMin: action.atMin,
            atMax: action.atMax,
          },
        },
      };
    case TIME_ONLINE__COMPARE_TIME_INFO_SHOW_MORE:
      return {
        ...state,
        compareTimeInfo: {
          ...state.compareTimeInfo,
          showCount: parseInt(state.compareTimeInfo.showCount) + 20,
        },
      };
    default:
      return state;
  }
}
