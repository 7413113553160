import {
  DELETED_FRIENDS_REQUEST,
  DELETED_FRIENDS_SUCCESS,
  DELETED_FRIENDS_ERROR,
  DELETED_FRIENDS_FILTER_REQUEST,
  DELETED_FRIENDS_FILTER_ERROR,
  DELETED_FRIENDS_FILTER_SUCCESS,
  DELETED_FRIENDS_SEARCH_REQUEST,
  DELETED_FRIENDS_SEARCH_ERROR,
  DELETED_FRIENDS_SEARCH_SUCCESS,
  DELETED_FRIENDS_SHOW_MORE,
} from "../../constType";

import { getSearchFilterFriendsV2 } from "helpers/actions";
import { calcAge } from "helpers";
import { defaultErrorProperty } from "api/eye3/responseHandler";

function getCallbackResult(usersItems) {
  let result = [];

  usersItems.forEach((user) => {
    if (user.hasOwnProperty("deactivated")) {
      result.push({
        vk_id: user.id,
        name: user.first_name + " " + user.last_name,
        age: user.bdate ? calcAge(user.bdate) : null, // Расчет возраста по дате рождения
        sex: user.sex ? user.sex.toString() : null,
        city: user.city ? user.city.title : null, // Город
        city_id: user.city ? user.city.id.toString() : null, // Город id
        photo_url: user.photo_100 ? user.photo_100 : "", // Фото
        country: user.country ? user.country.title : null, // Страна
        country_id: user.country ? user.country.id.toString() : null, // Страна id
      });
    }
  });

  return result;
}

export function fetchDeletedFriends() {
  return async (dispatch, getState) => {
    try {
      dispatch(request());
      const state = getState().toolDeletedFriends;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.friends.search,
        filter: state.friends.filter,
        cache: state.friends.cachePayload,
        callbackResult: (usersItems) => {
          return getCallbackResult(usersItems);
        },
      });

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: DELETED_FRIENDS_REQUEST };
  }

  function success(payload) {
    return { type: DELETED_FRIENDS_SUCCESS, payload };
  }

  function error(error) {
    return { type: DELETED_FRIENDS_ERROR, error };
  }
}

export function showMoreFriendDF() {
  return { type: DELETED_FRIENDS_SHOW_MORE };
}

export function filterFriendDF(filter = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(request(filter));
      const state = getState().toolDeletedFriends;

      const friends = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.friends.search,
        filter: state.friends.filter,
        cache: state.friends.cachePayload,
        callbackResult: (usersItems) => {
          return getCallbackResult(usersItems);
        },
      });

      dispatch(success(friends));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(filter) {
    return { type: DELETED_FRIENDS_FILTER_REQUEST, filter };
  }

  function success(payload) {
    return { type: DELETED_FRIENDS_FILTER_SUCCESS, payload };
  }

  function error(error) {
    return { type: DELETED_FRIENDS_FILTER_ERROR, error };
  }
}

export function searchUsersDF(search = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(request(search));

      const state = getState().toolDeletedFriends;

      const friends = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.friends.search,
        filter: state.friends.filter,
        cache: state.friends.cachePayload,
        callbackResult: (usersItems) => {
          return getCallbackResult(usersItems);
        },
      });

      dispatch(success(friends));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(search) {
    return { type: DELETED_FRIENDS_SEARCH_REQUEST, search };
  }

  function success(payload) {
    return { type: DELETED_FRIENDS_SEARCH_SUCCESS, payload };
  }

  function error(error) {
    return { type: DELETED_FRIENDS_SEARCH_ERROR, error };
  }
}
