import React from "react";
import { Group, Search, Tabs, TabsItem } from "@vkontakte/vkui";
import PFUserItem from "../PFUserItem/PFUserItem";
import Icon24Filter from "@vkontakte/icons/dist/24/filter";
import { TABS_FRIENDS, TABS_SUBSCRIBERS } from "../../constTabs";

const PFUserList = (props) => {
  return (
    <div>
      <Search icon={<Icon24Filter />} placeholder={"Имя или ссылка на страницу"} />
      <Tabs>
        <TabsItem selected={TABS_FRIENDS}>Друзья</TabsItem>
        <TabsItem selected={TABS_SUBSCRIBERS}>Подписчики</TabsItem>
      </Tabs>
      <Group style={{ marginBottom: "20px" }}>
        <PFUserItem
          title={"Даниил Даньшин"}
          description={"5000 друзей | 13456 подписчиков"}
          openView={props.openView}
        />
      </Group>
    </div>
  );
};

export default PFUserList;
