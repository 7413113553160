import React from "react";
import { Link, Avatar, SimpleCell } from "@vkontakte/vkui";
import Icon24Comment from "@vkontakte/icons/dist/24/comment";
import Icon24Like from "@vkontakte/icons/dist/24/like";
import Icon28ArticleOutline from "@vkontakte/icons/dist/28/article_outline";
import styles from "./AOtherUserInfoItem.module.css";

import {
  LIKE_POST,
  LIKE_COMMENT,
  LIKE_VIDEO,
  LIKE_PHOTO,
  LIKE_PHOTO_COMMENT,
} from "views/profile/panels/like/constLike";

import { COMMENT, COMMENT_PHOTO, POST } from "views/profile/panels/comment/constComment";

const AOtherUserInfoItem = (props) => {
  const getTypeText = () => {
    switch (props.type) {
      case LIKE_POST:
        return props.sex === "1" ? "Лайкнула запись " + props.name : "Лайкнул запись " + props.name;
      case LIKE_COMMENT:
        return props.sex === "1"
          ? "Лайкнула комментарий " + props.name
          : "Лайкнул комментарий " + props.name;
      case LIKE_PHOTO:
        return props.sex === "1"
          ? "Лайкнула фотографию " + props.name
          : "Лайкнул фотографию " + props.name;
      case LIKE_PHOTO_COMMENT:
        return props.sex === "1"
          ? "Лайкнула комментарий к фото " + props.name
          : "Лайкнул комментарий к фото " + props.name;
      case LIKE_VIDEO:
        return props.sex === "1" ? "Лайкнула видео " + props.name : "Лайкнул видео " + props.name;
      case COMMENT:
        return props.sex === "1"
          ? "Прокомментировала запись " + props.name
          : "Прокомментировал запись " + props.name;
      case COMMENT_PHOTO:
        return props.sex === "1"
          ? "Прокомментировала фото " + props.name
          : "Прокомментировал фото " + props.name;
      case POST:
        return props.sex === "1" ? "Написала пост " + props.name : "Написал пост " + props.name;
      default:
        return "";
    }
  };

  const getTypeAsideIcon = () => {
    switch (props.type) {
      case LIKE_POST:
        return props.infoPhoto ? (
          <img src={props.infoPhoto} width={28} height={28} alt="" />
        ) : (
          <Icon28ArticleOutline fill={"#3F8AE0"} />
        );
      case LIKE_COMMENT:
        return <img src={props.infoPhoto} width={28} height={28} alt="" />;
      case LIKE_PHOTO:
        return <img src={props.infoPhoto} width={28} height={28} alt="" />;
      case LIKE_VIDEO:
        return <img src={props.infoPhoto} width={28} height={28} alt="" />;
      case LIKE_PHOTO_COMMENT:
        return <img src={props.infoPhoto} width={28} height={28} alt="" />;
      default:
        return "";
    }
  };

  const getTypeBeforeIcon = () => {
    switch (props.type) {
      case LIKE_POST:
      case LIKE_COMMENT:
      case LIKE_PHOTO:
      case LIKE_PHOTO_COMMENT:
      case LIKE_VIDEO:
        return (
          <Avatar size={48} src={props.photoUrl}>
            <div className={styles.like}>
              <Icon24Like width={12} height={12} fill="var(--white)" />
            </div>
          </Avatar>
        );
      case COMMENT:
      case COMMENT_PHOTO:
      case POST:
        return (
          <Avatar size={48} src={props.photoUrl}>
            <div className={styles.comment}>
              <Icon24Comment width={12} height={12} fill="var(--white)" />
            </div>
          </Avatar>
        );
      default:
        return "";
    }
  };

  return (
    <SimpleCell
      before={getTypeBeforeIcon()}
      after={getTypeAsideIcon()}
      description={"в " + props.time}
      multiline
      disabled={props.infoLink !== null ? false : true}
      target="_blank"
      href={props.infoLink ? props.infoLink : null}
    >
      <span style={{ color: "var(--text_secondary)" }}>{getTypeText()} </span>
      <Link target="_blank" href={props.infoLink ? props.infoLink : null}>
        {props.infoText ? "«" + props.infoText + "»" : ""}
      </Link>
    </SimpleCell>
  );
};

export default AOtherUserInfoItem;
