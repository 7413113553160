import React from "react";

import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import PCUserList from "./components/PCUserList/PСUserList";
import { Panel } from "@vkontakte/vkui";
import PopularIcon from "assets/img/tool/popularCommunites/banner/popularCommunites.png";
import ToolBanner from "template/components/ToolBanner/ToolBanner";
import { withRouter } from "hoc";

const PanelPopularCommunity = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Популярные сообщества</PanelHeaderWithPath>
      <ToolBanner
        tool="toolPopularCommunity"
        icon={PopularIcon}
        text=" Узнавайте какие сообщества популярны среди ваших друзей"
      />
      <PCUserList
        openView={props.openView}
        openModal={props.openModal}
        closeModal={props.closeModal}
      />
    </Panel>
  );
};

export default withRouter(PanelPopularCommunity);
