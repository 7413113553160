import {
  PROFILE_JOIN_COMMUNITY_REQUEST,
  PROFILE_JOIN_COMMUNITY_SUCCESS,
  PROFILE_JOIN_COMMUNITY_ERROR,
  PROFILE_JOIN_COMMUNITY_SHOW_MORE,
} from "../../constType";

import { APIEye3GetJoinGroupsTool } from "api/eye3";
import { openModal } from "../page";
import { checkEYE3ApiError, defaultErrorProperty } from "api/eye3/responseHandler";
import { delayMethodGetByIdGroupsVKApi } from "api";

export function fetchJoinCommunity() {
  return async (dispatch, getState) => {
    try {
      const state = getState().profileJoinCommunity;
      const page = getState().page;
      const profile = getState().profile;

      const id = profile.history[page.activeStory].user.id;

      if (state.activeUserId === profile.history[page.activeStory].user.id) {
        // Если предыдущий открытый профиль равен текущему открытому профилю
        return false;
      }

      dispatch(request(id));

      let result = [];
      const joinGroup = await APIEye3GetJoinGroupsTool({ userId: id });

      // Проверка ошибок в API
      const errorAPI = checkEYE3ApiError(joinGroup);
      if (errorAPI !== false) {
        errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
        dispatch(error({ ...errorAPI }));
        return;
      }

      //console.log(joinGroup);

      if (joinGroup.groups.length === 0) return dispatch(success(result));

      const groupIds = joinGroup.groups.map((item) => item.group_id);

      const groups = await delayMethodGetByIdGroupsVKApi({
        groupIds: groupIds,
      });

      groups.forEach((group, i) => {
        result.push({
          vk_id: group.id,
          name: group.name,
          photo_url: group.photo_100,
          type: joinGroup.groups[i].type,
          _at: joinGroup.groups[i]._at,
        });
      });

      result = result.sort((a, b) => b._at - a._at);

      return dispatch(success(result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e, isTryAgain: false }));
    }
  };

  function request(id) {
    return { type: PROFILE_JOIN_COMMUNITY_REQUEST, id };
  }

  function success(payload) {
    return { type: PROFILE_JOIN_COMMUNITY_SUCCESS, payload };
  }

  function error(error) {
    return { type: PROFILE_JOIN_COMMUNITY_ERROR, error };
  }
}

export function showMoreJoinCommunity() {
  return { type: PROFILE_JOIN_COMMUNITY_SHOW_MORE };
}
