import { Icon28WalletOutline, Icon28MoneyTransferOutline } from "@vkontakte/icons";
import { Avatar, SimpleCell } from "@vkontakte/vkui";
import React from "react";
import styles from "./HistoryItem.module.scss";

const HistoryItem = (props) => {
  const getBefore = () => {
    if (props.count > 0) {
      return (
        <Avatar size={40} style={{ background: "#B8C1CC" }}>
          <Icon28WalletOutline fill="var(--white)" />
        </Avatar>
      );
    } else {
      return (
        <Avatar size={40} style={{ background: "#B8C1CC" }}>
          <Icon28MoneyTransferOutline fill="var(--white)" />
        </Avatar>
      );
    }
  };

  const getAfter = () => {
    return props.count > 0 ? (
      <span
        className={styles.title}
        style={{
          color: "#4BB34B",
        }}
      >
        {"+" + props.count + " ₽"}
      </span>
    ) : (
      <span className={styles.title}>{props.count} ₽</span>
    );
  };

  return (
    <SimpleCell before={getBefore()} after={getAfter()} disabled multiline>
      <span className={styles.txt}>{props.txt}</span>
    </SimpleCell>
  );
};

HistoryItem.defaultProps = {
  count: 0,
  txt: "",
};

export default HistoryItem;
