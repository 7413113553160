import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import AdminCommunityList from "./components/ACList/ACList";
import { Panel } from "@vkontakte/vkui";
import ToolBanner from "template/components/ToolBanner/ToolBanner";
import HornIcon from "assets/img/tool/adminCommunity/banner/horn.png";
import { withRouter } from "hoc";

const PanelAdminCommunity = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Администрируемые</PanelHeaderWithPath>
      <ToolBanner
        tool="toolAdminCommunity"
        icon={HornIcon}
        text="Узнавайте в каких сообществах пользователь указан как администратор"
      />
      <AdminCommunityList
        openView={props.openView}
        openModal={props.openModal}
        closeModal={props.closeModal}
      />
    </Panel>
  );
};

export default withRouter(PanelAdminCommunity);
