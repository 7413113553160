import React from "react";
import { ModalCard } from "@vkontakte/vkui";

import Icon24DownloadOutline from "@vkontakte/icons/dist/24/download_outline";
import Icon24StatisticsOutline from "@vkontakte/icons/dist/24/statistics_outline";
import { PANEL_DOWNLOAD_HISTORY, PANEL_HISTORY_STATISTICS } from "../../panels/constPanels";
import ToolModalItem from "views/tool/panels/main/components/ToolModalItem/ToolModalItem";

const MenuToolListHistoriesModal = (props) => {
  const caption = props.modalParam ? props.modalParam.caption : "";

  return (
    <ModalCard
      id={props.id}
      caption={caption}
      onClose={props.handleCloseModal}
      header={
        <span
          style={{
            padding: "0px",
            color: "#818C99",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "13px",
            lineHeight: "16px",
            letterSpacing: "0.2px",
            textTransform: "uppercase",
          }}
        >
          ИСТОРИИ
        </span>
      }
    >
      <ToolModalItem
        title={"Скачивание историй"}
        to={PANEL_DOWNLOAD_HISTORY}
        icon={<Icon24DownloadOutline width={30} height={30} fill={"#3F8AE0"} />}
        openView={props.openView}
        closeModal={props.handleCloseModal}
      />
      <ToolModalItem
        title={"Статистика историй"}
        to={PANEL_HISTORY_STATISTICS}
        icon={<Icon24StatisticsOutline width={30} height={30} fill={"#19E4A7"} />}
        openView={props.openView}
        closeModal={props.handleCloseModal}
      />
    </ModalCard>
  );
};

export default MenuToolListHistoriesModal;
