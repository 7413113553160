import React from "react";
import { ModalCard } from "@vkontakte/vkui";
import {
  PANEL_ACTIVITY,
  PANEL_GIFT,
  PANEL_JOIN_COMMUNITY,
  PANEL_MARTIAL_STATUS,
  PANEL_NEW_FRIENDS,
  PANEL_REGISTRATION_DATE,
  PANEL_TIME_ONLINE,
  PANEL_TRAP,
  PANEL_WHO_ADDED_CHS,
  PANEL_WHO_HIDDEN,
  PANEL_FAN,
} from "../../panels/constPanels";
import Icon24Recent from "@vkontakte/icons/dist/24/recent";
import MaritalStatusIcon from "../../../../assets/img/tool/main/maritalStatus.svg";
import Icon24UserAddOutline from "@vkontakte/icons/dist/24/user_add_outline";
import Icon24AddSquareOutline from "@vkontakte/icons/dist/24/add_square_outline";
import Icon28ListLikeOutline from "@vkontakte/icons/dist/28/list_like_outline";
import Icon24Linked from "@vkontakte/icons/dist/24/linked";
import Icon36CalendarOutline from "@vkontakte/icons/dist/36/calendar_outline";
import Icon24DoNotDisturb from "@vkontakte/icons/dist/24/do_not_disturb";
import Icon24HideOutline from "@vkontakte/icons/dist/24/hide_outline";
import Icon24GiftOutline from "@vkontakte/icons/dist/24/gift_outline";
import Icon24FavoriteOutline from "@vkontakte/icons/dist/24/favorite_outline";
import ToolModalItem from "views/tool/panels/main/components/ToolModalItem/ToolModalItem";

const MenuToolListMyAccountModal = (props) => {
  const caption = props.modalParam ? props.modalParam.caption : "";

  return (
    <ModalCard
      id={props.id}
      caption={caption}
      onClose={props.handleCloseModal}
      header={
        <span
          style={{
            color: "#818C99",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "13px",
            lineHeight: "16px",
            letterSpacing: "0.2px",
            textTransform: "uppercase",
          }}
        >
          МОЙ АККАУНТ
        </span>
      }
    >
      <ToolModalItem
        title={"Время онлайн"}
        icon={<Icon24Recent width={30} height={30} fill={"#3191FF"} />}
        openView={props.openView}
        closeModal={props.handleCloseModal}
        to={PANEL_TIME_ONLINE}
      />

      <ToolModalItem
        title={"Семейное положение"}
        icon={
          <img
            src={MaritalStatusIcon}
            width={30}
            height={29}
            alt=""
            style={{ paddingTop: "4px" }}
          ></img>
        }
        openView={props.openView}
        closeModal={props.handleCloseModal}
        to={PANEL_MARTIAL_STATUS}
      />
      <ToolModalItem
        title={"Новые друзья"}
        icon={<Icon24UserAddOutline width={30} height={30} fill={"#FF6433"} />}
        openView={props.openView}
        closeModal={props.handleCloseModal}
        to={PANEL_NEW_FRIENDS}
      />
      <ToolModalItem
        title={"Вступления/выход"}
        icon={<Icon24AddSquareOutline width={30} height={30} fill={"#19BF15"} />}
        openView={props.openView}
        closeModal={props.handleCloseModal}
        to={PANEL_JOIN_COMMUNITY}
      />
      <ToolModalItem
        title={"Активности"}
        icon={<Icon28ListLikeOutline width={30} height={30} fill={"#F83A73"} />}
        openView={props.openView}
        closeModal={props.handleCloseModal}
        to={PANEL_ACTIVITY}
      />
      <ToolModalItem
        title={"Поставить ловушку"}
        icon={<Icon24Linked width={30} height={30} fill={"#FFA000"} />}
        openView={props.openView}
        closeModal={props.handleCloseModal}
        to={PANEL_TRAP}
      />
      <ToolModalItem
        title={"Дата регистрации"}
        icon={<Icon36CalendarOutline width={30} height={30} fill={"#4BB34B"} />}
        openView={props.openView}
        closeModal={props.handleCloseModal}
        to={PANEL_REGISTRATION_DATE}
      />
      <ToolModalItem
        title={"Кто в ЧС"}
        icon={<Icon24DoNotDisturb width={30} height={30} fill={"#FF3347"} />}
        openView={props.openView}
        closeModal={props.handleCloseModal}
        to={PANEL_WHO_ADDED_CHS}
      />
      <ToolModalItem
        title={"Скрытые пользователи"}
        icon={<Icon24HideOutline width={30} height={30} fill={"#3F8AE0"} />}
        openView={props.openView}
        closeModal={props.handleCloseModal}
        to={PANEL_WHO_HIDDEN}
      />
      <ToolModalItem
        title={"Подарки"}
        icon={<Icon24GiftOutline width={30} height={30} fill={"#ED5CCD"} />}
        openView={props.openView}
        closeModal={props.handleCloseModal}
        to={PANEL_GIFT}
      />
      <ToolModalItem
        title={"Поклонники"}
        icon={<Icon24FavoriteOutline width={30} height={30} fill={"#FEB907"} />}
        openView={props.openView}
        closeModal={props.handleCloseModal}
        to={PANEL_FAN}
      />
    </ModalCard>
  );
};

export default MenuToolListMyAccountModal;
