import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Search, Group, Placeholder } from "@vkontakte/vkui";
import Icon24Filter from "@vkontakte/icons/dist/24/filter";
import Icon56UsersOutline from "@vkontakte/icons/dist/56/users_outline";
import GUserItem from "../GUserItem/GUserItem";

import { fetchGift, searchGift, filterGift, showMoreGift } from "store/actions/tool/gift";

import { declOfNum } from "helpers";

import { MODAL_PAGE_TEMPLATE_FILTER_SEX_AGE_CITY } from "template/modals/constModals";
import { useDebounceSearch, useShowMore } from "hooks";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import SecondaryTitle from "template/components/SecondaryTitle/SecondaryTitle";

const GUserList = (props) => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const loading = useSelector((state) => state.toolGift.friends.loading);
  const search = useSelector((state) => state.toolGift.friends.search);
  const filter = useSelector((state) => state.toolGift.friends.filter);
  const isOnce = useSelector((state) => state.toolGift.friends.isOnce);
  const error = useSelector((state) => state.toolGift.friends.error);
  const showCount = useSelector((state) => state.toolGift.friends.showCount);
  const friends = useSelector((state) => state.toolGift.friends.payload);

  const [searchTerm, setSearchTerm] = useState(search);

  const handleFetchGift = useCallback(() => dispatch(fetchGift()), [dispatch]);
  const handleSearchGift = useCallback((search) => dispatch(searchGift(search)), [dispatch]);
  const handleFilterGift = useCallback((filter) => dispatch(filterGift(filter)), [dispatch]);
  const handleShowMoreGift = useCallback(() => dispatch(showMoreGift()), [dispatch]);

  const renderFriendList = () => {
    return friends.length > 0 ? (
      friends.map((friend, i) => {
        const id = friend.vk_id;
        const name = friend.name;
        const sex = friend.sex;
        const city = friend.city ? friend.city : "";
        const age = friend.age ? friend.age : "";
        const photo = friend.photo_url ? friend.photo_url : "";
        const ageStrFormat = age ? age + " " + declOfNum(age, ["год", "года", "лет"]) : "";
        const comma = ageStrFormat && city ? ", " : "";

        if (i < showCount) {
          return (
            <GUserItem
              key={i}
              id={id}
              title={name}
              sex={sex}
              description={ageStrFormat + comma + city}
              photo={photo}
              openView={props.openView}
            />
          );
        }

        return false;
      })
    ) : (
      <Placeholder icon={<Icon56UsersOutline />}>Друзья не найдены</Placeholder>
    );
  };

  useShowMore({
    items: friends,
    showCount: showCount,
    maxLength: friends !== null ? friends.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMoreGift,
  });

  useEffect(() => {
    if (!isOnce) handleFetchGift();
  }, [handleFetchGift, isOnce]);

  useDebounceSearch({
    search: search,
    searchTerm: searchTerm,
    handler: handleSearchGift,
  });

  const handleFilter = () => {
    if (loading) return;

    props.openModal({
      id: MODAL_PAGE_TEMPLATE_FILTER_SEX_AGE_CITY,
      param: {
        filter: filter,
        closeModal: props.closeModal,
        applyFilter: handleFilterGift,
      },
    });
  };

  return (
    <>
      <SecondaryTitle style={{ marginTop: "5px" }}>
        Выберите пользователя из списка друзей или воспользуйтесь поиском.
      </SecondaryTitle>
      <Search
        value={searchTerm}
        icon={<Icon24Filter />}
        placeholder={"Имя или ссылка на страницу"}
        onIconClick={handleFilter}
        onChange={(e) => {
          if (loading) return;

          setSearchTerm(e.target.value);
        }}
      />

      <Group style={{ marginBottom: "20px" }}>
        {friends !== null && error === null ? renderFriendList() : ""}
      </Group>
      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchGift,
      })}
    </>
  );
};

export default GUserList;
