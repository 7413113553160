import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Panel } from "@vkontakte/vkui";
import WhoHiddenAnotherUserInfoList from "./components/WhoHiddenAnotherUserInfoList/WhoHiddenAnotherUserInfoList";
import { withRouter } from "hoc";

const PanelWhoHiddenAnotherUserInfo = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Скрытые пользователи</PanelHeaderWithPath>
      <WhoHiddenAnotherUserInfoList openView={props.openView} />
    </Panel>
  );
};

export default withRouter(PanelWhoHiddenAnotherUserInfo);
