import React from "react";
import { Panel } from "@vkontakte/vkui";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import ACInfoList from "./components/ACInfoList/ACInfoList";
import { withRouter } from "hoc";

const PanelACInfo = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Администрируемые</PanelHeaderWithPath>
      <ACInfoList openView={props.openView} />
    </Panel>
  );
};

export default withRouter(PanelACInfo);
