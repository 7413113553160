import {
  JOIN_COMMUNITY_REQUEST,
  JOIN_COMMUNITY_SUCCESS,
  JOIN_COMMUNITY_ERROR,
  JOIN_COMMUNITY_SEARCH_REQUEST,
  JOIN_COMMUNITY_SEARCH_SUCCESS,
  JOIN_COMMUNITY_SEARCH_ERROR,
  JOIN_COMMUNITY_FILTER_REQUEST,
  JOIN_COMMUNITY_FILTER_SUCCESS,
  JOIN_COMMUNITY_FILTER_ERROR,
  JOIN_COMMUNITY_SHOW_MORE,
  JOIN_COMMUNITY__INFO_GROUP_REQUEST,
  JOIN_COMMUNITY__INFO_GROUP_SUCCESS,
  JOIN_COMMUNITY__INFO_GROUP_ERROR,
  JOIN_COMMUNITY__INFO_GROUP_SET_PERIOD,
} from "../../constType";

import { getPeriod } from "template/popouts/DateChangePeriod/actions";

import { APIEye3GetJoinGroupsTool } from "api/eye3";

import { getSearchFilterFriendsV2 } from "helpers/actions";
import { openModal } from "../page";
import { checkEYE3ApiError, defaultErrorProperty } from "api/eye3/responseHandler";
import { delayMethodGetByIdGroupsVKApi } from "api";

export function fetchJoinCommunity() {
  return async (dispatch, getState) => {
    try {
      dispatch(request());

      const state = getState().toolJoinCommunity;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.friends.search,
        filter: state.friends.filter,
        cache: state.friends.cachePayload,
      });

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: JOIN_COMMUNITY_REQUEST };
  }

  function success(payload) {
    return { type: JOIN_COMMUNITY_SUCCESS, payload };
  }

  function error(error) {
    return { type: JOIN_COMMUNITY_ERROR, error };
  }
}

export function showMoreJoinCommunity() {
  return { type: JOIN_COMMUNITY_SHOW_MORE };
}

export function filterJoinCommunity(filter = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(request(filter));

      const state = getState().toolJoinCommunity;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.friends.search,
        filter: state.friends.filter,
        cache: state.friends.cachePayload,
      });

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(filter) {
    return { type: JOIN_COMMUNITY_FILTER_REQUEST, filter };
  }

  function success(payload) {
    return { type: JOIN_COMMUNITY_FILTER_SUCCESS, payload };
  }

  function error(error) {
    return { type: JOIN_COMMUNITY_FILTER_ERROR, error };
  }
}

export function searchJoinCommunity(search = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(request(search));

      const state = getState().toolJoinCommunity;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.friends.search,
        filter: state.friends.filter,
        cache: state.friends.cachePayload,
      });

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(search) {
    return { type: JOIN_COMMUNITY_SEARCH_REQUEST, search };
  }

  function success(payload) {
    return { type: JOIN_COMMUNITY_SEARCH_SUCCESS, payload };
  }

  function error(error) {
    return { type: JOIN_COMMUNITY_SEARCH_ERROR, error };
  }
}

export function setPeriodInfoGroup(name, atMin = null, atMax = null) {
  return { type: JOIN_COMMUNITY__INFO_GROUP_SET_PERIOD, name, atMin, atMax };
}

export function fetchInfoGroup() {
  return async (dispatch, getState) => {
    try {
      const state = getState().toolJoinCommunity;
      const pageParam = getState().page.param;

      // Задаем pageParam в зависимости от его наличия (когда загружен новый пользователь при изменении периода используются значения из state.groups)
      const id = pageParam ? pageParam.activeUserId : state.groups.activeUserId;
      const title = pageParam ? pageParam.activeUserTitle : state.groups.activeUserTitle;
      const description = pageParam
        ? pageParam.activeUserDescription
        : state.groups.activeUserDescription;
      const photo = pageParam ? pageParam.activeUserPhoto : state.groups.activeUserPhoto;
      const sex = pageParam ? pageParam.activeUserSex : state.groups.activeUserSex;

      const period = state.groups.period;

      dispatch(request(id, title, description, photo, sex));

      const { atMin, atMax } = getPeriod(period);

      let result = [];

      const joinGroup = await APIEye3GetJoinGroupsTool({
        userId: id,
        _atMin: atMin,
        _atMax: atMax,
      });

      // Проверка ошибок в API
      const errorAPI = checkEYE3ApiError(joinGroup);
      if (errorAPI !== false) {
        errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
        dispatch(error({ ...errorAPI }));
        return;
      }

      if (joinGroup.groups.length === 0) {
        dispatch(success(result));
        return;
      }

      const groupIds = joinGroup.groups.map((item) => item.group_id);

      const groups = await delayMethodGetByIdGroupsVKApi({
        groupIds: groupIds,
      });
      //console.log(groups.response);

      groups.forEach((group, i) => {
        result.push({
          vk_id: group.id,
          name: group.name,
          photo_url: group.photo_100,
          type: joinGroup.groups[i].type,
          _at: joinGroup.groups[i]._at,
        });
      });

      dispatch(success(result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(id, title, description, photo, sex) {
    return { type: JOIN_COMMUNITY__INFO_GROUP_REQUEST, id, title, description, photo, sex };
  }

  function success(payload) {
    return { type: JOIN_COMMUNITY__INFO_GROUP_SUCCESS, payload };
  }

  function error(error) {
    return { type: JOIN_COMMUNITY__INFO_GROUP_ERROR, error };
  }
}
