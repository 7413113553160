import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Search, Tabs, TabsItem, Placeholder, Group } from "@vkontakte/vkui";
import { renderPeriodV2 } from "template/popouts/DateChangePeriod/views";
import PopoutTemplateDateChangePeriod from "template/popouts/DateChangePeriod/DateChangePeriod";
import Icon24Filter from "@vkontakte/icons/dist/24/filter";
import { TABS_MALE, TABS_FEMALE, TABS_ALL } from "../../../constTabs";
import Icon56UsersOutline from "@vkontakte/icons/dist/56/users_outline";
import {
  setPeriodFan,
  tabsTopAll,
  showMoreTopAll,
  fetchTopAll,
  searchTopAll,
  fitlerTopAll,
} from "store/actions/tool/fan";
import { PANEL_FAN__TOP } from "../../../../constPanels";

import TopAllItem from "../TopAllItem/TopAllItem";
import { FAN_GUEST, FAN_LIKE, FAN_COMMENT } from "views/tool/panels/fan/constFan";
import { declOfNum } from "helpers";
import { MODAL_PAGE_TEMPLATE_FILTER_AGE_CITY } from "template/modals/constModals";

import { VIEW_TOOL } from "views/constViews";
import { useShowMore } from "hooks";
import { renderErrorPlaceholder } from "helpers/views";
import LoaderWithProgressBar from "template/components/LoaderWithProgressBar/LoaderWithProgressBar";

const TopAllList = (props) => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const loading = useSelector((state) => state.toolFan.topAll.loading);
  const filter = useSelector((state) => state.toolFan.topAll.filter);
  const search = useSelector((state) => state.toolFan.topAll.search);
  const typeTop = useSelector((state) => state.toolFan.topAll.typeTop);
  const progressValue = useSelector((state) => state.toolFan.topAll.progressValue);
  const tops = useSelector((state) => state.toolFan.topAll.payload);
  const error = useSelector((state) => state.toolFan.topAll.error);
  const showCount = useSelector((state) => state.toolFan.topAll.showCount);

  const tabs = useSelector((state) => state.toolFan.tabs);
  const period = useSelector((state) => state.toolFan.period);

  const handleFetchTopAll = useCallback(() => dispatch(fetchTopAll()), [dispatch]);
  const handleShowMoreTopAll = useCallback(() => dispatch(showMoreTopAll()), [dispatch]);
  const handleTabsTopAll = useCallback((tabs) => dispatch(tabsTopAll(tabs)), [dispatch]);
  const handleSetPeriodFan = useCallback(
    (name, atMin = null, atMax = null) => dispatch(setPeriodFan(name, atMin, atMax)),
    [dispatch]
  );
  const handleFitlerTopAll = useCallback((filter) => dispatch(fitlerTopAll(filter)), [dispatch]);
  const handleSearchTopAll = useCallback((search) => dispatch(searchTopAll(search)), [dispatch]);

  useEffect(() => {
    handleFetchTopAll();
  }, [handleFetchTopAll, period]);

  useShowMore({
    items: tops,
    showCount: showCount,
    maxLength: tops !== null ? tops.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMoreTopAll,
  });

  const renderTopListDescription = (value) => {
    switch (typeTop) {
      case FAN_GUEST:
        return value + " " + declOfNum(value, ["просмотр", "просмотра", "просмотров"]);
      case FAN_LIKE:
        return value + " " + declOfNum(value, ["лайк", "лайка", "лайков"]);
      case FAN_COMMENT:
        return value + " " + declOfNum(value, ["комментарий", "комментария", "комментариев"]);
      default:
        return "";
    }
  };

  const renderTopAllList = () => {
    return tops.length > 0 ? (
      tops.map((top, i) => {
        const id = top.vk_id;
        const name = top.name;
        const count = top.count;
        const photo = top.photo_url;

        if (i < showCount) {
          return (
            <TopAllItem
              id={id}
              key={i}
              title={name}
              photo={photo}
              openView={props.openView}
              description={renderTopListDescription(count)}
            />
          );
        }

        return false;
      })
    ) : (
      <Placeholder icon={<Icon56UsersOutline />}>
        Поклонники за данный период не найдены
      </Placeholder>
    );
  };

  const onClickFilter = () => {
    !loading &&
      props.openModal({
        id: MODAL_PAGE_TEMPLATE_FILTER_AGE_CITY,
        param: {
          filter: filter,
          closeModal: props.closeModal,
          applyFilter: handleFitlerTopAll,
        },
      });
  };

  const handlePeriod = () => {
    if (loading) return false;

    props.openPopout({
      popout: (
        <PopoutTemplateDateChangePeriod
          openView={props.openView}
          period={period} // Период из redux store
          setPeriod={handleSetPeriodFan} // action из redux
          backView={VIEW_TOOL}
          backPanel={PANEL_FAN__TOP}
          panelTitle={"Поклонники"}
          closePopout={props.closePopout}
        />
      ),
    });
  };

  const setTabsSex = (tabs) => {
    if (!loading) {
      handleTabsTopAll(tabs);
    }
  };

  const renderTitle = () => {
    switch (typeTop) {
      case FAN_GUEST:
        return "Топ по просмотру страницы";
      case FAN_LIKE:
        return "Топ по лайкам";
      case FAN_COMMENT:
        return "Топ по комментариям";
      default:
        return "Топ";
    }
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      {renderPeriodV2({ title: renderTitle(), period: period, handler: handlePeriod })}
      <Tabs>
        <TabsItem
          disabled={loading}
          onClick={() => {
            if (tabs !== TABS_ALL) setTabsSex(TABS_ALL);
          }}
          selected={tabs === TABS_ALL}
        >
          Все
        </TabsItem>
        <TabsItem
          disabled={loading}
          onClick={() => {
            if (tabs !== TABS_MALE) setTabsSex(TABS_MALE);
          }}
          selected={tabs === TABS_MALE}
        >
          Мужчины
        </TabsItem>
        <TabsItem
          disabled={loading}
          onClick={() => {
            if (tabs !== TABS_FEMALE) setTabsSex(TABS_FEMALE);
          }}
          selected={tabs === TABS_FEMALE}
        >
          Женщины
        </TabsItem>
      </Tabs>
      <Search
        icon={<Icon24Filter />}
        disabled={loading}
        value={search}
        onIconClick={() => onClickFilter()}
        onChange={(e) => handleSearchTopAll(e.target.value)}
      />
      {loading && <LoaderWithProgressBar progressValue={progressValue} />}
      <Group style={{ marginBottom: "20px" }}>
        {tops !== null && error === null ? renderTopAllList() : ""}
      </Group>
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchTopAll,
      })}
    </div>
  );
};

export default TopAllList;
