import {
  PROFILE_COMMENT_REQUEST,
  PROFILE_COMMENT_SUCCESS,
  PROFILE_COMMENT_ERROR,
  PROFILE_COMMENT_PROGRESS,
  PROFILE_COMMENT_SHOW_MORE,
  PROFILE_COMMENT_CANCEL,
} from "../../constType";

import { delay } from "helpers";

import { APIEye3ActiveMyPageTrackActive } from "api/eye3";
import { openModal } from "../page";
import { checkEYE3ApiError, defaultErrorProperty } from "api/eye3/responseHandler";

export function fetchComment() {
  return async (dispatch, getState) => {
    try {
      const state = getState().profileComment;
      const page = getState().page;
      const profile = getState().profile;
      const id = profile.history[page.activeStory].user.id;

      // Если предыдущий открытый профиль равен текущему открытому профилю
      if (
        state.activeUserId === profile.history[page.activeStory].user.id &&
        !state.isStopLoading
      ) {
        return false;
      }

      dispatch(request(id));

      async function process() {
        try {
          if (!getState().profileComment.loading) return;

          const comments = await APIEye3ActiveMyPageTrackActive({
            userId: id,
            activeType: "comment",
          });

          let result = [];

          //console.log('comments: ', comments);

          // Проверка ошибок в API
          const errorAPI = checkEYE3ApiError(comments);
          if (errorAPI !== false) {
            errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
            dispatch(error({ ...errorAPI }));
            return;
          }

          if (comments.status_task === "work") {
            dispatch(progress(comments.percent));
            await delay(2000);
            await process();
          } else if (comments.status_task === "success") {
            comments.result.forEach((comment) => {
              result.push({
                vk_id: comment.vk_id,
                type: comment.type,
                _at: comment._at,
                info_link: comment.info_link,
                info_photo: comment.info_photo,
                info_text: comment.info_text,
              });
            });

            dispatch(success(result));
          }

          return;
        } catch (e) {
          dispatch(error({ ...defaultErrorProperty, message: e }));
        }
      }

      await process();
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e, isTryAgain: false }));
    }
  };

  function request(id) {
    return { type: PROFILE_COMMENT_REQUEST, id };
  }

  function progress(progressValue) {
    return { type: PROFILE_COMMENT_PROGRESS, progressValue };
  }

  function success(payload) {
    return { type: PROFILE_COMMENT_SUCCESS, payload };
  }

  function error(error) {
    return { type: PROFILE_COMMENT_ERROR, error };
  }
}

export function cancelComment() {
  return { type: PROFILE_COMMENT_CANCEL };
}

export function showMoreComment() {
  return { type: PROFILE_COMMENT_SHOW_MORE };
}
