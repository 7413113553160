import React, { useState } from "react";
import { Div, Group, Button, Panel } from "@vkontakte/vkui";
import DatePickerMobile from "template/components/DatePickerMobile/DatePickerMobile";
import { PERIOD_CUSTOM } from "template/popouts/DateChangePeriod/constPeriod";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { withRouter } from "hoc";

const PanelTemplateDateChangePeriod = (props) => {
  const [isOpenDateFrom, setIsOpenDateFrom] = useState(false);
  const [isOpenDateTo, setIsOpenDateTo] = useState(false);
  const [dateFromValue, setDateFromValue] = useState("");
  const [dateToValue, setDateToValue] = useState("");
  const pageParam = props.pageParam;

  const setPeriodBackParam = (period, atMin, atMax) => {
    // Вернуть период с пользователькими значениями От и До
    pageParam.setPeriod(period, atMin, atMax);
    props.goBack();
  };

  const handleSelectDateFrom = (time) => {
    setDateFromValue(time);
    setIsOpenDateFrom(false);
  };

  const handleSelectDateTo = (time) => {
    setDateToValue(time);
    setIsOpenDateTo(false);
  };

  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>
        {pageParam ? pageParam.panelTitle : null}
      </PanelHeaderWithPath>
      <Group>
        <Div>
          <span
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              lineHeight: "24px",
              letterDpacing: "0.38px",
            }}
          >
            Выбор периода
          </span>
        </Div>
        <Div>
          <DatePickerMobile
            value={dateFromValue}
            isOpen={isOpenDateFrom}
            placeholderText={"Начало периода"}
            handleIsOpen={(isOpen) => setIsOpenDateFrom(isOpen)}
            handleSelectDate={(time) => handleSelectDateFrom(time)}
          />
        </Div>
        <Div>
          <DatePickerMobile
            value={dateToValue}
            isOpen={isOpenDateTo}
            placeholderText={"Конец периода"}
            handleIsOpen={(isOpen) => setIsOpenDateTo(isOpen)}
            handleSelectDate={(time) => handleSelectDateTo(time)}
          />
        </Div>
        <Div>
          <Button
            size="l"
            style={{ width: "100%", marginBottom: "16px" }}
            onClick={() => setPeriodBackParam(PERIOD_CUSTOM, dateFromValue, dateToValue)}
            disabled={
              dateFromValue !== "" && pageParam !== null && dateToValue !== "" ? false : true
            }
          >
            Применить
          </Button>
          <Button mode="secondary" style={{ width: "100%" }} size="l" onClick={props.goBack}>
            Отмена
          </Button>
        </Div>
      </Group>
    </Panel>
  );
};

export default withRouter(PanelTemplateDateChangePeriod);
