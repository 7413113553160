import React, { useCallback, useEffect } from "react";
import { Group, Placeholder } from "@vkontakte/vkui";
import WLFCommonAudioForMeItem from "../WLFCommonAudioForMeItem/WLFCommonAudioForMeItem";
import HeadTitle from "template/components/HeadTitle/HeadTitle";
import { useDispatch } from "react-redux";
import {
  fetchCommonAudioForMe,
  showMoreCommonAudioForMe,
} from "store/actions/tool/whoListenFriends";
import { useSelector } from "react-redux";
import { useShowMore } from "hooks";
import { Icon56UsersOutline } from "@vkontakte/icons";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";

const WLFCommonAudioForMeList = (props) => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const showCount = useSelector((state) => state.toolWhoListenFriends.commonAudioForMe.showCount);
  const loading = useSelector((state) => state.toolWhoListenFriends.commonAudioForMe.loading);
  const error = useSelector((state) => state.toolWhoListenFriends.commonAudioForMe.error);
  const friends = useSelector((state) => state.toolWhoListenFriends.commonAudioForMe.payload);

  const handleFetchCommonAudioForMe = useCallback(() => {
    dispatch(fetchCommonAudioForMe());
  }, [dispatch]);

  const handleShowMoreCommonAudioForMe = useCallback(
    () => dispatch(showMoreCommonAudioForMe()),
    [dispatch]
  );

  useEffect(() => {
    handleFetchCommonAudioForMe();
  }, [handleFetchCommonAudioForMe]);

  useShowMore({
    items: friends,
    showCount: showCount,
    maxLength: friends !== null ? friends.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMoreCommonAudioForMe,
  });

  const renderFriends = () => {
    return friends.length > 0 ? (
      friends.map((friend, i) => {
        const id = friend.vk_id;
        const photo = friend.photo_url;
        const name = friend.name;
        const commonCount = friend.common_count;
        const commonMusic = friend.common_music;

        if (i < showCount) {
          return (
            <WLFCommonAudioForMeItem
              key={i}
              id={id}
              title={name}
              photo={photo}
              commonCount={commonCount}
              commonMusic={commonMusic}
              openModal={props.openModal}
            />
          );
        }

        return false;
      })
    ) : (
      <Placeholder icon={<Icon56UsersOutline />}>Друзья не найдены</Placeholder>
    );
  };

  return (
    <div>
      <Group style={{ marginBottom: "20px" }}>
        <HeadTitle>Общие аудио со мной</HeadTitle>
        {friends !== null && error === null ? renderFriends() : ""}
      </Group>
      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchCommonAudioForMe,
      })}
    </div>
  );
};

export default WLFCommonAudioForMeList;
