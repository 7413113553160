import React, { useCallback, useEffect, useState } from "react";
import { Group, Placeholder, Search } from "@vkontakte/vkui";
import NFUserItem from "../NFUserItem/NFUserItem";
import { useDispatch, useSelector } from "react-redux";
import { declOfNum } from "helpers";
import Icon24Filter from "@vkontakte/icons/dist/24/filter";
import Icon56UsersOutline from "@vkontakte/icons/dist/56/users_outline";
import { MODAL_PAGE_TEMPLATE_FILTER_SEX_AGE_CITY } from "template/modals/constModals";
import {
  fetchNewFriends,
  filterNewFriends,
  searchNewFriends,
  showMoreNewFriends,
} from "store/actions/tool/newFriends";
import { useDebounceSearch, useShowMore } from "hooks";
import SecondaryTitle from "template/components/SecondaryTitle/SecondaryTitle";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";

const NFUserList = (props) => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const loading = useSelector((state) => state.toolNewFriends.friends.loading);
  const search = useSelector((state) => state.toolNewFriends.friends.search);
  const friends = useSelector((state) => state.toolNewFriends.friends.payload);
  const filter = useSelector((state) => state.toolNewFriends.friends.filter);
  const isOnce = useSelector((state) => state.toolNewFriends.friends.isOnce);
  const error = useSelector((state) => state.toolNewFriends.friends.error);
  const showCount = useSelector((state) => state.toolNewFriends.friends.showCount);
  const [searchTerm, setSearchTerm] = useState(search);

  const handleFilterNewFriends = useCallback(
    (filter) => dispatch(filterNewFriends(filter)),
    [dispatch]
  );
  const handleSearchNewFriends = useCallback(
    (search) => dispatch(searchNewFriends(search)),
    [dispatch]
  );
  const handleShowMoreNewFriends = useCallback(() => dispatch(showMoreNewFriends()), [dispatch]);
  const handleFetchNewFriends = useCallback(() => dispatch(fetchNewFriends()), [dispatch]);

  useShowMore({
    items: friends,
    showCount: showCount,
    maxLength: friends !== null ? friends.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMoreNewFriends,
  });

  useEffect(() => {
    if (!isOnce) handleFetchNewFriends();
  }, [handleFetchNewFriends, isOnce]);

  useDebounceSearch({
    search: search,
    searchTerm: searchTerm,
    handler: handleSearchNewFriends,
  });

  const renderFriends = () => {
    return friends.length > 0 ? (
      friends.map((friend, i) => {
        const id = friend.vk_id;
        const name = friend.name;
        const city = friend.city ? friend.city : "";
        const age = friend.age ? friend.age : "";
        const photo = friend.photo_url ? friend.photo_url : "";
        const sex = friend.sex;
        const ageStrFormat = age ? age + " " + declOfNum(age, ["год", "года", "лет"]) : "";
        const comma = ageStrFormat && city ? ", " : "";

        if (i < showCount) {
          return (
            <NFUserItem
              title={name}
              sex={sex}
              id={id}
              key={i}
              description={ageStrFormat + comma + city}
              photo={photo}
              openView={props.openView}
            />
          );
        }

        return false;
      })
    ) : (
      <Placeholder icon={<Icon56UsersOutline />}>Друзья не найдены</Placeholder>
    );
  };

  const handleFilter = () => {
    if (loading) return;

    props.openModal({
      id: MODAL_PAGE_TEMPLATE_FILTER_SEX_AGE_CITY,
      param: {
        filter: filter,
        closeModal: props.closeModal,
        applyFilter: handleFilterNewFriends,
      },
    });
  };

  return (
    <div>
      <SecondaryTitle style={{ marginTop: "5px" }}>
        Выберите пользователя из списка друзей или воспользуйтесь поиском.
      </SecondaryTitle>

      <Search
        value={searchTerm}
        icon={<Icon24Filter />}
        placeholder="Имя или ссылка на страницу"
        onIconClick={handleFilter}
        onChange={(e) => {
          if (loading) return;

          setSearchTerm(e.target.value);
        }}
      />

      <Group style={{ marginBottom: "20px" }}>
        {friends !== null && error === null ? renderFriends() : ""}
      </Group>
      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        loading: loading,
        error: error,
        isTryAgainHandler: handleFetchNewFriends,
      })}
    </div>
  );
};

export default NFUserList;
