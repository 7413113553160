import { queryEye3 } from "api";

async function APIEye3GetRegDateTool({ userIds = null } = {}) {
  try {
    const response = await queryEye3({
      method: "tool.get_reg_date",
      get: { user_ids: userIds },
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3GetRegDateTool;
