import { queryEye3 } from "api";

async function APIEye3StorySaveTool({ ids }) {
  try {
    const response = await queryEye3({
      method: "tool.story_save",
      get: { ids: ids },
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3StorySaveTool;
