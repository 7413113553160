import {
  NEW_FRIENDS_REQUEST,
  NEW_FRIENDS_SUCCESS,
  NEW_FRIENDS_ERROR,
  NEW_FRIENDS_SHOW_MORE,
  NEW_FRIENDS_SEARCH_REQUEST,
  NEW_FRIENDS_SEARCH_SUCCESS,
  NEW_FRIENDS_SEARCH_ERROR,
  NEW_FRIENDS_FILTER_REQUEST,
  NEW_FRIENDS_FILTER_SUCCESS,
  NEW_FRIENDS_FILTER_ERROR,
  NEW_FRIENDS__INFO_REQUEST,
  NEW_FRIENDS__INFO_SUCCESS,
  NEW_FRIENDS__INFO_ERROR,
  NEW_FRIENDS__INFO_SET_PERIOD,
} from "../../constType";
import { PERIOD_MONTH } from "../../../template/popouts/DateChangePeriod/constPeriod";

const initialState = {
  friends: {
    payload: null,
    cachePayload: null,
    showCount: 20,
    filter: "",
    isOnce: false,
    search: "",
    error: null,
    loading: false,
  },
  newFriends: {
    payload: null,
    showCount: 20,
    activeUserId: null, // Параметры для сохранения пользователя
    activeUserDescription: null,
    activeUserTitle: null,
    activeUserPhoto: null,
    activeUserSex: null,
    period: {
      name: PERIOD_MONTH,
      atMin: null,
      atMax: null,
    },
    error: null,
    loading: false,
  },
};

export default function newFriendsReducer(state = initialState, action) {
  switch (action.type) {
    case NEW_FRIENDS_REQUEST:
      return {
        ...state,
        friends: {
          ...state.friends,
          showCount: 20,
          payload: null,
          cachePayload:
            state.friends.search === "" && state.friends.filter === ""
              ? null // Если поле поиска и фильтра пустое то очистить кэш
              : state.friends.cachePayload,
          isOnce: true,
          loading: true,
          error: null,
        },
      };
    case NEW_FRIENDS_SUCCESS:
      return {
        ...state,
        friends: {
          ...state.friends,
          cachePayload:
            state.friends.search === "" && state.friends.filter === ""
              ? action.payload // Если поле поиска и фильтра пустое то сохранить результат
              : state.friends.cachePayload,
          payload: action.payload,
          loading: false,
          error: null,
        },
      };
    case NEW_FRIENDS_ERROR:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          error: action.error,
        },
      };
    case NEW_FRIENDS_SHOW_MORE:
      return {
        ...state,
        friends: {
          ...state.friends,
          showCount: parseInt(state.friends.showCount) + 20,
        },
      };
    case NEW_FRIENDS_SEARCH_REQUEST:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: null,
          showCount: 20,
          search: action.search,
          loading: true,
          error: null,
        },
      };
    case NEW_FRIENDS_SEARCH_SUCCESS:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: action.payload,
          loading: false,
        },
      };
    case NEW_FRIENDS_SEARCH_ERROR:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          error: action.error,
        },
      };
    case NEW_FRIENDS_FILTER_REQUEST:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: null,
          showCount: 20,
          filter: action.filter,
          loading: true,
          error: null,
        },
      };
    case NEW_FRIENDS_FILTER_SUCCESS:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          payload: action.payload,
        },
      };
    case NEW_FRIENDS_FILTER_ERROR:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          error: action.error,
        },
      };
    case NEW_FRIENDS__INFO_REQUEST:
      return {
        ...state,
        newFriends: {
          ...state.newFriends,
          showCount: 20,
          payload: null,
          activeUserId: action.id,
          activeUserDescription: action.description,
          activeUserTitle: action.title,
          activeUserPhoto: action.photo,
          activeUserSex: action.sex,
          loading: true,
          error: null,
        },
      };
    case NEW_FRIENDS__INFO_SUCCESS:
      return {
        ...state,
        newFriends: {
          ...state.newFriends,
          payload: action.payload,
          loading: false,
        },
      };
    case NEW_FRIENDS__INFO_ERROR:
      return {
        ...state,
        newFriends: {
          ...state.newFriends,
          loading: false,
          error: action.error,
        },
      };
    case NEW_FRIENDS__INFO_SET_PERIOD:
      return {
        ...state,
        newFriends: {
          ...state.newFriends,
          period: {
            ...state.newFriends.period,
            name: action.name,
            atMin: action.atMin,
            atMax: action.atMax,
          },
        },
      };
    default:
      return state;
  }
}
