import React from "react";
import Icon16Chevron from "@vkontakte/icons/dist/16/chevron";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Group, Panel, SimpleCell } from "@vkontakte/vkui";
import { VIEW_TOOL } from "../../../constViews";
import { PANEL_WLF_COMMON_AUDIO, PANEL_WLF_POPULAR_AUDIO } from "../constPanels";

import { Icon28MusicOutline, Icon28UsersOutline } from "@vkontakte/icons";
import ToolBanner from "template/components/ToolBanner/ToolBanner";
import WLFIcon from "assets/img/tool/whoListenFriends/banner/WLF.png";
import { withRouter } from "hoc";

const PanelWhoListenFriends = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Что слушают друзья</PanelHeaderWithPath>
      <ToolBanner
        tool="toolWhoListenFriends"
        icon={WLFIcon}
        text="Узнавайте какие песни популярны среди ваших друзей"
      />
      <Group>
        <SimpleCell
          before={<Icon28MusicOutline />}
          after={<Icon16Chevron fill="#B8C1CC" />}
          onClick={() => props.openView({ view: VIEW_TOOL, panel: PANEL_WLF_POPULAR_AUDIO })}
        >
          Популярные аудио
        </SimpleCell>
        <SimpleCell
          before={<Icon28UsersOutline />}
          after={<Icon16Chevron fill="#B8C1CC" />}
          onClick={() => props.openView({ view: VIEW_TOOL, panel: PANEL_WLF_COMMON_AUDIO })}
        >
          Общие аудио
        </SimpleCell>
      </Group>
    </Panel>
  );
};

export default withRouter(PanelWhoListenFriends);
