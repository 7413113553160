import { isEye3VkMethodError } from "api";
import { APIEye3VkApiUser } from "api/eye3";

async function APIVkGetCitiesDatabase({ countryId, needAll = 0 }) {
  const method = "database.getCities";
  try {
    /*const response = await bridge.send("VKWebAppCallAPIMethod", {
      method: method,
      params: {
        need_all: needAll,
        country_id: countryId,
        v: getVKApiVersion(),
        access_token: getVKToken(),
      },
    });

    responseHandler(method, response);*/

    const citiesAPI = await APIEye3VkApiUser({
      method: method,
      params: {
        country_id: countryId,
        need_all: needAll,
      },
    });

    if (isEye3VkMethodError(citiesAPI)) {
      throw JSON.stringify(citiesAPI);
    }

    return citiesAPI.result;
  } catch (e) {
    // errorHandler(method, e);
    throw e;
  }
}

export default APIVkGetCitiesDatabase;
