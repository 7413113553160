import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View } from "@vkontakte/vkui";

import {
  PANEL_STEP1,
  PANEL_STEP2,
  PANEL_STEP3,
  PANEL_STEP4,
  PANEL_STEP5,
  PANEL_STEP6,
  PANEL_STEP7,
} from "./panels/constPanels";

import PanelStep1 from "./panels/step1";
import PanelStep2 from "./panels/step2";
import PanelStep3 from "./panels/step3";
import PanelStep4 from "./panels/step4";
import PanelStep5 from "./panels/step5";
import PanelStep6 from "./panels/step6";
import PanelStep7 from "./panels/step7";
import { onTransitionPage } from "store/actions/page";

const EducationView = (props) => {
  const dispatch = useDispatch();
  const activePanel = useSelector((state) => state.page.activePanel);

  const handleOnTransitionPage = useCallback(
    (param) => dispatch(onTransitionPage(param)),
    [dispatch]
  );

  return (
    <View id={props.id} activePanel={activePanel} onTransition={handleOnTransitionPage}>
      <PanelStep1 id={PANEL_STEP1} />
      <PanelStep2 id={PANEL_STEP2} />
      <PanelStep3 id={PANEL_STEP3} />
      <PanelStep4 id={PANEL_STEP4} />
      <PanelStep5 id={PANEL_STEP5} />
      <PanelStep6 id={PANEL_STEP6} />
      <PanelStep7 id={PANEL_STEP7} />
    </View>
  );
};

export default EducationView;
