import { Group, Header } from "@vkontakte/vkui";
import React from "react";
import { VictoryPie } from "victory";

const GraphSex = (props) => {
  return (
    <>
      <Group separator="hide" header={<Header mode="secondary">Пол и возраст</Header>}>
        <div style={{ textAlign: "center" }}>
          <svg width={180} height={180}>
            <g transform="translate(-40, -40)">
              <VictoryPie
                width={256}
                height={256}
                standalone={false}
                labels={({ datum }) => ""}
                colorScale={["#EE94B4", "#579BEB"]}
                data={[
                  { x: "Female", y: props.female },
                  { x: "Male", y: props.male },
                ]}
              />
            </g>
          </svg>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              marginRight: "100px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  width: "6px",
                  height: "6px",
                  borderRadius: "32px",
                  backgroundColor: "#579BEB",
                  marginRight: "2px",
                }}
              ></div>
              <span style={{ color: "var(--text_subhead)", fontSize: "20px" }}>{props.male}%</span>
            </div>
            <span style={{ color: "var(--text_secondary)", fontSize: "13px" }}>мужчины</span>
          </div>
          <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  width: "6px",
                  height: "6px",
                  borderRadius: "32px",
                  backgroundColor: "#EE94B4",
                  marginRight: "2px",
                }}
              ></div>
              <span style={{ color: "var(--text_subhead)", fontSize: "20px" }}>
                {props.female}%
              </span>
            </div>
            <span style={{ color: "var(--text_secondary)", fontSize: "13px" }}>женщины</span>
          </div>
        </div>
      </Group>
    </>
  );
};

GraphSex.defaultProps = {
  male: 0,
  female: 0,
};

export default GraphSex;
