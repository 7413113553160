import {
  REGISTRATION_DATE_REQUEST,
  REGISTRATION_DATE_SUCCESS,
  REGISTRATION_DATE_ERROR,
  REGISTRATION_DATE_FRIEND_SHOW_MORE,
  REGISTRATION_DATE_FRIEND_FILTER_REQUEST,
  REGISTRATION_DATE_FRIEND_FILTER_SUCCESS,
  REGISTRATION_DATE_FRIEND_FILTER_ERROR,
  REGISTRATION_DATE_FRIEND_SEARCH_REQUEST,
  REGISTRATION_DATE_FRIEND_SEARCH_SUCCESS,
  REGISTRATION_DATE_FRIEND_SEARCH_ERROR,
} from "../../constType";

import { calcAge } from "helpers";

import { getSearchObject, checkVKMethodError } from "api";

import { APIVkGetUsers } from "api/vk/apiMethods";

import { APIEye3GetRegDateTool } from "api/eye3";

import {
  getFilterFriends,
  getFriendsComparePopular,
  getSearchFriends,
  getSearchURLUsers,
} from "helpers/actions";
import { defaultErrorProperty } from "api/eye3/responseHandler";

export function showMoreUsersRegistrationDate() {
  return { type: REGISTRATION_DATE_FRIEND_SHOW_MORE };
}

async function getRegDate({
  friends,
  search,
  filter,
  fields = ["photo_100", "bdate", "city", "country", "sex"],
}) {
  try {
    let usersItems = null;
    let { users, isSearchVkId } = await getSearchURLUsers({
      search,
      fields,
    });

    let result = [];

    if (users !== null && checkVKMethodError(users)) {
      // Если в методе возникла ошибка
      return { result };
    }

    if (!isSearchVkId) {
      users = await getFriendsComparePopular({
        friends: friends,
        fields: fields,
      });
    }

    usersItems = users.response.hasOwnProperty("items") ? users.response.items : users.response;

    if (usersItems.length === 0) return { result };

    const regDate = await APIEye3GetRegDateTool({
      userIds: usersItems.map((user) => user.id).join(","),
    });

    let regDateShow = regDate.self_user.reg_date_show;
    let regAgoTime = regDate.self_user.reg_ago_time;

    regDate.users.forEach((regAPI) => {
      usersItems.forEach((user) => {
        if (!user.hasOwnProperty("deactivated")) {
          if (regAPI.user_id !== user.id) {
            // Если id юзера из даты регистрации не совпадают со списком id из vk api то прервать итерацию
            return;
          }

          if (!user.hasOwnProperty("deactivated")) {
            result.push({
              vk_id: user.id,
              name: user.first_name + " " + user.last_name,
              age: user.bdate ? calcAge(user.bdate) : null, // Расчет возраста по дате рождения
              sex: user.sex ? user.sex.toString() : null,
              city: user.city ? user.city.title : null, // Город
              city_id: user.city ? user.city.id.toString() : null, // Город id
              photo_url: user.photo_100 ? user.photo_100 : "", // Фото
              country: user.country ? user.country.title : null, // Страна
              country_id: user.country ? user.country.id.toString() : null, // Страна id
              reg_date_show: regAPI.reg_date_show,
              reg_ago_time: regAPI.reg_ago_time,
            });
          }
        }
      });
    });

    result = getFilterFriends({
      data: result,
      filter: filter,
    });

    result = getSearchFriends({
      data: result,
      search: search,
      isSearchVkId,
    });

    return { result, regDateShow, regAgoTime };
  } catch (e) {
    throw e;
  }
}

export function fetchRegistrationDate() {
  return async (dispatch, getState) => {
    try {
      const state = getState().toolRegistrationDate;

      dispatch(request());

      const res = await getRegDate({
        friends: getState().friend.payload,
        search: state.friends.search,
        filter: state.friends.filter,
      });

      const myRes = await APIVkGetUsers({
        userIds: getSearchObject().vk_user_id,
        fields: ["photo_100", "photo_200"].join(","),
      });

      let myInfoResult = {
        vk_id: myRes.response[0].id,
        photo_url: myRes.response[0].photo_100,
        photo_url_200: myRes.response[0].photo_200,
        reg_date_show: res.regDateShow,
        reg_ago_time: res.regAgoTime,
      };

      dispatch(success(myInfoResult, res.result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: REGISTRATION_DATE_REQUEST };
  }

  function success(myPayload, friendsPayload) {
    return { type: REGISTRATION_DATE_SUCCESS, myPayload, friendsPayload };
  }

  function error(error) {
    return { type: REGISTRATION_DATE_ERROR, error };
  }
}

export function searchUsersRegistrationDate(search = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(request(search));

      const state = getState().toolRegistrationDate;

      const res = await getRegDate({
        friends: getState().friend.payload,
        search: state.friends.search,
        filter: state.friends.filter,
      });

      dispatch(success(res.result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(search) {
    return { type: REGISTRATION_DATE_FRIEND_SEARCH_REQUEST, search };
  }

  function success(payload) {
    return { type: REGISTRATION_DATE_FRIEND_SEARCH_SUCCESS, payload };
  }

  function error(error) {
    return { type: REGISTRATION_DATE_FRIEND_SEARCH_ERROR, error };
  }
}

export function filterUsersRegistrationDate(filter = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(request(filter));

      const state = getState().toolRegistrationDate;

      const res = await getRegDate({
        friends: getState().friend.payload,
        search: state.friends.search,
        filter: state.friends.filter,
      });

      dispatch(success(res.result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(filter) {
    return { type: REGISTRATION_DATE_FRIEND_FILTER_REQUEST, filter };
  }

  function success(payload) {
    return { type: REGISTRATION_DATE_FRIEND_FILTER_SUCCESS, payload };
  }

  function error(error) {
    return { type: REGISTRATION_DATE_FRIEND_FILTER_ERROR, error };
  }
}
