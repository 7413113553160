import { Icon56MessageOutline } from "@vkontakte/icons";
import { Div, Group, Placeholder } from "@vkontakte/vkui";
import { convertMonthDay, unixTimeToDate } from "helpers";
import { declOfNum } from "helpers";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import { useLoadMore } from "hooks";
import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchMentionInfo,
  loadMoreMentionInfo,
  setPeriodMentionInfo,
} from "store/actions/tool/mention";
import SeparateFilter from "template/components/SeparateFilter/SeparateFilter";
import PopoutTemplateDateChangePeriod from "template/popouts/DateChangePeriod/DateChangePeriod";
import { renderPeriodV2 } from "template/popouts/DateChangePeriod/views";
import { VIEW_TOOL } from "views/constViews";
import { PANEL_MENTION__INFO } from "views/tool/panels/constPanels";
import MInfoItem from "../MInfoItem/MInfoItem";
import MInfoTopPanel from "../MInfoTopPanel/MInfoTopPanel";

const MInfoList = (props) => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const activeUserId = useSelector((state) => state.toolMention.mentions.activeUserId);
  const activeUserTitle = useSelector((state) => state.toolMention.mentions.activeUserTitle);
  const activeUserPhoto = useSelector((state) => state.toolMention.mentions.activeUserPhoto);
  const activeUserDescription = useSelector(
    (state) => state.toolMention.mentions.activeUserDescription
  );
  const mentions = useSelector((state) => state.toolMention.mentions.payload);
  const period = useSelector((state) => state.toolMention.mentions.period);
  const error = useSelector((state) => state.toolMention.mentions.error);
  const offset = useSelector((state) => state.toolMention.mentions.offset);
  const maxLength = useSelector((state) => state.toolMention.mentions.maxLength);
  const loading = useSelector((state) => state.toolMention.mentions.loading);
  const handleFetchMentionInfo = useCallback(() => dispatch(fetchMentionInfo()), [dispatch]);
  const handleSetPeriodMentionInfo = useCallback(
    (name, atMin = null, atMax = null) => dispatch(setPeriodMentionInfo(name, atMin, atMax)),
    [dispatch]
  );
  const handleLoadMoreMentionInfo = useCallback(() => dispatch(loadMoreMentionInfo()), [dispatch]);

  let lastAt = new Date();

  useLoadMore({
    items: mentions,
    offset: offset,
    loading: loading,
    isTransitionEnd: isTransitionEnd,
    maxLength: maxLength,
    handler: handleLoadMoreMentionInfo,
  });

  useEffect(() => {
    handleFetchMentionInfo();
  }, [handleFetchMentionInfo, period]);

  const renderUser = () => {
    const mentionsCount = maxLength;
    const mentionsCountStrFormat = mentionsCount
      ? mentionsCount + " " + declOfNum(mentionsCount, ["упоминание", "упоминания", "упоминаний"])
      : "0 упоминаний";

    return (
      <MInfoTopPanel
        id={activeUserId}
        title={activeUserTitle}
        description={activeUserDescription}
        photo={activeUserPhoto}
        bottomContent={mentionsCountStrFormat}
        openView={props.openView}
      />
    );
  };

  const renderMentions = () => {
    return mentions.length > 0 ? (
      mentions.map((item, i) => {
        const postId = item.post_id;
        const toId = item.to_id;
        const fromId = item.from_id;
        const typePage = item.type_page;
        const name = item.name;
        const photo = item.photo_url;
        const date = unixTimeToDate(item.date);
        const type = item.type;
        const text = item.text;

        let separate = <SeparateFilter>{convertMonthDay(date)}</SeparateFilter>;

        if (lastAt.getDate() === date.getDate() && i !== 0) {
          separate = "";
        }

        lastAt = date;

        return (
          <div key={i}>
            {separate}
            <MInfoItem
              fromId={fromId}
              postId={postId}
              toId={toId}
              typePage={typePage}
              name={name}
              photo={photo}
              date={date}
              text={text}
              type={type}
            />
          </div>
        );
      })
    ) : (
      <Placeholder icon={<Icon56MessageOutline />}>Упоминания не найдены</Placeholder>
    );
  };

  const handlePeriod = () => {
    if (loading) return false;

    props.openPopout({
      popout: (
        <PopoutTemplateDateChangePeriod
          openView={props.openView}
          period={period} // Период из redux store
          setPeriod={handleSetPeriodMentionInfo} // action из redux
          backPanel={PANEL_MENTION__INFO}
          backView={VIEW_TOOL}
          panelTitle={"Упоминания"}
          closePopout={props.closePopout}
        />
      ),
    });
  };

  return (
    <>
      {renderPeriodV2({ title: "Упоминания", period: period, handler: handlePeriod })}
      {mentions !== null && error === null ? <Group separator="hide">{renderUser()}</Group> : ""}
      {mentions !== null && error === null ? (
        <Group style={{ marginBottom: "20px" }}>
          <Div>{renderMentions()}</Div>
        </Group>
      ) : (
        ""
      )}
      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchMentionInfo,
      })}
    </>
  );
};

export default MInfoList;
