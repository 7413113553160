import React from "react";

import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import CCUserList from "./components/CCUserList/ССUserList";
import { Panel } from "@vkontakte/vkui";
import ToolBanner from "template/components/ToolBanner/ToolBanner";
import CommunityIcon from "assets/img/tool/commonCommunity/banner/common.png";
import { withRouter } from "hoc";

const PanelCommonCommunities = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Общие сообщества</PanelHeaderWithPath>
      <ToolBanner
        tool="toolCommonCommunity"
        icon={CommunityIcon}
        text="Узнавайте с кем из друзей вы состоите в одинаковых сообществах"
      />
      <CCUserList
        openView={props.openView}
        openModal={props.openModal}
        closeModal={props.closeModal}
      />
    </Panel>
  );
};

export default withRouter(PanelCommonCommunities);
