import React from "react";
import { Button, Avatar, RichCell } from "@vkontakte/vkui";

import { MODAL_PAGE_TEMPLATE_TRACKING_OPTION } from "template/modals/constModals";

const TSStartTrackingItem = (props) => {
  return (
    <RichCell
      disabled
      before={<Avatar size={72} src={props.photo} />}
      caption={<div style={{ marginTop: "3px", marginBottom: "6px" }}>{props.description}</div>}
      bottom={
        !props.isTracking ? (
          <Button
            onClick={async () => {
              await props.openModal({
                id: MODAL_PAGE_TEMPLATE_TRACKING_OPTION,
                param: {
                  call: "start_tracking",
                  id: props.id,
                  setTracking: props.handleSetTracking,
                  closeModal: props.closeModal,
                },
              });
            }}
          >
            Отслеживать
          </Button>
        ) : (
          <Button mode="secondary" onClick={() => props.handleSetTracking(props.id, false)}>
            Не отслеживать
          </Button>
        )
      }
    >
      <span style={{ fontSize: "16px" }}>{props.title}</span>
    </RichCell>
  );
};

export default TSStartTrackingItem;
