import React from "react";
import { Avatar, SimpleCell } from "@vkontakte/vkui";
import Icon16Chevron from "@vkontakte/icons/dist/16/chevron";
import { PANEL_POSTING__INFO } from "../../../constPanels";
import { VIEW_TOOL } from "views/constViews";

const PUserItem = (props) => {
  return (
    <SimpleCell
      id={props.id}
      before={<Avatar size={48} src={props.photo} />}
      description={props.description}
      onClick={() =>
        props.openView({
          view: VIEW_TOOL,
          panel: PANEL_POSTING__INFO,
          param: {
            activeUserId: props.id,
            activeUserPhoto: props.photo,
            activeUserTitle: props.title,
            activeUserDescription: props.description,
          },
        })
      }
      after={<Icon16Chevron fill="#B8C1CC" />}
    >
      {props.title}
    </SimpleCell>
  );
};

export default PUserItem;
