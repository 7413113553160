import { ModalRoot } from "@vkontakte/vkui";
import React from "react";
import { MODAL_PAGE_FILTER, MODAL_CARD_VISITED } from "views/guest/modals/constModals";
import FilterModal from "./FilterModal/FilterModal";
import VisitedModal from "./VisitedModal/VisitedModal";
import {
  MODAL_CARD_TEMPLATE_TARIFF_WORK,
  MODAL_CARD_TEMPLATE_TARIFF_LIMIT,
} from "template/modals/constModals";

import TariffWorkModal from "template/modals/TariffWork/TariffWork";
import TariffLimitModal from "template/modals/TariffLimit/TariffLimit";
import { MODAL_PAGE_TEMPLATE_TRACKING_OPTION } from "template/modals/constModals";
import TrackingOptionModal from "template/modals/TrackingOption/TrackingOption";

import { fetchGuestV2, tabsGuestV2 } from "store/actions/guestV2";
import { TABS_ACTIVE } from "../panels/main/constTabs";
import { STORY_SHOP } from "stories/constStories";

/* Профиль */

import ProfileMutualFriendModal from "views/profile/modals/ProfileMutualFriendModal/ProfileMutualFriendModal";
import ProfileMutualCommunityModal from "views/profile/modals/ProfileMutualCommunityModal/ProfileMutualCommunityModal";
import HoroscopeDateBirthModal from "views/profile/modals/HoroscopeDateBirthModal/HoroscopeDateBirthModal";

import {
  MODAL_PAGE_MUTUAL_FRIEND,
  MODAL_PAGE_MUTUAL_COMMUNITY,
  MODAL_CARD_HOROSCOPE_DATE_BIRTH,
} from "views/profile/modals/constModals";
import { useCallback } from "react";
import { closeModal, openStory } from "store/actions/page";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { PANEL_MAIN } from "views/shop/panels/constPanels";
///

const ModalList = () => {
  const dispatch = useDispatch();

  const handleOpenStory = useCallback(
    ({ story, panel }) => dispatch(openStory({ story: story, panel: panel })),
    [dispatch]
  );

  const handleCloseModal = useCallback(() => dispatch(closeModal()), [dispatch]);
  const modalParam = useSelector((state) => state.page.modalParam);
  const activeModal = useSelector((state) =>
    state.page.activeModal[state.page.activeView] === undefined
      ? null
      : state.page.activeModal[state.page.activeView]
  );

  // Visited Modal
  const loadingGuest = useSelector((state) => state.guestV2.loading);
  const handleFetchGuestV2 = useCallback(
    (isRefresh = false) => dispatch(fetchGuestV2(isRefresh)),
    [dispatch]
  );
  const handleTabsGuestV2 = useCallback((tabs) => dispatch(tabsGuestV2(tabs)), [dispatch]);
  const handleCloseVisitedModal = () => {
    // В случае перемещения в магазин и подключения тарифа сделать TABS_ALL
    if (!loadingGuest) {
      handleCloseModal();
      handleTabsGuestV2(TABS_ACTIVE);
      handleFetchGuestV2(true);
    }
  };
  const handleGoShopVisitedModal = () => {
    if (!loadingGuest) {
      handleCloseModal();
      handleOpenStory({ story: STORY_SHOP, panel: PANEL_MAIN });
    }
  };
  //

  return (
    <ModalRoot
      activeModal={activeModal}
      onClose={(modalId) => {
        if (modalId === MODAL_CARD_VISITED) handleCloseVisitedModal();
        else handleCloseModal();
      }}
    >
      <FilterModal id={MODAL_PAGE_FILTER} />
      <VisitedModal
        id={MODAL_CARD_VISITED}
        openStory={handleOpenStory}
        handleGoShopVisitedModal={handleGoShopVisitedModal}
        handleCloseModal={handleCloseVisitedModal}
      />
      <TariffWorkModal
        id={MODAL_CARD_TEMPLATE_TARIFF_WORK}
        openStory={handleOpenStory}
        handleCloseModal={handleCloseModal}
        modalParam={modalParam}
      />
      <TariffLimitModal
        id={MODAL_CARD_TEMPLATE_TARIFF_LIMIT}
        openStory={handleOpenStory}
        handleCloseModal={handleCloseModal}
        modalParam={modalParam}
      />

      {/* Профиль */}

      <ProfileMutualFriendModal id={MODAL_PAGE_MUTUAL_FRIEND} />
      <ProfileMutualCommunityModal id={MODAL_PAGE_MUTUAL_COMMUNITY} />
      <TrackingOptionModal id={MODAL_PAGE_TEMPLATE_TRACKING_OPTION} modalParam={modalParam} />
      <HoroscopeDateBirthModal
        id={MODAL_CARD_HOROSCOPE_DATE_BIRTH}
        modalParam={modalParam}
        handleCloseModal={handleCloseModal}
      />

      {/* /Профиль */}
    </ModalRoot>
  );
};

export default ModalList;
