import {
  PROFILE_INFO_HISTORY_REQUEST,
  PROFILE_INFO_HISTORY_SUCCESS,
  PROFILE_INFO_HISTORY_ERROR,
  PROFILE_INFO_HISTORY_SHOW_MORE,
} from "../../constType";

import { APIEye3GetUserInfoHistoryTool } from "api/eye3";

import {
  INFO_GENERAL,
  //INFO_CONTACT,
  INFO_EDUCATION,
} from "views/profile/panels/infoHistory/constInfo";
import { openModal } from "../page";
import { checkEYE3ApiError, defaultErrorProperty } from "api/eye3/responseHandler";

export function fetchInfoHistory() {
  return async (dispatch, getState) => {
    try {
      const state = getState().profileInfoHistory;
      const page = getState().page;
      const profile = getState().profile;

      const id = profile.history[page.activeStory].user.id;

      if (state.activeUserId === profile.history[page.activeStory].user.id) {
        // Если предыдущий открытый профиль равен текущему открытому профилю
        return false;
      }

      dispatch(request(id));

      let result = [];

      const infoHistory = await APIEye3GetUserInfoHistoryTool({
        userId: id,
      });

      // Проверка ошибок в API
      const errorAPI = checkEYE3ApiError(infoHistory);
      if (errorAPI !== false) {
        errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
        dispatch(error({ ...errorAPI }));
        return;
      }

      var permissibleInfo = new Map([
        ["status", INFO_GENERAL],
        ["first_name", INFO_GENERAL],
        ["last_name", INFO_GENERAL],
        ["sex", INFO_GENERAL],
        ["screen_name", INFO_GENERAL],
        ["relatives", INFO_GENERAL],
        ["occupation", INFO_GENERAL],
        ["photo_id", INFO_GENERAL],
        /*['contacts', INFO_CONTACT],
                ['city', INFO_CONTACT],
                ['country', INFO_CONTACT],*/
        ["schools", INFO_EDUCATION],
        ["university_name", INFO_EDUCATION],
        ["faculty_name", INFO_EDUCATION],
      ]);

      var noteInfo = {
        status: "Статус",
        first_name: "Имя",
        last_name: "Фамилия",
        sex: "Пол",
        screen_name: "Короткое имя странцы",
        relatives: "Родственники",
        occupation: "Род занятий",
        photo_id: "Смена аватарки",
        /*contacts: 'Контакты',
                city: 'Город',
                country: 'Страна',*/
        schools: "Школа",
        university_name: "Университет",
        faculty_name: "Факультет",
      };

      //console.log('infoHistory: ', infoHistory);
      const changes = infoHistory.changes;
      //console.log('changes: ', changes);

      changes.forEach((change) => {
        permissibleInfo.forEach((value, key) => {
          if (change.hasOwnProperty(key)) {
            result.push({
              vk_id: id,
              [key]: change[key],
              item_key: key,
              item_note: noteInfo[key],
              type_info: value,
              create_at: change.create_at,
            });
          }
        });
      });

      //console.log('result: ', result);

      dispatch(success(result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e, isTryAgain: false }));
    }
  };

  function request(id) {
    return { type: PROFILE_INFO_HISTORY_REQUEST, id };
  }

  function success(payload) {
    return { type: PROFILE_INFO_HISTORY_SUCCESS, payload };
  }

  function error(error) {
    return { type: PROFILE_INFO_HISTORY_ERROR, error };
  }
}

export function showMoreInfoHistory() {
  return { type: PROFILE_INFO_HISTORY_SHOW_MORE };
}
