import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

// import bridge from "@vkontakte/vk-bridge";

import { ConfigProvider, Epic, ScreenSpinner } from "@vkontakte/vkui";

import "@vkontakte/vkui/dist/vkui.css";
import "./App.scss";
import "./themes.scss";
import "./template/components/themes.scss";

import {
  STORY_GUEST,
  STORY_MOY_CRUSH,
  STORY_MY_PROFILE,
  STORY_TOOL,
  STORY_SHOP,
  STORY_EDUCATION,
} from "./stories/constStories";

// import TabbarMenu from "./template/components/TabbarMenu/TabbarMenu";

import GuestStory from "./stories/guest";
import MoyCrushStory from "./stories/moycrush";
import MyProfileStory from "./stories/myProfile";
import ToolStory from "./stories/tool";
import ShopStory from "./stories/shop";
import EducationStory from "./stories/education";

import AuthView from "./views/auth";

import { auth } from "./store/actions/myProfile";
import { goBack } from "./store/actions/page";
// import { getVKStorage } from "./store/actions/vkStorage";
import { usePopStateBackAction } from "hooks";

const App = () => {
  const dispatch = useDispatch();

  const isAuth = useSelector((state) => state.myProfile.isAuth);
  const myProfileLoading = useSelector((state) => state.myProfile.loading);
  const myProfileError = useSelector((state) => state.myProfile.error);
  const myProfileIsOnce = useSelector((state) => state.myProfile.isOnce);

  const handleAuth = useCallback(() => dispatch(auth()), [dispatch]);
  // const handleGetVKStorage = useCallback(() => dispatch(getVKStorage()), [dispatch]);

  const activeStory = useSelector((state) => state.page.activeStory);
  /*const handleOpenStory = useCallback(
    ({ story, panel }) => dispatch(openStory({ story: story, panel: panel })),
    [dispatch]
  );*/
  const handleGoBack = useCallback(() => dispatch(goBack()), [dispatch]);

  /*const openStoryTab = (e) =>
    handleOpenStory({ story: e.currentTarget.dataset.story, panel: null });*/
  // const [colorScheme, setColorScheme] = useState("bright_light");

  usePopStateBackAction({ goBack: handleGoBack });

  /*useEffect(() => {
    bridge.subscribe(({ detail: { type, data } }) => {
      if (type === "VKWebAppUpdateConfig") {
        const scheme = data.scheme ? data.scheme : colorScheme;
        setColorScheme(scheme);

        const schemeAttribute = document.createAttribute("scheme");
        schemeAttribute.value = scheme;
        document.body.attributes.setNamedItem(schemeAttribute);
      }
    });
  }, [colorScheme]);*/

  /*React.useEffect(() => {
    const $tabBar = document.querySelector(".Tabbar");
    if ($tabBar === null) {
      return;
    }

    if ($tabBar.classList.contains("Tabbar--ios")) {
      $tabBar.style.bottom = "56px";
    }
  }, []);*/

  // Авторизация
  useEffect(() => {
    handleAuth();
  }, [handleAuth]);

  // Получение данных с vkStorage
  /*useEffect(() => {
    handleGetVKStorage();
  }, [handleGetVKStorage]);*/

  const getStories = () => {
    if (myProfileLoading) return;

    // Если при авторизации возникла ошибка
    if (myProfileIsOnce && myProfileError !== null) return <AuthView handleRepeat={handleAuth} />;

    if (isAuth) {
      // Если авторизация прошла без ошибок

      switch (activeStory) {
        case STORY_SHOP:
          return <ShopStory id={STORY_SHOP} />; // Пользователь находится на странице магазина
        case STORY_EDUCATION:
          return <EducationStory id={STORY_EDUCATION} />; // Пользователь находится на странице ознакомительных уроков
        default:
          return (
            <Epic
              activeStory={activeStory}
              // tabbar={<TabbarMenu activeStory={activeStory} openStory={openStoryTab} />}
            >
              <GuestStory id={STORY_GUEST} />
              <MoyCrushStory id={STORY_MOY_CRUSH} />
              <MyProfileStory id={STORY_MY_PROFILE} />
              <ToolStory id={STORY_TOOL} />
            </Epic>
          );
      }
    }
  };

  return (
    <>
      <ConfigProvider>
        {getStories()}
        {myProfileLoading && <ScreenSpinner size="large" />}
      </ConfigProvider>
    </>
  );
};

export default App;
