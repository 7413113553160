import {
  MY_PROFILE_AUTH_REQUEST,
  MY_PROFILE_AUTH_SUCCESS,
  MY_PROFILE_AUTH_ERROR,
  MY_PROFILE_UPDATE_REQUEST,
  MY_PROFILE_UPDATE_SUCCESS,
  MY_PROFILE_UPDATE_ERROR,
  MY_PROFILE_SET_NOTIFICATION_REQUEST,
  MY_PROFILE_SET_NOTIFICATION_SUCCESS,
  MY_PROFILE_SET_NOTIFICATION_ERROR,
  MY_PROFILE_SUBSCRIBE_GROUP_REQUEST,
  MY_PROFILE_SUBSCRIBE_GROUP_SUCCESS,
  MY_PROFILE_SUBSCRIBE_GROUP_ERROR,
  MY_PROFILE_LICENSE_REQUEST,
  MY_PROFILE_LICENSE_SUCCESS,
  MY_PROFILE_LICENSE_ERROR,
  MY_PROFILE_SET_LICENSE_RENEW_REQUEST,
  MY_PROFILE_SET_LICENSE_RENEW_SUCCESS,
  MY_PROFILE_SET_LICENSE_RENEW_ERROR,
  MY_PROFILE_CHECKED_ACCESS_REQUEST,
  MY_PROFILE_CHECKED_ACCESS_SUCCESS,
  MY_PROFILE_CHECKED_ACCESS_PROGRESS,
  MY_PROFILE_CHECKED_ACCESS_ERROR,
} from "../constType";

const initialState = {
  payloadVK: null,
  payloadEYE3: null,
  isAuth: false,
  loading: false,
  isOnce: false,
  error: null,

  checkedAccess: {
    payload: null,
    loading: false,
    error: null,
  },

  license: {
    payload: null,
    loading: false,
    error: null,
  },
};

export default function myProfileReducer(state = initialState, action) {
  switch (action.type) {
    case MY_PROFILE_AUTH_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        isOnce: true,
      };
    case MY_PROFILE_AUTH_SUCCESS:
      return {
        ...state,
        /*payloadVK: action.payloadVK,
        payloadEYE3: action.payloadEYE3,*/
        isAuth: true,
        loading: false,
      };
    case MY_PROFILE_AUTH_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case MY_PROFILE_CHECKED_ACCESS_REQUEST:
      return {
        ...state,
        checkedAccess: {
          ...state.checkedAccess,
          error: null,
          loading: true,
        },
      };
    case MY_PROFILE_CHECKED_ACCESS_PROGRESS:
      return {
        ...state,
        checkedAccess: {
          ...state.checkedAccess,
          payload: action.payload,
        },
      };
    case MY_PROFILE_CHECKED_ACCESS_SUCCESS:
      return {
        ...state,
        checkedAccess: {
          ...state.checkedAccess,
          loading: false,
        },
      };
    case MY_PROFILE_CHECKED_ACCESS_ERROR:
      return {
        ...state,
        checkedAccess: {
          ...state.checkedAccess,
          error: action.error,
          loading: false,
        },
      };
    case MY_PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case MY_PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        payloadEYE3: action.payloadEYE3,
        loading: false,
      };
    case MY_PROFILE_UPDATE_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case MY_PROFILE_SET_NOTIFICATION_REQUEST:
      return {
        ...state,
      };
    case MY_PROFILE_SET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        payloadEYE3: {
          ...state.payloadEYE3,
          user: {
            ...state.payloadEYE3.user,
            is_notification: action.isEnabled,
          },
        },
      };
    case MY_PROFILE_SET_NOTIFICATION_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case MY_PROFILE_SUBSCRIBE_GROUP_REQUEST:
      return {
        ...state,
      };
    case MY_PROFILE_SUBSCRIBE_GROUP_SUCCESS:
      return {
        ...state,
        payloadEYE3: {
          ...state.payloadEYE3,
          user: {
            ...state.payloadEYE3.user,
            group_member: true,
          },
        },
      };
    case MY_PROFILE_SUBSCRIBE_GROUP_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case MY_PROFILE_LICENSE_REQUEST:
      return {
        ...state,
        license: {
          ...state.license,
          loading: true,
          error: null,
        },
      };
    case MY_PROFILE_LICENSE_SUCCESS:
      return {
        ...state,
        license: {
          ...state.license,
          loading: false,
          payload: action.payload,
        },
      };
    case MY_PROFILE_LICENSE_ERROR:
      return {
        ...state,
        license: {
          ...state.license,
          loading: false,
          error: action.error,
        },
      };
    case MY_PROFILE_SET_LICENSE_RENEW_REQUEST:
      return {
        ...state,
      };
    case MY_PROFILE_SET_LICENSE_RENEW_SUCCESS:
      return {
        ...state,
        license: {
          ...state.license,
          payload: {
            ...state.license.payload,
            user: state.license.payload.user.map((item) => {
              if (item.id === action.id) {
                return {
                  ...item,
                  is_renew: action.isRenew,
                };
              }
              return item;
            }),
          },
        },
      };
    case MY_PROFILE_SET_LICENSE_RENEW_ERROR:
      return {
        ...state,
        license: {
          ...state.license,
          error: action.error,
        },
      };
    default:
      return state;
  }
}
