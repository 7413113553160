import { classNames, Div } from "@vkontakte/vkui";
import React, { useRef } from "react";
import Slider from "react-slick";
import LeftArrowIcon from "assets/img/tool/main/leftArrow.svg";
import RightArrowIcon from "assets/img/tool/main/rightArrow.svg";
import ToolItemV2 from "../ToolItem/ToolItem";
import styles from "./PopularSlider.module.scss";

import MaritalStatusIcon from "assets/img/tool/main/maritalStatus.svg";

import {
  Icon24AddSquareOutline,
  Icon24CommentOutline,
  Icon24DoNotDisturb,
  Icon24GiftOutline,
  Icon24HideOutline,
  Icon24Linked,
  Icon24Recent,
  Icon24Search,
  Icon24UserAddOutline,
  Icon28AdvertisingOutline,
  Icon28ListLikeOutline,
  Icon36CalendarOutline,
} from "@vkontakte/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchPopularMenuList } from "store/actions/tool/toolList";
import { useCallback } from "react";
import { useEffect } from "react";
import {
  PANEL_ACTIVITY,
  PANEL_ADMIN_COMMUNITY,
  PANEL_GIFT,
  PANEL_JOIN_COMMUNITY,
  PANEL_MARTIAL_STATUS,
  PANEL_MENTION,
  PANEL_NEW_FRIENDS,
  PANEL_REGISTRATION_DATE,
  PANEL_TIME_ONLINE,
  PANEL_TOTAL_SURVEILLANCE,
  PANEL_TRAP,
  PANEL_WHO_ADDED_CHS,
  PANEL_WHO_HIDDEN,
} from "views/tool/panels/constPanels";
import { renderLoader } from "helpers/views";

function NextArrow(props) {
  const cnNext = classNames(props.className, styles.arrow, {
    [styles.disabled]: props.className.indexOf("slick-disabled") !== -1 ? true : false,
  });

  return (
    <div className={cnNext} onClick={props.onClick}>
      <img src={RightArrowIcon} alt=""></img>
    </div>
  );
}

function PrevArrow(props) {
  const cnPrev = classNames(props.className, styles.arrow, {
    [styles.disabled]: props.className.indexOf("slick-disabled") !== -1 ? true : false,
  });

  return (
    <div className={cnPrev} onClick={props.onClick}>
      <img src={LeftArrowIcon} alt=""></img>
    </div>
  );
}

function PopularSlider(props) {
  const refSlider = useRef();

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.toolPopularItems.popularItems.loading);
  const popularItems = useSelector((state) => state.toolPopularItems.popularItems.payload);
  const handleFetchPopularMenuList = useCallback(
    () => dispatch(fetchPopularMenuList()),
    [dispatch]
  );

  useEffect(() => {
    handleFetchPopularMenuList();
  }, [handleFetchPopularMenuList]);

  const slides = [
    {
      name: "whoHidden",
      title: (
        <>
          Скрытые<br></br> пользователи
        </>
      ),
      icon: <Icon24HideOutline width={37} height={37} fill={"#3F8AE0"} />,
      to: PANEL_WHO_HIDDEN,
    },
    {
      name: "whoAddedChs",
      title: <>Кто в ЧС</>,
      icon: <Icon24DoNotDisturb width={37} height={37} fill={"#FF3347"} />,
      to: PANEL_WHO_ADDED_CHS,
    },
    {
      name: "registrationDate",
      title: (
        <>
          Дата<br></br> регистрации
        </>
      ),
      icon: <Icon36CalendarOutline width={37} height={37} fill={"#4BB34B"} />,
      to: PANEL_REGISTRATION_DATE,
    },
    {
      name: "trap",
      title: (
        <>
          Поставить<br></br> ловушку
        </>
      ),
      icon: <Icon24Linked width={37} height={37} fill={"#FFA000"} />,
      to: PANEL_TRAP,
    },
    {
      name: "totalSurveillance",
      title: (
        <>
          Тотальная<br></br> слежка
        </>
      ),
      icon: <Icon24Search width={37} height={37} fill={"#7845E5"} />,
      to: PANEL_TOTAL_SURVEILLANCE,
    },
    {
      name: "maritalStatus",
      title: (
        <>
          Семейное<br></br> положение
        </>
      ),
      icon: <img src={MaritalStatusIcon} width={37} height={37} alt=""></img>,
      to: PANEL_MARTIAL_STATUS,
    },
    {
      name: "activity",
      title: <>Активности</>,
      icon: <Icon28ListLikeOutline width={37} height={37} fill={"#F83A73"} />,
      to: PANEL_ACTIVITY,
    },
    {
      name: "newFriends",
      title: (
        <>
          Новые<br></br> друзья
        </>
      ),
      icon: <Icon24UserAddOutline width={37} height={37} fill={"#FF6433"} />,
      to: PANEL_NEW_FRIENDS,
    },
    {
      name: "timeOnline",
      title: (
        <>
          Время<br></br> онлайн
        </>
      ),
      icon: <Icon24Recent width={37} height={37} fill={"#3191FF"} />,
      to: PANEL_TIME_ONLINE,
    },
    {
      name: "mention",
      title: <>Упоминания</>,
      icon: <Icon24CommentOutline width={37} height={37} fill={"#3B8FF1"} />,
      to: PANEL_MENTION,
    },
    {
      name: "adminCommunity",
      title: <>Администрируемые</>,
      icon: <Icon28AdvertisingOutline width={37} height={37} fill={"#4395F3"} />,
      to: PANEL_ADMIN_COMMUNITY,
    },
    {
      name: "joinCommunity",
      title: <>Вступления/выход</>,
      icon: <Icon24AddSquareOutline width={37} height={37} fill={"#19BF15"} />,
      to: PANEL_JOIN_COMMUNITY,
    },
    {
      name: "gift",
      title: <>Подарки</>,
      icon: <Icon24GiftOutline width={37} height={37} fill={"#ED5CCD"} />,
      to: PANEL_GIFT,
    },
  ];

  const parseSlides = () => {
    let newSlides = [];

    if (popularItems === null) return slides;

    for (let popularItem of popularItems.menu) {
      for (let slide of slides) {
        if (popularItem === slide.name) {
          newSlides.push(slide);
          break;
        }
      }
    }

    return newSlides;
  };

  let settings = {
    dots: false,
    arrows: true,
    swipe: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    swipeToSlide: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          arrows: false,
          swipe: true,
          swipeToSlide: true,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Div style={{ paddingBottom: "0px" }}>
        <h2 className={styles.header}>Популярное</h2>
      </Div>
      <div className={styles.container}>
        {!loading && (
          <Slider {...settings} ref={refSlider}>
            {parseSlides().map((slide, index) => {
              return (
                <ToolItemV2
                  key={index}
                  title={slide.title}
                  icon={slide.icon}
                  openView={props.openView}
                  to={slide.to}
                />
              );
            })}
          </Slider>
        )}
        {loading && renderLoader({ loading: loading })}
      </div>
    </>
  );
}

export default PopularSlider;
