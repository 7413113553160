import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View } from "@vkontakte/vkui";
import {
  PANEL_MAIN,
  PANEL_BALANCE_REPLENISHMENT,
  PANEL_BALANCE_IFRAME,
  PANEL_BALANCE_CHECKED_STATUS,
  PANEL_BALANCE_HISTORY,
} from "./panels/constPanels";

import PanelMain from "./panels/main";
import PanelBalanceReplenishment from "./panels/balanceReplenishment";
import PanelBalanceIframe from "./panels/balanceIframe";
import PanelBalanceCheckedStatus from "./panels/balanceCheckedStatus";
import PanelBalanceHistory from "./panels/balanceHistory";

import { onTransitionPage } from "store/actions/page";

import "./themes.scss";

import PanelTemplatePrivacyPolicy from "template/panels/PrivacyPolicy/PrivacyPolicy";
import PanelTemplateUserAgreement from "template/panels/UserAgreement/UserAgreement";
import {
  PANEL_TEMPLATE_PRIVACY_POLICY,
  PANEL_TEMPLATE_USER_AGREEMENT,
} from "template/panels/constPanels";
import ModalList from "./modals";

const ShopView = (props) => {
  const dispatch = useDispatch();
  const activePanel = useSelector((state) => state.page.activePanel);
  const history = useSelector((state) =>
    state.page.panelsHistory[state.page.activeView] === undefined
      ? [state.page.activeView]
      : state.page.panelsHistory[state.page.activeView]
  );
  const handleOnTransitionPage = useCallback(
    (param) => dispatch(onTransitionPage(param)),
    [dispatch]
  );

  return (
    <View
      id={props.id}
      history={history}
      onTransition={handleOnTransitionPage}
      activePanel={activePanel}
      modal={<ModalList />}
    >
      <PanelMain id={PANEL_MAIN} />
      <PanelBalanceReplenishment id={PANEL_BALANCE_REPLENISHMENT} />
      <PanelBalanceIframe id={PANEL_BALANCE_IFRAME} />
      <PanelBalanceCheckedStatus id={PANEL_BALANCE_CHECKED_STATUS} />
      <PanelBalanceHistory id={PANEL_BALANCE_HISTORY} />
      <PanelTemplatePrivacyPolicy id={PANEL_TEMPLATE_PRIVACY_POLICY} />
      <PanelTemplateUserAgreement id={PANEL_TEMPLATE_USER_AGREEMENT} />
    </View>
  );
};

export default ShopView;
