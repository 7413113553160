import React from "react";
import { RichCell, Avatar } from "@vkontakte/vkui";

import { Icon28ChevronRightOutline } from "@vkontakte/icons";
import { VIEW_TOOL } from "views/constViews";
import { PANEL_PROFILE } from "views/profile/panels/constPanels";

const MInfoTopPanel = (props) => {
  return (
    <RichCell
      before={<Avatar size={72} src={props.photo} />}
      caption={<div style={{ marginTop: "3px", marginBottom: "4px" }}>{props.description}</div>}
      bottom={
        <div style={{ fontSize: "13px", color: "var(--color-custom-link)" }}>
          {props.bottomContent}
        </div>
      }
      onClick={() =>
        props.openView({
          view: VIEW_TOOL,
          panel: PANEL_PROFILE,
          param: {
            activeProfileId: props.id,
          },
        })
      }
      after={<Icon28ChevronRightOutline fill="#B8C1CC" />}
    >
      <span style={{ fontSize: "16px" }}>{props.title}</span>
    </RichCell>
  );
};

export default MInfoTopPanel;
