import { queryEye3 } from "api";

async function APIEye3CreateUnitpayPayment({ email, count, type }) {
  try {
    const response = await queryEye3({
      method: "payment.create_unitpay",
      get: { email: email, count: count, type: type },
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3CreateUnitpayPayment;
