import { Avatar, SimpleCell } from "@vkontakte/vkui";
import React from "react";
import Icon16Chevron from "@vkontakte/icons/dist/16/chevron";
import { VIEW_TOOL } from "views/constViews";
import { PANEL_HS__INFO } from "views/tool/panels/constPanels";

const HSUserItem = (props) => {
  return (
    <SimpleCell
      before={<Avatar size={48} src={props.photo} />}
      after={<Icon16Chevron fill="#B8C1CC" />}
      onClick={() =>
        props.openView({
          view: VIEW_TOOL,
          panel: PANEL_HS__INFO,
          param: {
            activeId: props.id,
            activeStoryType: props.storyType,
          },
        })
      }
    >
      {props.title}
    </SimpleCell>
  );
};

export default HSUserItem;
