import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon28StoryOutline from "@vkontakte/icons/dist/28/story_outline";

import { Avatar, Div, Placeholder, SimpleCell } from "@vkontakte/vkui";

import SeparateFilter from "template/components/SeparateFilter/SeparateFilter";
import ViewStoriesItem from "../ViewStoriesItem/ViewStoriesItem";

import { unixTimeToDate, convertMonthDay, convertTime } from "helpers";

import {
  fetchViewStories,
  showMoreViewStories,
  cancelViewStories,
} from "store/actions/profile/viewStories";

import { declOfNum } from "helpers";
import LoaderWithProgressBar from "template/components/LoaderWithProgressBar/LoaderWithProgressBar";
import { renderErrorPlaceholder } from "helpers/views";
import { useShowMore } from "hooks";
import HeadTitle from "template/components/HeadTitle/HeadTitle";

const ViewStoriesList = () => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const activeStory = useSelector((state) => state.page.activeStory);
  const profileHistory = useSelector((state) => state.profile.history);

  const stories = useSelector((state) => state.profileViewStories.payload);
  const progressValue = useSelector((state) => state.profileViewStories.progressValue);
  const error = useSelector((state) => state.profileViewStories.error);
  const loading = useSelector((state) => state.profileViewStories.loading);
  const showCount = useSelector((state) => state.profileViewStories.showCount);

  const handleFetchViewStories = useCallback(() => dispatch(fetchViewStories()), [dispatch]);
  const handleCancelViewStories = useCallback(() => dispatch(cancelViewStories()), [dispatch]);
  const handleShowMoreViewStories = useCallback(() => dispatch(showMoreViewStories()), [dispatch]);

  useEffect(() => {
    return () => {
      handleCancelViewStories();
    };
  }, [handleCancelViewStories]);

  useShowMore({
    items: stories,
    showCount: showCount,
    maxLength: stories !== null ? stories.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMoreViewStories,
  });

  const renderTopPanel = () => {
    const user = profileHistory[activeStory].user;
    const name = user.name;
    const city = user.city ? user.city : "";
    const age = user.age ? user.age : "";
    const photo = user.photo_url ? user.photo_url : "";
    const ageStrFormat = age ? age + " " + declOfNum(age, ["год", "года", "лет"]) : "";
    const comma = ageStrFormat && city ? ", " : "";

    return (
      <SimpleCell
        disabled
        before={<Avatar size={72} src={photo} />}
        description={ageStrFormat + comma + city}
      >
        {name}
      </SimpleCell>
    );
  };

  const renderViewStories = () => {
    return stories.map((storyRow, i) => {
      let currentDate = unixTimeToDate(storyRow[0].date);

      if (i < showCount) {
        return (
          <div key={i}>
            <SeparateFilter style={{ marginTop: "10px", marginBottom: "10px" }}>
              {convertMonthDay(currentDate)}
            </SeparateFilter>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {storyRow.map((storyCell, j) => {
                const id = storyCell.vk_id;
                const date = unixTimeToDate(storyCell.date);
                const photo = storyCell.photo_url;

                return (
                  <ViewStoriesItem id={id} photo={photo} time={convertTime(date)} key={i + j} />
                );
              })}
            </div>
          </div>
        );
      }

      return false;
    });
  };

  useEffect(() => {
    handleFetchViewStories();
  }, [handleFetchViewStories]);

  return (
    <div style={{ marginBottom: "20px" }}>
      <HeadTitle>Просмотры моих историй</HeadTitle>
      {renderTopPanel()}
      {loading ? <LoaderWithProgressBar progressValue={progressValue} /> : ""}
      {stories !== null && error === null ? <Div>{renderViewStories()}</Div> : ""}

      {!loading &&
      stories !== null &&
      Object.prototype.toString.call(stories) === "[object Array]" &&
      error === null &&
      stories.length <= 0 ? (
        <Placeholder icon={<Icon28StoryOutline width={56} height={56} />}>
          Истории не найдены
        </Placeholder>
      ) : (
        ""
      )}

      {renderErrorPlaceholder({
        loading: loading,
        error: error,
        isTryAgainHandler: handleFetchViewStories,
      })}
    </div>
  );
};

export default ViewStoriesList;
