import { Group, Placeholder, Tabs, TabsItem } from "@vkontakte/vkui";
import HeadTitle from "template/components/HeadTitle/HeadTitle";
import { Icon28ViewOutline } from "@vkontakte/icons";
import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { readyTopSpectators, tabsTopSpectators } from "store/actions/tool/historyStatistics";
import { TABS_ALL, TABS_FEMALE, TABS_MALE } from "../../constTabs";
import TopItem from "../TopItem/TopItem";

const TopList = (props) => {
  const dispatch = useDispatch();
  const activeTypeSpectator = useSelector(
    (state) => state.toolHistoryStatistics.topSpectators.activeTypeSpectator
  );
  const activeHeader = useSelector(
    (state) => state.toolHistoryStatistics.topSpectators.activeHeader
  );
  const tabs = useSelector((state) => state.toolHistoryStatistics.topSpectators.tabs);
  const topItems = useSelector((state) => state.toolHistoryStatistics.topSpectators.payload);
  const handleTabsTopSpectators = useCallback(
    (tabs) => dispatch(tabsTopSpectators(tabs)),
    [dispatch]
  );
  const handleReadyTopSpectators = useCallback(() => dispatch(readyTopSpectators()), [dispatch]);

  useEffect(() => {
    handleReadyTopSpectators();
  }, [handleReadyTopSpectators]);

  const renderTop = () => {
    return topItems.length > 0 ? (
      topItems.map((item, i) => {
        const id = item.vk_id;
        const title = item.name;
        const photo = item.photo_url;
        const value = item[activeTypeSpectator];

        if (value > 0) {
          return (
            <TopItem
              key={i}
              id={id}
              title={title}
              photo={photo}
              type={activeTypeSpectator}
              value={value}
              openView={props.openView}
            />
          );
        }

        return false;
      })
    ) : (
      <Placeholder icon={<Icon28ViewOutline width={56} height={56} />}>
        Зрители не найдены
      </Placeholder>
    );
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      <HeadTitle>{activeHeader}</HeadTitle>
      <Group separator="hide">
        <Tabs>
          <TabsItem
            onClick={() => {
              if (tabs !== TABS_ALL) handleTabsTopSpectators(TABS_ALL);
            }}
            selected={tabs === TABS_ALL}
          >
            Все
          </TabsItem>
          <TabsItem
            onClick={() => {
              if (tabs !== TABS_MALE) handleTabsTopSpectators(TABS_MALE);
            }}
            selected={tabs === TABS_MALE}
          >
            Мужчины
          </TabsItem>
          <TabsItem
            onClick={() => {
              if (tabs !== TABS_FEMALE) handleTabsTopSpectators(TABS_FEMALE);
            }}
            selected={tabs === TABS_FEMALE}
          >
            Женщины
          </TabsItem>
        </Tabs>

        {topItems !== null && renderTop()}
      </Group>
    </div>
  );
};

TopList.defaultProps = {
  openView: null,
};

export default TopList;
