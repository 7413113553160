import {
  PROFILE_VIEW_PAGE_REQUEST,
  PROFILE_VIEW_PAGE_SUCCESS,
  PROFILE_VIEW_PAGE_ERROR,
} from "../../constType";

import { APIEye3GetGuestViewTool } from "api/eye3";
import { openModal } from "../page";
import { checkEYE3ApiError, defaultErrorProperty } from "api/eye3/responseHandler";

export function fetchViewPage() {
  return async (dispatch, getState) => {
    try {
      const state = getState().profileViewPage;
      const page = getState().page;
      const profile = getState().profile;

      const id = profile.history[page.activeStory].user.id;

      if (state.activeUserId === profile.history[page.activeStory].user.id) {
        // Если предыдущий открытый профиль равен текущему открытому профилю
        return false;
      }

      dispatch(request(id));
      const result = await APIEye3GetGuestViewTool({ userId: id });

      //console.log(result);

      // Проверка ошибок в API
      const errorAPI = checkEYE3ApiError(result);
      if (errorAPI !== false) {
        errorAPI.modal !== null &&
          dispatch(openModal({ id: errorAPI.modal, param: { tariffCaption: "Гости" } }));
        dispatch(error({ ...errorAPI }));
        return;
      }

      if (result.hasOwnProperty("visits")) {
        return dispatch(success(result.visits));
      }

      return dispatch(success([]));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e, isTryAgain: false }));
    }
  };

  function request(id) {
    return { type: PROFILE_VIEW_PAGE_REQUEST, id };
  }

  function success(payload) {
    return { type: PROFILE_VIEW_PAGE_SUCCESS, payload };
  }

  function error(error) {
    return { type: PROFILE_VIEW_PAGE_ERROR, error };
  }
}
