import React, { useCallback, useEffect } from "react";
import { Group, Placeholder } from "@vkontakte/vkui";
import WLFPopularAudioItem from "../WLFPopularAudioItem/WLFPopularAudioItem";
import HeadTitle from "template/components/HeadTitle/HeadTitle";
import { useDispatch } from "react-redux";
import { fetchPopularAudio, showMorePopularAudio } from "store/actions/tool/whoListenFriends";
import { useSelector } from "react-redux";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import { Icon56MusicOutline } from "@vkontakte/icons";
import { useShowMore } from "hooks";

const WLFPopularAudioList = (props) => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const showCount = useSelector((state) => state.toolWhoListenFriends.popularAudio.showCount);
  const loading = useSelector((state) => state.toolWhoListenFriends.popularAudio.loading);
  const error = useSelector((state) => state.toolWhoListenFriends.popularAudio.error);
  const musics = useSelector((state) => state.toolWhoListenFriends.popularAudio.payload);

  const handleFetchPopularAudio = useCallback(() => dispatch(fetchPopularAudio()), [dispatch]);
  const handleShowMorePopularAudio = useCallback(
    () => dispatch(showMorePopularAudio()),
    [dispatch]
  );

  useEffect(() => {
    handleFetchPopularAudio();
  }, [handleFetchPopularAudio]);

  useShowMore({
    items: musics,
    showCount: showCount,
    maxLength: musics !== null ? musics.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMorePopularAudio,
  });

  const renderAudio = () => {
    return musics.length > 0 ? (
      musics.map((music, i) => {
        const id = music.vk_id;
        const title = music.title;
        const artist = music.artist;
        const duration = music.duration;
        const listeningFriends = music.listening_friends;

        if (i < showCount) {
          return (
            <WLFPopularAudioItem
              key={i}
              id={id}
              title={title}
              artist={artist}
              duration={duration}
              listeningFriends={listeningFriends}
              openModal={props.openModal}
            />
          );
        }

        return false;
      })
    ) : (
      <Placeholder icon={<Icon56MusicOutline />}>Популярные аудио не найдены</Placeholder>
    );
  };

  return (
    <>
      <Group style={{ marginBottom: "20px" }}>
        <HeadTitle>Популярные аудио</HeadTitle>
        {musics !== null && renderAudio()}
        {renderLoader({ loading: loading })}

        {renderErrorPlaceholder({
          error: error,
          loading: loading,
          isTryAgainHandler: handleFetchPopularAudio,
        })}
      </Group>
    </>
  );
};

export default WLFPopularAudioList;
