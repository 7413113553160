import React from "react";
import { Search } from "@vkontakte/vkui";
import Icon24Filter from "@vkontakte/icons/dist/24/filter";

const GuestSearch = (props) => {
  return (
    <div style={{ marginTop: "8px" }}>
      <Search
        value={props.value}
        icon={<Icon24Filter />}
        onChange={props.onChange}
        onIconClick={props.onIconClick}
      />
    </div>
  );
};

export default GuestSearch;
