import React from "react";
import { Avatar, Group, SimpleCell } from "@vkontakte/vkui";
import { Icon28ChevronRightOutline } from "@vkontakte/icons";
import { PANEL_PROFILE } from "views/profile/panels/constPanels";
import { VIEW_TOOL } from "views/constViews";

const AUserInfoTopPanel = (props) => {
  return (
    <Group separator="hide">
      <SimpleCell
        before={<Avatar size={72} src={props.photo} />}
        after={<Icon28ChevronRightOutline fill="#B8C1CC" />}
        description={props.description}
        onClick={() =>
          props.openView({
            view: VIEW_TOOL,
            panel: PANEL_PROFILE,
            param: {
              activeProfileId: props.id,
            },
          })
        }
      >
        {props.title}
      </SimpleCell>
    </Group>
  );
};

export default AUserInfoTopPanel;
