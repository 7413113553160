import {
  PERIOD_WEEK,
  PERIOD_TODAY,
  PERIOD_MONTH,
  PERIOD_CUSTOM,
  PERIOD_TWO_WEEK,
} from "./constPeriod";
import { dateToUnixTime } from "helpers";

export function getPeriod(period) {
  let atMin = null;
  let atMax = null;

  if (period.name === PERIOD_CUSTOM) {
    atMin = period.atMin;
    atMax = period.atMax;
    // Указать начальные последние значения дня
    atMin.setHours(0, 0, 0, 0);
    atMax.setHours(23, 59, 59, 999);
  } else if (period.name === PERIOD_MONTH) {
    atMin = new Date();
    const oldMonthDay = new Date(atMin.getFullYear(), atMin.getMonth() - 1, 0).getDate(); // Получить количество дней прошлого месяца
    atMin.setDate(atMin.getDate() - oldMonthDay); // Вычесть количество дней с прошлого месяца
    atMin.setHours(0, 0, 0, 0);
    atMax = new Date();
  } else if (period.name === PERIOD_TODAY) {
    atMin = new Date();
    atMin.setHours(0, 0, 0, 0);
    atMax = new Date();
    atMax.setHours(23, 59, 59, 999);
  } else if (period.name === PERIOD_WEEK) {
    atMin = new Date();
    atMin.setDate(atMin.getDate() - 7);
    atMin.setHours(0, 0, 0, 0);
    atMax = new Date();
  } else if (period.name === PERIOD_TWO_WEEK) {
    atMin = new Date();
    atMin.setDate(atMin.getDate() - 14);
    atMin.setHours(0, 0, 0, 0);
    atMax = new Date();
  }
  if (atMin !== null && atMax !== null) {
    atMax = dateToUnixTime(atMax);
    atMin = dateToUnixTime(atMin);
  }

  return { atMin, atMax };
}
