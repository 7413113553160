import { Button, Panel } from "@vkontakte/vkui";
import { checkVKMethodError } from "api";
import { VkBridgeSend } from "api/vk";
import { withRouter } from "hoc";
import CoinImg from "assets/img/education/step6/coin.png";
import React, { useEffect, useState } from "react";
import EducationStepWithLogo from "views/education/components/EducationStepWithLogo/EducationStepWithLogo";
// import { PANEL_STEP7 } from "../constPanels";
import { CSSTransition } from "react-transition-group";
import styles from "./step6.module.scss";
import { APIEye3EducationProfile } from "api/eye3";
import { checkedAccessProc } from "store/actions/myProfile";
import { useDispatch, useSelector } from "react-redux";
import { STORY_GUEST } from "stories/constStories";
import { PANEL_BOT_CONNECT } from "views/guest/panels/constPanels";

const PanelStep6 = (props) => {
  const dispatch = useDispatch();
  const [inAnimation, setInAnimation] = useState(false);
  const payloadEYE3 = useSelector((state) =>
    state.myProfile.payloadEYE3 ? state.myProfile.payloadEYE3 : null
  );
  const loading = useSelector((state) => state.myProfile.checkedAccess.loading);

  const next = () => {
    VkBridgeSend("VKWebAppAllowMessagesFromGroup", {
      group_id: 194197472,
    }).then((res) => {
      if (checkVKMethodError(res)) {
        return;
      }

      APIEye3EducationProfile({ education: 1 }).then(() => {
        if (!payloadEYE3.user.is_bot_connect && !loading) {
          props.openStory({ story: STORY_GUEST, panel: PANEL_BOT_CONNECT });
          dispatch(checkedAccessProc());
        } else {
          if (!loading) dispatch(checkedAccessProc());
        }
      });
    });
  };

  useEffect(() => {
    setInAnimation(true);
  }, []);

  return (
    <Panel id={props.id}>
      <EducationStepWithLogo
        header={
          <CSSTransition
            in={inAnimation}
            timeout={200}
            classNames={{
              enterActive: styles.enterActive,
              enterDone: styles.enterDone,
            }}
          >
            <div>Обучение успешно пройдено!</div>
          </CSSTransition>
        }
        description={
          <CSSTransition
            in={inAnimation}
            timeout={600}
            classNames={{
              enterActive: styles.enterActive,
              enterDone: styles.enterDone,
            }}
          >
            <div>
              На твой счёт начислено 50 монет – их хватит для покупки тарифа «Гости»
              <br /> <br />
              Наши инструменты присылают результат в личные сообщения – это очень удобно.
              <br /> <br />
              Разреши нашему сообществу присылать тебе сообщения, будь на связи 👇🏽
            </div>
          </CSSTransition>
        }
        icon={<img src={CoinImg} width={60} height={60} alt=""></img>}
        action={
          <CSSTransition
            in={inAnimation}
            timeout={1000}
            classNames={{
              enterActive: styles.enterActive,
              enterDone: styles.enterDone,
            }}
          >
            <Button mode="primary" size="xl" style={{ width: "100%" }} onClick={next}>
              Разрешить сообщения
            </Button>
          </CSSTransition>
        }
      />
    </Panel>
  );
};

export default withRouter(PanelStep6);
