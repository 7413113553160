import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Panel } from "@vkontakte/vkui";
import HeadTitle from "template/components/HeadTitle/HeadTitle";
import MSGeoSettingForm from "./components/MSGeoSettingForm/MSGeoSettingForm";
import { withRouter } from "hoc";

const PanelMSGeoSettings = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Семейное положение</PanelHeaderWithPath>
      <HeadTitle>Параметры поиска сменивших семейное положение</HeadTitle>
      <MSGeoSettingForm openView={props.openView} />
    </Panel>
  );
};

export default withRouter(PanelMSGeoSettings);
