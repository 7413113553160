import { queryEye3 } from "api";

async function APIEye3SubscriptionsFilterTool({
  sex,
  ageFrom,
  ageTo,
  countryId,
  cityId,
  relation,
}) {
  try {
    const response = await queryEye3({
      method: "tool.subscriptions_filter",
      get: {
        sex: sex,
        age_from: ageFrom,
        age_to: ageTo,
        country_id: countryId,
        city_id: cityId,
        relation: relation,
      },
      access: { isBotConnect: true, isLicense: true, isLimit: true },
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3SubscriptionsFilterTool;
