import { queryEye3 } from "api";

async function APIEye3SetUsersTrackOnline({ userIds }) {
  try {
    const response = await queryEye3({
      method: "trackonline.set_users",
      get: {
        user_ids: userIds,
      },
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3SetUsersTrackOnline;
