import React from "react";
import { Avatar, SimpleCell } from "@vkontakte/vkui";
import Icon16Chevron from "@vkontakte/icons/dist/16/chevron";
import { PANEL_WH__WHO_HIDDEN_ANOTHER_USER_INFO } from "views/tool/panels/constPanels";
import { VIEW_TOOL } from "views/constViews";

const WhoHiddenAnotherUserItem = (props) => {
  return (
    <SimpleCell
      before={<Avatar size={48} src={props.photo} />}
      after={<Icon16Chevron fill="#B8C1CC" />}
      description={props.description}
      onClick={() =>
        props.openView({
          view: VIEW_TOOL,
          panel: PANEL_WH__WHO_HIDDEN_ANOTHER_USER_INFO,
          param: {
            activeUserId: props.id,
          },
        })
      }
    >
      {props.title}
    </SimpleCell>
  );
};

export default WhoHiddenAnotherUserItem;
