import { Div } from "@vkontakte/vkui";
import React from "react";

const CardPayList = (props) => {
  return (
    <Div style={{ paddingTop: "0px", paddingBottom: "6px" }}>
      <div style={{ display: "flex" }}>{props.children}</div>
    </Div>
  );
};

export default CardPayList;
