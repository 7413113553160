import React from "react";

import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import Icon16Chevron from "@vkontakte/icons/dist/16/chevron";
import {
  PANEL_WH__WHO_HIDDEN_ME,
  PANEL_WH__WHO_HIDDEN_ANOTHER_USER,
  PANEL_WH__WHOM_HIDDEN_IM,
  PANEL_WH__WHOM_HIDDEN_ANOTHER_USER,
} from "../constPanels";

import { Group, Panel, SimpleCell } from "@vkontakte/vkui";
import WhoHiddenIcon from "assets/img/tool/whoHidden/banner/hidden.png";
import { VIEW_TOOL } from "views/constViews";
import ToolBanner from "template/components/ToolBanner/ToolBanner";
import { withRouter } from "hoc";

const PanelWhoHidden = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Скрытые пользователи</PanelHeaderWithPath>
      <ToolBanner
        tool="toolWhoHidden"
        icon={WhoHiddenIcon}
        text="Узнай кто кто скрыл тебя или другого пользователя из своей ленты!"
      />
      <Group separator="hide">
        <SimpleCell
          after={<Icon16Chevron fill="#B8C1CC" />}
          onClick={() => props.openView({ view: VIEW_TOOL, panel: PANEL_WH__WHO_HIDDEN_ME })}
        >
          Кто скрыл меня
        </SimpleCell>
        <SimpleCell
          after={<Icon16Chevron fill="#B8C1CC" />}
          onClick={() =>
            props.openView({ view: VIEW_TOOL, panel: PANEL_WH__WHO_HIDDEN_ANOTHER_USER })
          }
        >
          Кто скрыл другого пользователя
        </SimpleCell>
        <SimpleCell
          after={<Icon16Chevron fill="#B8C1CC" />}
          onClick={() => props.openView({ view: VIEW_TOOL, panel: PANEL_WH__WHOM_HIDDEN_IM })}
        >
          Кого скрыл я
        </SimpleCell>
        <SimpleCell
          after={<Icon16Chevron fill="#B8C1CC" />}
          onClick={() =>
            props.openView({ view: VIEW_TOOL, panel: PANEL_WH__WHOM_HIDDEN_ANOTHER_USER })
          }
        >
          Кого скрыл другой пользователь
        </SimpleCell>
      </Group>
    </Panel>
  );
};

export default withRouter(PanelWhoHidden);
