import {
  PROFILE_GIFT_REQUEST,
  PROFILE_GIFT_SUCCESS,
  PROFILE_GIFT_ERROR,
  PROFILE_GIFT_SHOW_MORE,
} from "../../constType";

import { APIEye3GetGiftsTool } from "api/eye3";
import { delayMethodGetUsersVKApi } from "api";
import { openModal } from "../page";
import { checkEYE3ApiError, defaultErrorProperty } from "api/eye3/responseHandler";

export function fetchGift() {
  return async (dispatch, getState) => {
    try {
      const state = getState().profileGift;
      const page = getState().page;
      const profile = getState().profile;

      const id = profile.history[page.activeStory].user.id;

      if (state.activeUserId === profile.history[page.activeStory].user.id) {
        // Если предыдущий открытый профиль равен текущему открытому профилю
        return false;
      }

      dispatch(request(id));
      const gifts = await APIEye3GetGiftsTool({ userId: id });
      let result = [];

      // Проверка ошибок в API
      const errorAPI = checkEYE3ApiError(gifts);
      if (errorAPI !== false) {
        errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
        dispatch(error({ ...errorAPI }));
        return;
      }

      let userIds = gifts.items.map((user) => user.vk_id);

      const userInfo = await delayMethodGetUsersVKApi({
        userIds,
        fields: ["photo_100"],
      });

      const userItems = userInfo;

      gifts.items.forEach((gift) => {
        userItems.forEach((user) => {
          if (gift.vk_id === user.id) {
            result.push({
              vk_id: user.id,
              name: user.first_name + " " + user.last_name,
              date: gift.date,
              type: gift.type,
              text: gift.text,
              photo_user_url: user.photo_100,
              photo_gift_url: gift.photo,
            });
            return;
          }
        });
      });

      return dispatch(success(result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e, isTryAgain: false }));
    }
  };

  function request(id) {
    return { type: PROFILE_GIFT_REQUEST, id };
  }

  function success(payload) {
    return { type: PROFILE_GIFT_SUCCESS, payload };
  }

  function error(error) {
    return { type: PROFILE_GIFT_ERROR, error };
  }
}

export function showMoreGift() {
  return { type: PROFILE_GIFT_SHOW_MORE };
}
