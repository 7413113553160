import React from "react";
import { Link, SimpleCell } from "@vkontakte/vkui";

import Icon28HomeOutline from "@vkontakte/icons/dist/28/home_outline";
import Icon28PhoneOutline from "@vkontakte/icons/dist/28/phone_outline";
import Icon28SmileOutline from "@vkontakte/icons/dist/28/smile_outline";
import Icon28TargetOutline from "@vkontakte/icons/dist/28/target_outline";
import Icon20EducationOutline from "@vkontakte/icons/dist/20/education_outline";
import Icon20WorkOutline from "@vkontakte/icons/dist/20/work_outline";
import Icon28BrainOutline from "@vkontakte/icons/dist/28/brain_outline";

import {
  INFO_GENERAL,
  INFO_CONTACT,
  INFO_INTERESTS,
  INFO_EDUCATION,
  INFO_CAREER,
  INFO_MILITARY_SERVICE,
  INFO_LIFE_POSITION,
} from "../../constInfo";

const InfoHistoryItem = (props) => {
  const getTypeIcon = () => {
    switch (props.type) {
      case INFO_GENERAL:
        return <Icon28HomeOutline fill={"#3F8AE0"} />;
      case INFO_CONTACT:
        return <Icon28PhoneOutline fill={"#3F8AE0"} />;
      case INFO_INTERESTS:
        return <Icon28SmileOutline fill={"#3F8AE0"} />;
      case INFO_EDUCATION:
        return <Icon20EducationOutline width={28} height={28} fill={"#3F8AE0"} />;
      case INFO_CAREER:
        return <Icon20WorkOutline width={28} height={28} fill={"#3F8AE0"} />;
      case INFO_MILITARY_SERVICE:
        return <Icon28TargetOutline fill={"#3F8AE0"} />;
      case INFO_LIFE_POSITION:
        return <Icon28BrainOutline fill={"#3F8AE0"} />;
      default:
        return "";
    }
  };

  const getTypeTitle = () => {
    switch (props.type) {
      case INFO_GENERAL:
        return "Основное";
      case INFO_CONTACT:
        return "Контакты";
      case INFO_INTERESTS:
        return "Интересы";
      case INFO_EDUCATION:
        return "Образование";
      case INFO_CAREER:
        return "Карьера";
      case INFO_MILITARY_SERVICE:
        return "Военная служба";
      case INFO_LIFE_POSITION:
        return "Жизненная позиция";
      default:
        return "";
    }
  };

  const getItemValue = (key, value) => {
    if (key === "photo_id") {
      return "";
    } else if (key === "schools" && value instanceof Array) {
      return value.map((item, i) => {
        return (
          <div key={i}>
            {item.hasOwnProperty("name") ? "Наименование школы: " + item.name : ""}
            {item.hasOwnProperty("name") ? <br></br> : ""}
            {item.hasOwnProperty("year_from") ? "Год начала обучения: " + item.year_from : ""}
            {item.hasOwnProperty("year_from") ? <br></br> : ""}
            {item.hasOwnProperty("year_to") ? "Год окончания обучения: " + item.year_to : ""}
            {item.hasOwnProperty("year_to") ? <br></br> : ""}
            {item.hasOwnProperty("year_graduated") ? "Год выпуска: " + item.year_graduated : ""}
            {item.hasOwnProperty("year_graduated") ? <br></br> : ""}
            {item.hasOwnProperty("class") ? "Буква класса: " + item.class : ""}
          </div>
        );
      });
    } else if (key === "occupation" && value !== null && typeof value === "object") {
      function getTypeOccupation(type) {
        switch (type) {
          case "work":
            return "Работа";
          case "school":
            return "Среднее образование";
          case "university":
            return "Высшее образование";
          default:
            return type;
        }
      }
      return (
        <div>
          {value.hasOwnProperty("type") ? getTypeOccupation(value.type) : ""}
          {": "}
          {value.hasOwnProperty("name") ? value.name : ""}
        </div>
      );
    } else if (key === "relatives" && value instanceof Array) {
      function getTypeRelatives(type) {
        switch (type) {
          case "child":
            return "Сын/Дочь";
          case "sibling":
            return "Брат/Сестра";
          case "parent":
            return "Отец/Мать";
          case "grandparent":
            return "Дедушка/Бабушка";
          case "grandchild":
            return "Внук/Внучка";
          default:
            return type;
        }
      }

      return value.map((item, i) => {
        return (
          <div key={i}>
            {item.hasOwnProperty("type") ? getTypeRelatives(item.type) : ""}:
            <Link href={"https://vk.com/id" + item.id} target="_blank">
              {" "}
              id{item.id}
            </Link>
          </div>
        );
      });
    }

    return value;
  };

  return (
    <SimpleCell disabled multiline after={getTypeIcon()}>
      <span style={{ color: "#818C99" }}>Изменения в блоке «{getTypeTitle()}»:</span>
      <br></br>
      {props.itemNote}
      {": "}
      {getItemValue(props.itemKey, props.itemValue)}
    </SimpleCell>
  );
};

export default InfoHistoryItem;
