import { TABS_ALL } from "views/guest/panels/main/constTabs";
import {
  GUEST_REQUEST,
  GUEST_SUCCESS,
  GUEST_ERROR,
  GUEST_SHOW_MORE,
  GUEST_SEARCH_REQUEST,
  GUEST_SEARCH_SUCCESS,
  GUEST_SEARCH_ERROR,
  GUEST_FILTER_REQUEST,
  GUEST_FILTER_SUCCESS,
  GUEST_FILTER_ERROR,
  GUEST_TABS,
} from "../constType";

const initialState = {
  payload: null,
  cachePayload: null,
  showCount: 20,
  filter: "",
  tabs: TABS_ALL,
  search: "",
  loading: false,
  error: null,
};

export default function guestV2Reducer(state = initialState, action) {
  switch (action.type) {
    case GUEST_REQUEST:
      return {
        ...state,
        payload: null,
        loading: true,
        error: null,
      };
    case GUEST_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        cachePayload: action.payload,
        loading: false,
      };
    case GUEST_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case GUEST_SHOW_MORE:
      return {
        ...state,
        showCount: parseInt(state.showCount) + 20,
      };
    case GUEST_TABS:
      return {
        ...state,
        payload: null,
        loading: true,
        showCount: 20,
        tabs: action.tabs,
      };
    case GUEST_SEARCH_REQUEST:
      return {
        ...state,
        loading: true,
        search: action.search,
        showCount: 20,
        payload: null,
        error: null,
      };
    case GUEST_SEARCH_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        loading: false,
      };
    case GUEST_SEARCH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case GUEST_FILTER_REQUEST:
      return {
        ...state,
        loading: true,
        filter: action.filter,
        showCount: 20,
        payload: null,
        error: null,
      };
    case GUEST_FILTER_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        cachePayload: action.payload,
        loading: false,
      };
    case GUEST_FILTER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
