import React from "react";
import { ModalCard } from "@vkontakte/vkui";
import {
  PANEL_ADMIN_COMMUNITY,
  PANEL_COMMON_COMMUNITY,
  PANEL_COMMON_FRIENDS,
  PANEL_DELETED_FRIENDS,
  PANEL_FRIENDS_IN_APP,
  PANEL_PAIR,
  PANEL_POSTING,
  PANEL_WHO_LISTEN_FRIENDS,
  PANEL_WHO_NOT_ONLINE,
} from "../../panels/constPanels";
import {
  Icon28WriteSquareOutline,
  Icon28AdvertisingOutline,
  Icon28LikeOutline,
  Icon28MusicOutline,
  Icon28MuteOutline,
  Icon28Users3Outline,
  Icon20UsersOutline,
  Icon28FireOutline,
  Icon28DeleteOutline,
  Icon28ServicesOutline,
} from "@vkontakte/icons";
import ToolModalItem from "views/tool/panels/main/components/ToolModalItem/ToolModalItem";

const MenuToolListFriendsModal = (props) => {
  const caption = props.modalParam ? props.modalParam.caption : "";

  return (
    <ModalCard
      id={props.id}
      caption={caption}
      onClose={props.handleCloseModal}
      header={
        <span
          style={{
            color: "#818C99",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "13px",
            lineHeight: "16px",
            letterSpacing: "0.2px",
            textTransform: "uppercase",
          }}
        >
          ДРУЗЬЯ
        </span>
      }
    >
      <ToolModalItem
        title={"Администрируемые"}
        icon={<Icon28AdvertisingOutline width={30} height={30} fill={"#4395F3"} />}
        openView={props.openView}
        closeModal={props.handleCloseModal}
        to={PANEL_ADMIN_COMMUNITY}
      />
      <ToolModalItem
        title={"Пара"}
        icon={<Icon28LikeOutline width={30} height={30} fill={"#ED5CCD"} />}
        openView={props.openView}
        closeModal={props.handleCloseModal}
        to={PANEL_PAIR}
      />
      <ToolModalItem
        title={"Что слушают друзья"}
        icon={<Icon28MusicOutline width={30} height={30} fill={"#4F84E9"} />}
        openView={props.openView}
        closeModal={props.handleCloseModal}
        to={PANEL_WHO_LISTEN_FRIENDS}
      />
      <ToolModalItem
        title={"Кто давно не был онлайн"}
        icon={<Icon28MuteOutline width={30} height={30} fill={"#18A615"} />}
        to={PANEL_WHO_NOT_ONLINE}
        closeModal={props.handleCloseModal}
        openView={props.openView}
      />
      <ToolModalItem
        title={"Общие сообщества"}
        icon={<Icon28Users3Outline width={30} height={30} fill={"#FCB01C"} />}
        to={PANEL_COMMON_COMMUNITY}
        closeModal={props.handleCloseModal}
        openView={props.openView}
      />
      <ToolModalItem
        title={"Общие друзья"}
        to={PANEL_COMMON_FRIENDS}
        icon={<Icon20UsersOutline width={30} height={30} fill={"#3B8FF1"} />}
        closeModal={props.handleCloseModal}
        openView={props.openView}
      />
      <ToolModalItem
        title={"Популярные друзья"}
        icon={<Icon28FireOutline width={30} height={30} fill={"#FB8643"} />}
        closeModal={props.handleCloseModal}
        openView={props.openView}
      />
      <ToolModalItem
        title={"Удаленные"}
        icon={<Icon28DeleteOutline width={30} height={30} fill={"#3AB2F6"} />}
        openView={props.openView}
        closeModal={props.handleCloseModal}
        to={PANEL_DELETED_FRIENDS}
      />
      <ToolModalItem
        title={"Друзья в приложении"}
        icon={<Icon28ServicesOutline width={30} height={30} fill={"#458AF2"} />}
        openView={props.openView}
        closeModal={props.handleCloseModal}
        to={PANEL_FRIENDS_IN_APP}
      />
      <ToolModalItem
        title={"Постинг"}
        icon={<Icon28WriteSquareOutline width={30} height={30} fill={"#3AB2F6"} />}
        openView={props.openView}
        closeModal={props.handleCloseModal}
        to={PANEL_POSTING}
      />
    </ModalCard>
  );
};

export default MenuToolListFriendsModal;
