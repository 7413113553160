import React from "react";

import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";

import JoinCommunityList from "./components/JCList/JCList";
import { Panel } from "@vkontakte/vkui";
import JoinIcon from "assets/img/tool/joinCommunity/banner/join.png";
import ToolBanner from "template/components/ToolBanner/ToolBanner";
import { withRouter } from "hoc";

const PanelJoinCommunity = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Вступления</PanelHeaderWithPath>
      <ToolBanner
        tool="toolJoinCommunity"
        icon={JoinIcon}
        text="Узнавайте в какие сообщества вступают/покидают выбранные пользователи"
      />
      <JoinCommunityList
        openView={props.openView}
        openModal={props.openModal}
        closeModal={props.closeModal}
      />
    </Panel>
  );
};

export default withRouter(PanelJoinCommunity);
