import { queryEye3 } from "api";

async function APIEye3WithdrawToMoneyPartner({ sum, card }) {
  try {
    const response = await queryEye3({
      method: "partner.withdraw_to_money",
      get: { sum: sum, card: card },
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3WithdrawToMoneyPartner;
