import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Group, Placeholder, Header } from "@vkontakte/vkui";
import TWhoCrossedOverItem from "../TWhoCrossedOverItem/TWhoCrossedOverItem";

import { fetchWhoCrossedOver } from "store/actions/tool/trap";
import { convertMonthDay, unixTimeToDate } from "helpers";
import EmptyWhoCrossedOverIcon from "assets/img/tool/trap/emptyWhoCrossedOver.png";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";

const TWhoCrossedOverList = (props) => {
  const dispatch = useDispatch();
  const handleFetchWhoCrossedOver = useCallback(() => dispatch(fetchWhoCrossedOver()), [dispatch]);
  const trap = useSelector((state) => state.toolTrap.whoCrossedOver.payload);
  const loading = useSelector((state) => state.toolTrap.whoCrossedOver.loading);
  const error = useSelector((state) => state.toolTrap.whoCrossedOver.error);

  useEffect(() => {
    handleFetchWhoCrossedOver();
  }, [handleFetchWhoCrossedOver]);

  const renderTrap = () => {
    return trap.length > 0 ? (
      trap.map((t, i) => {
        const id = t.vk_id;
        const name = t.name;
        const url = t.url;
        const at = unixTimeToDate(t._at);
        const photo = t.photo_url ? t.photo_url : "";

        return (
          <TWhoCrossedOverItem
            id={id}
            key={i}
            title={name}
            description={"по ссылке " + url}
            bottomContent={convertMonthDay(at)}
            photo={photo}
            openView={props.openView}
          />
        );
      })
    ) : (
      <Placeholder icon={<img src={EmptyWhoCrossedOverIcon} width="50" height="50" alt=""></img>}>
        Ни один пользователь не попал в вашу ловшку
      </Placeholder>
    );
  };

  return (
    <>
      <Group
        style={{ marginBottom: "20px" }}
        header={<Header mode="secondary">Перешли по ссылке</Header>}
      >
        {trap !== null && renderTrap()}
      </Group>
      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchWhoCrossedOver,
      })}
    </>
  );
};

export default TWhoCrossedOverList;
