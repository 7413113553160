import React from "react";

import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import PFBanner from "./components/PFBanner/PFBanner";
import PFUserList from "./components/PFUserList/PFUserList";
import { Panel } from "@vkontakte/vkui";
import { withRouter } from "hoc";

const PanelPopularFriends = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Популярные друзья</PanelHeaderWithPath>
      <PFBanner />
      <PFUserList openView={props.openView} />
    </Panel>
  );
};

export default withRouter(PanelPopularFriends);
