import { Div } from "@vkontakte/vkui";
import { convertMonthDay, unixTimeToDate } from "helpers";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import { useShowMore } from "hooks";
import React, { useEffect } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRefererHistory, showMoreRefererHistory } from "store/actions/partner";
import HeadTitle from "template/components/HeadTitle/HeadTitle";
import HistoryItem from "../HistoryItem/HistoryItem";
import styles from "./HistoryList.module.scss";

const HistoryList = () => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const showCount = useSelector((state) => state.partner.refererHistory.showCount);
  const loading = useSelector((state) => state.partner.refererHistory.loading);
  const error = useSelector((state) => state.partner.refererHistory.error);
  const history = useSelector((state) => state.partner.refererHistory.payload);
  const handleFetchRefererHistory = useCallback(() => dispatch(fetchRefererHistory()), [dispatch]);
  const handleShowMoreRefererHistory = useCallback(
    () => dispatch(showMoreRefererHistory()),
    [dispatch]
  );

  let lastAt = new Date();

  useEffect(() => {
    handleFetchRefererHistory();
  }, [handleFetchRefererHistory]);

  useShowMore({
    items: history,
    showCount: showCount,
    maxLength: history !== null ? history.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMoreRefererHistory,
  });

  const getSeparate = (value) => {
    return (
      <Div>
        <div className={styles.separate}>{convertMonthDay(value)}</div>
      </Div>
    );
  };

  const renderHistory = () => {
    return history.map((item, i) => {
      const at = unixTimeToDate(item._at);
      const count = item.count;
      const txt = item.txt;

      let separate = getSeparate(at);

      if (lastAt.getDate() === at.getDate() && i !== 0) {
        separate = "";
      }

      lastAt = at;

      if (i < showCount) {
        return (
          <div key={i}>
            {separate}
            <HistoryItem key={i} count={count} txt={txt} />
          </div>
        );
      }
      return false;
    });
  };

  return (
    <>
      <HeadTitle>История операций</HeadTitle>
      {renderErrorPlaceholder({
        loading: loading,
        error: error,
        isTryAgainHandler: handleFetchRefererHistory,
      })}
      <div style={{ marginBottom: "20px" }}>{history !== null && renderHistory()}</div>
      {renderLoader({
        loading: loading,
      })}
    </>
  );
};

export default HistoryList;
