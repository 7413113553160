import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Group, Div, Placeholder } from "@vkontakte/vkui";
import PInfoTopPanel from "../PInfoTopPanel/PInfoTopPanel";
import Icon56Users3Outline from "@vkontakte/icons/dist/56/users_3_outline";
import PInfoItem from "../PInfoItem/PInfoItem";

import {
  fetchPostingInfo,
  setPeriodPostingInfo,
  loadMorePostingInfo,
} from "store/actions/tool/posting";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import { PANEL_POSTING__INFO } from "../../../../constPanels";
import { renderPeriodV2 } from "template/popouts/DateChangePeriod/views";
import { VIEW_TOOL } from "views/constViews";
import PopoutTemplateDateChangePeriod from "template/popouts/DateChangePeriod/DateChangePeriod";
import SeparateFilter from "template/components/SeparateFilter/SeparateFilter";
import { convertMonthDay, unixTimeToDate } from "helpers";
import { declOfNum } from "helpers";
import { useLoadMore } from "hooks";

const PInfoList = (props) => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const activeUserId = useSelector((state) => state.toolPosting.posts.activeUserId);
  const activeUserTitle = useSelector((state) => state.toolPosting.posts.activeUserTitle);
  const activeUserPhoto = useSelector((state) => state.toolPosting.posts.activeUserPhoto);
  const posts = useSelector((state) => state.toolPosting.posts.payload);
  const loading = useSelector((state) => state.toolPosting.posts.loading);
  const period = useSelector((state) => state.toolPosting.posts.period);
  const offset = useSelector((state) => state.toolPosting.posts.offset);
  const maxLength = useSelector((state) => state.toolPosting.posts.maxLength);
  const error = useSelector((state) => state.toolPosting.posts.error);
  const handleFetchPostingInfo = useCallback(() => dispatch(fetchPostingInfo()), [dispatch]);
  const handleLoadMorePostingInfo = useCallback(() => dispatch(loadMorePostingInfo()), [dispatch]);

  const handleSetPeriodPostingInfo = useCallback(
    (name, atMin = null, atMax = null) => dispatch(setPeriodPostingInfo(name, atMin, atMax)),
    [dispatch]
  );

  let lastAt = new Date();

  useLoadMore({
    items: posts,
    offset: offset,
    loading: loading,
    isTransitionEnd: isTransitionEnd,
    maxLength: maxLength,
    handler: handleLoadMorePostingInfo,
  });

  useEffect(() => {
    handleFetchPostingInfo();
  }, [handleFetchPostingInfo, period]);

  const renderUser = () => {
    const postsCount = maxLength;
    const postsCountStrFormat = postsCount
      ? postsCount + " " + declOfNum(postsCount, ["пост", "поста", "постов"])
      : "0 постов";

    return (
      <PInfoTopPanel
        id={activeUserId}
        title={activeUserTitle}
        photo={activeUserPhoto}
        bottomContent={postsCountStrFormat}
        openView={props.openView}
      />
    );
  };

  const renderPosts = () => {
    return posts.length > 0 ? (
      posts.map((item, i) => {
        const groupId = item.group_id;
        const groupName = item.group_name;
        const groupPhoto = item.group_photo_url;
        const typePage = item.type_page;
        const postId = item.post_id;
        const postDate = unixTimeToDate(item.post_date);
        const postText = item.post_text;
        const postAttachments = item.post_attachments;

        let separate = <SeparateFilter>{convertMonthDay(postDate)}</SeparateFilter>;

        if (lastAt.getDate() === postDate.getDate() && i !== 0) {
          separate = "";
        }

        lastAt = postDate;

        return (
          <div key={i}>
            {separate}
            <PInfoItem
              groupId={groupId}
              groupName={groupName}
              groupPhoto={groupPhoto}
              postId={postId}
              postText={postText}
              postDate={postDate}
              postAttachments={postAttachments}
              typePage={typePage}
            />
          </div>
        );
      })
    ) : (
      <Placeholder icon={<Icon56Users3Outline />}>Сообщества не найдены</Placeholder>
    );
  };

  const handlePeriod = () => {
    if (loading) return false;

    props.openPopout({
      popout: (
        <PopoutTemplateDateChangePeriod
          openView={props.openView}
          period={period} // Период из redux store
          setPeriod={handleSetPeriodPostingInfo} // action из redux
          backPanel={PANEL_POSTING__INFO}
          backView={VIEW_TOOL}
          panelTitle={"Постинг"}
          closePopout={props.closePopout}
        />
      ),
    });
  };

  return (
    <>
      {renderPeriodV2({ title: "Посты", period: period, handler: handlePeriod })}
      {posts !== null && error === null ? <Group separator="hide">{renderUser()}</Group> : ""}
      {posts !== null && error === null ? (
        <Group style={{ marginBottom: "20px" }}>
          <Div>{renderPosts()}</Div>
        </Group>
      ) : (
        ""
      )}
      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchPostingInfo,
      })}
    </>
  );
};

export default PInfoList;
