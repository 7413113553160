import bridge from "@vkontakte/vk-bridge";
import { getVKApiVersion, getVKToken } from "api";
import { errorHandler, responseHandler } from "./responseHandler";

async function VkBridgeSendApiMethod(method, params = {}) {
  try {
    params.access_token = getVKToken();
    params.v = getVKApiVersion();

    const response = await bridge.send("VKWebAppCallAPIMethod", {
      method: method,
      params: params,
    });

    responseHandler(method, response);
    return response;
  } catch (e) {
    errorHandler(method, e);
    return e;
  }
}

export default VkBridgeSendApiMethod;
