import {
  MY_PROFILE_AUTH_REQUEST,
  MY_PROFILE_AUTH_SUCCESS,
  MY_PROFILE_AUTH_ERROR,
  MY_PROFILE_SET_NOTIFICATION_REQUEST,
  MY_PROFILE_SET_NOTIFICATION_SUCCESS,
  MY_PROFILE_SET_NOTIFICATION_ERROR,
  MY_PROFILE_UPDATE_REQUEST,
  MY_PROFILE_UPDATE_SUCCESS,
  MY_PROFILE_UPDATE_ERROR,
  MY_PROFILE_SUBSCRIBE_GROUP_REQUEST,
  MY_PROFILE_SUBSCRIBE_GROUP_SUCCESS,
  MY_PROFILE_SUBSCRIBE_GROUP_ERROR,
  MY_PROFILE_LICENSE_REQUEST,
  MY_PROFILE_LICENSE_SUCCESS,
  MY_PROFILE_LICENSE_ERROR,
  MY_PROFILE_SET_LICENSE_RENEW_REQUEST,
  MY_PROFILE_SET_LICENSE_RENEW_SUCCESS,
  MY_PROFILE_SET_LICENSE_RENEW_ERROR,
  MY_PROFILE_CHECKED_ACCESS_REQUEST,
  MY_PROFILE_CHECKED_ACCESS_SUCCESS,
  MY_PROFILE_CHECKED_ACCESS_ERROR,
  MY_PROFILE_CHECKED_ACCESS_PROGRESS,
} from "../constType";

import { APIVkGetStories } from "api/vk/apiMethods";
import { delay } from "helpers";

import { checkVKMethodError, getSearchObject } from "api";

import { VkBridgeSend } from "api/vk";

import {
  APIEye3AccessProfile,
  APIEye3GetLicense,
  APIEye3GetProfile,
  APIEye3NotificationProfile,
  APIEye3SetRenewLicense,
  APIEye3StorySaveTool,
} from "api/eye3";

import { fetchFriends } from "./friend";

import { clearHistory, openStory } from "./page";
import { STORY_GUEST } from "stories/constStories";
import { PANEL_MAIN, PANEL_NOT_SUPPORTED } from "views/guest/panels/constPanels";

async function saveStories() {
  try {
    const tokenSettings = getSearchObject().vk_access_token_settings.split(",");

    if (!tokenSettings.includes("stories")) {
      return false;
    }

    const userStories = await APIVkGetStories({
      ownerId: getSearchObject().vk_user_id,
    });

    if (checkVKMethodError(userStories)) {
      //console.log('Ошибка при загрузке историй');
      return false;
    }

    let storiesItems = userStories.response.hasOwnProperty("items")
      ? userStories.response.items
      : userStories.response;

    if (storiesItems.length === 0) return false;

    storiesItems = storiesItems.map((s) => {
      if (s.type === "stories") {
        return s.stories;
      }
      return false;
    });

    if (storiesItems.length === 0) return false;

    //console.log('storiesItems: ', storiesItems[0]);

    const storiesItemsIds = storiesItems[0].map((story) => story.id).join(",");

    //console.log('storiesItemsIds: ', storiesItemsIds);

    await APIEye3StorySaveTool({
      ids: storiesItemsIds,
    });

    //console.log('storiesSave: ', stories);

    /*const getStories = await APIEye3StoryGetTool();
        console.log('getStories: ', getStories);*/
  } catch (e) {
    throw e;
  }
}

export function checkedAccessProc() {
  return async (dispatch, getState) => {
    try {
      await dispatch(request());

      async function process() {
        try {
          if (getState().myProfile.checkedAccess.loading) {
            const accessInfo = await APIEye3AccessProfile();
            //console.log('accessInfo: ', accessInfo);

            if (accessInfo.status && accessInfo.is_bot_connect === false) {
              await dispatch(progress(accessInfo));
              await delay(2000);
              await process();
            } else {
              await dispatch(success());

              // Получение списка друзей
              await dispatch(fetchFriends());
              // Получение списка тарифов
              await dispatch(fetchLicense());
              // Обновление профиля
              await dispatch(updateMyProfile());
              // Сохранение сторисов пользователя (при наличии)
              await saveStories();

              await dispatch(clearHistory());
              await dispatch(openStory({ story: STORY_GUEST, panel: PANEL_MAIN }));
              return;
            }
          }
        } catch (e) {
          dispatch(error(e));
        }
      }

      await process();
    } catch (e) {
      dispatch(error(e));
    }
  };

  function request() {
    return { type: MY_PROFILE_CHECKED_ACCESS_REQUEST };
  }

  function progress(payload) {
    return { type: MY_PROFILE_CHECKED_ACCESS_PROGRESS, payload };
  }

  function success() {
    return { type: MY_PROFILE_CHECKED_ACCESS_SUCCESS };
  }

  function error(e) {
    return { type: MY_PROFILE_CHECKED_ACCESS_ERROR, error: e };
  }
}

// Авторизация приложения
export function auth(isBotConnect = null) {
  return async (dispatch, getState) => {
    try {
      await dispatch(request());

      //
      await dispatch(openStory({ story: STORY_GUEST, panel: PANEL_NOT_SUPPORTED }));
      await dispatch(success(null, null));
      return;
      //

      // Получение токена
      /*const payloadVKToken = await VkBridgeSend("VKWebAppGetAuthToken", {
        app_id: parseInt(getSearchObject().vk_app_id),
        scope: getVKScope(),
      });
      setVKToken(payloadVKToken);

      const ref = getHashRef();
      if (ref !== false) {
        dispatch(activeRef(ref));
      }

      const payloadVK = await VkBridgeSend("VKWebAppGetUserInfo");
      if (checkVKMethodError(payloadVK)) {
        throw new Error();
      }

      const payloadEYE3 = await APIEye3GetProfile();

      if (payloadEYE3.user.is_show_education) {
        // Убрать !
        await dispatch(openStory({ story: STORY_EDUCATION, panel: PANEL_STEP1 }));
        await dispatch(success(payloadVK, payloadEYE3));
        return;
      }

      if (!payloadEYE3.user.is_bot_connect && isBotConnect === null) {
        await dispatch(openStory({ story: STORY_GUEST, panel: PANEL_BOT_CONNECT }));
        await dispatch(success(payloadVK, payloadEYE3));
        await dispatch(checkedAccessProc());
        return;
      }

      // Получение списка друзей
      await dispatch(fetchFriends());
      // Получение списка тарифов
      await dispatch(fetchLicense());
      // Сохранение сторисов пользователя (при наличии)
      await saveStories();

      await dispatch(success(payloadVK, payloadEYE3));*/
    } catch (e) {
      dispatch(error(e));
    }
  };

  function request() {
    return { type: MY_PROFILE_AUTH_REQUEST };
  }

  function success(payloadVK, payloadEYE3) {
    return { type: MY_PROFILE_AUTH_SUCCESS, payloadVK, payloadEYE3 };
  }

  function error(e) {
    return { type: MY_PROFILE_AUTH_ERROR, error: e };
  }
}

export function updateMyProfile() {
  return async (dispatch) => {
    try {
      dispatch(request());
      const payloadEYE3 = await APIEye3GetProfile();
      dispatch(success(payloadEYE3));
    } catch (e) {
      dispatch(error(e));
    }
  };

  function request() {
    return { type: MY_PROFILE_UPDATE_REQUEST };
  }

  function success(payloadEYE3) {
    return { type: MY_PROFILE_UPDATE_SUCCESS, payloadEYE3 };
  }

  function error(e) {
    return { type: MY_PROFILE_UPDATE_ERROR, error: e };
  }
}

export function subscribeGroup() {
  return async (dispatch) => {
    try {
      dispatch(request());
      const group = await VkBridgeSend("VKWebAppJoinGroup", { group_id: 194197472 });
      //console.log('group: ', group);

      if (checkVKMethodError(group)) {
        // Если в методе возникла ошибка или пользователь отказался от подписки
        return;
      }

      dispatch(success());
    } catch (e) {
      dispatch(error(e));
    }
  };

  function request() {
    return { type: MY_PROFILE_SUBSCRIBE_GROUP_REQUEST };
  }

  function success() {
    return { type: MY_PROFILE_SUBSCRIBE_GROUP_SUCCESS };
  }

  function error(e) {
    return { type: MY_PROFILE_SUBSCRIBE_GROUP_ERROR, error: e };
  }
}

export function setNotification(isEnabled) {
  return async (dispatch) => {
    try {
      dispatch(request());

      if (isEnabled) {
        const checkNotification = await VkBridgeSend("VKWebAppAllowMessagesFromGroup", {
          group_id: 194197472,
        });

        if (checkVKMethodError(checkNotification)) {
          return;
        }
      }

      await APIEye3NotificationProfile({ isNotification: +isEnabled });

      dispatch(success(isEnabled));
    } catch (e) {
      dispatch(error(e));
    }
  };

  function request() {
    return { type: MY_PROFILE_SET_NOTIFICATION_REQUEST };
  }

  function success(isEnabled) {
    return { type: MY_PROFILE_SET_NOTIFICATION_SUCCESS, isEnabled };
  }

  function error(e) {
    return { type: MY_PROFILE_SET_NOTIFICATION_ERROR, error: e };
  }
}

export function fetchLicense() {
  return async (dispatch) => {
    try {
      dispatch(request());

      const license = await APIEye3GetLicense();
      //console.log('license: ', license);

      if (!license.status) {
        dispatch(error(""));
        throw new Error("fetchLicense");
      }

      dispatch(success(license));
    } catch (e) {
      dispatch(error(e));
      throw new Error("fetchLicense::: " + JSON.stringify(e));
    }
  };

  function request() {
    return { type: MY_PROFILE_LICENSE_REQUEST };
  }

  function success(payload) {
    return { type: MY_PROFILE_LICENSE_SUCCESS, payload };
  }

  function error(e) {
    return { type: MY_PROFILE_LICENSE_ERROR, error: e };
  }
}

export function setLicenseRenew(id, isRenew) {
  return async (dispatch) => {
    try {
      dispatch(request());

      const renew = await APIEye3SetRenewLicense({
        id: id,
        renew: +isRenew,
      });

      if (renew.status) {
        dispatch(success(id, isRenew));
      }
    } catch (e) {
      dispatch(error(e));
    }
  };

  function request() {
    return { type: MY_PROFILE_SET_LICENSE_RENEW_REQUEST };
  }

  function success(id, isRenew) {
    return { type: MY_PROFILE_SET_LICENSE_RENEW_SUCCESS, id, isRenew };
  }

  function error(e) {
    return { type: MY_PROFILE_SET_LICENSE_RENEW_ERROR, error: e };
  }
}
