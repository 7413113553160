import { defaultErrorProperty } from "api/eye3/responseHandler";
import { APIEye3MenuListStat } from "../../../api/eye3";

import {
  TOOL_LIST_POPULAR_ERROR,
  TOOL_LIST_POPULAR_REQUEST,
  TOOL_LIST_POPULAR_SUCCESS,
} from "../../constType";

export function fetchPopularMenuList() {
  return async (dispatch) => {
    try {
      dispatch(request());
      const menuList = await APIEye3MenuListStat();

      dispatch(success(menuList));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: TOOL_LIST_POPULAR_REQUEST };
  }

  function success(payload) {
    return { type: TOOL_LIST_POPULAR_SUCCESS, payload };
  }
  function error(error) {
    return { type: TOOL_LIST_POPULAR_ERROR, error };
  }
}
