import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Div, Group, Search, Header, Link, Placeholder } from "@vkontakte/vkui";
import FIAUserItem from "../FIAUserItem/FIAUserItem";

import {
  fetchPopularFriendsInApp,
  showMorePopularFriendsInApp,
  cancelPopularFriendsInApp,
} from "store/actions/tool/friendsInApp";

import LoaderWithProgressBar from "template/components/LoaderWithProgressBar/LoaderWithProgressBar";
import { renderErrorPlaceholder } from "helpers/views";
import { declOfNum } from "helpers";
import { PANEL_FIA__INFO, PANEL_FIA__WHAT_IS_APP_ID } from "views/tool/panels/constPanels";
import { VIEW_TOOL } from "views/constViews";
import { useShowMore } from "hooks";
import SecondaryTitle from "template/components/SecondaryTitle/SecondaryTitle";

const FIAUserList = (props) => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const loading = useSelector((state) => state.toolFriendsInApp.popularApp.loading);
  const error = useSelector((state) => state.toolFriendsInApp.popularApp.error);
  const popularApp = useSelector((state) => state.toolFriendsInApp.popularApp.payload);
  const showCount = useSelector((state) => state.toolFriendsInApp.popularApp.showCount);
  const progressValue = useSelector((state) => state.toolFriendsInApp.popularApp.progressValue);
  const handleFetchPopularFriendsInApp = useCallback(
    () => dispatch(fetchPopularFriendsInApp()),
    [dispatch]
  );
  const handleShowMorePopularFriendsInApp = useCallback(
    () => dispatch(showMorePopularFriendsInApp()),
    [dispatch]
  );
  const handleCancelPopularFriendsInApp = useCallback(
    () => dispatch(cancelPopularFriendsInApp()),
    [dispatch]
  );
  const [searchAppID, setSearchAppID] = useState("");

  useShowMore({
    items: popularApp,
    showCount: showCount,
    maxLength: popularApp !== null ? popularApp.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMorePopularFriendsInApp,
  });

  useEffect(() => {
    handleFetchPopularFriendsInApp();
  }, [handleFetchPopularFriendsInApp]);

  useEffect(() => {
    return () => {
      handleCancelPopularFriendsInApp();
    };
  }, [handleCancelPopularFriendsInApp]);

  const renderPopularFriendsInApp = () => {
    return popularApp.length > 0
      ? popularApp.map((app, i) => {
          const id = app.id;
          const title = app.title;
          const photoUrl = app.photo_url;
          const friendCount = app.friend_count;

          const friendCountStrFormat = friendCount
            ? friendCount + " " + declOfNum(friendCount, ["друг", "друга", "друзей"])
            : "0 друзей";

          if (i < showCount) {
            return (
              <FIAUserItem
                key={i}
                id={id}
                title={title}
                photo={photoUrl}
                description={friendCountStrFormat}
                openView={props.openView}
              />
            );
          }

          return false;
        })
      : "";
  };

  const checkoutAppID = () => {
    const regExp = /vk\.com\/app(\d+)|vk\.com\/services\?w=app(\d+)|(^\d+$)/;
    let searchValue = null;

    if (searchAppID !== "" && regExp.test(searchAppID)) {
      let matchValues = searchAppID.match(regExp);

      if (matchValues[1] !== null && matchValues[1] !== undefined) {
        searchValue = matchValues[1];
      } else if (matchValues[2] !== null && matchValues[2] !== undefined) {
        searchValue = matchValues[2];
      } else if (matchValues[3] !== null && matchValues[3] !== undefined) {
        searchValue = matchValues[3];
      }

      props.openView({
        view: VIEW_TOOL,
        panel: PANEL_FIA__INFO,
        param: {
          activeAppId: searchValue,
        },
      });
    } else {
      //console.log(false);
    }
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      <Group separator="show">
        <SecondaryTitle>
          Введите ссылку на приложение или его App ID (
          <Link
            onClick={() => props.openView({ view: VIEW_TOOL, panel: PANEL_FIA__WHAT_IS_APP_ID })}
          >
            как узнать App ID?
          </Link>
          )
        </SecondaryTitle>
        <Search
          maxLength="100"
          placeholder={"Ссылка или App ID"}
          onChange={(e) => setSearchAppID(e.target.value)}
          value={searchAppID}
        />
        <Div>
          <Button size="l" disabled={loading} style={{ width: "100%" }} onClick={checkoutAppID}>
            Проверить приложение
          </Button>
        </Div>
      </Group>

      {popularApp !== null && error === null ? (
        <Group
          header={<Header mode="secondary">Популярные у друзей</Header>}
          style={{ marginBottom: "20px" }}
        >
          {renderPopularFriendsInApp()}
        </Group>
      ) : (
        ""
      )}
      {loading ? <LoaderWithProgressBar progressValue={progressValue} /> : ""}
      {Object.prototype.toString.call(popularApp) === "[object Array]" &&
      !loading &&
      popularApp.length <= 0 &&
      error === null ? (
        <Placeholder>Приложения не найдены</Placeholder>
      ) : (
        ""
      )}

      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchPopularFriendsInApp,
      })}
    </div>
  );
};

export default FIAUserList;
