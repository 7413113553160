import { PERIOD_MONTH } from "template/popouts/DateChangePeriod/constPeriod";
import { TABS_HISTORY } from "views/tool/panels/historyStatistics/constTabs";
import { TABS_ALL } from "views/tool/panels/historyStatistics/HSInfoTopSpectator/constTabs";
import {
  HISTORY_STATISTICS_REQUEST,
  HISTORY_STATISTICS_SUCCESS,
  HISTORY_STATISTICS_ERROR,
  HISTORY_STATISTICS__INFO_REQUEST,
  HISTORY_STATISTICS__INFO_SUCCESS,
  HISTORY_STATISTICS__INFO_PROGRESS,
  HISTORY_STATISTICS__INFO_ERROR,
  HISTORY_STATISTICS__INFO_TABS,
  HISTORY_STATISTICS__INFO_CANCEL,
  HISTORY_STATISTICS__INFO_SET_PERIOD,
  HISTORY_STATISTICS__INFO_SAVE_CACHE,
  HISTORY_STATISTICS__INFO_TOP_SPECTATOR_READY,
  HISTORY_STATISTICS__INFO_TOP_SPECTATOR_TABS,
} from "../../constType";

const initialState = {
  stories: {
    payload: null,
    loading: false,
    error: null,
  },
  stats: {
    activeId: null,
    activeStoryType: null,
    progressValue: 0,
    tabs: TABS_HISTORY,
    payload: null,
    cachePayload: null,
    loading: false,
    error: null,
    isOnce: false,
    period: {
      name: PERIOD_MONTH,
      atMin: null,
      atMax: null,
    },
  },
  topSpectators: {
    payload: null,
    activeHeader: null,
    activeTypeSpectator: null,
    activeItems: null,
    tabs: TABS_ALL,
  },
};

export default function historyStatisticsReducer(state = initialState, action) {
  switch (action.type) {
    case HISTORY_STATISTICS_REQUEST:
      return {
        ...state,
        stories: {
          ...state.stories,
          payload: null,
          loading: true,
          error: null,
        },
      };
    case HISTORY_STATISTICS_SUCCESS:
      return {
        ...state,
        stories: {
          ...state.stories,
          loading: false,
          payload: action.payload,
        },
      };
    case HISTORY_STATISTICS_ERROR:
      return {
        ...state,
        stories: {
          ...state.stories,
          loading: false,
          error: action.error,
        },
      };
    case HISTORY_STATISTICS__INFO_REQUEST:
      return {
        ...state,
        stats: {
          ...state.stats,
          payload: null,
          cachePayload: null,
          loading: true,
          error: null,
          progressValue: 0,
          activeId: action.id,
          activeStoryType: action.storyType,
          isOnce: true,
        },
      };
    case HISTORY_STATISTICS__INFO_SAVE_CACHE:
      return {
        ...state,
        stats: {
          ...state.stats,
          cachePayload: action.cachePayload,
        },
      };
    case HISTORY_STATISTICS__INFO_SUCCESS:
      return {
        ...state,
        stats: {
          ...state.stats,
          loading: false,
          payload: action.payload,
        },
      };
    case HISTORY_STATISTICS__INFO_PROGRESS:
      return {
        ...state,
        stats: {
          ...state.stats,
          progressValue: action.progressValue,
        },
      };
    case HISTORY_STATISTICS__INFO_ERROR:
      return {
        ...state,
        stats: {
          ...state.stats,
          loading: false,
          error: action.error,
        },
      };
    case HISTORY_STATISTICS__INFO_CANCEL:
      return {
        ...state,
        stats: {
          ...state.stats,
          loading: false,
        },
      };
    case HISTORY_STATISTICS__INFO_SET_PERIOD:
      return {
        ...state,
        stats: {
          ...state.stats,
          period: {
            ...state.stats.period,
            name: action.name,
            atMin: action.atMin,
            atMax: action.atMax,
          },
        },
      };
    case HISTORY_STATISTICS__INFO_TABS:
      return {
        ...state,
        stats: {
          ...state.stats,
          tabs: action.tabs,
        },
      };
    case HISTORY_STATISTICS__INFO_TOP_SPECTATOR_READY:
      return {
        ...state,
        topSpectators: {
          ...state.topSpectators,
          payload: action.payload,
          activeHeader: action.header,
          activeTypeSpectator: action.typeSpectator,
          activeItems: action.items,
        },
      };
    case HISTORY_STATISTICS__INFO_TOP_SPECTATOR_TABS:
      return {
        ...state,
        topSpectators: {
          ...state.topSpectators,
          tabs: action.tabs,
          payload: action.payload,
        },
      };
    default:
      return state;
  }
}
