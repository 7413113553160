import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Panel } from "@vkontakte/vkui";
import MentionUserList from "./components/MUserList/MUserList";
import MentionIcon from "assets/img/tool/mention/banner/mention.png";
import ToolBanner from "template/components/ToolBanner/ToolBanner";
import { withRouter } from "hoc";

const PanelMention = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Упоминания</PanelHeaderWithPath>
      <ToolBanner
        tool="toolMention"
        icon={MentionIcon}
        text="Узнавайте в каких записях отмечали выбранного пользователя"
      />
      <MentionUserList
        openView={props.openView}
        openModal={props.openModal}
        closeModal={props.closeModal}
      />
    </Panel>
  );
};

export default withRouter(PanelMention);
