import {
  WHO_LISTEN_FRIENDS_POPULAR_AUDIO_REQUEST,
  WHO_LISTEN_FRIENDS_POPULAR_AUDIO_SUCCESS,
  WHO_LISTEN_FRIENDS_POPULAR_AUDIO_ERROR,
  WHO_LISTEN_FRIENDS_POPULAR_AUDIO_SHOW_MORE,
  WHO_LISTEN_FRIENDS_POPULAR_AUDIO_INFO_REQUEST,
  WHO_LISTEN_FRIENDS_POPULAR_AUDIO_INFO_SUCCESS,
  WHO_LISTEN_FRIENDS_POPULAR_AUDIO_INFO_ERROR,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_REQUEST,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_SUCCESS,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_ERROR,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_SHOW_MORE,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_SEARCH_REQUEST,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_SEARCH_SUCCESS,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_SEARCH_ERROR,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_FILTER_REQUEST,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_FILTER_SUCCESS,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_FILTER_ERROR,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_FOR_ME_REQUEST,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_FOR_ME_SUCCESS,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_FOR_ME_ERROR,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_FOR_ME_SHOW_MORE,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_WITH_REQUEST,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_WITH_SUCCESS,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_WITH_ERROR,
  WHO_LISTEN_FRIENDS_COMMON_AUDIO_WITH_SHOW_MORE,
} from "../../constType";

const initialState = {
  popularAudio: {
    payload: null,
    showCount: 20,
    loading: false,
    error: null,
  },
  popularAudioInfo: {
    payload: null,
    loading: false,
    error: null,
  },
  commonAudio: {
    // Список друзей
    payload: null,
    cachePayload: null,
    loading: false,
    error: null,
    showCount: 20,
    search: "",
    filter: "",
    isOnce: false,
  },
  commonAudioForMe: {
    // Общие аудио со мной
    payload: null,
    showCount: 20,
    loading: false,
    error: null,
  },
  commonAudioWith: {
    // Общие аудио друзей
    activeUserId: null, // Параметры для сохранения пользователя
    activeUserPhoto: null, //
    activeUserTitle: null, //
    activeUserDescription: null, //
    payload: null,
    showCount: 20,
    loading: false,
    error: null,
  },
};

export default function whoListenFriendsReducer(state = initialState, action) {
  switch (action.type) {
    case WHO_LISTEN_FRIENDS_POPULAR_AUDIO_REQUEST:
      return {
        ...state,
        popularAudio: {
          ...state.popularAudio,
          loading: true,
          payload: null,
          error: null,
        },
      };
    case WHO_LISTEN_FRIENDS_POPULAR_AUDIO_SUCCESS:
      return {
        ...state,
        popularAudio: {
          ...state.popularAudio,
          loading: false,
          payload: action.payload,
        },
      };
    case WHO_LISTEN_FRIENDS_POPULAR_AUDIO_ERROR:
      return {
        ...state,
        popularAudio: {
          ...state.popularAudio,
          loading: false,
          error: action.error,
        },
      };
    case WHO_LISTEN_FRIENDS_POPULAR_AUDIO_SHOW_MORE:
      return {
        ...state,
        popularAudio: {
          ...state.popularAudio,
          showCount: parseInt(state.popularAudio.showCount) + 20,
        },
      };
    case WHO_LISTEN_FRIENDS_POPULAR_AUDIO_INFO_REQUEST:
      return {
        ...state,
        popularAudioInfo: {
          ...state.popularAudioInfo,
          loading: true,
          payload: null,
          error: null,
        },
      };
    case WHO_LISTEN_FRIENDS_POPULAR_AUDIO_INFO_SUCCESS:
      return {
        ...state,
        popularAudioInfo: {
          ...state.popularAudioInfo,
          loading: false,
          payload: action.payload,
        },
      };
    case WHO_LISTEN_FRIENDS_POPULAR_AUDIO_INFO_ERROR:
      return {
        ...state,
        popularAudioInfo: {
          ...state.popularAudioInfo,
          loading: false,
          error: action.error,
        },
      };
    case WHO_LISTEN_FRIENDS_COMMON_AUDIO_REQUEST:
      return {
        ...state,
        commonAudio: {
          ...state.commonAudio,
          payload: null,
          cachePayload:
            state.commonAudio.search === "" && state.commonAudio.filter === ""
              ? null // Если поле поиска и фильтра пустое то очистить кэш
              : state.commonAudio.cachePayload,
          loading: true,
          error: null,
          isOnce: true,
        },
      };
    case WHO_LISTEN_FRIENDS_COMMON_AUDIO_SUCCESS:
      return {
        ...state,
        commonAudio: {
          ...state.commonAudio,
          payload: action.payload,
          loading: false,
          cachePayload:
            state.commonAudio.search === "" && state.commonAudio.filter === ""
              ? action.payload // Если поле поиска и фильтра пустое то сохранить результат
              : state.commonAudio.cachePayload,
        },
      };
    case WHO_LISTEN_FRIENDS_COMMON_AUDIO_ERROR:
      return {
        ...state,
        commonAudio: {
          ...state.commonAudio,
          loading: false,
          error: action.error,
        },
      };
    case WHO_LISTEN_FRIENDS_COMMON_AUDIO_SHOW_MORE:
      return {
        ...state,
        commonAudio: {
          ...state.commonAudio,
          showCount: parseInt(state.commonAudio.showCount) + 20,
        },
      };
    case WHO_LISTEN_FRIENDS_COMMON_AUDIO_SEARCH_REQUEST:
      return {
        ...state,
        commonAudio: {
          ...state.commonAudio,
          payload: null,
          showCount: 20,
          search: action.search,
          loading: true,
          error: null,
        },
      };
    case WHO_LISTEN_FRIENDS_COMMON_AUDIO_SEARCH_SUCCESS:
      return {
        ...state,
        commonAudio: {
          ...state.commonAudio,
          payload: [...action.payload],
          loading: false,
        },
      };
    case WHO_LISTEN_FRIENDS_COMMON_AUDIO_SEARCH_ERROR:
      return {
        ...state,
        commonAudio: {
          ...state.commonAudio,
          loading: false,
          error: action.error,
        },
      };
    case WHO_LISTEN_FRIENDS_COMMON_AUDIO_FILTER_REQUEST:
      return {
        ...state,
        commonAudio: {
          ...state.commonAudio,
          payload: null,
          showCount: 20,
          filter: action.filter,
          loading: true,
          error: null,
        },
      };
    case WHO_LISTEN_FRIENDS_COMMON_AUDIO_FILTER_SUCCESS:
      return {
        ...state,
        commonAudio: {
          ...state.commonAudio,
          loading: false,
          payload: [...action.payload],
        },
      };
    case WHO_LISTEN_FRIENDS_COMMON_AUDIO_FILTER_ERROR:
      return {
        ...state,
        commonAudio: {
          ...state.commonAudio,
          loading: false,
          error: action.error,
        },
      };
    case WHO_LISTEN_FRIENDS_COMMON_AUDIO_FOR_ME_REQUEST:
      return {
        ...state,
        commonAudioForMe: {
          ...state.commonAudioForMe,
          loading: true,
          payload: null,
          error: null,
        },
      };
    case WHO_LISTEN_FRIENDS_COMMON_AUDIO_FOR_ME_SUCCESS:
      return {
        ...state,
        commonAudioForMe: {
          ...state.commonAudioForMe,
          loading: false,
          payload: action.payload,
        },
      };
    case WHO_LISTEN_FRIENDS_COMMON_AUDIO_FOR_ME_ERROR:
      return {
        ...state,
        commonAudioForMe: {
          ...state.commonAudioForMe,
          loading: false,
          error: action.error,
        },
      };
    case WHO_LISTEN_FRIENDS_COMMON_AUDIO_FOR_ME_SHOW_MORE:
      return {
        ...state,
        commonAudioForMe: {
          ...state.commonAudioForMe,
          showCount: parseInt(state.commonAudioForMe.showCount) + 20,
        },
      };
    case WHO_LISTEN_FRIENDS_COMMON_AUDIO_WITH_REQUEST:
      return {
        ...state,
        commonAudioWith: {
          ...state.commonAudioWith,
          activeUserId: action.id,
          activeUserDescription: action.description,
          activeUserTitle: action.title,
          activeUserPhoto: action.photo,
          loading: true,
          payload: null,
          error: null,
        },
      };
    case WHO_LISTEN_FRIENDS_COMMON_AUDIO_WITH_SUCCESS:
      return {
        ...state,
        commonAudioWith: {
          ...state.commonAudioWith,
          loading: false,
          payload: action.payload,
        },
      };
    case WHO_LISTEN_FRIENDS_COMMON_AUDIO_WITH_ERROR:
      return {
        ...state,
        commonAudioWith: {
          ...state.commonAudioWith,
          loading: false,
          error: action.error,
        },
      };
    case WHO_LISTEN_FRIENDS_COMMON_AUDIO_WITH_SHOW_MORE:
      return {
        ...state,
        commonAudioWith: {
          ...state.commonAudioWith,
          showCount: parseInt(state.commonAudioWith.showCount) + 20,
        },
      };
    default:
      return state;
  }
}
