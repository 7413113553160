import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Div, Panel } from "@vkontakte/vkui";
import { withRouter } from "hoc";

const FIAWhatIsAppId = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Друзья в приложении</PanelHeaderWithPath>
      <Div>
        <h3>Как узнать App ID?</h3>
        <p>Зайдите на страницу приложения и посмотрите в адресную строку браузера.</p>
        <p>
          В строке вы увидите примерно следующий адрес <b>vk.com/app7520879_26575455</b> - ID
          приложения это цифры после <b>app</b> и до символа нижнего подчеркивания "<b>_</b>", т.е.
          в данном случае ID приложения будет <b>7520879</b>.
        </p>
      </Div>
    </Panel>
  );
};

export default withRouter(FIAWhatIsAppId);
