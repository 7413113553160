import React from "react";

import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Panel } from "@vkontakte/vkui";
import WNOUserList from "./components/WNOUserList/WNOUserList";
import ToolBanner from "template/components/ToolBanner/ToolBanner";
import WNOIcon from "assets/img/tool/whoNotOnline/banner/WNO.png";
import { withRouter } from "hoc";

const PanelWhoNotOnline = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Кто давно не был онлайн</PanelHeaderWithPath>
      <ToolBanner
        tool="toolWhoNotOnline"
        icon={WNOIcon}
        text="Узнавайте кто из ваших друзей давно не был онлайн"
      />
      <WNOUserList
        openView={props.openView}
        openModal={props.openModal}
        closeModal={props.closeModal}
        openPopout={props.openPopout}
        closePopout={props.closePopout}
      />
    </Panel>
  );
};

export default withRouter(PanelWhoNotOnline);
