import {
  PAIR_REQUEST,
  PAIR_SUCCESS,
  PAIR_ERROR,
  PAIR_SHOW_MORE,
  PAIR_SEARCH_REQUEST,
  PAIR_SEARCH_SUCCESS,
  PAIR_SEARCH_ERROR,
  PAIR_FILTER_REQUEST,
  PAIR_FILTER_SUCCESS,
  PAIR_FILTER_ERROR,
  PAIR__INFO_REQUEST,
  PAIR__INFO_SUCCESS,
  PAIR__INFO_ERROR,
} from "../../constType";

const initialState = {
  friends: {
    payload: null,
    showCount: 20,
    cachePayload: null,
    search: "",
    filter: "",
    loading: false,
    error: null,
    isOnce: false,
  },
  pairs: {
    payload: null,
    activeUserId: null, // Параметры для сохранения пользователя
    activeUserPhoto: null, //
    activeUserTitle: null, //
    activeUserDescription: null, //
    activeUserSex: null,
    loading: false,
    error: null,
  },
};

export default function pairReducer(state = initialState, action) {
  switch (action.type) {
    case PAIR_REQUEST:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: null,
          cachePayload:
            state.friends.search === "" && state.friends.filter === ""
              ? null // Если поле поиска и фильтра пустое то очистить кэш
              : state.friends.cachePayload,
          loading: true,
          error: null,
          isOnce: true,
        },
      };
    case PAIR_SUCCESS:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: action.payload,
          loading: false,
          cachePayload:
            state.friends.search === "" && state.friends.filter === ""
              ? action.payload // Если поле поиска и фильтра пустое то сохранить результат
              : state.friends.cachePayload,
        },
      };
    case PAIR_ERROR:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          error: action.error,
        },
      };
    case PAIR_SHOW_MORE:
      return {
        ...state,
        friends: {
          ...state.friends,
          showCount: parseInt(state.friends.showCount) + 20,
        },
      };
    case PAIR_SEARCH_REQUEST:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: null,
          showCount: 20,
          search: action.search,
          loading: true,
          error: null,
        },
      };
    case PAIR_SEARCH_SUCCESS:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: [...action.payload],
          loading: false,
        },
      };
    case PAIR_SEARCH_ERROR:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          error: action.error,
        },
      };
    case PAIR_FILTER_REQUEST:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: null,
          showCount: 20,
          filter: action.filter,
          loading: true,
          error: null,
        },
      };
    case PAIR_FILTER_SUCCESS:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          payload: [...action.payload],
        },
      };
    case PAIR_FILTER_ERROR:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          error: action.error,
        },
      };
    case PAIR__INFO_REQUEST:
      return {
        ...state,
        pairs: {
          ...state.pairs,
          payload: null,
          activeUserId: action.id,
          activeUserDescription: action.description,
          activeUserTitle: action.title,
          activeUserPhoto: action.photo,
          activeUserSex: action.sex,
          loading: true,
          error: null,
        },
      };
    case PAIR__INFO_SUCCESS:
      return {
        ...state,
        pairs: {
          ...state.pairs,
          payload: action.payload,
          loading: false,
        },
      };
    case PAIR__INFO_ERROR:
      return {
        ...state,
        pairs: {
          ...state.pairs,
          loading: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}
