import {
  MOY_CRUSH_MY_CRUSH_LIST_REQUEST,
  MOY_CRUSH_MY_CRUSH_LIST_SUCCESS,
  MOY_CRUSH_MY_CRUSH_LIST_ERROR,
  MOY_CRUSH_CHOOSE_CRUSH_REQUEST,
  MOY_CRUSH_CHOOSE_CRUSH_SUCCESS,
  MOY_CRUSH_CHOOSE_CRUSH_ERROR,
  MOY_CRUSH_CHOOSE_CRUSH_SHOW_MORE,
  MOY_CRUSH_CHOOSE_CRUSH_SEARCH_REQUEST,
  MOY_CRUSH_CHOOSE_CRUSH_SEARCH_SUCCESS,
  MOY_CRUSH_CHOOSE_CRUSH_SEARCH_ERROR,
  MOY_CRUSH_CHOOSE_CRUSH_FILTER_REQUEST,
  MOY_CRUSH_CHOOSE_CRUSH_FILTER_SUCCESS,
  MOY_CRUSH_CHOOSE_CRUSH_FILTER_ERROR,
  MOY_CRUSH_SET_CHOOSE_CRUSH_REQUEST,
  MOY_CRUSH_SET_CHOOSE_CRUSH_SUCCESS,
  MOY_CRUSH_SET_CHOOSE_CRUSH_ERROR,
  MOY_CRUSH_CHOOSE_CRUSH_CHECKED,
  MOY_CRUSH_CHOOSE_CRUSH_TABS,
  MOY_CRUSH_REMOVE_MY_CRUSH_REQUEST,
  MOY_CRUSH_REMOVE_MY_CRUSH_SUCCESS,
  MOY_CRUSH_REMOVE_MY_CRUSH_ERROR,
} from "../../constType";

import { checkVKMethodError, delayMethodGetUsersVKApi } from "api";

import { calcAge } from "helpers";

import {
  APIEye3ListMyCrushMoyCrush,
  APIEye3RemoveMyCrushMoyCrush,
  APIEye3SetMyCrushMoyCrush,
} from "api/eye3";

import {
  getSearchURLUsers,
  getFilterTabsFriends,
  getSearchFriends,
  getFriendsComparePopular,
} from "helpers/actions";
import { defaultErrorProperty } from "api/eye3/responseHandler";

async function getFriends({
  friends,
  myCrush,
  select,
  search,
  filter,
  tabs,
  fields = ["photo_100", "bdate", "city", "country", "sex"],
}) {
  try {
    let usersItems = null;
    let { users, isSearchVkId } = await getSearchURLUsers({
      search,
      fields,
    });

    let result = [];

    if (users !== null && checkVKMethodError(users)) {
      // Если в методе возникла ошибка
      return result;
    }

    if (!isSearchVkId) {
      users = await getFriendsComparePopular({
        friends: friends,
        fields: fields,
      });
    }

    usersItems = users.response.hasOwnProperty("items") ? users.response.items : users.response;

    if (usersItems.length === 0) return result;

    let selectedItems = select !== null ? select : [];
    //console.log('selectedItems: ', selectedItems);

    usersItems.forEach((user) => {
      let selected = false;

      if (select === null) {
        // Если отсутствуют выбранные краши
        let allCrush = [...myCrush.my, ...myCrush.mutual];

        allCrush.forEach((crush) => {
          if (user.id === crush.vk_id) {
            selected = true;
            selectedItems.push(user.id.toString());
          }
        });
      } else {
        selectedItems.forEach((crush) => {
          if (user.id === parseInt(crush)) {
            selected = true;
          }
        });
      }

      if (!user.hasOwnProperty("deactivated")) {
        result.push({
          vk_id: user.id,
          name: user.first_name + " " + user.last_name,
          age: user.bdate ? calcAge(user.bdate) : null, // Расчет возраста по дате рождения
          sex: user.sex ? user.sex.toString() : null,
          city: user.city ? user.city.title : null, // Город
          city_id: user.city ? user.city.id.toString() : null, // Город id
          photo_url: user.photo_100 ? user.photo_100 : "", // Фото
          country: user.country ? user.country.title : null, // Страна
          country_id: user.country ? user.country.id.toString() : null, // Страна id
          selected: selected,
        });
      }
    });

    result = getFilterTabsFriends({
      data: result,
      filter: filter,
      tabsKey: ["sex"],
      tabsValue: tabs !== "0" ? tabs : "",
    });

    result = getSearchFriends({
      data: result,
      search: search,
      isSearchVkId,
    });

    return { result, selected: selectedItems };
  } catch (e) {
    throw e;
  }
}

export function fetchMyCrush() {
  // Список моих крашей
  return async (dispatch) => {
    try {
      dispatch(request());

      let result = {
        my: [],
        mutual: [],
      };
      const myCrush = await APIEye3ListMyCrushMoyCrush();

      //console.log('myCrush: ', myCrush);

      const myCrushInfo = await delayMethodGetUsersVKApi({
        userIds: myCrush.users,
        fields: ["photo_100", "bdate", "city", "country"],
      });

      const mutualCrushInfo = await delayMethodGetUsersVKApi({
        userIds: myCrush.found,
        fields: ["photo_100", "bdate", "city", "country"],
      });

      const myCrushItems = myCrushInfo;
      const mutualCrushItems = mutualCrushInfo;

      myCrushItems.forEach((crush) => {
        result.my.push({
          vk_id: crush.id,
          name: crush.first_name + " " + crush.last_name,
          age: crush.bdate ? calcAge(crush.bdate) : null, // Расчет возраста по дате рождения
          city: crush.city ? crush.city.title : null, // Город
          city_id: crush.city ? crush.city.id.toString() : null, // Город id
          photo_url: crush.photo_100 ? crush.photo_100 : "", // Фото
          country: crush.country ? crush.country.title : null, // Страна
          country_id: crush.country ? crush.country.id.toString() : null, // Страна id
        });
      });

      mutualCrushItems.forEach((crush) => {
        result.mutual.push({
          vk_id: crush.id,
          name: crush.first_name + " " + crush.last_name,
          age: crush.bdate ? calcAge(crush.bdate) : null, // Расчет возраста по дате рождения
          city: crush.city ? crush.city.title : null, // Город
          city_id: crush.city ? crush.city.id.toString() : null, // Город id
          photo_url: crush.photo_100 ? crush.photo_100 : "", // Фото
          country: crush.country ? crush.country.title : null, // Страна
          country_id: crush.country ? crush.country.id.toString() : null, // Страна id
        });
      });

      //console.log(result);

      dispatch(success(result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: MOY_CRUSH_MY_CRUSH_LIST_REQUEST };
  }

  function success(payload) {
    return { type: MOY_CRUSH_MY_CRUSH_LIST_SUCCESS, payload };
  }

  function error(error) {
    return { type: MOY_CRUSH_MY_CRUSH_LIST_ERROR, error };
  }
}

export function removeMyCrush(id) {
  return async (dispatch, getState) => {
    try {
      await dispatch(request());

      const res = await APIEye3RemoveMyCrushMoyCrush({
        userIds: id,
      });

      //console.log(res);

      if (res.status) {
        await dispatch(success());
        await dispatch(fetchMyCrush());
        return;
      }
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: MOY_CRUSH_REMOVE_MY_CRUSH_REQUEST };
  }

  function success() {
    return { type: MOY_CRUSH_REMOVE_MY_CRUSH_SUCCESS };
  }

  function error(error) {
    return { type: MOY_CRUSH_REMOVE_MY_CRUSH_ERROR, error };
  }
}

export function showMoreChooseCrush() {
  return { type: MOY_CRUSH_CHOOSE_CRUSH_SHOW_MORE };
}

export function fetchChooseCrush() {
  // Отметить крашей

  return async (dispatch, getState) => {
    try {
      dispatch(request());
      const state = getState().toolMoyCrush;

      const friends = await getFriends({
        friends: getState().friend.payload,
        myCrush: state.myCrush.payload,
        select: state.chooseCrush.selected,
        search: state.chooseCrush.search,
        filter: state.chooseCrush.filter,
        tabs: state.chooseCrush.tabs,
      });

      dispatch(success(friends.result, friends.selected));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: MOY_CRUSH_CHOOSE_CRUSH_REQUEST };
  }

  function success(payload, selected) {
    return { type: MOY_CRUSH_CHOOSE_CRUSH_SUCCESS, payload, selected };
  }

  function error(error) {
    return { type: MOY_CRUSH_CHOOSE_CRUSH_ERROR, error };
  }
}

export function searchChooseCrush(search = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(request(search));
      const state = getState().toolMoyCrush;

      const friends = await getFriends({
        friends: getState().friend.payload,
        myCrush: state.myCrush.payload,
        select: state.chooseCrush.selected,
        search: state.chooseCrush.search,
        filter: state.chooseCrush.filter,
        tabs: state.chooseCrush.tabs,
      });

      dispatch(success(friends.result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(search) {
    return { type: MOY_CRUSH_CHOOSE_CRUSH_SEARCH_REQUEST, search };
  }

  function success(payload) {
    return { type: MOY_CRUSH_CHOOSE_CRUSH_SEARCH_SUCCESS, payload };
  }

  function error(error) {
    return { type: MOY_CRUSH_CHOOSE_CRUSH_SEARCH_ERROR, error };
  }
}

export function filterChooseCrush(filter = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(request(filter));
      const state = getState().toolMoyCrush;

      const friends = await getFriends({
        friends: getState().friend.payload,
        myCrush: state.myCrush.payload,
        select: state.chooseCrush.selected,
        search: state.chooseCrush.search,
        filter: state.chooseCrush.filter,
        tabs: state.chooseCrush.tabs,
      });

      dispatch(success(friends.result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(filter) {
    return { type: MOY_CRUSH_CHOOSE_CRUSH_FILTER_REQUEST, filter };
  }

  function success(payload) {
    return { type: MOY_CRUSH_CHOOSE_CRUSH_FILTER_SUCCESS, payload };
  }

  function error(error) {
    return { type: MOY_CRUSH_CHOOSE_CRUSH_FILTER_ERROR, error };
  }
}

export function checkedChooseCrush(value, checked) {
  return { type: MOY_CRUSH_CHOOSE_CRUSH_CHECKED, value, checked };
}

export function tabsChooseCrush(tabs) {
  return { type: MOY_CRUSH_CHOOSE_CRUSH_TABS, tabs };
}

export function setChooseCrush(ids) {
  return async (dispatch) => {
    try {
      dispatch(request());
      await APIEye3SetMyCrushMoyCrush({ userIds: ids });

      dispatch(success());
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: MOY_CRUSH_SET_CHOOSE_CRUSH_REQUEST };
  }

  function success() {
    return { type: MOY_CRUSH_SET_CHOOSE_CRUSH_SUCCESS };
  }

  function error(error) {
    return { type: MOY_CRUSH_SET_CHOOSE_CRUSH_ERROR, error };
  }
}
