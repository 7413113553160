import React from "react";
import { Avatar, RichCell } from "@vkontakte/vkui";
import { Icon28ChevronRightOutline } from "@vkontakte/icons";
import { PANEL_PROFILE } from "views/profile/panels/constPanels";
import { VIEW_TOOL } from "views/constViews";

import {
  MARITAL_STATUS_NOT_MARRIED,
  MARITAL_STATUS_HAVE_FRIEND,
  MARITAL_STATUS_ENGAGED,
  MARITAL_STATUS_MARRIED,
  MARITAL_STATUS_COMPLICATED,
  MARITAL_STATUS_ACTIVELY_LOOKING,
  MARITAL_STATUS_LOVE,
  MARITAL_CIVIL_MARRIAGE,
} from "../../constMaritalStatus";

const PInfoTopPanel = (props) => {
  const getTypeText = () => {
    switch (props.selfRelation) {
      case MARITAL_STATUS_NOT_MARRIED:
        return props.sex === "2" ? "не женат" : "не замужем";
      case MARITAL_STATUS_HAVE_FRIEND:
        return props.sex === "2" ? "есть друг" : "есть подруга";
      case MARITAL_STATUS_ENGAGED:
        return props.sex === "2" ? "помолвлен" : "помолвлена";
      case MARITAL_STATUS_MARRIED:
        return props.sex === "2" ? "женат" : "замужем";
      case MARITAL_STATUS_COMPLICATED:
        return "всё сложно";
      case MARITAL_STATUS_ACTIVELY_LOOKING:
        return "в активном поиске";
      case MARITAL_STATUS_LOVE:
        return props.sex === "2" ? "влюблён" : "влюблена";
      case MARITAL_CIVIL_MARRIAGE:
        return "в гражданском браке";
      default:
        return "отсутствует";
    }
  };

  return (
    <RichCell
      before={<Avatar size={72} src={props.photo} />}
      caption={<div style={{ marginTop: "3px", marginBottom: "4px" }}>{props.description}</div>}
      bottom={
        <div style={{ fontSize: "13px", color: "var(--color-custom-link)" }}>{getTypeText()}</div>
      }
      after={<Icon28ChevronRightOutline fill="#B8C1CC" />}
      onClick={() =>
        props.openView({
          view: VIEW_TOOL,
          panel: PANEL_PROFILE,
          param: {
            activeProfileId: props.id,
          },
        })
      }
    >
      <span style={{ fontSize: "16px" }}>{props.title}</span>
    </RichCell>
  );
};

export default PInfoTopPanel;
