import React from "react";
import { useSelector } from "react-redux";
import PanelHeaderWitchoutPath from "template/components/PanelHeaderWitchoutPath/PanelHeaderWitchoutPath";
import MyCrushList from "./components/MyCrush/MyCrushList/MyCrushList";
import MutualCrushList from "./components/MutualCrush/MutualCrushList/MutualCrushList";
import CrushIcon from "assets/img/moycrush/banner/crush.png";
import ToolBanner from "template/components/ToolBanner/ToolBanner";

import { Panel } from "@vkontakte/vkui";
import { renderLoader } from "helpers/views";
import { withRouter } from "hoc";

const PanelMain = (props) => {
  const loading = useSelector((state) => state.toolMoyCrush.myCrush.loading);

  return (
    <Panel id={props.id}>
      <PanelHeaderWitchoutPath>Краши</PanelHeaderWitchoutPath>

      <ToolBanner
        tool="toolMoyCrush"
        icon={CrushIcon}
        text="Узнавай с кем у тебя возникла взаимная симпатия"
      />

      <div style={{ marginBottom: "20px" }}>
        <MutualCrushList
          openView={props.openView}
          openModal={props.openModal}
          closeModal={props.closeModal}
          goBack={props.goBack}
          openPopout={props.openPopout}
          closePopout={props.closePopout}
        />
        <MyCrushList
          openView={props.openView}
          openPopout={props.openPopout}
          closePopout={props.closePopout}
          openModal={props.openModal}
          closeModal={props.closeModal}
          goBack={props.goBack}
        />

        {renderLoader({ loading: loading })}
      </div>
    </Panel>
  );
};

export default withRouter(PanelMain);
