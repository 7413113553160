import React from "react";
import { Div, Avatar, Button, SimpleCell } from "@vkontakte/vkui";
import Icon16Chevron from "@vkontakte/icons/dist/16/chevron";
import ViewPageIcon from "assets/img/fan/viewPage.png";
import LikeIcon from "assets/img/fan/like.png";
import CommentIcon from "assets/img/fan/comment.png";
import { PANEL_PROFILE } from "views/profile/panels/constPanels";
import { PANEL_FAN__TOP } from "../../../constPanels";
import { FAN_GUEST, FAN_LIKE, FAN_COMMENT } from "../../constFan";
import { declOfNum } from "helpers";
import { VIEW_TOOL } from "views/constViews";
import styles from "./FanItem.module.css";

const FanItem = (props) => {
  const getTypeIcon = () => {
    switch (props.type) {
      case FAN_GUEST:
        return <img src={ViewPageIcon} alt=""></img>;
      case FAN_LIKE:
        return <img src={LikeIcon} alt=""></img>;
      case FAN_COMMENT:
        return <img src={CommentIcon} alt=""></img>;
      default:
        return "";
    }
  };

  const getTitle = () => {
    switch (props.type) {
      case FAN_GUEST:
        return "Топ по просмотру страницы";
      case FAN_LIKE:
        return "Топ по лайкам";
      case FAN_COMMENT:
        return "Топ по комментариям";
      default:
        return "";
    }
  };

  const getDescription = (value) => {
    switch (props.type) {
      case FAN_GUEST:
        return value + " " + declOfNum(value, ["просмотр", "просмотра", "просмотров"]);
      case FAN_LIKE:
        return value + " " + declOfNum(value, ["лайк", "лайка", "лайков"]);
      case FAN_COMMENT:
        return value + " " + declOfNum(value, ["комментарий", "комментария", "комментариев"]);
      default:
        return "";
    }
  };

  const getBgStyle = () => {
    switch (props.type) {
      case FAN_GUEST:
        return {
          background: "radial-gradient(109.55% 109.55% at 0% 0%, #FFCE51 0%, #DB8E1A 100%)",
        };
      case FAN_LIKE:
        return {
          background: "radial-gradient(109.55% 109.55% at 0% 0%, #FF7051 0%, #DB1A1A 100%)",
        };
      case FAN_COMMENT:
        return {
          background: "radial-gradient(109.55% 109.55% at 0% 0%, #51D5FF 0%, #1A8ADB 100%)",
        };
      default:
        return {
          background: "radial-gradient(109.55% 109.55% at 0% 0%, #FFCE51 0%, #DB8E1A 100%)",
        };
    }
  };

  return (
    <Div style={{ position: "relative" }}>
      <div className={styles.icon}>{getTypeIcon()}</div>
      <div className={styles.container} style={getBgStyle()}>
        <div className={styles.header}>{getTitle()}</div>
        <SimpleCell
          before={<Avatar size={72} src={props.photo} />}
          multiline
          after={<Icon16Chevron fill="#FFFFFF" />}
          onClick={(e) =>
            props.id
              ? props.openView({
                  view: VIEW_TOOL,
                  panel: PANEL_PROFILE,
                  param: { activeProfileId: props.id },
                })
              : e.preventDefault()
          }
          description={
            <span style={{ color: "rgba(255, 255, 255, 0.7)" }}>
              {getDescription(props.count || 0)}
            </span>
          }
        >
          <span style={{ color: "#FFFFFF" }}>{props.name || ""}</span>
        </SimpleCell>
        <div
          style={{
            padding: "6px 16px",
          }}
        >
          <Button
            mode="commerce"
            style={{ width: "100%" }}
            size="l"
            onClick={() =>
              props.openView({
                view: VIEW_TOOL,
                panel: PANEL_FAN__TOP,
                param: { typeTop: props.type },
              })
            }
          >
            Смотреть всех
          </Button>
        </div>
        <div
          style={{
            padding: "6px 16px",
          }}
        >
          <Button mode="overlay_outline" disabled={true} style={{ width: "100%" }} size="l">
            Сделать пост
          </Button>
        </div>
      </div>
    </Div>
  );
};

export default FanItem;
