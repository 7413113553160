import { APIEye3GetFriends } from "api/eye3";
import { FRIEND_REQUEST, FRIEND_SUCCESS, FRIEND_ERROR } from "../constType";

export function fetchFriends() {
  return async (dispatch) => {
    try {
      dispatch(request());

      const friends = await APIEye3GetFriends();

      if (!friends.status) {
        dispatch(error(""));
        throw new Error("fetchFriends::: ");
      }

      const result = friends.users.map((friend) => {
        return {
          ...friend,
        };
      });

      dispatch(success(result));
    } catch (e) {
      dispatch(error(e));
      throw new Error("fetchFriends::: " + JSON.stringify(e));
    }
  };

  function request() {
    return { type: FRIEND_REQUEST };
  }

  function success(payload) {
    return { type: FRIEND_SUCCESS, payload };
  }

  function error(e) {
    return { type: FRIEND_ERROR, error: e };
  }
}
