import { Icon56UserAddOutline } from "@vkontakte/icons";
import { Group, Placeholder } from "@vkontakte/vkui";
import { convertMonthDay, convertTime, unixTimeToDate } from "helpers";
import { declOfNum } from "helpers";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchNewFriendsInfo, setPeriodNewFriendsInfo } from "store/actions/tool/newFriends";
import SeparateFilter from "template/components/SeparateFilter/SeparateFilter";
import PopoutTemplateDateChangePeriod from "template/popouts/DateChangePeriod/DateChangePeriod";
import { renderPeriodV2 } from "template/popouts/DateChangePeriod/views";
import { VIEW_TOOL } from "views/constViews";
import { PANEL_NEW_FRIENDS__INFO } from "views/tool/panels/constPanels";
import NFInfoItem from "../NFInfoItem/NFInfoItem";
import NFInfoTopPanel from "../NFInfoTopPanel/NFInfoTopPanel";

const NFInfoList = (props) => {
  const dispatch = useDispatch();
  const activeUserId = useSelector((state) => state.toolNewFriends.newFriends.activeUserId);
  const activeUserTitle = useSelector((state) => state.toolNewFriends.newFriends.activeUserTitle);
  const activeUserPhoto = useSelector((state) => state.toolNewFriends.newFriends.activeUserPhoto);
  const activeUserDescription = useSelector(
    (state) => state.toolNewFriends.newFriends.activeUserDescription
  );
  const activeUserSex = useSelector((state) => state.toolNewFriends.newFriends.activeUserSex);
  const newFriends = useSelector((state) => state.toolNewFriends.newFriends.payload);
  const period = useSelector((state) => state.toolNewFriends.newFriends.period);
  const error = useSelector((state) => state.toolNewFriends.newFriends.error);
  const loading = useSelector((state) => state.toolNewFriends.newFriends.loading);
  const handleSetPeriodNewFriendsInfo = useCallback(
    (name, atMin = null, atMax = null) => dispatch(setPeriodNewFriendsInfo(name, atMin, atMax)),
    [dispatch]
  );
  const handleFetchNewFriendsInfo = useCallback(() => dispatch(fetchNewFriendsInfo()), [dispatch]);

  let lastAt = new Date();

  useEffect(() => {
    handleFetchNewFriendsInfo();
  }, [handleFetchNewFriendsInfo, period]);

  const renderUser = () => {
    const count = newFriends.length;
    const countStrFormat = count
      ? count + " " + declOfNum(count, ["друг", "друга", "друзей"])
      : "0 друзей";

    return (
      <NFInfoTopPanel
        id={activeUserId}
        title={activeUserTitle}
        description={activeUserDescription}
        photo={activeUserPhoto}
        bottomContent={countStrFormat}
        openView={props.openView}
      />
    );
  };

  const renderFriends = () => {
    return newFriends.length > 0 ? (
      newFriends.map((item, i) => {
        const id = item.vk_id;
        const name = item.name;
        const sex = item.sex;
        const photo = item.photo_url;
        const action = item.action;
        const type = item.type;
        const at = unixTimeToDate(item._at);

        let separate = <SeparateFilter>{convertMonthDay(at)}</SeparateFilter>;

        if (lastAt.getDate() === at.getDate() && i !== 0) {
          separate = "";
        }

        lastAt = at;

        return (
          <div key={i}>
            {separate}
            <NFInfoItem
              id={id}
              title={name}
              time={convertTime(at)}
              action={action}
              type={type}
              photo={photo}
              activeUserSex={activeUserSex}
              activeUserTitle={activeUserTitle}
              sex={sex}
            />
          </div>
        );
      })
    ) : (
      <Placeholder icon={<Icon56UserAddOutline />}>Новые друзья не найдены</Placeholder>
    );
  };

  const handlePeriod = () => {
    if (loading) return false;

    props.openPopout({
      popout: (
        <PopoutTemplateDateChangePeriod
          openView={props.openView}
          period={period} // Период из redux store
          setPeriod={handleSetPeriodNewFriendsInfo} // action из redux
          backPanel={PANEL_NEW_FRIENDS__INFO}
          backView={VIEW_TOOL}
          panelTitle={"Новые друзья"}
          closePopout={props.closePopout}
        />
      ),
    });
  };

  return (
    <>
      {renderPeriodV2({ title: "Новые друзья", period: period, handler: handlePeriod })}
      {newFriends !== null && error === null ? <Group separator="hide">{renderUser()}</Group> : ""}
      {newFriends !== null && error === null ? (
        <Group style={{ marginBottom: "20px" }}>{renderFriends()}</Group>
      ) : (
        ""
      )}
      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchNewFriendsInfo,
      })}
    </>
  );
};

export default NFInfoList;
