import {
  SUBSCRIBERS_FILTER_REQUEST,
  SUBSCRIBERS_FILTER_SHOW_MORE,
  SUBSCRIBERS_FILTER_SUCCESS,
  SUBSCRIBERS_FILTER_ERROR,
  SUBSCRIBERS_SET_FILTER,
} from "../../constType";

const initialState = {
  filter: {
    countryId: "",
    cityId: "",
    sex: "",
    ageFrom: "",
    ageTo: "",
    relation: "",
  },
  searchResult: {
    payload: null,
    showCount: 20,
    loading: false,
    error: null,
  },
};

export default function subscribersFilterReducer(state = initialState, action) {
  switch (action.type) {
    case SUBSCRIBERS_FILTER_REQUEST:
      return {
        ...state,
        searchResult: {
          ...state.searchResult,
          showCount: 20,
          payload: null,
          error: null,
          loading: true,
        },
      };
    case SUBSCRIBERS_FILTER_SUCCESS:
      return {
        ...state,
        searchResult: {
          ...state.searchResult,
          payload: action.payload,
          loading: false,
        },
      };
    case SUBSCRIBERS_FILTER_ERROR:
      return {
        ...state,
        searchResult: {
          ...state.searchResult,
          loading: false,
          error: action.error,
        },
      };
    case SUBSCRIBERS_FILTER_SHOW_MORE:
      return {
        ...state,
        searchResult: {
          ...state.searchResult,
          showCount: parseInt(state.searchResult.showCount) + 20,
        },
      };
    case SUBSCRIBERS_SET_FILTER:
      return {
        ...state,
        filter: action.filter,
      };
    default:
      return state;
  }
}
