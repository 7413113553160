import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import FIAAppsFromFriendsInfoList from "./FIAAppsFromFriendsInfoList/FIAAppsFromFriendsInfoList";
import { Panel } from "@vkontakte/vkui";
import { withRouter } from "hoc";

const PanelFIAAppsFromFriendsInfo = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Друзья в приложении</PanelHeaderWithPath>
      <FIAAppsFromFriendsInfoList openView={props.openView} />
    </Panel>
  );
};

export default withRouter(PanelFIAAppsFromFriendsInfo);
