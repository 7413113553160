import {
  PARTNER_REFERER_HISTORY_REQUEST,
  PARTNER_REFERER_HISTORY_SUCCESS,
  PARTNER_REFERER_HISTORY_ERROR,
  PARTNER_WITHDRAW_TO_COIN_REQUEST,
  PARTNER_WITHDRAW_TO_COIN_SUCCESS,
  PARTNER_WITHDRAW_TO_COIN_ERROR,
  PARTNER_WITHDRAW_TO_MONEY_REQUEST,
  PARTNER_WITHDRAW_TO_MONEY_SUCCESS,
  PARTNER_WITHDRAW_TO_MONEY_ERROR,
  PARTNER_CLS_WITHDRAW,
  PARTNER_ACTIVE_REF_REQUEST,
  PARTNER_ACTIVE_REF_SUCCESS,
  PARTNER_ACTIVE_REF_ERROR,
  PARTNER_REFERER_HISTORY_SHOW_MORE,
} from "../constType";

const initialState = {
  refererHistory: {
    payload: null,
    showCount: 20,
    loading: false,
    error: null,
  },
  activeRef: {
    payload: null,
    loading: false,
    error: null,
  },
  createPromo: {
    payload: null,
    loading: false,
    error: null,
  },
  activePromo: {
    payload: null,
    loading: false,
    error: null,
  },
  withdrawToMoney: {
    payload: null,
    loading: false,
    error: null,
  },
  withdrawToCoin: {
    payload: null,
    loading: false,
    error: null,
  },
};

export default function partnerReducer(state = initialState, action) {
  switch (action.type) {
    case PARTNER_REFERER_HISTORY_REQUEST:
      return {
        ...state,
        refererHistory: {
          ...state.refererHistory,
          payload: null,
          loading: true,
          error: null,
        },
      };
    case PARTNER_REFERER_HISTORY_SUCCESS:
      return {
        ...state,
        refererHistory: {
          ...state.refererHistory,
          payload: action.payload,
          loading: false,
        },
      };
    case PARTNER_REFERER_HISTORY_ERROR:
      return {
        ...state,
        refererHistory: {
          ...state.refererHistory,
          error: action.error,
          loading: false,
        },
      };
    case PARTNER_REFERER_HISTORY_SHOW_MORE:
      return {
        ...state,
        refererHistory: {
          ...state.refererHistory,
          showCount: parseInt(state.refererHistory.showCount) + 20,
        },
      };
    case PARTNER_ACTIVE_REF_REQUEST:
      return {
        ...state,
        activeRef: {
          ...state.activeRef,
          payload: null,
          loading: true,
          error: null,
        },
      };
    case PARTNER_ACTIVE_REF_SUCCESS:
      return {
        ...state,
        activeRef: {
          ...state.activeRef,
          payload: action.payload,
          loading: false,
        },
      };
    case PARTNER_ACTIVE_REF_ERROR:
      return {
        ...state,
        activeRef: {
          ...state.activeRef,
          error: action.error,
          loading: false,
        },
      };
    case PARTNER_CLS_WITHDRAW:
      return {
        ...state,
        withdrawToCoin: {
          ...state.withdrawToCoin,
          payload: null,
          error: null,
        },
        withdrawToMoney: {
          ...state.withdrawToMoney,
          payload: null,
          error: null,
        },
      };
    case PARTNER_WITHDRAW_TO_COIN_REQUEST:
      return {
        ...state,
        withdrawToCoin: {
          ...state.withdrawToCoin,
          payload: null,
          error: null,
          loading: false,
        },
      };
    case PARTNER_WITHDRAW_TO_COIN_SUCCESS:
      return {
        ...state,
        withdrawToCoin: {
          ...state.withdrawToCoin,
          payload: action.payload,
          loading: false,
        },
      };
    case PARTNER_WITHDRAW_TO_COIN_ERROR:
      return {
        ...state,
        withdrawToCoin: {
          ...state.withdrawToCoin,
          error: action.error,
          loading: false,
        },
      };
    case PARTNER_WITHDRAW_TO_MONEY_REQUEST:
      return {
        ...state,
        withdrawToMoney: {
          ...state.withdrawToMoney,
          payload: null,
          error: null,
          loading: false,
        },
      };
    case PARTNER_WITHDRAW_TO_MONEY_SUCCESS:
      return {
        ...state,
        withdrawToMoney: {
          ...state.withdrawToMoney,
          payload: action.payload,
          loading: false,
        },
      };
    case PARTNER_WITHDRAW_TO_MONEY_ERROR:
      return {
        ...state,
        withdrawToMoney: {
          ...state.withdrawToMoney,
          error: action.error,
          loading: false,
        },
      };
    default:
      return state;
  }
}
