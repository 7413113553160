import React from "react";
import { useSelector } from "react-redux";
import { Group, Placeholder, Header } from "@vkontakte/vkui";
import TSTrackedUserItem from "../TSTrackedUserItem/TSTrackedUserItem";
import EmptyTrackedUserIcon from "assets/img/tool/totalSurveillance/emptyTracked.png";

import { declOfNum } from "helpers";

const TSTrackedUserList = (props) => {
  const users = useSelector((state) => state.toolTotalSurveillance.trackedUser.payload);

  const renderTrackedUser = () => {
    return users.length > 0 ? (
      users.map((user, i) => {
        const id = user.vk_id;
        const name = user.name;
        const city = user.city ? user.city : "";
        const age = user.age ? user.age : "";
        const photo = user.photo_url ? user.photo_url : "";

        const ageStrFormat = age ? age + " " + declOfNum(age, ["год", "года", "лет"]) : "";
        const comma = ageStrFormat && city ? ", " : "";

        return (
          <TSTrackedUserItem
            id={id}
            key={i}
            title={name}
            description={ageStrFormat + comma + city}
            photo={photo}
            openView={props.openView}
          />
        );
      })
    ) : (
      <Placeholder icon={<img src={EmptyTrackedUserIcon} width="50" height="50" alt=""></img>}>
        Вы не отслеживаете ни одного пользователя
      </Placeholder>
    );
  };

  return (
    <>
      <Group
        style={{ marginBottom: "20px" }}
        header={<Header mode="secondary">Список отлеживаемых</Header>}
        separator={"hide"}
      >
        {users !== null && renderTrackedUser()}
      </Group>
    </>
  );
};

export default TSTrackedUserList;
