import { useEffect } from "react";

function useLoadMore({ items, maxLength, offset, loading, isTransitionEnd, handler }) {
  useEffect(() => {
    const handleScroll = () => {
      var scrollTop =
        (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
      var scrollHeight =
        (document.documentElement && document.documentElement.scrollHeight) ||
        document.body.scrollHeight;
      var clientHeight = document.documentElement.clientHeight || window.innerHeight;
      var scrolledToBottom = Math.ceil(scrollTop + clientHeight + 20) >= scrollHeight;

      if (scrolledToBottom && items !== null && !loading && isTransitionEnd && maxLength > offset) {
        handler();
      }

      //console.log('handleScroll');
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      //console.log('rm handleScroll');
    };
  }, [handler, offset, maxLength, items, isTransitionEnd, loading]);
}

export default useLoadMore;
