import {
  PROFILE_MARITAL_STATUS_REQUEST,
  PROFILE_MARITAL_STATUS_SUCCESS,
  PROFILE_MARITAL_STATUS_ERROR,
  PROFILE_MARITAL_STATUS_SHOW_MORE,
} from "../../constType";

import { APIEye3GetRelationHistoryTool } from "api/eye3";
import { APIVkGetUsers } from "api/vk/apiMethods";
import { openModal } from "../page";
import { checkEYE3ApiError, defaultErrorProperty } from "api/eye3/responseHandler";

export function fetchMaritalStatus() {
  return async (dispatch, getState) => {
    try {
      const state = getState().profileMaritalStatus;
      const page = getState().page;
      const profile = getState().profile;

      const id = profile.history[page.activeStory].user.id;

      if (state.activeUserId === profile.history[page.activeStory].user.id) {
        // Если предыдущий открытый профиль равен текущему открытому профилю
        return false;
      }

      dispatch(request(id));

      let result = [];

      const maritalStatus = await APIEye3GetRelationHistoryTool({
        userId: id,
      });

      //console.log('maritalStatus: ', maritalStatus);

      // Проверка ошибок в API
      const errorAPI = checkEYE3ApiError(maritalStatus);
      if (errorAPI !== false) {
        errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
        dispatch(error({ ...errorAPI }));
        return;
      }

      let userInfo = null;
      const changes = maritalStatus.changes;
      const relationPartnersIds = changes // Проверка на список партнеров из СП
        .filter((change) => change.hasOwnProperty("relation_partner"))
        .map((change) => change.relation_partner)
        .join(",");

      //console.log('relationPartnersIds: ', relationPartnersIds);

      if (relationPartnersIds) {
        userInfo = await APIVkGetUsers({
          userIds: relationPartnersIds,
          fields: ["photo_100"].join(","),
        });

        userInfo = userInfo.response.hasOwnProperty("items")
          ? userInfo.response.items
          : userInfo.response;
      }

      changes.forEach((change) => {
        let data = {};

        if (change.hasOwnProperty("relation")) data.relation = change.relation;

        data._at = change._at;

        if (userInfo) {
          userInfo.forEach((user) => {
            let partner = {};

            if (user.id === change.relation_partner) {
              partner = {
                relation_partner: change.relation_partner,
                name: user.first_name + " " + user.last_name,
                photo_url: user.photo_100,
              };
            }

            result.push({
              ...data,
              ...partner,
            });
          });
        } else {
          result.push({
            ...data,
          });
        }
      });

      //console.log('result: ', result);

      dispatch(success(result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e, isTryAgain: false }));
    }
  };

  function request(id) {
    return { type: PROFILE_MARITAL_STATUS_REQUEST, id };
  }

  function success(payload) {
    return { type: PROFILE_MARITAL_STATUS_SUCCESS, payload };
  }

  function error(error) {
    return { type: PROFILE_MARITAL_STATUS_ERROR, error };
  }
}

export function showMoreMaritalStatus() {
  return { type: PROFILE_MARITAL_STATUS_SHOW_MORE };
}
