import React from "react";
import { Avatar, SimpleCell } from "@vkontakte/vkui";
import Icon16Chevron from "@vkontakte/icons/dist/16/chevron";
import { VIEW_TOOL } from "views/constViews";
import { PANEL_DH__INFO } from "views/tool/panels/constPanels";

const DHUserItem = (props) => {
  return (
    <SimpleCell
      id={props.id}
      before={<Avatar size={48} src={props.photo} />}
      onClick={() => {
        props.openView({
          view: VIEW_TOOL,
          panel: PANEL_DH__INFO,
          param: {
            activeId: props.id,
            activeStoryType: props.storyType,
          },
        });
      }}
      description={props.description}
      after={<Icon16Chevron fill="#B8C1CC" />}
    >
      {props.title}
    </SimpleCell>
  );
};

export default DHUserItem;
