import {
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_ERROR,
  PROFILE_SET_TRACKING_REQUEST,
  PROFILE_SET_TRACKING_SUCCESS,
  PROFILE_SET_TRACKING_ERROR,
  PROFILE_SET_NOTIFICATION_REQUEST,
  PROFILE_SET_NOTIFICATION_SUCCESS,
  PROFILE_SET_NOTIFICATION_ERROR,
  PROFILE_TRACK_ACTIVE_REQUEST,
  PROFILE_TRACK_ACTIVE_SUCCESS,
  PROFILE_TRACK_ACTIVE_ERROR,
  PROFILE_SET_CRUSH_REQUEST,
  PROFILE_SET_CRUSH_SUCCESS,
  PROFILE_SET_CRUSH_ERROR,
} from "../../constType";

const initialState = {
  loading: false,
  activeProfileId: null,
  payload: null,
  history: {},
  error: null,
};

export default function profileReducer(state = initialState, action) {
  switch (action.type) {
    case PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        payload: null,
        activeProfileId: action.id,
        error: null,
      };
    case PROFILE_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        history: action.history,
        loading: false,
      };
    case PROFILE_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case PROFILE_SET_TRACKING_REQUEST:
      return {
        ...state,
        //loading: true,
        error: null,
      };
    case PROFILE_SET_TRACKING_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: {
          ...state.payload,
          track_active: {
            ...action.tracking,
            mutual_groups: state.payload.track_active.mutual_groups,
            is_bot_connect: state.payload.track_active.is_bot_connect,
          },
        },
      };
    case PROFILE_SET_TRACKING_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case PROFILE_SET_NOTIFICATION_REQUEST:
      return {
        ...state,
        error: null,
      };
    case PROFILE_SET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        payload: {
          ...state.payload,
          track_active: {
            ...state.payload.track_active,
            is_notification: action.isEnable,
          },
        },
      };
    case PROFILE_SET_NOTIFICATION_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case PROFILE_SET_CRUSH_REQUEST:
      return {
        ...state,
        error: null,
      };
    case PROFILE_SET_CRUSH_SUCCESS:
      return {
        ...state,
        payload: {
          ...state.payload,
          track_active: {
            ...state.payload.track_active,
            is_crush: action.isCrush,
          },
        },
      };
    case PROFILE_SET_CRUSH_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case PROFILE_TRACK_ACTIVE_REQUEST:
      return {
        ...state,
        //loading: true,
        error: null,
      };
    case PROFILE_TRACK_ACTIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: {
          ...state.payload,
          track_active: action.payload,
        },
      };
    case PROFILE_TRACK_ACTIVE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
