import { Avatar, RichCell } from "@vkontakte/vkui";
import React from "react";
import { Icon28ChevronRightOutline } from "@vkontakte/icons";
import {
  Icon20PictureOutline,
  Icon20VideocamOutline,
  Icon20MusicOutline,
  Icon20DocumentOutline,
  Icon16Linked,
} from "@vkontakte/icons";
import styles from "./PInfoItem.module.css";
import { convertTime } from "helpers";

const PInfoItem = (props) => {
  const getAttachments = () => {
    if (props.postAttachments !== null && props.postAttachments.all !== 0) {
      return (
        <div className={styles.attachmentContainer}>
          <div style={{ alignSelf: "center", color: "#818C99", marginRight: "6px" }}>Вложения:</div>
          {props.postAttachments.photo !== 0 && (
            <div className={styles.attachmentItem}>
              <Icon20PictureOutline fill="#3F8AE0" />
              <span style={{ marginLeft: "5px", marginRight: "8px" }}>
                {props.postAttachments.photo} фото
              </span>
            </div>
          )}
          {props.postAttachments.video !== 0 && (
            <div className={styles.attachmentItem}>
              <Icon20VideocamOutline fill="#3F8AE0" />
              <span style={{ marginLeft: "5px", marginRight: "8px" }}>
                {props.postAttachments.video} видео
              </span>
            </div>
          )}
          {props.postAttachments.link !== 0 && (
            <div className={styles.attachmentItem}>
              <Icon16Linked width={16} height={16} fill="#3F8AE0" />
              <span style={{ marginLeft: "5px", marginRight: "8px" }}>
                {props.postAttachments.link} ссылка
              </span>
            </div>
          )}
          {props.postAttachments.audio !== 0 && (
            <div className={styles.attachmentItem}>
              <Icon20MusicOutline fill="#3F8AE0" />
              <span style={{ marginLeft: "5px", marginRight: "8px" }}>
                {props.postAttachments.audio} аудио
              </span>
            </div>
          )}
          {props.postAttachments.document !== 0 && (
            <div className={styles.attachmentItem}>
              <Icon20DocumentOutline fill="#3F8AE0" />
              <span style={{ marginLeft: "5px", marginRight: "8px" }}>
                {props.postAttachments.document} документ
              </span>
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <div className={styles.postContainer}>
      <RichCell
        before={<Avatar size={48} src={props.groupPhoto} />}
        target="_blank"
        href={`https://vk.com/${props.typePage}${props.groupId}?w=wall-${props.groupId}_${props.postId}`}
        caption={
          <div style={{ marginTop: "3px", marginBottom: "4px", color: "var(--color-custom-link)" }}>
            {props.groupName}
          </div>
        }
        bottom={
          <div style={{ fontSize: "13px", color: "#818C99" }}>в {convertTime(props.postDate)}</div>
        }
        after={<Icon28ChevronRightOutline fill="#B8C1CC" />}
      >
        <span style={{ fontSize: "16px", color: "#818C99" }}>В сообществе</span>
      </RichCell>
      <div style={{ padding: "0 16px" }}>
        <div
          style={{
            fontSize: "15px",
            fontWeight: "normal",
            lineHeight: "20px",
          }}
        >
          {props.postText}
        </div>
        {getAttachments()}
      </div>
    </div>
  );
};

PInfoItem.defaultProps = {
  groupId: null,
  groupName: "",
  groupPhoto: "",
  typePage: null,
  postId: null,
  postText: "",
  postDate: 0,
  postAttachments: null,
};

export default PInfoItem;
