import { Link } from "@vkontakte/vkui";
import React from "react";
import styles from "./DHInfoItem.module.css";
import { Icon24Camera, Icon24Videocam } from "@vkontakte/icons";

const DHInfoItem = (props) => {
  return (
    <div className={styles.itemCnt}>
      <div className={styles.iconCnt}>
        {props.type === "video" ? (
          <Icon24Videocam width={20} height={20} fill="#fff" />
        ) : (
          <Icon24Camera width={20} height={20} fill="#fff" />
        )}
      </div>
      <Link href={props.link} target="_blank">
        <img
          src={props.photo}
          width={104}
          height={156}
          style={{ borderRadius: "4px" }}
          alt=""
        ></img>
      </Link>
    </div>
  );
};

export default DHInfoItem;
