import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import TimeOnlineList from "./components/TimeOnlineList/TimeOnlineList";
import { Panel } from "@vkontakte/vkui";
import { withRouter } from "hoc";

const PanelTimeOnline = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Профиль</PanelHeaderWithPath>
      <TimeOnlineList
        openView={props.openView}
        openPopout={props.openPopout}
        closePopout={props.closePopout}
      />
    </Panel>
  );
};

export default withRouter(PanelTimeOnline);
