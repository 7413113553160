import React from "react";
import { Avatar, SimpleCell } from "@vkontakte/vkui";
import Icon16Chevron from "@vkontakte/icons/dist/16/chevron";
import { Icon12OnlineMobile, Icon12Clock, Icon12View } from "@vkontakte/icons";
import styles from "./TOUserItem.module.css";
import { VIEW_TOOL } from "views/constViews";
import { PANEL_TO__TRACKING_USER_INFO } from "views/tool/panels/constPanels";

const TOUserItem = (props) => {
  const getTypePlatformIcon = () => {
    if (props.online === 1) {
      switch (props.platform) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
          return (
            <Avatar size={48} src={props.photo}>
              <div className={styles.backgroundMobile}>
                <Icon12OnlineMobile fill="#4BB34B" />
              </div>
            </Avatar>
          );
        case 7:
          return (
            <Avatar size={48} src={props.photo}>
              <div className={styles.backgroundPC}>
                <div className={styles.onlinePC}></div>
              </div>
            </Avatar>
          );
        default:
          break;
      }
    } else {
      return <Avatar size={48} src={props.photo}></Avatar>;
    }
  };

  const getTypeTrackOrOnline = () => {
    switch (props.type) {
      case "track":
        return <Icon12View fill="#4986cc" />;
      case "online":
        return <Icon12Clock fill="#4BB34B" />;
      default:
        return;
    }
  };

  return (
    <SimpleCell
      before={getTypePlatformIcon()}
      onClick={() =>
        props.openView({
          view: VIEW_TOOL,
          panel: PANEL_TO__TRACKING_USER_INFO,
          param: {
            activeUserId: props.id,
            activeUserPhoto: props.photo,
            activeUserTitle: props.title,
            activeUserDescription: props.info,
            activeUserIsTracking: true,
            activeUserTypeTracking: props.type,
          },
        })
      }
      description={props.description}
      after={<Icon16Chevron fill="#B8C1CC" />}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: "5px" }}>{props.title}</span>
        {getTypeTrackOrOnline()}
      </div>
    </SimpleCell>
  );
};

export default TOUserItem;
