import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Panel } from "@vkontakte/vkui";
import DHInfoList from "./components/DHInfoList/DHInfoList";
import { withRouter } from "hoc";

const PanelDHInfo = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Скачивание историй</PanelHeaderWithPath>
      <DHInfoList openView={props.openView} />
    </Panel>
  );
};

export default withRouter(PanelDHInfo);
