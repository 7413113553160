import { APIEye3SubscriptionsFilterTool } from "api/eye3";
import {
  SUBSCRIBERS_FILTER_REQUEST,
  SUBSCRIBERS_FILTER_SHOW_MORE,
  SUBSCRIBERS_FILTER_SUCCESS,
  SUBSCRIBERS_FILTER_ERROR,
  SUBSCRIBERS_SET_FILTER,
} from "../../constType";
import { openModal } from "../page";
import { delayMethodGetUsersVKApi } from "api";
import { calcAge } from "helpers";
import { checkEYE3ApiError, defaultErrorProperty } from "api/eye3/responseHandler";

export function fetchSubscribersFilter() {
  return async (dispatch, getState) => {
    try {
      dispatch(request());
      const state = getState().toolSubscribersFilter;
      let result = [];

      const subsFilter = await APIEye3SubscriptionsFilterTool({
        countryId: state.filter.countryId !== "" ? state.filter.countryId : null,
        cityId: state.filter.cityId !== "" ? state.filter.cityId : null,
        relation: state.filter.relation !== "" ? state.filter.relation : null,
        sex: state.filter.sex !== "" ? state.filter.sex : null,
        ageFrom: state.filter.ageFrom !== "" ? state.filter.ageFrom : null,
        ageTo: state.filter.ageTo !== "" ? state.filter.ageTo : null,
      });

      //console.log('subsFilter: ', subsFilter);

      // Проверка ошибок в API
      const errorAPI = checkEYE3ApiError(subsFilter);
      if (errorAPI !== false) {
        errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
        dispatch(error({ ...errorAPI }));
        return;
      }

      if (subsFilter.hasOwnProperty("items") && subsFilter.items.length !== 0) {
        const subsFilterInfo = await delayMethodGetUsersVKApi({
          userIds: subsFilter.items,
          fields: ["photo_100", "bdate", "city", "country"],
        });

        const subsFilterInfoItems = subsFilterInfo;

        subsFilterInfoItems.forEach((user) => {
          result.push({
            vk_id: user.id,
            name: user.first_name + " " + user.last_name,
            age: user.bdate ? calcAge(user.bdate) : null, // Расчет возраста по дате рождения
            city: user.city ? user.city.title : null, // Город
            city_id: user.city ? user.city.id.toString() : null, // Город id
            photo_url: user.photo_100 ? user.photo_100 : "", // Фото
            country: user.country ? user.country.title : null, // Страна
            country_id: user.country ? user.country.id.toString() : null, // Страна id
          });
        });

        //console.log('result: ', result);
      }

      dispatch(success(result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: SUBSCRIBERS_FILTER_REQUEST };
  }

  function success(payload) {
    return { type: SUBSCRIBERS_FILTER_SUCCESS, payload };
  }

  function error(error) {
    return { type: SUBSCRIBERS_FILTER_ERROR, error };
  }
}

export function showMoreSubscribersFilter() {
  return { type: SUBSCRIBERS_FILTER_SHOW_MORE };
}

export function setSubscribersFilter(filter) {
  return { type: SUBSCRIBERS_SET_FILTER, filter };
}
