import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import TOCompareTimeTopPanel from "../TOCompareTimeTopPanel/TOCompareTimeTopPanel";
import HeadTitle from "template/components/HeadTitle/HeadTitle";
import { Placeholder, Group } from "@vkontakte/vkui";
import Icon56UsersOutline from "@vkontakte/icons/dist/56/users_outline";
import TOCompareTimeItem from "../TOCompareTimeItem/TOCompareTimeItem";

import { fetchCompareTime } from "store/actions/tool/timeOnline";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import SecondaryTitle from "template/components/SecondaryTitle/SecondaryTitle";

const TOCompareTimeList = (props) => {
  const dispatch = useDispatch();
  const handleFetchCompareTime = useCallback(() => dispatch(fetchCompareTime()), [dispatch]);
  const activeUserId = useSelector((state) => state.toolTimeOnline.compareTime.activeUserId);
  const activeUserTitle = useSelector((state) => state.toolTimeOnline.compareTime.activeUserTitle);
  const activeUserDescription = useSelector(
    (state) => state.toolTimeOnline.compareTime.activeUserDescription
  );
  const activeUserPhoto = useSelector((state) => state.toolTimeOnline.compareTime.activeUserPhoto);
  const loading = useSelector((state) => state.toolTimeOnline.compareTime.loading);
  const error = useSelector((state) => state.toolTimeOnline.compareTime.error);
  const friends = useSelector((state) => state.toolTimeOnline.compareTime.payload);

  useEffect(() => {
    handleFetchCompareTime();
  }, [handleFetchCompareTime]);

  const renderUser = () => {
    return (
      <Group separator="show">
        <TOCompareTimeTopPanel
          id={activeUserId}
          title={activeUserTitle}
          photo={activeUserPhoto}
          description={activeUserDescription}
          openView={props.openView}
        />
        <SecondaryTitle style={{ marginTop: "5px" }}>
          Выберите с кем вы хотите сравнить время онлайн
        </SecondaryTitle>
      </Group>
    );
  };

  const renderFriends = () => {
    return friends.length > 0 ? (
      friends.map((friend, i) => {
        const id = friend.vk_id;
        const name = friend.name;
        const photo = friend.photo_url ? friend.photo_url : "";
        const time = friend.time;

        return (
          <TOCompareTimeItem
            key={i}
            id={id}
            title={name}
            photo={photo}
            description={time}
            openView={props.openView}
            activeUserId={activeUserId}
            activeUserTitle={activeUserTitle}
            activeUserPhoto={activeUserPhoto}
            activeUserDescription={activeUserDescription}
          />
        );
      })
    ) : (
      <Placeholder icon={<Icon56UsersOutline />}>Пользователи для сравнения не найдены</Placeholder>
    );
  };

  return (
    <>
      <HeadTitle>Сравнить время онлайн</HeadTitle>
      {renderUser()}
      <Group style={{ marginBottom: "20px" }}>
        {friends !== null && error === null ? renderFriends() : ""}
        {renderLoader({ loading: loading })}
      </Group>
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchCompareTime,
      })}
    </>
  );
};

export default TOCompareTimeList;
