import React from "react";
import { useSelector } from "react-redux";
import ShopItem from "../ShopItem/ShopItem";
import ShopItemBlank from "../ShopItemBlank/ShopItemBlank";

const ShopList = (props) => {
  // shop
  const loading = useSelector((state) => state.shop.tariffConnect.loading);
  // license
  const license = useSelector((state) =>
    state.myProfile.license.payload ? state.myProfile.license.payload : null
  );
  const autopayment = license !== null ? license.autopayment : false;
  const guestAllow = license !== null ? license.guest_allow : false;
  const licenseIsGuest = license !== null ? license.license_is_guest : true;
  const licenseIsTools = license !== null ? license.license_is_tools : true;
  const config = license !== null ? license.config : null;
  const guest = config !== null ? config.guest : [];
  const tools = config !== null ? config.tools : [];

  return (
    <div style={{ marginBottom: "20px" }}>
      {/*<BalanceBanner openView={props.openView} />*/}
      {guestAllow ? (
        <ShopItem
          tariffCaption="Гости"
          tariffSubtitle="Подключите инструмент «Гости», чтобы знать кто и когда посещал вашу страничку"
          tariffType="guest"
          timeCaption="30 дней"
          dayCount={guest.find((item) => item.day_count === 30).day_count}
          sum={guest.find((item) => item.day_count === 30).price}
          loading={loading}
          autopayment={autopayment}
          isEnable={licenseIsGuest}
          openModal={props.openModal}
        />
      ) : (
        <ShopItemBlank
          tariffCaption="Гости"
          tariffSubtitle="Подключите инструмент «Гости», чтобы знать кто и когда посещал вашу страничку"
          tariffType="guest"
          timeCaption="30 дней"
          dayCount={guest.find((item) => item.day_count === 30).day_count}
          sum={guest.find((item) => item.day_count === 30).price}
          loading={loading}
          autopayment={autopayment}
          isEnable={licenseIsGuest}
        />
      )}
      <ShopItem
        tariffCaption="Premium"
        tariffSubtitle={
          <>
            Включает: <br />
            {guestAllow ? (
              <div>
                – тариф «Гости» <br />
              </div>
            ) : (
              ""
            )}
            – «Тотальная слежка» (до 3 человек) <br />– «Время онлайн» (до 9 человек) <br />– Полный
            функционал КрашеLove
          </>
        }
        tariffType="tools"
        timeCaption="30 дней"
        dayCount={tools.find((item) => item.day_count === 30).day_count}
        sum={tools.find((item) => item.day_count === 30).price}
        loading={loading}
        isEnable={licenseIsTools}
        autopayment={autopayment}
        openModal={props.openModal}
      />
    </div>
  );
};

export default ShopList;
