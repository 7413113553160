import {
  WHO_ADDED_CHS_REQUEST,
  WHO_ADDED_CHS_PROGRESS,
  WHO_ADDED_CHS_SUCCESS,
  WHO_ADDED_CHS_ERROR,
  //WHO_ADDED_CHS_CANCEL
} from "../../constType";

const initialState = {
  payload: null,
  progressValue: 0,
  loading: false,
  error: null,
};

export default function whoAddedChsReducer(state = initialState, action) {
  switch (action.type) {
    case WHO_ADDED_CHS_REQUEST:
      return {
        ...state,
        loading: true,
        payload: null,
        error: null,
        progressValue: 0,
      };
    case WHO_ADDED_CHS_PROGRESS:
      return {
        ...state,
        progressValue: action.progressValue,
        payload: action.payload,
      };
    case WHO_ADDED_CHS_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: action.payload,
      };
    case WHO_ADDED_CHS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    /*case WHO_ADDED_CHS_CANCEL:
            return {
                ...state,
                loading: false,
            }*/
    default:
      return state;
  }
}
