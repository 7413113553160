import {
  PROFILE_HOROSCOPE_REQUEST,
  PROFILE_HOROSCOPE_SUCCESS,
  PROFILE_HOROSCOPE_ERROR,
} from "../../constType";

const initialState = {
  activeUserId: null,
  payload: null,
  loading: false,
  error: null,
};

export default function horoscopeReducer(state = initialState, action) {
  switch (action.type) {
    case PROFILE_HOROSCOPE_REQUEST:
      return {
        ...state,
        payload: null,
        loading: true,
        error: null,
        activeUserId: action.id,
      };
    case PROFILE_HOROSCOPE_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: action.payload,
      };
    case PROFILE_HOROSCOPE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
