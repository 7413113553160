import React from "react";
import { Avatar, SimpleCell } from "@vkontakte/vkui";
import Icon16MoreVertical from "@vkontakte/icons/dist/16/more_vertical";

const CAUserItem = (props) => {
  /*const dispatch = useDispatch();

  const handleUserAction = () => {
    handleOpenPopout(
      <ActionSheet onClose={handleClosePopout}>
        <ActionSheetItem autoclose>Перейти к сообществу</ActionSheetItem>
        <ActionSheetItem autoclose onClick={handleUserDelete}>
          Отписаться
        </ActionSheetItem>
      </ActionSheet>
    );
  };
  const handleUserDelete = () => {
    handleOpenPopout(
      <Alert
        actions={[
          {
            title: "Отмена",
            autoclose: true,
            mode: "cancel",
            // action () => handleDeleteFriend
          },
          {
            title: "Отписаться",
            autoclose: true,
            // mode:'unsubscribe'
            //  action: () => this.addActionLogItem('Право на модерацию контента добавлено.'),
          },
        ]}
        onClose={handleClosePopout}
      >
        <p>Вы действительно хотите отписаться от сообщества Стартапы и бизнес?</p>
      </Alert>
    );
  };*/
  return (
    <SimpleCell
      id={props.id}
      before={<Avatar size={48} src={props.photo} />}
      description={props.description}
      after={<Icon16MoreVertical fill="var(--text_secondary)" />}
    >
      {props.title}
    </SimpleCell>
  );
};

export default CAUserItem;
