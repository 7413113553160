import {
  FAN_REQUEST,
  FAN_SUCCESS,
  FAN_PROGRESS,
  FAN_SET_PERIOD,
  FAN_TABS,
  FAN_ERROR,
  FAN__TOP_ALL_SHOW_MORE,
  FAN__TOP_ALL_REQUEST,
  FAN__TOP_ALL_SUCCESS,
  FAN__TOP_ALL_PROGRESS,
  FAN__TOP_ALL_ERROR,
  FAN__TOP_ALL_TABS,
  FAN__TOP_ALL_SEARCH_REQUEST,
  FAN__TOP_ALL_SEARCH_SUCCESS,
  FAN__TOP_ALL_SEARCH_ERROR,
  FAN__TOP_ALL_FILTER_REQUEST,
  FAN__TOP_ALL_FILTER_SUCCESS,
  FAN__TOP_ALL_FILTER_ERROR,
} from "../../constType";

import { APIEye3GetFans } from "api/eye3";
import { delay } from "helpers";

import { dateToUnixTime, calcAge } from "helpers";

import { getPeriod } from "template/popouts/DateChangePeriod/actions";
import { FAN_COMMENT, FAN_GUEST, FAN_LIKE } from "views/tool/panels/fan/constFan";

import { compareIf } from "helpers";
import { delayMethodGetUsersVKApi } from "api";
import { defaultErrorProperty } from "api/eye3/responseHandler";

export function setPeriodFan(name, atMin = null, atMax = null) {
  return { type: FAN_SET_PERIOD, name, atMin, atMax };
}

export function tabsFan(tabs) {
  return { type: FAN_TABS, tabs };
}

function getTabs(filter, tabs) {
  let currentFilter = filter;

  if (tabs === "0") return currentFilter;

  if (currentFilter !== null && typeof filter === "object") {
    // Если переменная объект
    currentFilter.sex = tabs;
  } else if (currentFilter === "") {
    // Если переменная пустая строка (преобразовать в объект)
    currentFilter = {};
    currentFilter.sex = tabs;
  }

  return currentFilter;
}

function getSortedFan(fans, search, filter, tabs) {
  try {
    let fanResult = fans;

    let currentFilter = getTabs(filter, tabs);

    fanResult = fanResult.filter((item) => {
      let sex =
        currentFilter.hasOwnProperty("sex") && currentFilter.sex !== "" // Пол
          ? compareIf(item.sex, "===", currentFilter.sex)
          : true;
      let age_from =
        currentFilter.hasOwnProperty("age_from") && currentFilter.age_from !== "" // Возраст от
          ? compareIf(parseInt(item.age), ">=", parseInt(currentFilter.age_from))
          : true;
      let age_to =
        currentFilter.hasOwnProperty("age_to") && currentFilter.age_to !== "" // Возраст до
          ? compareIf(parseInt(item.age), "<=", parseInt(currentFilter.age_to))
          : true;
      let country_id =
        currentFilter.hasOwnProperty("country_id") && currentFilter.country_id !== "" // id страны
          ? compareIf(parseInt(item.country_id), "===", parseInt(currentFilter.country_id))
          : true;
      let city_id =
        currentFilter.hasOwnProperty("city_id") && currentFilter.city_id !== "" // id города
          ? compareIf(parseInt(item.city_id), "===", parseInt(currentFilter.city_id))
          : true;

      if (sex && age_from && age_to && country_id && city_id) return item;

      return false;
    });

    if (search !== "") {
      fanResult = fanResult.filter(
        (item) => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1
      );
    }

    return fanResult;
  } catch (e) {
    throw new Error("getSortedFan::: " + e);
  }
}

async function getFan(fans) {
  // Получить поклонников
  try {
    let fanResult = [];

    let ids = fans.map((fan) => fan.vk_id);

    let fanInfo = await delayMethodGetUsersVKApi({
      userIds: ids,
      fields: ["photo_100", "bdate", "city", "country", "sex"],
    });

    for (let fan of fans) {
      for (let info of fanInfo) {
        if (fan.vk_id === info.id && !info.hasOwnProperty("deactivated")) {
          fanResult.push({
            vk_id: fan.vk_id,
            name: info.first_name + " " + info.last_name,
            count: fan.count,
            age: info.bdate ? calcAge(info.bdate) : null, // Расчет возраста по дате рождения
            city: info.city ? info.city.title : null, // Город
            city_id: info.city ? info.city.id.toString() : null, // Город id
            country: info.country ? info.country.title : null, // Страна
            country_id: info.country ? info.country.id.toString() : null, // Страна id
            sex: info.sex ? info.sex.toString() : null, // Пол
            photo_url: info.photo_100,
          });

          break;
        }
      }
    }

    return fanResult;
  } catch (e) {
    throw new Error("getFan::: " + e);
  }
}

export function fetchFan() {
  return async (dispatch, getState) => {
    try {
      const state = getState().toolFan;

      if (state.loading) return;

      dispatch(request());

      async function process() {
        try {
          const period = state.period;

          const { atMin, atMax } = getPeriod(period);

          const fan = await APIEye3GetFans({
            timeMin: atMin === null ? 1 : atMin,
            timeMax: atMax === null ? dateToUnixTime(new Date()) : atMax,
          });

          if (fan.status === false) {
            throw new Error("Ошибка");
          } else if (fan.hasOwnProperty("is_bot_connect") && fan.is_bot_connect === false) {
            throw new Error("Не подключен бот");
          }

          let guests = [];
          let likes = [];
          let comments = [];

          //console.log('fan: ', fan);

          if (fan.hasOwnProperty("guests") && fan.guests.length > 0) {
            guests = await getFan(fan.guests);
            guests = getSortedFan(guests, "", "", state.tabs);
            await delay(250);
          }

          if (fan.hasOwnProperty("likes") && fan.likes.length > 0) {
            likes = await getFan(fan.likes);
            likes = getSortedFan(likes, "", "", state.tabs);
            await delay(250);
          }

          if (fan.hasOwnProperty("comments") && fan.comments.length > 0) {
            comments = await getFan(fan.comments);
            comments = getSortedFan(comments, "", "", state.tabs);
            await delay(250);
          }

          if (fan.status_task === "success") {
            dispatch(success(guests, likes, comments));
          } else if (fan.status_task === "work") {
            dispatch(progress(fan.percent, guests, likes, comments));
            await delay(2000);
            await process();
          } else if (fan.status_task === "error") {
            dispatch(error({ ...defaultErrorProperty, message: "" }));
          }

          return;
        } catch (e) {
          dispatch(error({ ...defaultErrorProperty, message: e }));
        }
      }

      await process();
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: FAN_REQUEST };
  }

  function success(guests, likes, comments) {
    return { type: FAN_SUCCESS, guests, likes, comments };
  }

  function progress(progressValue, guests, likes, comments) {
    return { type: FAN_PROGRESS, progressValue, guests, likes, comments };
  }

  function error(error) {
    return { type: FAN_ERROR, error };
  }
}

export function fetchTopAll() {
  return async (dispatch, getState) => {
    try {
      const state = getState().toolFan;
      const pageParam = getState().page.param;
      const typeTop = pageParam ? pageParam.typeTop : state.topAll.typeTop;

      if (state.topAll.loading) return;

      dispatch(request(typeTop));

      async function process() {
        try {
          const period = state.period;

          const { atMin, atMax } = getPeriod(period);

          const fan = await APIEye3GetFans({
            timeMin: atMin === null ? 1 : atMin,
            timeMax: atMax === null ? dateToUnixTime(new Date()) : atMax,
          });

          if (fan.status === false) {
            throw new Error("Ошибка");
          } else if (fan.hasOwnProperty("is_bot_connect") && fan.is_bot_connect === false) {
            throw new Error("Не подключен бот");
          }

          let topAll = [];
          let topAllCache = [];
          //console.log('fan: ', fan);

          if (fan.hasOwnProperty("guests") && fan.guests.length > 0 && typeTop === FAN_GUEST) {
            topAll = await getFan(fan.guests);
            topAllCache = topAll;
            topAll = getSortedFan(topAll, state.topAll.search, state.topAll.filter, state.tabs);
            await delay(250);
          } else if (fan.hasOwnProperty("likes") && fan.likes.length > 0 && typeTop === FAN_LIKE) {
            topAll = await getFan(fan.likes);
            topAllCache = topAll;
            topAll = getSortedFan(topAll, state.topAll.search, state.topAll.filter, state.tabs);
            await delay(250);
          } else if (
            fan.hasOwnProperty("comments") &&
            fan.comments.length > 0 &&
            typeTop === FAN_COMMENT
          ) {
            topAll = await getFan(fan.comments);
            topAllCache = topAll;
            topAll = getSortedFan(topAll, state.topAll.search, state.topAll.filter, state.tabs);
            await delay(250);
          }

          if (fan.status_task === "success") {
            dispatch(success(topAll, topAllCache));
          } else if (fan.status_task === "work") {
            dispatch(progress(fan.percent, topAll));
            await delay(2000);
            await process();
          } else if (fan.status_task === "error") {
            dispatch(error({ ...defaultErrorProperty, message: "" }));
          }

          return;
        } catch (e) {
          dispatch(error({ ...defaultErrorProperty, message: e }));
        }
      }

      await process();
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(typeTop) {
    return { type: FAN__TOP_ALL_REQUEST, typeTop };
  }

  function success(payload, cachePayload) {
    return { type: FAN__TOP_ALL_SUCCESS, payload, cachePayload };
  }

  function progress(progressValue, payload) {
    return { type: FAN__TOP_ALL_PROGRESS, progressValue, payload };
  }

  function error(error) {
    return { type: FAN__TOP_ALL_ERROR, error };
  }
}

export function showMoreTopAll() {
  return { type: FAN__TOP_ALL_SHOW_MORE };
}

export function tabsTopAll(tabs) {
  return (dispatch, getState) => {
    const state = getState().toolFan;

    const fans = getSortedFan(
      state.topAll.cachePayload,
      state.topAll.search,
      state.topAll.filter,
      tabs
    );

    dispatch(exec(tabs, fans));
  };

  function exec(tabs, payload) {
    return { type: FAN__TOP_ALL_TABS, tabs, payload };
  }
}

export function searchTopAll(search) {
  return (dispatch, getState) => {
    try {
      dispatch(request(search));
      const state = getState().toolFan;
      const fans = getSortedFan(
        state.topAll.cachePayload,
        state.topAll.search,
        state.topAll.filter,
        state.tabs
      );
      dispatch(success(fans));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(search) {
    return { type: FAN__TOP_ALL_SEARCH_REQUEST, search };
  }

  function success(payload) {
    return { type: FAN__TOP_ALL_SEARCH_SUCCESS, payload };
  }

  function error(error) {
    return { type: FAN__TOP_ALL_SEARCH_ERROR, error };
  }
}

export function fitlerTopAll(filter) {
  return (dispatch, getState) => {
    try {
      dispatch(request(filter));
      const state = getState().toolFan;
      const fans = getSortedFan(
        state.topAll.cachePayload,
        state.topAll.search,
        state.topAll.filter,
        state.tabs
      );
      dispatch(success(fans));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(filter) {
    return { type: FAN__TOP_ALL_FILTER_REQUEST, filter };
  }

  function success(payload) {
    return { type: FAN__TOP_ALL_FILTER_SUCCESS, payload };
  }

  function error(error) {
    return { type: FAN__TOP_ALL_FILTER_ERROR, error };
  }
}
