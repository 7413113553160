import { Avatar, SimpleCell } from "@vkontakte/vkui";
import Icon16Chevron from "@vkontakte/icons/dist/16/chevron";
import React from "react";
import { declOfNum } from "helpers";
import { VIEW_TOOL } from "views/constViews";
import { PANEL_PROFILE } from "views/profile/panels/constPanels";

const TopItem = (props) => {
  const getType = () => {
    switch (props.type) {
      case "views":
        return `${props.value} ${declOfNum(props.value, ["просмотр", "просмотра", "просмотров"])}`;
      case "likes":
        return `${props.value} ${declOfNum(props.value, ["лайк", "лайка", "лайков"])}`;
      default:
        return props.value;
    }
  };

  return (
    <SimpleCell
      before={<Avatar size={48} src={props.photo} />}
      after={<Icon16Chevron fill="#B8C1CC" />}
      onClick={() =>
        props.openView({
          view: VIEW_TOOL,
          panel: PANEL_PROFILE,
          param: {
            activeProfileId: props.id,
          },
        })
      }
      description={getType()}
    >
      {props.title}
    </SimpleCell>
  );
};

TopItem.defaultProps = {
  id: 0,
  title: "",
  photo: null,
  type: "",
  openView: null,
};

export default TopItem;
