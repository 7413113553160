import { queryEye3 } from "api";

async function APIEye3ListMyCrushMoyCrush() {
  try {
    const response = await queryEye3({
      method: "moycrush.list_my_crush",
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3ListMyCrushMoyCrush;
