import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import ViewPageList from "./components/ViewPageList/ViewPageList";
import { Panel } from "@vkontakte/vkui";
import { withRouter } from "hoc";

const PanelViewPage = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Профиль</PanelHeaderWithPath>
      <ViewPageList />
    </Panel>
  );
};

export default withRouter(PanelViewPage);
