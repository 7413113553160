import React from "react";
import { Group, Placeholder, Search } from "@vkontakte/vkui";
import WLFCommonAudioItem from "../WLFCommonAudioItem/WLFCommonAudioItem";
import Icon24Filter from "@vkontakte/icons/dist/24/filter";
import SecondaryTitle from "template/components/SecondaryTitle/SecondaryTitle";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useCallback } from "react";
import {
  fetchCommonAudio,
  filterCommonAudio,
  searchCommonAudio,
  showMoreCommonAudio,
} from "store/actions/tool/whoListenFriends";
import { declOfNum } from "helpers";
import { Icon56UsersOutline } from "@vkontakte/icons";
import { useDebounceSearch, useShowMore } from "hooks";
import { useEffect } from "react";
import { MODAL_PAGE_TEMPLATE_FILTER_SEX_AGE_CITY } from "template/modals/constModals";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";

const WLFCommonAudioList = (props) => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const loading = useSelector((state) => state.toolWhoListenFriends.commonAudio.loading);
  const search = useSelector((state) => state.toolWhoListenFriends.commonAudio.search);
  const filter = useSelector((state) => state.toolWhoListenFriends.commonAudio.filter);
  const error = useSelector((state) => state.toolWhoListenFriends.commonAudio.error);
  const isOnce = useSelector((state) => state.toolWhoListenFriends.commonAudio.isOnce);
  const showCount = useSelector((state) => state.toolWhoListenFriends.commonAudio.showCount);
  const friends = useSelector((state) => state.toolWhoListenFriends.commonAudio.payload);

  const [searchTerm, setSearchTerm] = useState(search);

  const handleFetchCommonAudio = useCallback(() => dispatch(fetchCommonAudio()), [dispatch]);
  const handleShowMoreCommonAudio = useCallback(() => dispatch(showMoreCommonAudio()), [dispatch]);
  const handleSearchCommonAudio = useCallback(
    (search) => dispatch(searchCommonAudio(search)),
    [dispatch]
  );
  const handleFilterCommonAudio = useCallback(
    (filter) => dispatch(filterCommonAudio(filter)),
    [dispatch]
  );

  const renderFriendList = () => {
    return friends.length > 0 ? (
      friends.map((friend, i) => {
        const id = friend.vk_id;
        const name = friend.name;
        const city = friend.city ? friend.city : "";
        const age = friend.age ? friend.age : "";
        const photo = friend.photo_url ? friend.photo_url : "";
        const ageStrFormat = age ? age + " " + declOfNum(age, ["год", "года", "лет"]) : "";
        const comma = ageStrFormat && city ? ", " : "";
        const sex = friend.sex;

        if (i < showCount) {
          return (
            <WLFCommonAudioItem
              key={i}
              id={id}
              title={name}
              sex={sex}
              description={ageStrFormat + comma + city}
              photo={photo}
              openView={props.openView}
            />
          );
        }

        return false;
      })
    ) : (
      <Placeholder icon={<Icon56UsersOutline />}>Друзья не найдены</Placeholder>
    );
  };

  useShowMore({
    items: friends,
    showCount: showCount,
    maxLength: friends !== null ? friends.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMoreCommonAudio,
  });

  useDebounceSearch({
    search: search,
    searchTerm: searchTerm,
    handler: handleSearchCommonAudio,
  });

  useEffect(() => {
    if (!isOnce) handleFetchCommonAudio();
  }, [handleFetchCommonAudio, isOnce]);

  const onClickFilter = () => {
    if (loading) return;

    props.openModal({
      id: MODAL_PAGE_TEMPLATE_FILTER_SEX_AGE_CITY,
      param: {
        filter: filter,
        closeModal: props.closeModal,
        applyFilter: handleFilterCommonAudio,
      },
    });
  };

  return (
    <div>
      <SecondaryTitle
        style={{
          marginTop: "18px",
          marginBottom: "13px",
          fontSize: "13px",
          textTransform: "uppercase",
        }}
      >
        Общие аудио друзей
      </SecondaryTitle>
      <SecondaryTitle>
        Выберите пользователя из списка друзей или воспользуйтесь поиском.
      </SecondaryTitle>

      <Search
        value={searchTerm}
        icon={<Icon24Filter />}
        placeholder={"Имя или ссылка на страницу"}
        onIconClick={() => onClickFilter()}
        onChange={(e) => {
          if (loading) return;

          setSearchTerm(e.target.value);
        }}
      />

      <Group style={{ marginBottom: "20px" }}>
        {friends !== null && error === null ? renderFriendList() : ""}
      </Group>
      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchCommonAudio,
      })}
    </div>
  );
};

export default WLFCommonAudioList;
