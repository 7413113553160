import { queryEye3 } from "api";

async function APIEye3GetUserInfoHistoryTool({ userId }) {
  try {
    const response = await queryEye3({
      method: "tool.get_user_info_history",
      get: { user_id: userId },
      access: { isBotConnect: true, isLicense: true, isLimit: true },
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3GetUserInfoHistoryTool;
