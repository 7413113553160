import React from "react";
import { Avatar, Link, SimpleCell } from "@vkontakte/vkui";
import Icon16Add from "@vkontakte/icons/dist/16/add";
import Icon16Cancel from "@vkontakte/icons/dist/16/cancel";
import styles from "./JCInfoItem.module.css";

const GROUP_JOIN = "join";
const GROUP_UNJOIN = "unjoin";

const JCInfoItem = (props) => {
  const getTypeText = () => {
    switch (props.type) {
      case GROUP_JOIN:
        return props.sex === "1" ? "Вступила в сообщество" : "Вступил в сообщество";
      case GROUP_UNJOIN:
        return props.sex === "1" ? "Покинула сообщество" : "Покинул сообщество";
      default:
        return "";
    }
  };

  const getTypeIcon = () => {
    switch (props.type) {
      case GROUP_JOIN:
        return (
          <Avatar size={48} src={props.photo}>
            <div className={styles.join}>
              <Icon16Add width={12} height={12} fill="var(--white)" />
            </div>
          </Avatar>
        );
      case GROUP_UNJOIN:
        return (
          <Avatar size={48} src={props.photo}>
            <div className={styles.unjoin}>
              <Icon16Cancel width={12} height={12} fill="var(--white)" />
            </div>
          </Avatar>
        );
      default:
        return "";
    }
  };

  return (
    <SimpleCell disabled before={getTypeIcon()} description={"в " + props.time} multiline>
      <span style={{ color: "var(--text_secondary)" }}>{getTypeText()}</span>
      <Link href={"https://vk.com/club" + props.id} target="_blank">
        {" "}
        {props.title}{" "}
      </Link>
    </SimpleCell>
  );
};

export default JCInfoItem;
