import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Placeholder, SimpleCell } from "@vkontakte/vkui";
import SeparateFilter from "template/components/SeparateFilter/SeparateFilter";
import GroupItem from "../GroupItem/GroupItem";
import { convertMonthDay, unixTimeToDate, convertTime } from "helpers";

import { fetchJoinCommunity, showMoreJoinCommunity } from "store/actions/profile/joinCommunity";
import { declOfNum } from "helpers";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import { useShowMore } from "hooks";
import HeadTitle from "template/components/HeadTitle/HeadTitle";
import { Icon56Users3Outline } from "@vkontakte/icons";

const GroupList = () => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const activeStory = useSelector((state) => state.page.activeStory);
  const profileHistory = useSelector((state) => state.profile.history);

  const loading = useSelector((state) => state.profileJoinCommunity.loading);
  const error = useSelector((state) => state.profileJoinCommunity.error);
  const groups = useSelector((state) => state.profileJoinCommunity.payload);
  const showCount = useSelector((state) => state.profileJoinCommunity.showCount);

  const handleFetchJoinCommunity = useCallback(() => dispatch(fetchJoinCommunity()), [dispatch]);
  const handleShowMoreJoinCommunity = useCallback(
    () => dispatch(showMoreJoinCommunity()),
    [dispatch]
  );

  let lastAt = new Date();

  useEffect(() => {
    handleFetchJoinCommunity();
  }, [handleFetchJoinCommunity]);

  useShowMore({
    items: groups,
    showCount: showCount,
    maxLength: groups !== null ? groups.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMoreJoinCommunity,
  });

  const renderTopPanel = () => {
    const user = profileHistory[activeStory].user;
    const name = user.name;
    const city = user.city ? user.city : "";
    const age = user.age ? user.age : "";
    const photo = user.photo_url ? user.photo_url : "";
    const ageStrFormat = age ? age + " " + declOfNum(age, ["год", "года", "лет"]) : "";
    const comma = ageStrFormat && city ? ", " : "";

    return (
      <SimpleCell
        disabled
        before={<Avatar size={72} src={photo} />}
        description={ageStrFormat + comma + city}
      >
        {name}
      </SimpleCell>
    );
  };

  const renderGroup = () => {
    return groups.length > 0 ? (
      groups.map((group, i) => {
        const id = group.vk_id;
        const name = group.name;
        const photo = group.photo_url ? group.photo_url : "";
        const type = group.type;
        const at = unixTimeToDate(group._at);
        const sex = profileHistory[activeStory].user.sex;

        let separate = <SeparateFilter>{convertMonthDay(at)}</SeparateFilter>;

        if (lastAt.getDate() === at.getDate() && i !== 0) {
          separate = "";
        }

        lastAt = at;

        if (i < showCount) {
          return (
            <div key={i}>
              {separate}
              <GroupItem
                id={id}
                title={name}
                type={type}
                photo={photo}
                time={convertTime(at)}
                sex={sex}
              />
            </div>
          );
        }

        return false;
      })
    ) : (
      <Placeholder icon={<Icon56Users3Outline />}>Сообщества не найдены</Placeholder>
    );
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      <HeadTitle>Вступления/выход из сообществ</HeadTitle>
      {renderTopPanel()}
      {groups !== null && error === null ? renderGroup() : ""}
      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchJoinCommunity,
      })}
    </div>
  );
};

export default GroupList;
