import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon16MoreVertical from "@vkontakte/icons/dist/16/more_vertical";
import {
  Cell,
  Avatar,
  UsersStack,
  Group,
  Div,
  Header,
  Switch,
  Alert,
  ActionSheet,
  ActionSheetItem,
  SimpleCell,
} from "@vkontakte/vkui";

import Icon24List from "@vkontakte/icons/dist/24/list";
import Icon16Chevron from "@vkontakte/icons/dist/16/chevron";
import Icon28Notifications from "@vkontakte/icons/dist/28/notifications";

import { declOfNum, gotoUrl } from "helpers";

import { setTracking, setNotification, setCrush } from "store/actions/profile/profile";

import { MODAL_PAGE_TEMPLATE_TRACKING_OPTION } from "template/modals/constModals";

import styles from "./ProfileTopMenuItem.module.css";
import {
  MODAL_CARD_HOROSCOPE_DATE_BIRTH,
  MODAL_PAGE_MUTUAL_FRIEND,
  MODAL_PAGE_MUTUAL_COMMUNITY,
} from "views/profile/modals/constModals";

import Icon24SearchSlashOutline from "@vkontakte/icons/dist/24/search_slash_outline";
import Icon24Search from "@vkontakte/icons/dist/24/search";
import Icon24LikeOutline from "@vkontakte/icons/dist/24/like_outline";
import Icon24Like from "@vkontakte/icons/dist/24/like";
import Icon24BrainOutline from "@vkontakte/icons/dist/24/brain_outline";
import HoroscopeIcon from "assets/img/profile/horoscope.svg";
import {
  PANEL_PROFILE_HOROSCOPE,
  PANEL_PROFILE_PSYCHOTYPE,
} from "views/profile/panels/constPanels";
import { renderLoader } from "helpers/views";

const ProfileControlList = (props) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => (state.profile.payload ? state.profile.payload : null));
  const loading = useSelector((state) => state.profile.loading);
  const activeView = useSelector((state) => state.page.activeView);

  const trackActive = profile ? profile.track_active : null;
  const trackActiveBdate = trackActive ? trackActive.bdate : null;
  const isEnable = trackActive ? trackActive.is_enable : false;
  const isNotification = trackActive ? trackActive.is_notification : false;
  const isCrush = trackActive ? trackActive.is_crush : false;

  const payloadVK = useSelector((state) => state.myProfile.payloadVK);

  const handleSetTracking = useCallback(
    (isEnable, options = null) => dispatch(setTracking(isEnable, options)),
    [dispatch]
  );
  const handleSetNotification = useCallback(
    (isEnable) => dispatch(setNotification(isEnable)),
    [dispatch]
  );
  const handleSetCrush = useCallback(() => dispatch(setCrush()), [dispatch]);

  const handleTracking = () => {
    isEnable
      ? props.openPopout({
          popout: (
            <Alert
              actionsLayout="horizontal"
              actions={[
                {
                  title: "Отмена",
                  autoclose: true,
                  mode: "cancel",
                },
                {
                  title: "Не отслеживать",
                  autoclose: true,
                  mode: "destructive",
                  action: () => handleSetTracking(false),
                },
              ]}
              onClose={props.closePopout}
            >
              <p>Вы уверены, что хотите перестать отслеживать этого пользователя?</p>
            </Alert>
          ),
        })
      : handleSetTracking(true);
  };

  const handleCrush = () => {
    handleSetCrush().then((res) => {
      if (res.hasOwnProperty("users_limit") && res.users_limit === false) {
        props.openPopout({
          popout: (
            <Alert
              actionsLayout="horizontal"
              actions={[
                {
                  title: "Ок",
                  autoclose: true,
                  mode: "cancel",
                },
              ]}
              onClose={props.closePopout}
            >
              <p>Количество выбранных крашей не может быть более 10</p>
            </Alert>
          ),
        });
      }
    });
  };

  const handleUserAction = () => {
    props.openPopout({
      popout: (
        <ActionSheet onClose={props.closePopout}>
          <ActionSheetItem
            autoclose
            onClick={() => {
              gotoUrl(`http://vk.com/id${profile.user.vk_id}`);
            }}
          >
            Перейти на страницу ВК
          </ActionSheetItem>
        </ActionSheet>
      ),
    });
  };

  const renderTopMenu = () => {
    return (
      <Div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className={styles.item} onClick={handleTracking}>
            {isEnable ? (
              <Icon24SearchSlashOutline width={28} height={28} fill={"#ff0000"} />
            ) : (
              <Icon24Search width={28} height={28} fill={"#3F8AE0"} />
            )}
            <span className={styles.itemText}>
              {isEnable ? "Отключить отслеживание" : "Отслеживать"}
            </span>
          </div>
          <div className={styles.item} onClick={handleCrush}>
            {isCrush ? (
              <Icon24Like fill={"#3F8AE0"} width={28} height={28} />
            ) : (
              <Icon24LikeOutline width={28} height={28} fill={"#3F8AE0"} />
            )}
            <span className={styles.itemText}>Краш</span>
          </div>
          <div
            className={styles.item}
            onClick={() => {
              if (
                (profile.user.age === null && trackActiveBdate === null) ||
                (profile.user.age === "" && trackActiveBdate === "")
              ) {
                props.openModal({
                  id: MODAL_CARD_HOROSCOPE_DATE_BIRTH,
                  param: {
                    message: "Пользователь не указал дату своего рождения",
                  },
                });
              } else if (payloadVK.bdate === null || payloadVK.bdate === "") {
                props.openModal({
                  id: MODAL_CARD_HOROSCOPE_DATE_BIRTH,
                  param: {
                    message: "Вы не указали дату своего рождения",
                  },
                });
              } else {
                props.openView({ view: activeView, panel: PANEL_PROFILE_HOROSCOPE });
              }
            }}
          >
            <img src={HoroscopeIcon} alt="" style={{ width: "28px", height: "28px" }}></img>
            <span className={styles.itemText}>Гороскоп</span>
          </div>
          <div
            className={styles.item}
            onClick={() => props.openView({ view: activeView, panel: PANEL_PROFILE_PSYCHOTYPE })}
          >
            <Icon24BrainOutline width={28} height={28} fill={"#3F8AE0"} />
            <span className={styles.itemText}>Психотип</span>
          </div>
        </div>
      </Div>
    );
  };

  const renderProfileUser = () => {
    const user = profile.user;
    const name = user.name;
    const city = user.city ? user.city : "";
    const age = user.age ? user.age : "";
    const photo = user.photo_url ? user.photo_url : "";
    const ageStrFormat = age ? age + " " + declOfNum(age, ["год", "года", "лет"]) : "";
    const comma = ageStrFormat && city ? ", " : "";

    return (
      <SimpleCell
        disabled
        before={<Avatar size={72} src={photo} />}
        description={ageStrFormat + comma + city}
        after={<Icon16MoreVertical fill="var(--text_secondary)" onClick={handleUserAction} />}
      >
        {name}
      </SimpleCell>
    );
  };

  const renderMutualFriends = () => {
    const mutualFriends = profile ? profile.mutual_friends : [];
    const photos = mutualFriends.map((friend) => {
      return friend.photo_url;
    });
    const commonStrFormat = declOfNum(photos.length, ["общий", "общих", "общих"]);
    const friendStrFormat = declOfNum(photos.length, ["друг", "друга", "друзей"]);

    const stack = photos.length ? (
      <Div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
        <div
          style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
          onClick={() => props.openModal({ id: MODAL_PAGE_MUTUAL_FRIEND })}
        >
          <div
            style={{
              fontWeight: "normal",
              fontSize: "13px",
              lineHeight: "16px",
              letterSpacing: "0.2px",
              color: "#818C99",
            }}
          >
            {photos.length + " " + commonStrFormat + " " + friendStrFormat}
          </div>
          <div>
            <UsersStack
              photos={photos}
              size="s"
              style={{ padding: "4px 12px" }}
              visibleCount={3}
              layout="vertical"
            />
          </div>
        </div>
      </Div>
    ) : (
      ""
    );

    return stack;
  };

  const renderMutualGroups = () => {
    const mutualGroups = profile ? profile.mutual_groups : [];
    const photos = mutualGroups.map((group) => {
      return group.photo_url;
    });
    const commonStrFormat = declOfNum(photos.length, ["общее", "общих", "общих"]);
    const groupStrFormat = declOfNum(photos.length, ["сообщество", "сообщества", "сообществ"]);

    const stack = photos.length ? (
      <Div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          onClick={() => props.openModal({ id: MODAL_PAGE_MUTUAL_COMMUNITY })}
        >
          <div
            style={{
              fontWeight: "normal",
              fontSize: "13px",
              lineHeight: "16px",
              letterSpacing: "0.2px",
              color: "#818C99",
            }}
          >
            {photos.length + " " + commonStrFormat + " " + groupStrFormat}
          </div>
          <div>
            <UsersStack
              photos={photos}
              style={{ padding: "4px 12px" }}
              size="s"
              visibleCount={3}
              layout="vertical"
            />
          </div>
        </div>
      </Div>
    ) : (
      ""
    );

    return stack;
  };

  const renderTotalSurveillance = () => {
    return (
      <Group header={<Header mode="secondary">настройки «тотальной слежки»</Header>}>
        <Cell
          before={<Icon28Notifications width={24} height={24} />}
          asideContent={
            <Switch
              defaultChecked={isNotification}
              onClick={() => handleSetNotification(isNotification ? false : true)}
            />
          }
        >
          Получать уведомления
        </Cell>
        <Cell
          before={<Icon24List />}
          asideContent={<Icon16Chevron fill="#B8C1CC" />}
          onClick={() =>
            props.openModal({
              id: MODAL_PAGE_TEMPLATE_TRACKING_OPTION,
              param: {
                closeModal: props.closeModal,
                setTracking: handleSetTracking,
                settings: trackActive.settings,
              },
            })
          }
        >
          Опции отслеживания
        </Cell>
      </Group>
    );
  };

  return (
    <>
      {profile !== null ? (
        <Group separator="show">
          {renderProfileUser()}
          {renderTopMenu()}
          {renderMutualFriends()}
          {renderMutualGroups()}
        </Group>
      ) : (
        ""
      )}

      {profile !== null && isEnable ? renderTotalSurveillance() : ""}
      {renderLoader({ loading: loading })}
    </>
  );
};

export default ProfileControlList;
