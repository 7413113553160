import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import AOtherUserInfoList from "./components/AOtherUserInfoList/AOtherUserInfoList";
import { Panel } from "@vkontakte/vkui";
import { withRouter } from "hoc";

const PanelAOtherUserInfo = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Активности</PanelHeaderWithPath>
      <AOtherUserInfoList
        openView={props.openView}
        openModal={props.openModal}
        closeModal={props.closeModal}
        openPopout={props.openPopout}
        closePopout={props.closePopout}
      />
    </Panel>
  );
};

export default withRouter(PanelAOtherUserInfo);
