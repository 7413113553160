import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Group, Div, Button, Search, TabsItem, Tabs, Placeholder, Alert } from "@vkontakte/vkui";
import ChooseCrushItem from "../ChooseCrushItem/ChooseCrushItem";
import Icon56UsersOutline from "@vkontakte/icons/dist/56/users_outline";
import Icon24Filter from "@vkontakte/icons/dist/24/filter";

import { TABS_MALE, TABS_FEMALE, TABS_ALL } from "../../constTabs";

import { declOfNum } from "helpers";

import {
  fetchChooseCrush,
  setChooseCrush,
  filterChooseCrush,
  searchChooseCrush,
  checkedChooseCrush,
  showMoreChooseCrush,
  tabsChooseCrush,
} from "store/actions/tool/moyCrush";

import { MODAL_PAGE_TEMPLATE_FILTER_AGE_CITY } from "template/modals/constModals";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import { MODAL_CARD_CRUSH_POST } from "views/moycrush/modals/constModals";
import { useDebounceSearch, useShowMore } from "hooks";

const MyCrushList = (props) => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const error = useSelector((state) => state.toolMoyCrush.chooseCrush.error);
  const loading = useSelector((state) => state.toolMoyCrush.chooseCrush.loading);
  const showCount = useSelector((state) => state.toolMoyCrush.chooseCrush.showCount);
  const tabs = useSelector((state) => state.toolMoyCrush.chooseCrush.tabs);
  const search = useSelector((state) => state.toolMoyCrush.chooseCrush.search);
  const filter = useSelector((state) => state.toolMoyCrush.chooseCrush.filter);
  const chooseSelectedCrush = useSelector((state) =>
    state.toolMoyCrush.chooseCrush.selected ? state.toolMoyCrush.chooseCrush.selected : null
  );
  const chooseCrush = useSelector((state) =>
    state.toolMoyCrush.chooseCrush.payload ? state.toolMoyCrush.chooseCrush.payload : null
  );

  const handleFetchChooseCrush = useCallback(() => dispatch(fetchChooseCrush()), [dispatch]);
  const handleFilterChooseCrush = useCallback(
    (filter) => dispatch(filterChooseCrush(filter)),
    [dispatch]
  );
  const handleSearchChooseCrush = useCallback(
    (search) => dispatch(searchChooseCrush(search)),
    [dispatch]
  );
  const handleShowMoreChooseCrush = useCallback(() => dispatch(showMoreChooseCrush()), [dispatch]);
  const handleSetChooseCrush = useCallback((ids) => dispatch(setChooseCrush(ids)), [dispatch]);
  const handleCheckedChooseCrush = useCallback(
    (value, checked) => dispatch(checkedChooseCrush(value, checked)),
    [dispatch]
  );
  const handleTabsChooseCrush = useCallback((tabs) => dispatch(tabsChooseCrush(tabs)), [dispatch]);

  const [searchTerm, setSearchTerm] = useState(search);

  const crush = useSelector((state) =>
    state.toolMoyCrush.myCrush.payload ? state.toolMoyCrush.myCrush.payload : null
  );
  const mutual = crush !== null ? crush.mutual : null;

  useShowMore({
    items: chooseCrush,
    showCount: showCount,
    maxLength: chooseCrush !== null ? chooseCrush.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMoreChooseCrush,
  });

  useEffect(() => {
    handleFetchChooseCrush();
  }, [handleFetchChooseCrush]);

  useDebounceSearch({
    search: search,
    searchTerm: searchTerm,
    handler: handleSearchChooseCrush,
  });

  const renderChooseCrush = () => {
    return chooseCrush.length > 0 ? (
      chooseCrush.map((crush, i) => {
        const id = crush.vk_id;
        const name = crush.name;
        const sex = crush.sex;
        const city = crush.city ? crush.city : "";
        const age = crush.age ? crush.age : "";
        const photo = crush.photo_url ? crush.photo_url : "";
        const ageStrFormat = age ? age + " " + declOfNum(age, ["год", "года", "лет"]) : "";
        const comma = ageStrFormat && city ? ", " : "";
        const selected = crush.hasOwnProperty("selected") ? crush.selected : false;

        if (i < showCount) {
          return (
            <ChooseCrushItem
              key={i}
              id={id}
              title={name}
              sex={sex}
              description={ageStrFormat + comma + city}
              photo={photo}
              selected={selected}
              onChangeCrush={onChangeCrush}
            />
          );
        }

        return false;
      })
    ) : (
      <Placeholder icon={<Icon56UsersOutline />}>Друзья не найдены</Placeholder>
    );
  };

  const onClickFilter = () => {
    if (loading) return;

    props.openModal({
      id: MODAL_PAGE_TEMPLATE_FILTER_AGE_CITY,
      param: {
        filter: filter,
        closeModal: props.closeModal,
        applyFilter: handleFilterChooseCrush,
      },
    });
  };

  const onChangeCrush = (e) => {
    const { value, checked } = e.target;
    handleCheckedChooseCrush(value, checked);
  };

  const showLimitCrushPopout = () => {
    props.openPopout({
      popout: (
        <Alert
          actionsLayout="horizontal"
          actions={[
            {
              title: "Ок",
              autoclose: true,
              mode: "cancel",
            },
          ]}
          onClose={props.closePopout}
        >
          <p>Количество выбранных крашей не может быть более 10</p>
        </Alert>
      ),
    });
  };

  const setTabsSex = (tabs) => {
    if (!loading) {
      handleTabsChooseCrush(tabs);
      handleFetchChooseCrush();
    }
  };

  const checkMutualCrush = () => {
    if (mutual !== null && mutual.length === 0) {
      props.openModal({ id: MODAL_CARD_CRUSH_POST });
    }
  };

  const saveCrush = () => {
    if (Object.values(chooseSelectedCrush).length >= 11) {
      showLimitCrushPopout();
      return;
    }

    handleSetChooseCrush(chooseSelectedCrush.join(","));
    props.goBack();
    checkMutualCrush();
  };

  return (
    <div>
      <Div style={{ paddingBottom: "0px" }}>
        <span
          style={{
            fontSize: "20px",
            lineHeight: "24px",
            letterSpacing: "0.38px",
            fontStyle: "normal",
            fontWeight: "bold",
          }}
        >
          Отметь крашей
        </span>
      </Div>

      <Div style={{ paddingBottom: "0px" }}>
        <span
          style={{
            fontSize: "15px",
            fontStyle: "normal",
            fontWeight: "normal",
            lineHeight: "20px",
            letterSpacing: "0.2px",
            color: "var(--text_secondary)",
          }}
        >
          Отметь сердечком симпатичных тебе пользователей
        </span>
      </Div>
      <Search
        value={searchTerm}
        icon={<Icon24Filter />}
        onIconClick={() => onClickFilter()}
        onChange={(e) => {
          if (loading) return;

          setSearchTerm(e.target.value);
        }}
        placeholder={"Имя или ссылка на страницу"}
      />
      <Tabs>
        <TabsItem
          disabled={loading}
          onClick={() => {
            if (tabs !== TABS_ALL) setTabsSex(TABS_ALL);
          }}
          selected={tabs === TABS_ALL}
        >
          Все
        </TabsItem>
        <TabsItem
          disabled={loading}
          onClick={() => {
            if (tabs !== TABS_MALE) setTabsSex(TABS_MALE);
          }}
          selected={tabs === TABS_MALE}
        >
          Мужчины
        </TabsItem>
        <TabsItem
          disabled={loading}
          onClick={() => {
            if (tabs !== TABS_FEMALE) setTabsSex(TABS_FEMALE);
          }}
          selected={tabs === TABS_FEMALE}
        >
          Женщины
        </TabsItem>
      </Tabs>
      <Group className={"choose-crush-list"} style={{ marginBottom: "20px" }}>
        {chooseCrush !== null ? renderChooseCrush() : ""}
        {renderLoader({ loading: loading, style: { margin: "10px 0" } })}
        <div style={{ height: "60px" }}></div>
      </Group>
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchChooseCrush,
      })}
      <div
        style={{
          position: "fixed",
          width: "100%",
          bottom: "46px",
          zIndex: "10",
          backgroundColor: "var(--background_content)",
        }}
      >
        <Div>
          <Button
            size="xl"
            style={{ width: "100%" }}
            onClick={() => saveCrush()}
            disabled={loading}
          >
            Сохранить
          </Button>
        </Div>
      </div>
    </div>
  );
};

export default MyCrushList;
