import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  closeModal,
  openModal,
  goBack,
  onTransitionPage,
  openStory,
  openView,
  openPopout,
  closePopout,
} from "store/actions/page";

const withRouter = (Component) => (props) => {
  return (
    <Component
      pageParam={props.param}
      modalParam={props.modalParam}
      isTransitionEnd={props.isTransitionEnd}
      onTransitionPage={props.onTransitionPage}
      openView={props.openView}
      openStory={props.openStory}
      goBack={props.goBack}
      openModal={props.openModal}
      closeModal={props.closeModal}
      openPopout={props.openPopout}
      closePopout={props.closePopout}
      {...props}
    />
  );
};

const mapStateToProps = (state) => ({
  param: state.page.param,
  modalParam: state.page.modalParam,
  isTransitionEnd: state.page.isTransitionEnd,
});

const mapDispatchToProps = (dispatch) => ({
  goBack: () => dispatch(goBack()),
  onTransitionPage: () => dispatch(onTransitionPage()),
  openView: ({ view, panel, param = null }) =>
    dispatch(openView({ view: view, panel: panel, param: param })),
  openStory: ({ story, panel }) => dispatch(openStory({ story: story, panel: panel })),
  openModal: ({ id = null, param = null }) => dispatch(openModal({ id: id, param: param })),
  closeModal: () => dispatch(closeModal()),
  openPopout: ({ popout }) => dispatch(openPopout({ popout: popout })),
  closePopout: () => dispatch(closePopout()),
});

const composedWithRouter = compose(connect(mapStateToProps, mapDispatchToProps), withRouter);

export default composedWithRouter;
