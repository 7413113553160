import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PANEL_T__LINK_DONE } from "../../constPanels";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Button, FormLayout, Input, Div, Panel } from "@vkontakte/vkui";

import { fetchConvertLink } from "store/actions/tool/trap";
import { VIEW_TOOL } from "views/constViews";
import HeadTitle from "template/components/HeadTitle/HeadTitle";
import { renderLoader } from "helpers/views";
import { withRouter } from "hoc";

const PanelTrapConvertLink = (props) => {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.toolTrap.convertLink.loading);

  const [url, setUrl] = useState("");
  const [isValid, setIsValid] = useState(true);
  const handleFetchConvertLink = useCallback((url) => dispatch(fetchConvertLink(url)), [dispatch]);

  const handleConvert = () => {
    if (url !== "" && isValid) {
      handleFetchConvertLink(url).then(() => {
        props.openView({ view: VIEW_TOOL, panel: PANEL_T__LINK_DONE });
      });
    } else {
      setIsValid(false);
    }
  };

  const getStatus = () => {
    if (isValid && url === "") {
      return null;
    } else if (isValid && url !== "") {
      return "valid";
    } else {
      return "error";
    }
  };

  const handleUrl = (e) => {
    const value = e.target.value;
    setUrl(value);

    // eslint-disable-next-line no-useless-escape
    var r = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    r.test(value) ? setIsValid(true) : setIsValid(false);

    //console.log(r.test(value));
  };

  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Ловушка</PanelHeaderWithPath>
      {renderLoader({ loading: loading })}
      {!loading ? (
        <div>
          <HeadTitle>Преобразование ссылки</HeadTitle>
          <Div
            style={{
              fontSize: "15px",
              fontStyle: "normal",
              fontWeight: "normal",
              lineHeight: "20px",
              paddingTop: "0px",
              paddingBottom: "6px",
              letterSpacing: "0.2px",
              color: "var(--text_secondary)",
            }}
          >
            Введите любую сылку, по которой будут переходить пользователи и нажмите "Сгенерировать"
          </Div>
          <FormLayout>
            <Input
              type="text"
              value={url}
              top="Ссылка"
              onChange={(e) => handleUrl(e)}
              placeholder="Вставьте вашу ссылку"
              status={getStatus()}
              bottom={!isValid ? "Некорректная ссылка" : null}
            />
          </FormLayout>
          <Div>
            <Button
              size="l"
              style={{ width: "100%" }}
              onClick={handleConvert}
              disabled={isValid && url !== "" ? false : true}
            >
              Сгенерировать
            </Button>
          </Div>
        </div>
      ) : (
        ""
      )}
    </Panel>
  );
};

export default withRouter(PanelTrapConvertLink);
