import bridge from "@vkontakte/vk-bridge";
import { getVKApiVersion, getVKToken } from "api";
import { errorHandler, responseHandler } from "../responseHandler";

async function APIVkExecute({ code }) {
  const method = "execute";
  try {
    const response = await bridge.send("VKWebAppCallAPIMethod", {
      method: method,
      params: {
        code: code,
        v: getVKApiVersion(),
        access_token: getVKToken(),
      },
    });

    responseHandler(method, response);

    return response;
  } catch (e) {
    errorHandler(method, e);
    return e;
  }
}

export default APIVkExecute;
