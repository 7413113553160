import { queryEye3 } from "api";

async function APIEye3FriendsHistoryTrackActive({ userId }) {
  try {
    const response = await queryEye3({
      method: "trackactive.friends_history",
      get: {
        user_id: userId,
      },
      access: { isBotConnect: true, isLicense: true, isLimit: true },
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3FriendsHistoryTrackActive;
