import {
  JOIN_COMMUNITY_REQUEST,
  JOIN_COMMUNITY_SUCCESS,
  JOIN_COMMUNITY_ERROR,
  JOIN_COMMUNITY_SHOW_MORE,
  JOIN_COMMUNITY_SEARCH_REQUEST,
  JOIN_COMMUNITY_SEARCH_SUCCESS,
  JOIN_COMMUNITY_SEARCH_ERROR,
  JOIN_COMMUNITY_FILTER_REQUEST,
  JOIN_COMMUNITY_FILTER_SUCCESS,
  JOIN_COMMUNITY_FILTER_ERROR,
  JOIN_COMMUNITY__INFO_GROUP_REQUEST,
  JOIN_COMMUNITY__INFO_GROUP_SUCCESS,
  JOIN_COMMUNITY__INFO_GROUP_ERROR,
  JOIN_COMMUNITY__INFO_GROUP_SET_PERIOD,
} from "../../constType";

import { PERIOD_MONTH } from "template/popouts/DateChangePeriod/constPeriod";

const initialState = {
  friends: {
    payload: null,
    showCount: 20,
    cachePayload: null,
    search: "",
    filter: "",
    loading: false,
    error: null,
    isOnce: false,
  },
  groups: {
    payload: null,
    activeUserId: null, // Параметры для сохранения пользователя
    activeUserPhoto: null, //
    activeUserTitle: null, //
    activeUserDescription: null, //
    activeUserSex: null,
    loading: false,
    error: null,
    period: {
      name: PERIOD_MONTH,
      atMin: null,
      atMax: null,
    },
  },
};

export default function joinCommunityReducer(state = initialState, action) {
  switch (action.type) {
    case JOIN_COMMUNITY_REQUEST:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: null,
          cachePayload:
            state.friends.search === "" && state.friends.filter === ""
              ? null // Если поле поиска и фильтра пустое то очистить кэш
              : state.friends.cachePayload,
          loading: true,
          error: null,
          isOnce: true,
        },
      };
    case JOIN_COMMUNITY_SUCCESS:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: action.payload,
          loading: false,
          cachePayload:
            state.friends.search === "" && state.friends.filter === ""
              ? action.payload // Если поле поиска и фильтра пустое то сохранить результат
              : state.friends.cachePayload,
        },
      };
    case JOIN_COMMUNITY_ERROR:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          error: action.error,
        },
      };
    case JOIN_COMMUNITY_SHOW_MORE:
      return {
        ...state,
        friends: {
          ...state.friends,
          showCount: parseInt(state.friends.showCount) + 20,
        },
      };
    case JOIN_COMMUNITY_SEARCH_REQUEST:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: null,
          showCount: 20,
          search: action.search,
          loading: true,
          error: null,
        },
      };
    case JOIN_COMMUNITY_SEARCH_SUCCESS:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: [...action.payload],
          loading: false,
        },
      };
    case JOIN_COMMUNITY_SEARCH_ERROR:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          error: action.error,
        },
      };
    case JOIN_COMMUNITY_FILTER_REQUEST:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: null,
          showCount: 20,
          filter: action.filter,
          loading: true,
          error: null,
        },
      };
    case JOIN_COMMUNITY_FILTER_SUCCESS:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          payload: [...action.payload],
        },
      };
    case JOIN_COMMUNITY_FILTER_ERROR:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          error: action.error,
        },
      };
    case JOIN_COMMUNITY__INFO_GROUP_REQUEST:
      return {
        ...state,
        groups: {
          ...state.groups,
          payload: null,
          activeUserId: action.id,
          activeUserDescription: action.description,
          activeUserTitle: action.title,
          activeUserPhoto: action.photo,
          activeUserSex: action.sex,
          loading: true,
          error: null,
        },
      };
    case JOIN_COMMUNITY__INFO_GROUP_SUCCESS:
      return {
        ...state,
        groups: {
          ...state.groups,
          payload: action.payload,
          loading: false,
        },
      };
    case JOIN_COMMUNITY__INFO_GROUP_ERROR:
      return {
        ...state,
        groups: {
          ...state.groups,
          loading: false,
          error: action.error,
        },
      };
    case JOIN_COMMUNITY__INFO_GROUP_SET_PERIOD:
      return {
        ...state,
        groups: {
          ...state.groups,
          period: {
            ...state.groups.period,
            name: action.name,
            atMin: action.atMin,
            atMax: action.atMax,
          },
        },
      };
    default:
      return state;
  }
}
