import {
  TOTAL_SURVEILLANCE_REQUEST,
  TOTAL_SURVEILLANCE_SUCCESS,
  TOTAL_SURVEILLANCE_ERROR,
  TOTAL_SURVEILLANCE__START_TRACKING_REQUEST,
  TOTAL_SURVEILLANCE__START_TRACKING_SUCCESS,
  TOTAL_SURVEILLANCE__START_TRACKING_ERROR,
  TOTAL_SURVEILLANCE__START_TRACKING_SHOW_MORE,
  TOTAL_SURVEILLANCE__START_TRACKING_SEARCH_REQUEST,
  TOTAL_SURVEILLANCE__START_TRACKING_SEARCH_SUCCESS,
  TOTAL_SURVEILLANCE__START_TRACKING_SEARCH_ERROR,
  TOTAL_SURVEILLANCE__START_TRACKING_FILTER_REQUEST,
  TOTAL_SURVEILLANCE__START_TRACKING_FILTER_SUCCESS,
  TOTAL_SURVEILLANCE__START_TRACKING_FILTER_ERROR,
  TOTAL_SURVEILLANCE_SET_TRACKING_REQUEST,
  TOTAL_SURVEILLANCE_SET_TRACKING_SUCCESS,
  TOTAL_SURVEILLANCE_SET_TRACKING_ERROR,
  TOTAL_SURVEILLANCE_SET_NOTIFICATION_REQUEST,
  TOTAL_SURVEILLANCE_SET_NOTIFICATION_SUCCESS,
  TOTAL_SURVEILLANCE_SET_NOTIFICATION_ERROR,
} from "../../constType";

import {
  checkLicenseEYE3Api,
  checkLimitEYE3Api,
  checkVKMethodError,
  delayMethodGetUsersVKApi,
} from "api";

import { calcAge } from "helpers";

import {
  APIEye3GetUsersTrackActive,
  APIEye3SetEnableTrackActive,
  APIEye3SetNotificationTrackActive,
} from "api/eye3";

import {
  getFilterFriends,
  getFriendsComparePopular,
  getSearchFriends,
  getSearchURLUsers,
} from "helpers/actions";
import { openModal } from "../page";
import {
  MODAL_CARD_TEMPLATE_TARIFF_LIMIT,
  MODAL_CARD_TEMPLATE_TARIFF_WORK,
} from "template/modals/constModals";
import { defaultErrorProperty } from "api/eye3/responseHandler";

async function getStartTracking({
  friends,
  trackUsers,
  search,
  filter,
  fields = ["photo_100", "bdate", "city", "country", "sex"],
}) {
  try {
    let usersItems = null;
    let { users, isSearchVkId } = await getSearchURLUsers({
      search,
      fields,
    });

    let result = [];

    if (users !== null && checkVKMethodError(users)) {
      // Если в методе возникла ошибка
      return result;
    }

    if (!isSearchVkId) {
      users = await getFriendsComparePopular({
        friends: friends,
        fields: fields,
      });
    }

    usersItems = users.response.hasOwnProperty("items") ? users.response.items : users.response;

    if (usersItems.length === 0) return result;

    usersItems.forEach((user) => {
      let isTracking = false;

      trackUsers.forEach((trackUser) => {
        if (trackUser.vk_id === user.id) {
          // Если пользователь найден как отслеживаемый из списка друзей
          isTracking = true;
          return;
        }
      });

      if (!user.hasOwnProperty("deactivated")) {
        result.push({
          vk_id: user.id,
          name: user.first_name + " " + user.last_name,
          age: user.bdate ? calcAge(user.bdate) : null, // Расчет возраста по дате рождения
          sex: user.sex ? user.sex.toString() : null,
          city: user.city ? user.city.title : null, // Город
          city_id: user.city ? user.city.id.toString() : null, // Город id
          photo_url: user.photo_100 ? user.photo_100 : "", // Фото
          country: user.country ? user.country.title : null, // Страна
          country_id: user.country ? user.country.id.toString() : null, // Страна id
          is_tracking: isTracking,
        });
      }
    });

    //console.log('result: ', result);

    result = getFilterFriends({
      data: result,
      filter: filter,
    });

    result = getSearchFriends({
      data: result,
      search: search,
      isSearchVkId,
    });

    return result;
  } catch (e) {
    throw e;
  }
}

async function getUserTracking() {
  try {
    const trackingUsersIds = await APIEye3GetUsersTrackActive(); // Получить список id отслеживаемых пользователей
    let trackingUsers = [];
    let trackingUsersIdsItems = [];

    trackingUsersIds.items.forEach((user) => {
      trackingUsersIdsItems.push({
        is_notification: user.is_notification,
      });
    });

    const trackingUsersInfo = await delayMethodGetUsersVKApi({
      userIds: trackingUsersIds.items.map((user) => user.vk_id),
      fields: ["bdate", "city", "country", "photo_100"],
    }); // Получить информацию о пользователях

    trackingUsersInfo.forEach((user, i) => {
      trackingUsers.push({
        vk_id: user.id, // id гостя
        name: user.first_name + " " + user.last_name, // Фамилия и имя
        age: user.bdate ? calcAge(user.bdate) : null, // Расчет возраста по дате рождения
        city: user.city ? user.city.title : null, // Город
        city_id: user.city ? user.city.id.toString() : null, // Город id
        photo_url: user.photo_100 ? user.photo_100 : "", // Фото
        country: user.country ? user.country.title : null, // Страна
        country_id: user.country ? user.country.id.toString() : null, // Страна id
        is_notification: trackingUsersIdsItems[i].is_notification,
      });
    });

    return trackingUsers;
  } catch (e) {
    throw e;
  }
}

export function fetchTotalSurveillance() {
  return async (dispatch) => {
    try {
      dispatch(request());
      const trackingUser = await getUserTracking();
      dispatch(success(trackingUser));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: TOTAL_SURVEILLANCE_REQUEST };
  }

  function success(payload) {
    return { type: TOTAL_SURVEILLANCE_SUCCESS, payload };
  }

  function error(error) {
    return { type: TOTAL_SURVEILLANCE_ERROR, error };
  }
}

export function fetchStartTracking() {
  return async (dispatch, getState) => {
    try {
      dispatch(request());
      const state = getState().toolTotalSurveillance;
      const res = await getStartTracking({
        friends: getState().friend.payload,
        trackUsers: state.payloadCacheUser,
        search: state.startTracking.search,
        filter: state.startTracking.filter,
      });
      dispatch(success(res));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: TOTAL_SURVEILLANCE__START_TRACKING_REQUEST };
  }

  function success(payload) {
    return { type: TOTAL_SURVEILLANCE__START_TRACKING_SUCCESS, payload };
  }

  function error(error) {
    return { type: TOTAL_SURVEILLANCE__START_TRACKING_ERROR, error };
  }
}

export function showMoreStartTracking() {
  return { type: TOTAL_SURVEILLANCE__START_TRACKING_SHOW_MORE };
}

export function searchStartTracking(search = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(request(search));
      const state = getState().toolTotalSurveillance;
      const res = await getStartTracking({
        friends: getState().friend.payload,
        trackUsers: state.payloadCacheUser,
        search: state.startTracking.search,
        filter: state.startTracking.filter,
      });

      dispatch(success(res));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(search) {
    return { type: TOTAL_SURVEILLANCE__START_TRACKING_SEARCH_REQUEST, search };
  }

  function success(payload) {
    return { type: TOTAL_SURVEILLANCE__START_TRACKING_SEARCH_SUCCESS, payload };
  }

  function error(error) {
    return { type: TOTAL_SURVEILLANCE__START_TRACKING_SEARCH_ERROR, error };
  }
}

export function filterStartTracking(filter = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(request(filter));
      const state = getState().toolTotalSurveillance;
      const res = await getStartTracking({
        friends: getState().friend.payload,
        trackUsers: state.payloadCacheUser,
        search: state.startTracking.search,
        filter: state.startTracking.filter,
      });
      dispatch(success(res));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(filter) {
    return { type: TOTAL_SURVEILLANCE__START_TRACKING_FILTER_REQUEST, filter };
  }

  function success(payload) {
    return { type: TOTAL_SURVEILLANCE__START_TRACKING_FILTER_SUCCESS, payload };
  }

  function error(error) {
    return { type: TOTAL_SURVEILLANCE__START_TRACKING_FILTER_ERROR, error };
  }
}

export function setTracking(id, isEnable, options = null) {
  return async (dispatch) => {
    try {
      dispatch(request());

      const tracking = await APIEye3SetEnableTrackActive({
        userId: id,
        enable: +isEnable,
        settings: options,
      });
      //console.log(tracking);

      if (!checkLicenseEYE3Api(tracking)) {
        dispatch(openModal({ id: MODAL_CARD_TEMPLATE_TARIFF_WORK }));
        dispatch(success(id, 0));
        return;
      } else if (!checkLimitEYE3Api(tracking)) {
        dispatch(openModal({ id: MODAL_CARD_TEMPLATE_TARIFF_LIMIT }));
        dispatch(success(id, 0));
        return;
      }

      dispatch(success(id, isEnable));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: TOTAL_SURVEILLANCE_SET_TRACKING_REQUEST };
  }
  function success(id, isEnable) {
    return { type: TOTAL_SURVEILLANCE_SET_TRACKING_SUCCESS, id, isEnable };
  }
  function error(error) {
    return { type: TOTAL_SURVEILLANCE_SET_TRACKING_ERROR, error };
  }
}

export function setNotification(id, isEnable) {
  return async (dispatch) => {
    try {
      dispatch(request());
      await APIEye3SetNotificationTrackActive({ userId: id, isNotification: +isEnable });

      dispatch(success(id, isEnable));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: TOTAL_SURVEILLANCE_SET_NOTIFICATION_REQUEST };
  }

  function success(id, isEnable) {
    return { type: TOTAL_SURVEILLANCE_SET_NOTIFICATION_SUCCESS, id, isEnable };
  }

  function error(error) {
    return { type: TOTAL_SURVEILLANCE_SET_NOTIFICATION_ERROR, error };
  }
}
