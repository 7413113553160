import React from "react";
import { useSelector } from "react-redux";
import { Button, Div } from "@vkontakte/vkui";
import { VIEW_SHOP } from "views/constViews";
import { PANEL_BALANCE_REPLENISHMENT } from "../../panels/constPanels";
import CoinIcon from "assets/img/panelHeader/coin.png";
import { declOfNum } from "helpers";
import styles from "./BalanceBanner.module.css";

const BalanceBanner = (props) => {
  const payloadEYE3 = useSelector((state) =>
    state.myProfile.payloadEYE3 ? state.myProfile.payloadEYE3 : null
  );
  const balance = payloadEYE3 ? payloadEYE3.user.balance : null;
  const balanceStr = balance
    ? +balance + " " + declOfNum(balance, ["монета", "монеты", "монет"])
    : "0 монет";

  return (
    <Div style={{ paddingBottom: "0px" }}>
      <div className={styles.container}>
        <div className={styles.moneyCaption}>Баланс</div>
        <div className={styles.between}>
          <div style={{ position: "relative" }}>
            <img className={styles.coinIcon} src={CoinIcon} alt="" />
            <span className={styles.moneyValue}>{balanceStr}</span>
          </div>
          <div>
            <Button
              mode="commerce"
              onClick={() => {
                props.openView({ view: VIEW_SHOP, panel: PANEL_BALANCE_REPLENISHMENT });
              }}
            >
              Пополнить
            </Button>
          </div>
        </div>
      </div>
    </Div>
  );
};

export default BalanceBanner;
