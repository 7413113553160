import { queryEye3 } from "api";

async function APIEye3GetChangesTrackOnline({ userId, startAt = null, endAt = null }) {
  try {
    const response = await queryEye3({
      method: "trackonline.get_changes",
      get: {
        user_id: userId,
        start_at: startAt,
        end_at: endAt,
      },
      access: { isBotConnect: true, isLicense: true, isLimit: true },
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3GetChangesTrackOnline;
