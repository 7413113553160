import {
  PAGE_OPEN_VIEW,
  PAGE_OPEN_STORY,
  PAGE_OPEN_MODAL,
  PAGE_CLOSE_MODAL,
  PAGE_GO_BACK,
  PAGE_GO_BACK_MODAL,
  PAGE_OPEN_POPOUT,
  PAGE_CLOSE_POPOUT,
  PAGE_GO_BACK_POPOUT,
  PAGE_ON_TRANSITION,
  PAGE_CLEAR_HISTORY,
} from "../constType";

const initialState = {
  activeView: null, // Текущаю вью
  activeStory: null, // Текущаю вкладка tabbar
  activePanel: null, // Текущаю панель
  param: null, // параметры
  isTransitionEnd: false, // Если анимация перехода завершена

  storiesHistory: [],
  viewsHistory: {},
  panelsHistory: {},
  paramHistory: {},

  activeModal: [],
  modalParam: null,
  modalHistory: [],

  popouts: [],

  scrollPosition: {},
};

export default function pageReducer(state = initialState, action) {
  switch (action.type) {
    case PAGE_OPEN_STORY:
      return {
        ...state,
        activeStory: action.activeStory,
        activeView: action.activeView,
        activePanel: action.activePanel,
        param: action.setParam,
        isTransitionEnd: true,

        storiesHistory: action.storiesHistory,

        viewsHistory: {
          ...state.viewsHistory,
          [action.activeView]: action.viewsHistory,
        },
        paramHistory: {
          ...state.paramHistory,
          [action.activeStory + "/" + action.activeView + "/" + action.activePanel]:
            action.setParam,
        },
        panelsHistory: {
          ...state.panelsHistory,
          [action.activeView]: action.panelsHistory,
        },
        scrollPosition: {
          ...state.scrollPosition,
          [state.activeStory + "/" + state.activeView + "/" + state.activePanel]:
            window.pageYOffset,
        },
      };
    case PAGE_OPEN_VIEW:
      return {
        ...state,
        activePanel: action.Panel,
        activeView: action.View,
        param: action.param,
        isTransitionEnd: false,

        panelsHistory: {
          ...state.panelsHistory,
          [action.View]: action.panelsHistory,
        },
        viewsHistory: {
          ...state.viewsHistory,
          [state.activeStory]: [...action.viewsHistory, action.View],
        },
        paramHistory: {
          ...state.paramHistory,
          [state.activeStory + "/" + action.View + "/" + action.Panel]: action.param,
        },
        scrollPosition: {
          ...state.scrollPosition,
          [state.activeStory + "/" + state.activeView + "/" + state.activePanel]:
            window.pageYOffset,
        },
      };
    case PAGE_GO_BACK:
      return {
        ...state,
        activeView: action.setView,
        activePanel: action.setPanel,
        activeStory: action.setStory,
        param: action.setParam,
        isTransitionEnd: false,

        viewsHistory: {
          ...state.viewsHistory,
          [state.activeView]: action.viewsHistory,
        },
        paramHistory: {
          ...state.paramHistory,
          [state.activeStory + "/" + state.activeView + "/" + state.activePanel]:
            action.paramHistory,
        },
        panelsHistory: {
          ...state.panelsHistory,
          [state.activeView]: action.panelsHistory,
        },
      };
    case PAGE_CLEAR_HISTORY:
      return {
        ...state,
        storiesHistory: initialState.storiesHistory,
        viewsHistory: initialState.viewsHistory,
        paramHistory: initialState.paramHistory,
        panelsHistory: initialState.panelsHistory,
        scrollPosition: initialState.scrollPosition,
        modalHistory: initialState.modalHistory,
      };
    case PAGE_OPEN_MODAL:
      return {
        ...state,
        activeModal: {
          ...state.activeModal,
          [state.activeView]: action.activeModal,
        },
        modalHistory: {
          ...state.modalHistory,
          [state.activeView]: action.modalHistory,
        },
        modalParam: action.modalParam,
      };
    case PAGE_CLOSE_MODAL:
      return {
        ...state,
        activeModal: {
          ...state.activeModal,
          [state.activeView]: action.activeModal,
        },
        modalHistory: {
          ...state.modalHistory,
          [state.activeView]: action.modalHistory,
        },
        modalParam: null,
      };
    case PAGE_GO_BACK_MODAL:
      return {
        ...state,
        activeModal: {
          ...state.activeModal,
          [action.setView]: action.activeModal,
        },
        modalHistory: {
          ...state.modalHistory,
          [action.setView]: action.modalHistory,
        },
      };
    case PAGE_OPEN_POPOUT:
      return {
        ...state,
        popouts: {
          ...state.popouts,
          [state.activeView]: action.popout,
        },
      };
    case PAGE_CLOSE_POPOUT:
      return {
        ...state,
        popouts: {
          ...state.popouts,
          [state.activeView]: null,
        },
      };
    case PAGE_GO_BACK_POPOUT:
      return {
        ...state,
        popouts: {
          ...state.popouts,
          ...action.popouts,
        },
      };
    case PAGE_ON_TRANSITION:
      return {
        ...state,
        isTransitionEnd: true,
      };
    default:
      return state;
  }
}
