import { getSearchFilterFriendsV2 } from "helpers/actions";
import {
  MENTION_REQUEST,
  MENTION_SUCCESS,
  MENTION_ERROR,
  MENTION_SHOW_MORE,
  MENTION__INFO_REQUEST,
  MENTION__INFO_SUCCESS,
  MENTION__INFO_ERROR,
  MENTION__INFO_SET_PERIOD,
  MENTION_FILTER_REQUEST,
  MENTION_FILTER_SUCCESS,
  MENTION_FILTER_ERROR,
  MENTION_SEARCH_REQUEST,
  MENTION_SEARCH_SUCCESS,
  MENTION_SEARCH_ERROR,
  MENTION__INFO_LOAD_MORE_REQUEST,
  MENTION__INFO_LOAD_MORE_SUCCESS,
  MENTION__INFO_LOAD_MORE_ERROR,
} from "../../constType";
import { openModal } from "../page";
import { getPeriod } from "template/popouts/DateChangePeriod/actions";
import { checkEYE3ApiError, defaultErrorProperty } from "api/eye3/responseHandler";
import { APIEye3GetMention } from "api/eye3";
import { APIVkGetUsers } from "api/vk/apiMethods";
import {
  MENTION_REPLY,
  MENTION_COMMUNITY,
  MENTION_RECORD,
} from "views/tool/panels/mention/constPostType";
import { delayMethodGetByIdGroupsVKApi } from "api";

export function fetchMention() {
  return async (dispatch, getState) => {
    try {
      dispatch(request());

      const state = getState().toolMention;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.friends.search,
        filter: state.friends.filter,
        cache: state.friends.cachePayload,
      });

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: MENTION_REQUEST };
  }

  function success(payload) {
    return { type: MENTION_SUCCESS, payload };
  }

  function error(error) {
    return { type: MENTION_ERROR, error };
  }
}

export function showMoreMention() {
  return { type: MENTION_SHOW_MORE };
}

export function filterMention(filter = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(request(filter));

      const state = getState().toolMention;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.friends.search,
        filter: state.friends.filter,
        cache: state.friends.cachePayload,
      });

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(filter) {
    return { type: MENTION_FILTER_REQUEST, filter };
  }

  function success(payload) {
    return { type: MENTION_FILTER_SUCCESS, payload };
  }

  function error(error) {
    return { type: MENTION_FILTER_ERROR, error };
  }
}

export function searchMention(search = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(request(search));

      const state = getState().toolMention;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.friends.search,
        filter: state.friends.filter,
        cache: state.friends.cachePayload,
      });

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(search) {
    return { type: MENTION_SEARCH_REQUEST, search };
  }

  function success(payload) {
    return { type: MENTION_SEARCH_SUCCESS, payload };
  }

  function error(error) {
    return { type: MENTION_SEARCH_ERROR, error };
  }
}

async function getFromMention(mentionsItems) {
  try {
    let result = {
      groups: [],
      profiles: [],
      items: mentionsItems,
    };

    let groupIds = [];
    let userIds = [];

    for (let mention of mentionsItems) {
      if (mention.from_id < 0) {
        groupIds.push(Math.abs(mention.from_id));
      } else {
        userIds.push(mention.from_id);
      }
    }

    /*console.log('userIds: ', userIds);
        console.log('groupIds: ', groupIds);*/

    if (groupIds.length !== 0) {
      const groups = await delayMethodGetByIdGroupsVKApi({
        groupIds: groupIds,
      });

      result.groups.push(...groups);

      //console.log('groups: ', groups);
    }

    if (userIds.length !== 0) {
      const users = await APIVkGetUsers({
        userIds: userIds.join(","),
        fields: ["photo_100"].join(","),
      });

      result.profiles.push(...users.response);

      //console.log('users: ', users);
    }

    return result;
  } catch (e) {
    throw new Error("getFromMention::: " + e);
  }
}

function getCombiningResult(fromMentions) {
  let { groups, items, profiles } = fromMentions;
  let result = [];

  for (let item of items) {
    //console.log('item: ', item);

    if (item.from_id < 0) {
      for (let group of groups) {
        if (Math.abs(item.from_id) === group.id) {
          result.push({
            name: group.name,
            photo_url: group.photo_100,
            text: item.text,
            type: item.post_type === "post" ? MENTION_COMMUNITY : MENTION_REPLY,
            date: item.date,
            type_page: group.type === "page" ? "public" : "club",
            from_id: item.from_id,
            to_id: item.to_id ? item.to_id : null,
            post_id: item.id ? item.id : null,
          });

          break;
        }
      }
    } else {
      for (let profile of profiles) {
        if (item.from_id === profile.id) {
          result.push({
            name: profile.first_name + " " + profile.last_name,
            photo_url: profile.photo_100,
            text: item.text,
            type: item.post_type === "post" ? MENTION_RECORD : MENTION_REPLY,
            date: item.date,
            type_page: "id",
            from_id: item.from_id,
            to_id: item.to_id ? item.to_id : null,
            post_id: item.post_id ? item.post_id : null,
          });

          break;
        }
      }
    }
  }

  return result;
}

function getMentionsPeriod(dispatch, getState, mentions, maxCount) {
  const period = getState().toolMention.mentions.period;
  const { atMin, atMax } = getPeriod(period);
  let result = [];

  for (let mention of mentions) {
    if ((mention.date >= atMin && mention.date <= atMax) || (atMin === null && atMax === null)) {
      mention.text = getParseText(mention.text);
      result.push(mention);
    }
  }

  return {
    items: result,
    maxLength: atMin !== null && atMax !== null ? result.length : maxCount,
  };
}

function getParseText(text) {
  let str = text;

  const exp = /[[](id|public|club|event)([0-9]{1,9})+[|](.+?)[\]]/giu;
  str = str.replace(
    exp,
    // eslint-disable-next-line quotes
    '<a target="_blank" href="https://vk.com/$1$2" style="color: var(--color-custom-link); text-decoration: none">$3</a>'
  );

  return str;
}

async function getMentionInfo(dispatch, getState, error) {
  try {
    const state = getState().toolMention;
    const pageParam = getState().page.param;

    const id = pageParam ? pageParam.activeUserId : state.mentions.activeUserId;

    const count = state.mentions.count;
    const offset = state.mentions.offset;

    //console.log('count: ', count);

    const mentions = await APIEye3GetMention({
      ownerId: id,
      count: count,
      offset: offset,
    });

    //console.log('mentions: ', mentions);

    let result = [];

    // Проверка ошибок в API
    const errorAPI = checkEYE3ApiError(mentions);
    if (errorAPI !== false) {
      errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
      dispatch(error({ ...errorAPI }));
      return {
        result: result,
        maxLength: 0,
      };
    }

    const mentionsItems = mentions.response.items;
    const fromMentions = await getFromMention(mentionsItems);
    //console.log('fromMentions: ', fromMentions);

    const combiningResult = getCombiningResult(fromMentions);
    const { items, maxLength } = getMentionsPeriod(
      dispatch,
      getState,
      combiningResult,
      mentions.response.count
    );

    //console.log('items: ', items);

    return {
      result: items,
      maxLength: maxLength,
    };
  } catch (e) {
    throw new Error("getMentionInfo::: " + e);
  }
}

export function fetchMentionInfo() {
  return async (dispatch, getState) => {
    try {
      const state = getState().toolMention;
      const pageParam = getState().page.param;

      const id = pageParam ? pageParam.activeUserId : state.mentions.activeUserId;
      const title = pageParam ? pageParam.activeUserTitle : state.mentions.activeUserTitle;
      const description = pageParam
        ? pageParam.activeUserDescription
        : state.mentions.activeUserDescription;
      const photo = pageParam ? pageParam.activeUserPhoto : state.mentions.activeUserPhoto;

      await dispatch(request(id, title, description, photo));

      const { result, maxLength } = await getMentionInfo(dispatch, getState, error);

      if (getState().toolMention.mentions.error === null)
        await dispatch(success(result, maxLength));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(id, title, description, photo) {
    return { type: MENTION__INFO_REQUEST, id, title, description, photo };
  }

  function success(payload, maxLength) {
    return { type: MENTION__INFO_SUCCESS, payload, maxLength };
  }

  function error(error) {
    return { type: MENTION__INFO_ERROR, error };
  }
}

export function setPeriodMentionInfo(name, atMin = null, atMax = null) {
  return { type: MENTION__INFO_SET_PERIOD, name, atMin, atMax };
}

export function loadMoreMentionInfo() {
  return async (dispatch, getState) => {
    try {
      await dispatch(request());
      const { result } = await getMentionInfo(dispatch, getState, error);

      // console.log('result: ', result);

      await dispatch(success(result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: MENTION__INFO_LOAD_MORE_REQUEST };
  }

  function success(payload) {
    return { type: MENTION__INFO_LOAD_MORE_SUCCESS, payload };
  }

  function error(error) {
    return { type: MENTION__INFO_LOAD_MORE_ERROR, error };
  }
}
