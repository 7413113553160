import { PERIOD_MONTH } from "template/popouts/DateChangePeriod/constPeriod";
import {
  POSTING_REQUEST,
  POSTING_SUCCESS,
  POSTING_ERROR,
  POSTING_SHOW_MORE,
  POSTING_SEARCH_REQUEST,
  POSTING_SEARCH_SUCCESS,
  POSTING_SEARCH_ERROR,
  POSTING_FILTER_REQUEST,
  POSTING_FILTER_SUCCESS,
  POSTING_FILTER_ERROR,
  POSTING__INFO_REQUEST,
  POSTING__INFO_SUCCESS,
  POSTING__INFO_ERROR,
  POSTING__INFO_SET_PERIOD,
  POSTING__INFO_LOAD_MORE_REQUEST,
  POSTING__INFO_LOAD_MORE_SUCCESS,
  POSTING__INFO_LOAD_MORE_ERROR,
} from "../../constType";

const initialState = {
  friends: {
    payload: null,
    showCount: 20,
    cachePayload: null,
    search: "",
    filter: "",
    loading: false,
    error: null,
    isOnce: false,
  },
  posts: {
    payload: null,
    maxLength: 0,
    count: 50,
    offset: 0,
    activeUserId: null, // Параметры для сохранения пользователя
    activeUserPhoto: null, //
    activeUserTitle: null, //
    activeUserDescription: null, //
    loading: false,
    error: null,
    period: {
      name: PERIOD_MONTH,
      atMin: null,
      atMax: null,
    },
  },
};

export default function postingReducer(state = initialState, action) {
  switch (action.type) {
    case POSTING_REQUEST:
      return {
        ...state,
        friends: {
          ...state.friends,
          showCount: 20,
          payload: null,
          cachePayload:
            state.friends.search === "" && state.friends.filter === ""
              ? null // Если поле поиска и фильтра пустое то очистить кэш
              : state.friends.cachePayload,
          loading: true,
          error: null,
          isOnce: true,
        },
      };
    case POSTING_SUCCESS:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: action.payload,
          loading: false,
          cachePayload:
            state.friends.search === "" && state.friends.filter === ""
              ? action.payload // Если поле поиска и фильтра пустое то сохранить результат
              : state.friends.cachePayload,
        },
      };
    case POSTING_ERROR:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          error: action.error,
        },
      };
    case POSTING_SHOW_MORE:
      return {
        ...state,
        friends: {
          ...state.friends,
          showCount: parseInt(state.friends.showCount) + 20,
        },
      };
    case POSTING_SEARCH_REQUEST:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: null,
          showCount: 20,
          search: action.search,
          loading: true,
          error: null,
        },
      };
    case POSTING_SEARCH_SUCCESS:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: action.payload,
          loading: false,
        },
      };
    case POSTING_SEARCH_ERROR:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          error: action.error,
        },
      };
    case POSTING_FILTER_REQUEST:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: null,
          showCount: 20,
          filter: action.filter,
          loading: true,
          error: null,
        },
      };
    case POSTING_FILTER_SUCCESS:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          payload: action.payload,
        },
      };
    case POSTING_FILTER_ERROR:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          error: action.error,
        },
      };
    case POSTING__INFO_REQUEST:
      return {
        ...state,
        posts: {
          ...state.posts,
          offset: 0,
          maxLength: 0,
          payload: null,
          activeUserId: action.id,
          activeUserDescription: action.description,
          activeUserTitle: action.title,
          activeUserPhoto: action.photo,
          loading: true,
          error: null,
        },
      };
    case POSTING__INFO_SUCCESS:
      return {
        ...state,
        posts: {
          ...state.posts,
          payload: action.payload,
          maxLength: action.maxLength,
          loading: false,
        },
      };
    case POSTING__INFO_ERROR:
      return {
        ...state,
        posts: {
          ...state.posts,
          loading: false,
          error: action.error,
        },
      };
    case POSTING__INFO_SET_PERIOD:
      return {
        ...state,
        posts: {
          ...state.posts,
          period: {
            ...state.posts.period,
            name: action.name,
            atMin: action.atMin,
            atMax: action.atMax,
          },
        },
      };
    case POSTING__INFO_LOAD_MORE_REQUEST:
      return {
        ...state,
        posts: {
          ...state.posts,
          loading: true,
          offset:
            state.posts.offset === 0
              ? (state.posts.offset = state.posts.count + 1)
              : (state.posts.offset += 50), // Подгрузка постов
        },
      };
    case POSTING__INFO_LOAD_MORE_SUCCESS:
      return {
        ...state,
        posts: {
          ...state.posts,
          loading: false,
          payload: [...state.posts.payload, ...action.payload],
        },
      };
    case POSTING__INFO_LOAD_MORE_ERROR:
      return {
        ...state,
        posts: {
          ...state.posts,
          loading: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}
