import {
  WHO_HIDDEN__WHO_HIDDEN_ME_REQUEST,
  WHO_HIDDEN__WHO_HIDDEN_ME_SUCCESS,
  WHO_HIDDEN__WHO_HIDDEN_ME_ERROR,
  WHO_HIDDEN__WHO_HIDDEN_ME_PROGRESS,
  WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_REQUEST,
  WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_SUCCESS,
  WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_ERROR,
  WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_SHOW_MORE,
  WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_SEARCH_REQUEST,
  WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_SEARCH_SUCCESS,
  WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_SEARCH_ERROR,
  WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_FILTER_REQUEST,
  WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_FILTER_SUCCESS,
  WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_FILTER_ERROR,
  WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_INFO_REQUEST,
  WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_INFO_PROGRESS,
  WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_INFO_CANCEL,
  WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_INFO_SUCCESS,
  WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_INFO_ERROR,
  WHO_HIDDEN__WHOM_HIDDEN_IM_REQUEST,
  WHO_HIDDEN__WHOM_HIDDEN_IM_SUCCESS,
  WHO_HIDDEN__WHOM_HIDDEN_IM_ERROR,
  WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_REQUEST,
  WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_SUCCESS,
  WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_ERROR,
  WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_SHOW_MORE,
  WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_SEARCH_REQUEST,
  WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_SEARCH_SUCCESS,
  WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_SEARCH_ERROR,
  WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_FILTER_REQUEST,
  WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_FILTER_SUCCESS,
  WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_FILTER_ERROR,
  WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_INFO_REQUEST,
  WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_INFO_PROGRESS,
  WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_INFO_CANCEL,
  WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_INFO_SUCCESS,
  WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_INFO_ERROR,
  WHO_HIDDEN__WHO_HIDDEN_ME_CANCEL,
} from "../../constType";

const initialState = {
  whoHiddenMe: {
    payload: null,
    loading: false,
    progressValue: 0,
    error: null,
  },
  whoHiddenAnotherUser: {
    payload: null,
    cachePayload: null,
    showCount: 20,
    filter: "",
    search: "",
    isOnce: false,
    loading: false,
    error: null,
  },
  whoHiddenAnotherUserInfo: {
    payload: null,
    activeUserId: null,
    loading: false,
    progressValue: 0,
    error: null,
  },
  whomHiddenAnotherUser: {
    payload: null,
    cachePayload: null,
    showCount: 20,
    filter: "",
    search: "",
    isOnce: false,
    loading: false,
    error: null,
  },
  whomHiddenAnotherUserInfo: {
    payload: null,
    activeUserId: null,
    loading: false,
    progressValue: 0,
    error: null,
  },
  whomHiddenIm: {
    payload: null,
    loading: false,
    error: null,
  },
};

export default function whoHiddenReducer(state = initialState, action) {
  switch (action.type) {
    case WHO_HIDDEN__WHO_HIDDEN_ME_REQUEST:
      return {
        ...state,
        whoHiddenMe: {
          ...state.whoHiddenMe,
          payload: null,
          loading: true,
          error: null,
        },
      };
    case WHO_HIDDEN__WHO_HIDDEN_ME_SUCCESS:
      return {
        ...state,
        whoHiddenMe: {
          ...state.whoHiddenMe,
          payload: action.payload,
          loading: false,
        },
      };
    case WHO_HIDDEN__WHO_HIDDEN_ME_ERROR:
      return {
        ...state,
        whoHiddenMe: {
          ...state.whoHiddenMe,
          loading: false,
          error: action.error,
        },
      };
    case WHO_HIDDEN__WHO_HIDDEN_ME_PROGRESS:
      return {
        ...state,
        whoHiddenMe: {
          ...state.whoHiddenMe,
          payload: action.payload,
          progressValue: action.progressValue,
        },
      };
    case WHO_HIDDEN__WHO_HIDDEN_ME_CANCEL:
      return {
        ...state,
        whoHiddenMe: {
          ...state.whoHiddenMe,
          loading: false,
        },
      };
    case WHO_HIDDEN__WHOM_HIDDEN_IM_REQUEST:
      return {
        ...state,
        whomHiddenIm: {
          ...state.whomHiddenIm,
          payload: null,
          loading: true,
          error: null,
        },
      };
    case WHO_HIDDEN__WHOM_HIDDEN_IM_SUCCESS:
      return {
        ...state,
        whomHiddenIm: {
          ...state.whomHiddenIm,
          loading: false,
          payload: action.payload,
        },
      };
    case WHO_HIDDEN__WHOM_HIDDEN_IM_ERROR:
      return {
        ...state,
        whomHiddenIm: {
          ...state.whomHiddenIm,
          loading: false,
          error: action.error,
        },
      };
    case WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_REQUEST:
      return {
        ...state,
        whoHiddenAnotherUser: {
          ...state.whoHiddenAnotherUser,
          loading: true,
          error: null,
          payload: null,
          cachePayload:
            state.whoHiddenAnotherUser.search === "" && state.whoHiddenAnotherUser.filter === ""
              ? null // Если поле поиска и фильтра пустое то очистить кэш
              : state.whoHiddenAnotherUser.cachePayload,
          isOnce: true,
        },
      };
    case WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_SUCCESS:
      return {
        ...state,
        whoHiddenAnotherUser: {
          ...state.whoHiddenAnotherUser,
          loading: false,
          payload: action.payload,
          cachePayload:
            state.whoHiddenAnotherUser.search === "" && state.whoHiddenAnotherUser.filter === ""
              ? action.payload // Если поле поиска и фильтра пустое то сохранить результат
              : state.whoHiddenAnotherUser.cachePayload,
        },
      };
    case WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_ERROR:
      return {
        ...state,
        whoHiddenAnotherUser: {
          ...state.whoHiddenAnotherUser,
          loading: false,
          error: action.error,
        },
      };
    case WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_SHOW_MORE:
      return {
        ...state,
        whoHiddenAnotherUser: {
          ...state.whoHiddenAnotherUser,
          showCount: parseInt(state.whoHiddenAnotherUser.showCount) + 20,
        },
      };
    case WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_SEARCH_REQUEST:
      return {
        ...state,
        whoHiddenAnotherUser: {
          ...state.whoHiddenAnotherUser,
          loading: true,
          error: null,
          payload: null,
          showCount: 20,
          search: action.search,
        },
      };
    case WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_SEARCH_SUCCESS:
      return {
        ...state,
        whoHiddenAnotherUser: {
          ...state.whoHiddenAnotherUser,
          loading: false,
          payload: [...action.payload],
        },
      };
    case WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_SEARCH_ERROR:
      return {
        ...state,
        whoHiddenAnotherUser: {
          ...state.whoHiddenAnotherUser,
          loading: false,
          error: action.error,
        },
      };
    case WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_FILTER_REQUEST:
      return {
        ...state,
        whoHiddenAnotherUser: {
          ...state.whoHiddenAnotherUser,
          payload: null,
          showCount: 20,
          loading: true,
          error: null,
          filter: action.filter,
        },
      };
    case WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_FILTER_SUCCESS:
      return {
        ...state,
        whoHiddenAnotherUser: {
          ...state.whoHiddenAnotherUser,
          loading: false,
          payload: [...action.payload],
        },
      };
    case WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_FILTER_ERROR:
      return {
        ...state,
        whoHiddenAnotherUser: {
          ...state.whoHiddenAnotherUser,
          loading: false,
          error: action.error,
        },
      };
    case WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_INFO_REQUEST:
      return {
        ...state,
        whoHiddenAnotherUserInfo: {
          ...state.whoHiddenAnotherUserInfo,
          payload: null,
          loading: true,
          error: null,
          activeUserId: action.id,
        },
      };
    case WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_INFO_SUCCESS:
      return {
        ...state,
        whoHiddenAnotherUserInfo: {
          ...state.whoHiddenAnotherUserInfo,
          payload: action.payload,
          loading: false,
        },
      };
    case WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_INFO_PROGRESS:
      return {
        ...state,
        whoHiddenAnotherUserInfo: {
          ...state.whoHiddenAnotherUserInfo,
          payload: action.payload,
          progressValue: action.progressValue,
        },
      };
    case WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_INFO_CANCEL:
      return {
        ...state,
        whoHiddenAnotherUserInfo: {
          ...state.whoHiddenAnotherUserInfo,
          loading: false,
        },
      };
    case WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_INFO_ERROR:
      return {
        ...state,
        whoHiddenAnotherUserInfo: {
          ...state.whoHiddenAnotherUserInfo,
          loading: false,
          error: action.error,
        },
      };
    case WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_REQUEST:
      return {
        ...state,
        whomHiddenAnotherUser: {
          ...state.whomHiddenAnotherUser,
          loading: true,
          error: null,
          payload: null,
          isOnce: true,
          cachePayload:
            state.whomHiddenAnotherUser.search === "" && state.whomHiddenAnotherUser.filter === ""
              ? null // Если поле поиска и фильтра пустое то очистить кэш
              : state.whomHiddenAnotherUser.cachePayload,
        },
      };
    case WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_SUCCESS:
      return {
        ...state,
        whomHiddenAnotherUser: {
          ...state.whomHiddenAnotherUser,
          loading: false,
          payload: action.payload,
          cachePayload:
            state.whomHiddenAnotherUser.search === "" && state.whomHiddenAnotherUser.filter === ""
              ? action.payload // Если поле поиска и фильтра пустое то сохранить результат
              : state.whomHiddenAnotherUser.cachePayload,
        },
      };
    case WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_INFO_CANCEL:
      return {
        ...state,
        whomHiddenAnotherUserInfo: {
          ...state.whomHiddenAnotherUserInfo,
          loading: false,
        },
      };
    case WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_ERROR:
      return {
        ...state,
        whomHiddenAnotherUser: {
          ...state.whomHiddenAnotherUser,
          loading: false,
          error: action.error,
        },
      };
    case WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_SHOW_MORE:
      return {
        ...state,
        whomHiddenAnotherUser: {
          ...state.whomHiddenAnotherUser,
          showCount: parseInt(state.whomHiddenAnotherUser.showCount) + 20,
        },
      };
    case WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_SEARCH_REQUEST:
      return {
        ...state,
        whomHiddenAnotherUser: {
          ...state.whomHiddenAnotherUser,
          loading: true,
          error: null,
          payload: null,
          showCount: 20,
          search: action.search,
        },
      };
    case WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_SEARCH_SUCCESS:
      return {
        ...state,
        whomHiddenAnotherUser: {
          ...state.whomHiddenAnotherUser,
          loading: false,
          payload: [...action.payload],
        },
      };
    case WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_SEARCH_ERROR:
      return {
        ...state,
        whomHiddenAnotherUser: {
          ...state.whomHiddenAnotherUser,
          loading: false,
          error: action.error,
        },
      };
    case WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_FILTER_REQUEST:
      return {
        ...state,
        whomHiddenAnotherUser: {
          ...state.whomHiddenAnotherUser,
          payload: null,
          showCount: 20,
          loading: true,
          error: null,
          filter: action.filter,
        },
      };
    case WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_FILTER_SUCCESS:
      return {
        ...state,
        whomHiddenAnotherUser: {
          ...state.whomHiddenAnotherUser,
          loading: false,
          payload: [...action.payload],
        },
      };
    case WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_FILTER_ERROR:
      return {
        ...state,
        whomHiddenAnotherUser: {
          ...state.whomHiddenAnotherUser,
          loading: false,
          error: action.error,
        },
      };
    case WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_INFO_REQUEST:
      return {
        ...state,
        whomHiddenAnotherUserInfo: {
          ...state.whomHiddenAnotherUserInfo,
          payload: null,
          loading: true,
          error: null,
          activeUserId: action.id,
        },
      };
    case WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_INFO_SUCCESS:
      return {
        ...state,
        whomHiddenAnotherUserInfo: {
          ...state.whomHiddenAnotherUserInfo,
          payload: action.payload,
          loading: false,
        },
      };
    case WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_INFO_PROGRESS:
      return {
        ...state,
        whomHiddenAnotherUserInfo: {
          ...state.whomHiddenAnotherUserInfo,
          payload: action.payload,
          progressValue: action.progressValue,
        },
      };
    case WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_INFO_ERROR:
      return {
        ...state,
        whomHiddenAnotherUserInfo: {
          ...state.whomHiddenAnotherUserInfo,
          loading: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}
