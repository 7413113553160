import React from "react";
import LogoIcon from "assets/img/education/moyCrushLogo.png";
import { Div, Placeholder } from "@vkontakte/vkui";
import styles from "./EducationStepWithLogo.module.scss";

const EducationStepWithLogo = (props) => {
  return (
    <div>
      <Div>
        <div className={styles.logo}>
          <img src={LogoIcon} width={164} height={26} alt=""></img>
        </div>
      </Div>
      <div className={styles.placeholderCnt}>
        <Placeholder header={props.header} icon={props.icon}>
          {props.description}
        </Placeholder>
      </div>
      <div className={styles.btnCnt}>
        <Div>{props.action}</Div>
      </div>
    </div>
  );
};

EducationStepWithLogo.defaultProps = {
  header: "",
  icon: null,
  description: "",
  action: null,
};

export default EducationStepWithLogo;
