import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Panel } from "@vkontakte/vkui";
import WhomHiddenAnotherUserList from "./components/WhomHiddenAnotherUserList/WhomHiddenAnotherUserList";
import { withRouter } from "hoc";

const PanelWHwhomHiddenAnotherUser = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Скрытые пользователи</PanelHeaderWithPath>
      <WhomHiddenAnotherUserList
        openView={props.openView}
        openModal={props.openModal}
        closeModal={props.closeModal}
      />
    </Panel>
  );
};

export default withRouter(PanelWHwhomHiddenAnotherUser);
