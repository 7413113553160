import { queryEye3 } from "api";

async function APIEye3GetFriends() {
  try {
    const response = await queryEye3({
      method: "friends.get",
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3GetFriends;
