import { useEffect } from "react";

function usePopStateBackAction({ goBack }) {
  useEffect(() => {
    let lastBackAction = 0;

    const handlePopStateBackAction = () => {
      let timeNow = +new Date();

      if (timeNow - lastBackAction > 750) {
        lastBackAction = timeNow;
        goBack();
      } else {
        window.history.pushState(null, null);
      }
    };
    window.addEventListener("popstate", handlePopStateBackAction);
    return () => {
      window.removeEventListener("popstate", handlePopStateBackAction);
    };
  }, [goBack]);
}

export default usePopStateBackAction;
