import React from "react";

import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Panel } from "@vkontakte/vkui";
import ToolBanner from "template/components/ToolBanner/ToolBanner";
import PairIcon from "assets/img/tool/pair/banner/pair.png";
import PairUserList from "./components/PUserList/PUserList";
import { withRouter } from "hoc";

const PanelPair = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Пара</PanelHeaderWithPath>
      <ToolBanner
        tool="toolPair"
        icon={PairIcon}
        text="Узнавайте кто ставил в СП выбранного пользователя"
      />
      <PairUserList
        openView={props.openView}
        openModal={props.openModal}
        closeModal={props.closeModal}
      />
    </Panel>
  );
};

export default withRouter(PanelPair);
