import { ModalRoot } from "@vkontakte/vkui";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "store/actions/page";
import {
  MODAL_CARD_DISABLE_AUTO_PAYMENT /*MODAL_CARD_MAKE_MONEY_WITH_CRUSH*/,
} from "./constModals";
import DisableAutoPaymentModal from "./DisableAutoPaymentModal/DisableAutoPaymentModal";
// import MakeMoneyWithCrushModal from "./MakeMoneyWithCrushModal/MakeMoneyWithCrushModal";

const ModalList = () => {
  const dispatch = useDispatch();
  const activeModal = useSelector((state) =>
    state.page.activeModal[state.page.activeView] === undefined
      ? null
      : state.page.activeModal[state.page.activeView]
  );
  const handleCloseModal = useCallback(() => dispatch(closeModal()), [dispatch]);
  const modalParam = useSelector((state) => state.page.modalParam);

  return (
    <ModalRoot activeModal={activeModal} onClose={handleCloseModal}>
      {/*<MakeMoneyWithCrushModal
        id={MODAL_CARD_MAKE_MONEY_WITH_CRUSH}
        handleCloseModal={handleCloseModal}
      />*/}
      <DisableAutoPaymentModal
        id={MODAL_CARD_DISABLE_AUTO_PAYMENT}
        modalParam={modalParam}
        handleCloseModal={handleCloseModal}
      />
    </ModalRoot>
  );
};

export default ModalList;
