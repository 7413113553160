import React from "react";
import PanelHeaderWitchoutPath from "template/components/PanelHeaderWitchoutPath/PanelHeaderWitchoutPath";
import { Panel } from "@vkontakte/vkui";
import { withRouter } from "hoc";
import PopularSlider from "./components/PopularSlider/PopularSlider";
import Monitoring from "./components/Monitoring/Monitoring";
import CategoryList from "./components/CategoryList/CategoryList";

const PanelMain = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWitchoutPath>Инструменты</PanelHeaderWitchoutPath>
      <PopularSlider openView={props.openView} />
      <Monitoring openView={props.openView} />
      <CategoryList openModal={props.openModal} />
    </Panel>
  );
};

export default withRouter(PanelMain);
