import { calcAge, calcLastOnlineTime, unixTimeToDate } from "helpers";
import { getSearchFilterFriendsV2 } from "helpers/actions";
import {
  WHO_NOT_ONLINE_REQUEST,
  WHO_NOT_ONLINE_SUCCESS,
  WHO_NOT_ONLINE_ERROR,
  WHO_NOT_ONLINE_SHOW_MORE,
  WHO_NOT_ONLINE_SEARCH_REQUEST,
  WHO_NOT_ONLINE_SEARCH_SUCCESS,
  WHO_NOT_ONLINE_SEARCH_ERROR,
  WHO_NOT_ONLINE_FILTER_REQUEST,
  WHO_NOT_ONLINE_FILTER_SUCCESS,
  WHO_NOT_ONLINE_FILTER_ERROR,
} from "../../constType";
import { defaultErrorProperty } from "api/eye3/responseHandler";

function getCallbackResult(usersItems) {
  let result = [];
  let date1970 = new Date(0);

  usersItems.forEach((user) => {
    const currentDate = new Date();
    const lastSeen = user.hasOwnProperty("last_seen") && user.last_seen ? user.last_seen : null;
    const lastSeenDate =
      lastSeen !== null && lastSeen.hasOwnProperty("time")
        ? unixTimeToDate(lastSeen.time)
        : date1970;

    if (!user.hasOwnProperty("deactivated") && currentDate.getDate() !== lastSeenDate.getDate()) {
      result.push({
        vk_id: user.id,
        name: user.first_name + " " + user.last_name,
        age: user.bdate ? calcAge(user.bdate) : null, // Расчет возраста по дате рождения
        sex: user.sex ? user.sex.toString() : null,
        city: user.city ? user.city.title : null, // Город
        city_id: user.city ? user.city.id.toString() : null, // Город id
        photo_url: user.photo_100 ? user.photo_100 : "", // Фото
        country: user.country ? user.country.title : null, // Страна
        country_id: user.country ? user.country.id.toString() : null, // Страна id
        last_seen: lastSeen !== null && lastSeen.hasOwnProperty("time") ? lastSeen.time : date1970,
      });
    }
  });

  result.sort((a, b) => {
    return a.last_seen - b.last_seen;
  });

  result.forEach((item) => {
    const lastSeenDate = unixTimeToDate(item.last_seen);
    item.last_seen =
      item.last_seen === date1970 ? "давно не был(а)" : calcLastOnlineTime(lastSeenDate);
  });

  return result;
}

export function fetchWhoNotOnline() {
  return async (dispatch, getState) => {
    try {
      dispatch(request());

      const state = getState().toolWhoNotOnline;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.search,
        filter: state.filter,
        cache: state.cachePayload,
        callbackResult: (usersItems) => {
          return getCallbackResult(usersItems);
        },
      });

      dispatch(success(users));
    } catch (e) {
      dispatch(
        error({
          ...defaultErrorProperty,
          message: e,
        })
      );
    }
  };

  function request() {
    return { type: WHO_NOT_ONLINE_REQUEST };
  }

  function success(payload) {
    return { type: WHO_NOT_ONLINE_SUCCESS, payload };
  }

  function error(error) {
    return { type: WHO_NOT_ONLINE_ERROR, error };
  }
}

export function showMoreWhoNotOnline() {
  return { type: WHO_NOT_ONLINE_SHOW_MORE };
}

export function filterWhoNotOnline(filter = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(request(filter));

      const state = getState().toolWhoNotOnline;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.search,
        filter: state.filter,
        cache: state.cachePayload,
        callbackResult: (usersItems) => {
          return getCallbackResult(usersItems);
        },
      });

      dispatch(success(users));
    } catch (e) {
      dispatch(
        error({
          ...defaultErrorProperty,
          message: e,
        })
      );
    }
  };

  function request(filter) {
    return { type: WHO_NOT_ONLINE_FILTER_REQUEST, filter };
  }

  function success(payload) {
    return { type: WHO_NOT_ONLINE_FILTER_SUCCESS, payload };
  }

  function error(error) {
    return { type: WHO_NOT_ONLINE_FILTER_ERROR, error };
  }
}

export function searchWhoNotOnline(search = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(request(search));

      const state = getState().toolWhoNotOnline;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.search,
        filter: state.filter,
        cache: state.cachePayload,
        callbackResult: (usersItems) => {
          return getCallbackResult(usersItems);
        },
      });

      dispatch(success(users));
    } catch (e) {
      dispatch(
        error({
          ...defaultErrorProperty,
          message: e,
        })
      );
    }
  };

  function request(search) {
    return { type: WHO_NOT_ONLINE_SEARCH_REQUEST, search };
  }

  function success(payload) {
    return { type: WHO_NOT_ONLINE_SEARCH_SUCCESS, payload };
  }

  function error(error) {
    return { type: WHO_NOT_ONLINE_SEARCH_ERROR, error };
  }
}
