import React, { useCallback, useEffect, useState } from "react";
import { Group, Placeholder, Search } from "@vkontakte/vkui";
import MartialUsersItem from "../MSUserItem/MSUserItem";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMaritalStatus,
  filterMaritalStatus,
  searchMaritalStatus,
  showMoreMaritalStatus,
} from "store/actions/tool/maritalStatus";

import Icon56UsersOutline from "@vkontakte/icons/dist/56/users_outline";
import Icon24Filter from "@vkontakte/icons/dist/24/filter";
import { MODAL_PAGE_TEMPLATE_FILTER_SEX_AGE_CITY } from "template/modals/constModals";
import { useDebounceSearch, useShowMore } from "hooks";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";

const MSUserList = (props) => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const loading = useSelector((state) => state.toolMaritalStatus.friends.loading);
  const search = useSelector((state) => state.toolMaritalStatus.friends.search);
  const friends = useSelector((state) => state.toolMaritalStatus.friends.payload);
  const filter = useSelector((state) => state.toolMaritalStatus.friends.filter);
  const isOnce = useSelector((state) => state.toolMaritalStatus.friends.isOnce);
  const error = useSelector((state) => state.toolMaritalStatus.friends.error);
  const showCount = useSelector((state) => state.toolMaritalStatus.friends.showCount);
  const [searchTerm, setSearchTerm] = useState(search);

  const handleFetchMaritalStatus = useCallback(() => dispatch(fetchMaritalStatus()), [dispatch]);
  const handleFilterMaritalStatus = useCallback(
    (filter) => dispatch(filterMaritalStatus(filter)),
    [dispatch]
  );
  const handleSearchMaritalStatus = useCallback(
    (search) => dispatch(searchMaritalStatus(search)),
    [dispatch]
  );
  const handleShowMoreMaritalStatus = useCallback(
    () => dispatch(showMoreMaritalStatus()),
    [dispatch]
  );
  //const handleSetSaveRelationSettings = useCallback(() => dispatch(setSaveRelationSetting()), [dispatch]);

  useShowMore({
    items: friends,
    showCount: showCount,
    maxLength: friends !== null ? friends.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMoreMaritalStatus,
  });

  useEffect(() => {
    if (!isOnce) handleFetchMaritalStatus();
  }, [handleFetchMaritalStatus, isOnce]);

  useDebounceSearch({
    search: search,
    searchTerm: searchTerm,
    handler: handleSearchMaritalStatus,
  });

  const renderMaritalList = () => {
    return friends.length > 0 ? (
      friends.map((friend, i) => {
        const id = friend.vk_id;
        const name = friend.name;
        const photo = friend.photo_url ? friend.photo_url : "";
        const sex = friend.sex ? friend.sex : "";
        const relation = friend.relation;

        if (i < showCount) {
          return (
            <MartialUsersItem
              title={name}
              id={id}
              sex={sex}
              key={i}
              photo={photo}
              relation={relation}
              openView={props.openView}
            />
          );
        }

        return false;
      })
    ) : (
      <Placeholder icon={<Icon56UsersOutline />}>Друзья не найдены</Placeholder>
    );
  };

  const handleFilter = () => {
    if (loading) return;

    props.openModal({
      id: MODAL_PAGE_TEMPLATE_FILTER_SEX_AGE_CITY,
      param: {
        filter: filter,
        closeModal: props.closeModal,
        applyFilter: handleFilterMaritalStatus,
      },
    });
  };

  return (
    <>
      <Search
        value={searchTerm}
        icon={<Icon24Filter />}
        placeholder="Имя или ссылка на страницу"
        onIconClick={handleFilter}
        onChange={(e) => {
          if (loading) return;

          setSearchTerm(e.target.value);
        }}
      />
      <Group style={{ marginBottom: "20px" }}>
        {friends !== null && error === null ? renderMaritalList() : ""}
      </Group>
      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchMaritalStatus,
      })}
    </>
  );
};

export default MSUserList;
