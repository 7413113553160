import React, { useEffect, useCallback } from "react";
import { Div, Panel } from "@vkontakte/vkui";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { useDispatch, useSelector } from "react-redux";
import { fetchHoroscope } from "store/actions/profile/horoscope";

import HoroscopeSignItem from "./components/HoroscopeSignItem/HoroscopeSignItem";
import HoroscopeСompatibility from "./components/HoroscopeСompatibility/HoroscopeСompatibility";

import styles from "./horoscope.module.css";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import { withRouter } from "hoc";

const PanelHoroscope = (props) => {
  const dispatch = useDispatch();
  const horoscope = useSelector((state) =>
    state.profileHoroscope.payload ? state.profileHoroscope.payload : null
  );
  const loading = useSelector((state) => state.profileHoroscope.loading);
  const error = useSelector((state) => state.profileHoroscope.error);

  const percent = horoscope !== null ? horoscope.horoscope_percent : 0;
  const about = horoscope !== null ? horoscope.about : {};

  const self = horoscope !== null ? horoscope.self : null;
  const selfFirstName = self !== null ? self.first_name : "";
  const selfHoroscope = self !== null ? self.horoscope : "";
  const selfSex = self !== null ? self.sex : "";

  const target = horoscope !== null ? horoscope.target : null;
  const targetFirstName = target !== null ? target.first_name : "";
  const targetHoroscope = target !== null ? target.horoscope : "";
  const targetSex = target !== null ? target.sex : "";

  const handleFetchHoroscope = useCallback(() => dispatch(fetchHoroscope()), [dispatch]);

  useEffect(() => {
    handleFetchHoroscope();
  }, [handleFetchHoroscope]);

  const renderHoroscope = () => {
    return (
      <div className={styles.bg}>
        <Div>
          <div className={styles.container}>
            <HoroscopeSignItem title={selfFirstName} sign={selfHoroscope} sex={selfSex} />
            <HoroscopeСompatibility sum={percent} />
            <HoroscopeSignItem title={targetFirstName} sign={targetHoroscope} sex={targetSex} />
          </div>
          {Object.keys(about).length !== 0 ? (
            <div className={styles.about}>
              <h2 className={styles.title}>1. {about["title_1"]}</h2>
              <p className={styles.desc}>{about["about_1"]}</p>
              <h2 className={styles.title}>2. {about["title_2"]}</h2>
              <p className={styles.desc}>{about["about_2"]}</p>
              <h2 className={styles.title}>3. {about["title_3"]}</h2>
              <p className={styles.desc}>{about["about_3"]}</p>
              <h2 className={styles.title}>4. {about["title_4"]}</h2>
              <p className={styles.desc}>{about["about_4"]}</p>
              <h2 className={styles.title}>5. {about["title_5"]}</h2>
              <p className={styles.desc}>{about["about_5"]}</p>
            </div>
          ) : (
            ""
          )}
        </Div>
      </div>
    );
  };

  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Гороскоп</PanelHeaderWithPath>
      {horoscope !== null ? renderHoroscope() : ""}
      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchHoroscope,
      })}
    </Panel>
  );
};

export default withRouter(PanelHoroscope);
