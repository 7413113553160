import React from "react";

import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Panel } from "@vkontakte/vkui";
import MSUserList from "./components/MSUserList/MSUserList";
import MSGeo from "./components/MSGeo/MSGeo";
import MaritalIcon from "assets/img/tool/maritalStatus/banner/marital.png";
import ToolBanner from "template/components/ToolBanner/ToolBanner";
import { withRouter } from "hoc";

const PanelMaritalStatus = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Семейное положение</PanelHeaderWithPath>
      <ToolBanner
        tool="toolMaritalStatus"
        icon={MaritalIcon}
        text="Узнавайте о смене семейного положения пользователей VK"
      />
      <MSGeo openView={props.openView} />
      <MSUserList
        openView={props.openView}
        openModal={props.openModal}
        closeModal={props.closeModal}
      />
    </Panel>
  );
};

export default withRouter(PanelMaritalStatus);
