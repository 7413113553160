import { Button, Div, Group, Header } from "@vkontakte/vkui";
import React from "react";
import { VIEW_TOOL } from "views/constViews";
import { PANEL_HS__INFO_TOP_SPECTATOR } from "views/tool/panels/constPanels";
import TopItem from "../TopItem/TopItem";

const TopList = (props) => {
  const renderTop = () => {
    return props.items.map((item, i) => {
      const id = item.vk_id;
      const title = item.name;
      const photo = item.photo_url;
      const value = item[props.type];

      if (i < 3 && value > 0) {
        return (
          <TopItem
            key={i}
            id={id}
            title={title}
            photo={photo}
            type={props.type}
            value={value}
            openView={props.openView}
          />
        );
      }

      return false;
    });
  };

  return (
    <Group header={<Header mode="secondary">{props.header}</Header>} separator="hide">
      {props.items !== null && renderTop()}
      {props.items !== null && (
        <Div>
          <Button
            mode="secondary"
            size="l"
            style={{ width: "100%" }}
            onClick={() =>
              props.openView({
                view: VIEW_TOOL,
                panel: PANEL_HS__INFO_TOP_SPECTATOR,
                param: {
                  activeHeader: props.header,
                  activeTypeSpectator: props.type,
                  activeItems: props.items,
                },
              })
            }
          >
            Смотреть всех
          </Button>
        </Div>
      )}
    </Group>
  );
};

TopList.defaultProps = {
  header: "",
  type: "",
  items: null,
};

export default TopList;
