import React from "react";
import { ModalCard } from "@vkontakte/vkui";
import Question from "assets/img/myProfile/modal/question.png";
import { useCallback } from "react";
import { setLicenseRenew } from "store/actions/myProfile";
import { useDispatch } from "react-redux";

const DisableAutoPaymentModal = (props) => {
  const dispatch = useDispatch();
  const handleSetLicenseRenew = useCallback(
    (id, isRenew) => {
      dispatch(setLicenseRenew(id, isRenew));
    },
    [dispatch]
  );

  const tariffId = props.modalParam ? props.modalParam.tariffId : null;

  return (
    <ModalCard
      id={props.id}
      header="Отключить автопродление?"
      icon={<img width={50} height={50} src={Question} alt=""></img>}
      caption="Вы уверены что хотите отключить автопродление тарифа?"
      onClose={props.handleCloseModal}
      actions={[
        {
          title: "Отключить",
          mode: "secondary",
          action: () => {
            handleSetLicenseRenew(tariffId, false);
            props.handleCloseModal();
          },
        },
        {
          title: "Не отключать",
          mode: "primary",
          action: props.handleCloseModal,
        },
      ]}
    />
  );
};

export default DisableAutoPaymentModal;
