import {
  SHOP_BALANCE_REPLENISHMENT_CREATE_PAYMENT_REQUEST,
  SHOP_BALANCE_REPLENISHMENT_CREATE_PAYMENT_SUCCESS,
  SHOP_BALANCE_REPLENISHMENT_CREATE_PAYMENT_ERROR,
  SHOP_BALANCE_REPLENISHMENT_CREATE_UNITPAY_PAYMENT_REQUEST,
  SHOP_BALANCE_REPLENISHMENT_CREATE_UNITPAY_PAYMENT_SUCCESS,
  SHOP_BALANCE_REPLENISHMENT_CREATE_UNITPAY_PAYMENT_ERROR,
  SHOP_BALANCE_REPLENISHMENT_SAVE_PAYMENT,
  SHOP_CONNECT_TARIFF_REQUEST,
  SHOP_CONNECT_TARIFF_SUCCESS,
  SHOP_CONNECT_TARIFF_ERROR,
  SHOP_SAVE_TARIFF,
  SHOP_BALANCE_CHECKED_PAYMENT_REQUEST,
  SHOP_BALANCE_CHECKED_PAYMENT_PROGRESS,
  SHOP_BALANCE_CHECKED_PAYMENT_SUCCESS,
  SHOP_BALANCE_CHECKED_PAYMENT_ERROR,
  SHOP_BALANCE_CHECKED_PAYMENT_CANCEL,
  SHOP_BALANCE_HISTORY_REQUEST,
  SHOP_BALANCE_HISTORY_SUCCESS,
  SHOP_BALANCE_HISTORY_ERROR,
  SHOP_BALANCE_HISTORY_LOAD_MORE_REQUEST,
  SHOP_BALANCE_HISTORY_LOAD_MORE_SUCCESS,
  SHOP_BALANCE_HISTORY_LOAD_MORE_ERROR,
} from "../constType";

const initialState = {
  // Пополнение баланса
  balanceReplenishment: {
    payload: null,
    loading: false,
    error: null,
  },
  // История баланса
  balanceHistory: {
    payload: null,
    loading: false,
    error: null,
    maxLength: 0,
    count: 50,
    offset: 0,
  },
  // Подключение тарифа
  tariffConnect: {
    payload: null,
    loading: false,
    error: null,
  },
  // Сохранение текущего платежа
  payment: {
    paymentType: null,
    count: null,
    email: null,
  },
  // Сохранение текущего подключаемого тарифа
  tariff: {
    dayCount: null,
    tariffCaption: null,
    tariffType: null,
    autopayment: null,
  },
  // Проверка платежа
  checkedPayment: {
    payload: null,
    isCheck: false,
    loading: false,
    error: null,
  },
};

export default function shopReducer(state = initialState, action) {
  switch (action.type) {
    case SHOP_BALANCE_CHECKED_PAYMENT_REQUEST:
      return {
        ...state,
        checkedPayment: {
          ...state.checkedPayment,
          payload: null,
          loading: true,
          error: null,
        },
      };
    case SHOP_BALANCE_CHECKED_PAYMENT_PROGRESS:
      return {
        ...state,
        checkedPayment: {
          ...state.checkedPayment,
          payload: action.payload,
        },
      };
    case SHOP_BALANCE_CHECKED_PAYMENT_SUCCESS:
      return {
        ...state,
        checkedPayment: {
          ...state.checkedPayment,
          loading: false,
        },
      };
    case SHOP_BALANCE_CHECKED_PAYMENT_ERROR:
      return {
        ...state,
        checkedPayment: {
          ...state.checkedPayment,
          loading: false,
          error: action.error,
        },
      };
    case SHOP_BALANCE_CHECKED_PAYMENT_CANCEL:
      return {
        ...state,
        checkedPayment: {
          loading: false,
        },
      };
    case SHOP_BALANCE_REPLENISHMENT_CREATE_PAYMENT_REQUEST:
      return {
        ...state,
        balanceReplenishment: {
          ...state.balanceReplenishment,
          payload: null,
          loading: true,
          error: null,
        },
      };
    case SHOP_BALANCE_REPLENISHMENT_CREATE_PAYMENT_SUCCESS:
      return {
        ...state,
        balanceReplenishment: {
          ...state.balanceReplenishment,
          payload: action.payload,
          loading: false,
        },
      };
    case SHOP_BALANCE_REPLENISHMENT_CREATE_PAYMENT_ERROR:
      return {
        ...state,
        balanceReplenishment: {
          ...state.balanceReplenishment,
          loading: false,
          error: action.error,
        },
      };
    case SHOP_BALANCE_REPLENISHMENT_CREATE_UNITPAY_PAYMENT_REQUEST:
      return {
        ...state,
        balanceReplenishment: {
          ...state.balanceReplenishment,
          payload: null,
          loading: true,
          error: null,
        },
      };
    case SHOP_BALANCE_REPLENISHMENT_CREATE_UNITPAY_PAYMENT_SUCCESS:
      return {
        ...state,
        balanceReplenishment: {
          ...state.balanceReplenishment,
          payload: action.payload,
          loading: false,
        },
      };
    case SHOP_BALANCE_REPLENISHMENT_CREATE_UNITPAY_PAYMENT_ERROR:
      return {
        ...state,
        balanceReplenishment: {
          ...state.balanceReplenishment,
          loading: false,
          error: action.error,
        },
      };
    case SHOP_BALANCE_REPLENISHMENT_SAVE_PAYMENT:
      return {
        ...state,
        payment: {
          ...state.payment,
          email: action.email,
          count: action.count,
          paymentType: action.paymentType,
        },
      };
    case SHOP_CONNECT_TARIFF_REQUEST:
      return {
        ...state,
        tariffConnect: {
          ...state.tariffConnect,
          loading: true,
          payload: null,
        },
      };
    case SHOP_CONNECT_TARIFF_SUCCESS:
      return {
        ...state,
        tariffConnect: {
          ...state.tariffConnect,
          loading: false,
          payload: action.payload,
        },
      };
    case SHOP_CONNECT_TARIFF_ERROR:
      return {
        ...state,
        tariffConnect: {
          ...state.tariffConnect,
          loading: false,
          error: action.error,
        },
      };
    case SHOP_SAVE_TARIFF:
      return {
        ...state,
        tariff: {
          ...state.tariff,
          tariffCaption: action.tariffCaption,
          dayCount: action.dayCount,
          tariffType: action.tariffType,
          autopayment: action.autopayment,
        },
      };
    case SHOP_BALANCE_HISTORY_REQUEST:
      return {
        ...state,
        balanceHistory: {
          ...state.balanceHistory,
          offset: 0,
          maxLength: 0,
          payload: null,
          loading: true,
          error: null,
        },
      };
    case SHOP_BALANCE_HISTORY_SUCCESS:
      return {
        ...state,
        balanceHistory: {
          ...state.balanceHistory,
          payload: action.payload,
          maxLength: action.maxLength,
          loading: false,
        },
      };
    case SHOP_BALANCE_HISTORY_ERROR:
      return {
        ...state,
        balanceHistory: {
          ...state.balanceHistory,
          loading: false,
          error: action.error,
        },
      };
    case SHOP_BALANCE_HISTORY_LOAD_MORE_REQUEST:
      return {
        ...state,
        balanceHistory: {
          ...state.balanceHistory,
          loading: true,
          offset:
            state.balanceHistory.offset === 0
              ? (state.balanceHistory.offset = state.balanceHistory.count + 1)
              : (state.balanceHistory.offset += 50), // Подгрузка
        },
      };
    case SHOP_BALANCE_HISTORY_LOAD_MORE_SUCCESS:
      return {
        ...state,
        balanceHistory: {
          ...state.balanceHistory,
          loading: false,
          payload: [...state.balanceHistory.payload, ...action.payload],
        },
      };
    case SHOP_BALANCE_HISTORY_LOAD_MORE_ERROR:
      return {
        ...state,
        balanceHistory: {
          ...state.balanceHistory,
          loading: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}
