import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Placeholder, SimpleCell } from "@vkontakte/vkui";
import Icon56UserAddOutline from "@vkontakte/icons/dist/56/user_add_outline";
import SeparateFilter from "template/components/SeparateFilter/SeparateFilter";
import FriendHistoryItem from "../FriendHistoryItem/FriendHistoryItem";

import { unixTimeToDate, convertMonthDay, convertTime } from "helpers";

import { fetchFriendHistory, showMoreFriendHistory } from "store/actions/profile/friendHistory";
import { declOfNum } from "helpers";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import { useShowMore } from "hooks";
import HeadTitle from "template/components/HeadTitle/HeadTitle";

const FriendHistoryList = () => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const profile = useSelector((state) => (state.profile.payload ? state.profile.payload : null));

  const loading = useSelector((state) => state.profileFriendHistory.loading);
  const error = useSelector((state) => state.profileFriendHistory.error);
  const friendHistory = useSelector((state) => state.profileFriendHistory.payload);
  const showCount = useSelector((state) => state.profileFriendHistory.showCount);

  const handleFetchFriendHistory = useCallback(() => dispatch(fetchFriendHistory()), [dispatch]);
  const handleShowMoreFriendHistory = useCallback(
    () => dispatch(showMoreFriendHistory()),
    [dispatch]
  );

  let lastAt = new Date();

  useEffect(() => {
    handleFetchFriendHistory();
  }, [handleFetchFriendHistory]);

  useShowMore({
    items: friendHistory,
    showCount: showCount,
    maxLength: friendHistory !== null ? friendHistory.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMoreFriendHistory,
  });

  const renderTopPanel = () => {
    const user = profile.user;
    const name = user.name;
    const city = user.city ? user.city : "";
    const age = user.age ? user.age : "";
    const photo = user.photo_url ? user.photo_url : "";
    const ageStrFormat = age ? age + " " + declOfNum(age, ["год", "года", "лет"]) : "";
    const comma = ageStrFormat && city ? ", " : "";

    return (
      <SimpleCell
        disabled
        before={<Avatar size={72} src={photo} />}
        description={ageStrFormat + comma + city}
      >
        {name}
      </SimpleCell>
    );
  };

  const renderFriendHistory = () => {
    return friendHistory.length > 0 ? (
      friendHistory.map((friend, i) => {
        const user = profile.user;
        const sex = user.sex;

        const id = friend.vk_id;
        const name = friend.name;
        const type = friend.friend_type;
        const status = friend.status;
        const photo = friend.photo_url;
        const at = unixTimeToDate(friend._at);

        let separate = <SeparateFilter>{convertMonthDay(at)}</SeparateFilter>;

        if (lastAt.getDate() === at.getDate() && i !== 0) {
          separate = "";
        }

        lastAt = at;

        if (i < showCount) {
          return (
            <div key={i}>
              {separate}
              <FriendHistoryItem
                key={i}
                time={convertTime(at)}
                id={id}
                title={name}
                type={type}
                status={status}
                photo={photo}
                sex={sex}
              />
            </div>
          );
        }

        return false;
      })
    ) : (
      <Placeholder icon={<Icon56UserAddOutline />}>Добавленные друзья не найдены</Placeholder>
    );
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      <HeadTitle>Добавление/удаление друзей</HeadTitle>
      {renderTopPanel()}
      {friendHistory !== null && error === null ? renderFriendHistory() : ""}
      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchFriendHistory,
      })}
    </div>
  );
};

export default FriendHistoryList;
