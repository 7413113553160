import React from "react";
import { ModalCard } from "@vkontakte/vkui";
import HoroscopeIcon from "assets/img/profile/modal/horoscopeDateBirth.png";

const HoroscopeDateBirthModal = (props) => {
  const message = props.modalParam ? props.modalParam.message : "";

  return (
    <ModalCard
      id={props.id}
      header="Звёзды не сошлись :("
      icon={<img src={HoroscopeIcon} style={{ width: "50px", height: "50px" }} alt=""></img>}
      caption={message}
      onClose={props.handleCloseModal}
      actions={[
        {
          title: "Назад",
          mode: "primary",
          action: props.handleCloseModal,
        },
      ]}
    />
  );
};

export default HoroscopeDateBirthModal;
