import React from "react";
import { Avatar, SimpleCell } from "@vkontakte/vkui";
import Icon16Chevron from "@vkontakte/icons/dist/16/chevron";
import { VIEW_TOOL } from "views/constViews";
import { PANEL_PROFILE } from "views/profile/panels/constPanels";

import {
  MARITAL_STATUS_NOT_MARRIED,
  MARITAL_STATUS_HAVE_FRIEND,
  MARITAL_STATUS_ENGAGED,
  MARITAL_STATUS_MARRIED,
  MARITAL_STATUS_COMPLICATED,
  MARITAL_STATUS_ACTIVELY_LOOKING,
  MARITAL_STATUS_LOVE,
  MARITAL_CIVIL_MARRIAGE,
} from "../../constMaritalStatus";

const PInfoItem = (props) => {
  const getTypeText = () => {
    switch (props.relation) {
      case MARITAL_STATUS_NOT_MARRIED:
        return props.sex === "2" ? "не женат" : "не замужем";
      case MARITAL_STATUS_HAVE_FRIEND:
        return props.sex === "2"
          ? "встречается с " + props.selfTitle
          : "встречается с " + props.selfTitle;
      case MARITAL_STATUS_ENGAGED:
        return props.sex === "2"
          ? "помолвлен с " + props.selfTitle
          : "помолвлена с " + props.selfTitle;
      case MARITAL_STATUS_MARRIED:
        return props.sex === "2" ? "женат на " + props.selfTitle : "замужем за " + props.selfTitle;
      case MARITAL_STATUS_COMPLICATED:
        return "всё сложно";
      case MARITAL_STATUS_ACTIVELY_LOOKING:
        return "в активном поиске";
      case MARITAL_STATUS_LOVE:
        return props.sex === "2" ? "влюблён в " + props.selfTitle : "влюблена в " + props.selfTitle;
      case MARITAL_CIVIL_MARRIAGE:
        return "в гражданском браке с " + props.selfTitle;
      default:
        return "";
    }
  };

  return (
    <SimpleCell
      before={<Avatar size={48} src={props.photo} />}
      after={<Icon16Chevron fill="#B8C1CC" />}
      onClick={() =>
        props.openView({
          view: VIEW_TOOL,
          panel: PANEL_PROFILE,
          param: { activeProfileId: props.id },
        })
      }
      description={getTypeText()}
    >
      {props.title}
    </SimpleCell>
  );
};

export default PInfoItem;
