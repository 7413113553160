import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import WLFCommonAudioWithList from "./WLFCommonAudioWithList/WLFCommonAudioWithList";
import { Panel } from "@vkontakte/vkui";
import { withRouter } from "hoc";

const PanelWLFCommonAudioWith = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Что слушают друзья</PanelHeaderWithPath>
      <WLFCommonAudioWithList
        openView={props.openView}
        openModal={props.openModal}
        closeModal={props.closeModal}
      />
    </Panel>
  );
};

export default withRouter(PanelWLFCommonAudioWith);
