import {
  PROFILE_ALL_LIKE_REQUEST,
  PROFILE_ALL_LIKE_SUCCESS,
  PROFILE_ALL_LIKE_ERROR,
  PROFILE_ALL_LIKE_PROGRESS,
  PROFILE_ALL_LIKE_CANCEL,
  PROFILE_ALL_LIKE_SHOW_MORE,
} from "../../constType";

const initialState = {
  activeUserId: null,
  payload: null,
  loading: false,
  isStopLoading: false,
  progressValue: 0,
  showCount: 20,
  error: null,
};

export default function allLikeReducer(state = initialState, action) {
  switch (action.type) {
    case PROFILE_ALL_LIKE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        payload: null,
        isStopLoading: false,
        activeUserId: action.id,
        showCount: 20,
        progressValue: 0,
      };
    case PROFILE_ALL_LIKE_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: action.payload,
      };
    case PROFILE_ALL_LIKE_PROGRESS:
      return {
        ...state,
        progressValue: action.progressValue,
        payload: action.payload,
      };
    case PROFILE_ALL_LIKE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case PROFILE_ALL_LIKE_CANCEL:
      return {
        ...state,
        loading: false,
        isStopLoading: state.loading ? true : false,
      };
    case PROFILE_ALL_LIKE_SHOW_MORE:
      return {
        ...state,
        showCount: parseInt(state.showCount) + 20,
      };
    default:
      return state;
  }
}
