import { isEye3VkMethodError } from "api";
import { APIEye3VkApiUser } from "api/eye3";

async function APIVkGetByIdGroups({ groupIds, fields = null }) {
  const method = "groups.getById";
  try {
    /*const response = await bridge.send("VKWebAppCallAPIMethod", {
      method: method,
      params: {
        group_ids: groupIds,
        fields: fields,
        v: getVKApiVersion(),
        access_token: getVKToken(),
      },
    });*/

    //responseHandler(method, response);

    const groupsAPI = await APIEye3VkApiUser({
      method: method,
      params: {
        group_ids: groupIds,
        fields: fields,
      },
    });

    if (isEye3VkMethodError(groupsAPI)) {
      throw JSON.stringify(groupsAPI);
    }

    console.log("groupsAPI: ", groupsAPI);

    return groupsAPI.result;
  } catch (e) {
    //errorHandler(method, e);
    throw e;
  }
}

export default APIVkGetByIdGroups;
