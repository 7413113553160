import React from "react";
import { ModalCard } from "@vkontakte/vkui";
import HeartIcon from "assets/img/moycrush/modal/heart.png";

const CrushPostModal = (props) => {
  return (
    <ModalCard
      id={props.id}
      header="Твоих крашей ещё нет в приложении"
      icon={<img src={HeartIcon} style={{ width: "50px", height: "50px" }} alt=""></img>}
      caption="Размести пост на своей странице, чтобы узнать, кто из них взаимный"
      actions={[
        {
          title: "Разместить пост",
          mode: "primary",
        },
      ]}
    />
  );
};

export default CrushPostModal;
