import { APIEye3PopularGroupsTool } from "api/eye3";
import { getFriendsComparePopular } from "helpers/actions";
import { checkEYE3ApiError, defaultErrorProperty } from "api/eye3/responseHandler";
import {
  POPULAR_COMMUNITY_REQUEST,
  POPULAR_COMMUNITY_SUCCESS,
  POPULAR_COMMUNITY_ERROR,
  POPULAR_COMMUNITY_CANCEL,
  POPULAR_COMMUNITY_SHOW_MORE,
} from "../../constType";
import { delayMethodGetByIdGroupsVKApi } from "api";
import { openModal } from "../page";

async function getPopularCommunity(dispatch, getState) {
  let result = [];
  let prepareResult = [];

  const popularCommunity = await APIEye3PopularGroupsTool();
  // console.log("popularCommunity: ", popularCommunity);

  // Проверка ошибок в API
  const errorAPI = checkEYE3ApiError(popularCommunity);
  if (errorAPI !== false) {
    errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
    dispatch(error({ ...errorAPI }));
    return result;
  }

  if (!popularCommunity.status) return result;

  const popularItems = popularCommunity.items.length !== 0 ? popularCommunity.items : [];

  if (popularItems.length === 0) return result;

  const allGroupsById = await delayMethodGetByIdGroupsVKApi({
    groupIds: popularItems.map((item) => item.id),
  });

  const allGroupsByIdItems = allGroupsById;

  if (allGroupsByIdItems.length === 0) return result;

  //console.log("allGroupsById: ", allGroupsByIdItems);

  for (let popularItem of popularItems) {
    for (let groupItem of allGroupsByIdItems) {
      if (popularItem.id === groupItem.id) {
        prepareResult.push({
          vk_id: popularItem.id,
          common_users: popularItem.common_users,
          common_count: popularItem.common_count,
          name: groupItem.name,
          photo_url: groupItem.photo_100,
        });

        break;
      }
    }
  }

  //console.log("prepareResult: ", prepareResult);

  const friendsPopular = await getFriendsComparePopular({
    friends: getState().friend.payload,
    fields: ["photo_100", "bdate"],
  });

  const friendsPopularItems =
    friendsPopular.response.items.length !== 0 ? friendsPopular.response.items : [];

  for (let prepareItem of prepareResult) {
    let commonUsers = [];
    const userIds = prepareItem.common_users;

    for (let i = 0; i < userIds.length; i++) {
      for (let j = 0; j < friendsPopularItems.length; j++) {
        if (friendsPopularItems[j].id === userIds[i]) {
          commonUsers.push({
            vk_id: friendsPopularItems[j].id,
            name: friendsPopularItems[j].first_name + " " + friendsPopularItems[j].last_name,
            photo_url: friendsPopularItems[j].photo_100,
          });

          break;
        }
      }
    }

    result.push({
      ...prepareItem,
      common_users: commonUsers,
    });
  }

  //console.log("result: ", result);
  return result;

  function error(error) {
    return { type: POPULAR_COMMUNITY_ERROR, error };
  }
}

export function fetchPopularCommunity() {
  return async (dispatch, getState) => {
    try {
      dispatch(request());

      const result = await getPopularCommunity(dispatch, getState);

      dispatch(success(result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: POPULAR_COMMUNITY_REQUEST };
  }

  function success(payload) {
    return { type: POPULAR_COMMUNITY_SUCCESS, payload };
  }

  function error(error) {
    return { type: POPULAR_COMMUNITY_ERROR, error };
  }
}

export function showMorePopularCommunity() {
  return { type: POPULAR_COMMUNITY_SHOW_MORE };
}

export function cancelPopularCommunity() {
  return { type: POPULAR_COMMUNITY_CANCEL };
}
