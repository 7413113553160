import React from "react";
import { SimpleCell } from "@vkontakte/vkui";
import { Icon28GlobeOutline } from "@vkontakte/icons";
import { PANEL_MS__GEO } from "../../../constPanels";
import Icon16Chevron from "@vkontakte/icons/dist/16/chevron";
import { VIEW_TOOL } from "views/constViews";

const MSGeo = (props) => {
  return (
    <SimpleCell
      multiline
      before={<Icon28GlobeOutline />}
      description="Поиск пользователей, сменивших семейное положение в указанном городе за определенный период времени"
      onClick={() => props.openView({ view: VIEW_TOOL, panel: PANEL_MS__GEO })}
      after={<Icon16Chevron fill="#B8C1CC" />}
    >
      Семейное положение по гео
    </SimpleCell>
  );
};

export default MSGeo;
