import React from "react";
import { Avatar } from "@vkontakte/vkui";
import { Icon24ClockOutline } from "@vkontakte/icons";
import styles from "./TOCompareTimeInfoTopPanel.module.css";

const TOCompareTimeInfoTopPanel = (props) => {
  const compareUsers = props.compareUsers ? props.compareUsers : null;
  const oneUser = compareUsers ? compareUsers.one : null;
  const oneUserTitle = oneUser ? oneUser.title : "";
  const oneUserPhoto = oneUser ? oneUser.photo : "";
  const twoUser = compareUsers ? compareUsers.two : null;
  const twoUserTitle = twoUser ? twoUser.title : "";
  const twoUserPhoto = twoUser ? twoUser.photo : "";

  return (
    <div className={styles.topContainer}>
      <div className={styles.clockWrapper}>
        <Avatar size={72} src={oneUserPhoto} />
        <div className={styles.clockTitle}>{oneUserTitle}</div>
      </div>
      <div className={styles.clockWrapper}>
        <div className={styles.clockContainer}>
          <Icon24ClockOutline width={32} height={32} fill="#31A8FF" />
        </div>
        <div className={styles.clockTitle}>Общее время</div>
      </div>
      <div className={styles.clockWrapper}>
        <Avatar size={72} src={twoUserPhoto} />
        <div className={styles.clockTitle}>{twoUserTitle}</div>
      </div>
    </div>
  );
};

export default TOCompareTimeInfoTopPanel;
