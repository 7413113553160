import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import TOTrackingUserInfoList from "./components/TOTrackingUserInfoList/TOTrackingUserInfoList";
import { Panel } from "@vkontakte/vkui";
import { withRouter } from "hoc";

const PanelTOTrackingUserInfo = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Время онлайн</PanelHeaderWithPath>
      <TOTrackingUserInfoList
        openView={props.openView}
        openPopout={props.openPopout}
        closePopout={props.closePopout}
      />
    </Panel>
  );
};

export default withRouter(PanelTOTrackingUserInfo);
