import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View } from "@vkontakte/vkui";

import "./themes.scss";

import {
  PANEL_MAIN,
  PANEL_AFFILIATE_REFERER_HISTORY,
  PANEL_AFFILIATE_WITHDRAW_TO_COIN,
  PANEL_AFFILIATE_WITHDRAW_TO_MONEY,
} from "./panels/constPanels";
import {
  PANEL_TEMPLATE_PRIVACY_POLICY,
  PANEL_TEMPLATE_USER_AGREEMENT,
} from "template/panels/constPanels";

import { onTransitionPage } from "store/actions/page";

import PanelMain from "./panels/main";
import PanelAffiliateRefererHistory from "./panels/affiliateRefererHistory";
import PanelAffiliateWithdrawToCoin from "./panels/affiliateWithdrawToCoin";
import PanelAffiliateWithdrawToMoney from "./panels/affiliateWithdrawToMoney";

import PanelTemplatePrivacyPolicy from "template/panels/PrivacyPolicy/PrivacyPolicy";
import PanelTemplateUserAgreement from "template/panels/UserAgreement/UserAgreement";
import ModalList from "./modals";

const MyProfileView = (props) => {
  const dispatch = useDispatch();
  const activePanel = useSelector((state) => state.page.activePanel);
  const history = useSelector((state) =>
    state.page.panelsHistory[state.page.activeView] === undefined
      ? [state.page.activeView]
      : state.page.panelsHistory[state.page.activeView]
  );

  const handleOnTransitionPage = useCallback(
    (param) => dispatch(onTransitionPage(param)),
    [dispatch]
  );

  return (
    <View
      id={props.id}
      history={history}
      onTransition={handleOnTransitionPage}
      activePanel={activePanel}
      modal={<ModalList />}
    >
      <PanelMain id={PANEL_MAIN} />
      <PanelAffiliateRefererHistory id={PANEL_AFFILIATE_REFERER_HISTORY} />
      <PanelAffiliateWithdrawToCoin id={PANEL_AFFILIATE_WITHDRAW_TO_COIN} />
      <PanelAffiliateWithdrawToMoney id={PANEL_AFFILIATE_WITHDRAW_TO_MONEY} />
      <PanelTemplatePrivacyPolicy id={PANEL_TEMPLATE_PRIVACY_POLICY} />
      <PanelTemplateUserAgreement id={PANEL_TEMPLATE_USER_AGREEMENT} />
    </View>
  );
};

export default MyProfileView;
