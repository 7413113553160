import { Icon16Done } from "@vkontakte/icons";
import { Avatar, Snackbar } from "@vkontakte/vkui";
import { VkBridgeSend } from "api/vk";
import React from "react";

const SnakbarCopy = (props) => {
  const handleCopy = () => {
    VkBridgeSend("VKWebAppCopyText", { text: props.link }).then(() => {
      openBaseWithAction();
    });
  };

  const openBaseWithAction = () => {
    if (props.copy !== null) return;
    props.setCopy(
      <Snackbar
        layout="vertical"
        before={
          <Avatar
            size={24}
            style={{
              backgroundColor: "var(--accent)",
            }}
          >
            <Icon16Done fill="#fff" width={14} height={14} />
          </Avatar>
        }
        onClose={() => props.setCopy(null)}
      >
        {props.message}
      </Snackbar>
    );
  };

  return (
    <>
      {React.cloneElement(props.children, { onClick: handleCopy })}
      {props.copy}
    </>
  );
};

SnakbarCopy.defaultProps = {
  copy: null,
  setCopy: null,
  link: null,
  message: "Ссылка скопирована",
};

export default SnakbarCopy;
