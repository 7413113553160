import React, { useCallback, useState } from "react";
import { Group, Search, Placeholder } from "@vkontakte/vkui";
import Icon24Filter from "@vkontakte/icons/dist/24/filter";
import CFUserItem from "../CFUserItem/CFUserItem";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCommonFriends,
  filterCommonFriends,
  searchCommonFriends,
  showMoreCommonFriends,
  cancelCommonFriends,
} from "store/actions/tool/commonFriends";
import { useDebounceSearch, useShowMore } from "hooks";
import { declOfNum } from "helpers";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import { MODAL_PAGE_TEMPLATE_FILTER_SEX_AGE_CITY } from "template/modals/constModals";
import Icon56UsersOutline from "@vkontakte/icons/dist/56/users_outline";

const CFUserList = (props) => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const search = useSelector((state) => state.toolCommonFriends.search);
  const filter = useSelector((state) => state.toolCommonFriends.filter);
  const showCount = useSelector((state) => state.toolCommonFriends.showCount);
  const loading = useSelector((state) => state.toolCommonFriends.loading);
  const error = useSelector((state) => state.toolCommonFriends.error);
  const friends = useSelector((state) => state.toolCommonFriends.payload);
  const handleFetchCommonFriends = useCallback(() => dispatch(fetchCommonFriends()), [dispatch]);
  const handleSearchCommonFriends = useCallback(
    (search) => dispatch(searchCommonFriends(search)),
    [dispatch]
  );
  const handleFilterCommonFriends = useCallback(
    (filter) => dispatch(filterCommonFriends(filter)),
    [dispatch]
  );
  const handleShowMoreCommonFriends = useCallback(
    () => dispatch(showMoreCommonFriends()),
    [dispatch]
  );
  const handleCancelCommonFriends = useCallback(() => dispatch(cancelCommonFriends()), [dispatch]);

  const [searchTerm, setSearchTerm] = useState(search);

  useEffect(() => {
    handleFetchCommonFriends();
  }, [handleFetchCommonFriends]);

  useDebounceSearch({
    search: search,
    searchTerm: searchTerm,
    handler: handleSearchCommonFriends,
  });

  useEffect(() => {
    return () => {
      handleCancelCommonFriends();
    };
  }, [handleCancelCommonFriends]);

  useShowMore({
    items: friends,
    showCount: showCount,
    maxLength: friends !== null ? friends.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMoreCommonFriends,
  });

  const handleFilter = () => {
    if (loading || error !== null) return;

    props.openModal({
      id: MODAL_PAGE_TEMPLATE_FILTER_SEX_AGE_CITY,
      param: {
        filter: filter,
        closeModal: props.closeModal,
        applyFilter: handleFilterCommonFriends,
      },
    });
  };

  const renderFriendList = () => {
    return friends.length > 0 ? (
      friends.map((friend, i) => {
        const id = friend.vk_id;
        const name = friend.name;
        const photo = friend.photo_url;
        const commonCount = friend.common_count;
        const commonCountStrFormat =
          commonCount !== 0
            ? commonCount + " " + declOfNum(commonCount, ["общий", "общих", "общих"])
            : "0 общих";

        const commonFriends = friend.common_friends;

        if (i < showCount) {
          return (
            <CFUserItem
              key={i}
              id={id}
              title={name}
              photo={photo}
              commonFriends={commonFriends}
              commonCount={commonCountStrFormat}
              openModal={props.openModal}
            />
          );
        }

        return false;
      })
    ) : (
      <Placeholder icon={<Icon56UsersOutline />}>Друзья не найдены</Placeholder>
    );
  };

  return (
    <div>
      <Search
        value={searchTerm}
        icon={<Icon24Filter />}
        placeholder={"Имя или ссылка на страницу"}
        onIconClick={handleFilter}
        onChange={(e) => {
          if (loading || error !== null) return;

          setSearchTerm(e.target.value);
        }}
      />
      <Group style={{ marginBottom: "20px" }}>
        {friends !== null && error === null ? renderFriendList() : ""}
      </Group>
      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchCommonFriends,
      })}
    </div>
  );
};

export default CFUserList;
