import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Banner } from "@vkontakte/vkui";
import { setVKStorage } from "store/actions/vkStorage";
import styles from "./ToolBanner.module.css";

const ToolBanner = (props) => {
  const dispatch = useDispatch();
  const vkStorage = useSelector((state) => state.vkStorage.payload);

  const tool =
    vkStorage !== null && vkStorage.hasOwnProperty(props.tool) ? vkStorage[props.tool] : {};
  const isBanner = tool.hasOwnProperty("banner") ? tool.banner : true;
  const handleSetVKStorage = useCallback(
    ({ key, value }) => dispatch(setVKStorage({ key, value })),
    [dispatch]
  );

  return (
    <Banner
      size="m"
      mode="image"
      asideMode="dismiss"
      imageTheme="dark"
      background={<div className={styles.background}></div>}
      style={!isBanner ? { display: "none" } : null}
      onDismiss={() => {
        handleSetVKStorage({
          key: props.tool,
          value: {
            banner: false,
          },
        });
      }}
      before={<img width="28" height="28" src={props.icon} alt=""></img>}
      text={props.text ? <span style={{ fontSize: "16px" }}> {props.text} </span> : null}
      header={
        props.header ? (
          <span style={{ fontSize: "16px", fontWeight: "500" }}> {props.header} </span>
        ) : null
      }
      subheader={props.subHeader ? props.subHeader : null}
    />
  );
};

ToolBanner.defaultProps = {
  tool: null, // Инструмент
  icon: null, // Иконка
  text: null, // Текст
  header: null,
  subHeader: null,
};

export default ToolBanner;
