import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Group, Div, Placeholder, Alert, Button } from "@vkontakte/vkui";
import MyCrushItem from "../MyCrushItem/MyCrushItem";

import HeartIcon from "assets/img/moycrush/heart1.png";

import { VIEW_MOY_CRUSH } from "views/constViews";
import { PANEL_CHOOSE_CRUSH } from "../../../../constPanels";
import Icon16Add from "@vkontakte/icons/dist/16/add";

import { declOfNum } from "helpers";

import { fetchMyCrush, removeMyCrush } from "store/actions/tool/moyCrush";
import { renderErrorPlaceholder } from "helpers/views";

const MyCrushList = (props) => {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.toolMoyCrush.myCrush.error);
  const loading = useSelector((state) => state.toolMoyCrush.myCrush.loading);
  const crush = useSelector((state) =>
    state.toolMoyCrush.myCrush.payload ? state.toolMoyCrush.myCrush.payload : null
  );
  const my = crush !== null ? crush.my : null;
  const mutual = crush !== null ? crush.mutual : null;

  const handleFetchMyCrush = useCallback(() => dispatch(fetchMyCrush()), [dispatch]);
  const handleRemoveMyCrush = useCallback((id) => dispatch(removeMyCrush(id)), [dispatch]);

  useEffect(() => {
    handleFetchMyCrush();
  }, [handleFetchMyCrush]);

  const handleDelete = (id, name) => {
    props.openPopout({
      popout: (
        <Alert
          actionsLayout="horizontal"
          actions={[
            {
              title: "Отмена",
              autoclose: true,
              mode: "cancel",
            },
            {
              title: "Удалить",
              autoclose: true,
              mode: "destructive",
              action: () => {
                handleRemoveMyCrush(id);
              },
            },
          ]}
          onClose={props.closePopout}
        >
          <p>{"Вы уверены, что хотите удалить " + name + " из списка крашей?"}</p>
        </Alert>
      ),
    });
  };

  const renderMyCrushList = () => {
    return my.map((crush, i) => {
      const id = crush.vk_id;
      const name = crush.name;
      const city = crush.city ? crush.city : "";
      const age = crush.age ? crush.age : "";
      const photo = crush.photo_url ? crush.photo_url : "";

      const ageStrFormat = age ? age + " " + declOfNum(age, ["год", "года", "лет"]) : "";
      const comma = ageStrFormat && city ? ", " : "";

      return (
        <div key={i}>
          <MyCrushItem
            id={id}
            photo={photo}
            title={name}
            description={ageStrFormat + comma + city}
            openView={props.openView}
            handleDelete={handleDelete}
          />
        </div>
      );
    });
  };

  return (
    <div>
      {!loading && error === null ? (
        <Div style={{ paddingBottom: "0px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                fontSize: "20px",
                lineHeight: "24px",
                letterSpacing: "0.38px",
                fontStyle: "normal",
                marginTop: "14px",
                fontWeight: "bold",
              }}
            >
              Мои краши
            </div>
            <div>
              <Button
                size="l"
                mode="outline"
                disabled={loading}
                style={{ width: "100%", marginTop: "10px" }}
                before={<Icon16Add />}
                onClick={() => {
                  props.openView({ view: VIEW_MOY_CRUSH, panel: PANEL_CHOOSE_CRUSH });
                }}
              >
                Добавить
              </Button>
            </div>
          </div>
        </Div>
      ) : (
        ""
      )}

      <Group>
        {my !== null && mutual !== null && my.length <= 0 && mutual.length <= 0 ? (
          <Placeholder
            icon={<img src={HeartIcon} style={{ width: "50px", height: "50px" }} alt=""></img>}
          >
            Добавь в список пользователей, которые тебе симпатичны и узнай, взаимны ли ваши чувства
          </Placeholder>
        ) : (
          ""
        )}
        {my !== null ? renderMyCrushList() : ""}
        {renderErrorPlaceholder({
          error: error,
          loading: loading,
          isTryAgainHandler: handleFetchMyCrush,
        })}
      </Group>
    </div>
  );
};

export default MyCrushList;
