import React, { useState, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Div, FormLayout, FormLayoutGroup, Input, Button, Panel } from "@vkontakte/vkui";

import { fetchConvertSelfLink } from "store/actions/tool/trap";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import HeadTitle from "template/components/HeadTitle/HeadTitle";
import SnakbarCopy from "template/components/SnakbarCopy/SnakbarCopy";
import { withRouter } from "hoc";

const PanelTLinkDone = (props) => {
  const dispatch = useDispatch();
  const handleFetchConvertSelfLink = useCallback(
    () => dispatch(fetchConvertSelfLink()),
    [dispatch]
  );
  const trap = useSelector((state) => state.toolTrap.convertSelfLink.payload);
  const link = trap !== null ? trap.link : "";
  const loading = useSelector((state) => state.toolTrap.convertSelfLink.loading);
  const error = useSelector((state) => state.toolTrap.convertSelfLink.error);
  const inputRef = useRef(null);

  const [snackBarNotifiction, setSnackBarNotifiction] = useState(null);

  useEffect(() => {
    handleFetchConvertSelfLink();
  }, [handleFetchConvertSelfLink]);

  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Ловушка</PanelHeaderWithPath>
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        defaultMessage: "Ошибка при создании ссылки",
      })}
      {renderLoader({ loading: loading })}
      {!loading && error === null ? (
        <div>
          <HeadTitle>Ссылка готова!</HeadTitle>
          <Div
            style={{
              fontSize: "15px",
              fontStyle: "normal",
              fontWeight: "normal",
              lineHeight: "20px",
              paddingTop: "0px",
              paddingBottom: "6px",
              letterSpacing: "0.2px",
              color: "var(--text_secondary)",
            }}
          >
            Скопируйте ссылку ниже, разместите у себя на странице и узнавайте кто по ней перешел.
            Ссылка будет вести пользователя на его собственную страницу.
          </Div>
          <FormLayout>
            <FormLayoutGroup top="Ваша ссылка">
              <Input
                type="text"
                placeholder=""
                getRef={inputRef}
                defaultValue={link}
                disabled={link ? false : true}
              />
            </FormLayoutGroup>
          </FormLayout>
          <Div style={{ paddingBottom: "6px" }}>
            <SnakbarCopy link={link} copy={snackBarNotifiction} setCopy={setSnackBarNotifiction}>
              <Button size="l" style={{ width: "100%" }} disabled={link ? false : true}>
                Скопировать ссылку
              </Button>
            </SnakbarCopy>
          </Div>
          <Div>
            <Button size="l" style={{ width: "100%" }} disabled={true} mode="secondary">
              Создать пост
            </Button>
          </Div>
        </div>
      ) : (
        ""
      )}
    </Panel>
  );
};

export default withRouter(PanelTLinkDone);
