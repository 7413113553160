import React from "react";

import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Panel } from "@vkontakte/vkui";
import PUserList from "./components/PUserList/PUserList";
import ToolBanner from "template/components/ToolBanner/ToolBanner";
import PostingIcon from "assets/img/tool/posting/banner/posting.png";
import { withRouter } from "hoc";

const PanelPosting = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Постинг</PanelHeaderWithPath>
      <ToolBanner
        tool="toolPosting"
        icon={PostingIcon}
        text="Узнавайте какие посты в сообществах публиковали от своего имени ваши друзья"
      />
      <PUserList
        openView={props.openView}
        openModal={props.openModal}
        closeModal={props.closeModal}
      />
    </Panel>
  );
};

export default withRouter(PanelPosting);
