import React from "react";
import { Avatar, SimpleCell } from "@vkontakte/vkui";
import Icon16Chevron from "@vkontakte/icons/dist/16/chevron";

const PSGroupInfoItem = (props) => {
  return (
    <SimpleCell
      id={props.id}
      before={<Avatar size={48} src={props.photo} />}
      description={<span>{props.description}</span>}
      after={<Icon16Chevron fill="#B8C1CC" />}
    >
      {props.title}
    </SimpleCell>
  );
};

export default PSGroupInfoItem;
