import {
  Icon16Advertising,
  Icon16Comment,
  Icon16Mention,
  Icon28ChevronRightOutline,
} from "@vkontakte/icons";
import { Avatar, RichCell } from "@vkontakte/vkui";
import { convertTime } from "helpers";
import React from "react";
import { MENTION_RECORD, MENTION_COMMUNITY, MENTION_REPLY } from "../../../constPostType";
import styles from "./MInfoItem.module.css";

const MInfoItem = (props) => {
  const getType = (type) => {
    switch (type) {
      case MENTION_COMMUNITY:
        return "В сообществе";
      case MENTION_RECORD:
        return "В записи";
      case MENTION_REPLY:
      default:
        return "В комментарии";
    }
  };

  const getBefore = (type) => {
    switch (type) {
      case MENTION_COMMUNITY:
        return (
          <Avatar size={48} src={props.photo}>
            <div className={styles.community}>
              <Icon16Advertising width={12} height={12} fill="var(--white)" />
            </div>
          </Avatar>
        );
      case MENTION_RECORD:
        return (
          <Avatar size={48} src={props.photo}>
            <div className={styles.record}>
              <Icon16Mention width={12} height={12} fill="var(--white)" />
            </div>
          </Avatar>
        );
      case MENTION_REPLY:
      default:
        return (
          <Avatar size={48} src={props.photo}>
            <div className={styles.comment}>
              <Icon16Comment width={12} height={12} fill="var(--white)" />
            </div>
          </Avatar>
        );
    }
  };

  const isHref = () => {
    return props.typePage !== null &&
      props.fromId !== null &&
      props.toId !== null &&
      props.postId !== null
      ? true
      : false;
  };

  return (
    <div className={styles.container}>
      <RichCell
        before={getBefore(props.type)}
        target="_blank"
        disabled={isHref() ? false : true}
        href={
          isHref()
            ? `https://vk.com/${props.typePage}${Math.abs(props.fromId)}?w=wall${props.toId}_${
                props.postId
              }`
            : null
        }
        caption={
          <div style={{ marginTop: "3px", marginBottom: "4px", color: "var(--color-custom-link)" }}>
            {props.name}
          </div>
        }
        bottom={
          <div style={{ fontSize: "13px", color: "#818C99" }}>в {convertTime(props.date)}</div>
        }
        after={isHref() ? <Icon28ChevronRightOutline fill="#B8C1CC" /> : null}
      >
        <span style={{ fontSize: "16px", color: "#818C99" }}>{getType(props.type)}</span>
      </RichCell>
      <div style={{ padding: "0 16px" }}>
        <div
          style={{
            fontSize: "15px",
            fontWeight: "normal",
            lineHeight: "20px",
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: props.text }}></div>
        </div>
      </div>
    </div>
  );
};

MInfoItem.defaultProps = {
  name: "",
  photo: "",
  text: "",
  type: "",
  typePage: null,
  date: 0,
  fromId: null,
  postId: null,
  toId: null,
};

export default MInfoItem;
