import React from "react";
import { Avatar, Link } from "@vkontakte/vkui";
import SimpleCell from "@vkontakte/vkui/dist/components/SimpleCell/SimpleCell";
import { MODAL_PAGE_TEMPLATE_MULTI } from "template/modals/constModals";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { fetchPopularAudioInfo } from "store/actions/tool/whoListenFriends";
import { declOfNum } from "helpers";

const WLFPopularAudioItem = (props) => {
  const dispatch = useDispatch();

  const handleFetchPopularAudioInfo = useCallback(
    (id) => dispatch(fetchPopularAudioInfo(id)),
    [dispatch]
  );

  const renderFriends = (friends) => {
    return friends.map((friend, i) => {
      const id = friend.vk_id;
      const name = friend.name;
      const photo = friend.photo_url;

      return (
        <SimpleCell key={i} id={id} before={<Avatar size={40} src={photo} />}>
          {name}
        </SimpleCell>
      );
    });
  };

  const getDescription = () => {
    const friendStr =
      props.listeningFriends.length +
      " " +
      declOfNum(props.listeningFriends.length, ["друг", "друга", "друзей"]);

    return (
      <span>
        Добавили <Link>{friendStr}</Link>
      </span>
    );
  };

  return (
    <SimpleCell
      id={props.id}
      description={getDescription()}
      onClick={() => {
        handleFetchPopularAudioInfo(props.id).then((friends) => {
          props.openModal({
            id: MODAL_PAGE_TEMPLATE_MULTI,
            param: {
              header: (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p
                    style={{
                      fontWeight: "500",
                      fontSize: "13px",
                      marginBottom: "2px",
                      letterSpacing: "0.2px",
                      textTransform: "uppercase",
                      color: "#818C99",
                    }}
                  >
                    Список добавивших
                  </p>
                  <p
                    style={{
                      fontWeight: "500",
                      fontSize: "13px",
                      marginTop: "4px",
                      marginBottom: "10px",
                      letterSpacing: "0.2px",
                      textTransform: "uppercase",
                      color: "var(--text_primary)",
                    }}
                  >
                    {props.title + " "}
                    <span style={{ color: "var(--text_secondary)" }}>{props.artist}</span>
                  </p>
                </div>
              ),
              content: <div>{friends !== null && renderFriends(friends)}</div>,
            },
          });
        });
      }}
      after={
        <span
          style={{
            display: "flex",
            fontSize: "13px",
            alignItems: "center",
            color: "var(--text_secondary)",
          }}
        >
          {props.duration}
        </span>
      }
    >
      {props.title} <span style={{ color: "var(--text_secondary)" }}>{props.artist}</span>
    </SimpleCell>
  );
};

export default WLFPopularAudioItem;
