import React from "react";
import { Avatar, SimpleCell } from "@vkontakte/vkui";
import { PANEL_MS__INFO } from "../../../constPanels";
import Icon16Chevron from "@vkontakte/icons/dist/16/chevron";
import { VIEW_TOOL } from "views/constViews";
import {
  MARITAL_CIVIL_MARRIAGE,
  MARITAL_STATUS_ACTIVELY_LOOKING,
  MARITAL_STATUS_COMPLICATED,
  MARITAL_STATUS_ENGAGED,
  MARITAL_STATUS_HAVE_FRIEND,
  MARITAL_STATUS_LOVE,
  MARITAL_STATUS_MARRIED,
  MARITAL_STATUS_NOT_MARRIED,
} from "../../constMaritalStatus";

const MSUserItem = (props) => {
  const getRelation = () => {
    switch (props.relation) {
      case MARITAL_STATUS_NOT_MARRIED:
        return props.sex === "1" ? "не замужем" : "не женат";
      case MARITAL_STATUS_HAVE_FRIEND:
        return props.sex === "1" ? "есть друг" : "есть подруга";
      case MARITAL_STATUS_ENGAGED:
        return props.sex === "1" ? "помолвлена" : "помолвлен";
      case MARITAL_STATUS_MARRIED:
        return props.sex === "1" ? "замужем" : "женат";
      case MARITAL_STATUS_COMPLICATED:
        return "всё сложно";
      case MARITAL_STATUS_ACTIVELY_LOOKING:
        return "в активном поиске";
      case MARITAL_STATUS_LOVE:
        return props.sex === "1" ? "влюблена" : "влюблён";
      case MARITAL_CIVIL_MARRIAGE:
        return "в гражданском браке";
      default:
        return "";
    }
  };

  return (
    <SimpleCell
      multiline
      id={props.id}
      before={<Avatar size={48} src={props.photo} />}
      description={getRelation()}
      onClick={() =>
        props.openView({
          view: VIEW_TOOL,
          panel: PANEL_MS__INFO,
          param: {
            activeUserId: props.id,
            activeUserPhoto: props.photo,
            activeUserTitle: props.title,
            activeUserDescription: getRelation(),
            activeUserSex: props.sex,
          },
        })
      }
      after={<Icon16Chevron fill="#B8C1CC" />}
    >
      {props.title}
    </SimpleCell>
  );
};

export default MSUserItem;
