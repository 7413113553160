import React from "react";
import { Avatar, Link, SimpleCell } from "@vkontakte/vkui";
import Icon16Add from "@vkontakte/icons/dist/16/add";
import Icon16Cancel from "@vkontakte/icons/dist/16/cancel";
import styles from "./FriendHistory.module.css";

const FRIEND_STATUS_ADD = "add";
const FRIEND_STATUS_OUT = "out";
const FOLLOWER = "follower";
const FRIEND = "friend";

const FriendHistoryItem = (props) => {
  const getStatusFriend = () => {
    let str = "";

    if (props.status === FRIEND_STATUS_ADD && props.type === FRIEND)
      str = props.sex === "1" ? "Добавила в друзья " : "Добавил в друзья ";
    else if (props.status === FRIEND_STATUS_OUT && props.type === FRIEND)
      str = props.sex === "1" ? "Удалила из друзей " : "Удалил из друзей ";
    else if (props.status === FRIEND_STATUS_ADD && props.type === FOLLOWER)
      str = props.sex === "1" ? "Добавила в подписчики " : "Добавил в подписчики ";
    else if (props.status === FRIEND_STATUS_OUT && props.type === FOLLOWER)
      str = props.sex === "1" ? "Удалила из подписчиков " : "Удалил из подписчиков ";

    return str;
  };

  const getStatusIcon = () => {
    switch (props.status) {
      case FRIEND_STATUS_ADD:
        return (
          <Avatar size={48} src={props.photo}>
            <div className={styles.add}>
              <Icon16Add width={12} height={12} fill="var(--white)" />
            </div>
          </Avatar>
        );
      case FRIEND_STATUS_OUT:
        return (
          <Avatar size={48} src={props.photo}>
            <div className={styles.out}>
              <Icon16Cancel width={12} height={12} fill="var(--white)" />
            </div>
          </Avatar>
        );
      default:
        return "";
    }
  };

  return (
    <SimpleCell disabled before={getStatusIcon()} description={"в " + props.time} multiline>
      <span style={{ color: "var(--text_secondary)" }}>{getStatusFriend()}</span>
      <Link href={"https://www.vk.com/id" + props.id} target="_blank">
        {props.title}
      </Link>
    </SimpleCell>
  );
};

export default FriendHistoryItem;
