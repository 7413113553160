import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Panel } from "@vkontakte/vkui";
import ShopList from "./components/ShopList/ShopList";
import { withRouter } from "hoc";

const PanelMain = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Магазин</PanelHeaderWithPath>
      <ShopList
        openView={props.openView}
        openModal={props.openModal}
        closeModal={props.closeModal}
        openPopout={props.openPopout}
        closePopout={props.closePopout}
        goBack={props.goBack}
      />
    </Panel>
  );
};

export default withRouter(PanelMain);
