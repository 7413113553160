import React from "react";
import { addVKScope, checkVKMethodError } from "api";
import Icon56LockOutline from "@vkontakte/icons/dist/56/lock_outline";
import {
  HISTORY_STATISTICS_REQUEST,
  HISTORY_STATISTICS_SUCCESS,
  HISTORY_STATISTICS_ERROR,
  HISTORY_STATISTICS__INFO_PROGRESS,
  HISTORY_STATISTICS__INFO_REQUEST,
  HISTORY_STATISTICS__INFO_SUCCESS,
  HISTORY_STATISTICS__INFO_ERROR,
  HISTORY_STATISTICS__INFO_TABS,
  HISTORY_STATISTICS__INFO_SET_PERIOD,
  HISTORY_STATISTICS__INFO_SAVE_CACHE,
  HISTORY_STATISTICS__INFO_TOP_SPECTATOR_TABS,
  HISTORY_STATISTICS__INFO_TOP_SPECTATOR_READY,
} from "../../constType";
import { VkBridgeSend } from "api/vk";
import { APIEye3StoryGetTool } from "api/eye3";
import { STORY_TYPE_USER } from "views/tool/panels/historyStatistics/constStoryType";
import { APIVkExecute, APIVkGetByIdStories } from "api/vk/apiMethods";
import { calcAge, delay } from "helpers";
import { getPeriod } from "template/popouts/DateChangePeriod/actions";
import { getFilterTabsFriends } from "helpers/actions";
import { defaultErrorProperty } from "api/eye3/responseHandler";

// Получить ответы на сторисы
/*async function getStoriesReplies(dispatch, getState, activeId, storiesIds) {
    try {
        let result = [];
        let loadLength = storiesIds.length;
        let loadDone = 0;

        let minCounterReplies = 0;
        let maxCounterReplies = storiesIds.length < 24 ? storiesIds.length : 24;

        async function process() {
            try {
                let chunkRepliesItems = [];

                for (let i = minCounterReplies; i < maxCounterReplies; i++) {
                    chunkRepliesItems.push(storiesIds[i]);
                    minCounterReplies++;
                    loadDone++;
                }

                maxCounterReplies += 24;

                if (maxCounterReplies >= storiesIds.length)
                    maxCounterReplies = storiesIds.length;

                console.log('minCounterReplies: ', minCounterReplies);
                console.log('maxCounterReplies: ', maxCounterReplies);

                const executeReplies = await APIVkExecute({
                    code: `
                        var ids = [${chunkRepliesItems}];
                        var result = [];
                        var i = 0;
                        while (i < ids.length) {
                            var method = API.stories.getReplies({
                                owner_id: '${activeId}', 
                                story_id: ids[i],
                            });

                            result.push(method);
            
                            i = i + 1;
                        }
            
                        return result;
                    `
                });

                console.log('executeReplies: ', executeReplies);

                if (!executeReplies.hasOwnProperty('response'))
                    return result;
                else if (executeReplies.response === null)
                    return result;
                else if (executeReplies.response.length !== chunkRepliesItems.length)
                    return result;

                const progressValue = (loadDone * 100) / loadLength;

                dispatch(progress(Math.round(progressValue), result));

                if (minCounterReplies < storiesIds.length) {
                    await delay(400);
                    await process();
                }
            }
            catch (e) {
                throw new Error('process::: ', e);
            }
        }

        await process();

        return result;        
    }
    catch (e) {
        throw new Error('getStoriesReplies::: ', e);
    }
}*/

export function fetchHistoryStatistics() {
  return async (dispatch, getState) => {
    try {
      await dispatch(request());

      if (!(await addVKScope("stories"))) {
        // Проверка на разрешение stories
        dispatch(
          error({
            ...defaultErrorProperty,
            message: "Для просмотра статистики требуется доступ к историям",
            icon: <Icon56LockOutline />,
            isVisibleMessage: true,
          })
        );

        return;
      }

      const userInfo = await VkBridgeSend("VKWebAppGetUserInfo");
      if (checkVKMethodError(userInfo)) {
        throw new Error("error");
      }
      //console.log('userInfo: ', userInfo);

      let result = {
        vk_id: userInfo.id,
        name: userInfo.first_name + " " + userInfo.last_name,
        photo_url: userInfo.photo_100,
      };

      await dispatch(success(result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: HISTORY_STATISTICS_REQUEST };
  }

  function success(payload) {
    return { type: HISTORY_STATISTICS_SUCCESS, payload };
  }

  function error(error) {
    return { type: HISTORY_STATISTICS_ERROR, error };
  }
}

export function fetchInfoStatistics() {
  return async (dispatch, getState) => {
    try {
      const state = getState().toolHistoryStatistics;
      const pageParam = getState().page.param;

      const id = pageParam ? pageParam.activeId : state.stats.activeId;
      const storyType = pageParam ? pageParam.activeStoryType : state.stats.activeStoryType;

      dispatch(request(id, storyType));

      let storiesIds = [];

      if (storyType === STORY_TYPE_USER) {
        const stories = await APIEye3StoryGetTool();
        storiesIds = stories.items;
        //console.log('storiesIds: ', storiesIds);
      }

      let result = await getStoriesData(dispatch, getState, id, storiesIds);

      //console.log('result: ', result);

      dispatch(success(result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(id, storyType) {
    return { type: HISTORY_STATISTICS__INFO_REQUEST, id, storyType };
  }

  function success(payload) {
    return { type: HISTORY_STATISTICS__INFO_SUCCESS, payload };
  }

  function error(error) {
    return { type: HISTORY_STATISTICS__INFO_ERROR, error };
  }
}

function progress(progressValue) {
  return { type: HISTORY_STATISTICS__INFO_PROGRESS, progressValue };
}

function saveCacheInfoStatistics(cachePayload) {
  return { type: HISTORY_STATISTICS__INFO_SAVE_CACHE, cachePayload };
}

export function tabsInfoStatistics(tabs) {
  return { type: HISTORY_STATISTICS__INFO_TABS, tabs };
}

export function setPeriodInfoStatistics(name, atMin = null, atMax = null) {
  return (dispatch, getState) => {
    dispatch(exec(name, atMin, atMax));

    const cachePayload = getState().toolHistoryStatistics.stats.cachePayload;
    let result = getStoriesPeriod(dispatch, getState, cachePayload);
    //console.log('result: ', result);

    dispatch(success(result));
  };

  function exec() {
    return { type: HISTORY_STATISTICS__INFO_SET_PERIOD, name, atMin, atMax };
  }

  function success(payload) {
    return { type: HISTORY_STATISTICS__INFO_SUCCESS, payload };
  }
}

// Получить пользователей посмотревших историю
async function getStoriesViewers(dispatch, getState, activeId, storiesIds) {
  try {
    let result = [];
    let loadLength = storiesIds.length;
    let loadDone = 0;

    let minCounterViewers = 0;
    let maxCounterViewers = storiesIds.length < 24 ? storiesIds.length : 24;

    async function process() {
      try {
        let chunkViewersItems = [];

        for (let i = minCounterViewers; i < maxCounterViewers; i++) {
          chunkViewersItems.push(storiesIds[i]);
          minCounterViewers++;
          loadDone++;
        }

        maxCounterViewers += 24;

        if (maxCounterViewers >= storiesIds.length) maxCounterViewers = storiesIds.length;

        /*console.log('minCounterViewers: ', minCounterViewers);
                console.log('maxCounterViewers: ', maxCounterViewers);*/

        const executeViewers = await APIVkExecute({
          code: `
                        var ids = [${chunkViewersItems}];
                        var result = [];
                        var i = 0;
                        while (i < ids.length) {
                            var method = API.stories.getViewers({
                                owner_id: '${activeId}', 
                                story_id: ids[i],
                                fields: 'photo_100,bdate,country,city,sex',
                                extended: 1
                            });

                            result.push(method);
            
                            i = i + 1;
                        }
            
                        return result;
                    `,
        });

        //console.log('executeViewers: ', executeViewers);

        if (!executeViewers.hasOwnProperty("response")) return result;
        else if (executeViewers.response === null) return result;
        else if (executeViewers.response.length !== chunkViewersItems.length) return result;

        for (let i = 0; i < executeViewers.response.length; i++) {
          const element = executeViewers.response[i];
          const storyId = chunkViewersItems[i];

          if (element !== false && element.hasOwnProperty("items")) {
            for (let item of element.items) {
              result.push({
                ...item,
                story_id: storyId,
              });
            }
          }
        }

        const progressValue = (loadDone * 100) / loadLength;

        dispatch(progress(Math.round(progressValue), result));

        if (minCounterViewers < storiesIds.length) {
          await delay(400);
          await process();
        }
      } catch (e) {
        throw new Error("process::: " + e);
      }
    }

    await process();

    return result;
  } catch (e) {
    throw new Error("getViewers::: " + e);
  }
}

// Получить сторисы пользователя по id
async function getStoriesById(activeId, storiesIds, viewers) {
  try {
    let result = [];

    const stories = await APIVkGetByIdStories({
      stories: storiesIds.map((story) => `${activeId}_${story}`).join(","),
    });

    const storiesItems = stories.response.hasOwnProperty("items")
      ? stories.response.items
      : stories.response;

    //console.log('APIVkGetByIdStories: ', storiesItems);

    for (let story of storiesItems) {
      let viewer = []; // Зрители текущей истории

      if (!story.hasOwnProperty("is_deleted") && story !== false) {
        for (let view of viewers) {
          if (view.story_id === story.id) {
            viewer.push({
              is_liked: view.is_liked,
              vk_id: view.user.id,
              name: view.user.first_name + " " + view.user.last_name,
              age: view.user.bdate ? calcAge(view.user.bdate) : null,
              city: view.user.city ? view.user.city.title : null,
              city_id: view.user.city ? view.user.city.id.toString() : null,
              photo_url: view.user.photo_100,
              country: view.user.country ? view.user.country.title : null,
              country_id: view.user.country ? view.user.country.id.toString() : null,
              sex: view.user.sex,
            });
          }
        }

        result.push({
          story_id: story.id,
          user_id: story.owner_id,
          photo_url: getPreviewUrl(story),
          views: story.views,
          likes: story.likes_count,
          replies: story.replies.hasOwnProperty("count") ? story.replies.count : 0,
          date: story.date,
          viewers: viewer,
        });
      }
    }

    return result;
  } catch (e) {
    throw new Error("getStoriesById::: " + e);
  }
}

// Получить статистику по историям
async function getStoriesStats(dispatch, getState, activeId, stories) {
  try {
    let storiesIds = stories.map((story) => story.story_id);
    let result = [];
    let loadLength = storiesIds.length;
    let loadDone = 0;

    let minCounterStats = 0;
    let maxCounterStats = storiesIds.length < 24 ? storiesIds.length : 24;

    async function process() {
      try {
        let chunkStatsItems = [];

        for (let i = minCounterStats; i < maxCounterStats; i++) {
          chunkStatsItems.push(storiesIds[i]);
          minCounterStats++;
          loadDone++;
        }

        maxCounterStats += 24;

        if (maxCounterStats >= storiesIds.length) maxCounterStats = storiesIds.length;

        /*console.log('minCounterStats: ', minCounterStats);
                console.log('maxCounterStats: ', maxCounterStats);*/

        const executeStats = await APIVkExecute({
          code: `
                        var ids = [${chunkStatsItems}];
                        var result = [];
                        var i = 0;
                        while (i < ids.length) {
                            var method = API.stories.getStats({
                                owner_id: '${activeId}', 
                                story_id: ids[i],
                            });

                            result.push(method);
            
                            i = i + 1;
                        }
            
                        return result;
                    `,
        });

        //console.log('executeStats: ', executeStats);

        if (!executeStats.hasOwnProperty("response")) return result;
        else if (executeStats.response === null) return result;
        else if (executeStats.response.length !== chunkStatsItems.length) return result;

        for (let i = 0; i < executeStats.response.length; i++) {
          const element = executeStats.response[i];
          const storyId = chunkStatsItems[i];

          for (let story of stories) {
            if (storyId === story.story_id) {
              result.push({
                ...story,
                shares:
                  element !== false &&
                  element.hasOwnProperty("shares") &&
                  element.shares.state === "on"
                    ? element.shares.count
                    : 0,
                answers:
                  element !== false &&
                  element.hasOwnProperty("answer") &&
                  element.answer.state === "on"
                    ? element.answer.count
                    : 0,
              });
            }
          }
        }

        const progressValue = (loadDone * 100) / loadLength;
        dispatch(progress(Math.round(progressValue), result));

        if (minCounterStats < storiesIds.length) {
          await delay(400);
          await process();
        }
      } catch (e) {
        throw new Error("process::: " + e);
      }
    }

    await process();

    return result;
  } catch (e) {
    throw new Error("getStoriesStats::: " + e);
  }
}

function getPreviewUrl(s) {
  if (s.hasOwnProperty("photo"))
    return s.photo.sizes
      .filter((size) => size.type === "m")
      .map((size) => size.url)
      .join("");
  else if (s.hasOwnProperty("video"))
    return s.video.image
      .filter((size) => size.width === 130)
      .map((size) => size.url)
      .join("");
  else return "";
}

// Подсчет статистики во вкладке истории
function calcStoriesCounter(stories) {
  let result = {
    publish: 0, // Опубликовано
    views: 0, // Просмотрено
    likes: 0, // Лайки
    answers: 0, // Ответы на историю (коментарии и т.д)
    replies: 0, // Ответы на историю другой историей
    shares: 0, // Репосты историй
    top: {
      views: [],
      likes: [],
      answers: [],
      replies: [],
      shares: [],
    },
    spectators: {},
  };

  result.publish = stories.length;

  for (let story of stories) {
    result.views += story.views;
    result.likes += story.likes;
    result.answers += story.answers;
    result.replies += story.replies;
    result.shares += story.shares;

    result.top.views.push(story);
    result.top.likes.push(story);
    result.top.answers.push(story);
    result.top.replies.push(story);
    result.top.shares.push(story);
  }

  result.top.views = result.top.views.sort((a, b) => b.views - a.views);
  result.top.likes = result.top.likes.sort((a, b) => b.likes - a.likes);
  result.top.answers = result.top.answers.sort((a, b) => b.answers - a.answers);
  result.top.replies = result.top.replies.sort((a, b) => b.replies - a.replies);
  result.top.shares = result.top.shares.sort((a, b) => b.shares - a.shares);

  result.spectators = calcStoriesSpectator(stories);

  return result;
}

// Для подсчета процентов от числа
function calcPercentCount(sum, value) {
  let percent = 0;

  if (sum <= 0 || value <= 0) return percent;

  percent = (value / sum) * 100;
  percent = percent.toFixed(1);
  return parseFloat(percent);
}

// Для подсчета возраста
function calcAges(data, ageFrom, ageTo, age, sex) {
  let ageRes = data;

  if (age >= ageFrom && age <= ageTo) {
    if (sex === 1) ageRes.female_count++;
    else if (sex === 2) ageRes.male_count++;
  }

  return ageRes;
}

// Подсчет статистики во вкладке зрители
function calcStoriesSpectator(stories) {
  let result = {}; // Объект spectator
  let spectatorIds = []; // vk_id зрителей
  let agesCount = 0; // Количество пользователей у которых указан возраст
  let cityCount = 0; // Количество пользователей у которых указан город
  let sex = {
      male_count: 0,
      male_percent: 0,
      female_count: 0,
      female_percent: 0,
    },
    age14_24 = {
      male_count: 0,
      male_percent: 0,
      female_count: 0,
      female_percent: 0,
    },
    age25_34 = {
      male_count: 0,
      male_percent: 0,
      female_count: 0,
      female_percent: 0,
    },
    age35_44 = {
      male_count: 0,
      male_percent: 0,
      female_count: 0,
      female_percent: 0,
    },
    age45_54 = {
      male_count: 0,
      male_percent: 0,
      female_count: 0,
      female_percent: 0,
    },
    age55 = {
      male_count: 0,
      male_percent: 0,
      female_count: 0,
      female_percent: 0,
    },
    geo = {},
    top = [],
    topView = [],
    topLike = [];

  for (let story of stories) {
    let viewers = story.viewers; // Получение зрителей

    for (let user of viewers) {
      // Топы по просмотрам и лайкам
      for (let tops of top) {
        // Ищем пользователя с одинаковым vk_id и добавляем просмотр
        if (tops.vk_id === user.vk_id) {
          tops.views++;

          if (user.is_liked) {
            // Если пользователь лайкнул сторис
            tops.likes++;
          }
        }
      }

      // Если пользователь уже был подсчитан
      if (spectatorIds.includes(user.vk_id)) {
        break;
      }

      // Топы по просмотрам и лайкам
      top.push({
        // Сохраняем пользователя в топ
        vk_id: user.vk_id,
        name: user.name,
        photo_url: user.photo_url,
        sex: user.sex,
        views: 1,
        likes: user.is_liked ? 1 : 0,
      });

      // Пол
      if (user.sex === 1) sex.female_count++;
      else if (user.sex === 2) sex.male_count++;

      // Возраст
      if (user.age !== "" && user.age !== null) {
        agesCount++;
        age14_24 = calcAges(age14_24, 14, 24, user.age, user.sex);
        age25_34 = calcAges(age25_34, 25, 34, user.age, user.sex);
        age35_44 = calcAges(age35_44, 35, 44, user.age, user.sex);
        age45_54 = calcAges(age45_54, 45, 54, user.age, user.sex);
        age55 = calcAges(age55, 55, 100, user.age, user.sex);
      }

      // Гео
      if (user.city !== "" && user.city !== null) {
        cityCount++;

        if (geo[user.city] === undefined) {
          geo[user.city] = {
            count: 0,
            percent: 0,
          };
        }

        geo[user.city].count++;
      }

      spectatorIds.push(user.vk_id); // Сохранение пользователя для исключения дублирований
    }
  }

  sex.male_percent = calcPercentCount(spectatorIds.length, sex.male_count);
  sex.female_percent = calcPercentCount(spectatorIds.length, sex.female_count);

  age14_24.male_percent = calcPercentCount(agesCount, age14_24.male_count);
  age14_24.female_percent = calcPercentCount(agesCount, age14_24.female_count);

  age25_34.male_percent = calcPercentCount(agesCount, age25_34.male_count);
  age25_34.female_percent = calcPercentCount(agesCount, age25_34.female_count);

  age35_44.male_percent = calcPercentCount(agesCount, age35_44.male_count);
  age35_44.female_percent = calcPercentCount(agesCount, age35_44.female_count);

  age45_54.male_percent = calcPercentCount(agesCount, age45_54.male_count);
  age45_54.female_percent = calcPercentCount(agesCount, age45_54.female_count);

  age55.male_percent = calcPercentCount(agesCount, age55.male_count);
  age55.female_percent = calcPercentCount(agesCount, age55.female_count);

  for (let key in geo) {
    geo[key].percent = calcPercentCount(cityCount, geo[key].count); // Процент по гео
  }

  topView.push(...top);
  topLike.push(...top);
  topView = topView.sort((a, b) => b.views - a.views);
  topLike = topLike.sort((a, b) => b.likes - a.likes);

  result.sex = sex;
  result.age14_24 = age14_24;
  result.age25_34 = age25_34;
  result.age35_44 = age35_44;
  result.age45_54 = age45_54;
  result.age55 = age55;
  result.geo = geo;
  //result.top = top;
  result.topView = topView;
  result.topLike = topLike;

  return result;
}

function getStoriesPeriod(dispatch, getState, stats) {
  const period = getState().toolHistoryStatistics.stats.period;
  const { atMin, atMax } = getPeriod(period);
  let result = [];

  if (stats === null) return result;

  for (let story of stats) {
    if ((story.date >= atMin && story.date <= atMax) || (atMin === null && atMax === null)) {
      result.push(story);
    }
  }

  //console.log('storiesPeriod: ', result);

  result = calcStoriesCounter(result);

  return result;
}

async function getStoriesData(dispatch, getState, activeId, storiesIds) {
  try {
    let result = {};

    const viewers = await getStoriesViewers(dispatch, getState, activeId, storiesIds);
    //console.log('storiesGetViewers: ', viewers);

    const stories = await getStoriesById(activeId, storiesIds, viewers);
    //console.log('storiesGetById: ', stories);

    await delay(400);

    const stats = await getStoriesStats(dispatch, getState, activeId, stories);
    //console.log('storiesStats: ', stats);

    dispatch(saveCacheInfoStatistics(stats));

    result = getStoriesPeriod(dispatch, getState, stats);

    /*const replies = await getStoriesReplies(dispatch, getState, activeId, storiesIds);
        console.log('storiesReplies: ', replies);*/

    return result;
  } catch (e) {
    throw new Error("getStoriesData::: " + e);
  }
}

export function readyTopSpectators() {
  return (dispatch, getState) => {
    const state = getState().toolHistoryStatistics;
    const pageParam = getState().page.param;

    const header = pageParam ? pageParam.activeHeader : state.topSpectators.activeHeader;
    const typeSpectator = pageParam
      ? pageParam.activeTypeSpectator
      : state.topSpectators.activeTypeSpectator;
    const items = pageParam ? pageParam.activeItems : state.topSpectators.activeItems;

    const tabs = state.topSpectators.tabs;

    let result = [];

    result = getFilterTabsFriends({
      data: items,
      filter: "",
      tabsKey: ["sex"],
      tabsValue: tabs !== "0" ? parseInt(tabs) : "",
      template: [
        {
          first: "sex",
          compare: "===",
          two: "sex",
        },
      ],
    });

    dispatch(exec(header, typeSpectator, items, result));
  };

  function exec(header, typeSpectator, items, payload) {
    return {
      type: HISTORY_STATISTICS__INFO_TOP_SPECTATOR_READY,
      header,
      typeSpectator,
      items,
      payload,
    };
  }
}

export function tabsTopSpectators(tabs) {
  return (dispatch, getState) => {
    const state = getState().toolHistoryStatistics;
    const pageParam = getState().page.param;

    const items = pageParam ? pageParam.activeItems : state.topSpectators.activeItems;

    let result = [];

    result = getFilterTabsFriends({
      data: items,
      filter: "",
      tabsKey: ["sex"],
      tabsValue: tabs !== "0" ? parseInt(tabs) : "",
      template: [
        {
          first: "sex",
          compare: "===",
          two: "sex",
        },
      ],
    });

    dispatch(exec(tabs, result));
  };

  function exec(tabs, payload) {
    return { type: HISTORY_STATISTICS__INFO_TOP_SPECTATOR_TABS, tabs, payload };
  }
}
