import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Panel } from "@vkontakte/vkui";
import WhoHiddenAnotherUserList from "./components/WhoHiddenAnotherUserList/WhoHiddenAnotherUserList";
import { withRouter } from "hoc";

const PanelWHwhoHiddenAnotherUser = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Скрытые пользователи</PanelHeaderWithPath>
      <WhoHiddenAnotherUserList
        openView={props.openView}
        openModal={props.openModal}
        closeModal={props.closeModal}
      />
    </Panel>
  );
};

export default withRouter(PanelWHwhoHiddenAnotherUser);
