import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Panel, Placeholder, Button } from "@vkontakte/vkui";

import PlaceholderIcon from "assets/img/profile/psychotypePlaceholder.png";
import { subscribeGroup } from "store/actions/myProfile";
import { withRouter } from "hoc";

const PanelPsychotype = (props) => {
  const dispatch = useDispatch();
  const payloadEYE3 = useSelector((state) =>
    state.myProfile.payloadEYE3 ? state.myProfile.payloadEYE3 : null
  );
  const isGroupMember = payloadEYE3 ? payloadEYE3.user.group_member : true;

  const handleSubscribeGroup = useCallback(() => {
    dispatch(subscribeGroup());
  }, [dispatch]);

  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Психотип</PanelHeaderWithPath>
      <Placeholder
        icon={<img src={PlaceholderIcon} style={{ width: "50px", height: "50px" }} alt=""></img>}
        header="Тест в разработке, скоро вы узнаете свой психотип ;)"
        action={
          <Button
            size="l"
            mode={!isGroupMember ? "primary" : "secondary"}
            stretched
            onClick={() => {
              if (!isGroupMember) handleSubscribeGroup();
              else window.open("https://vk.com/club194197472", "_blank");
            }}
          >
            {!isGroupMember ? "Подписаться на сообщество" : "Перейти в сообщество"}
          </Button>
        }
        stretched
      >
        А пока следи за обновлениями в нашем сообществе Вконтакте
      </Placeholder>
    </Panel>
  );
};

export default withRouter(PanelPsychotype);
