import { setVKToken, getVKScope, getSearchObject } from "api";
import { VkBridgeSend } from "api/vk";

async function reAuth() {
  try {
    // console.log("reAuth: Получение нового токена...");

    const payloadVKToken = await VkBridgeSend("VKWebAppGetAuthToken", {
      app_id: parseInt(getSearchObject().vk_app_id),
      scope: getVKScope(),
    });

    setVKToken(payloadVKToken);

    // console.log("reAuth: Завершено");
  } catch (e) {
    throw new Error("reAuth::: " + JSON.stringify(e));
  }
}

export function responseHandler(method, response) {
  if (process.env.NODE_ENV === "development") {
    // console.log(`(${method}): `, response);
  }
}

export function errorHandler(method, error) {
  const errorType = error.hasOwnProperty("error_type") ? error.error_type : "";
  const data = error.hasOwnProperty("error_data") ? error.error_data : {};
  const reason = data.hasOwnProperty("error_reason") ? data.error_reason : {};
  const code = reason.hasOwnProperty("error_code") ? reason.error_code : null;

  errorType !== "" ? console.error(method + ": " + errorType + " --- ", data) : console.log("");

  if (code !== null) {
    switch (code) {
      case 5:
        reAuth();
        break;
      case 15:
        //addVKScope(reason.request_params);
        break;
      default:
        return;
    }
  }
}
