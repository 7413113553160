import {
  PROFILE_TIME_ONLINE_REQUEST,
  PROFILE_TIME_ONLINE_SUCCESS,
  PROFILE_TIME_ONLINE_ERROR,
  PROFILE_TIME_ONLINE_SET_PERIOD,
  PROFILE_TIME_ONLINE_SHOW_MORE,
} from "../../constType";

import { PERIOD_MONTH } from "template/popouts/DateChangePeriod/constPeriod";

const initialState = {
  activeUserId: null,
  payload: null,
  loading: false,
  error: null,
  showCount: 20,
  period: {
    name: PERIOD_MONTH,
    atMin: null,
    atMax: null,
  },
};

export default function timeOnlineReducer(state = initialState, action) {
  switch (action.type) {
    case PROFILE_TIME_ONLINE_REQUEST:
      return {
        ...state,
        showCount: 20,
        loading: true,
        error: null,
        payload: null,
        activeUserId: action.id,
      };
    case PROFILE_TIME_ONLINE_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: action.payload,
      };
    case PROFILE_TIME_ONLINE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case PROFILE_TIME_ONLINE_SHOW_MORE:
      return {
        ...state,
        showCount: parseInt(state.showCount) + 20,
      };
    case PROFILE_TIME_ONLINE_SET_PERIOD:
      return {
        ...state,
        period: {
          ...state.period,
          name: action.name,
          atMin: action.atMin,
          atMax: action.atMax,
        },
      };
    default:
      return state;
  }
}
