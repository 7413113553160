import React from "react";
import styles from "./ViewStoriesItem.module.css";

const ViewStoriesItem = (props) => {
  return (
    <div className={styles.itemCnt}>
      <img src={props.photo} width={79} height={130} alt=""></img>
      <div className={styles.itemTime}>{props.time}</div>
    </div>
  );
};

export default ViewStoriesItem;
