import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Panel } from "@vkontakte/vkui";
import PSOneGroupList from "./PSGroupInfoList/PSGroupInfoList";
import { withRouter } from "hoc";

const PanelPSOneGroup = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Популярные подписчики</PanelHeaderWithPath>
      <PSOneGroupList openView={props.openView} />
    </Panel>
  );
};

export default withRouter(PanelPSOneGroup);
