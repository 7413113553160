import React from "react";
import { FixedLayout, PanelHeader, Separator } from "@vkontakte/vkui";

import PanelHeaderBalance from "../PanelHeaderBalance/PanelHeaderBalance";

const PanelHeaderWitchoutPath = (props) => {
  return (
    <>
      <PanelHeader separator={false}>{props.children}</PanelHeader>
      <FixedLayout vertical="top">
        <PanelHeaderBalance />
      </FixedLayout>
      <Separator style={{ paddingTop: "62px" }} />
    </>
  );
};

export default PanelHeaderWitchoutPath;
