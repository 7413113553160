import bridge from "@vkontakte/vk-bridge";
import { errorHandler, responseHandler } from "./responseHandler";

async function VkBridgeSend(method, params = {}) {
  try {
    const response = await bridge.send(method, params);
    responseHandler(method, response);
    return response;
  } catch (e) {
    errorHandler(method, e);
    return e;
  }
}

export default VkBridgeSend;
