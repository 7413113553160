import React from "react";
import gImg from "assets/img/shop/guest.png";
import tImg from "assets/img/shop/premium.png";
import styles from "./ShopItem.module.css";
import { Button, Div } from "@vkontakte/vkui";
import {
  MODAL_CARD_CONNECT_AUTO_PAYMENT,
  MODAL_CARD_TARIFF_CONNECTION,
} from "views/shop/modals/constModals";

const ShopItem = (props) => {
  return (
    <Div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
      <div className={styles.item}>
        <div className={styles.title}>Тариф «{props.tariffCaption}»</div>
        <div className={styles.subtitle}>{props.tariffSubtitle}</div>
        <div className={styles.itemCnt}>
          <div className={styles.itemLeft}>
            <div className={styles.img}>
              {props.tariffType === "guest" ? (
                <img width={48} height={48} src={gImg} alt="" />
              ) : props.tariffType === "tools" ? (
                <img width={48} height={48} src={tImg} alt="" />
              ) : (
                <></>
              )}
            </div>
            <div>
              <div className={styles.money}>{props.sum} монет</div>
              <div className={styles.day}>{props.dayCount} дней</div>
            </div>
          </div>
          <div className={styles.itemRight}>
            {!props.isEnable ? (
              <Button
                mode="commerce"
                disabled={props.loading}
                onClick={() => {
                  !props.autopayment // Вывод модального окна в зависимости от подключения автоплатежа
                    ? props.openModal({
                        id: MODAL_CARD_CONNECT_AUTO_PAYMENT,
                        param: {
                          tariffCaption: props.tariffCaption,
                          timeCaption: props.timeCaption,
                          tariffType: props.tariffType,
                          sum: props.sum,
                          dayCount: props.dayCount,
                        },
                      })
                    : props.openModal({
                        id: MODAL_CARD_TARIFF_CONNECTION,
                        param: {
                          tariffCaption: props.tariffCaption,
                          timeCaption: props.timeCaption,
                          tariffType: props.tariffType,
                          sum: props.sum,
                          dayCount: props.dayCount,
                        },
                      });
                }}
              >
                {props.loading ? "Подключение..." : "Подключить"}
              </Button>
            ) : (
              <Button mode="commerce" disabled={true}>
                Подключено
              </Button>
            )}
          </div>
        </div>
      </div>
    </Div>
  );
};

ShopItem.defaultProps = {
  tariffCaption: "", // Заголовок тарифа
  tariffSubtitle: "", // Подзаголовок
  timeCaption: "", // Заголовок количество месяцев/дней
  sum: 0, // Сумма тарифа
  tariffType: "", // Тип тарифа guest/tools
  dayCount: 0, // Количество дней
  autopayment: false, // Автоплатеж
  openModal: null, // Функция вызова модального окна
  isEnable: false, // Если тариф подключен
  loading: false, // Если идет подключение тарифа
};

export default ShopItem;
