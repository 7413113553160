export const PANEL_PROFILE = "profile"; // Страница профиля

export const PANEL_PROFILE_VIEW_PAGE = "profile_view_page"; // Страница просмотров
export const PANEL_PROFILE_VIEW_STORIES = "profile_view_stories"; // Страница историй
export const PANEL_PROFILE_LIKE = "profile_like"; // Страница лайков
export const PANEL_PROFILE_COMMENT = "profile_comment"; // Страница комментариев
export const PANEL_PROFILE_TIME_ONLINE = "profile_time_online"; // Время онлайн
export const PANEL_PROFILE_INFO_HISTORY = "profile_info_history"; // История пользователя
export const PANEL_PROFILE_MARITAL_STATUS = "profile_marital_status"; // Семейное положение
export const PANEL_PROFILE_FRIEND_HISTORY = "profile_friend_history"; // Добавление/Удаление друзей
export const PANEL_PROFILE_JOIN_COMMUNITY = "profile_join_community"; // Вступление/Выход из сообществ
export const PANEL_PROFILE_GIFT = "profile_gift"; // Подарки
export const PANEL_PROFILE_ALL_LIKE = "profile_all_like"; // Страница всех лайков
export const PANEL_PROFILE_ALL_COMMENT = "profile_all_comment"; // Страница всех комментариев

export const PANEL_PROFILE_PSYCHOTYPE = "profile_psychotype"; // Психотип
export const PANEL_PROFILE_HOROSCOPE = "profile_horoscope"; // Гороскоп
