import React from "react";
import { ModalCard } from "@vkontakte/vkui";

const BalanceStatusModal = (props) => {
  const header = props.modalParam ? props.modalParam.header : "";
  const caption = props.modalParam ? props.modalParam.caption : "";
  const icon = props.modalParam ? props.modalParam.icon : "";
  const btnTitle = props.modalParam ? props.modalParam.btnTitle : "";

  return (
    <ModalCard
      id={props.id}
      header={header}
      caption={caption}
      icon={<img style={{ width: "50px", height: "50px" }} src={icon} alt="" />}
      onClose={props.handleCloseModal}
      actions={[
        {
          title: btnTitle,
          mode: "primary",
          action: props.handleCloseModal,
        },
      ]}
    />
  );
};

export default BalanceStatusModal;
