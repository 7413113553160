import React from "react";
import { Avatar, SimpleCell } from "@vkontakte/vkui";
import Icon16Chevron from "@vkontakte/icons/dist/16/chevron";
import { VIEW_TOOL } from "views/constViews";
import { PANEL_PROFILE } from "views/profile/panels/constPanels";

const WhoHiddenAnotherUserInfoItem = (props) => {
  return (
    <SimpleCell
      before={<Avatar size={72} src={props.photo} />}
      description={props.description}
      onClick={() =>
        props.openView({
          view: VIEW_TOOL,
          panel: PANEL_PROFILE,
          param: { activeProfileId: props.id },
        })
      }
      after={<Icon16Chevron fill="#B8C1CC" />}
    >
      {props.title}
    </SimpleCell>
  );
};

export default WhoHiddenAnotherUserInfoItem;
