import React, { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Div, Panel, Spinner } from "@vkontakte/vkui";
import { checkedPayment, cancelCheckedPayment } from "store/actions/shop";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { withRouter } from "hoc";

const PanelBalanceCheckedStatus = (props) => {
  const dispatch = useDispatch();
  const handleCheckedPayment = useCallback(() => dispatch(checkedPayment()), [dispatch]);
  const handleCancelCheckedPayment = useCallback(
    () => dispatch(cancelCheckedPayment()),
    [dispatch]
  );

  useEffect(() => {
    return () => {
      handleCancelCheckedPayment();
    };
  }, [handleCancelCheckedPayment]);

  useEffect(() => {
    handleCheckedPayment();
  }, [handleCheckedPayment]);

  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack} isVisibleBalance={false}>
        Ожидание оплаты...
      </PanelHeaderWithPath>
      <Div>
        <Spinner size="medium" />
      </Div>
    </Panel>
  );
};

export default withRouter(PanelBalanceCheckedStatus);
