import React from "react";
import { Cell, Avatar } from "@vkontakte/vkui";
import "./ChooseCrushItem.scss";

const ChooseCrushItem = (props) => {
  return (
    <Cell
      selectable
      onChange={(e) => {
        props.onChangeCrush(e);
      }}
      value={props.id}
      defaultChecked={props.selected}
      before={<Avatar size={48} src={props.photo} />}
      className={"choose-crush-item"}
      description={props.description}
    >
      {props.title}
    </Cell>
  );
};

export default ChooseCrushItem;
