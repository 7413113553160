import { queryEye3 } from "api";

async function APIEye3CreateTrap({ url }) {
  try {
    const response = await queryEye3({
      method: "trap.create",
      get: { url: url },
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3CreateTrap;
