import {
  FAN_REQUEST,
  FAN_SUCCESS,
  FAN_PROGRESS,
  FAN_TABS,
  FAN_SET_PERIOD,
  FAN_ERROR,
  FAN__TOP_ALL_REQUEST,
  FAN__TOP_ALL_PROGRESS,
  FAN__TOP_ALL_SUCCESS,
  FAN__TOP_ALL_ERROR,
  FAN__TOP_ALL_SHOW_MORE,
  FAN__TOP_ALL_TABS,
  FAN__TOP_ALL_SEARCH_REQUEST,
  FAN__TOP_ALL_SEARCH_SUCCESS,
  FAN__TOP_ALL_SEARCH_ERROR,
  FAN__TOP_ALL_FILTER_REQUEST,
  FAN__TOP_ALL_FILTER_SUCCESS,
  FAN__TOP_ALL_FILTER_ERROR,
} from "../../constType";

import { TABS_ALL } from "views/moycrush/panels/chooseCrush/constTabs";
import { PERIOD_MONTH } from "template/popouts/DateChangePeriod/constPeriod";

const initialState = {
  topAll: {
    payload: null,
    cachePayload: null,
    loading: false,
    typeTop: null,
    progressValue: 0,
    error: null,
    filter: "",
    search: "",
    showCount: 20,
  },
  guests: {
    payload: null,
    topValue: null,
  },
  likes: {
    payload: null,
    topValue: null,
  },
  comments: {
    payload: null,
    topValue: null,
  },
  period: {
    name: PERIOD_MONTH,
    atMin: null,
    atMax: null,
  },
  lastPeriodName: null,
  tabs: TABS_ALL,
  loading: false,
  progressValue: 0,
  error: null,
};

export default function fanReducer(state = initialState, action) {
  switch (action.type) {
    case FAN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        guests: {
          ...state.guests,
          payload: null,
          topValue: null,
        },
        likes: {
          ...state.likes,
          payload: null,
          topValue: null,
        },
        comments: {
          ...state.comments,
          payload: null,
          topValue: null,
        },
      };
    case FAN_SUCCESS:
      return {
        ...state,
        loading: false,
        guests: {
          ...state.guests,
          payload: action.guests,
          topValue: action.guests.length !== 0 ? action.guests[0] : null,
        },
        likes: {
          ...state.likes,
          payload: action.likes,
          topValue: action.likes.length !== 0 ? action.likes[0] : null,
        },
        comments: {
          ...state.comments,
          payload: action.comments,
          topValue: action.comments.length !== 0 ? action.comments[0] : null,
        },
      };
    case FAN_PROGRESS:
      return {
        ...state,
        guests: {
          ...state.guests,
          payload: action.guests,
        },
        likes: {
          ...state.likes,
          payload: action.likes,
        },
        comments: {
          ...state.comments,
          payload: action.comments,
        },
        progressValue: action.progressValue,
      };
    case FAN_TABS:
      return {
        ...state,
        tabs: action.tabs,
      };
    case FAN_SET_PERIOD:
      return {
        ...state,
        topAll: {
          ...state.topAll,
          showCount: 20,
        },
        lastPeriodName: state.period.name, // Сохраняем предыдущий период
        period: {
          ...state.period,
          name: action.name,
          atMin: action.atMin,
          atMax: action.atMax,
        },
      };
    case FAN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case FAN__TOP_ALL_REQUEST:
      return {
        ...state,
        topAll: {
          ...state.topAll,
          showCount: 20,
          typeTop: action.typeTop,
          loading: true,
          error: null,
          payload: null,
        },
      };
    case FAN__TOP_ALL_PROGRESS:
      return {
        ...state,
        topAll: {
          ...state.topAll,
          progressValue: action.progressValue,
          payload: action.payload,
        },
      };
    case FAN__TOP_ALL_SUCCESS:
      return {
        ...state,
        topAll: {
          ...state.topAll,
          loading: false,
          cachePayload: action.cachePayload,
          payload: action.payload,
        },
      };
    case FAN__TOP_ALL_ERROR:
      return {
        ...state,
        topAll: {
          ...state.topAll,
          loading: false,
          error: action.error,
        },
      };
    case FAN__TOP_ALL_SHOW_MORE:
      return {
        ...state,
        topAll: {
          ...state.topAll,
          showCount: parseInt(state.topAll.showCount) + 20,
        },
      };
    case FAN__TOP_ALL_TABS:
      return {
        ...state,
        tabs: action.tabs,
        topAll: {
          ...state.topAll,
          showCount: 20,
          payload: action.payload,
        },
      };
    case FAN__TOP_ALL_SEARCH_REQUEST:
      return {
        ...state,
        topAll: {
          ...state.topAll,
          showCount: 20,
          search: action.search,
          loading: true,
          error: null,
        },
      };
    case FAN__TOP_ALL_SEARCH_SUCCESS:
      return {
        ...state,
        topAll: {
          ...state.topAll,
          loading: false,
          payload: [...action.payload],
        },
      };
    case FAN__TOP_ALL_SEARCH_ERROR:
      return {
        ...state,
        topAll: {
          ...state.topAll,
          loading: false,
          error: action.error,
        },
      };
    case FAN__TOP_ALL_FILTER_REQUEST:
      return {
        ...state,
        topAll: {
          ...state.topAll,
          showCount: 20,
          payload: null,
          filter: action.filter,
          loading: true,
          error: null,
        },
      };
    case FAN__TOP_ALL_FILTER_SUCCESS:
      return {
        ...state,
        topAll: {
          ...state.topAll,
          payload: [...action.payload],
          loading: false,
        },
      };
    case FAN__TOP_ALL_FILTER_ERROR:
      return {
        ...state,
        topAll: {
          ...state.topAll,
          loading: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}
