import {
  FRIENDS_IN_APP_POPULAR_REQUEST,
  FRIENDS_IN_APP_POPULAR_PROGRESS,
  FRIENDS_IN_APP_POPULAR_SHOW_MORE,
  FRIENDS_IN_APP_POPULAR_SUCCESS,
  FRIENDS_IN_APP_POPULAR_ERROR,
  FRIENDS_IN_APP__INFO_REQUEST,
  FRIENDS_IN_APP__INFO_SHOW_MORE,
  FRIENDS_IN_APP__INFO_PROGRESS,
  FRIENDS_IN_APP__INFO_SUCCESS,
  FRIENDS_IN_APP__INFO_ERROR,
  FRIENDS_IN_APP__APPS_FROM_FRIENDS_REQUEST,
  FRIENDS_IN_APP__APPS_FROM_FRIENDS_PROGRESS,
  FRIENDS_IN_APP__APPS_FROM_FRIENDS_SHOW_MORE,
  FRIENDS_IN_APP__APPS_FROM_FRIENDS_SUCCESS,
  FRIENDS_IN_APP__APPS_FROM_FRIENDS_ERROR,
  FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_REQUEST,
  FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_PROGRESS,
  FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_SHOW_MORE,
  FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_SUCCESS,
  FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_ERROR,
  FRIENDS_IN_APP_POPULAR_CANCEL,
  FRIENDS_IN_APP__APPS_FROM_FRIENDS_CANCEL,
  FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_CANCEL,
  FRIENDS_IN_APP__INFO_CANCEL,
} from "../../constType";

const initialState = {
  popularApp: {
    payload: null,
    progressValue: 0,
    showCount: 20,
    loading: false,
    error: null,
  },
  appsInfo: {
    payload: null,
    activeAppId: null,
    progressValue: 0,
    showCount: 20,
    loading: false,
    error: null,
  },
  appsFromFriends: {
    payload: null,
    progressValue: 0,
    showCount: 20,
    loading: false,
    error: null,
  },
  appsFromFriendsInfo: {
    payload: null,
    activeUserId: null, // Параметры для сохранения пользователя
    activeUserPhoto: null, //
    activeUserTitle: null, //
    activeUserDescription: null, //
    progressValue: 0,
    showCount: 20,
    loading: false,
    error: null,
  },
};

export default function friendsInAppReducer(state = initialState, action) {
  switch (action.type) {
    case FRIENDS_IN_APP_POPULAR_REQUEST:
      return {
        ...state,
        popularApp: {
          ...state.popularApp,
          showCount: 20,
          payload: null,
          loading: true,
          error: null,
        },
      };
    case FRIENDS_IN_APP_POPULAR_PROGRESS:
      return {
        ...state,
        popularApp: {
          ...state.popularApp,
          payload: action.payload,
          progressValue: action.progressValue,
        },
      };
    case FRIENDS_IN_APP_POPULAR_SHOW_MORE:
      return {
        ...state,
        popularApp: {
          ...state.popularApp,
          showCount: parseInt(state.popularApp.showCount) + 20,
        },
      };
    case FRIENDS_IN_APP_POPULAR_SUCCESS:
      return {
        ...state,
        popularApp: {
          ...state.popularApp,
          payload: action.payload,
          loading: false,
        },
      };
    case FRIENDS_IN_APP_POPULAR_CANCEL:
      return {
        ...state,
        popularApp: {
          ...state.popularApp,
          loading: false,
        },
      };
    case FRIENDS_IN_APP_POPULAR_ERROR:
      return {
        ...state,
        popularApp: {
          ...state.popularApp,
          loading: false,
          error: action.error,
        },
      };
    case FRIENDS_IN_APP__APPS_FROM_FRIENDS_REQUEST:
      return {
        ...state,
        appsFromFriends: {
          ...state.appsFromFriends,
          payload: null,
          showCount: 20,
          loading: true,
          error: null,
        },
      };
    case FRIENDS_IN_APP__APPS_FROM_FRIENDS_PROGRESS:
      return {
        ...state,
        appsFromFriends: {
          ...state.appsFromFriends,
          payload: action.payload,
          progressValue: action.progressValue,
        },
      };
    case FRIENDS_IN_APP__APPS_FROM_FRIENDS_CANCEL:
      return {
        ...state,
        appsFromFriends: {
          ...state.appsFromFriends,
          loading: false,
        },
      };
    case FRIENDS_IN_APP__APPS_FROM_FRIENDS_SHOW_MORE:
      return {
        ...state,
        appsFromFriends: {
          ...state.appsFromFriends,
          showCount: parseInt(state.appsFromFriends.showCount) + 20,
        },
      };
    case FRIENDS_IN_APP__APPS_FROM_FRIENDS_SUCCESS:
      return {
        ...state,
        appsFromFriends: {
          ...state.appsFromFriends,
          payload: action.payload,
          loading: false,
        },
      };
    case FRIENDS_IN_APP__APPS_FROM_FRIENDS_ERROR:
      return {
        ...state,
        appsFromFriends: {
          ...state.appsFromFriends,
          loading: false,
          error: action.error,
        },
      };
    case FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_REQUEST:
      return {
        ...state,
        appsFromFriendsInfo: {
          ...state.appsFromFriendsInfo,
          payload: null,
          showCount: 20,
          activeUserId: action.id,
          activeUserDescription: action.description,
          activeUserTitle: action.title,
          activeUserPhoto: action.photo,
          loading: true,
          error: null,
        },
      };
    case FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_SUCCESS:
      return {
        ...state,
        appsFromFriendsInfo: {
          ...state.appsFromFriendsInfo,
          loading: false,
          payload: action.payload,
        },
      };
    case FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_PROGRESS:
      return {
        ...state,
        appsFromFriendsInfo: {
          ...state.appsFromFriendsInfo,
          progressValue: action.progressValue,
          payload: action.payload,
        },
      };
    case FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_CANCEL:
      return {
        ...state,
        appsFromFriendsInfo: {
          ...state.appsFromFriendsInfo,
          loading: false,
        },
      };
    case FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_SHOW_MORE:
      return {
        ...state,
        appsFromFriendsInfo: {
          ...state.appsFromFriendsInfo,
          showCount: parseInt(state.appsFromFriendsInfo.showCount) + 20,
        },
      };
    case FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_ERROR:
      return {
        ...state,
        appsFromFriendsInfo: {
          ...state.appsFromFriendsInfo,
          loading: false,
          error: action.error,
        },
      };
    case FRIENDS_IN_APP__INFO_REQUEST:
      return {
        ...state,
        appsInfo: {
          ...state.appsInfo,
          payload: null,
          showCount: 20,
          activeAppId: action.id,
          loading: true,
          error: null,
        },
      };
    case FRIENDS_IN_APP__INFO_SHOW_MORE:
      return {
        ...state,
        appsInfo: {
          ...state.appsInfo,
          showCount: parseInt(state.appsInfo.showCount) + 20,
        },
      };
    case FRIENDS_IN_APP__INFO_SUCCESS:
      return {
        ...state,
        appsInfo: {
          ...state.appsInfo,
          payload: action.payload,
          loading: false,
        },
      };
    case FRIENDS_IN_APP__INFO_PROGRESS:
      return {
        ...state,
        appsInfo: {
          ...state.appsInfo,
          progressValue: action.progressValue,
          payload: action.payload,
        },
      };
    case FRIENDS_IN_APP__INFO_CANCEL:
      return {
        ...state,
        appsInfo: {
          ...state.appsInfo,
          loading: false,
        },
      };
    case FRIENDS_IN_APP__INFO_ERROR:
      return {
        ...state,
        appsInfo: {
          ...state.appsInfo,
          loading: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}
