import React from "react";
import { FixedLayout, PanelHeader, PanelHeaderButton, Separator } from "@vkontakte/vkui";
import Icon28ArrowLeftOutline from "@vkontakte/icons/dist/28/arrow_left_outline";

import PanelHeaderBalance from "../PanelHeaderBalance/PanelHeaderBalance";

const PanelHeaderWithPath = (props) => {
  return (
    <>
      <PanelHeader
        separator={false}
        left={
          <PanelHeaderButton onClick={props.goBack}>
            <Icon28ArrowLeftOutline fill="#fff" />
          </PanelHeaderButton>
        }
      >
        {props.children}
      </PanelHeader>
      {props.isVisibleBalance && (
        <>
          <FixedLayout vertical="top">
            <PanelHeaderBalance />
          </FixedLayout>
          <Separator style={{ paddingTop: "62px" }} />
        </>
      )}
    </>
  );
};

PanelHeaderWithPath.defaultProps = {
  goBack: null,
  isVisibleBalance: true,
};

export default PanelHeaderWithPath;
