import React from "react";

import { Div } from "@vkontakte/vkui";
import Icon12OnlineMobile from "@vkontakte/icons/dist/12/online_mobile";
import OnlinePCIcon from "../../../../../../assets/img/onlinePC.svg";

import styles from "./ViewPageItem.module.css";

const ViewPageItem = (props) => {
  return (
    <Div className={styles.item}>
      <div className={styles.itemCnt}>
        <span className={styles.itemText}>{props.sex === "1" ? "Заходила" : "Заходил"} </span> в{" "}
        {props.title}
      </div>
      {props.isOnlineMobile ? (
        <Icon12OnlineMobile fill={"#4bb34b"} />
      ) : (
        <img src={OnlinePCIcon} alt="" />
      )}
    </Div>
  );
};

export default ViewPageItem;
