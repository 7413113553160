import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PANEL_TS__START_TRACKING, PANEL_TS__SETTING_NOTIFICATION } from "../constPanels";
import Icon28ListAddOutline from "@vkontakte/icons/dist/28/list_add_outline";
import Icon28Notifications from "@vkontakte/icons/dist/28/notifications";
import Icon16Chevron from "@vkontakte/icons/dist/16/chevron";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Group, Cell, Panel } from "@vkontakte/vkui";

import { fetchTotalSurveillance } from "store/actions/tool/totalSurveillance";
import { VIEW_TOOL } from "views/constViews";

import TSTrackedUserList from "./components/TSTrackedUserList/TSTrackedUserList";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import ToolBanner from "template/components/ToolBanner/ToolBanner";
import LoupeIcon from "assets/img/tool/totalSurveillance/banner/loupe.png";
import { withRouter } from "hoc";

const PanelTotalSurveillance = (props) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.toolTotalSurveillance.loading);
  const error = useSelector((state) => state.toolTotalSurveillance.error);
  const handleFetchTotalSurveillance = useCallback(
    () => dispatch(fetchTotalSurveillance()),
    [dispatch]
  );

  useEffect(() => {
    handleFetchTotalSurveillance();
  }, [handleFetchTotalSurveillance]);

  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Тотальная слежка</PanelHeaderWithPath>

      <ToolBanner
        tool="toolTotalSurveillance"
        icon={LoupeIcon}
        text="Получайте уведомления обо всех активностях пользователей VK"
      />

      {!loading && error === null ? (
        <Group separator="hide">
          <Cell
            before={<Icon28ListAddOutline />}
            asideContent={<Icon16Chevron fill="#B8C1CC" />}
            onClick={() => props.openView({ view: VIEW_TOOL, panel: PANEL_TS__START_TRACKING })}
          >
            Начать отслеживать
          </Cell>
          <Cell
            before={<Icon28Notifications />}
            asideContent={<Icon16Chevron fill="#B8C1CC" />}
            onClick={() =>
              props.openView({ view: VIEW_TOOL, panel: PANEL_TS__SETTING_NOTIFICATION })
            }
          >
            Настройки уведомлений
          </Cell>
        </Group>
      ) : (
        ""
      )}

      {!loading && error === null ? <TSTrackedUserList openView={props.openView} /> : ""}

      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchTotalSurveillance,
      })}
    </Panel>
  );
};

export default withRouter(PanelTotalSurveillance);
