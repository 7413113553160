import { queryEye3 } from "api";

async function APIEye3SearchRelationBackgroundTool({ isEnable }) {
  try {
    const response = await queryEye3({
      method: "tool.search_relation_background",
      get: { is_enable: isEnable },
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3SearchRelationBackgroundTool;
