import React, { useState, useEffect, useCallback } from "react";
import { Panel, ScreenSpinner } from "@vkontakte/vkui";
import { useDispatch, useSelector } from "react-redux";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { updateMyProfile } from "store/actions/myProfile";
import { connectTariff } from "store/actions/shop";
import { PANEL_MAIN, PANEL_BALANCE_REPLENISHMENT } from "../constPanels";
import {
  MODAL_CARD_BALANCE_STATUS,
  MODAL_CARD_TARIFF_CONNECTED,
} from "views/shop/modals/constModals";
import { declOfNum } from "helpers";

import BalanceReplenishedIcon from "assets/img/shop/modal/balanceReplenished.png";
import SomethingWentWrongIcon from "assets/img/shop/modal/somethingWentWrong.png";
import { VIEW_SHOP } from "views/constViews";
import { withRouter } from "hoc";

const PanelBalanceIframe = (props) => {
  const dispatch = useDispatch();

  const handleConnectTariff = useCallback(
    (tariffType, dayCount, autopayment, tariffCaption) =>
      dispatch(connectTariff(tariffType, dayCount, autopayment, tariffCaption)),
    [dispatch]
  );
  const tariffType = useSelector((state) => state.shop.tariff.tariffType);
  const dayCount = useSelector((state) => state.shop.tariff.dayCount);
  const autopayment = useSelector((state) => state.shop.tariff.autopayment);
  const tariffCaption = useSelector((state) => state.shop.tariff.tariffCaption);

  const handleUpdateMyProfile = useCallback(() => dispatch(updateMyProfile()), [dispatch]);

  const pageParam = props.pageParam;
  const isRoullete =
    pageParam !== null && pageParam.hasOwnProperty("isRoullete") ? pageParam.isRoullete : false;
  const needSum =
    pageParam !== null && pageParam.hasOwnProperty("needSum") ? pageParam.needSum : null;

  const balanceReplenishment = useSelector((state) =>
    state.shop.balanceReplenishment.payload ? state.shop.balanceReplenishment.payload : null
  );
  const url =
    balanceReplenishment !== null && balanceReplenishment.hasOwnProperty("url")
      ? balanceReplenishment.url
      : null;
  const count = useSelector((state) =>
    state.shop.payment.count !== null ? state.shop.payment.count : null
  );
  const countStr = count ? +count + " " + declOfNum(count, ["монета", "монеты", "монет"]) : "";

  const [visibleFrame, setVisibleFrame] = useState(true);

  useEffect(() => {
    window.addEventListener("message", msgHandler);
    return () => {
      window.removeEventListener("message", msgHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Если платеж прошел
  const handlePayment = (header, caption, icon, btnTitle, status) => {
    //console.log('iframe: ' + header);
    setVisibleFrame(false);
    window.removeEventListener("message", msgHandler);

    // Если идет пополнение баланса без дальнейшего подключения тарифа
    if (needSum === null) {
      props.openModal({
        id: MODAL_CARD_BALANCE_STATUS,
        param: {
          header,
          caption,
          icon,
          btnTitle,
        },
      });
    }

    if (status === "payment_success") {
      // Если идет пополнение недостающего баланса для подключения тарифа
      if (needSum !== null && !isRoullete) {
        handleConnectTariff(tariffType, dayCount, autopayment, tariffCaption);
      } else {
        handleUpdateMyProfile();
      }

      if (isRoullete) {
        // Если подключение автоплатежа от рулетки для выигрыша тарифа
        props.openModal({ id: MODAL_CARD_TARIFF_CONNECTED });
      }

      props.openView({ view: VIEW_SHOP, panel: PANEL_MAIN });
    } else {
      props.openView({ view: VIEW_SHOP, panel: PANEL_BALANCE_REPLENISHMENT });
    }
  };

  const msgHandler = useCallback((e) => {
    if (visibleFrame) {
      //console.log('data: ', e.data);
      switch (e.data) {
        case "payment_success":
          handlePayment(
            "Баланс пополнен!",
            "Ваш баланс был успешно пополнен на " + countStr,
            BalanceReplenishedIcon,
            "Ура!",
            "payment_success"
          );
          break;
        case "payment_fail":
          handlePayment(
            "Что-то пошло не так",
            "Проверьте правильность ввода реквизитов или баланс своего счета",
            SomethingWentWrongIcon,
            "Попбробовать еще раз",
            "payment_fail"
          );
          break;
        default:
          return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack} isVisibleBalance={false}>
        Пополнить баланс
      </PanelHeaderWithPath>
      <div style={{ background: "#fff", minHeight: "100%", position: "relative", zIndex: "100" }}>
        {visibleFrame && url ? (
          <iframe
            title="pay"
            frameBorder="0"
            src={url}
            width={"100%"}
            style={{
              height: document.documentElement.clientHeight - 62 + "px",
            }}
          ></iframe>
        ) : (
          ""
        )}
        {!visibleFrame && <ScreenSpinner size="large" />}
      </div>
    </Panel>
  );
};

export default withRouter(PanelBalanceIframe);
