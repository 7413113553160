import React from "react";
import DatePicker from "react-mobile-datepicker";
import styles from "./DatePickerMobile.module.css";

const DatePickerMobile = (props) => {
  return (
    <>
      <button className={styles.btn} onClick={() => props.handleIsOpen(true)}>
        {Object.prototype.toString.call(props.value) === "[object Date]" ? (
          ("0" + props.value.getDate()).slice(-2) +
          "/" +
          ("0" + (props.value.getMonth() + 1)).slice(-2) +
          "/" +
          (props.value.getFullYear() + " ")
        ) : (
          <span className={styles.text}> {props.placeholderText}</span>
        )}
      </button>

      <DatePicker
        value={
          Object.prototype.toString.call(props.value) !== "[object Date]" ? new Date() : props.value
        }
        theme="android"
        isOpen={props.isOpen}
        dateConfig={{
          date: {
            format: "DD",
            caption: "Day",
            step: 1,
          },
          month: {
            format: "MM",
            caption: "Mon",
            step: 1,
          },
          year: {
            format: "YYYY",
            caption: "Year",
            step: 1,
          },
        }}
        headerFormat="DD/MM/YYYY"
        cancelText="Отмена"
        confirmText="Ок"
        onSelect={(time) => props.handleSelectDate(time)}
        onCancel={() => props.handleIsOpen(false)}
      />
    </>
  );
};

DatePickerMobile.defaultProps = {
  placeholderText: "Дата",
};

export default DatePickerMobile;
