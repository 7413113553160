import React from "react";
import { ActionSheet, ActionSheetItem, Avatar, SimpleCell } from "@vkontakte/vkui";
import Icon16MoreVertical from "@vkontakte/icons/dist/16/more_vertical";
import { PANEL_PROFILE } from "views/profile/panels/constPanels";
import { VIEW_TOOL } from "views/constViews";

const WNOUsersItem = (props) => {
  const handleUserAction = () => {
    props.openPopout({
      popout: (
        <ActionSheet onClose={props.closePopout}>
          <ActionSheetItem
            autoclose
            onClick={() => {
              props.openView({
                view: VIEW_TOOL,
                panel: PANEL_PROFILE,
                param: { activeProfileId: props.id },
              });
            }}
          >
            Перейти к профилю в приложении
          </ActionSheetItem>
          <ActionSheetItem
            autoclose
            onClick={() => {
              window.open("http://vk.com/id" + props.id, "_blank");
            }}
          >
            Перейти к профилю в VK
          </ActionSheetItem>
        </ActionSheet>
      ),
    });
  };

  return (
    <SimpleCell
      disabled
      before={<Avatar size={48} src={props.photo} />}
      description={props.description}
      after={<Icon16MoreVertical fill="var(--text_secondary)" onClick={handleUserAction} />}
    >
      {props.title}
    </SimpleCell>
  );
};

export default WNOUsersItem;
