import React from "react";
import { Div, Link, Panel } from "@vkontakte/vkui";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { withRouter } from "hoc";

const UserAgreement = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Пользовательское соглашение</PanelHeaderWithPath>
      <Div>
        <p style={{ marginTop: "0px", textAlign: "center", fontSize: "18px" }}>
          Пользовательское соглашение об использовании приложения «МойКраш»
        </p>
        <p>
          Любое использование приложения «МойКраш» означает, что вы полностью прочитали, поняли и
          согласны с условиями данного пользовательского соглашения, обязуетесь их соблюдать и нести
          ответственность за их нарушение. Приложение содержит платный функционал.
        </p>
        <p style={{ textAlign: "center", fontWeight: "bold" }}>1. Общие положения</p>
        <p>
          Сайт – сайт, расположенный в сети Интернет по адресу{" "}
          <Link href={"https://vk.com"} target="_blank">
            http://vk.com/
          </Link>
          .
        </p>
        <p>
          Разработчик (Правообладатель) – индивидуальный предприниматель Моргунов Артем Юрьевич,
          зарегистрированный по адресу: 624260, Свердловская область, город Асбест, ул. Строителей,
          д. 24, кв. 56.
        </p>
        <p>
          Администрация Сайта – общество с ограниченной ответственностью «В Контакте», расположенное
          по адресу: 191024, Санкт-Петербург, ул. Херсонская, д. 12-14, литер А, помещение 1-Н, ОГРН
          1079847035179, ИНН 7842349892.
        </p>
        <p>
          Приложение – программный сервис, размещенный Разработчиком на Сайте в разделе
          «Приложения», прошедший модерацию Администрацией Сайта и включенный в каталог приложений.
        </p>
        <p>
          Страница Приложения – веб-страница Приложения на Сайте по сетевому адресу:
          <b> http://vk.com/app7520879</b>.
        </p>
        <p>
          Пользователь – пользователь Сайта, зарегистрированный в установленном порядке и
          использующий Приложение.
        </p>
        <p>
          Персональная страница– персональная страница пользователя Сайта ВКонтакте, которую
          администрация Сайта ВКонтакте предоставляет пользователю при регистрации. Доступ к
          Персональной странице осуществляется с помощью логина и пароля Персональной страницы. Если
          в качестве пользователя Сайта ВКонтакте зарегистрировано вымышленное лицо, Пользователем
          является лицо, которое использует Персональную страницу этого вымышленного лица для
          доступа к Приложению.
        </p>
        <p>
          Администрация – лица, действующиеот имени Правообладателя и уполномоченныеим на управление
          Приложением, осуществление контроля за соблюдением Пользователями положений
          Пользовательского соглашения, применение мер ответственности за нарушение
          Пользовательского соглашения и т.д.
        </p>
        <p>
          Модератор – лицо, уполномоченное Правообладателем на осуществление контроля за выполнением
          Пользователями Пользовательского соглашения.
        </p>
        <p>
          Сообщество – созданная Правообладателем на Сайте ВКонтакте группа «МойКраш», посвященная
          Приложению и размещенная по сетевому адресу:
          <Link href={"https://vk.com/moycrush"} target="_blank">
            {" "}
            http://vk.com/moycrush
          </Link>
          .
        </p>
        <p>
          Дополнительный функционал Приложения – программные коды, исполняющиеся на ЭВМ в составе
          Приложения, предназначенные для предоставления Пользователю дополнительных возможностей в
          Приложении.
        </p>
        <p>
          Монеты – программные коды, исполняющиеся на ЭВМ и выполняющие функцию универсальной
          условной единицы измерения объема прав использования Дополнительного функционала
          Приложения.
        </p>
        <p style={{ textAlign: "center", fontWeight: "bold" }}>2. Статус соглашения</p>
        <p>
          2.1. Настоящее соглашение между Правообладателем и Пользователем (далее вместе именуемые
          «Стороны») регулирует права и обязанности Правообладателя и Пользователя в связи с
          использованием Приложения.
        </p>
        <p>
          2.2. Положения Пользовательского соглашения являются публичной офертой в соответствии со
          ст. 437 Гражданского кодекса Российской Федерации. Пользователь обязан полностью
          ознакомиться с условиями соглашения до первого запуска Приложения. Запуск Приложения
          Пользователем означает полное и безоговорочное принятие Пользователем Пользовательского
          соглашения в соответствии со ст. 438 Гражданского кодекса Российской Федерации. Положения
          соглашения могут быть приняты только в целом без каких-либо изъятий.
        </p>
        <p>
          2.3. Пользователь гарантирует, что в соответствии с правом государства, гражданство
          которого он имеет, он:
        </p>
        <p>
          имеет право заключить Пользовательское соглашение самостоятельно или с согласия своих
          родителей или законных представителей;
        </p>
        <p>
          получил согласие своих родителей или законных представителей на заключение
          Пользовательского соглашения (если такое согласие необходимо);
        </p>
        <p>
          обладает дееспособностью, необходимой для осуществления прав и исполнения обязанностей,
          предусмотренных Пользовательским соглашением.
        </p>
        <p>
          2.4. Правообладатель имеет право в любое время изменить или дополнить Пользовательское
          соглашение (в том числе любое из приложений к Пользовательскому соглашению). Администрация
          публикует Пользовательское соглашение в новой редакции с указанием даты такого
          опубликования. Администрация обязана известить Пользователя об изменении или дополнении
          Пользовательского соглашения одним из следующих способов:
        </p>
        <p>
          опубликовать информацию об изменении или дополнении Пользовательского соглашения в
          Сообществе;
        </p>
        <p>
          опубликовать информацию об изменении или дополнении Пользовательского соглашения на
          Странице Приложения;
        </p>
        <p>
          поместить информацию об изменении или дополнении Пользовательского соглашения в
          Приложении;
        </p>
        <p>
          отправить Пользователю личное сообщение об изменении или дополнении Пользовательского
          соглашения.
        </p>
        <p>
          После получения извещения об изменении или дополнении Пользовательского соглашения
          Пользователь обязан незамедлительно ознакомиться с новой редакцией Пользовательского
          соглашения.
        </p>
        <p>
          Если Пользователь не согласен соблюдать Пользовательское соглашение в новой редакции,
          Пользователь обязан прекратить использование Приложения.Использование Приложения после
          извещения Пользователя об изменении или дополнении Пользовательского соглашения означает,
          что Пользователь ознакомился и согласен с условиями Пользовательского соглашения в новой
          редакции, а также обязуется добросовестно исполнять их.
        </p>
        <p style={{ textAlign: "center", fontWeight: "bold" }}>3. Использование Приложения</p>
        <p>
          3.1. Приложение предоставляется Пользователю «как есть». Правообладатель не дает гарантий
          того, что Приложение, включая Дополнительный функционал Приложения:
        </p>
        <p>будет функционировать непрерывно и без ошибок;</p>
        <p>будет соответствовать требованиям и ожиданиям Пользователя.</p>
        <p>
          3.2.Правообладатель не несет ответственности перед Пользователем за любой ущерб,
          нанесённый Пользователю (включая, помимо прочего, ущерб имуществу Пользователя и его
          имущественным правам) при использовании или невозможности использования Приложения, в том
          числе в связи с ограничением права Пользователя использовать Приложение или лишением
          Пользователя такого права.
        </p>
        <p>
          Правообладатель не несет ответственности перед Пользователем за любые особенности
          функционирования Сайта ВКонтакте (блокирование доступа, сбои и перерывы в работе Сайта
          ВКонтакте и т.д.) или действия администрации Сайта ВКонтакте.
        </p>
        <p>
          3.3. Пользователь самостоятельно несет ответственность за свои действия по использованию
          Приложения. Пользователь самостоятельно несет ответственность за соблюдение прав третьих
          лиц, применимого законодательства, Пользовательского соглашения, каких-либо правил и/или
          обязательных инструкций Правообладателя, размещенных в разделе «Помощь» Приложения, при
          использовании Приложения.
        </p>
        <p>
          3.4. Правообладатель предоставляет Пользователю право доступа к Приложению безвозмездно.
          Правообладатель предоставляет Пользователю возможность платно получить доступ к
          Дополнительному функционалу Приложения.
        </p>
        <p>
          Для приобретения доступа к Дополнительному функционалу Приложенияна определенный период
          времени Пользователь вносит оплату через доступные в Приложении платежные системы.
          Правообладатель предоставляет Пользователю в Приложении количество Монет, равное сумме
          внесенной оплаты в российских рублях.
        </p>
        <p>
          Пользователь подключает дополнительные возможности в Приложении в обмен на списание
          соответствующего количества Монет. Обязательство предоставить Пользователю доступ к
          дополнительному функционалу Приложения на определенный период считается исполненным
          Правообладателем в момент активации соответствующих опций Приложения.
        </p>
        <p>
          3.5. Правообладатель ни при каких обстоятельствам не возвращает Пользователю денежные
          средства, внесенные для получения Монет в Приложении (включая случаи, когда Пользователь
          не может воспользоваться Дополнительным функционалом Приложения в результате ограничения
          права Пользователя использовать Приложение или лишения его такого права, а также
          ограничения функциональности Приложения).
        </p>
        <p>
          3.6. Пользователь через интерфейс Приложения может подключить автоматическое пополнение
          количества Монет в Приложении. При подключении автоматического пополнения к учетной записи
          Пользователя привязывается банковская карта, указанная и подтвержденная Пользователем. При
          подключении автоматического пополнения количества Монет Пользователь выражает свое
          согласие на списание с привязанной банковской карты денежной суммы в размере, определенном
          Пользователем, либо недостающей для продления доступа к подключенным опциям, без
          отдельного распоряжения Пользователя об этом.
        </p>
        <p>
          Пользователь может отключить автоматическое пополнение количества Монет следуя инструкции
          в настройках Приложения.
        </p>
        <p>
          3.7. Пользователь через интерфейс Приложения может подключить автоматическое продление
          доступа к выбранному Дополнительному функционалу Приложения. При подключении
          автоматического продления доступа к учетной записи Пользователя привязывается банковская
          карта, указанная и подтвержденная Пользователем.При подключении автоматического продления
          доступа Пользователь выражает свое согласие на списание с привязанной банковской карты
          стоимости доступа к выбранным опциям за каждый последующий период доступа в дату окончания
          подключенного периода без отдельного распоряжения Пользователя об этом.
        </p>
        <p>
          Пользователь может отключить автоматическое продление доступа следуя инструкции в
          настройках Приложения.
        </p>
        <p>
          3.8. При подключении автоматического продления доступа Пользователь соглашается, что если
          в последний день оплаченного периода доступа к выбранным опциям у Пользователя
          недостаточно Монет в Приложении или денежных средств на привязанной банковской карте для
          продления доступа, то доступ к выбранным опциям прекращается, о чем Пользователь будет
          уведомлен в Приложении.
        </p>
        <p>
          3.9. Приложение является объектом авторских прав. Исключительные права на Приложение
          принадлежат Правообладателю. Правообладатель предоставляет Пользователю услугу по доступу
          к Приложению для использования Приложения по его прямому назначению.
        </p>
        <p>
          Любое использование Пользователем Приложения в иных целях (включая коммерческие)
          запрещено.
        </p>
        <p style={{ textAlign: "center", fontWeight: "bold" }}>4. Права и обязанности Сторон</p>
        <p>
          4.1. Пользователь обязан ознакомиться с информацией о Разработчике (Правообладателе),
          политикой конфиденциальности Разработчика (Правообладателя) и настоящим соглашением до
          первого запуска Приложения. При несогласии с положениями указанных документов Пользователь
          обязан воздержаться от запуска и использования Приложения.
        </p>
        <p>
          4.2. Пользователь обязуется использовать Приложение в личных некоммерческих целях.
          Запрещается предлагать услуги, связанные с использованием Приложения, другим Пользователям
          в целях извлечения прибыли. Запрещается совершать действия, направленные на нарушение
          нормального функционирования Приложения, и использовать специальные программы, содержащие
          вредоносные компоненты («вирусы»).
        </p>
        <p>
          4.3. Разработчик вправе в любое время вносить изменения в функционал Приложения, интерфейс
          и/или содержание Приложения с уведомлением Пользователей или без такового.
        </p>
        <p>
          4.4. Правообладатель вправе в одностороннем порядке устанавливать стоимость отдельных
          сервисов, предлагаемых Приложением. Пользователь обязуется добросовестно использовать
          способы пополнения личного счета Пользователя в Приложении и дальнейшего использования
          средств. При обнаружении Администрацией фактов неправомерного пополнения личного счета
          Пользователя в Приложении Администрация вправе отказать Пользователю в дальнейшем
          предоставлении услуг либо в одностороннем порядке уменьшить личный счет Пользователя в
          Приложении.
        </p>
        <p>
          4.5. Правообладатель вправе запросить и использовать информацию о Пользователе
          исключительно в целях предоставления услуг по использованию Приложения. Использование
          информации о Пользователе регулируется политикой конфиденциальности Правообладателя.
        </p>
        <p>
          4.6. Правообладатель обязан обеспечить техническую поддержку Приложения и предоставить
          простой способ связи для обращений Пользователей по всем возникающим в процессе
          использования Приложения вопросам в разделе «Помощь» и в настройках Приложения, во вкладке
          «Информация о разработчике».
        </p>
        <p>
          4.7. Пользователь вправе обращаться к Модератору и Администрации с запросами. Запросы
          Пользователя направляются путём размещения сообщения в беседе на соответствующую тему в
          Сообществе (при направлении запроса иным способом Модератор и Администрация имеют право не
          отвечать на запрос Пользователя). Пользователь имеет право направлять запросы в отношении:
        </p>
        <p>толкования и исполнения Пользовательского соглашения;</p>
        <p>
          применения к Пользователю мер ответственности за нарушение Пользовательского соглашения.
          При направлении такого запроса Пользователь должен указать дату и причину применения к
          нему соответствующих мер ответственности;
        </p>
        <p>конкурсов и акций, проводимых Администрацией;</p>
        <p>
          неправильного функционирования Приложения (технические неполадки, сбои функционала
          Приложения и т.д.), если такое неправильное функционирование не зависит от действий
          Пользователя, особенностей используемого Пользователем оборудования и программного
          обеспечения, качества Интернет-соединения, функционирования Сайта ВКонтакте и иных
          обстоятельств, за которые Правообладатель и Администрация ответственности не несут.
        </p>
        <p>4.8. Пользователю запрещается:</p>
        <p>
          исследовать исходный код Приложения, вносить в него изменения и дополнения, создавать на
          его основе производные произведения;
        </p>
        <p>использовать для доступа к Приложению Персональную страницу другого Пользователя;</p>
        <p>
          вводить Правообладателя, Администрацию и Модераторов в заблуждение (в том числе с целью
          получения выгоды);
        </p>
        <p>
          предоставлять третьим лицам возможность доступа к Приложению с использованием Персональной
          страницы Пользователя;
        </p>
        <p>
          использовать Приложение любым способом, который мешает или может помешать нормальному
          функционированию Приложения;
        </p>
        <p>
          использовать любые компьютерные программы и технические средства, препятствующие
          нормальному функционированию Приложения;
        </p>
        <p>
          использовать дефекты функционирования Приложения. Дефектом функционирования Приложения
          является особенность функционирования Приложения, не предусмотренная Разработчиком и не
          соответствующая нормальному функционированию Приложения.
        </p>
        <p>
          4.9. Правообладатель вправе ограничивать функциональность Приложения полностью или
          частично по собственному усмотрению (в том числе удалить Приложение с Сайта).
          Правообладатель и Администрация не обязаны предупреждать Пользователя об ограничении
          функциональности Приложения и не несут ответственности за вред, причиненный Пользователю в
          результате ограничения функциональности Приложения.
        </p>
        <p>
          4.10. Если Пользователь нарушает положения Пользовательского соглашения, Администрация
          может применить к Пользователю следующие меры ответственности:
        </p>
        <p>лишить Пользователя права использовать Приложение;</p>
        <p>
          вынести Пользователю предупреждение о недопустимости нарушения Пользовательского
          соглашения.
        </p>
        <p>
          Администрация определяет меру ответственности Пользователя по собственному усмотрению
          исходя из характера нарушения.Администрация не обязана предупреждать Пользователя о
          лишении его права использовать Приложение. Правообладатель и Администрация не несут
          ответственности за вред, причинённый Пользователю в результате лишения Пользователя права
          использовать Приложении.
        </p>
        <p>
          Администрация сообщает Пользователю о причине применения к нему мер ответственности за
          нарушение Пользовательского соглашения.
        </p>
        <p>
          4.11. Правообладатель и Администрация вправе сообщить правоохранительным органам о фактах
          нарушения Пользователем законодательства и передать правоохранительным органам все
          имеющиеся сведения о противоправных действиях Пользователя.
        </p>
        <p style={{ textAlign: "center", fontWeight: "bold" }}>5. Заключительные положения</p>
        <p>
          5.1. Пользователь вправе в любой момент отказаться от предоставления услуг посредством
          удаления Приложения со своей персональной страницы на Сайте.
        </p>
        <p>
          5.2. Правообладатель вправе в любой момент приостанавливать или прекращать
          функционирование Приложения с уведомлением Пользователей или без такового.
        </p>
        <p>
          5.3. Пользовательское соглашение регулируется и толкуется в соответствии с
          законодательством Российской Федерации. Вопросы, не урегулированные настоящим соглашением,
          подлежат разрешению в соответствии с законодательством Российской Федерации.
        </p>
        <p>
          5.4. В случае возникновения любых споров или разногласий, связанных с исполнением
          Пользовательского соглашения, Правообладатель и Пользователь приложат все усилия для их
          разрешения путем проведения переговоров между ними. В случае, если споры не будут
          разрешены путем переговоров, споры подлежат разрешению в порядке, установленном
          действующим законодательством Российской Федерации.
        </p>
        <p>
          5.5. Если по тем или иным причинам одно или несколько положений настоящего соглашения
          будут признаны недействительными или не имеющими юридической силы, это не оказывает
          влияния на действительность или применимость остальных положений соглашения.
        </p>
      </Div>
    </Panel>
  );
};

export default withRouter(UserAgreement);
