import { APIEye3GetAppFriendIdTool, APIEye3GetAppFriendsTool, APIEye3GetAppTool } from "api/eye3";
import { delayMethodGetUsersVKApi } from "api";
import {
  FRIENDS_IN_APP_POPULAR_REQUEST,
  FRIENDS_IN_APP_POPULAR_PROGRESS,
  FRIENDS_IN_APP_POPULAR_SHOW_MORE,
  FRIENDS_IN_APP_POPULAR_SUCCESS,
  FRIENDS_IN_APP_POPULAR_ERROR,
  FRIENDS_IN_APP__APPS_FROM_FRIENDS_REQUEST,
  FRIENDS_IN_APP__APPS_FROM_FRIENDS_PROGRESS,
  FRIENDS_IN_APP__APPS_FROM_FRIENDS_SUCCESS,
  FRIENDS_IN_APP__APPS_FROM_FRIENDS_ERROR,
  FRIENDS_IN_APP__APPS_FROM_FRIENDS_SHOW_MORE,
  FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_REQUEST,
  FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_PROGRESS,
  FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_SUCCESS,
  FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_ERROR,
  FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_SHOW_MORE,
  FRIENDS_IN_APP__INFO_REQUEST,
  FRIENDS_IN_APP__INFO_PROGRESS,
  FRIENDS_IN_APP__INFO_SUCCESS,
  FRIENDS_IN_APP__INFO_ERROR,
  FRIENDS_IN_APP__INFO_SHOW_MORE,
  FRIENDS_IN_APP_POPULAR_CANCEL,
  FRIENDS_IN_APP__APPS_FROM_FRIENDS_CANCEL,
  FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_CANCEL,
  FRIENDS_IN_APP__INFO_CANCEL,
} from "../../constType";
import { openModal } from "../page";

import { delay } from "helpers";
import { APIVkGetApps } from "api/vk/apiMethods";
import { checkEYE3ApiError, defaultErrorProperty } from "api/eye3/responseHandler";

export function fetchPopularFriendsInApp() {
  return async (dispatch, getState) => {
    try {
      const state = getState().toolFriendsInApp;

      if (state.popularApp.loading) return;

      dispatch(request());

      async function process() {
        try {
          if (!getState().toolFriendsInApp.popularApp.loading) return;

          const friendsInAppPopular = await APIEye3GetAppTool();
          let result = [];

          // Проверка ошибок в API
          const errorAPI = checkEYE3ApiError(friendsInAppPopular);
          if (errorAPI !== false) {
            errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
            dispatch(error({ ...errorAPI }));
            return;
          }

          if (
            friendsInAppPopular.hasOwnProperty("result") &&
            friendsInAppPopular.result.length !== 0
          ) {
            result = friendsInAppPopular.result.map((app) => {
              return {
                id: app.id,
                title: app.title,
                photo_url: app.photo_url,
                friend_count: app.friend_count,
              };
            });
          }

          if (friendsInAppPopular.status_task === "work") {
            dispatch(progress(friendsInAppPopular.percent, result));
            await delay(2000);
            await process();
          } else if (friendsInAppPopular.status_task === "success") {
            dispatch(success(result));
          } else if (friendsInAppPopular.status_task === "error") {
            dispatch(error({ ...defaultErrorProperty, message: "" }));
          }
        } catch (e) {
          dispatch(error({ ...defaultErrorProperty, message: e }));
        }
      }

      await process();
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: FRIENDS_IN_APP_POPULAR_REQUEST };
  }

  function progress(progressValue, payload) {
    return { type: FRIENDS_IN_APP_POPULAR_PROGRESS, progressValue, payload };
  }

  function success(payload) {
    return { type: FRIENDS_IN_APP_POPULAR_SUCCESS, payload };
  }

  function error(error) {
    return { type: FRIENDS_IN_APP_POPULAR_ERROR, error };
  }
}

export function showMorePopularFriendsInApp() {
  return { type: FRIENDS_IN_APP_POPULAR_SHOW_MORE };
}

export function cancelPopularFriendsInApp() {
  return { type: FRIENDS_IN_APP_POPULAR_CANCEL };
}

export function fetchAppsFromFriends() {
  return async (dispatch, getState) => {
    try {
      const state = getState().toolFriendsInApp;

      if (state.appsFromFriends.loading) return;

      dispatch(request());

      async function process() {
        try {
          if (!getState().toolFriendsInApp.appsFromFriends.loading) return;

          const friendsInAppFromFriends = await APIEye3GetAppFriendsTool();
          //console.log('friendsInAppFromFriends: ', friendsInAppFromFriends);
          let result = [];

          // Проверка ошибок в API
          const errorAPI = checkEYE3ApiError(friendsInAppFromFriends);
          if (errorAPI !== false) {
            errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
            dispatch(error({ ...errorAPI }));
            return;
          }

          if (
            friendsInAppFromFriends.hasOwnProperty("result") &&
            friendsInAppFromFriends.result.length !== 0
          ) {
            const friendsInAppFromFriendsInfo = await delayMethodGetUsersVKApi({
              userIds: friendsInAppFromFriends.result.map((user) => user.user_id),
              fields: ["photo_100", "bdate", "city", "country"],
            });

            const friendsInAppFromFriendsItems = friendsInAppFromFriendsInfo;

            friendsInAppFromFriends.result.forEach((user) => {
              friendsInAppFromFriendsItems.forEach((userInfo) => {
                if (user.user_id === userInfo.id) {
                  result.push({
                    vk_id: userInfo.id,
                    count: user.count,
                    name: userInfo.first_name + " " + userInfo.last_name,
                    photo_url: userInfo.photo_100 ? userInfo.photo_100 : "", // Фото
                  });
                }
              });
            });
          }

          if (friendsInAppFromFriends.status_task === "work") {
            dispatch(progress(friendsInAppFromFriends.percent, result));
            await delay(2000);
            await process();
          } else if (friendsInAppFromFriends.status_task === "success") {
            dispatch(success(result));
          } else if (friendsInAppFromFriends.status_task === "error") {
            dispatch(error({ ...defaultErrorProperty, message: "" }));
          }
        } catch (e) {
          dispatch(error({ ...defaultErrorProperty, message: e }));
        }
      }

      await process();
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: FRIENDS_IN_APP__APPS_FROM_FRIENDS_REQUEST };
  }

  function progress(progressValue, payload) {
    return { type: FRIENDS_IN_APP__APPS_FROM_FRIENDS_PROGRESS, progressValue, payload };
  }

  function success(payload) {
    return { type: FRIENDS_IN_APP__APPS_FROM_FRIENDS_SUCCESS, payload };
  }

  function error(error) {
    return { type: FRIENDS_IN_APP__APPS_FROM_FRIENDS_ERROR, error };
  }
}

export function showMoreAppsFromFriends() {
  return { type: FRIENDS_IN_APP__APPS_FROM_FRIENDS_SHOW_MORE };
}

export function cancelAppsFromFriends() {
  return { type: FRIENDS_IN_APP__APPS_FROM_FRIENDS_CANCEL };
}

export function fetchAppsFromFriendsInfo() {
  return async (dispatch, getState) => {
    try {
      const state = getState().toolFriendsInApp;
      const pageParam = getState().page.param;

      if (state.appsFromFriendsInfo.loading) return;

      const id = pageParam ? pageParam.activeUserId : state.appsFromFriendsInfo.activeUserId;
      const title = pageParam
        ? pageParam.activeUserTitle
        : state.appsFromFriendsInfo.activeUserTitle;
      const description = pageParam
        ? pageParam.activeUserDescription
        : state.appsFromFriendsInfo.activeUserDescription;
      const photo = pageParam
        ? pageParam.activeUserPhoto
        : state.appsFromFriendsInfo.activeUserPhoto;

      dispatch(request(id, title, description, photo));

      async function process() {
        try {
          if (!getState().toolFriendsInApp.appsFromFriendsInfo.loading) return;

          const FIAById = await APIEye3GetAppFriendIdTool({ userId: id });
          //console.log('FIAById: ', FIAById);
          let result = [];

          // Проверка ошибок в API
          const errorAPI = checkEYE3ApiError(FIAById);
          if (errorAPI !== false) {
            errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
            dispatch(error({ ...errorAPI }));
            return;
          }

          if (FIAById.hasOwnProperty("result") && FIAById.result.length !== 0) {
            result = FIAById.result.map((app) => {
              return {
                app_id: app.id,
                title: app.title,
                photo_url: app.photo_url,
                friend_count: app.friend_count,
              };
            });
          }

          if (FIAById.status_task === "work") {
            dispatch(progress(FIAById.percent, result));
            await delay(2000);
            await process();
          } else if (FIAById.status_task === "success") {
            dispatch(success(result));
          } else if (FIAById.status_task === "error") {
            dispatch(error({ ...defaultErrorProperty, message: "" }));
          }
        } catch (e) {
          dispatch(error({ ...defaultErrorProperty, message: e }));
        }
      }

      await process();
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(id, title, description, photo) {
    return { type: FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_REQUEST, id, title, description, photo };
  }

  function progress(progressValue, payload) {
    return { type: FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_PROGRESS, progressValue, payload };
  }

  function success(payload) {
    return { type: FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_SUCCESS, payload };
  }

  function error(error) {
    return { type: FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_ERROR, error };
  }
}

export function showMoreAppsFromFriendsInfo() {
  return { type: FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_SHOW_MORE };
}

export function cancelAppsFromFriendsInfo() {
  return { type: FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_CANCEL };
}

export function fetchInfoApp() {
  return async (dispatch, getState) => {
    try {
      const state = getState().toolFriendsInApp;
      const pageParam = getState().page.param;

      if (state.appsInfo.loading) return;

      const id = pageParam ? pageParam.activeAppId : state.appsInfo.activeAppId;
      dispatch(request(id));

      let result = {
        app: {},
        friends: [],
      };

      const apps = await APIVkGetApps({
        appId: id,
        fields: ["photo_100"].join(","),
        returnFriends: 1,
      });

      //console.log('apps: ', apps);

      const appsItems = apps.response.hasOwnProperty("items") ? apps.response.items : apps.response;
      const appsProfiles = apps.response.hasOwnProperty("profiles") ? apps.response.profiles : [];

      result.app = appsItems.length !== 0 ? appsItems[0] : {};

      async function process() {
        try {
          if (!getState().toolFriendsInApp.appsInfo.loading) return;

          const friendsInAppFromFriends = await APIEye3GetAppFriendsTool();
          //console.log('friendsInAppFromFriends: ', friendsInAppFromFriends);

          // Проверка ошибок в API
          const errorAPI = checkEYE3ApiError(friendsInAppFromFriends);
          if (errorAPI !== false) {
            errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
            dispatch(error({ ...errorAPI }));
            return;
          }

          if (
            friendsInAppFromFriends.hasOwnProperty("result") &&
            friendsInAppFromFriends.result.length !== 0
          ) {
            const resAppFromFriends = friendsInAppFromFriends.result;

            /*console.log('appsProfiles: ', appsProfiles);
                        console.log('resAppFromFriends: ', resAppFromFriends);*/

            for (let i = 0; i < appsProfiles.length; i++) {
              for (let j = 0; j < resAppFromFriends.length; j++) {
                if (appsProfiles[i].id === resAppFromFriends[j].user_id) {
                  result.friends.push({
                    vk_id: appsProfiles[i].id,
                    count: resAppFromFriends[j].count,
                    name: appsProfiles[i].first_name + " " + appsProfiles[i].last_name,
                    photo_url: appsProfiles[i].photo_100 ? appsProfiles[i].photo_100 : "", // Фото
                  });

                  break;
                }
              }
            }
            /*const friendsInAppFromFriendsInfo = await delayMethodGetUsersVKApi({
                            userIds: friendsInAppFromFriends.result.map((user) => user.user_id),
                            fields: ['photo_100', 'bdate', 'city', 'country']
                        });
            
                        const friendsInAppFromFriendsItems = friendsInAppFromFriendsInfo;

                        friendsInAppFromFriends.result.forEach(user => {
                            friendsInAppFromFriendsItems.forEach((userInfo) => {
                                if (user.user_id === userInfo.id) {
                                    result.friends.push({
                                        vk_id: userInfo.id,
                                        count: user.count,
                                        name: userInfo.first_name + ' ' + userInfo.last_name,
                                        photo_url: userInfo.photo_100 ? userInfo.photo_100 : '', // Фото
                                    });
                                }
                            });
                        });*/
          }

          if (friendsInAppFromFriends.status_task === "work") {
            dispatch(progress(friendsInAppFromFriends.percent, result));
            await delay(2000);
            await process();
          } else if (friendsInAppFromFriends.status_task === "success") {
            dispatch(success(result));
          } else if (friendsInAppFromFriends.status_task === "error") {
            dispatch(error({ ...defaultErrorProperty, message: "" }));
          }
        } catch (e) {
          dispatch(error({ ...defaultErrorProperty, message: e }));
        }
      }

      await process();

      /*const apps = await APIVkGetApps({
                appId: id,
                fields: ['photo_100', 'bdate', 'city', 'country']
            });
            console.log('apps: ', apps);

            const appsItems = apps.response.hasOwnProperty('items') ? 
            apps.response.items : apps.response;
            const appsProfiles = apps.response.hasOwnProperty('profiles') ? 
            apps.response.profiles : [];

            result.app = appsItems.length !== 0 ? appsItems[0] : {};

            appsProfiles.forEach((profile) => {
                result.friends.push({
                    vk_id: profile.id,
                    name: profile.first_name + ' ' + profile.last_name,
                    age: profile.bdate ? calcAge(profile.bdate) : null, // Расчет возраста по дате рождения
                    city: profile.city ? profile.city.title : null, // Город
                    city_id: profile.city ? profile.city.id.toString() : null, // Город id
                    photo_url: profile.photo_100 ? profile.photo_100 : '', // Фото
                    country: profile.country ? profile.country.title : null, // Страна
                    country_id: profile.country ? profile.country.id.toString() : null, // Страна id
                });
            });

            console.log('result: ', result);

            dispatch(success(result));*/
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(id) {
    return { type: FRIENDS_IN_APP__INFO_REQUEST, id };
  }

  function progress(progressValue, payload) {
    return { type: FRIENDS_IN_APP__INFO_PROGRESS, progressValue, payload };
  }

  function success(payload) {
    return { type: FRIENDS_IN_APP__INFO_SUCCESS, payload };
  }

  function error(error) {
    return { type: FRIENDS_IN_APP__INFO_ERROR, error };
  }
}

export function showMoreInfoApp() {
  return { type: FRIENDS_IN_APP__INFO_SHOW_MORE };
}

export function cancelInfoApp() {
  return { type: FRIENDS_IN_APP__INFO_CANCEL };
}
