import React from "react";

import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";

import { Panel } from "@vkontakte/vkui";
import WhoAddedChsList from "./components/WACList/WACList";
import ChsIcon from "assets/img/tool/whoAddedChs/banner/chs.png";
import ToolBanner from "template/components/ToolBanner/ToolBanner";
import { withRouter } from "hoc";

const PanelWhoAddedChs = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Кто в ЧС</PanelHeaderWithPath>
      <ToolBanner
        tool="toolWhoAddedChs"
        icon={ChsIcon}
        text="Узнай кто добавил тебя в черный список!"
      />
      <WhoAddedChsList openView={props.openView} />
    </Panel>
  );
};

export default withRouter(PanelWhoAddedChs);
