import {
  ADMIN_COMMUNITY_REQUEST,
  ADMIN_COMMUNITY_SUCCESS,
  ADMIN_COMMUNITY_ERROR,
  ADMIN_COMMUNITY_SHOW_MORE,
  ADMIN_COMMUNITY_SEARCH_REQUEST,
  ADMIN_COMMUNITY_SEARCH_SUCCESS,
  ADMIN_COMMUNITY_SEARCH_ERROR,
  ADMIN_COMMUNITY_FILTER_REQUEST,
  ADMIN_COMMUNITY_FILTER_SUCCESS,
  ADMIN_COMMUNITY_FILTER_ERROR,
  ADMIN_COMMUNITY__INFO_GROUP_REQUEST,
  ADMIN_COMMUNITY__INFO_GROUP_SUCCESS,
  ADMIN_COMMUNITY__INFO_GROUP_ERROR,
} from "../../constType";

import { APIVkGetByIdGroups } from "api/vk/apiMethods";

import { APIEye3GetAdminGroupsTool } from "api/eye3";

import { getSearchFilterFriendsV2 } from "helpers/actions";
import { openModal } from "../page";
import { checkEYE3ApiError, defaultErrorProperty } from "api/eye3/responseHandler";

export function fetchAdminCommunity() {
  return async (dispatch, getState) => {
    try {
      dispatch(request());

      const state = getState().toolAdminCommunity;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.friends.search,
        filter: state.friends.filter,
        cache: state.friends.cachePayload,
      });

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: ADMIN_COMMUNITY_REQUEST };
  }

  function success(payload) {
    return { type: ADMIN_COMMUNITY_SUCCESS, payload };
  }

  function error(error) {
    return { type: ADMIN_COMMUNITY_ERROR, error };
  }
}

export function showMoreAdminCommunity() {
  return { type: ADMIN_COMMUNITY_SHOW_MORE };
}

export function searchAdminCommunity(search = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(request(search));
      const state = getState().toolAdminCommunity;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.friends.search,
        filter: state.friends.filter,
        cache: state.friends.cachePayload,
      });

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(search) {
    return { type: ADMIN_COMMUNITY_SEARCH_REQUEST, search };
  }

  function success(payload) {
    return { type: ADMIN_COMMUNITY_SEARCH_SUCCESS, payload };
  }

  function error(error) {
    return { type: ADMIN_COMMUNITY_SEARCH_ERROR, error };
  }
}

export function filterAdminCommunity(filter = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(request(filter));
      const state = getState().toolAdminCommunity;
      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.friends.search,
        filter: state.friends.filter,
        cache: state.friends.cachePayload,
      });

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(filter) {
    return { type: ADMIN_COMMUNITY_FILTER_REQUEST, filter };
  }

  function success(payload) {
    return { type: ADMIN_COMMUNITY_FILTER_SUCCESS, payload };
  }

  function error(error) {
    return { type: ADMIN_COMMUNITY_FILTER_ERROR, error };
  }
}

export function fetchInfoGroup() {
  return async (dispatch, getState) => {
    try {
      const state = getState().toolAdminCommunity;
      const pageParam = getState().page.param;

      if (pageParam && pageParam.activeUserId === state.groups.activeUserId)
        // Если id равны то не перезагружать
        return false;

      // Задаем pageParam в зависимости от его наличия (когда загружен новый пользователь при изменении периода используются значения из state.groups)
      const id = pageParam ? pageParam.activeUserId : state.groups.activeUserId;
      const title = pageParam ? pageParam.activeUserTitle : state.groups.activeUserTitle;
      const description = pageParam
        ? pageParam.activeUserDescription
        : state.groups.activeUserDescription;
      const photo = pageParam ? pageParam.activeUserPhoto : state.groups.activeUserPhoto;

      dispatch(request(id, title, description, photo));

      let result = [];
      const adminGroup = await APIEye3GetAdminGroupsTool({ userId: id });
      //console.log(adminGroup);

      // Проверка ошибок в API
      const errorAPI = checkEYE3ApiError(adminGroup);
      if (errorAPI !== false) {
        errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
        dispatch(error({ ...errorAPI }));
        return;
      }

      if (adminGroup.groups.length === 0) {
        dispatch(success(result));
        return;
      }

      const adminGroupsJoinIds = adminGroup.groups.map((group) => group).join(",");

      const groups = await APIVkGetByIdGroups({
        groupIds: adminGroupsJoinIds,
        fields: ["members_count"].join(","),
      });
      //console.log(groups.response);

      groups.response.forEach((group) => {
        result.push({
          vk_id: group.id,
          name: group.name,
          photo_url: group.photo_100,
          members_count: group.members_count,
        });
      });

      dispatch(success(result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(id, title, description, photo) {
    return { type: ADMIN_COMMUNITY__INFO_GROUP_REQUEST, id, title, description, photo };
  }

  function success(payload) {
    return { type: ADMIN_COMMUNITY__INFO_GROUP_SUCCESS, payload };
  }

  function error(error) {
    return { type: ADMIN_COMMUNITY__INFO_GROUP_ERROR, error };
  }
}
