import React from "react";
import { ActionSheet, ActionSheetItem, Avatar, SimpleCell } from "@vkontakte/vkui";
import Icon16MoreVertical from "@vkontakte/icons/dist/16/more_vertical";

const DFUserItem = (props) => {
  const handleUserAction = () => {
    props.openPopout({
      popout: (
        <ActionSheet onClose={props.closePopout}>
          <ActionSheetItem
            autoclose
            onClick={() => {
              window.open("http://vk.com/id" + props.id, "_blank");
            }}
          >
            Перейти на страницу ВК
          </ActionSheetItem>
        </ActionSheet>
      ),
    });
  };

  return (
    <SimpleCell
      id={props.id}
      disabled
      before={<Avatar size={48} src={props.photo} />}
      description={props.description}
      after={<Icon16MoreVertical fill="var(--text_secondary)" onClick={handleUserAction} />}
    >
      {props.title}
    </SimpleCell>
  );
};

export default DFUserItem;
