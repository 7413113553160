import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";

import InfoList from "./components/InfoHistoryList/InfoHistoryList";
import { Panel } from "@vkontakte/vkui";
import { withRouter } from "hoc";

const PanelInfoHistory = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Профиль</PanelHeaderWithPath>
      <InfoList />
    </Panel>
  );
};

export default withRouter(PanelInfoHistory);
