import { isEye3VkMethodError } from "api";
import { APIEye3VkApiUser } from "api/eye3";

async function APIVkGetCountriesDatabase({ needAll = 1, count = 234 } = {}) {
  const method = "database.getCountries";
  try {
    /*const response = await bridge.send("VKWebAppCallAPIMethod", {
      method: method,
      params: {
        need_all: needAll,
        count: count,
        v: getVKApiVersion(),
        access_token: getVKToken(),
      },
    });

    responseHandler(method, response);*/
    const citiesAPI = await APIEye3VkApiUser({
      method: method,
      params: {
        need_all: needAll,
        count: count,
      },
    });

    if (isEye3VkMethodError(citiesAPI)) {
      throw JSON.stringify(citiesAPI);
    }

    return citiesAPI.result;
  } catch (e) {
    // errorHandler(method, e);
    throw e;
  }
}

export default APIVkGetCountriesDatabase;
