import { queryEye3 } from "api";

async function APIEye3WithdrawToCoinPartner({ sum }) {
  try {
    const response = await queryEye3({
      method: "partner.withdraw_to_coin",
      get: { sum: sum },
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3WithdrawToCoinPartner;
