import React, { useCallback } from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Avatar, Panel, Placeholder, SimpleCell } from "@vkontakte/vkui";
import HeadTitle from "template/components/HeadTitle/HeadTitle";
import { useDispatch, useSelector } from "react-redux";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import { Icon16Chevron } from "@vkontakte/icons";
import { VIEW_TOOL } from "views/constViews";
import { PANEL_PROFILE } from "views/profile/panels/constPanels";
import { declOfNum } from "helpers";
import { showMoreGeoSearch } from "store/actions/tool/maritalStatus";
import { useShowMore } from "hooks";
import SearchIcon from "assets/img/tool/maritalStatus/search.png";
import { withRouter } from "hoc";

const PanelMSGeoSearch = (props) => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const handleShowMoreGeoSearch = useCallback(() => dispatch(showMoreGeoSearch()), [dispatch]);
  const geoSearch = useSelector((state) => state.toolMaritalStatus.geoSearch.payload);
  const loading = useSelector((state) => state.toolMaritalStatus.geoSearch.loading);
  const showCount = useSelector((state) => state.toolMaritalStatus.geoSearch.showCount);
  const error = useSelector((state) => state.toolMaritalStatus.geoSearch.error);

  useShowMore({
    items: geoSearch,
    showCount: showCount,
    maxLength: geoSearch !== null ? geoSearch.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMoreGeoSearch,
  });

  const renderGeoSearch = () => {
    return geoSearch.length > 0 ? (
      geoSearch.map((item, i) => {
        const id = item.vk_id;
        const name = item.name;
        const city = item.city ? item.city : "";
        const age = item.age ? item.age : "";
        const photo = item.photo_url ? item.photo_url : "";
        const ageStrFormat = age ? age + " " + declOfNum(age, ["год", "года", "лет"]) : "";
        const comma = ageStrFormat && city ? ", " : "";

        if (i < showCount) {
          return (
            <SimpleCell
              key={i}
              before={<Avatar size={48} src={photo} />}
              after={<Icon16Chevron fill="#B8C1CC" />}
              description={ageStrFormat + comma + city}
              onClick={() =>
                props.openView({
                  view: VIEW_TOOL,
                  panel: PANEL_PROFILE,
                  param: { activeProfileId: id },
                })
              }
            >
              {name}
            </SimpleCell>
          );
        }

        return false;
      })
    ) : (
      <Placeholder icon={<img src={SearchIcon} width={50} height={50} alt="" />}>
        По заданным критериям не найдено ни одного пользователя
      </Placeholder>
    );
  };

  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Семейное положение</PanelHeaderWithPath>
      <div style={{ marginBottom: "20px" }}>
        <HeadTitle>Результаты поиска</HeadTitle>
        {geoSearch !== null && error === null ? renderGeoSearch() : ""}
        {renderLoader({
          loading: loading,
        })}
        {renderErrorPlaceholder({
          loading: loading,
          error: error,
        })}
      </div>
    </Panel>
  );
};

export default withRouter(PanelMSGeoSearch);
