import React from "react";
import styles from "./HoroscopeСompatibility.module.css";

const HoroscopeСompatibility = (props) => {
  return (
    <div className={styles.container}>
      <span className={styles.topText}>Совместимость</span>
      <div className={styles.bottomText}>{props.sum} %</div>
    </div>
  );
};

HoroscopeСompatibility.defaultProps = {
  sum: 0,
};

export default HoroscopeСompatibility;
