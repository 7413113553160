import React from "react";
import { ModalPage, ModalPageHeader, List, Avatar, SimpleCell } from "@vkontakte/vkui";

import { declOfNum } from "helpers";

const MutualCommunity = (props) => {
  const modalParam = props.modalParam;
  const mutualCommunity = modalParam !== null ? modalParam.community : [];
  const commonStrFormat = declOfNum(mutualCommunity.length, ["общее", "общих", "общих"]);
  const communityStrFormat = declOfNum(mutualCommunity.length, [
    "сообщество",
    "сообщества",
    "сообществ",
  ]);

  const renderMutualCommunity = () => {
    return mutualCommunity.map((community, i) => {
      const name = community.name;
      const photo = community.photo_url;

      return (
        <SimpleCell key={i} disabled before={<Avatar size={40} src={photo} />}>
          {name}
        </SimpleCell>
      );
    });
  };

  return (
    <ModalPage
      id={props.id}
      header={
        <ModalPageHeader>
          <span
            style={{
              fontWeight: "500",
              fontSize: "13px",
              lineHeight: "16px",
              letterSpacing: "0.2px",
              textTransform: "uppercase",
              color: "#818C99",
            }}
          >
            {mutualCommunity.length + " " + commonStrFormat + " " + communityStrFormat}
          </span>
        </ModalPageHeader>
      }
      settlingHeight={80}
    >
      <List>{mutualCommunity ? renderMutualCommunity() : ""}</List>
    </ModalPage>
  );
};

export default MutualCommunity;
