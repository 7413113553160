import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, TabsItem, Placeholder } from "@vkontakte/vkui";
import FanItem from "../FanItem/FanItem";
import Icon56UsersOutline from "@vkontakte/icons/dist/56/users_outline";

import { renderPeriodV2 } from "template/popouts/DateChangePeriod/views";
import { PANEL_FAN } from "../../../constPanels";
import PopoutTemplateDateChangePeriod from "template/popouts/DateChangePeriod/DateChangePeriod";
import { TABS_MALE, TABS_FEMALE, TABS_ALL } from "../../constTabs";

import { fetchFan, tabsFan, setPeriodFan } from "store/actions/tool/fan";
import { FAN_COMMENT, FAN_GUEST, FAN_LIKE } from "views/tool/panels/fan/constFan";
import { VIEW_TOOL } from "views/constViews";
import { renderErrorPlaceholder } from "helpers/views";
import LoaderWithProgressBar from "template/components/LoaderWithProgressBar/LoaderWithProgressBar";

const FanList = (props) => {
  const dispatch = useDispatch();
  const topGuest = useSelector((state) => state.toolFan.guests.topValue);
  const topLike = useSelector((state) => state.toolFan.likes.topValue);
  const topComment = useSelector((state) => state.toolFan.comments.topValue);
  const loading = useSelector((state) => state.toolFan.loading);
  const tabs = useSelector((state) => state.toolFan.tabs);
  const error = useSelector((state) => state.toolFan.error);
  const period = useSelector((state) => state.toolFan.period);
  const progressValue = useSelector((state) => state.toolFan.progressValue);

  const handleFetchFan = useCallback(() => dispatch(fetchFan()), [dispatch]);
  const handleTabsFan = useCallback((tabs) => dispatch(tabsFan(tabs)), [dispatch]);
  const handleSetPeriodFan = useCallback(
    (name, atMin = null, atMax = null) => dispatch(setPeriodFan(name, atMin, atMax)),
    [dispatch]
  );

  useEffect(() => {
    handleFetchFan();
  }, [handleFetchFan, period]);

  const handlePeriod = () => {
    if (loading) return false;

    props.openPopout({
      popout: (
        <PopoutTemplateDateChangePeriod
          openView={props.openView}
          period={period} // Период из redux store
          setPeriod={handleSetPeriodFan} // action из redux
          backPanel={PANEL_FAN}
          backView={VIEW_TOOL}
          panelTitle={"Поклонники"}
          closePopout={props.closePopout}
        />
      ),
    });
  };

  const setTabsSex = (tabs) => {
    if (!loading) {
      handleTabsFan(tabs);
      handleFetchFan();
    }
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      {renderPeriodV2({ title: "Поклонники", period: period, handler: handlePeriod })}
      <Tabs>
        <TabsItem
          disabled={loading}
          onClick={() => {
            if (tabs !== TABS_ALL) setTabsSex(TABS_ALL);
          }}
          selected={tabs === TABS_ALL}
        >
          Все
        </TabsItem>
        <TabsItem
          disabled={loading}
          onClick={() => {
            if (tabs !== TABS_MALE) setTabsSex(TABS_MALE);
          }}
          selected={tabs === TABS_MALE}
        >
          Мужчины
        </TabsItem>
        <TabsItem
          disabled={loading}
          onClick={() => {
            if (tabs !== TABS_FEMALE) setTabsSex(TABS_FEMALE);
          }}
          selected={tabs === TABS_FEMALE}
        >
          Женщины
        </TabsItem>
      </Tabs>
      {loading ? <LoaderWithProgressBar progressValue={progressValue} /> : ""}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchFan,
      })}
      {!loading &&
      topGuest === null &&
      topLike === null &&
      topComment === null &&
      error === null ? (
        <Placeholder icon={<Icon56UsersOutline />}>
          Поклонники за данный период не найдены
        </Placeholder>
      ) : (
        ""
      )}
      {topGuest && error === null ? (
        <FanItem
          openView={props.openView}
          id={topGuest ? topGuest.vk_id : null}
          name={topGuest ? topGuest.name : ""}
          photo={topGuest ? topGuest.photo_url : null}
          count={topGuest ? topGuest.count : 0}
          type={FAN_GUEST}
        />
      ) : (
        ""
      )}
      {topLike && error === null ? (
        <FanItem
          openView={props.openView}
          id={topLike ? topLike.vk_id : null}
          name={topLike ? topLike.name : ""}
          photo={topLike ? topLike.photo_url : null}
          count={topLike ? topLike.count : 0}
          type={FAN_LIKE}
        />
      ) : (
        ""
      )}
      {topComment && error === null ? (
        <FanItem
          openView={props.openView}
          id={topComment ? topComment.vk_id : null}
          name={topComment ? topComment.name : ""}
          photo={topComment ? topComment.photo_url : null}
          count={topComment ? topComment.count : 0}
          type={FAN_COMMENT}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default FanList;
