import React from "react";
import { Banner } from "@vkontakte/vkui";
import CommunityIcon from "../../../../../../assets/img/tool/communityActivity/banner/community.png";

const CABanner = () => {
  return (
    <Banner
      size="m"
      mode="image"
      asideMode="dismiss"
      imageTheme="dark"
      background={
        <div
          style={{
            background: "radial-gradient(109.55% 109.55% at 0% 0%, #358DFF 0%, #0336FF 100%",
          }}
        ></div>
      }
      before={<img width={28} height={28} src={CommunityIcon} alt="" />}
      text={
        <span style={{ fontSize: "16px" }}>
          Узнавайте в каких сообществах давно не было обновлений
        </span>
      }
    />
  );
};

export default CABanner;
