import React from "react";
import { APIVkExecute, APIVkGetByIdStories } from "api/vk/apiMethods";
import Icon56LockOutline from "@vkontakte/icons/dist/56/lock_outline";
import {
  PROFILE_VIEW_STORIES_REQUEST,
  PROFILE_VIEW_STORIES_SUCCESS,
  PROFILE_VIEW_STORIES_ERROR,
  PROFILE_VIEW_STORIES_PROGRESS,
  PROFILE_VIEW_STORIES_CANCEL,
  PROFILE_VIEW_STORIES_SHOW_MORE,
} from "../../constType";

import { delay, unixTimeToDate } from "helpers";
import { addVKScope, getSearchObject } from "api";
import { APIEye3StoryGetTool } from "api/eye3";
import { defaultErrorProperty } from "api/eye3/responseHandler";

async function getViewersStories(storiesIds, profileId, dispatch, getState) {
  try {
    //console.log('getViewersStoriesIds: ', storiesIds);

    const vkUserId = getSearchObject().vk_user_id;
    let result = [];
    let currentDate = null;
    let index = 0;
    let loadLength = storiesIds.length;
    let loadDone = 0;

    let minCounterViewers = 0;
    let maxCounterViewers = storiesIds.length < 24 ? storiesIds.length : 24;

    async function process() {
      try {
        if (!getState().profileViewStories.loading) return result;

        let executeViewersItems = [];
        let chunkViewersItems = [];

        for (let i = minCounterViewers; i < maxCounterViewers; i++) {
          chunkViewersItems.push(storiesIds[i]);
          minCounterViewers++;
          loadDone++;
        }

        maxCounterViewers += 24;

        if (maxCounterViewers >= storiesIds.length) maxCounterViewers = storiesIds.length;

        /*console.log('minCounterViewers: ', minCounterViewers);
                console.log('maxCounterViewers: ', maxCounterViewers);*/

        const executeViewers = await APIVkExecute({
          code: `
                        var ids = [${chunkViewersItems}];
                        var result = [];
                        var i = 0;
                        while (i < ids.length) {
                            var method = API.stories.getViewers({
                                owner_id: '${vkUserId}', 
                                story_id: ids[i],
                            });

                            result.push(method);
            
                            i = i + 1;
                        }
            
                        return result;
                    `,
        });

        //console.log('executeViewers: ', executeViewers);

        if (!executeViewers.hasOwnProperty("response")) return result;
        else if (executeViewers.response === null) return result;
        else if (executeViewers.response.length !== chunkViewersItems.length) return result;

        for (let i = 0; i < executeViewers.response.length; i++) {
          const element = executeViewers.response[i];
          const storyId = chunkViewersItems[i];

          if (element !== false && element.hasOwnProperty("items")) {
            for (let item of element.items) {
              if (item.user_id === profileId) {
                // Если история была просмотрена данным profileId
                executeViewersItems.push(vkUserId + "_" + storyId); // Сохранить id просмотренной истории
              }
            }
          }
        }

        //console.log('executeViewersItems: ', executeViewersItems);

        if (executeViewersItems.length !== 0) {
          const stories = await APIVkGetByIdStories({
            stories: executeViewersItems.join(","),
          });

          //console.log('storiesGetById: ', stories);

          const storiesItems = stories.response.hasOwnProperty("items")
            ? stories.response.items
            : stories.response;

          for (let storyItem of storiesItems) {
            const storyItemDate = unixTimeToDate(storyItem.date).getDate();

            if (currentDate === null) {
              currentDate = storyItemDate;
              result.push([]);
            } else if (currentDate !== storyItemDate) {
              currentDate = storyItemDate;
              result.push([]);
              index++;
            }

            result[index].push({
              vk_id: storyItem.id,
              date: storyItem.date,
              photo_url: getPreviewUrl(storyItem),
            });
          }

          //console.log('storiesItems: ', storiesItems);
        }

        const progressValue = (loadDone * 100) / loadLength;

        dispatch(progress(Math.round(progressValue), result));

        if (minCounterViewers < storiesIds.length) {
          await delay(400);
          await process();
        }
      } catch (e) {
        throw new Error("process::: " + e);
      }
    }

    await process();

    return result;
  } catch (e) {
    throw new Error("getViewersStories::: " + e);
  }
}

function getPreviewUrl(s) {
  if (s.hasOwnProperty("photo"))
    return s.photo.sizes
      .filter((size) => size.type === "m")
      .map((size) => size.url)
      .join("");
  else if (s.hasOwnProperty("video"))
    return s.video.image
      .filter((size) => size.width === 130)
      .map((size) => size.url)
      .join("");
  else return "";
}

export function showMoreViewStories() {
  return { type: PROFILE_VIEW_STORIES_SHOW_MORE };
}

function progress(progressValue, payload) {
  return { type: PROFILE_VIEW_STORIES_PROGRESS, progressValue, payload };
}

export function fetchViewStories() {
  return async (dispatch, getState) => {
    try {
      if (!(await addVKScope("stories"))) {
        // Проверка на разрешение stories
        dispatch(
          error({
            ...defaultErrorProperty,
            message: "Для отображения просмотров требуется доступ к историям",
            icon: <Icon56LockOutline />,
            isVisibleMessage: true,
          })
        );

        return false;
      }

      const state = getState().profileViewStories;
      const page = getState().page;
      const profile = getState().profile;
      const id = profile.history[page.activeStory].user.id;

      // Если предыдущий открытый профиль равен текущему открытому профилю
      if (
        state.activeUserId === profile.history[page.activeStory].user.id &&
        !state.isStopLoading
      ) {
        return false;
      }

      await dispatch(request(id));

      const storiesIds = await APIEye3StoryGetTool();
      //console.log('storiesIds: ', storiesIds);

      if (storiesIds.items.length === 0) {
        await dispatch(success([]));
        return false;
      }

      const storiesViewers = await getViewersStories(storiesIds.items, id, dispatch, getState);
      //console.log('storiesViewers: ', storiesViewers);

      if (storiesViewers.length === 0) {
        await dispatch(success([]));
        return false;
      }

      await dispatch(success(storiesViewers));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(id) {
    return { type: PROFILE_VIEW_STORIES_REQUEST, id };
  }

  function success(payload) {
    return { type: PROFILE_VIEW_STORIES_SUCCESS, payload };
  }

  function error(error) {
    return { type: PROFILE_VIEW_STORIES_ERROR, error };
  }
}

export function cancelViewStories() {
  return { type: PROFILE_VIEW_STORIES_CANCEL };
}
