import { Group, Header, Placeholder, Search } from "@vkontakte/vkui";
import Icon28StoryOutline from "@vkontakte/icons/dist/28/story_outline";
import { declOfNum } from "helpers";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchDownloadHistory, searchDownloadHistory } from "store/actions/tool/downloadHistory";
import DHUserItem from "../DHUserItem/DHUserItem";
import { STORY_TYPE_FRIENDS, STORY_TYPE_GROUPS, STORY_TYPE_SEARCH } from "../../constStoryType";
import { useDebounceSearch } from "hooks";

const DHUserList = (props) => {
  const dispatch = useDispatch();
  const handleFetchDownloadHistory = useCallback(
    () => dispatch(fetchDownloadHistory()),
    [dispatch]
  );
  const handleSearchDownloadHistory = useCallback(
    (search) => dispatch(searchDownloadHistory(search)),
    [dispatch]
  );
  const isOnce = useSelector((state) => state.toolDownloadHistory.stories.isOnce);
  const stories = useSelector((state) => state.toolDownloadHistory.stories.payload);
  const search = useSelector((state) => state.toolDownloadHistory.stories.search);
  const loading = useSelector((state) => state.toolDownloadHistory.stories.loading);
  const error = useSelector((state) => state.toolDownloadHistory.stories.error);

  const [searchTerm, setSearchTerm] = useState(search);

  const storiesFriends =
    stories !== null && stories.hasOwnProperty("friends") ? stories.friends : [];
  const storiesGroups = stories !== null && stories.hasOwnProperty("groups") ? stories.groups : [];

  const searchPayload = useSelector((state) => state.toolDownloadHistory.storiesSearch.payload);
  const storiesSearch = searchPayload !== null ? searchPayload : [];
  const storiesSearchError = useSelector((state) => state.toolDownloadHistory.storiesSearch.error);

  useEffect(() => {
    if (!isOnce) handleFetchDownloadHistory();
  }, [handleFetchDownloadHistory, isOnce]);

  useDebounceSearch({
    search: search,
    searchTerm: searchTerm,
    handler: handleSearchDownloadHistory,
  });

  const renderFriends = () => {
    return storiesFriends.map((friend, i) => {
      const id = friend.vk_id;
      const name = friend.name;
      const photo = friend.photo_url;
      const storyCount = friend.count;
      const storyCountStrFormat = storyCount
        ? storyCount + " " + declOfNum(storyCount, ["история", "истории", "историй"])
        : "0 историй";

      return (
        <DHUserItem
          key={i}
          id={id}
          storyType={STORY_TYPE_FRIENDS}
          title={name}
          description={storyCountStrFormat}
          photo={photo}
          openView={props.openView}
        />
      );
    });
  };

  const renderGroups = () => {
    return storiesGroups.map((group, i) => {
      const id = group.vk_id;
      const name = group.name;
      const photo = group.photo_url;
      const storyCount = group.count;
      const storyCountStrFormat = storyCount
        ? storyCount + " " + declOfNum(storyCount, ["история", "истории", "историй"])
        : "0 историй";

      return (
        <DHUserItem
          key={i}
          id={id}
          storyType={STORY_TYPE_GROUPS}
          title={name}
          description={storyCountStrFormat}
          photo={photo}
          openView={props.openView}
        />
      );
    });
  };

  const renderSearch = () => {
    return storiesSearch.map((item, i) => {
      const id = item.vk_id;
      const name = item.name;
      const photo = item.photo_url;
      const storyCount = item.count;
      const storyCountStrFormat = storyCount
        ? storyCount + " " + declOfNum(storyCount, ["история", "истории", "историй"])
        : "0 историй";

      return (
        <DHUserItem
          key={i}
          id={id}
          storyType={STORY_TYPE_SEARCH}
          title={name}
          description={storyCountStrFormat}
          photo={photo}
          openView={props.openView}
        />
      );
    });
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      {stories !== null && error === null ? (
        <Search
          value={searchTerm}
          onChange={(e) => {
            if (loading) return;

            setSearchTerm(e.target.value);
          }}
          placeholder="Ссылка на страницу"
        />
      ) : (
        ""
      )}
      {search !== "" ? (
        <Group header={<Header mode="secondary">Результаты поиска</Header>} separator="hide">
          {storiesSearch.length !== 0 && renderSearch()}
        </Group>
      ) : (
        ""
      )}
      {storiesFriends.length !== 0 && search === "" ? (
        <Group header={<Header mode="secondary">Истории друзей</Header>} separator="hide">
          {renderFriends()}
        </Group>
      ) : (
        ""
      )}
      {storiesGroups.length !== 0 && search === "" ? (
        <Group header={<Header mode="secondary">Истории сообществ</Header>} separator="hide">
          {renderGroups()}
        </Group>
      ) : (
        ""
      )}
      {(!loading &&
        stories !== null &&
        error === null &&
        storiesFriends.length === 0 &&
        storiesGroups.length === 0 &&
        storiesSearch.length === 0) ||
      (!loading &&
        stories !== null &&
        storiesSearchError === null &&
        storiesSearch.length === 0 &&
        search !== "") ? (
        <Placeholder icon={<Icon28StoryOutline width={56} height={56} />}>
          Истории не найдены
        </Placeholder>
      ) : (
        ""
      )}
      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchDownloadHistory,
      })}
    </div>
  );
};

export default DHUserList;
