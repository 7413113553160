import { Div } from "@vkontakte/vkui";
import HeadTitle from "template/components/HeadTitle/HeadTitle";
import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import DHInfoItem from "../DHInfoItem/DHInfoItem";

import { fetchInfoDownload } from "store/actions/tool/downloadHistory";
import SecondaryTitle from "template/components/SecondaryTitle/SecondaryTitle";

const DHInfoList = () => {
  const dispatch = useDispatch();
  const handleFetchInfoDownload = useCallback(() => dispatch(fetchInfoDownload()), [dispatch]);
  const stories = useSelector((state) => state.toolDownloadHistory.downloads.payload);

  const renderStories = () => {
    return stories.map((story, i) => {
      const type = story.type;
      const link = story.link;
      const photo = story.photo_url;

      return <DHInfoItem key={i} type={type} link={link} photo={photo} />;
    });
  };

  useEffect(() => {
    handleFetchInfoDownload();
  }, [handleFetchInfoDownload]);

  return (
    <>
      <HeadTitle>Выбор историй</HeadTitle>
      <SecondaryTitle>
        При выборе истории, в открывшемся окне, щелкните правой кнопкой мыши по фото или видео (или
        удерживайте касание если пользуетесь мобильным устройством) и выберите пункт «Скачать видео»
        или «Скачать изображение» в зависимости от типа файла.
      </SecondaryTitle>
      <Div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {stories !== null && renderStories()}
        </div>
      </Div>
    </>
  );
};

export default DHInfoList;
