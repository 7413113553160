import { queryEye3 } from "api";

async function APIEye3SetMyCrushMoyCrush({ userIds }) {
  try {
    const response = await queryEye3({
      method: "moycrush.set_my_crush",
      get: { user_ids: userIds },
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3SetMyCrushMoyCrush;
