import {
  WHO_ADDED_CHS_REQUEST,
  WHO_ADDED_CHS_PROGRESS,
  WHO_ADDED_CHS_SUCCESS,
  WHO_ADDED_CHS_ERROR,
} from "../../constType";

import { calcAge, delay } from "helpers";
import { APIEye3BlacklistTool } from "api/eye3";
import { openModal } from "../page";
import { delayMethodGetUsersVKApi } from "api";
import { checkEYE3ApiError, defaultErrorProperty } from "api/eye3/responseHandler";

export function fetchWhoAddedChs() {
  // Кто добавил в чс

  return async (dispatch, getState) => {
    try {
      if (getState().toolWhoAddedChs.loading) return;

      dispatch(request());

      async function process() {
        try {
          const whoAddedChs = await APIEye3BlacklistTool();
          let result = [];
          //console.log(whoAddedChs);

          // Проверка ошибок в API
          const errorAPI = checkEYE3ApiError(whoAddedChs);
          if (errorAPI !== false) {
            errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
            dispatch(error({ ...errorAPI }));
            return;
          }

          if (whoAddedChs.hasOwnProperty("result") && whoAddedChs.result.length !== 0) {
            const whoAddedChsInfo = await delayMethodGetUsersVKApi({
              userIds: whoAddedChs.result,
              fields: ["photo_100", "bdate", "city", "country"],
            });

            const whoAddedChsItems = whoAddedChsInfo;

            result = whoAddedChsItems.map((user) => {
              return {
                vk_id: user.id,
                name: user.first_name + " " + user.last_name,
                age: user.bdate ? calcAge(user.bdate) : null, // Расчет возраста по дате рождения
                city: user.city ? user.city.title : null, // Город
                city_id: user.city ? user.city.id.toString() : null, // Город id
                photo_url: user.photo_100 ? user.photo_100 : "", // Фото
                country: user.country ? user.country.title : null, // Страна
                country_id: user.country ? user.country.id.toString() : null, // Страна id
              };
            });
          }

          if (whoAddedChs.status_task === "work") {
            dispatch(progress(whoAddedChs.percent, result));
            await delay(2000);
            await process();
          } else if (whoAddedChs.status_task === "success") {
            dispatch(success(result));
          } else if (whoAddedChs.status_task === "error") {
            dispatch(error({ ...defaultErrorProperty, message: "" }));
          }

          return;
        } catch (e) {
          dispatch(error({ ...defaultErrorProperty, message: e }));
        }
      }

      await process();
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: WHO_ADDED_CHS_REQUEST };
  }

  function progress(progressValue, payload) {
    return { type: WHO_ADDED_CHS_PROGRESS, progressValue, payload };
  }

  function success(payload) {
    return { type: WHO_ADDED_CHS_SUCCESS, payload };
  }

  function error(error) {
    return { type: WHO_ADDED_CHS_ERROR, error };
  }
}
