import {
  PROFILE_JOIN_COMMUNITY_REQUEST,
  PROFILE_JOIN_COMMUNITY_SUCCESS,
  PROFILE_JOIN_COMMUNITY_ERROR,
  PROFILE_JOIN_COMMUNITY_SHOW_MORE,
} from "../../constType";

const initialState = {
  activeUserId: null,
  payload: null,
  loading: false,
  showCount: 20,
  error: null,
};

export default function joinCommunityReducer(state = initialState, action) {
  switch (action.type) {
    case PROFILE_JOIN_COMMUNITY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        payload: null,
        activeUserId: action.id,
        showCount: 20,
      };
    case PROFILE_JOIN_COMMUNITY_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: action.payload,
      };
    case PROFILE_JOIN_COMMUNITY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case PROFILE_JOIN_COMMUNITY_SHOW_MORE:
      return {
        ...state,
        showCount: parseInt(state.showCount) + 20,
      };
    default:
      return state;
  }
}
