import { APIEye3GetHoroscope } from "api/eye3";
import { defaultErrorProperty } from "api/eye3/responseHandler";
import {
  PROFILE_HOROSCOPE_REQUEST,
  PROFILE_HOROSCOPE_SUCCESS,
  PROFILE_HOROSCOPE_ERROR,
} from "../../constType";

export function fetchHoroscope() {
  return async (dispatch, getState) => {
    try {
      const state = getState().profileHoroscope;
      const page = getState().page;
      const profile = getState().profile;

      const id = profile.history[page.activeStory].user.id;

      // Если предыдущий открытый профиль равен текущему открытому профилю
      if (state.activeUserId === profile.history[page.activeStory].user.id) {
        return false;
      }

      dispatch(request(id));

      const horoscope = await APIEye3GetHoroscope({
        userId: id,
      });

      //console.log('horoscope: ', horoscope);

      if (!horoscope.status && horoscope.hasOwnProperty("is_bot_connect")) {
        dispatch(
          error({
            ...defaultErrorProperty,
            message: "Необходимо подключение бота",
            isTryAgain: false,
            isVisibleMessage: true,
          })
        );
        return;
      } else if (!horoscope.status && horoscope.hasOwnProperty("self_bdate")) {
        dispatch(
          error({
            ...defaultErrorProperty,
            message: "Не удалось получить дату рождения для текущего пользователя",
            isTryAgain: false,
            isVisibleMessage: true,
          })
        );
        return;
      } else if (!horoscope.status && horoscope.hasOwnProperty("target_bdate")) {
        dispatch(
          error({
            ...defaultErrorProperty,
            message: "Не удалось получить дату рождения для выбранного пользователя",
            isTryAgain: false,
            isVisibleMessage: true,
          })
        );
        return;
      }

      dispatch(success(horoscope));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(id) {
    return { type: PROFILE_HOROSCOPE_REQUEST, id };
  }

  function success(payload) {
    return { type: PROFILE_HOROSCOPE_SUCCESS, payload };
  }

  function error(error) {
    return { type: PROFILE_HOROSCOPE_ERROR, error };
  }
}
