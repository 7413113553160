import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { ModalCard } from "@vkontakte/vkui";
import ConnectAutoPaymentIcon from "assets/img/shop/modal/connectAutoPayment.png";

import { connectTariff } from "store/actions/shop";

const ConnectAutoPaymentModal = (props) => {
  const tariffCaption = props.modalParam ? props.modalParam.tariffCaption : "";
  const timeCaption = props.modalParam ? props.modalParam.timeCaption : "";
  const sum = props.modalParam ? props.modalParam.sum : "";

  const tariffType = props.modalParam ? props.modalParam.tariffType : "";
  const dayCount = props.modalParam ? props.modalParam.dayCount : "";

  const dispatch = useDispatch();
  const handleConnectTariff = useCallback(
    (tariffType, dayCount, autopayment, tariffCaption) =>
      dispatch(connectTariff(tariffType, dayCount, autopayment, tariffCaption)),
    [dispatch]
  );

  return (
    <ModalCard
      id={props.id}
      header={"Подключить автоплатеж для тарифа «" + tariffCaption + "»?"}
      onClose={props.handleCloseModal}
      icon={<img src={ConnectAutoPaymentIcon} style={{ width: "64px", height: "64px" }} alt="" />}
      caption={
        "Каждые " +
        timeCaption +
        " с вашего счета будет списываться " +
        sum +
        " рублей для поддержания работы тарифа"
      }
      actions={[
        {
          title: "Нет",
          mode: "secondary",
          action: () => {
            props.handleCloseModal();
            handleConnectTariff(tariffType, dayCount, false, tariffCaption);
          },
        },
        {
          title: "Подключить",
          mode: "primary",
          action: () => {
            props.handleCloseModal();
            handleConnectTariff(tariffType, dayCount, true, tariffCaption);
          },
        },
      ]}
    />
  );
};

export default ConnectAutoPaymentModal;
