import React from "react";
import { Avatar, Link, SimpleCell } from "@vkontakte/vkui";
import {
  MARITAL_STATUS_NOT_MARRIED,
  MARITAL_STATUS_HAVE_FRIEND,
  MARITAL_STATUS_ENGAGED,
  MARITAL_STATUS_MARRIED,
  MARITAL_STATUS_COMPLICATED,
  MARITAL_STATUS_ACTIVELY_LOOKING,
  MARITAL_STATUS_LOVE,
  MARITAL_CIVIL_MARRIAGE,
} from "../../../constMaritalStatus";

const MSInfoItem = (props) => {
  const getTypeIcon = () => {
    return props.partnerPhoto ? <Avatar size={48} src={props.partnerPhoto}></Avatar> : null;
  };

  const getRelation = () => {
    switch (props.relation) {
      case MARITAL_STATUS_NOT_MARRIED:
        return props.sex === "2" ? "«не женат»" : "«не замужем»";
      case MARITAL_STATUS_HAVE_FRIEND:
        return props.sex === "2" ? "«есть подруга»" : "«есть друг»";
      case MARITAL_STATUS_ENGAGED:
        return props.sex === "2" ? "«помолвлен»" : "«помолвлена»";
      case MARITAL_STATUS_MARRIED:
        return props.sex === "2" ? "«женат»" : "«замужем»";
      case MARITAL_STATUS_COMPLICATED:
        return "«всё сложно»";
      case MARITAL_STATUS_ACTIVELY_LOOKING:
        return "«в активном поиске»";
      case MARITAL_STATUS_LOVE:
        return props.sex === "2" ? "«влюблён»" : "«влюблена»";
      case MARITAL_CIVIL_MARRIAGE:
        return "«в гражданском браке»";
      default:
        return "";
    }
  };

  const getInfoText = () => {
    return (
      <>
        «
        <Link href={"https://www.vk.com/id" + props.partnerId} target="_blank">
          {props.partnerName}
        </Link>
        »
      </>
    );
  };

  return (
    <SimpleCell before={getTypeIcon()} disabled multiline description={"в " + props.time}>
      <span style={{ color: "#818C99" }}>
        {" "}
        {props.partnerId
          ? props.sex === "1"
            ? "Сменила партнера на "
            : "Сменил партнера на "
          : props.sex === "2"
          ? "Сменил семейное положение на"
          : "Сменила семейное положение на"}{" "}
      </span>
      {props.partnerId ? getInfoText() : getRelation()}
    </SimpleCell>
  );
};

export default MSInfoItem;
