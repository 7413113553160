import React, { useCallback } from "react";
import { Group, Placeholder, Tabs, TabsItem } from "@vkontakte/vkui";
import GuestItem from "../GuestItem/GuestItem";
import GuestSearch from "../GuestSearch/GuestSearch";
import SeparateFilter from "template/components/SeparateFilter/SeparateFilter";
import Icon56UsersOutline from "@vkontakte/icons/dist/56/users_outline";
import { useSelector, useDispatch } from "react-redux";

import { MODAL_CARD_VISITED, MODAL_PAGE_FILTER } from "views/guest/modals/constModals";

import { fetchGuestV2, showMoreGuestV2, searchGuestV2, tabsGuestV2 } from "store/actions/guestV2";

import { convertTime, convertMonthDay, unixTimeToDate } from "helpers";
import { declOfNum } from "helpers";

import { TABS_ACTIVE, TABS_ALL, TABS_VISIT } from "../../constTabs";
import { useShowMore } from "hooks";
import { renderLoader } from "helpers/views";

const GuestList = (props) => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const guestsList = useSelector((state) => (state.guestV2.payload ? state.guestV2.payload : null));
  const loading = useSelector((state) => state.guestV2.loading);
  const tabs = useSelector((state) => state.guestV2.tabs);
  const showCount = useSelector((state) => state.guestV2.showCount);
  const search = useSelector((state) => state.guestV2.search);

  const myProfileLicense = useSelector((state) =>
    state.myProfile.license.payload ? state.myProfile.license.payload : null
  );
  const guestAllow = myProfileLicense !== null ? myProfileLicense.guest_allow : false;
  const guestLicense = myProfileLicense !== null ? myProfileLicense.license_is_guest : false;

  let lastViewDate = new Date();

  const handleFetchGuestV2 = useCallback(
    (isRefresh = false) => dispatch(fetchGuestV2(isRefresh)),
    [dispatch]
  );
  const handleSearchGuestV2 = useCallback((search) => dispatch(searchGuestV2(search)), [dispatch]);
  const handleShowMoreGuestV2 = useCallback(() => dispatch(showMoreGuestV2()), [dispatch]);
  const handleTabsGuestV2 = useCallback((tabs) => dispatch(tabsGuestV2(tabs)), [dispatch]);

  useShowMore({
    items: guestsList,
    showCount: showCount,
    maxLength: guestsList !== null ? guestsList.guests.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMoreGuestV2,
  });

  const renderGuests = () => {
    return guestsList.guests.length > 0 ? (
      guestsList.guests.map((guest, i) => {
        const id = guest.vk_id;
        const name = guest.name;
        const link = guest.link;
        const type = guest.type;
        const typeName = guest.type_name;
        const viewDate = unixTimeToDate(guest.guest_at); // Unix Time to Date
        const city = guest.city ? guest.city : "";
        const age = guest.age ? guest.age : "";
        const photo = guest.photo_url ? guest.photo_url : "";
        const isNewView = guest.is_new ? guest.is_new : false;

        const ageStrFormat = age ? age + " " + declOfNum(age, ["год", "года", "лет"]) : "";
        const comma = ageStrFormat && city ? ", " : "";

        let separate = <SeparateFilter>{convertMonthDay(viewDate)}</SeparateFilter>;

        if (lastViewDate.getDate() === viewDate.getDate() && i !== 0) {
          separate = "";
        }

        lastViewDate = viewDate;

        if (i < showCount) {
          return (
            <div key={i}>
              {separate}
              <GuestItem
                id={id}
                title={name}
                link={link}
                type={type}
                typeName={typeName}
                license={guestLicense}
                allow={guestAllow}
                description={ageStrFormat + comma + city}
                photo={photo}
                isNewView={isNewView}
                viewTime={guest.guest_at ? convertTime(viewDate) : ""}
                openView={props.openView}
                openModal={props.openModal}
              />
            </div>
          );
        }

        return false;
      })
    ) : (
      <Placeholder icon={<Icon56UsersOutline />}>Гости не найдены</Placeholder>
    );
  };

  const setTabsGuest = (tabs) => {
    if (!loading) {
      if (!guestLicense && guestAllow && (tabs === TABS_VISIT || tabs === TABS_ALL)) {
        props.openModal({ id: MODAL_CARD_VISITED });
      }

      handleTabsGuestV2(tabs);
      handleFetchGuestV2(true);
    }
  };

  return (
    <>
      <GuestSearch
        value={search}
        onChange={(e) => {
          if (!guestLicense && guestAllow && (tabs === TABS_VISIT || tabs === TABS_ALL)) return;

          handleSearchGuestV2(e.target.value);
        }}
        onIconClick={() => {
          if (!guestLicense && guestAllow && (tabs === TABS_VISIT || tabs === TABS_ALL)) {
            props.openModal({ id: MODAL_CARD_VISITED });
            return;
          }

          if (!loading) props.openModal({ id: MODAL_PAGE_FILTER });
        }}
      />

      {guestAllow && (
        <Tabs>
          <TabsItem
            selected={tabs === TABS_ALL}
            onClick={() => {
              if (tabs !== TABS_ALL) setTabsGuest(TABS_ALL);
            }}
          >
            Все
          </TabsItem>
          <TabsItem
            selected={tabs === TABS_VISIT}
            onClick={() => {
              if (tabs !== TABS_VISIT) setTabsGuest(TABS_VISIT);
            }}
          >
            Гости
          </TabsItem>
          <TabsItem
            selected={tabs === TABS_ACTIVE}
            onClick={() => {
              if (tabs !== TABS_ACTIVE) setTabsGuest(TABS_ACTIVE);
            }}
          >
            Активности
          </TabsItem>
        </Tabs>
      )}
      <Group separator="hide" style={{ marginBottom: "20px" }}>
        {guestsList !== null ? renderGuests() : ""}
        {renderLoader({
          loading: loading,
        })}
      </Group>
    </>
  );
};

export default GuestList;
