import React from "react";
import styles from "./TOCompareTimeInfoItem.module.css";
import { Icon12OnlineMobile } from "@vkontakte/icons";
import OnlinePCIcon from "assets/img/onlinePC.svg";

const TOCompareTimeInfoItem = (props) => {
  return (
    <div className={styles.lineCompareContainer}>
      <div className={styles.lineCompareLeft}>
        {props.periodUser1}
        <div style={{ paddingLeft: "3px", paddingBottom: "3px" }}>
          {props.periodUser1IsMobile ? (
            <Icon12OnlineMobile fill={"#4bb34b"} />
          ) : (
            <img src={OnlinePCIcon} alt="" />
          )}
        </div>
      </div>
      <div className={styles.lineCompareCenter}>{props.periodCompare}</div>
      <div className={styles.lineCompareRight}>
        {props.periodUser2}
        <div style={{ paddingLeft: "3px", paddingBottom: "3px" }}>
          {props.periodUser2IsMobile ? (
            <Icon12OnlineMobile fill={"#4bb34b"} />
          ) : (
            <img src={OnlinePCIcon} alt="" />
          )}
        </div>
      </div>
    </div>
  );
};

export default TOCompareTimeInfoItem;
