import React from "react";
import FanList from "./components/FanList/FanList";
import { Panel } from "@vkontakte/vkui";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import FanIcon from "assets/img/fan/banner/fan.png";
import ToolBanner from "template/components/ToolBanner/ToolBanner";
import { withRouter } from "hoc";

const PanelFan = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Поклонники</PanelHeaderWithPath>
      <ToolBanner
        tool="toolFan"
        icon={FanIcon}
        text="Узнавайте кто из ваших друзей/подписчиков самый активный"
      />
      <FanList
        openView={props.openView}
        openPopout={props.openPopout}
        closePopout={props.closePopout}
      />
    </Panel>
  );
};

export default withRouter(PanelFan);
