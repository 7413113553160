import React from "react";
import { Placeholder, Div, Progress, InfoRow } from "@vkontakte/vkui";
import LoaderIcon from "assets/img/loader.png";

const LoaderWithProgressBar = (props) => {
  return (
    <Div>
      <Placeholder icon={<img src={LoaderIcon} width="50px" height="50px" alt=""></img>}>
        Данные загружаются, пожалуйста ожидайте...
      </Placeholder>
      <InfoRow header={props.progressValue + " %"}>
        <Progress value={props.progressValue} />
      </InfoRow>
    </Div>
  );
};

LoaderWithProgressBar.defaultProps = {
  progressValue: 0,
};

export default LoaderWithProgressBar;
