import {
  TOOL_LIST_POPULAR_REQUEST,
  TOOL_LIST_POPULAR_SUCCESS,
  TOOL_LIST_POPULAR_ERROR,
} from "../../constType";

const initialState = {
  popularItems: {
    payload: null,
    loading: false,
    error: null,
  },
};

export default function toolItemsReducer(state = initialState, action) {
  switch (action.type) {
    case TOOL_LIST_POPULAR_REQUEST:
      return {
        ...state,
        popularItems: {
          ...state.popularItems,
          error: null,
          payload: null,
          loading: true,
        },
      };
    case TOOL_LIST_POPULAR_SUCCESS:
      return {
        ...state,
        popularItems: {
          ...state.popularItems,
          payload: action.payload,
          loading: false,
        },
      };
    case TOOL_LIST_POPULAR_ERROR:
      return {
        ...state,
        popularItems: {
          ...state.popularItems,
          error: action.error,
          loading: false,
        },
      };
    default:
      return state;
  }
}
