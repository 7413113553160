import React from "react";
import { Avatar, Link, RichCell } from "@vkontakte/vkui";

import { Icon28ChevronRightOutline } from "@vkontakte/icons";
import IndicatorIcon from "assets/img/guest/indicator.svg";

import { PANEL_PROFILE } from "views/profile/panels/constPanels";
import { VIEW_GUEST } from "views/constViews";
import { VISIT } from "../../constGuest";
import { MODAL_CARD_VISITED } from "views/guest/modals/constModals";

const GuestItem = (props) => {
  const bottomContent = (
    <div style={{ fontSize: "13px", color: "var(--text_secondary)" }}>
      <span>в {props.viewTime}</span>
      {props.link ? (
        <Link href={props.link} target="_blank">
          {" (" + props.typeName + ")"}
        </Link>
      ) : (
        " (" + props.typeName + ")"
      )}
    </div>
  );

  const caption = <div style={{ marginTop: "3px", marginBottom: "4px" }}>{props.description}</div>;

  const goProfile = () => {
    if (!props.license && props.allow && props.type === VISIT) {
      props.openModal({ id: MODAL_CARD_VISITED });
      return;
    }
    props.openView({
      view: VIEW_GUEST,
      panel: PANEL_PROFILE,
      param: { activeProfileId: props.id },
    });

    return;
  };

  return (
    <RichCell
      disabled
      style={
        props.type === VISIT && !props.license
          ? {
              background: "rgba(255, 255, 255, 0.2)",
              filter: "blur(10px)",
            }
          : null
      }
      before={<Avatar size={72} src={props.photo} onClick={goProfile} />}
      caption={caption}
      bottom={bottomContent}
      after={<Icon28ChevronRightOutline fill="#B8C1CC" onClick={goProfile} />}
    >
      <span style={{ fontSize: "16px" }}>{props.title}</span>
      {props.isNew ? (
        <span style={{ position: "relative", left: "2px", bottom: "2px" }}>
          <img src={IndicatorIcon} alt=""></img>
        </span>
      ) : (
        ""
      )}
    </RichCell>
  );
};

export default GuestItem;
