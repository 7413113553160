import React from "react";
import { Avatar, SimpleCell, UsersStack } from "@vkontakte/vkui";
import Icon16Chevron from "@vkontakte/icons/dist/16/chevron";
import { MODAL_PAGE_TEMPLATE_MUTUAL_FRIEND } from "template/modals/constModals";

const PCUserItem = (props) => {
  const photos = props.commonUsers.map((item) => {
    return item.photo_url;
  });

  return (
    <SimpleCell
      id={props.id}
      before={<Avatar size={48} src={props.photo} />}
      description={
        <UsersStack size="s" visibleCount={3} photos={photos}>
          {props.commonCount}
        </UsersStack>
      }
      after={<Icon16Chevron fill="#B8C1CC" />}
      onClick={() => {
        props.openModal({
          id: MODAL_PAGE_TEMPLATE_MUTUAL_FRIEND,
          param: {
            header: "Список друзей в сообществе",
            friends: props.commonUsers,
          },
        });
      }}
    >
      {props.title}
    </SimpleCell>
  );
};

export default PCUserItem;
