import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import MSInfoList from "./components/MSInfoList/MSInfoList";
import { Panel } from "@vkontakte/vkui";
import { withRouter } from "hoc";

const PanelMSInfo = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Семейное положение</PanelHeaderWithPath>
      <MSInfoList openView={props.openView} />
    </Panel>
  );
};

export default withRouter(PanelMSInfo);
