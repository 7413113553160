import {
  VK_GET_STORAGE_REQUEST,
  VK_GET_STORAGE_SUCCESS,
  VK_GET_STORAGE_ERROR,
  VK_SET_STORAGE_REQUEST,
  VK_SET_STORAGE_SUCCESS,
  VK_SET_STORAGE_ERROR,
} from "../constType";

const initialState = {
  payload: null,
  loading: false,
  error: null,
};

export default function vkStorageReducer(state = initialState, action) {
  switch (action.type) {
    case VK_GET_STORAGE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case VK_GET_STORAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: action.payload,
      };
    case VK_GET_STORAGE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case VK_SET_STORAGE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case VK_SET_STORAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: action.payload,
      };
    case VK_SET_STORAGE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
