import {
  WHO_HIDDEN__WHO_HIDDEN_ME_REQUEST,
  WHO_HIDDEN__WHO_HIDDEN_ME_SUCCESS,
  WHO_HIDDEN__WHO_HIDDEN_ME_ERROR,
  WHO_HIDDEN__WHO_HIDDEN_ME_PROGRESS,
  WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_REQUEST,
  WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_SUCCESS,
  WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_ERROR,
  WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_SHOW_MORE,
  WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_SEARCH_REQUEST,
  WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_SEARCH_SUCCESS,
  WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_SEARCH_ERROR,
  WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_FILTER_REQUEST,
  WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_FILTER_SUCCESS,
  WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_FILTER_ERROR,
  WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_INFO_REQUEST,
  WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_INFO_PROGRESS,
  WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_INFO_CANCEL,
  WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_INFO_SUCCESS,
  WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_INFO_ERROR,
  WHO_HIDDEN__WHOM_HIDDEN_IM_REQUEST,
  WHO_HIDDEN__WHOM_HIDDEN_IM_SUCCESS,
  WHO_HIDDEN__WHOM_HIDDEN_IM_ERROR,
  WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_REQUEST,
  WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_SUCCESS,
  WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_ERROR,
  WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_SHOW_MORE,
  WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_SEARCH_REQUEST,
  WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_SEARCH_SUCCESS,
  WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_SEARCH_ERROR,
  WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_FILTER_REQUEST,
  WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_FILTER_SUCCESS,
  WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_FILTER_ERROR,
  WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_INFO_REQUEST,
  WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_INFO_PROGRESS,
  WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_INFO_CANCEL,
  WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_INFO_SUCCESS,
  WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_INFO_ERROR,
  WHO_HIDDEN__WHO_HIDDEN_ME_CANCEL,
} from "../../constType";

import { calcAge, delay } from "helpers";

import { APIEye3GetHideITool, APIEye3GetHideMeTool, APIEye3GetUserHideTool } from "api/eye3";

import { getSearchFilterFriendsV2 } from "helpers/actions";
import { delayMethodGetUsersVKApi } from "api";
import { openModal } from "../page";
import { checkEYE3ApiError, defaultErrorProperty } from "api/eye3/responseHandler";

export function fetchWhoHiddenMe() {
  // Кто скрыл меня
  return async (dispatch, getState) => {
    try {
      const state = getState().toolWhoHidden;

      if (state.whoHiddenMe.loading) return;

      dispatch(request());

      async function process() {
        try {
          if (!getState().toolWhoHidden.whoHiddenMe.loading) return;

          const whoHiddenMe = await APIEye3GetHideMeTool();
          let result = [];

          // Проверка ошибок в API
          const errorAPI = checkEYE3ApiError(whoHiddenMe);
          if (errorAPI !== false) {
            errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
            dispatch(error({ ...errorAPI }));
            return;
          }

          if (whoHiddenMe.hasOwnProperty("result") && whoHiddenMe.result.length !== 0) {
            const whoHiddenMeInfo = await delayMethodGetUsersVKApi({
              userIds: whoHiddenMe.result,
              fields: ["photo_100", "bdate", "city", "country"],
            });

            const whoHiddenMeItems = whoHiddenMeInfo;

            result = whoHiddenMeItems.map((user) => {
              return {
                vk_id: user.id,
                name: user.first_name + " " + user.last_name,
                age: user.bdate ? calcAge(user.bdate) : null, // Расчет возраста по дате рождения
                city: user.city ? user.city.title : null, // Город
                city_id: user.city ? user.city.id.toString() : null, // Город id
                photo_url: user.photo_100 ? user.photo_100 : "", // Фото
                country: user.country ? user.country.title : null, // Страна
                country_id: user.country ? user.country.id.toString() : null, // Страна id
              };
            });
          }

          if (whoHiddenMe.status_task === "work") {
            dispatch(progress(whoHiddenMe.percent, result));
            await delay(2000);
            await process();
          } else if (whoHiddenMe.status_task === "success") {
            dispatch(success(result));
          } else if (whoHiddenMe.status_task === "error") {
            dispatch(
              error({
                ...defaultErrorProperty,
                message: "",
              })
            );
          }
        } catch (e) {
          dispatch(
            error({
              ...defaultErrorProperty,
              message: e,
            })
          );
        }
      }

      await process();
    } catch (e) {
      dispatch(
        error({
          ...defaultErrorProperty,
          message: e,
        })
      );
    }
  };

  function request() {
    return { type: WHO_HIDDEN__WHO_HIDDEN_ME_REQUEST };
  }

  function progress(progressValue, payload) {
    return { type: WHO_HIDDEN__WHO_HIDDEN_ME_PROGRESS, progressValue, payload };
  }

  function success(payload) {
    return { type: WHO_HIDDEN__WHO_HIDDEN_ME_SUCCESS, payload };
  }

  function error(error) {
    return { type: WHO_HIDDEN__WHO_HIDDEN_ME_ERROR, error };
  }
}

export function cancelWhoHiddenMe() {
  return { type: WHO_HIDDEN__WHO_HIDDEN_ME_CANCEL };
}

export function fetchWhomHiddenIm() {
  // Кого скрыл я

  return async (dispatch, getState) => {
    try {
      const state = getState().toolWhoHidden;
      let result = [];

      if (state.whomHiddenIm.loading) return;

      dispatch(request());

      const whoHiddenIm = await APIEye3GetHideITool();

      // Проверка ошибок в API
      const errorAPI = checkEYE3ApiError(whoHiddenIm);
      if (errorAPI !== false) {
        errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
        dispatch(error({ ...errorAPI }));
        return;
      }

      //console.log(whoHiddenIm);

      if (whoHiddenIm.hasOwnProperty("is_closed") && whoHiddenIm.is_closed === true) {
        dispatch(
          error({ ...defaultErrorProperty, message: "Профиль скрыт", isVisibleMessage: true })
        );
        return;
      } else if (whoHiddenIm.hasOwnProperty("error_data") && whoHiddenIm.error_data === true) {
        dispatch(
          error({
            ...defaultErrorProperty,
            message: "Ошибка при получении данных",
            isVisibleMessage: true,
          })
        );
        return;
      } else if (whoHiddenIm.hasOwnProperty("users") && whoHiddenIm.users.length !== 0) {
        const whoHiddenImInfo = await delayMethodGetUsersVKApi({
          userIds: whoHiddenIm.users,
          fields: ["photo_100", "bdate", "city", "country"],
        });

        const whoHiddenImItems = whoHiddenImInfo;

        result = whoHiddenImItems.map((user) => {
          return {
            vk_id: user.id,
            name: user.first_name + " " + user.last_name,
            age: user.bdate ? calcAge(user.bdate) : null, // Расчет возраста по дате рождения
            city: user.city ? user.city.title : null, // Город
            city_id: user.city ? user.city.id.toString() : null, // Город id
            photo_url: user.photo_100 ? user.photo_100 : "", // Фото
            country: user.country ? user.country.title : null, // Страна
            country_id: user.country ? user.country.id.toString() : null, // Страна id
          };
        });
      }

      dispatch(success(result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: WHO_HIDDEN__WHOM_HIDDEN_IM_REQUEST };
  }

  function success(payload) {
    return { type: WHO_HIDDEN__WHOM_HIDDEN_IM_SUCCESS, payload };
  }

  function error(error) {
    return { type: WHO_HIDDEN__WHOM_HIDDEN_IM_ERROR, error };
  }
}

export function fetchWhoHiddenAnotherUser() {
  // Кто скрыл другого пользователя

  return async (dispatch, getState) => {
    try {
      dispatch(request());

      const state = getState().toolWhoHidden.whoHiddenAnotherUser;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.search,
        filter: state.filter,
        cache: state.cachePayload,
      });

      //console.log(users);

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_REQUEST };
  }

  function success(payload) {
    return { type: WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_SUCCESS, payload };
  }

  function error(error) {
    return { type: WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_ERROR, error };
  }
}

export function showMoreWhoHiddenAnotherUser() {
  return { type: WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_SHOW_MORE };
}

export function searchWhoHiddenAnotherUser(search) {
  return async (dispatch, getState) => {
    try {
      dispatch(request(search));

      const state = getState().toolWhoHidden.whoHiddenAnotherUser;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.search,
        filter: state.filter,
        cache: state.cachePayload,
      });

      //console.log(users);

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(search) {
    return { type: WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_SEARCH_REQUEST, search };
  }

  function success(payload) {
    return { type: WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_SEARCH_SUCCESS, payload };
  }

  function error(error) {
    return { type: WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_SEARCH_ERROR, error };
  }
}

export function filterWhoHiddenAnotherUser(filter) {
  return async (dispatch, getState) => {
    try {
      dispatch(request(filter));

      const state = getState().toolWhoHidden.whoHiddenAnotherUser;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.search,
        filter: state.filter,
        cache: state.cachePayload,
      });

      //console.log(users);

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(filter) {
    return { type: WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_FILTER_REQUEST, filter };
  }

  function success(payload) {
    return { type: WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_FILTER_SUCCESS, payload };
  }

  function error(error) {
    return { type: WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_FILTER_ERROR, error };
  }
}

export function fetchWhomHiddenAnotherUser() {
  // Кого скрыл другогй пользователь
  return async (dispatch, getState) => {
    try {
      const state = getState().toolWhoHidden.whomHiddenAnotherUser;

      dispatch(request());

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.search,
        filter: state.filter,
        cache: state.cachePayload,
      });

      //console.log(users);

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_REQUEST };
  }

  function success(payload) {
    return { type: WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_SUCCESS, payload };
  }

  function error(error) {
    return { type: WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_ERROR, error };
  }
}

export function showMoreWhomHiddenAnotherUser() {
  return { type: WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_SHOW_MORE };
}

export function searchWhomHiddenAnotherUser(search) {
  return async (dispatch, getState) => {
    try {
      dispatch(request(search));

      const state = getState().toolWhoHidden.whomHiddenAnotherUser;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.search,
        filter: state.filter,
        cache: state.cachePayload,
      });

      //console.log(users);

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(search) {
    return { type: WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_SEARCH_REQUEST, search };
  }

  function success(payload) {
    return { type: WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_SEARCH_SUCCESS, payload };
  }

  function error(error) {
    return { type: WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_SEARCH_ERROR, error };
  }
}

export function filterWhomHiddenAnotherUser(filter) {
  return async (dispatch, getState) => {
    try {
      dispatch(request(filter));

      const state = getState().toolWhoHidden.whomHiddenAnotherUser;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.search,
        filter: state.filter,
        cache: state.cachePayload,
      });

      //console.log(users);

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(filter) {
    return { type: WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_FILTER_REQUEST, filter };
  }

  function success(payload) {
    return { type: WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_FILTER_SUCCESS, payload };
  }

  function error(error) {
    return { type: WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_FILTER_ERROR, error };
  }
}

export function fetchWhoHiddenAnotherUserInfo() {
  return async (dispatch, getState) => {
    try {
      const state = getState().toolWhoHidden.whoHiddenAnotherUserInfo;
      const pageParam = getState().page.param;

      const id = pageParam ? pageParam.activeUserId : state.activeUserId;

      if (state.loading) return;

      dispatch(request(id));

      async function process() {
        try {
          if (!getState().toolWhoHidden.whoHiddenAnotherUserInfo.loading) return;

          const whoHiddenUser = await APIEye3GetUserHideTool({
            userId: id,
          });

          let result = [];

          // Проверка ошибок в API
          const errorAPI = checkEYE3ApiError(whoHiddenUser);
          if (errorAPI !== false) {
            errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
            dispatch(error({ ...errorAPI }));
            return;
          }

          if (whoHiddenUser.status_task === "work") {
            dispatch(progress(whoHiddenUser.percent));
            await delay(1000);
            await process();
          } else if (whoHiddenUser.status_task === "success") {
            if (
              !whoHiddenUser.result.hasOwnProperty("user_hide_me") ||
              whoHiddenUser.result.user_hide_me.length === 0
            )
              return dispatch(success(result));

            const whoHiddenUserInfo = await delayMethodGetUsersVKApi({
              userIds: whoHiddenUser.result.user_hide_me,
              fields: ["photo_100", "bdate", "city", "country"],
            });

            const whoHiddenUserItems = whoHiddenUserInfo;

            result = whoHiddenUserItems.map((user) => {
              return {
                vk_id: user.id,
                name: user.first_name + " " + user.last_name,
                age: user.bdate ? calcAge(user.bdate) : null, // Расчет возраста по дате рождения
                city: user.city ? user.city.title : null, // Город
                city_id: user.city ? user.city.id.toString() : null, // Город id
                photo_url: user.photo_100 ? user.photo_100 : "", // Фото
                country: user.country ? user.country.title : null, // Страна
                country_id: user.country ? user.country.id.toString() : null, // Страна id
              };
            });

            dispatch(success(result));
          } else if (whoHiddenUser.status_task === "error") {
            dispatch(error({ ...defaultErrorProperty, message: "" }));
          }

          return;
        } catch (e) {
          dispatch(error({ ...defaultErrorProperty, message: e }));
        }
      }

      await process();
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(id) {
    return { type: WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_INFO_REQUEST, id };
  }

  function progress(progressValue) {
    return { type: WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_INFO_PROGRESS, progressValue };
  }

  function success(payload) {
    return { type: WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_INFO_SUCCESS, payload };
  }

  function error(error) {
    return { type: WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_INFO_ERROR, error };
  }
}

export function cancelWhoHiddenAnotherUserInfo() {
  return { type: WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_INFO_CANCEL };
}

export function fetchWhomHiddenAnotherUserInfo() {
  return async (dispatch, getState) => {
    try {
      const state = getState().toolWhoHidden.whomHiddenAnotherUserInfo;
      const pageParam = getState().page.param;

      const id = pageParam ? pageParam.activeUserId : state.activeUserId;

      if (state.loading) return;

      dispatch(request(id));

      async function process() {
        try {
          const whomHiddenUser = await APIEye3GetUserHideTool({
            userId: id,
          });

          let result = [];

          // Проверка ошибок в API
          const errorAPI = checkEYE3ApiError(whomHiddenUser);
          if (errorAPI !== false) {
            errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
            dispatch(error({ ...errorAPI }));
            return;
          }

          if (whomHiddenUser.status_task === "work") {
            dispatch(progress(whomHiddenUser.percent));
            await delay(1000);
            await process();
          } else if (whomHiddenUser.status_task === "success") {
            if (
              !whomHiddenUser.result.hasOwnProperty("i_hide_user") ||
              whomHiddenUser.result.i_hide_user.length === 0
            )
              return dispatch(success(result));

            const whomHiddenUserInfo = await delayMethodGetUsersVKApi({
              userIds: whomHiddenUser.result.i_hide_user,
              fields: ["photo_100", "bdate", "city", "country"],
            });

            //console.log('whomHiddenUserInfo: ', whomHiddenUserInfo);

            const whomHiddenUserItems = whomHiddenUserInfo;

            result = whomHiddenUserItems.map((user) => {
              return {
                vk_id: user.id,
                name: user.first_name + " " + user.last_name,
                age: user.bdate ? calcAge(user.bdate) : null, // Расчет возраста по дате рождения
                city: user.city ? user.city.title : null, // Город
                city_id: user.city ? user.city.id.toString() : null, // Город id
                photo_url: user.photo_100 ? user.photo_100 : "", // Фото
                country: user.country ? user.country.title : null, // Страна
                country_id: user.country ? user.country.id.toString() : null, // Страна id
              };
            });

            dispatch(success(result));
          } else if (whomHiddenUser.status_task === "error") {
            dispatch(error({ ...defaultErrorProperty, message: "" }));
          }

          return;
        } catch (e) {
          dispatch(error({ ...defaultErrorProperty, message: e }));
        }
      }

      await process();
    } catch (e) {
      dispatch(error({ message: e }));
    }
  };

  function request(id) {
    return { type: WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_INFO_REQUEST, id };
  }

  function progress(progressValue) {
    return { type: WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_INFO_PROGRESS, progressValue };
  }

  function success(payload) {
    return { type: WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_INFO_SUCCESS, payload };
  }

  function error(error) {
    return { type: WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_INFO_ERROR, error };
  }
}

export function cancelWhomHiddenAnotherUserInfo() {
  return { type: WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_INFO_CANCEL };
}
