import React from "react";
import styles from "./EducationStep.module.scss";

const EducationStep = (props) => {
  return (
    <div className={styles.container}>
      <div></div>
      <div className={styles.sectionTop}>
        <div className={styles.icon}>{props.icon}</div>
        <div className={styles.header}>{props.header}</div>
      </div>
      <div className={styles.sectionCenter}>
        <div className={styles.description}>{props.description}</div>
      </div>
      <div className={styles.sectionBottom}>{props.action}</div>
    </div>
  );
};

EducationStep.defaultProps = {
  header: "",
  icon: null,
  description: "",
  action: null,
};

export default EducationStep;
