export const AQUARIUS = "aquarius";
export const PISCES = "pisces";
export const ARIES = "aries";
export const TAURUS = "taurus";
export const GEMINI = "gemini";
export const CANCER = "cancer";
export const LEO = "leo";
export const VIRGO = "virgo";
export const LIBRA = "libra";
export const SCORPIO = "scorpio";
export const SAGITTARIUS = "sagittarius";
export const CAPRICORN = "capricorn";
