import { combineReducers } from "redux";

import myProfile from "./reducers/myProfile";
import guestV2 from "./reducers/guestV2";
import friend from "./reducers/friend";
import page from "./reducers/page";
import vkStorage from "./reducers/vkStorage";
import shop from "./reducers/shop";
import partner from "./reducers/partner";

// tool
import toolTotalSurveillance from "./reducers/tool/totalSurveillance";
import toolWhoHidden from "./reducers/tool/whoHidden";
import toolWhoAddedChs from "./reducers/tool/whoAddedChs";
import toolRegistrationDate from "./reducers/tool/registrationDate";
import toolAdminCommunity from "./reducers/tool/adminCommunity";
import toolJoinCommunity from "./reducers/tool/joinCommunity";
import toolTimeOnline from "./reducers/tool/timeOnline";
import toolActivity from "./reducers/tool/activity";
import toolGift from "./reducers/tool/gift";
import toolWhoNotOnline from "./reducers/tool/whoNotOnline";
import toolTrap from "./reducers/tool/trap";
import toolNewFriends from "./reducers/tool/newFriends";
import toolDeletedFriends from "./reducers/tool/deletedFriends";
import toolPair from "./reducers/tool/pair";
import toolFriendsInApp from "./reducers/tool/friendsInApp";
import toolSubscribersFilter from "./reducers/tool/subscribersFilter";
import toolCommonFriends from "./reducers/tool/commonFriends";
import toolPosting from "./reducers/tool/posting";
import toolDownloadHistory from "./reducers/tool/downloadHistory";
import toolHistoryStatistics from "./reducers/tool/historyStatistics";
import toolFan from "./reducers/tool/fan";
import toolMention from "./reducers/tool/mention";
import toolMaritalStatus from "./reducers/tool/maritalStatus";
import toolCommonCommunity from "./reducers/tool/commonCommunity";
import toolPopularCommunity from "./reducers/tool/popularCommunity";
import toolWhoListenFriends from "./reducers/tool/whoListenFriends";
import toolMoyCrush from "./reducers/tool/moyCrush";

// profile
import profile from "./reducers/profile/profile";
import profileViewPage from "./reducers/profile/viewPage";
import profileViewStories from "./reducers/profile/viewStories";
import profileLike from "./reducers/profile/like";
import profileComment from "./reducers/profile/comment";
import profileTimeOnline from "./reducers/profile/timeOnline";
import profileInfoHistory from "./reducers/profile/infoHistory";
import profileMaritalStatus from "./reducers/profile/maritalStatus";
import profileFriendHistory from "./reducers/profile/friendHistory";
import profileJoinCommunity from "./reducers/profile/joinCommunity";
import profileAllLike from "./reducers/profile/allLike";
import profileAllComment from "./reducers/profile/allComment";
import profileGift from "./reducers/profile/gift";
import profileHoroscope from "./reducers/profile/horoscope";

// popular tool items
import toolPopularItems from "./reducers/tool/toolList";

export default combineReducers({
  myProfile,
  profile,
  guestV2,
  friend,
  page,
  vkStorage,
  shop,
  partner,
  toolFan,
  profileViewPage,
  profileViewStories,
  profileLike,
  profileComment,
  profileTimeOnline,
  profileInfoHistory,
  profileMaritalStatus,
  profileFriendHistory,
  profileJoinCommunity,
  profileAllLike,
  profileAllComment,
  profileGift,
  profileHoroscope,
  toolTotalSurveillance,
  toolGift,
  toolWhoNotOnline,
  toolWhoHidden,
  toolWhoAddedChs,
  toolRegistrationDate,
  toolAdminCommunity,
  toolJoinCommunity,
  toolMention,
  toolMoyCrush,
  toolTimeOnline,
  toolActivity,
  toolTrap,
  toolMaritalStatus,
  toolNewFriends,
  toolDeletedFriends,
  toolPair,
  toolFriendsInApp,
  toolSubscribersFilter,
  toolCommonFriends,
  toolPosting,
  toolPopularItems,
  // toolRoulette,
  toolDownloadHistory,
  toolHistoryStatistics,
  toolCommonCommunity,
  toolPopularCommunity,
  toolWhoListenFriends,
});
