import {
  Button,
  Div,
  FormLayout,
  FormLayoutGroup,
  FormStatus,
  Input,
  Panel,
} from "@vkontakte/vkui";
import { Formik } from "formik";
import { withRouter } from "hoc";
import * as Yup from "yup";
import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import AffiliateBalance from "views/myProfile/components/AffiliateBalance/AffiliateBalance";
import CoinIcon from "assets/img/panelHeader/coin.png";
import styles from "./index.module.scss";
import { clsWithdraw, withdrawToCoin } from "store/actions/partner";
import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { useSelector } from "react-redux";

const PanelAffiliateWithdrawToCoin = (props) => {
  const dispatch = useDispatch();
  const payload = useSelector((state) => state.partner.withdrawToCoin.payload);
  const loading = useSelector((state) => state.partner.withdrawToCoin.loading);
  const error = useSelector((state) => state.partner.withdrawToCoin.error);
  const errorMessage = error !== null && typeof error === "object" ? error.message : "";
  const isVisibleMessage =
    error !== null && typeof error === "object" ? error.isVisibleMessage : false;

  const handleChangeOnlyNumber = (e, fieldName, setFieldValue) => {
    e.preventDefault();

    if (error !== null || payload !== null) handleClsWithdraw();

    const { value } = e.target;
    const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
    if (!value || regex.test(value.toString())) {
      setFieldValue(fieldName, value);
    }
  };

  const handleWithdrawToCoin = useCallback(
    (sum) => {
      dispatch(withdrawToCoin(sum));
    },
    [dispatch]
  );

  const handleClsWithdraw = useCallback(() => {
    dispatch(clsWithdraw());
  }, [dispatch]);

  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack} isVisibleBalance={false}>
        Пополнить баланс
      </PanelHeaderWithPath>
      <Div>
        <AffiliateBalance style={{ marginBottom: "0px" }} />
      </Div>
      <Formik
        initialValues={{ sum: "", isValidServer: payload === null ? false : true }}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          sum: Yup.number().positive().required("Введите сумму"),
        })}
        onSubmit={(values, { resetForm }) => {
          handleWithdrawToCoin(values.sum);
          if (values.isValidServer) {
            resetForm();
          }
        }}
      >
        {({ values, errors, touched, handleBlur, handleSubmit, setFieldValue }) => (
          <>
            <FormLayout>
              <FormLayoutGroup
                status={
                  !errors.sum && touched.sum ? "valid" : errors.sum && touched.sum ? "error" : null
                }
                bottom={touched.sum && errors.sum ? errors.sum : ""}
                top="Сумма"
                className={styles.formLayoutGroup}
              >
                <div className={styles.inputSection}>
                  <div className={styles.inputImg}>
                    <img width="20" height="20" src={CoinIcon} alt=""></img>
                  </div>
                  <Input
                    name="sum"
                    maxLength="7"
                    pattern="[0-9]*"
                    value={values.sum}
                    onBlur={handleBlur}
                    status={
                      !errors.sum && touched.sum
                        ? "valid"
                        : errors.sum && touched.sum
                        ? "error"
                        : null
                    }
                    onChange={(e) => {
                      handleChangeOnlyNumber(e, "sum", setFieldValue);
                    }}
                  ></Input>
                </div>
                <div className={styles.paymentSection}>
                  <p className={styles.paymentTitle}>К оплате:</p>
                  <p className={styles.paymentValue}>{values.sum ? values.sum + " ₽" : "0 ₽"}</p>
                </div>
              </FormLayoutGroup>
              {error !== null && (
                <FormStatus mode="error">{isVisibleMessage ? errorMessage : ""}</FormStatus>
              )}
              {payload === true && (
                <FormStatus style={{ color: "var(--field_valid_border)" }} mode="default">
                  Монеты успешно зачислены
                </FormStatus>
              )}
            </FormLayout>
            <Div>
              <div>
                <Button
                  size="xl"
                  disabled={loading}
                  type="submit"
                  mode="primary"
                  onClick={handleSubmit}
                >
                  Купить монеты
                </Button>
              </div>
            </Div>
          </>
        )}
      </Formik>
    </Panel>
  );
};

export default withRouter(PanelAffiliateWithdrawToCoin);
