import { Group, Placeholder, SimpleCell } from "@vkontakte/vkui";
import React from "react";
import {
  Icon24StoryOutline,
  Icon24ViewOutline,
  Icon24LikeOutline,
  Icon24MessageOutline,
  Icon28StoryAddOutline,
  Icon24ShareOutline,
  Icon28StoryOutline,
} from "@vkontakte/icons";
import { useSelector } from "react-redux";
import TopList from "./components/TopList/TopList";

const StatsTab = (props) => {
  const stats = useSelector((state) => state.toolHistoryStatistics.stats.payload);
  const publish = stats !== null ? stats.publish : 0;
  const views = stats !== null ? stats.views : 0;
  const likes = stats !== null ? stats.likes : 0;
  const answers = stats !== null ? stats.answers : 0;
  const replies = stats !== null ? stats.replies : 0;
  const shares = stats !== null ? stats.shares : 0;

  const top = stats !== null ? stats.top : null;
  const topViews = top !== null ? top.views : [];
  const topLikes = top !== null ? top.likes : [];
  const topAnswers = top !== null ? top.answers : [];
  const topReplies = top !== null ? top.replies : [];
  const topShares = top !== null ? top.shares : [];

  return (
    <>
      {publish !== 0 ? (
        <>
          <Group separator="hide">
            <SimpleCell
              before={<Icon24StoryOutline />}
              after={<span style={{ color: "var(--text_secondary)" }}>{publish}</span>}
            >
              Опубликовано
            </SimpleCell>
            <SimpleCell
              before={<Icon24ViewOutline />}
              after={<span style={{ color: "var(--text_secondary)" }}>{views}</span>}
            >
              Просмотров
            </SimpleCell>
            <SimpleCell
              before={<Icon24LikeOutline />}
              after={<span style={{ color: "var(--text_secondary)" }}>{likes}</span>}
            >
              Лайков
            </SimpleCell>
            <SimpleCell
              before={<Icon24MessageOutline />}
              after={<span style={{ color: "var(--text_secondary)" }}>{answers}</span>}
            >
              Ответов
            </SimpleCell>
            <SimpleCell
              before={<Icon28StoryAddOutline width={24} height={24} />}
              after={<span style={{ color: "var(--text_secondary)" }}>{replies}</span>}
            >
              Ответов через историю
            </SimpleCell>
            <SimpleCell
              before={<Icon24ShareOutline />}
              after={<span style={{ color: "var(--text_secondary)" }}>{shares}</span>}
            >
              Репостов
            </SimpleCell>
          </Group>
          {views !== 0 && <TopList header="Топ по просмотрам" type="views" items={topViews} />}
          {likes !== 0 && <TopList header="Топ по лайкам" type="likes" items={topLikes} />}
          {answers !== 0 && <TopList header="Топ по ответам" type="answers" items={topAnswers} />}
          {replies !== 0 && (
            <TopList header="Топ по ответам через историю" type="replies" items={topReplies} />
          )}
          {shares !== 0 && <TopList header="Топ по репостам" type="shares" items={topShares} />}
        </>
      ) : (
        <Placeholder icon={<Icon28StoryOutline width={56} height={56} />}>
          Истории не найдены
        </Placeholder>
      )}
    </>
  );
};

export default StatsTab;
