import { APIEye3MutualFriendsTool } from "api/eye3";
import { checkSearchURL, getSearchFilterFriendsV2 } from "helpers/actions";
import { calcAge } from "helpers";
import {
  COMMON_FRIENDS_REQUEST,
  COMMON_FRIENDS_SUCCESS,
  COMMON_FRIENDS_ERROR,
  COMMON_FRIENDS_SHOW_MORE,
  COMMON_FRIENDS_SEARCH_REQUEST,
  COMMON_FRIENDS_SEARCH_SUCCESS,
  COMMON_FRIENDS_SEARCH_ERROR,
  COMMON_FRIENDS_FILTER_REQUEST,
  COMMON_FRIENDS_FILTER_SUCCESS,
  COMMON_FRIENDS_FILTER_ERROR,
  COMMON_FRIENDS_CANCEL,
} from "../../constType";
import { openModal } from "../page";
import { checkEYE3ApiError, defaultErrorProperty } from "api/eye3/responseHandler";

async function getCallbackResult(usersItems, dispatch, getState, search = "") {
  try {
    let result = [];
    let prepareResult = [];
    // Проверка на vk URL в поиске
    const isCheckSearchUrl = checkSearchURL({ search: search });

    const friends = await APIEye3MutualFriendsTool({
      userIds: isCheckSearchUrl.isSearchVkId ? isCheckSearchUrl.searchValue : null,
    });

    // console.log("friends: ", friends);

    // Проверка ошибок в API
    const errorAPI = checkEYE3ApiError(friends);
    if (errorAPI !== false) {
      errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
      dispatch(error({ ...errorAPI }));
      return result;
    }

    if (!friends.status) return result;

    const friendsItems = friends.items.length !== 0 ? friends.items : [];

    if (friendsItems.length === 0) return result;

    usersItems.forEach((user) => {
      if (!user.hasOwnProperty("deactivated")) {
        for (let i = 0; i < friendsItems.length; i++) {
          if (user.id === friendsItems[i].id) {
            prepareResult.push({
              vk_id: user.id,
              name: user.first_name + " " + user.last_name,
              age: user.bdate ? calcAge(user.bdate) : null, // Расчет возраста по дате рождения
              sex: user.sex ? user.sex.toString() : null,
              city: user.city ? user.city.title : null, // Город
              city_id: user.city ? user.city.id.toString() : null, // Город id
              photo_url: user.photo_100 ? user.photo_100 : "", // Фото
              country: user.country ? user.country.title : null, // Страна
              country_id: user.country ? user.country.id.toString() : null, // Страна id
              common_friends_ids: friendsItems[i].common_friends,
              common_count: friendsItems[i].common_count,
            });

            break;
          }
        }
      }
    });

    if (prepareResult.length === 0) return result;

    //console.log('prepareResult: ', prepareResult);

    for (let prepareItem of prepareResult) {
      let commonFriends = [];
      const frinedsIds = prepareItem.common_friends_ids;

      for (let i = 0; i < frinedsIds.length; i++) {
        for (let j = 0; j < prepareResult.length; j++) {
          if (prepareResult[j].vk_id === frinedsIds[i]) {
            commonFriends.push(prepareResult[j]);
            break;
          }
        }
      }

      result.push({
        ...prepareItem,
        common_friends: commonFriends,
      });
    }

    result = result.sort((a, b) => b.common_count - a.common_count);

    //console.log('result: ', result);

    return result;
  } catch (e) {
    throw new Error("getCallbackResult::: " + e);
  }

  function error(error) {
    return { type: COMMON_FRIENDS_ERROR, error };
  }
}

export function fetchCommonFriends() {
  return async (dispatch, getState) => {
    try {
      dispatch(request());

      const state = getState().toolCommonFriends;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.search,
        filter: state.filter,
        cache: state.cachePayload,
        asyncCallbackResult: (usersItems) => {
          return getCallbackResult(usersItems, dispatch, getState);
        },
      });

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: COMMON_FRIENDS_REQUEST };
  }

  function success(payload) {
    return { type: COMMON_FRIENDS_SUCCESS, payload };
  }

  function error(error) {
    return { type: COMMON_FRIENDS_ERROR, error };
  }
}

export function showMoreCommonFriends() {
  return { type: COMMON_FRIENDS_SHOW_MORE };
}

export function cancelCommonFriends() {
  return { type: COMMON_FRIENDS_CANCEL };
}

export function searchCommonFriends(search) {
  return async (dispatch, getState) => {
    try {
      dispatch(request(search));

      const state = getState().toolCommonFriends;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.search,
        filter: state.filter,
        cache: state.cachePayload,
        asyncCallbackResult: (usersItems) => {
          return getCallbackResult(usersItems, dispatch, getState, search);
        },
      });

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(search) {
    return { type: COMMON_FRIENDS_SEARCH_REQUEST, search };
  }

  function success(payload) {
    return { type: COMMON_FRIENDS_SEARCH_SUCCESS, payload };
  }

  function error(error) {
    return { type: COMMON_FRIENDS_SEARCH_ERROR, error };
  }
}

export function filterCommonFriends(filter) {
  return async (dispatch, getState) => {
    try {
      dispatch(request(filter));

      const state = getState().toolCommonFriends;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.search,
        filter: state.filter,
        cache: state.cachePayload,
        asyncCallbackResult: (usersItems) => {
          return getCallbackResult(usersItems, dispatch, getState);
        },
      });

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(filter) {
    return { type: COMMON_FRIENDS_FILTER_REQUEST, filter };
  }

  function success(payload) {
    return { type: COMMON_FRIENDS_FILTER_SUCCESS, payload };
  }

  function error(error) {
    return { type: COMMON_FRIENDS_FILTER_ERROR, error };
  }
}
