import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import WhomHiddenImItem from "../WhomHiddenImItem/WhomHiddenImItem";
import WhoHiddenInfo from "../../../components/WHInfo/WHInfo";

import { fetchWhomHiddenIm } from "store/actions/tool/whoHidden";

import { declOfNum } from "helpers";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import HeadTitle from "template/components/HeadTitle/HeadTitle";

const WhomHiddenImList = (props) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.toolWhoHidden.whomHiddenIm.loading);
  const error = useSelector((state) => state.toolWhoHidden.whomHiddenIm.error);
  const hiddenUsers = useSelector((state) => state.toolWhoHidden.whomHiddenIm.payload);
  const handleFetchWhomHiddenIm = useCallback(() => dispatch(fetchWhomHiddenIm()), [dispatch]);

  useEffect(() => {
    handleFetchWhomHiddenIm();
  }, [handleFetchWhomHiddenIm]);

  const renderHiddenUserList = () => {
    return hiddenUsers.length > 0 ? (
      hiddenUsers.map((user, i) => {
        const id = user.vk_id;
        const name = user.name;
        const city = user.city ? user.city : "";
        const age = user.age ? user.age : "";
        const photo = user.photo_url ? user.photo_url : "";

        const ageStrFormat = age ? age + " " + declOfNum(age, ["год", "года", "лет"]) : "";
        const comma = ageStrFormat && city ? ", " : "";

        return (
          <WhomHiddenImItem
            key={i}
            id={id}
            title={name}
            description={ageStrFormat + comma + city}
            photo={photo}
            openView={props.openView}
          />
        );
      })
    ) : (
      <WhoHiddenInfo description={"Вы не скрыли ни одного пользователя"} />
    );
  };

  return (
    <>
      <HeadTitle>Кого скрыл я</HeadTitle>
      {hiddenUsers !== null ? renderHiddenUserList() : ""}
      {renderLoader({ loading: loading })}

      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchWhomHiddenIm,
      })}
    </>
  );
};

export default WhomHiddenImList;
