import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import TimeOnlineItem from "../TimeOnlineItem/TimeOnlineItem";
import { Separator, Placeholder, SimpleCell, Avatar } from "@vkontakte/vkui";
import SeparateFilter from "template/components/SeparateFilter/SeparateFilter";
import Icon56RecentOutline from "@vkontakte/icons/dist/56/recent_outline";

import PopoutTemplateDateChangePeriod from "template/popouts/DateChangePeriod/DateChangePeriod";

import { convertTime, unixTimeToDate, convertMonthDay, calcTimeBetween } from "helpers";

import { fetchTimeOnline, setPeriod, showMoreTimeOnline } from "store/actions/profile/timeOnline";
import { renderPeriodV2 } from "template/popouts/DateChangePeriod/views";
import { declOfNum } from "helpers";
import { PANEL_PROFILE_TIME_ONLINE } from "views/profile/panels/constPanels";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import { useShowMore } from "hooks";

const TimeOnlineList = (props) => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const activeStory = useSelector((state) => state.page.activeStory);
  const activeView = useSelector((state) => state.page.activeView);
  const profileHistory = useSelector((state) => state.profile.history);

  const online = useSelector((state) => state.profileTimeOnline.payload);
  const error = useSelector((state) => state.profileTimeOnline.error);
  const period = useSelector((state) => state.profileTimeOnline.period);
  const loading = useSelector((state) => state.profileTimeOnline.loading);
  const showCount = useSelector((state) => state.profileTimeOnline.showCount);

  const handleFetchTimeOnline = useCallback(() => dispatch(fetchTimeOnline()), [dispatch]);
  const handleShowMoreTimeOnline = useCallback(() => dispatch(showMoreTimeOnline()), [dispatch]);
  const handleSetPeriod = useCallback(
    (name, atMin = null, atMax = null) => dispatch(setPeriod(name, atMin, atMax)),
    [dispatch]
  );

  let lastAt = new Date();

  useEffect(() => {
    handleFetchTimeOnline();
  }, [handleFetchTimeOnline, period]);

  useShowMore({
    items: online,
    showCount: showCount,
    maxLength: online !== null ? online.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMoreTimeOnline,
  });

  const timeSecToMinHourStr = (time) => {
    let minutes = Math.round(time / 60);
    return minutes + " мин ";
  };

  const renderTopPanel = () => {
    const user = profileHistory[activeStory].user;
    const name = user.name;
    const city = user.city ? user.city : "";
    const age = user.age ? user.age : "";
    const photo = user.photo_url ? user.photo_url : "";
    const ageStrFormat = age ? age + " " + declOfNum(age, ["год", "года", "лет"]) : "";
    const comma = ageStrFormat && city ? ", " : "";

    return (
      <SimpleCell
        disabled
        before={<Avatar size={72} src={photo} />}
        description={ageStrFormat + comma + city}
      >
        {name}
      </SimpleCell>
    );
  };

  const renderOnline = () => {
    return online.length > 0 ? (
      online.map((user, i) => {
        const periodStart = unixTimeToDate(user.period_start);
        const periodEnd = unixTimeToDate(user.period_end);
        const periodBetween = calcTimeBetween(periodStart, periodEnd);
        const periodSecMsg = user.period_sec_msg;
        const periodSecContent = user.period_sec_content;
        const periodIsMobile = user.period_is_mobile;

        let separate = (
          <SeparateFilter style={{ marginTop: "16px" }}>
            {convertMonthDay(periodEnd)}
          </SeparateFilter>
        );

        if (lastAt.getDate() === periodEnd.getDate() && i !== 0) {
          separate = "";
        }

        lastAt = periodEnd;

        if (i < showCount) {
          return (
            <div key={i}>
              {separate}
              <TimeOnlineItem
                key={i}
                periodBetween={periodBetween}
                widthMsgLine={Math.round((periodSecMsg / 60) * 2)}
                widthContentLine={Math.round((periodSecContent / 60) * 2)}
                periodMsg={timeSecToMinHourStr(periodSecMsg)}
                periodContent={timeSecToMinHourStr(periodSecContent)}
                isOnlineMobile={periodIsMobile}
              >
                {convertTime(periodStart) + " – " + convertTime(periodEnd)}
              </TimeOnlineItem>
              <Separator />
            </div>
          );
        }

        return false;
      })
    ) : (
      <Placeholder icon={<Icon56RecentOutline />}>
        Время онлайн за данный период отсутствует
      </Placeholder>
    );
  };

  const handlePeriod = () => {
    if (loading) return false;

    props.openPopout({
      popout: (
        <PopoutTemplateDateChangePeriod
          openView={props.openView}
          period={period} // Период из redux store
          setPeriod={handleSetPeriod} // action из redux
          backView={activeView}
          backPanel={PANEL_PROFILE_TIME_ONLINE}
          panelTitle={"Время онлайн"}
          closePopout={props.closePopout}
        />
      ),
    });
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      {renderPeriodV2({ title: "Время онлайн", period: period, handler: handlePeriod })}
      {renderTopPanel()}
      {online !== null && error === null ? renderOnline() : ""}
      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchTimeOnline,
      })}
    </div>
  );
};

export default TimeOnlineList;
