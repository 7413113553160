import {
  WHO_NOT_ONLINE_REQUEST,
  WHO_NOT_ONLINE_SUCCESS,
  WHO_NOT_ONLINE_ERROR,
  WHO_NOT_ONLINE_SHOW_MORE,
  WHO_NOT_ONLINE_SEARCH_REQUEST,
  WHO_NOT_ONLINE_SEARCH_SUCCESS,
  WHO_NOT_ONLINE_SEARCH_ERROR,
  WHO_NOT_ONLINE_FILTER_REQUEST,
  WHO_NOT_ONLINE_FILTER_SUCCESS,
  WHO_NOT_ONLINE_FILTER_ERROR,
} from "../../constType";

const initialState = {
  payload: null,
  cachePayload: null,
  loading: false,
  showCount: 20,
  filter: "",
  search: "",
  error: null,
};

export default function whoNotOnlineReducer(state = initialState, action) {
  switch (action.type) {
    case WHO_NOT_ONLINE_REQUEST:
      return {
        ...state,
        payload: null,
        loading: true,
        error: null,
        cachePayload:
          state.search === "" && state.filter === ""
            ? null // Если поле поиска и фильтра пустое то очистить кэш
            : state.cachePayload,
      };
    case WHO_NOT_ONLINE_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: action.payload,
        cachePayload:
          state.search === "" && state.filter === ""
            ? action.payload // Если поле поиска и фильтра пустое то сохранить результат
            : state.cachePayload,
      };
    case WHO_NOT_ONLINE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case WHO_NOT_ONLINE_SHOW_MORE:
      return {
        ...state,
        showCount: parseInt(state.showCount) + 20,
      };
    case WHO_NOT_ONLINE_SEARCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        payload: null,
        showCount: 20,
        search: action.search,
      };
    case WHO_NOT_ONLINE_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: action.payload,
      };
    case WHO_NOT_ONLINE_SEARCH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case WHO_NOT_ONLINE_FILTER_REQUEST:
      return {
        ...state,
        payload: null,
        showCount: 20,
        loading: true,
        error: null,
        filter: action.filter,
      };
    case WHO_NOT_ONLINE_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: action.payload,
      };
    case WHO_NOT_ONLINE_FILTER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
