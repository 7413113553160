import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Panel } from "@vkontakte/vkui";
import TSStartTrackingList from "./components/TSStartTrackingList/TSStartTrackingList";
import { withRouter } from "hoc";

const PanelTSStartTracking = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Тотальная слежка</PanelHeaderWithPath>
      <TSStartTrackingList openModal={props.openModal} closeModal={props.closeModal} />
    </Panel>
  );
};

export default withRouter(PanelTSStartTracking);
