import { queryEye3 } from "api";

async function APIEye3ActiveAllPageTrackActive({
  userId,
  activeType = null,
  timeMin = null,
  timeMax = null,
}) {
  try {
    const response = await queryEye3({
      method: "trackactive.active_all_page",
      get: {
        user_id: userId,
        active_type: activeType,
        time_min: timeMin,
        time_max: timeMax,
      },
      access: { isBotConnect: true, isLicense: true, isLimit: true },
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3ActiveAllPageTrackActive;
