import {
  TOTAL_SURVEILLANCE_REQUEST,
  TOTAL_SURVEILLANCE_SUCCESS,
  TOTAL_SURVEILLANCE_ERROR,
  TOTAL_SURVEILLANCE__START_TRACKING_REQUEST,
  TOTAL_SURVEILLANCE__START_TRACKING_SUCCESS,
  TOTAL_SURVEILLANCE__START_TRACKING_ERROR,
  TOTAL_SURVEILLANCE__START_TRACKING_SHOW_MORE,
  TOTAL_SURVEILLANCE__START_TRACKING_SEARCH_REQUEST,
  TOTAL_SURVEILLANCE__START_TRACKING_SEARCH_SUCCESS,
  TOTAL_SURVEILLANCE__START_TRACKING_SEARCH_ERROR,
  TOTAL_SURVEILLANCE__START_TRACKING_FILTER_REQUEST,
  TOTAL_SURVEILLANCE__START_TRACKING_FILTER_SUCCESS,
  TOTAL_SURVEILLANCE__START_TRACKING_FILTER_ERROR,
  TOTAL_SURVEILLANCE_SET_TRACKING_REQUEST,
  TOTAL_SURVEILLANCE_SET_TRACKING_SUCCESS,
  TOTAL_SURVEILLANCE_SET_TRACKING_ERROR,
  TOTAL_SURVEILLANCE_SET_NOTIFICATION_REQUEST,
  TOTAL_SURVEILLANCE_SET_NOTIFICATION_SUCCESS,
  TOTAL_SURVEILLANCE_SET_NOTIFICATION_ERROR,
} from "../../constType";

const initialState = {
  payloadCacheUser: null,
  settingNotification: {
    payload: null,
  },
  trackedUser: {
    payload: null,
  },
  startTracking: {
    payload: null,
    showCount: 20,
    search: "",
    filter: "",
    loading: false,
    error: null,
    isOnce: false,
  },
  loading: false,
  error: null,
};

export default function totalSurveillanceReducer(state = initialState, action) {
  switch (action.type) {
    case TOTAL_SURVEILLANCE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TOTAL_SURVEILLANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        payloadCacheUser: action.payload,
        trackedUser: {
          ...state.trackedUser,
          payload: action.payload,
        },
        settingNotification: {
          ...state.settingNotification,
          payload: action.payload,
        },
      };
    case TOTAL_SURVEILLANCE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case TOTAL_SURVEILLANCE__START_TRACKING_REQUEST:
      return {
        ...state,
        startTracking: {
          ...state.startTracking,
          loading: true,
          error: null,
          payload: null,
          isOnce: true,
        },
      };
    case TOTAL_SURVEILLANCE__START_TRACKING_SUCCESS:
      return {
        ...state,
        startTracking: {
          ...state.startTracking,
          loading: false,
          payload: action.payload,
        },
      };
    case TOTAL_SURVEILLANCE__START_TRACKING_ERROR:
      return {
        ...state,
        startTracking: {
          ...state.startTracking,
          loading: false,
          error: action.error,
        },
      };
    case TOTAL_SURVEILLANCE__START_TRACKING_SHOW_MORE:
      return {
        ...state,
        startTracking: {
          ...state.startTracking,
          showCount: parseInt(state.startTracking.showCount) + 20,
        },
      };
    case TOTAL_SURVEILLANCE__START_TRACKING_SEARCH_REQUEST:
      return {
        ...state,
        startTracking: {
          ...state.startTracking,
          loading: true,
          error: null,
          payload: null,
          showCount: 20,
          search: action.search,
        },
      };
    case TOTAL_SURVEILLANCE__START_TRACKING_SEARCH_SUCCESS:
      return {
        ...state,
        startTracking: {
          ...state.startTracking,
          loading: false,
          payload: [...action.payload],
        },
      };
    case TOTAL_SURVEILLANCE__START_TRACKING_SEARCH_ERROR:
      return {
        ...state,
        startTracking: {
          ...state.startTracking,
          loading: false,
          error: action.error,
        },
      };
    case TOTAL_SURVEILLANCE__START_TRACKING_FILTER_REQUEST:
      return {
        ...state,
        startTracking: {
          ...state.startTracking,
          payload: null,
          showCount: 20,
          loading: true,
          error: null,
          filter: action.filter,
        },
      };
    case TOTAL_SURVEILLANCE__START_TRACKING_FILTER_SUCCESS:
      return {
        ...state,
        startTracking: {
          ...state.startTracking,
          loading: false,
          payload: [...action.payload],
        },
      };
    case TOTAL_SURVEILLANCE__START_TRACKING_FILTER_ERROR:
      return {
        ...state,
        startTracking: {
          ...state.startTracking,
          loading: false,
          error: action.error,
        },
      };
    case TOTAL_SURVEILLANCE_SET_TRACKING_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TOTAL_SURVEILLANCE_SET_TRACKING_SUCCESS:
      return {
        ...state,
        loading: false,
        startTracking: {
          ...state.startTracking,
          payload: state.startTracking.payload.map((item) => {
            if (item.vk_id === action.id) {
              return {
                ...item,
                is_tracking: action.isEnable,
              };
            }
            return item;
          }),
        },
      };
    case TOTAL_SURVEILLANCE_SET_TRACKING_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case TOTAL_SURVEILLANCE_SET_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TOTAL_SURVEILLANCE_SET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        settingNotification: {
          ...state.settingNotification,
          payload: state.settingNotification.payload.map((item) => {
            if (item.vk_id === action.id) {
              return {
                ...item,
                is_notification: action.isEnable,
              };
            }
            return item;
          }),
        },
      };
    case TOTAL_SURVEILLANCE_SET_NOTIFICATION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
