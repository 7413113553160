import React from "react";

import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";

import GInfoList from "./components/GInfoList/GInfoList";
import { Panel } from "@vkontakte/vkui";
import { withRouter } from "hoc";

const PanelGInfo = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Подарки</PanelHeaderWithPath>
      <GInfoList
        openView={props.openView}
        openPopout={props.openPopout}
        closePopout={props.closePopout}
      />
    </Panel>
  );
};

export default withRouter(PanelGInfo);
