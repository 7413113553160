import React from "react";
import { ModalCard } from "@vkontakte/vkui";
import TariffIcon from "assets/img/template/modals/tariffWork.png";
import { STORY_SHOP } from "stories/constStories";
import { PANEL_MAIN } from "views/shop/panels/constPanels";
import { gotoUrl } from "helpers";

const TariffWork = (props) => {
  const tariffCaption = props.modalParam ? props.modalParam.tariffCaption : "Premium";

  return (
    <ModalCard
      id={props.id}
      header="Для использования инструмента необходимо подключить тариф"
      icon={<img src={TariffIcon} style={{ width: "50px", height: "50px" }} alt=""></img>}
      caption={"Подключи в магазине тариф «" + tariffCaption + "», чтобы использовать инструмент"}
      onClose={props.handleCloseModal}
      actions={[
        {
          title: "Получить монетки",
          mode: "secondary",
          action: () => {
            props.handleCloseModal();
            let url = "https://vk.com/wall-194197472_18172";
            gotoUrl(url);
          },
        },
        {
          title: "В магазин",
          mode: "primary",
          action: () => {
            props.handleCloseModal();
            props.openStory({ story: STORY_SHOP, panel: PANEL_MAIN });
          },
        },
      ]}
    />
  );
};

export default TariffWork;
