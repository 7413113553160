import { useEffect } from "react";

function useShowMore({ items, maxLength, showCount, isTransitionEnd, handler }) {
  useEffect(() => {
    const handleScroll = () => {
      var scrollTop =
        (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
      var scrollHeight =
        (document.documentElement && document.documentElement.scrollHeight) ||
        document.body.scrollHeight;
      var clientHeight = document.documentElement.clientHeight || window.innerHeight;
      var scrolledToBottom = Math.ceil(scrollTop + clientHeight + 20) >= scrollHeight;

      if (scrolledToBottom && items !== null && isTransitionEnd && showCount < maxLength) {
        handler();
      }

      //console.log('handleScroll');
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      //console.log('rm handleScroll');
    };
  }, [handler, showCount, maxLength, items, isTransitionEnd]);
}

export default useShowMore;
