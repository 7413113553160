import React, { useCallback } from "react";
import { Group, Header, Switch, SimpleCell } from "@vkontakte/vkui";
import { useDispatch, useSelector } from "react-redux";
import Icon28Notifications from "@vkontakte/icons/dist/28/notifications";

import { setNotification } from "store/actions/myProfile";

const SettingsApp = () => {
  const dispatch = useDispatch();
  const payloadEYE3 = useSelector((state) =>
    state.myProfile.payloadEYE3 ? state.myProfile.payloadEYE3 : null
  );
  const isNotification = payloadEYE3 ? payloadEYE3.user.is_notification : false;

  const handleSetNotification = useCallback(
    (isEnabled) => {
      dispatch(setNotification(isEnabled));
    },
    [dispatch]
  );

  return (
    <Group header={<Header mode="secondary">Настройки приложения</Header>}>
      {payloadEYE3 && (
        <SimpleCell
          before={<Icon28Notifications />}
          disabled
          after={
            <Switch
              defaultChecked={isNotification}
              onChange={() => handleSetNotification(!isNotification)}
            />
          }
        >
          Уведомления
        </SimpleCell>
      )}
    </Group>
  );
};

export default SettingsApp;
