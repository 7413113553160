import { APIEye3GetV2Guest } from "api/eye3";
import {
  GUEST_REQUEST,
  GUEST_SUCCESS,
  GUEST_ERROR,
  GUEST_FILTER_SUCCESS,
  GUEST_FILTER_REQUEST,
  GUEST_FILTER_ERROR,
  GUEST_SEARCH_ERROR,
  GUEST_SEARCH_REQUEST,
  GUEST_SEARCH_SUCCESS,
  GUEST_SHOW_MORE,
  GUEST_TABS,
} from "../constType";

import { calcAge, dateToUnixTime, getHashRef } from "helpers";
import { VISIT, ACTIVE } from "views/guest/panels/main/constGuest";
import { getFilterFriends, getSearchFriends } from "helpers/actions";
import { setRestoreScroll } from "helpers/actions";
import { delayMethodGetUsersVKApi } from "api";
import { openModal } from "./page";
import { TABS_ACTIVE, TABS_ALL, TABS_VISIT } from "views/guest/panels/main/constTabs";
import { MODAL_CARD_VISITED } from "views/guest/modals/constModals";
import { defaultErrorProperty } from "api/eye3/responseHandler";
import { Link } from "@vkontakte/vkui";
import BotIcon from "assets/img/bot.png";

async function getGuest({ dispatch, filter, tabs, error }) {
  try {
    let result = {
      is_bot_connect: true,
      active_count: 0,
      visit_count: 0,
      total_count: 0,
      new_active_count: 0,
      new_visit_count: 0,
      new_total_count: 0,
      guests: [],
    };

    const guestData = await APIEye3GetV2Guest({
      dateFrom: filter.date_from ? dateToUnixTime(filter.date_from) : null,
      dateTo: filter.date_to ? dateToUnixTime(filter.date_to) : null,
      type: tabs,
    });

    // console.log(guestData);

    if (
      guestData.status === false &&
      guestData.hasOwnProperty("is_bot_connect") &&
      guestData.is_bot_connect === false
    ) {
      result = {
        is_bot_connect: false,
        active_count: 0,
        visit_count: 0,
        total_count: 0,
        new_active_count: 0,
        new_visit_count: 0,
        new_total_count: 0,
        guests: [],
      };
      //console.log('Не подключен бот');
      dispatch(
        error({
          ...defaultErrorProperty,
          icon: <img src={BotIcon} width={238} height={260} alt=""></img>,
          message: (
            <>
              Для использования приложения необходимо подключение к боту. Для этого перейдите в чат
              и напишите «старт» <br />
              <Link href={"https://vk.com/im?sel=-194197472"}>Перейти в чат</Link>
            </>
          ),
          isVisibleMessage: true,
          isTryAgain: false,
        })
      );

      return result;
    } else if (guestData.status === false) {
      dispatch(
        error({
          ...defaultErrorProperty,
          icon: <img src={BotIcon} width={238} height={260} alt=""></img>,
          message: "",
          isTryAgain: true,
        })
      );

      return result;
    }

    if (!guestData.hasOwnProperty("guests")) {
      // console.log("Список гостей пуст");
      return result;
    }

    const guests = guestData.guests;

    if (guests.length === 0) {
      // console.log("Список гостей пуст");
      return result;
    }

    const guestsIds = guests.map((user) => user.vk_id);

    const userInfo = await delayMethodGetUsersVKApi({
      userIds: guestsIds,
      fields: ["photo_100", "bdate", "city", "country", "sex"],
    });

    let userItems = userInfo;

    result = {
      active_count: guestData.active_count,
      visit_count: guestData.visit_count,
      total_count: guestData.total_count,
      new_active_count: guestData.new_active_count,
      new_visit_count: guestData.new_visit_count,
      new_total_count: guestData.new_total_count,
      is_bot_connect: true,
    };

    result.guests = [];

    for (let guest of guests) {
      for (let user of userItems) {
        const bdate = user.bdate ? user.bdate : guest.bdate; // Если найдена дата рождения у вк иначе берем с Eye3

        let type = null;
        switch (guest.type) {
          case VISIT:
            type = "visit";
            break;
          default:
            type = "active";
            break;
        }

        if (guest.vk_id === user.id && !user.hasOwnProperty("deactivated")) {
          result.guests.push({
            vk_id: user.id, // id
            name: user.first_name + " " + user.last_name, // Имя и фамилия
            guest_at: guest.guest_at, // Дата в unixTime
            is_new: guest.is_new, // Если новый
            link: type === ACTIVE ? guest.link : null,
            type: type, // Тип (активность или визит)
            type_name: guest.type_name, // Тип активности или визита
            age: bdate ? calcAge(bdate) : null, // Возраст
            city: user.city ? user.city.title : null, // Город
            city_id: user.city ? user.city.id.toString() : null, // Город id
            photo_url: user.photo_100 ? user.photo_100 : "", // Фото
            country: user.country ? user.country.title : null, // Страна
            country_id: user.country ? user.country.id.toString() : null, // Страна id
            sex: user.sex ? user.sex.toString() : null, // Пол
          });

          break;
        }
      }
    }

    result.guests = getFilterFriends({
      data: result.guests,
      filter: filter,
    });

    return result;
  } catch (e) {
    throw new Error("getGuest::: " + e);
  }
}

export function fetchGuestV2(isRefresh = false) {
  return async (dispatch, getState) => {
    try {
      let myProfileLicense = getState().myProfile.license.payload;
      let payloadEYE3 = getState().myProfile.payloadEYE3;

      /*myProfileLicense.guest_allow = true; // test
            myProfileLicense.license_is_guest = true;*/ // test

      await dispatch(request());
      const state = getState().guestV2;

      if (myProfileLicense === null) {
        await dispatch(
          success({
            is_bot_connect: false,
            active_count: 0,
            visit_count: 0,
            total_count: 0,
            new_active_count: 0,
            new_visit_count: 0,
            new_total_count: 0,
            guests: [],
          })
        );

        return;
      }

      const guests = await getGuest({
        dispatch: dispatch,
        filter: state.filter,
        tabs: myProfileLicense.guest_allow ? state.tabs : TABS_ACTIVE,
        error: error,
      });

      await dispatch(success(guests));

      if (state.search !== "") {
        await dispatch(searchGuestV2(state.search));
      }

      if (!isRefresh) {
        await setRestoreScroll({
          // Востановление позиции скролла
          scrollPositions: getState().page.scrollPosition,
          story: getState().page.activeStory,
          panel: getState().page.activePanel,
        });
      }

      if (
        !myProfileLicense.license_is_guest &&
        !payloadEYE3.user.is_show_education &&
        !payloadEYE3.user.is_roulette &&
        getHashRef() === false &&
        myProfileLicense.guest_allow &&
        (state.tabs === TABS_VISIT || state.tabs === TABS_ALL)
      ) {
        await dispatch(openModal({ id: MODAL_CARD_VISITED }));
      }
    } catch (e) {
      dispatch(
        error({
          ...defaultErrorProperty,
          message: e,
        })
      );
    }
  };

  function request() {
    return { type: GUEST_REQUEST };
  }

  function success(payload) {
    return { type: GUEST_SUCCESS, payload };
  }

  function error(error) {
    return { type: GUEST_ERROR, error };
  }
}

export function showMoreGuestV2() {
  return { type: GUEST_SHOW_MORE };
}

export function tabsGuestV2(tabs) {
  return { type: GUEST_TABS, tabs };
}

export function searchGuestV2(search) {
  return async (dispatch, getState) => {
    try {
      const myProfileLicense = getState().myProfile.license.payload;

      if (myProfileLicense === null) return;

      dispatch(request(search));

      const state = getState().guestV2;

      let result = {
        active_count: state.cachePayload.active_count,
        visit_count: state.cachePayload.visit_count,
        total_count: state.cachePayload.total_count,
        new_active_count: state.cachePayload.new_active_count,
        new_visit_count: state.cachePayload.new_visit_count,
        new_total_count: state.cachePayload.new_total_count,
        is_bot_connect: true,
        guests: [...state.cachePayload.guests],
      };

      //console.log('resultSearch: ', result);

      result.guests = getSearchFriends({
        data: result.guests,
        search: search,
        isSearchVkId: false,
      });

      dispatch(success(result));
    } catch (e) {
      dispatch(
        error({
          ...defaultErrorProperty,
          message: e,
        })
      );
    }
  };

  function request(search) {
    return { type: GUEST_SEARCH_REQUEST, search };
  }

  function success(payload) {
    return { type: GUEST_SEARCH_SUCCESS, payload };
  }

  function error(error) {
    return { type: GUEST_SEARCH_ERROR, error };
  }
}

export function filterGuestV2(filter) {
  return async (dispatch, getState) => {
    try {
      const myProfileLicense = getState().myProfile.license.payload;

      if (myProfileLicense === null) return;

      await dispatch(request(filter));

      const state = getState().guestV2;

      const guests = await getGuest({
        filter: state.filter,
        tabs: myProfileLicense.guest_allow ? state.tabs : TABS_ACTIVE,
      });

      await dispatch(success(guests));

      if (state.search !== "") {
        await dispatch(searchGuestV2(state.search));
      }
    } catch (e) {
      dispatch(
        error({
          ...defaultErrorProperty,
          message: e,
        })
      );
    }
  };

  function request(filter) {
    return { type: GUEST_FILTER_REQUEST, filter };
  }

  function success(payload) {
    return { type: GUEST_FILTER_SUCCESS, payload };
  }

  function error(error) {
    return { type: GUEST_FILTER_ERROR, error };
  }
}
