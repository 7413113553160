import React from "react";
import { useSelector } from "react-redux";
import { Group, List, Header, SimpleCell } from "@vkontakte/vkui";

import Icon16Chevron from "@vkontakte/icons/dist/16/chevron";

import Icon24ViewOutline from "@vkontakte/icons/dist/24/view_outline";
import Icon24StoryOutline from "@vkontakte/icons/dist/24/story_outline";
import Icon24LikeOutline from "@vkontakte/icons/dist/24/like_outline";
import Icon24CommentOutline from "@vkontakte/icons/dist/24/comment_outline";
import Icon24AddSquareOutline from "@vkontakte/icons/dist/24/add_square_outline";
import Icon24RecentOutline from "@vkontakte/icons/dist/24/recent_outline";
import Icon28WriteOutline from "@vkontakte/icons/dist/28/write_outline";
import Icon28UsersOutline from "@vkontakte/icons/dist/28/users_outline";
import Icon24UserAddOutline from "@vkontakte/icons/dist/24/user_add_outline";
import Icon28GiftOutline from "@vkontakte/icons/dist/28/gift_outline";

import {
  PANEL_PROFILE_VIEW_PAGE,
  PANEL_PROFILE_VIEW_STORIES,
  PANEL_PROFILE_LIKE,
  PANEL_PROFILE_COMMENT,
  PANEL_PROFILE_TIME_ONLINE,
  PANEL_PROFILE_INFO_HISTORY,
  PANEL_PROFILE_MARITAL_STATUS,
  PANEL_PROFILE_FRIEND_HISTORY,
  PANEL_PROFILE_JOIN_COMMUNITY,
  PANEL_PROFILE_ALL_LIKE,
  PANEL_PROFILE_ALL_COMMENT,
  PANEL_PROFILE_GIFT,
} from "../../../constPanels";

const ProfileActivity = (props) => {
  const activeView = useSelector((state) => state.page.activeView);

  return (
    <div style={{ marginBottom: "20px" }}>
      <Group header={<Header mode="secondary">Активность на моей странице</Header>}>
        <List>
          <SimpleCell
            before={<Icon24ViewOutline />}
            after={<Icon16Chevron fill="#B8C1CC" />}
            onClick={() => props.openView({ view: activeView, panel: PANEL_PROFILE_VIEW_PAGE })}
          >
            Просмотры страницы
            {/*<span>32</span>*/}
          </SimpleCell>
          <SimpleCell
            before={<Icon24StoryOutline />}
            after={<Icon16Chevron fill="#B8C1CC" />}
            onClick={() => props.openView({ view: activeView, panel: PANEL_PROFILE_VIEW_STORIES })}
          >
            Просмотры историй
          </SimpleCell>
          <SimpleCell
            before={<Icon24LikeOutline />}
            after={<Icon16Chevron fill="#B8C1CC" />}
            onClick={() => props.openView({ view: activeView, panel: PANEL_PROFILE_LIKE })}
          >
            Лайки
          </SimpleCell>
          <SimpleCell
            before={<Icon24CommentOutline />}
            after={<Icon16Chevron fill="#B8C1CC" />}
            onClick={() => props.openView({ view: activeView, panel: PANEL_PROFILE_COMMENT })}
          >
            Комментарии
          </SimpleCell>
        </List>
      </Group>
      <Group header={<Header mode="secondary">Вся активность</Header>}>
        <List>
          <SimpleCell
            before={<Icon24RecentOutline />}
            after={<Icon16Chevron fill="#B8C1CC" />}
            onClick={() => props.openView({ view: activeView, panel: PANEL_PROFILE_TIME_ONLINE })}
          >
            Время онлайн
          </SimpleCell>
          <SimpleCell
            before={<Icon28WriteOutline width={24} height={24} />}
            after={<Icon16Chevron fill="#B8C1CC" />}
            onClick={() => props.openView({ view: activeView, panel: PANEL_PROFILE_INFO_HISTORY })}
          >
            Изменение блока информации
          </SimpleCell>
          <SimpleCell
            before={<Icon28UsersOutline width={24} height={24} />}
            after={<Icon16Chevron fill="#B8C1CC" />}
            onClick={() =>
              props.openView({ view: activeView, panel: PANEL_PROFILE_MARITAL_STATUS })
            }
          >
            Семейное положение
          </SimpleCell>
          <SimpleCell
            before={<Icon24UserAddOutline />}
            after={<Icon16Chevron fill="#B8C1CC" />}
            onClick={() =>
              props.openView({ view: activeView, panel: PANEL_PROFILE_FRIEND_HISTORY })
            }
          >
            Добавление/удаление друзей
          </SimpleCell>
          <SimpleCell
            before={<Icon24AddSquareOutline />}
            after={<Icon16Chevron fill="#B8C1CC" />}
            onClick={() =>
              props.openView({
                view: activeView,
                panel: PANEL_PROFILE_JOIN_COMMUNITY,
                param: {
                  activeUserPeriod: "all",
                },
              })
            }
          >
            Вступления/выход из сообществ
          </SimpleCell>
          <SimpleCell
            before={<Icon24LikeOutline />}
            after={<Icon16Chevron fill="#B8C1CC" />}
            onClick={() => props.openView({ view: activeView, panel: PANEL_PROFILE_ALL_LIKE })}
          >
            Все лайки
          </SimpleCell>
          <SimpleCell
            before={<Icon24CommentOutline />}
            after={<Icon16Chevron fill="#B8C1CC" />}
            onClick={() => props.openView({ view: activeView, panel: PANEL_PROFILE_ALL_COMMENT })}
          >
            Все комментарии
          </SimpleCell>
          <SimpleCell
            before={<Icon28GiftOutline width={24} height={24} />}
            after={<Icon16Chevron fill="#B8C1CC" />}
            onClick={() => props.openView({ view: activeView, panel: PANEL_PROFILE_GIFT })}
          >
            Подарки
          </SimpleCell>
        </List>
      </Group>
    </div>
  );
};

export default ProfileActivity;
