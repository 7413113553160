import { isEye3VkMethodError } from "api";
import { APIEye3VkApiUser } from "api/eye3";

async function APIVkGetUsers({ userIds, fields, nameCase = "Nom" }) {
  const method = "users.get";
  try {
    /*const response = await bridge.send("VKWebAppCallAPIMethod", {
      method: method,
      params: {
        user_ids: userIds,
        fields: fields,
        name_case: nameCase,
        v: getVKApiVersion(),
        access_token: getVKToken(),
      },
    });

    responseHandler(method, response);*/

    const usersAPI = await APIEye3VkApiUser({
      method: method,
      params: {
        user_ids: userIds,
        fields: fields,
      },
    });

    if (isEye3VkMethodError(usersAPI)) {
      throw JSON.stringify(usersAPI);
    }

    return usersAPI.result;
  } catch (e) {
    // errorHandler(method, e);
    throw e;
  }
}

export default APIVkGetUsers;
