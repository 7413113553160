import React from "react";
import { Div } from "@vkontakte/vkui";
import styles from "./SeparateFilter.module.css";

const SeparateFilter = (props) => {
  return (
    <Div className={styles.container} style={props.style}>
      <span className={styles.item}>{props.children}</span>
    </Div>
  );
};

SeparateFilter.defaultProps = {
  style: null,
};

export default SeparateFilter;
