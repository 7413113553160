import { Button } from "@vkontakte/vkui";
import React from "react";
import { useSelector } from "react-redux";
import { VIEW_MY_PROFILE } from "views/constViews";
import {
  PANEL_AFFILIATE_REFERER_HISTORY,
  PANEL_AFFILIATE_WITHDRAW_TO_MONEY,
} from "views/myProfile/panels/constPanels";
import styles from "./AffiliateBalance.module.scss";

const AffiliateBalance = (props) => {
  const payloadEYE3 = useSelector((state) =>
    state.myProfile.payloadEYE3 ? state.myProfile.payloadEYE3 : null
  );
  const balance = payloadEYE3 ? payloadEYE3.ref.balance : 0;
  const count = payloadEYE3 ? payloadEYE3.ref.count : 0;

  return (
    <div className={styles.container} style={props.style}>
      <div
        className={styles.leftSection}
        onClick={() => {
          props.openView !== null &&
            props.openView({ view: VIEW_MY_PROFILE, panel: PANEL_AFFILIATE_REFERER_HISTORY });
        }}
      >
        <p className={styles.balanceTitle}>Баланс</p>
        <p className={styles.balanceValue}>
          {balance}
          <span> ₽</span>
        </p>
        <p className={styles.refValue}>
          <span>Количество рефералов:</span> {count}
        </p>
      </div>
      {props.isWithdrawal && (
        <div className={styles.rightSection}>
          <Button
            mode="commerce"
            onClick={() =>
              props.openView({ view: VIEW_MY_PROFILE, panel: PANEL_AFFILIATE_WITHDRAW_TO_MONEY })
            }
          >
            Вывести
          </Button>
        </div>
      )}
    </div>
  );
};

AffiliateBalance.defaultProps = {
  openView: null,
  style: null,
  isWithdrawal: false,
};

export default AffiliateBalance;
