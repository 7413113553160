import React from "react";
import { Avatar, Button, Div, Group, RichCell } from "@vkontakte/vkui";
import Icon24ShareOutline from "@vkontakte/icons/dist/24/share_outline";
import Icon24StoryOutline from "@vkontakte/icons/dist/24/story_outline";

const RDTopPanel = (props) => {
  return (
    <Group separator="show">
      <Div>
        <RichCell
          disabled
          before={<Avatar size={72} src={props.photo} />}
          caption={
            <div
              style={{
                fontSize: "20px",
                lineHeight: "24px",
                letterSpacing: "0.38px",
                color: "#FFFFFF",
                fontWeight: "bold",
                marginBottom: "3px",
              }}
            >
              {props.description}
            </div>
          }
          bottom={
            <span
              style={{
                fontSize: "15px",
                fontWeight: "400",
                lineHeight: "20px",
                letterSpacing: "0.2px",
                color: "#FFFFFF",
              }}
            >
              {props.bottomContent}
            </span>
          }
          style={{
            background: "radial-gradient(109.55% 109.55% at 0% 0%, #358DFF 0%, #0336FF 100%)",
            borderRadius: "8px",
          }}
        >
          <span
            style={{
              opacity: "0.72",
              fontSize: "11px",
              fontWeight: "500",
              color: "#FFFFFF",
              textTransform: "uppercase",
            }}
          >
            Дата Регистрации
          </span>
        </RichCell>
      </Div>
      <Div
        style={{
          fontSize: "13px",
          lineHeight: "16px",
          letterSpacing: "0.2px",
          fontStyle: "normal",
          fontWeight: "500",
          textTransform: "uppercase",
          color: "#818C99",
          marginTop: "5px",
          paddingTop: "12px",
          paddingBottom: "2px",
        }}
      >
        Поделиться
      </Div>
      <Div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          before={<Icon24ShareOutline />}
          disabled={true}
          onClick={() => props.handlePostWall()}
          mode="outline"
          style={{ width: "100%", marginRight: "14px" }}
        >
          На стене
        </Button>
        <Button
          before={<Icon24StoryOutline />}
          disabled={true}
          onClick={() => props.handlePostHistory()}
          mode="outline"
          style={{ width: "100%" }}
        >
          В истории
        </Button>
      </Div>
    </Group>
  );
};

export default RDTopPanel;
