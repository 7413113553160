import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Panel } from "@vkontakte/vkui";
import FIAAppsFromFriendsList from "./FIAAppsFromFriendsList/FIAAppsFromFriendsList";
import { withRouter } from "hoc";

const FIAAppsFromFriends = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Друзья в приложении</PanelHeaderWithPath>
      <FIAAppsFromFriendsList openView={props.openView} />
    </Panel>
  );
};

export default withRouter(FIAAppsFromFriends);
