import {
  ACTIVITY__USER_REQUEST,
  ACTIVITY__USER_SUCCESS,
  ACTIVITY__USER_ERROR,
  ACTIVITY__USER_SHOW_MORE,
  ACTIVITY__USER_SEARCH_REQUEST,
  ACTIVITY__USER_SEARCH_SUCCESS,
  ACTIVITY__USER_SEARCH_ERROR,
  ACTIVITY__USER_FILTER_REQUEST,
  ACTIVITY__USER_FILTER_SUCCESS,
  ACTIVITY__USER_FILTER_ERROR,
  ACTIVITY__OTHER_USER_REQUEST,
  ACTIVITY__OTHER_USER_SUCCESS,
  ACTIVITY__OTHER_USER_ERROR,
  ACTIVITY__OTHER_USER_SHOW_MORE,
  ACTIVITY__OTHER_USER_SEARCH_REQUEST,
  ACTIVITY__OTHER_USER_SEARCH_SUCCESS,
  ACTIVITY__OTHER_USER_SEARCH_ERROR,
  ACTIVITY__OTHER_USER_FILTER_REQUEST,
  ACTIVITY__OTHER_USER_FILTER_SUCCESS,
  ACTIVITY__OTHER_USER_FILTER_ERROR,
  ACTIVITY__SET_OTHER_USER_PAGE,
  ACTIVITY__USER_INFO_REQUEST,
  ACTIVITY__USER_INFO_SUCCESS,
  ACTIVITY__USER_INFO_PROGRESS,
  ACTIVITY__USER_INFO_ERROR,
  ACTIVITY__USER_INFO_SET_PERIOD,
  ACTIVITY__USER_INFO_TABS,
  ACTIVITY__OTHER_USER_INFO_REQUEST,
  ACTIVITY__OTHER_USER_INFO_SUCCESS,
  ACTIVITY__OTHER_USER_INFO_PROGRESS,
  ACTIVITY__OTHER_USER_INFO_ERROR,
  ACTIVITY__OTHER_USER_INFO_SET_PERIOD,
  ACTIVITY__OTHER_USER_INFO_TABS,
  ACTIVITY__OTHER_USER_ACTIVE_REQUEST,
  ACTIVITY__OTHER_USER_ACTIVE_SUCCESS,
  ACTIVITY__OTHER_USER_ACTIVE_PROGRESS,
  ACTIVITY__OTHER_USER_ACTIVE_ERROR,
  ACTIVITY__OTHER_USER_ACTIVE_SET_PERIOD,
  ACTIVITY__USER_INFO_CANCEL,
  ACTIVITY__OTHER_USER_INFO_CANCEL,
  ACTIVITY__OTHER_USER_ACTIVE_CANCEL,
  ACTIVITY__USER_INFO_SEARCH,
  ACTIVITY__USER_INFO_FILTER,
  ACTIVITY__OTHER_USER_INFO_SEARCH,
  ACTIVITY__OTHER_USER_INFO_FILTER,
} from "../../constType";

import { PERIOD_MONTH } from "template/popouts/DateChangePeriod/constPeriod";
import { TABS_ALL } from "views/tool/panels/activity/constTabs";

const initialState = {
  users: {
    payload: null,
    cachePayload: null,
    showCount: 20,
    filter: "",
    search: "",
    isOnce: false,
    loading: false,
    error: null,
  },
  otherUsers: {
    payload: null,
    cachePayload: null,
    showCount: 20,
    filter: "",
    search: "",
    isOnce: false,
    loading: false,
    error: null,
  },
  userInfo: {
    payload: null,
    activeUserId: null,
    activeUserTitle: null,
    activeUserDescription: null,
    activeUserPhoto: null,
    activeUserSex: null,
    filter: "",
    search: "",
    loading: false,
    progressValue: 0,
    error: null,
    tabs: TABS_ALL,
    period: {
      name: PERIOD_MONTH,
      atMin: null,
      atMax: null,
    },
  },
  otherUserPage: {
    // Для сохранения выбранного юзера (активности у пользователя)
    activeUserId: null,
    activeUserTitle: null,
    activeUserDescription: null,
    activeUserPhoto: null,
    activeUserSex: null,
  },
  otherUserInfo: {
    payload: null,
    filter: "",
    search: "",
    loading: false,
    progressValue: 0,
    error: null,
    tabs: TABS_ALL,
    period: {
      name: PERIOD_MONTH,
      atMin: null,
      atMax: null,
    },
  },
  otherUserActive: {
    payload: null,
    loading: false,
    period: {
      name: PERIOD_MONTH,
      atMin: null,
      atMax: null,
    },
    progressValue: 0,
    error: null,
  },
};

export default function activityReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIVITY__USER_REQUEST:
      return {
        ...state,
        users: {
          ...state.users,
          showCount: 20,
          cachePayload:
            state.users.search === "" && state.users.filter === ""
              ? null // Если поле поиска и фильтра пустое то очистить кэш
              : state.users.cachePayload,
          payload: null,
          isOnce: true,
          loading: true,
          error: null,
        },
      };
    case ACTIVITY__USER_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          payload: action.payload,
          loading: false,
          cachePayload:
            state.users.search === "" && state.users.filter === ""
              ? action.payload // Если поле поиска и фильтра пустое то сохранить результат
              : state.users.cachePayload,
        },
      };
    case ACTIVITY__USER_ERROR:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          error: action.error,
        },
      };
    case ACTIVITY__USER_SHOW_MORE:
      return {
        ...state,
        users: {
          ...state.users,
          showCount: parseInt(state.users.showCount) + 20,
        },
      };
    case ACTIVITY__USER_SEARCH_REQUEST:
      return {
        ...state,
        users: {
          ...state.users,
          payload: null,
          showCount: 20,
          search: action.search,
          loading: true,
          error: null,
        },
      };
    case ACTIVITY__USER_SEARCH_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          payload: [...action.payload],
          loading: false,
        },
      };
    case ACTIVITY__USER_SEARCH_ERROR:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          error: action.error,
        },
      };
    case ACTIVITY__USER_FILTER_REQUEST:
      return {
        ...state,
        users: {
          ...state.users,
          payload: null,
          showCount: 20,
          filter: action.filter,
          loading: true,
          error: null,
        },
      };
    case ACTIVITY__USER_FILTER_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          payload: [...action.payload],
        },
      };
    case ACTIVITY__USER_FILTER_ERROR:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          error: action.error,
        },
      };
    case ACTIVITY__OTHER_USER_REQUEST:
      return {
        ...state,
        otherUsers: {
          ...state.otherUsers,
          showCount: 20,
          cachePayload:
            state.otherUsers.search === "" && state.otherUsers.filter === ""
              ? null // Если поле поиска и фильтра пустое то очистить кэш
              : state.otherUsers.cachePayload,
          payload: null,
          isOnce: true,
          loading: true,
          error: null,
        },
      };
    case ACTIVITY__OTHER_USER_SUCCESS:
      return {
        ...state,
        otherUsers: {
          ...state.otherUsers,
          payload: action.payload,
          loading: false,
          cachePayload:
            state.otherUsers.search === "" && state.otherUsers.filter === ""
              ? action.payload // Если поле поиска и фильтра пустое то сохранить результат
              : state.otherUsers.cachePayload,
        },
      };
    case ACTIVITY__OTHER_USER_ERROR:
      return {
        ...state,
        otherUsers: {
          ...state.otherUsers,
          loading: false,
          error: action.error,
        },
      };
    case ACTIVITY__OTHER_USER_SHOW_MORE:
      return {
        ...state,
        otherUsers: {
          ...state.otherUsers,
          showCount: parseInt(state.otherUsers.showCount) + 20,
        },
      };
    case ACTIVITY__OTHER_USER_SEARCH_REQUEST:
      return {
        ...state,
        otherUsers: {
          ...state.otherUsers,
          payload: null,
          showCount: 20,
          search: action.search,
          loading: true,
          error: null,
        },
      };
    case ACTIVITY__OTHER_USER_SEARCH_SUCCESS:
      return {
        ...state,
        otherUsers: {
          ...state.otherUsers,
          payload: [...action.payload],
          loading: false,
        },
      };
    case ACTIVITY__OTHER_USER_SEARCH_ERROR:
      return {
        ...state,
        otherUsers: {
          ...state.otherUsers,
          loading: false,
          error: action.error,
        },
      };
    case ACTIVITY__OTHER_USER_FILTER_REQUEST:
      return {
        ...state,
        otherUsers: {
          ...state.otherUsers,
          payload: null,
          showCount: 20,
          filter: action.filter,
          loading: true,
          error: null,
        },
      };
    case ACTIVITY__OTHER_USER_FILTER_SUCCESS:
      return {
        ...state,
        otherUsers: {
          ...state.otherUsers,
          loading: false,
          payload: [...action.payload],
        },
      };
    case ACTIVITY__OTHER_USER_FILTER_ERROR:
      return {
        ...state,
        otherUsers: {
          ...state.otherUsers,
          loading: false,
          error: action.error,
        },
      };
    case ACTIVITY__USER_INFO_REQUEST:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          loading: true,
          error: null,
          payload: null,
          activeUserId: action.id,
          activeUserTitle: action.title,
          activeUserDescription: action.description,
          activeUserPhoto: action.photo,
          activeUserSex: action.sex,
        },
      };
    case ACTIVITY__USER_INFO_PROGRESS:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          progressValue: action.progressValue,
          payload: action.payload,
        },
      };
    case ACTIVITY__USER_INFO_CANCEL:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          loading: false,
        },
      };
    case ACTIVITY__USER_INFO_SUCCESS:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          loading: false,
          payload: action.payload,
        },
      };
    case ACTIVITY__USER_INFO_ERROR:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          loading: false,
          error: action.error,
        },
      };
    case ACTIVITY__USER_INFO_SEARCH:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          search: action.search,
        },
      };
    case ACTIVITY__USER_INFO_FILTER:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          filter: action.filter,
        },
      };
    case ACTIVITY__USER_INFO_SET_PERIOD:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          period: {
            ...state.userInfo.period,
            name: action.name,
            atMin: action.atMin,
            atMax: action.atMax,
          },
        },
      };
    case ACTIVITY__USER_INFO_TABS:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          tabs: action.tabs,
        },
      };
    case ACTIVITY__OTHER_USER_INFO_REQUEST:
      return {
        ...state,
        otherUserInfo: {
          ...state.otherUserInfo,
          loading: true,
          error: null,
          payload: null,
        },
      };
    case ACTIVITY__OTHER_USER_INFO_CANCEL:
      return {
        ...state,
        otherUserInfo: {
          ...state.otherUserInfo,
          loading: false,
        },
      };
    case ACTIVITY__OTHER_USER_INFO_PROGRESS:
      return {
        ...state,
        otherUserInfo: {
          ...state.otherUserInfo,
          progressValue: action.progressValue,
          payload: action.payload,
        },
      };
    case ACTIVITY__OTHER_USER_INFO_SUCCESS:
      return {
        ...state,
        otherUserInfo: {
          ...state.otherUserInfo,
          loading: false,
          payload: action.payload,
        },
      };
    case ACTIVITY__OTHER_USER_INFO_ERROR:
      return {
        ...state,
        otherUserInfo: {
          ...state.otherUserInfo,
          loading: false,
          error: action.error,
        },
      };
    case ACTIVITY__OTHER_USER_INFO_SEARCH:
      return {
        ...state,
        otherUserInfo: {
          ...state.otherUserInfo,
          search: action.search,
        },
      };
    case ACTIVITY__OTHER_USER_INFO_FILTER:
      return {
        ...state,
        otherUserInfo: {
          ...state.otherUserInfo,
          filter: action.filter,
        },
      };
    case ACTIVITY__OTHER_USER_INFO_SET_PERIOD:
      return {
        ...state,
        otherUserInfo: {
          ...state.otherUserInfo,
          period: {
            ...state.otherUserInfo.period,
            name: action.name,
            atMin: action.atMin,
            atMax: action.atMax,
          },
        },
      };
    case ACTIVITY__OTHER_USER_INFO_TABS:
      return {
        ...state,
        otherUserInfo: {
          ...state.otherUserInfo,
          tabs: action.tabs,
        },
      };
    case ACTIVITY__SET_OTHER_USER_PAGE:
      return {
        ...state,
        otherUserPage: {
          ...state.otherUserPage,
          activeUserId: action.id,
          activeUserTitle: action.title,
          activeUserDescription: action.description,
          activeUserPhoto: action.photo,
          activeUserSex: action.sex,
        },
      };
    case ACTIVITY__OTHER_USER_ACTIVE_REQUEST:
      return {
        ...state,
        otherUserActive: {
          ...state.otherUserActive,
          loading: true,
          error: null,
          payload: null,
        },
      };
    case ACTIVITY__OTHER_USER_ACTIVE_SUCCESS:
      return {
        ...state,
        otherUserActive: {
          ...state.otherUserActive,
          loading: false,
          payload: action.payload,
        },
      };
    case ACTIVITY__OTHER_USER_ACTIVE_PROGRESS:
      return {
        ...state,
        otherUserActive: {
          ...state.otherUserActive,
          progressValue: action.progressValue,
          payload: action.payload,
        },
      };
    case ACTIVITY__OTHER_USER_ACTIVE_ERROR:
      return {
        ...state,
        otherUserActive: {
          ...state.otherUserActive,
          loading: false,
          error: action.error,
        },
      };
    case ACTIVITY__OTHER_USER_ACTIVE_CANCEL:
      return {
        ...state,
        otherUserActive: {
          ...state.otherUserActive,
          loading: false,
        },
      };
    case ACTIVITY__OTHER_USER_ACTIVE_SET_PERIOD:
      return {
        ...state,
        otherUserActive: {
          ...state.otherUserActive,
          period: {
            ...state.otherUserActive.period,
            name: action.name,
            atMin: action.atMin,
            atMax: action.atMax,
          },
        },
      };
    default:
      return state;
  }
}
