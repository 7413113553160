import {
  NEW_FRIENDS_REQUEST,
  NEW_FRIENDS_SUCCESS,
  NEW_FRIENDS_ERROR,
  NEW_FRIENDS_SHOW_MORE,
  NEW_FRIENDS_FILTER_ERROR,
  NEW_FRIENDS_FILTER_REQUEST,
  NEW_FRIENDS_FILTER_SUCCESS,
  NEW_FRIENDS_SEARCH_ERROR,
  NEW_FRIENDS_SEARCH_REQUEST,
  NEW_FRIENDS_SEARCH_SUCCESS,
  NEW_FRIENDS__INFO_REQUEST,
  NEW_FRIENDS__INFO_SUCCESS,
  NEW_FRIENDS__INFO_ERROR,
  NEW_FRIENDS__INFO_SET_PERIOD,
} from "../../constType";

import { getPeriod } from "../../../template/popouts/DateChangePeriod/actions";
import { APIEye3GetNewFriendTool } from "../../../api/eye3";
import { getSearchFilterFriendsV2 } from "../../../helpers/actions";
import { openModal } from "../page";
import { delayMethodGetUsersVKApi } from "api";
import { checkEYE3ApiError, defaultErrorProperty } from "api/eye3/responseHandler";

export function fetchNewFriends() {
  return async (dispatch, getState) => {
    try {
      dispatch(request());

      const state = getState().toolNewFriends;

      const friends = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.friends.search,
        filter: state.friends.filter,
        cache: state.friends.cachePayload,
      });

      dispatch(success(friends));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: NEW_FRIENDS_REQUEST };
  }

  function success(payload) {
    return { type: NEW_FRIENDS_SUCCESS, payload };
  }
  function error(error) {
    return { type: NEW_FRIENDS_ERROR, error };
  }
}

export function showMoreNewFriends() {
  return { type: NEW_FRIENDS_SHOW_MORE };
}

export function filterNewFriends(filter = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(request(filter));

      const state = getState().toolNewFriends;

      const friends = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.friends.search,
        filter: state.friends.filter,
        cache: state.friends.cachePayload,
      });

      dispatch(success(friends));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(filter) {
    return { type: NEW_FRIENDS_FILTER_REQUEST, filter };
  }

  function success(payload) {
    return { type: NEW_FRIENDS_FILTER_SUCCESS, payload };
  }
  function error(error) {
    return { type: NEW_FRIENDS_FILTER_ERROR, error };
  }
}

export function searchNewFriends(search = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(request(search));

      const state = getState().toolNewFriends;

      const friends = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.friends.search,
        filter: state.friends.filter,
        cache: state.friends.cachePayload,
      });

      dispatch(success(friends));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(search) {
    return { type: NEW_FRIENDS_SEARCH_REQUEST, search };
  }

  function success(payload) {
    return { type: NEW_FRIENDS_SEARCH_SUCCESS, payload };
  }
  function error(error) {
    return { type: NEW_FRIENDS_SEARCH_ERROR, error };
  }
}

export function fetchNewFriendsInfo() {
  return async (dispatch, getState) => {
    try {
      const state = getState().toolNewFriends;
      const pageParam = getState().page.param;

      const id = pageParam ? pageParam.activeUserId : state.newFriends.activeUserId;
      const title = pageParam ? pageParam.activeUserTitle : state.newFriends.activeUserTitle;
      const description = pageParam
        ? pageParam.activeUserDescription
        : state.newFriends.activeUserDescription;
      const photo = pageParam ? pageParam.activeUserPhoto : state.newFriends.activeUserPhoto;
      const sex = pageParam ? pageParam.activeUserSex : state.newFriends.activeUserSex;

      const period = state.newFriends.period;

      await dispatch(request(id, title, description, photo, sex));

      const { atMin, atMax } = getPeriod(period);

      const newFriends = await APIEye3GetNewFriendTool({
        userId: id,
        timeMin: atMin,
        timeMax: atMax,
      });

      //console.log('newFriends: ', newFriends);

      let result = [];

      // Проверка ошибок в API
      const errorAPI = checkEYE3ApiError(newFriends);
      if (errorAPI !== false) {
        errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
        dispatch(error({ ...errorAPI }));
        return;
      }

      const newFriendsItems = newFriends.items;

      //console.log('newFriendsItems: ', newFriendsItems);

      const friendsInfoItems = await delayMethodGetUsersVKApi({
        userIds: newFriendsItems.map((friend) => friend.vk_id),
        fields: ["photo_100", "sex"],
      });

      //console.log('friendsInfoItems: ', friendsInfoItems);

      for (let friend of newFriendsItems) {
        for (let friendInfo of friendsInfoItems) {
          if (friend.vk_id === friendInfo.id) {
            result.push({
              vk_id: friend.vk_id,
              name: friendInfo.first_name + " " + friendInfo.last_name,
              photo_url: friendInfo.photo_100,
              sex: friendInfo.sex.toString(),
              type: friend.type,
              action: friend.action.toString(),
              _at: friend._at,
            });
          }
        }
      }

      await dispatch(success(result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(id, title, description, photo, sex) {
    return { type: NEW_FRIENDS__INFO_REQUEST, id, title, description, photo, sex };
  }

  function success(payload) {
    return { type: NEW_FRIENDS__INFO_SUCCESS, payload };
  }
  function error(error) {
    return { type: NEW_FRIENDS__INFO_ERROR, error };
  }
}

export function setPeriodNewFriendsInfo(name, atMin = null, atMax = null) {
  return { type: NEW_FRIENDS__INFO_SET_PERIOD, name, atMin, atMax };
}
