import React from "react";
import { Placeholder } from "@vkontakte/vkui";
import InfoIcon from "assets/img/tool/whoAddedChs/info.png";

const WACInfo = (props) => {
  return (
    <Placeholder icon={<img src={InfoIcon} alt=""></img>} header={""}>
      {props.description}
    </Placeholder>
  );
};

export default WACInfo;
