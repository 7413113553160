import React from "react";
import { PANEL_TEMPLATE_DATE_CHANGE_PERIOD } from "template/panels/constPanels";
import { ActionSheet, ActionSheetItem } from "@vkontakte/vkui";
import { PERIOD_MONTH, PERIOD_TODAY, PERIOD_WEEK, PERIOD_ALL } from "./constPeriod";

const PopoutTemplateDateChangePeriod = (props) => {
  const setPeriodBackParam = (period) => {
    props.setPeriod(period);
  };

  return (
    <ActionSheet onClose={props.closePopout}>
      <ActionSheetItem autoclose onClick={() => setPeriodBackParam(PERIOD_TODAY)}>
        За сегодня
      </ActionSheetItem>
      <ActionSheetItem autoclose onClick={() => setPeriodBackParam(PERIOD_WEEK)}>
        За неделю
      </ActionSheetItem>
      <ActionSheetItem autoclose onClick={() => setPeriodBackParam(PERIOD_MONTH)}>
        За месяц
      </ActionSheetItem>
      <ActionSheetItem autoclose onClick={() => setPeriodBackParam(PERIOD_ALL)}>
        За всё время
      </ActionSheetItem>
      <ActionSheetItem
        autoclose
        onClick={() =>
          props.openView({
            view: props.backView,
            panel: PANEL_TEMPLATE_DATE_CHANGE_PERIOD,
            param: {
              panelTitle: props.panelTitle,
              setPeriod: props.setPeriod,
              backPanel: props.backPanel,
              backView: props.backView,
            },
          })
        }
      >
        Выбрать период...
      </ActionSheetItem>
    </ActionSheet>
  );
};

export default PopoutTemplateDateChangePeriod;
