import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Group, Placeholder } from "@vkontakte/vkui";
import { PANEL_JC_INFO } from "../../../../constPanels";

import { fetchInfoGroup, setPeriodInfoGroup } from "store/actions/tool/joinCommunity";

import SeparateFilter from "template/components/SeparateFilter/SeparateFilter";

import JCInfoItem from "../JCInfoItem/JCInfoItem";
import JCInfoTopPanel from "../JCInfoTopPanel/JCInfoTopPanel";
import { convertMonthDay, unixTimeToDate, convertTime } from "helpers";

import { declOfNum } from "helpers";
import { renderPeriodV2 } from "template/popouts/DateChangePeriod/views";
import PopoutTemplateDateChangePeriod from "template/popouts/DateChangePeriod/DateChangePeriod";
import Icon56Users3Outline from "@vkontakte/icons/dist/56/users_3_outline";
import { VIEW_TOOL } from "views/constViews";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";

const JCInfoList = (props) => {
  const dispatch = useDispatch();
  const activeUserId = useSelector((state) => state.toolJoinCommunity.groups.activeUserId);
  const activeUserTitle = useSelector((state) => state.toolJoinCommunity.groups.activeUserTitle);
  const activeUserDescription = useSelector(
    (state) => state.toolJoinCommunity.groups.activeUserDescription
  );
  const activeUserPhoto = useSelector((state) => state.toolJoinCommunity.groups.activeUserPhoto);
  const aciiveUserSex = useSelector((state) => state.toolJoinCommunity.groups.activeUserSex);
  const loading = useSelector((state) => state.toolJoinCommunity.groups.loading);
  const error = useSelector((state) => state.toolJoinCommunity.groups.error);
  const period = useSelector((state) => state.toolJoinCommunity.groups.period);
  const groups = useSelector((state) => state.toolJoinCommunity.groups.payload);
  const handleFetchInfoGroup = useCallback(() => dispatch(fetchInfoGroup()), [dispatch]);
  const handleSetPeriodInfoGroup = useCallback(
    (name, atMin = null, atMax = null) => dispatch(setPeriodInfoGroup(name, atMin, atMax)),
    [dispatch]
  );

  let lastAt = new Date();

  useEffect(() => {
    handleFetchInfoGroup();
  }, [handleFetchInfoGroup, period]);

  const renderUser = () => {
    const groupsCount = groups.length;
    const groupsCountStrFormat = groupsCount
      ? groupsCount + " " + declOfNum(groupsCount, ["сообщество", "сообщества", "сообществ"])
      : "0 сообществ";

    return (
      <JCInfoTopPanel
        id={activeUserId}
        title={activeUserTitle}
        photo={activeUserPhoto}
        description={activeUserDescription}
        openView={props.openView}
        bottomContent={groupsCountStrFormat}
      />
    );
  };

  const renderGroup = () => {
    return groups.length > 0 ? (
      groups.map((group, i) => {
        const id = group.vk_id;
        const name = group.name;
        const sex = aciiveUserSex;
        const photo = group.photo_url ? group.photo_url : "";
        const type = group.type;
        const at = unixTimeToDate(group._at);

        let separate = <SeparateFilter>{convertMonthDay(at)}</SeparateFilter>;

        if (lastAt.getDate() === at.getDate() && i !== 0) {
          separate = "";
        }

        lastAt = at;

        return (
          <div key={i}>
            {separate}
            <JCInfoItem
              id={id}
              title={name}
              sex={sex}
              type={type}
              photo={photo}
              time={convertTime(at)}
            />
          </div>
        );
      })
    ) : (
      <Placeholder icon={<Icon56Users3Outline />}>Сообщества не найдены</Placeholder>
    );
  };

  const handlePeriod = () => {
    if (loading) return false;

    props.openPopout({
      popout: (
        <PopoutTemplateDateChangePeriod
          openView={props.openView}
          period={period} // Период из redux store
          setPeriod={handleSetPeriodInfoGroup} // action из redux
          backPanel={PANEL_JC_INFO}
          backView={VIEW_TOOL}
          panelTitle={"Вступления"}
          closePopout={props.closePopout}
        />
      ),
    });
  };

  return (
    <>
      {renderPeriodV2({
        title: "Вступления/выход из сообществ",
        period: period,
        handler: handlePeriod,
      })}
      {groups !== null && error === null ? <Group separator="hide">{renderUser()}</Group> : ""}
      {groups !== null && error === null ? (
        <Group style={{ marginBottom: "20px" }}>{renderGroup()}</Group>
      ) : (
        ""
      )}
      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchInfoGroup,
      })}
    </>
  );
};

export default JCInfoList;
