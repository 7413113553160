import { classNames } from "@vkontakte/vkui";
import { checkVKMethodError } from "api";
import { VkBridgeSend } from "api/vk";
import { VIEW_TOOL } from "views/constViews";
import styles from "./ToolModalItem.module.scss";

ToolModalItem.defaultProps = {
  icon: null,
  title: "",
  style: null,
  to: null,
};

function ToolModalItem(props) {
  const cnItem = classNames(styles.item, {
    [styles.disabled]: props.to === null ? true : false,
  });

  const handleClick = (e) => {
    if (props.to) {
      VkBridgeSend("VKWebAppAllowMessagesFromGroup", {
        group_id: 194197472,
      }).then((check) => {
        if (checkVKMethodError(check)) {
          return;
        }

        props.closeModal();
        props.openView({ view: VIEW_TOOL, panel: props.to });
      });
    } else {
      e.preventDefault();
    }
  };

  return (
    <div className={cnItem} onClick={handleClick}>
      <div className={styles.icon}>{props.icon}</div>
      <p className={styles.title} style={props.style}>
        {props.title}
      </p>
    </div>
  );
}

export default ToolModalItem;
