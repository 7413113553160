import { Div, Group, Header, HorizontalScroll } from "@vkontakte/vkui";
import TopItem from "../TopItem/TopItem";
import React from "react";

const TopList = (props) => {
  const renderTop = () => {
    return props.items.map((item, i) => {
      const storyId = item.story_id;
      const userId = item.user_id;
      const title = item[props.type];
      const photo = item.photo_url;

      if (title !== 0) {
        return (
          <TopItem
            key={i}
            title={title}
            storyId={storyId}
            userId={userId}
            type={props.type}
            photo={photo}
          />
        );
      }

      return false;
    });
  };

  return (
    <>
      {props.items !== null && (
        <Group header={<Header mode="secondary">{props.header}</Header>} separator="hide">
          <HorizontalScroll>
            <Div>
              <div style={{ display: "flex" }}>{props.items !== null && renderTop()}</div>
            </Div>
          </HorizontalScroll>
        </Group>
      )}
    </>
  );
};

TopList.defaultProps = {
  header: "",
  type: "",
  items: null,
};

export default TopList;
