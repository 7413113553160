import {
  Icon20UsersOutline,
  Icon28StoryOutline,
  Icon28UserIncomingOutline,
  Icon28UserOutline,
  Icon28Users3Outline,
} from "@vkontakte/icons";
import { Div } from "@vkontakte/vkui";
import {
  MODAL_CARD_TOOL_LIST_MY_ACCOUNT,
  MODAL_CARD_TOOL_LIST_FRIENDS,
  MODAL_CARD_TOOL_LIST_COMMUNITY,
  MODAL_CARD_TOOL_LIST_SUBSCRIBERS,
  MODAL_CARD_TOOL_LIST_HISTORIES,
} from "views/tool/modals/constModals";
import CategoryItem from "../CategoryItem/CategoryItem";
import styles from "./CategoryList.module.scss";

function CategoryList(props) {
  return (
    <Div>
      <h2 className={styles.header}>Категории</h2>
      <div className={styles.container}>
        <div className={styles.section}>
          <CategoryItem
            title="Мой аккаунт"
            icon={<Icon28UserOutline width={40} height={40} fill={"#F8554B"} />}
            openModal={props.openModal}
            to={MODAL_CARD_TOOL_LIST_MY_ACCOUNT}
          />
        </div>
        <div className={styles.section}>
          <CategoryItem
            title="Друзья"
            icon={<Icon20UsersOutline width={40} height={40} fill={"#30AEF6"} />}
            openModal={props.openModal}
            style={{ marginRight: "8px" }}
            to={MODAL_CARD_TOOL_LIST_FRIENDS}
          />
          <CategoryItem
            title="Сообщества"
            icon={<Icon28Users3Outline width={40} height={40} fill={"#F78C2A"} />}
            openModal={props.openModal}
            to={MODAL_CARD_TOOL_LIST_COMMUNITY}
          />
        </div>
        <div className={styles.section}>
          <CategoryItem
            title="Подписчики"
            icon={<Icon28UserIncomingOutline width={40} height={40} fill={"#F0B762"} />}
            openModal={props.openModal}
            to={MODAL_CARD_TOOL_LIST_SUBSCRIBERS}
            style={{ marginRight: "8px" }}
          />
          <CategoryItem
            title="Истории"
            icon={<Icon28StoryOutline width={40} height={40} fill={"#5087F3"} />}
            openModal={props.openModal}
            to={MODAL_CARD_TOOL_LIST_HISTORIES}
          />
        </div>
      </div>
    </Div>
  );
}

export default CategoryList;
