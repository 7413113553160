import React from "react";
import { Div } from "@vkontakte/vkui";
import styles from "./HeadTitle.module.css";

const HeadTitle = (props) => {
  return (
    <Div>
      <div className={styles.title} style={props.style}>
        {props.children}
      </div>
    </Div>
  );
};

HeadTitle.defaultProps = {
  style: null,
};

export default HeadTitle;
