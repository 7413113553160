import { Icon24Add } from "@vkontakte/icons";
import { Button, Div, FormLayout, FormLayoutGroup, FormStatus, Select } from "@vkontakte/vkui";
import { APIVkGetCitiesDatabase, APIVkGetCountriesDatabase } from "api/vk/apiMethods";
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGeoSearch } from "store/actions/tool/maritalStatus";
import { VIEW_TOOL } from "views/constViews";
import { PANEL_MS__GEO_SEARCH } from "views/tool/panels/constPanels";

const MSGeoSettingForm = (props) => {
  const dispatch = useDispatch();
  const handleFetchGeoSearch = useCallback(
    (selectedFilters) => dispatch(fetchGeoSearch(selectedFilters)),
    [dispatch]
  );
  const geoSettings = useSelector((state) => state.toolMaritalStatus.geoSettings.payload);
  const loading = useSelector((state) => state.toolMaritalStatus.geoSearch.loading);

  const sex = geoSettings !== null ? geoSettings.sex : "";
  const ageFrom = geoSettings !== null ? geoSettings.age_from : "";
  const ageTo = geoSettings !== null ? geoSettings.age_to : "";
  const countryId = geoSettings !== null ? geoSettings.country_id : "";
  const cityIds = geoSettings !== null ? geoSettings.city_ids : [];
  const relationFrom = geoSettings !== null ? geoSettings.relation_from : [];
  const relationOn = geoSettings !== null ? geoSettings.relation_on : [];
  const periodDayCount = geoSettings !== null ? geoSettings.period_day_count : "";

  const [cities, setCities] = useState("");
  const [countries, setCountries] = useState("");

  const isArrayObj = (arg) => {
    return Object.prototype.toString.call(arg) === "[object Array]";
  };

  const [validateFilters, setValidateFilters] = useState({
    sex: {
      valid: sex !== null ? true : false,
      touch: false,
    }, // Пол
    country_id: {
      valid: countryId !== null ? true : false,
      touch: false,
    }, // ИД страны поиска (через запятую)
    city_ids: {
      valid: cityIds !== null && isArrayObj(cityIds) ? true : false,
      touch: false,
    }, // Список городов поиска (через запятую)
    relation_on: {
      valid: relationOn !== null && isArrayObj(relationOn) ? true : false,
      touch: false,
    }, //  На какое СП была смена
    period_day_count: {
      valid: periodDayCount !== null ? true : false,
      touch: false,
    }, // Период поиска, количество дней (число)
  });

  const [selectedFilters, setSelectedFilters] = useState({
    // Объект фильтров
    sex: sex !== null ? sex : "", // Пол
    age_from: ageFrom !== null ? ageFrom : "", // Возраст ОТ (необязательно).
    age_to: ageTo !== null ? ageTo : "", // Возраст ДО (необязательно).
    country_id: countryId !== null ? countryId : "", // ИД страны поиска
    city_ids: cityIds !== null && isArrayObj(cityIds) ? cityIds : [], // Список городов поиска (через запятую)
    relation_from: relationFrom !== null && isArrayObj(relationFrom) ? relationFrom : [], // С какого семейного положения была смена (необязательно)
    relation_on: relationOn !== null && isArrayObj(relationOn) ? relationOn : [], //  На какое СП была смена
    period_day_count: periodDayCount !== null ? periodDayCount : "", // Период поиска, количество дней (число)
  });

  /*useEffect(() => {
        console.log('selectedFilters: ', selectedFilters);
        console.log('validateFilters: ', validateFilters);
    }, [selectedFilters, validateFilters]);*/

  useEffect(() => {
    APIVkGetCountriesDatabase().then(async (country) => {
      setCountries(country.response);
      if (selectedFilters.country_id !== "") {
        const cities = await APIVkGetCitiesDatabase({
          countryId: selectedFilters.country_id,
        });
        setCities(cities.response);
      }
    });
  }, [selectedFilters.country_id]);

  const getStatus = (field) => {
    const targetField = validateFilters[field];

    if (!targetField.touch) return null;

    if (targetField.valid) return "valid";
    else return "error";
  };

  const renderFormStatus = (field, message) => {
    const targetField = validateFilters[field];

    if (!targetField.valid && targetField.touch) {
      return <FormStatus mode="error">{message}</FormStatus>;
    }
  };

  const getAges = () => {
    let ages = [];
    for (let i = 14; i <= 80; i++) {
      ages.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }

    return ages;
  };

  const getRelation = () => {
    return (
      <>
        <option value={1}>не женат/не замужем</option>
        <option value={2}>есть подруга/есть друг</option>
        <option value={3}>помолвлен/помолвлена</option>
        <option value={4}>женат/замужем</option>
        <option value={5}>всё сложно</option>
        <option value={6}>в активном поиске</option>
        <option value={7}>влюблён/влюблена</option>
        <option value={8}>в гражданском браке</option>
      </>
    );
  };

  const renderFieldCities = () => {
    const cityIds = selectedFilters.city_ids;

    return cityIds.length > 0
      ? cityIds.map((id, i) => {
          return (
            <Select
              key={i}
              placeholder="Выбрать город"
              defaultValue={id}
              status={getStatus("city_ids")}
              disabled={cities !== "" ? false : true}
              onChange={(e) => onChangeCity(e, i)}
            >
              {cities !== "" &&
                cities.items.map((city, i) => {
                  return (
                    <option key={i} value={city.id}>
                      {" "}
                      {city.title}{" "}
                    </option>
                  );
                })}
            </Select>
          );
        })
      : "";
  };

  const renderFieldRelationFrom = () => {
    const relationFromIds = selectedFilters.relation_from;

    return relationFromIds.length > 0
      ? relationFromIds.map((id, i) => {
          return (
            <Select
              key={i}
              placeholder="Выбрать"
              defaultValue={id}
              onChange={(e) => onChangeRelationFrom(e, i)}
            >
              {getRelation()}
            </Select>
          );
        })
      : "";
  };

  const renderFieldRelationOn = () => {
    const relationOnIds = selectedFilters.relation_on;

    return relationOnIds.length > 0
      ? relationOnIds.map((id, i) => {
          return (
            <Select
              key={i}
              placeholder="Выбрать"
              status={getStatus("relation_on")}
              defaultValue={id}
              onChange={(e) => onChangeRelationOn(e, i)}
            >
              {getRelation()}
            </Select>
          );
        })
      : "";
  };

  const addFieldCity = () => {
    let cityIds = selectedFilters.city_ids;

    if (cityIds.length >= 3) return;

    cityIds.push("");
    setSelectedFilters({
      ...selectedFilters,
      city_ids: cityIds,
    });
  };

  const addFieldRelationFrom = () => {
    let relationFromIds = selectedFilters.relation_from;

    if (relationFromIds.length >= 3) return;

    relationFromIds.push("");
    setSelectedFilters({
      ...selectedFilters,
      relation_from: relationFromIds,
    });
  };

  const addFiendRelationOn = () => {
    let relationOnIds = selectedFilters.relation_on;

    if (relationOnIds.length >= 3) return;

    relationOnIds.push("");
    setSelectedFilters({
      ...selectedFilters,
      relation_on: relationOnIds,
    });
  };

  const onChangeCountry = async (e) => {
    setCities("");

    let isValid = e.target.value !== "" ? true : false;

    setValidateFilters({
      ...validateFilters,
      country_id: {
        ...validateFilters.country_id,
        touch: true,
        valid: isValid,
      },
      city_ids: {
        ...validateFilters.city_ids,
        touch: true,
        valid: false,
      },
    });

    setSelectedFilters({
      ...selectedFilters,
      city_ids: [""],
      country_id: e.target.value,
    });

    if (e.target.value !== "") {
      const cities = await APIVkGetCitiesDatabase({
        countryId: e.target.value,
      });

      setCities(cities.response);
    }
  };

  const onChangeCity = (e, i) => {
    let cityIds = selectedFilters.city_ids;
    cityIds[i] = e.target.value !== "" ? parseInt(e.target.value) : e.target.value;

    let isValid = false;
    for (let id of cityIds) {
      if (id !== "") isValid = true;
    }

    setValidateFilters({
      ...validateFilters,
      city_ids: {
        ...validateFilters.city_ids,
        touch: true,
        valid: isValid,
      },
    });

    setSelectedFilters({
      ...selectedFilters,
      city_ids: cityIds,
    });
  };

  const onChangeRelationFrom = (e, i) => {
    let relationFromIds = selectedFilters.relation_from;
    relationFromIds[i] = e.target.value !== "" ? parseInt(e.target.value) : e.target.value;

    setSelectedFilters({
      ...selectedFilters,
      relation_from: relationFromIds,
    });
  };

  const onChangeRelationOn = (e, i) => {
    let relationOnIds = selectedFilters.relation_on;
    relationOnIds[i] = e.target.value !== "" ? parseInt(e.target.value) : e.target.value;

    let isValid = false;
    for (let id of relationOnIds) {
      if (id !== "") isValid = true;
    }

    setValidateFilters({
      ...validateFilters,
      relation_on: {
        ...validateFilters.relation_on,
        touch: true,
        valid: isValid,
      },
    });

    setSelectedFilters({
      ...selectedFilters,
      relation_on: relationOnIds,
    });
  };

  const onChangeSex = (e) => {
    let isValid = e.target.value !== "" ? true : false;

    setValidateFilters({
      ...validateFilters,
      sex: {
        ...validateFilters.sex,
        touch: true,
        valid: isValid,
      },
    });

    setSelectedFilters({
      ...selectedFilters,
      sex: e.target.value,
    });
  };

  const onChangeAgeFrom = (e) => {
    setSelectedFilters({
      ...selectedFilters,
      age_from: e.target.value,
    });
  };

  const onChangeAgeTo = (e) => {
    setSelectedFilters({
      ...selectedFilters,
      age_to: e.target.value,
    });
  };

  const onChangePeriodDayCount = (e) => {
    let isValid = e.target.value !== "" ? true : false;

    setValidateFilters({
      ...validateFilters,
      period_day_count: {
        ...validateFilters.period_day_count,
        touch: true,
        valid: isValid,
      },
    });

    setSelectedFilters({
      ...selectedFilters,
      period_day_count: e.target.value,
    });
  };

  const showResult = () => {
    if (
      validateFilters.country_id.valid &&
      validateFilters.city_ids.valid &&
      validateFilters.relation_on.valid &&
      validateFilters.sex.valid &&
      validateFilters.period_day_count.valid
    ) {
      handleFetchGeoSearch(selectedFilters);
      props.openView({ view: VIEW_TOOL, panel: PANEL_MS__GEO_SEARCH });
    }
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      <FormLayout>
        <Select
          top="Страна"
          placeholder="Выбрать страну"
          status={getStatus("country_id")}
          disabled={countries !== "" ? false : true}
          defaultValue={selectedFilters.country_id}
          onChange={onChangeCountry}
        >
          {countries !== "" &&
            countries.items.map((country, i) => {
              return (
                <option key={i} value={country.id}>
                  {" "}
                  {country.title}{" "}
                </option>
              );
            })}
        </Select>
        {renderFormStatus("country_id", "Выберите страну")}
        <FormLayoutGroup top="Город">
          {renderFieldCities()}
          {renderFormStatus("city_ids", "Выберите как минимум один город")}
          {selectedFilters.city_ids.length < 3 ? (
            <div style={{ textAlign: "center" }}>
              <Button before={<Icon24Add />} mode="tertiary" onClick={addFieldCity}>
                Добавить город
              </Button>
            </div>
          ) : (
            ""
          )}
        </FormLayoutGroup>
        <FormLayoutGroup top="Семейное положение было (необязательно)">
          {renderFieldRelationFrom()}
          {selectedFilters.relation_from.length < 3 ? (
            <div style={{ textAlign: "center" }}>
              <Button before={<Icon24Add />} mode="tertiary" onClick={addFieldRelationFrom}>
                Добавить
              </Button>
            </div>
          ) : (
            ""
          )}
        </FormLayoutGroup>
        <FormLayoutGroup top="Семейное положение стало">
          {renderFieldRelationOn()}
          {renderFormStatus("relation_on", "Выберите как минимум одно семейное положение")}
          {selectedFilters.relation_on.length < 3 ? (
            <div style={{ textAlign: "center" }}>
              <Button before={<Icon24Add />} mode="tertiary" onClick={addFiendRelationOn}>
                Добавить
              </Button>
            </div>
          ) : (
            ""
          )}
        </FormLayoutGroup>
        <FormLayoutGroup top="Пол">
          <Select
            top="Выбрать пол"
            status={getStatus("sex")}
            defaultValue={selectedFilters.sex}
            onChange={onChangeSex}
          >
            <option value={"1"}>Женский</option>
            <option value={"2"}>Мужской</option>
          </Select>
          {renderFormStatus("sex", "Выберите пол")}
        </FormLayoutGroup>
        <FormLayoutGroup
          top="Возраст (необязательно)"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            maxHeight: "44px",
          }}
        >
          <Select
            placeholder="От"
            style={{ width: "100%" }}
            value={selectedFilters.age_from}
            onChange={onChangeAgeFrom}
          >
            {getAges()}
          </Select>
          <Select
            placeholder="До"
            style={{ width: "100%" }}
            value={selectedFilters.age_to}
            onChange={onChangeAgeTo}
          >
            {getAges()}
          </Select>
        </FormLayoutGroup>
        <FormLayoutGroup top="Период смены СП">
          <Select
            top="Выбрать"
            status={getStatus("period_day_count")}
            defaultValue={selectedFilters.period_day_count}
            onChange={onChangePeriodDayCount}
          >
            <option value={1}>День</option>
            <option value={7}>Неделя</option>
            <option value={31}>Месяц</option>
          </Select>
          {renderFormStatus("period_day_count", "Выберите период смены СП")}
        </FormLayoutGroup>
      </FormLayout>
      <Div>
        <Button size="xl" disabled={loading} stretched onClick={showResult}>
          Показать результаты поиска
        </Button>
      </Div>
    </div>
  );
};

export default MSGeoSettingForm;
