import { queryEye3 } from "api";

async function APIEye3StoryGetTool() {
  try {
    const response = await queryEye3({
      method: "tool.story_get",
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3StoryGetTool;
