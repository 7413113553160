import bridge from "@vkontakte/vk-bridge";
import { getVKApiVersion, getVKToken } from "api";
import { errorHandler, responseHandler } from "../responseHandler";

async function APIVkGetApps({ appId, fields = [], nameCase = "Nom", returnFriends = 1 }) {
  const method = "apps.get";
  try {
    const response = await bridge.send("VKWebAppCallAPIMethod", {
      method: method,
      params: {
        app_id: appId,
        fields: fields,
        name_case: nameCase,
        return_friends: returnFriends,
        v: getVKApiVersion(),
        access_token: getVKToken(),
      },
    });

    responseHandler(method, response);

    return response;
  } catch (e) {
    errorHandler(method, e);
    return e;
  }
}

export default APIVkGetApps;
