import React, { useCallback, useEffect } from "react";
import { Group, Placeholder } from "@vkontakte/vkui";
import PCUserItem from "../PCUserItem/PCUserItem";
import {
  fetchPopularCommunity,
  showMorePopularCommunity,
} from "store/actions/tool/popularCommunity";
import { useDispatch, useSelector } from "react-redux";
import { useShowMore } from "hooks";
import { Icon56Users3Outline } from "@vkontakte/icons";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import { declOfNum } from "helpers";

const PCUserList = (props) => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const showCount = useSelector((state) => state.toolPopularCommunity.showCount);
  const loading = useSelector((state) => state.toolPopularCommunity.loading);
  const error = useSelector((state) => state.toolPopularCommunity.error);
  const groups = useSelector((state) => state.toolPopularCommunity.payload);

  const handleFetchPopularCommunity = useCallback(
    () => dispatch(fetchPopularCommunity()),
    [dispatch]
  );
  const handleShowMorePopularCommunity = useCallback(
    () => dispatch(showMorePopularCommunity()),
    [dispatch]
  );

  useShowMore({
    items: groups,
    showCount: showCount,
    maxLength: groups !== null ? groups.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMorePopularCommunity,
  });

  useEffect(() => {
    handleFetchPopularCommunity();
  }, [handleFetchPopularCommunity]);

  const renderGroupList = () => {
    return groups.length > 0 ? (
      groups.map((group, i) => {
        const id = group.vk_id;
        const name = group.name;
        const photo = group.photo_url;
        const commonCount = group.common_count;
        const commonCountStrFormat =
          commonCount !== 0
            ? commonCount + " " + declOfNum(commonCount, ["друг", "друга", "друзей"])
            : "0 друзей";
        const commonUsers = group.common_users;

        if (i < showCount) {
          return (
            <PCUserItem
              key={i}
              id={id}
              title={name}
              photo={photo}
              commonUsers={commonUsers}
              commonCount={commonCountStrFormat}
              openModal={props.openModal}
            />
          );
        }

        return false;
      })
    ) : (
      <Placeholder icon={<Icon56Users3Outline />}>Сообщества не найдены</Placeholder>
    );
  };

  return (
    <div>
      <Group style={{ marginBottom: "20px" }}>
        {groups !== null && error === null ? renderGroupList() : ""}
      </Group>
      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchPopularCommunity,
      })}
    </div>
  );
};

export default PCUserList;
