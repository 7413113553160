import { Div, Panel } from "@vkontakte/vkui";
import { withRouter } from "hoc";
import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import AffiliateBalance from "views/myProfile/components/AffiliateBalance/AffiliateBalance";
import HistoryList from "./components/HistoryList/HistoryList";

const PanelAffiliateRefererHistory = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack} isVisibleBalance={false}>
        Партнёрская программа
      </PanelHeaderWithPath>
      <Div>
        <AffiliateBalance
          openView={props.openView}
          isWithdrawal={true}
          style={{ marginBottom: "0px" }}
        />
      </Div>
      <HistoryList />
    </Panel>
  );
};

export default withRouter(PanelAffiliateRefererHistory);
