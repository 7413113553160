import React from "react";
import { ModalCard } from "@vkontakte/vkui";
import EyesIcon from "assets/img/guest/eyes.svg";

const VisitedModal = (props) => {
  return (
    <ModalCard
      id={props.id}
      header="Хочешь узнать кто посетил твою страницу?"
      icon={<img src={EyesIcon} alt=""></img>}
      caption="Подключи тариф «Гости» в магазине"
      onClose={props.handleCloseModal}
      actions={[
        {
          title: "Закрыть",
          mode: "secondary",
          action: props.handleCloseModal,
        },
        {
          title: "В магазин",
          mode: "primary",
          action: props.handleGoShopVisitedModal,
        },
      ]}
    />
  );
};

export default VisitedModal;
