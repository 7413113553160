import React from "react";
import { useSelector } from "react-redux";
import { ModalCard } from "@vkontakte/vkui";
import ConnectedIcon from "assets/img/shop/modal/tariffConnected.png";
import { STORY_GUEST, STORY_TOOL } from "stories/constStories";
import { PANEL_MAIN } from "views/guest/panels/constPanels";

const TariffConnectedModal = (props) => {
  const tariffCaption = useSelector((state) => state.shop.tariff.tariffCaption);
  const tariffType = useSelector((state) => state.shop.tariff.tariffType);

  const getDescription = () => {
    switch (tariffType) {
      case "guest":
        return "Теперь вы можете узнать кто и когда посещал вашу страничку";
      case "tools":
        return "Теперь вы можете добалять пользователей для отслеживания в инструментах «Тотальная слежка» и «Время онлайн»";
      default:
        return "";
    }
  };

  const getBtnTitle = () => {
    switch (tariffType) {
      case "guest":
        return "Перейти к списку гостей";
      case "tools":
        return "Перейти к инструментам";
      default:
        return "Ок";
    }
  };

  const getBtnAction = () => {
    switch (tariffType) {
      case "guest":
        props.handleCloseModal();
        props.openStory({ story: STORY_GUEST, panel: PANEL_MAIN });
        break;
      case "tools":
        props.handleCloseModal();
        props.openStory({ story: STORY_TOOL, panel: PANEL_MAIN });
        break;
      default:
        props.handleCloseModal();
        return false;
    }
  };

  return (
    <ModalCard
      id={props.id}
      icon={<img style={{ width: "50px", height: "50px" }} src={ConnectedIcon} alt="" />}
      header={"Тариф «" + tariffCaption + "» успешно подключен!"}
      caption={getDescription()}
      onClose={props.handleCloseModal}
      actions={[
        {
          title: getBtnTitle(),
          mode: "primary",
          action: getBtnAction,
        },
      ]}
    />
  );
};

export default TariffConnectedModal;
