import {
  PROFILE_TIME_ONLINE_REQUEST,
  PROFILE_TIME_ONLINE_SUCCESS,
  PROFILE_TIME_ONLINE_ERROR,
  PROFILE_TIME_ONLINE_SET_PERIOD,
  PROFILE_TIME_ONLINE_SHOW_MORE,
} from "../../constType";

import { APIEye3GetChangesTrackOnline } from "api/eye3";

import { getPeriod } from "template/popouts/DateChangePeriod/actions";
import { openModal } from "../page";
import { checkEYE3ApiError, defaultErrorProperty } from "api/eye3/responseHandler";

export function fetchTimeOnline() {
  return async (dispatch, getState) => {
    try {
      const state = getState().profileTimeOnline;
      const page = getState().page;
      const profile = getState().profile;
      const id = profile.history[page.activeStory].user.id;

      const period = state.period;

      dispatch(request(id));

      const { atMin, atMax } = getPeriod(period);

      let result = await APIEye3GetChangesTrackOnline({
        userId: id,
        startAt: atMin,
        endAt: atMax,
      });

      //console.log(result);

      // Проверка ошибок в API
      const errorAPI = checkEYE3ApiError(result);
      if (errorAPI !== false) {
        errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
        dispatch(error({ ...errorAPI }));
        return;
      }

      if (result.hasOwnProperty("is_active") && !result.is_active) {
        dispatch(
          error({
            ...defaultErrorProperty,
            message: "Пользователь не активен",
            isTryAgain: false,
            isVisibleMessage: true,
          })
        );
      }

      result = result.hasOwnProperty("online_data") ? result.online_data : [];

      dispatch(success(result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e, isTryAgain: false }));
    }
  };

  function request(id) {
    return { type: PROFILE_TIME_ONLINE_REQUEST, id };
  }

  function success(payload) {
    return { type: PROFILE_TIME_ONLINE_SUCCESS, payload };
  }

  function error(error) {
    return { type: PROFILE_TIME_ONLINE_ERROR, error };
  }
}

export function setPeriod(name, atMin = null, atMax = null) {
  return { type: PROFILE_TIME_ONLINE_SET_PERIOD, name, atMin, atMax };
}

export function showMoreTimeOnline() {
  return { type: PROFILE_TIME_ONLINE_SHOW_MORE };
}
