import styles from "./CategoryItem.module.scss";

CategoryItem.defaultProps = {
  title: "",
  icon: null,
  style: null,
  to: null,
};

function CategoryItem(props) {
  const handleClick = () => {
    props.openModal({ id: props.to });
  };

  return (
    <div className={styles.item} style={props.style} onClick={handleClick}>
      <div className={styles.icon}>{props.icon}</div>
      <p className={styles.title}>{props.title}</p>
    </div>
  );
}

export default CategoryItem;
