// page
export const PAGE_OPEN_STORY = "PAGE_OPEN_STORY";
export const PAGE_OPEN_VIEW = "PAGE_OPEN_VIEW";
export const PAGE_OPEN_MODAL = "PAGE_OPEN_MODAL";
export const PAGE_CLOSE_MODAL = "PAGE_CLOSE_MODAL";
export const PAGE_OPEN_POPOUT = "PAGE_OPEN_POPOUT";
export const PAGE_CLOSE_POPOUT = "PAGE_CLOSE_POPOUT";
export const PAGE_GO_BACK = "PAGE_GO_BACK";
export const PAGE_GO_BACK_MODAL = "PAGE_GO_BACK_MODAL";
export const PAGE_GO_BACK_POPOUT = "PAGE_GO_BACK_POPOUT";
export const PAGE_ON_TRANSITION = "PAGE_ON_TRANSITION";
export const PAGE_CLEAR_HISTORY = "PAGE_CLEAR_HISTORY";

// my-profile
export const MY_PROFILE_AUTH_REQUEST = "MY_PROFILE_AUTH_REQUEST";
export const MY_PROFILE_AUTH_SUCCESS = "MY_PROFILE_AUTH_SUCCESS";
export const MY_PROFILE_AUTH_ERROR = "MY_PROFILE_AUTH_ERROR";
export const MY_PROFILE_CHECKED_ACCESS_REQUEST = "MY_PROFILE_CHECKED_ACCESS_REQUEST";
export const MY_PROFILE_CHECKED_ACCESS_PROGRESS = "MY_PROFILE_CHECKED_ACCESS_PROGRESS";
export const MY_PROFILE_CHECKED_ACCESS_SUCCESS = "MY_PROFILE_CHECKED_ACCESS_SUCCESS";
export const MY_PROFILE_CHECKED_ACCESS_ERROR = "MY_PROFILE_CHECKED_ACCESS_ERROR";
export const MY_PROFILE_UPDATE_REQUEST = "MY_PROFILE_UPDATE_REQUEST";
export const MY_PROFILE_UPDATE_SUCCESS = "MY_PROFILE_UPDATE_SUCCESS";
export const MY_PROFILE_UPDATE_ERROR = "MY_PROFILE_UPDATE_ERROR";
export const MY_PROFILE_SUBSCRIBE_GROUP_REQUEST = "MY_PROFILE_SUBSCRIBE_GROUP_REQUEST";
export const MY_PROFILE_SUBSCRIBE_GROUP_SUCCESS = "MY_PROFILE_SUBSCRIBE_GROUP_SUCCESS";
export const MY_PROFILE_SUBSCRIBE_GROUP_ERROR = "MY_PROFILE_SUBSCRIBE_GROUP_ERROR";
export const MY_PROFILE_SET_NOTIFICATION_REQUEST = "MY_PROFILE_SET_NOTIFICATION_REQUEST";
export const MY_PROFILE_SET_NOTIFICATION_SUCCESS = "MY_PROFILE_SET_NOTIFICATION_SUCCESS";
export const MY_PROFILE_SET_NOTIFICATION_ERROR = "MY_PROFILE_SET_NOTIFICATION_ERROR";
export const MY_PROFILE_SET_LICENSE_RENEW_REQUEST = "MY_PROFILE_SET_LICENSE_RENEW_REQUEST";
export const MY_PROFILE_SET_LICENSE_RENEW_SUCCESS = "MY_PROFILE_SET_LICENSE_RENEW_SUCCESS";
export const MY_PROFILE_SET_LICENSE_RENEW_ERROR = "MY_PROFILE_SET_LICENSE_RENEW_ERROR";
export const MY_PROFILE_LICENSE_REQUEST = "MY_PROFILE_LICENSE_REQUEST";
export const MY_PROFILE_LICENSE_SUCCESS = "MY_PROFILE_LICENSE_SUCCESS";
export const MY_PROFILE_LICENSE_ERROR = "MY_PROFILE_LICENSE_ERROR";

// partner
export const PARTNER_REFERER_HISTORY_REQUEST = "PARTNER_REFERER_HISTORY_REQUEST";
export const PARTNER_REFERER_HISTORY_SUCCESS = "PARTNER_REFERER_HISTORY_SUCCESS";
export const PARTNER_REFERER_HISTORY_ERROR = "PARTNER_REFERER_HISTORY_ERROR";
export const PARTNER_REFERER_HISTORY_SHOW_MORE = "PARTNER_REFERER_HISTORY_SHOW_MORE";
export const PARTNER_CLS_WITHDRAW = "PARTNER_CLS_WITHDRAW";
export const PARTNER_WITHDRAW_TO_MONEY_REQUEST = "PARTNER_WITHDRAW_TO_MONEY_REQUEST";
export const PARTNER_WITHDRAW_TO_MONEY_SUCCESS = "PARTNER_WITHDRAW_TO_MONEY_SUCCESS";
export const PARTNER_WITHDRAW_TO_MONEY_ERROR = "PARTNER_WITHDRAW_TO_MONEY_ERROR";
export const PARTNER_WITHDRAW_TO_COIN_REQUEST = "PARTNER_WITHDRAW_TO_COIN_REQUEST";
export const PARTNER_WITHDRAW_TO_COIN_SUCCESS = "PARTNER_WITHDRAW_TO_COIN_SUCCESS";
export const PARTNER_WITHDRAW_TO_COIN_ERROR = "PARTNER_WITHDRAW_TO_COIN_ERROR";

export const PARTNER_ACTIVE_REF_REQUEST = "PARTNER_ACTIVE_REF_REQUEST";
export const PARTNER_ACTIVE_REF_SUCCESS = "PARTNER_ACTIVE_REF_SUCCESS";
export const PARTNER_ACTIVE_REF_ERROR = "PARTNER_ACTIVE_REF_ERROR";

// vk-storage
export const VK_GET_STORAGE_REQUEST = "VK_GET_STORAGE_REQUEST";
export const VK_GET_STORAGE_SUCCESS = "VK_GET_STORAGE_SUCCESS";
export const VK_GET_STORAGE_ERROR = "VK_GET_STORAGE_ERROR";
export const VK_SET_STORAGE_REQUEST = "VK_SET_STORAGE_REQUEST";
export const VK_SET_STORAGE_SUCCESS = "VK_SET_STORAGE_SUCCESS";
export const VK_SET_STORAGE_ERROR = "VK_SET_STORAGE_ERROR";

// friend
export const FRIEND_REQUEST = "FRIEND_REQUEST";
export const FRIEND_SUCCESS = "FRIEND_SUCCESS";
export const FRIEND_ERROR = "FRIEND_ERROR";

// view: guest - panel: profile
export const PROFILE_REQUEST = "PROFILE_REQUEST";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const PROFILE_TRACK_ACTIVE_REQUEST = "PROFILE_TRACK_ACTIVE_REQUEST";
export const PROFILE_TRACK_ACTIVE_SUCCESS = "PROFILE_TRACK_ACTIVE_SUCCESS";
export const PROFILE_TRACK_ACTIVE_ERROR = "PROFILE_TRACK_ACTIVE_ERROR";
export const PROFILE_SET_CRUSH_REQUEST = "PROFILE_SET_CRUSH_REQUEST";
export const PROFILE_SET_CRUSH_SUCCESS = "PROFILE_SET_CRUSH_SUCCESS";
export const PROFILE_SET_CRUSH_ERROR = "PROFILE_SET_CRUSH_ERROR";
export const PROFILE_SET_TRACKING_REQUEST = "PROFILE_SET_TRACKING_REQUEST";
export const PROFILE_SET_TRACKING_SUCCESS = "PROFILE_SET_TRACKING_SUCCESS";
export const PROFILE_SET_TRACKING_ERROR = "PROFILE_SET_TRACKING_ERROR";
export const PROFILE_SET_NOTIFICATION_REQUEST = "PROFILE_SET_NOTIFICATION_REQUEST";
export const PROFILE_SET_NOTIFICATION_SUCCESS = "PROFILE_SET_NOTIFICATION_SUCCESS";
export const PROFILE_SET_NOTIFICATION_ERROR = "PROFILE_SET_NOTIFICATION_ERROR";

// profile --- viewPage
export const PROFILE_VIEW_PAGE_REQUEST = "PROFILE_VIEW_PAGE_REQUEST";
export const PROFILE_VIEW_PAGE_SUCCESS = "PROFILE_VIEW_PAGE_SUCCESS";
export const PROFILE_VIEW_PAGE_ERROR = "PROFILE_VIEW_PAGE_ERROR";

// profile --- viewStories
export const PROFILE_VIEW_STORIES_REQUEST = "PROFILE_VIEW_STORIES_REQUEST";
export const PROFILE_VIEW_STORIES_SUCCESS = "PROFILE_VIEW_STORIES_SUCCESS";
export const PROFILE_VIEW_STORIES_ERROR = "PROFILE_VIEW_STORIES_ERROR";
export const PROFILE_VIEW_STORIES_SHOW_MORE = "PROFILE_VIEW_STORIES_SHOW_MORE";
export const PROFILE_VIEW_STORIES_PROGRESS = "PROFILE_VIEW_STORIES_PROGRESS";
export const PROFILE_VIEW_STORIES_CANCEL = "PROFILE_VIEW_STORIES_CANCEL";

// profile --- like
export const PROFILE_LIKE_REQUEST = "PROFILE_LIKE_REQUEST";
export const PROFILE_LIKE_SUCCESS = "PROFILE_LIKE_SUCCESS";
export const PROFILE_LIKE_ERROR = "PROFILE_LIKE_ERROR";
export const PROFILE_LIKE_SHOW_MORE = "PROFILE_LIKE_SHOW_MORE";
export const PROFILE_LIKE_PROGRESS = "PROFILE_LIKE_PROGRESS";
export const PROFILE_LIKE_CANCEL = "PROFILE_LIKE_CANCEL";

// profile --- comment
export const PROFILE_COMMENT_REQUEST = "PROFILE_COMMENT_REQUEST";
export const PROFILE_COMMENT_SUCCESS = "PROFILE_COMMENT_SUCCESS";
export const PROFILE_COMMENT_ERROR = "PROFILE_COMMENT_ERROR";
export const PROFILE_COMMENT_SHOW_MORE = "PROFILE_COMMENT_SHOW_MORE";
export const PROFILE_COMMENT_PROGRESS = "PROFILE_COMMENT_PROGRESS";
export const PROFILE_COMMENT_CANCEL = "PROFILE_COMMENT_CANCEL";

// profile --- timeOnline
export const PROFILE_TIME_ONLINE_REQUEST = "PROFILE_TIME_ONLINE_REQUEST";
export const PROFILE_TIME_ONLINE_SUCCESS = "PROFILE_TIME_ONLINE_SUCCESS";
export const PROFILE_TIME_ONLINE_ERROR = "PROFILE_TIME_ONLINE_ERROR";
export const PROFILE_TIME_ONLINE_SHOW_MORE = "PROFILE_TIME_ONLINE_SHOW_MORE";
export const PROFILE_TIME_ONLINE_SET_PERIOD = "PROFILE_TIME_ONLINE_SET_PERIOD";

// profile --- infoHistory
export const PROFILE_INFO_HISTORY_REQUEST = "PROFILE_INFO_HISTORY_REQUEST";
export const PROFILE_INFO_HISTORY_SUCCESS = "PROFILE_INFO_HISTORY_SUCCESS";
export const PROFILE_INFO_HISTORY_ERROR = "PROFILE_INFO_HISTORY_ERROR";
export const PROFILE_INFO_HISTORY_SHOW_MORE = "PROFILE_INFO_HISTORY_SHOW_MORE";

// profile --- maritalStatus
export const PROFILE_MARITAL_STATUS_REQUEST = "PROFILE_MARITAL_STATUS_REQUEST";
export const PROFILE_MARITAL_STATUS_SUCCESS = "PROFILE_MARITAL_STATUS_SUCCESS";
export const PROFILE_MARITAL_STATUS_ERROR = "PROFILE_MARITAL_STATUS_ERROR";
export const PROFILE_MARITAL_STATUS_SHOW_MORE = "PROFILE_MARITAL_STATUS_SHOW_MORE";

// profile --- friendHistory
export const PROFILE_FRIEND_HISTORY_REQUEST = "PROFILE_FRIEND_HISTORY_REQUEST";
export const PROFILE_FRIEND_HISTORY_SUCCESS = "PROFILE_FRIEND_HISTORY_SUCCESS";
export const PROFILE_FRIEND_HISTORY_ERROR = "PROFILE_FRIEND_HISTORY_ERROR";
export const PROFILE_FRIEND_HISTORY_SHOW_MORE = "PROFILE_FRIEND_HISTORY_SHOW_MORE";

// profile --- joinCommunity
export const PROFILE_JOIN_COMMUNITY_REQUEST = "PROFILE_JOIN_COMMUNITY_REQUEST";
export const PROFILE_JOIN_COMMUNITY_SUCCESS = "PROFILE_JOIN_COMMUNITY_SUCCESS";
export const PROFILE_JOIN_COMMUNITY_ERROR = "PROFILE_JOIN_COMMUNITY_ERROR";
export const PROFILE_JOIN_COMMUNITY_SHOW_MORE = "PROFILE_JOIN_COMMUNITY_SHOW_MORE";

// profile --- allLike
export const PROFILE_ALL_LIKE_REQUEST = "PROFILE_ALL_LIKE_REQUEST";
export const PROFILE_ALL_LIKE_SUCCESS = "PROFILE_ALL_LIKE_SUCCESS";
export const PROFILE_ALL_LIKE_ERROR = "PROFILE_ALL_LIKE_ERROR";
export const PROFILE_ALL_LIKE_CANCEL = "PROFILE_ALL_LIKE_CANCEL";
export const PROFILE_ALL_LIKE_PROGRESS = "PROFILE_ALL_LIKE_PROGRESS";
export const PROFILE_ALL_LIKE_SHOW_MORE = "PROFILE_ALL_LIKE_SHOW_MORE";

// profile --- allComment
export const PROFILE_ALL_COMMENT_REQUEST = "PROFILE_ALL_COMMENT_REQUEST";
export const PROFILE_ALL_COMMENT_SUCCESS = "PROFILE_ALL_COMMENT_SUCCESS";
export const PROFILE_ALL_COMMENT_ERROR = "PROFILE_ALL_COMMENT_ERROR";
export const PROFILE_ALL_COMMENT_CANCEL = "PROFILE_ALL_COMMENT_CANCEL";
export const PROFILE_ALL_COMMENT_PROGRESS = "PROFILE_ALL_COMMENT_PROGRESS";
export const PROFILE_ALL_COMMENT_SHOW_MORE = "PROFILE_ALL_COMMENT_SHOW_MORE";

// profile --- gift
export const PROFILE_GIFT_REQUEST = "PROFILE_GIFT_REQUEST";
export const PROFILE_GIFT_SUCCESS = "PROFILE_GIFT_SUCCESS";
export const PROFILE_GIFT_ERROR = "PROFILE_GIFT_ERROR";
export const PROFILE_GIFT_SHOW_MORE = "PROFILE_GIFT_SHOW_MORE";

// profile --- horoscope
export const PROFILE_HOROSCOPE_REQUEST = "PROFILE_HOROSCOPE_REQUEST";
export const PROFILE_HOROSCOPE_SUCCESS = "PROFILE_HOROSCOPE_SUCCESS";
export const PROFILE_HOROSCOPE_ERROR = "PROFILE_HOROSCOPE_ERROR";

// shop
export const SHOP_BALANCE_REPLENISHMENT_CREATE_PAYMENT_REQUEST =
  "SHOP_BALANCE_REPLENISHMENT_CREATE_PAYMENT_REQUEST";
export const SHOP_BALANCE_REPLENISHMENT_CREATE_PAYMENT_SUCCESS =
  "SHOP_BALANCE_REPLENISHMENT_CREATE_PAYMENT_SUCCESS";
export const SHOP_BALANCE_REPLENISHMENT_CREATE_PAYMENT_ERROR =
  "SHOP_BALANCE_REPLENISHMENT_CREATE_PAYMENT_ERROR";
export const SHOP_BALANCE_REPLENISHMENT_CREATE_UNITPAY_PAYMENT_REQUEST =
  "SHOP_BALANCE_REPLENISHMENT_CREATE_UNITPAY_PAYMENT_REQUEST";
export const SHOP_BALANCE_REPLENISHMENT_CREATE_UNITPAY_PAYMENT_SUCCESS =
  "SHOP_BALANCE_REPLENISHMENT_CREATE_UNITPAY_PAYMENT_SUCCESS";
export const SHOP_BALANCE_REPLENISHMENT_CREATE_UNITPAY_PAYMENT_ERROR =
  "SHOP_BALANCE_REPLENISHMENT_CREATE_UNITPAY_PAYMENT_ERROR";
export const SHOP_BALANCE_REPLENISHMENT_SAVE_PAYMENT = "SHOP_BALANCE_REPLENISHMENT_SAVE_PAYMENT";
export const SHOP_CONNECT_TARIFF_REQUEST = "SHOP_CONNECT_TARIFF_REQUEST";
export const SHOP_CONNECT_TARIFF_SUCCESS = "SHOP_CONNECT_TARIFF_SUCCESS";
export const SHOP_CONNECT_TARIFF_ERROR = "SHOP_CONNECT_TARIFF_ERROR";
export const SHOP_SAVE_TARIFF = "SHOP_SAVE_TARIFF";
export const SHOP_BALANCE_CHECKED_PAYMENT_REQUEST = "SHOP_BALANCE_CHECKED_PAYMENT_REQUEST";
export const SHOP_BALANCE_CHECKED_PAYMENT_PROGRESS = "SHOP_BALANCE_CHECKED_PAYMENT_PROGRESS";
export const SHOP_BALANCE_CHECKED_PAYMENT_SUCCESS = "SHOP_BALANCE_CHECKED_PAYMENT_SUCCESS";
export const SHOP_BALANCE_CHECKED_PAYMENT_ERROR = "SHOP_BALANCE_CHECKED_PAYMENT_ERROR";
export const SHOP_BALANCE_CHECKED_PAYMENT_CANCEL = "SHOP_BALANCE_CHECKED_PAYMENT_CANCEL";

export const SHOP_BALANCE_HISTORY_REQUEST = "SHOP_BALANCE_HISTORY_REQUEST";
export const SHOP_BALANCE_HISTORY_SUCCESS = "SHOP_BALANCE_HISTORY_SUCCESS";
export const SHOP_BALANCE_HISTORY_ERROR = "SHOP_BALANCE_HISTORY_ERROR";
export const SHOP_BALANCE_HISTORY_LOAD_MORE_REQUEST = "SHOP_BALANCE_HISTORY_LOAD_MORE_REQUEST";
export const SHOP_BALANCE_HISTORY_LOAD_MORE_SUCCESS = "SHOP_BALANCE_HISTORY_LOAD_MORE_SUCCESS";
export const SHOP_BALANCE_HISTORY_LOAD_MORE_ERROR = "SHOP_BALANCE_HISTORY_LOAD_MORE_ERROR";

// guest
export const GUEST_REQUEST = "GUEST_REQUEST";
export const GUEST_SUCCESS = "GUEST_SUCCESS";
export const GUEST_ERROR = "GUEST_ERROR";
export const GUEST_TABS = "GUEST_TABS";
export const GUEST_SHOW_MORE = "GUEST_SHOW_MORE";
export const GUEST_SEARCH_REQUEST = "GUEST_SEARCH_REQUEST";
export const GUEST_SEARCH_SUCCESS = "GUEST_SEARCH_SUCCESS";
export const GUEST_SEARCH_ERROR = "GUEST_SEARCH_ERROR";
export const GUEST_FILTER_REQUEST = "GUEST_FILTER_REQUEST";
export const GUEST_FILTER_SUCCESS = "GUEST_FILTER_SUCCESS";
export const GUEST_FILTER_ERROR = "GUEST_FILTER_ERROR";

// total surveillance
export const TOTAL_SURVEILLANCE_REQUEST = "TOTAL_SURVEILLANCE_REQUEST";
export const TOTAL_SURVEILLANCE_SUCCESS = "TOTAL_SURVEILLANCE_SUCCESS";
export const TOTAL_SURVEILLANCE_ERROR = "TOTAL_SURVEILLANCE_ERROR";

export const TOTAL_SURVEILLANCE_SET_TRACKING_REQUEST = "TOTAL_SURVEILLANCE_SET_TRACKING_REQUEST";
export const TOTAL_SURVEILLANCE_SET_TRACKING_SUCCESS = "TOTAL_SURVEILLANCE_SET_TRACKING_SUCCESS";
export const TOTAL_SURVEILLANCE_SET_TRACKING_ERROR = "TOTAL_SURVEILLANCE_SET_TRACKING_ERROR";
export const TOTAL_SURVEILLANCE_SET_NOTIFICATION_REQUEST =
  "TOTAL_SURVEILLANCE_SET_NOTIFICATION_REQUEST";
export const TOTAL_SURVEILLANCE_SET_NOTIFICATION_SUCCESS =
  "TOTAL_SURVEILLANCE_SET_NOTIFICATION_SUCCESS";
export const TOTAL_SURVEILLANCE_SET_NOTIFICATION_ERROR =
  "TOTAL_SURVEILLANCE_SET_NOTIFICATION_ERROR";

export const TOTAL_SURVEILLANCE__START_TRACKING_REQUEST =
  "TOTAL_SURVEILLANCE__START_TRACKING_REQUEST";
export const TOTAL_SURVEILLANCE__START_TRACKING_SUCCESS =
  "TOTAL_SURVEILLANCE__START_TRACKING_SUCCESS";
export const TOTAL_SURVEILLANCE__START_TRACKING_ERROR = "TOTAL_SURVEILLANCE__START_TRACKING_ERROR";
export const TOTAL_SURVEILLANCE__START_TRACKING_SHOW_MORE =
  "TOTAL_SURVEILLANCE__START_TRACKING_SHOW_MORE";
export const TOTAL_SURVEILLANCE__START_TRACKING_SEARCH_REQUEST =
  "TOTAL_SURVEILLANCE__START_TRACKING_SEARCH_REQUEST";
export const TOTAL_SURVEILLANCE__START_TRACKING_SEARCH_SUCCESS =
  "TOTAL_SURVEILLANCE__START_TRACKING_SEARCH_SUCCESS";
export const TOTAL_SURVEILLANCE__START_TRACKING_SEARCH_ERROR =
  "TOTAL_SURVEILLANCE__START_TRACKING_SEARCH_ERROR";
export const TOTAL_SURVEILLANCE__START_TRACKING_FILTER_REQUEST =
  "TOTAL_SURVEILLANCE__START_TRACKING_FILTER_REQUEST";
export const TOTAL_SURVEILLANCE__START_TRACKING_FILTER_SUCCESS =
  "TOTAL_SURVEILLANCE__START_TRACKING_FILTER_SUCCESS";
export const TOTAL_SURVEILLANCE__START_TRACKING_FILTER_ERROR =
  "TOTAL_SURVEILLANCE__START_TRACKING_FILTER_ERROR";

// who hidden
export const WHO_HIDDEN__WHO_HIDDEN_ME_REQUEST = "WHO_HIDDEN__WHO_HIDDEN_ME_REQUEST";
export const WHO_HIDDEN__WHO_HIDDEN_ME_SUCCESS = "WHO_HIDDEN__WHO_HIDDEN_ME_SUCCESS";
export const WHO_HIDDEN__WHO_HIDDEN_ME_PROGRESS = "WHO_HIDDEN__WHO_HIDDEN_ME_PROGRESS";
export const WHO_HIDDEN__WHO_HIDDEN_ME_CANCEL = "WHO_HIDDEN__WHO_HIDDEN_ME_CANCEL";
export const WHO_HIDDEN__WHO_HIDDEN_ME_ERROR = "WHO_HIDDEN__WHO_HIDDEN_ME_ERROR";

export const WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_REQUEST =
  "WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_REQUEST";
export const WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_SUCCESS =
  "WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_SUCCESS";
export const WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_ERROR =
  "WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_ERROR";
export const WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_SHOW_MORE =
  "WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_SHOW_MORE";
export const WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_SEARCH_REQUEST =
  "WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_SEARCH_REQUEST";
export const WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_SEARCH_SUCCESS =
  "WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_SEARCH_SUCCESS";
export const WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_SEARCH_ERROR =
  "WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_SEARCH_ERROR";
export const WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_FILTER_REQUEST =
  "WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_FILTER_REQUEST";
export const WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_FILTER_SUCCESS =
  "WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_FILTER_SUCCESS";
export const WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_FILTER_ERROR =
  "WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_FILTER_ERROR";

export const WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_INFO_REQUEST =
  "WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_INFO_REQUEST";
export const WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_INFO_SUCCESS =
  "WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_INFO_SUCCESS";
export const WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_INFO_CANCEL =
  "WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_INFO_CANCEL";
export const WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_INFO_PROGRESS =
  "WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_INFO_PROGRESS";
export const WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_INFO_ERROR =
  "WHO_HIDDEN__WHO_HIDDEN_ANOTHER_USER_INFO_ERROR";

export const WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_REQUEST =
  "WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_REQUEST";
export const WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_SUCCESS =
  "WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_SUCCESS";
export const WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_ERROR =
  "WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_ERROR";
export const WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_SHOW_MORE =
  "WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_SHOW_MORE";
export const WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_SEARCH_REQUEST =
  "WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_SEARCH_REQUEST";
export const WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_SEARCH_SUCCESS =
  "WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_SEARCH_SUCCESS";
export const WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_SEARCH_ERROR =
  "WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_SEARCH_ERROR";
export const WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_FILTER_REQUEST =
  "WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_FILTER_REQUEST";
export const WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_FILTER_SUCCESS =
  "WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_FILTER_SUCCESS";
export const WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_FILTER_ERROR =
  "WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_FILTER_ERROR";

export const WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_INFO_REQUEST =
  "WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_INFO_REQUEST";
export const WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_INFO_SUCCESS =
  "WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_INFO_SUCCESS";
export const WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_INFO_CANCEL =
  "WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_INFO_CANCEL";
export const WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_INFO_PROGRESS =
  "WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_INFO_PROGRESS";
export const WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_INFO_ERROR =
  "WHO_HIDDEN__WHOM_HIDDEN_ANOTHER_USER_INFO_ERROR";

export const WHO_HIDDEN__WHOM_HIDDEN_IM_REQUEST = "WHO_HIDDEN__WHOM_HIDDEN_IM_REQUEST";
export const WHO_HIDDEN__WHOM_HIDDEN_IM_SUCCESS = "WHO_HIDDEN__WHOM_HIDDEN_IM_SUCCESS";
export const WHO_HIDDEN__WHOM_HIDDEN_IM_ERROR = "WHO_HIDDEN__WHOM_HIDDEN_IM_ERROR";

// who added chs
export const WHO_ADDED_CHS_REQUEST = "WHO_ADDED_CHS_REQUEST";
export const WHO_ADDED_CHS_PROGRESS = "WHO_ADDED_CHS_PROGRESS";
export const WHO_ADDED_CHS_SUCCESS = "WHO_ADDED_CHS_SUCCESS";
export const WHO_ADDED_CHS_CANCEL = "WHO_ADDED_CHS_CANCEL";
export const WHO_ADDED_CHS_ERROR = "WHO_ADDED_CHS_ERROR";

// registration date
export const REGISTRATION_DATE_REQUEST = "REGISTRATION_DATE_REQUEST";
export const REGISTRATION_DATE_SUCCESS = "REGISTRATION_DATE_SUCCESS";
export const REGISTRATION_DATE_ERROR = "REGISTRATION_DATE_ERROR";
export const REGISTRATION_DATE_FRIEND_SHOW_MORE = "REGISTRATION_DATE_FRIEND_SHOW_MORE";
export const REGISTRATION_DATE_FRIEND_SEARCH_REQUEST = "REGISTRATION_DATE_FRIEND_SEARCH_REQUEST";
export const REGISTRATION_DATE_FRIEND_SEARCH_SUCCESS = "REGISTRATION_DATE_FRIEND_SEARCH_SUCCESS";
export const REGISTRATION_DATE_FRIEND_SEARCH_ERROR = "REGISTRATION_DATE_FRIEND_SEARCH_ERROR";
export const REGISTRATION_DATE_FRIEND_FILTER_REQUEST = "REGISTRATION_DATE_FRIEND_FILTER_REQUEST";
export const REGISTRATION_DATE_FRIEND_FILTER_SUCCESS = "REGISTRATION_DATE_FRIEND_FILTER_SUCCESS";
export const REGISTRATION_DATE_FRIEND_FILTER_ERROR = "REGISTRATION_DATE_FRIEND_FILTER_ERROR";

// activity
export const ACTIVITY__USER_REQUEST = "ACTIVITY__USER_REQUEST";
export const ACTIVITY__USER_SUCCESS = "ACTIVITY__USER_SUCCESS";
export const ACTIVITY__USER_ERROR = "ACTIVITY__USER_ERROR";
export const ACTIVITY__USER_SHOW_MORE = "ACTIVITY__USER_SHOW_MORE";
export const ACTIVITY__USER_SEARCH_REQUEST = "ACTIVITY__USER_SEARCH_REQUEST";
export const ACTIVITY__USER_SEARCH_SUCCESS = "ACTIVITY__USER_SEARCH_SUCCESS";
export const ACTIVITY__USER_SEARCH_ERROR = "ACTIVITY__USER_SEARCH_ERROR";
export const ACTIVITY__USER_FILTER_REQUEST = "ACTIVITY__USER_FILTER_REQUEST";
export const ACTIVITY__USER_FILTER_SUCCESS = "ACTIVITY__USER_FILTER_SUCCESS";
export const ACTIVITY__USER_FILTER_ERROR = "ACTIVITY__USER_FILTER_ERROR";

export const ACTIVITY__OTHER_USER_REQUEST = "ACTIVITY__OTHER_USER_REQUEST";
export const ACTIVITY__OTHER_USER_SUCCESS = "ACTIVITY__OTHER_USER_SUCCESS";
export const ACTIVITY__OTHER_USER_ERROR = "ACTIVITY__OTHER_USER_ERROR";
export const ACTIVITY__OTHER_USER_SHOW_MORE = "ACTIVITY__OTHER_USER_SHOW_MORE";
export const ACTIVITY__OTHER_USER_SEARCH_REQUEST = "ACTIVITY__OTHER_USER_SEARCH_REQUEST";
export const ACTIVITY__OTHER_USER_SEARCH_SUCCESS = "ACTIVITY__OTHER_USER_SEARCH_SUCCESS";
export const ACTIVITY__OTHER_USER_SEARCH_ERROR = "ACTIVITY__OTHER_USER_SEARCH_ERROR";
export const ACTIVITY__OTHER_USER_FILTER_REQUEST = "ACTIVITY__OTHER_USER_FILTER_REQUEST";
export const ACTIVITY__OTHER_USER_FILTER_SUCCESS = "ACTIVITY__OTHER_USER_FILTER_SUCCESS";
export const ACTIVITY__OTHER_USER_FILTER_ERROR = "ACTIVITY__OTHER_USER_FILTER_ERROR";

export const ACTIVITY__USER_INFO_REQUEST = "ACTIVITY__USER_INFO_REQUEST";
export const ACTIVITY__USER_INFO_PROGRESS = "ACTIVITY__USER_INFO_PROGRESS";
export const ACTIVITY__USER_INFO_CANCEL = "ACTIVITY__USER_INFO_CANCEL";
export const ACTIVITY__USER_INFO_SUCCESS = "ACTIVITY__USER_INFO_SUCCESS";
export const ACTIVITY__USER_INFO_ERROR = "ACTIVITY__USER_INFO_ERROR";
export const ACTIVITY__USER_INFO_SET_PERIOD = "ACTIVITY__USER_INFO_SET_PERIOD";
export const ACTIVITY__USER_INFO_TABS = "ACTIVITY__USER_INFO_TABS";
export const ACTIVITY__USER_INFO_SEARCH = "ACTIVITY__USER_INFO_SEARCH";
export const ACTIVITY__USER_INFO_FILTER = "ACTIVITY__USER_INFO_FILTER";

export const ACTIVITY__SET_OTHER_USER_PAGE = "ACTIVITY__SET_OTHER_USER_PAGE";

export const ACTIVITY__OTHER_USER_INFO_REQUEST = "ACTIVITY__OTHER_USER_INFO_REQUEST";
export const ACTIVITY__OTHER_USER_INFO_PROGRESS = "ACTIVITY__OTHER_USER_INFO_PROGRESS";
export const ACTIVITY__OTHER_USER_INFO_CANCEL = "ACTIVITY__OTHER_USER_INFO_CANCEL";
export const ACTIVITY__OTHER_USER_INFO_SUCCESS = "ACTIVITY__OTHER_USER_INFO_SUCCESS";
export const ACTIVITY__OTHER_USER_INFO_ERROR = "ACTIVITY__OTHER_USER_INFO_ERROR";
export const ACTIVITY__OTHER_USER_INFO_SET_PERIOD = "ACTIVITY__OTHER_USER_INFO_SET_PERIOD";
export const ACTIVITY__OTHER_USER_INFO_TABS = "ACTIVITY__OTHER_USER_INFO_TABS";
export const ACTIVITY__OTHER_USER_INFO_SEARCH = "ACTIVITY__OTHER_USER_INFO_SEARCH";
export const ACTIVITY__OTHER_USER_INFO_FILTER = "ACTIVITY__OTHER_USER_INFO_FILTER";

export const ACTIVITY__OTHER_USER_ACTIVE_REQUEST = "ACTIVITY__OTHER_USER_ACTIVE_REQUEST";
export const ACTIVITY__OTHER_USER_ACTIVE_SUCCESS = "ACTIVITY__OTHER_USER_ACTIVE_SUCCESS";
export const ACTIVITY__OTHER_USER_ACTIVE_PROGRESS = "ACTIVITY__OTHER_USER_ACTIVE_PROGRESS";
export const ACTIVITY__OTHER_USER_ACTIVE_ERROR = "ACTIVITY__OTHER_USER_ACTIVE_ERROR";
export const ACTIVITY__OTHER_USER_ACTIVE_CANCEL = "ACTIVITY__OTHER_USER_ACTIVE_CANCEL";
export const ACTIVITY__OTHER_USER_ACTIVE_SET_PERIOD = "ACTIVITY__OTHER_USER_ACTIVE_SET_PERIOD";

// admin community
export const ADMIN_COMMUNITY_REQUEST = "ADMIN_COMMUNITY_REQUEST";
export const ADMIN_COMMUNITY_SUCCESS = "ADMIN_COMMUNITY_SUCCESS";
export const ADMIN_COMMUNITY_ERROR = "ADMIN_COMMUNITY_ERROR";
export const ADMIN_COMMUNITY_SHOW_MORE = "ADMIN_COMMUNITY_SHOW_MORE";
export const ADMIN_COMMUNITY_SEARCH_REQUEST = "ADMIN_COMMUNITY_SEARCH_REQUEST";
export const ADMIN_COMMUNITY_SEARCH_SUCCESS = "ADMIN_COMMUNITY_SEARCH_SUCCESS";
export const ADMIN_COMMUNITY_SEARCH_ERROR = "ADMIN_COMMUNITY_SEARCH_ERROR";
export const ADMIN_COMMUNITY_FILTER_REQUEST = "ADMIN_COMMUNITY_FILTER_REQUEST";
export const ADMIN_COMMUNITY_FILTER_SUCCESS = "ADMIN_COMMUNITY_FILTER_SUCCESS";
export const ADMIN_COMMUNITY_FILTER_ERROR = "ADMIN_COMMUNITY_FILTER_ERROR";
export const ADMIN_COMMUNITY__INFO_GROUP_REQUEST = "ADMIN_COMMUNITY__INFO_GROUP_REQUEST";
export const ADMIN_COMMUNITY__INFO_GROUP_SUCCESS = "ADMIN_COMMUNITY__INFO_GROUP_SUCCESS";
export const ADMIN_COMMUNITY__INFO_GROUP_ERROR = "ADMIN_COMMUNITY__INFO_GROUP_ERROR";

// join community
export const JOIN_COMMUNITY_REQUEST = "JOIN_COMMUNITY_REQUEST";
export const JOIN_COMMUNITY_SUCCESS = "JOIN_COMMUNITY_SUCCESS";
export const JOIN_COMMUNITY_ERROR = "JOIN_COMMUNITY_ERROR";
export const JOIN_COMMUNITY_SHOW_MORE = "JOIN_COMMUNITY_SHOW_MORE";
export const JOIN_COMMUNITY_SEARCH_REQUEST = "JOIN_COMMUNITY_SEARCH_REQUEST";
export const JOIN_COMMUNITY_SEARCH_SUCCESS = "JOIN_COMMUNITY_SEARCH_SUCCESS";
export const JOIN_COMMUNITY_SEARCH_ERROR = "JOIN_COMMUNITY_SEARCH_ERROR";
export const JOIN_COMMUNITY_FILTER_REQUEST = "JOIN_COMMUNITY_FILTER_REQUEST";
export const JOIN_COMMUNITY_FILTER_SUCCESS = "JOIN_COMMUNITY_FILTER_SUCCESS";
export const JOIN_COMMUNITY_FILTER_ERROR = "JOIN_COMMUNITY_FILTER_ERROR";
export const JOIN_COMMUNITY__INFO_GROUP_REQUEST = "JOIN_COMMUNITY__INFO_GROUP_REQUEST";
export const JOIN_COMMUNITY__INFO_GROUP_SUCCESS = "JOIN_COMMUNITY__INFO_GROUP_SUCCESS";
export const JOIN_COMMUNITY__INFO_GROUP_ERROR = "JOIN_COMMUNITY__INFO_GROUP_ERROR";
export const JOIN_COMMUNITY__INFO_GROUP_SET_PERIOD = "JOIN_COMMUNITY__INFO_GROUP_SET_PERIOD";

// gift
export const GIFT_REQUEST = "GIFT_REQUEST";
export const GIFT_SUCCESS = "GIFT_SUCCESS";
export const GIFT_ERROR = "GIFT_ERROR";
export const GIFT_SHOW_MORE = "GIFT_SHOW_MORE";
export const GIFT_SEARCH_REQUEST = "GIFT_SEARCH_REQUEST";
export const GIFT_SEARCH_SUCCESS = "GIFT_SEARCH_SUCCESS";
export const GIFT_SEARCH_ERROR = "GIFT_SEARCH_ERROR";
export const GIFT_FILTER_REQUEST = "GIFT_FILTER_REQUEST";
export const GIFT_FILTER_SUCCESS = "GIFT_FILTER_SUCCESS";
export const GIFT_FILTER_ERROR = "GIFT_FILTER_ERROR";
export const GIFT__INFO_REQUEST = "GIFT__INFO_REQUEST";
export const GIFT__INFO_SUCCESS = "GIFT__INFO_SUCCESS";
export const GIFT__INFO_ERROR = "GIFT__INFO_ERROR";
export const GIFT__INFO_SET_PERIOD = "GIFT__INFO_SET_PERIOD";
export const GIFT__INFO_TABS = "GIFT__INFO_TABS";

// moy crush
export const MOY_CRUSH_MY_CRUSH_LIST_REQUEST = "MOY_CRUSH_MY_CRUSH_REQUEST";
export const MOY_CRUSH_MY_CRUSH_LIST_SUCCESS = "MOY_CRUSH_MY_CRUSH_SUCCESS";
export const MOY_CRUSH_MY_CRUSH_LIST_ERROR = "MOY_CRUSH_MY_CRUSH_ERROR";
export const MOY_CRUSH_REMOVE_MY_CRUSH_REQUEST = "MOY_CRUSH_REMOVE_MY_CRUSH_REQUEST";
export const MOY_CRUSH_REMOVE_MY_CRUSH_SUCCESS = "MOY_CRUSH_REMOVE_MY_CRUSH_SUCCESS";
export const MOY_CRUSH_REMOVE_MY_CRUSH_ERROR = "MOY_CRUSH_REMOVE_MY_CRUSH_ERROR";
export const MOY_CRUSH_CHOOSE_CRUSH_REQUEST = "MOY_CRUSH_CHOOSE_CRUSH_REQUEST";
export const MOY_CRUSH_CHOOSE_CRUSH_SUCCESS = "MOY_CRUSH_CHOOSE_CRUSH_SUCCESS";
export const MOY_CRUSH_CHOOSE_CRUSH_ERROR = "MOY_CRUSH_CHOOSE_CRUSH_ERROR";
export const MOY_CRUSH_CHOOSE_CRUSH_SHOW_MORE = "MOY_CRUSH_CHOOSE_CRUSH_SHOW_MORE";
export const MOY_CRUSH_CHOOSE_CRUSH_SEARCH_REQUEST = "MOY_CRUSH_CHOOSE_CRUSH_SEARCH_REQUEST";
export const MOY_CRUSH_CHOOSE_CRUSH_SEARCH_SUCCESS = "MOY_CRUSH_CHOOSE_CRUSH_SEARCH_SUCCESS";
export const MOY_CRUSH_CHOOSE_CRUSH_SEARCH_ERROR = "MOY_CRUSH_CHOOSE_CRUSH_SEARCH_ERROR";
export const MOY_CRUSH_CHOOSE_CRUSH_FILTER_REQUEST = "MOY_CRUSH_CHOOSE_CRUSH_FILTER_REQUEST";
export const MOY_CRUSH_CHOOSE_CRUSH_FILTER_SUCCESS = "MOY_CRUSH_CHOOSE_CRUSH_FILTER_SUCCESS";
export const MOY_CRUSH_CHOOSE_CRUSH_FILTER_ERROR = "MOY_CRUSH_CHOOSE_CRUSH_FILTER_ERROR";
export const MOY_CRUSH_CHOOSE_CRUSH_CHECKED = "MOY_CRUSH_CHOOSE_CRUSH_CHECKED";
export const MOY_CRUSH_CHOOSE_CRUSH_TABS = "MOY_CRUSH_CHOOSE_CRUSH_TABS";
export const MOY_CRUSH_SET_CHOOSE_CRUSH_REQUEST = "MOY_CRUSH_SET_CHOOSE_CRUSH_REQUEST";
export const MOY_CRUSH_SET_CHOOSE_CRUSH_SUCCESS = "MOY_CRUSH_SET_CHOOSE_CRUSH_SUCCESS";
export const MOY_CRUSH_SET_CHOOSE_CRUSH_ERROR = "MOY_CRUSH_SET_CHOOSE_CRUSH_ERROR";

// fan
export const FAN_REQUEST = "FAN_REQUEST";
export const FAN_SUCCESS = "FAN_SUCCESS";
export const FAN_PROGRESS = "FAN_PROGRESS";
export const FAN_TABS = "FAN_TABS";
export const FAN_SET_PERIOD = "FAN_SET_PERIOD";
export const FAN_ERROR = "FAN_ERROR";
export const FAN__TOP_ALL_REQUEST = "FAN__TOP_ALL_REQUEST";
export const FAN__TOP_ALL_PROGRESS = "FAN__TOP_ALL_PROGRESS";
export const FAN__TOP_ALL_SUCCESS = "FAN__TOP_ALL_SUCCESS";
export const FAN__TOP_ALL_ERROR = "FAN__TOP_ALL_ERROR";
export const FAN__TOP_ALL_TABS = "FAN__TOP_ALL_TABS";
export const FAN__TOP_ALL_SHOW_MORE = "FAN__TOP_ALL_SHOW_MORE";
export const FAN__TOP_ALL_SEARCH_REQUEST = "FAN__TOP_ALL_SEARCH_REQUEST";
export const FAN__TOP_ALL_SEARCH_SUCCESS = "FAN__TOP_ALL_SEARCH_SUCCESS";
export const FAN__TOP_ALL_SEARCH_ERROR = "FAN__TOP_ALL_SEARCH_ERROR";
export const FAN__TOP_ALL_FILTER_REQUEST = "FAN__TOP_ALL_FILTER_REQUEST";
export const FAN__TOP_ALL_FILTER_SUCCESS = "FAN__TOP_ALL_FILTER_SUCCESS";
export const FAN__TOP_ALL_FILTER_ERROR = "FAN__TOP_ALL_FILTER_ERROR";

// time online
export const TIME_ONLINE_TRACKING_USER_REQUEST = "TIME_ONLINE_TRACKING_USER_REQUEST";
export const TIME_ONLINE_TRACKING_USER_SUCCESS = "TIME_ONLINE_TRACKING_USER_SUCCESS";
export const TIME_ONLINE_TRACKING_USER_ERROR = "TIME_ONLINE_TRACKING_USER_ERROR";

export const TIME_ONLINE__TRACKING_USER_INFO_REQUEST = "TIME_ONLINE__TRACKING_USER_INFO_REQUEST";
export const TIME_ONLINE__TRACKING_USER_INFO_SUCCESS = "TIME_ONLINE__TRACKING_USER_INFO_SUCCESS";
export const TIME_ONLINE__TRACKING_USER_INFO_SHOW_MORE =
  "TIME_ONLINE__TRACKING_USER_INFO_SHOW_MORE";
export const TIME_ONLINE__TRACKING_USER_INFO_ERROR = "TIME_ONLINE__TRACKING_USER_INFO_ERROR";
export const TIME_ONLINE__TRACKING_USER_INFO_SET_PERIOD =
  "TIME_ONLINE__TRACKING_USER_INFO_SET_PERIOD";
export const TIME_ONLINE__SET_TRACKING_USER_INFO_REQUEST =
  "TIME_ONLINE__SET_TRACKING_USER_INFO_REQUEST";
export const TIME_ONLINE__SET_TRACKING_USER_INFO_SUCCESS =
  "TIME_ONLINE__SET_TRACKING_USER_INFO_SUCCESS";
export const TIME_ONLINE__SET_TRACKING_USER_INFO_ERROR =
  "TIME_ONLINE__SET_TRACKING_USER_INFO_ERROR";

export const TIME_ONLINE__START_TRACKING_REQUEST = "TIME_ONLINE__START_TRACKING_REQUEST";
export const TIME_ONLINE__START_TRACKING_SUCCESS = "TIME_ONLINE__START_TRACKING_SUCCESS";
export const TIME_ONLINE__START_TRACKING_ERROR = "TIME_ONLINE__START_TRACKING_ERROR";
export const TIME_ONLINE__START_TRACKING_SHOW_MORE = "TIME_ONLINE__START_TRACKING_SHOW_MORE";
export const TIME_ONLINE__START_TRACKING_SEARCH_REQUEST =
  "TIME_ONLINE__START_TRACKING_SEARCH_REQUEST";
export const TIME_ONLINE__START_TRACKING_SEARCH_SUCCESS =
  "TIME_ONLINE__START_TRACKING_SEARCH_SUCCESS";
export const TIME_ONLINE__START_TRACKING_SEARCH_ERROR = "TIME_ONLINE__START_TRACKING_SEARCH_ERROR";
export const TIME_ONLINE__START_TRACKING_FILTER_REQUEST =
  "TIME_ONLINE__START_TRACKING_FILTER_REQUEST";
export const TIME_ONLINE__START_TRACKING_FILTER_SUCCESS =
  "TIME_ONLINE__START_TRACKING_FILTER_SUCCESS";
export const TIME_ONLINE__START_TRACKING_FILTER_ERROR = "TIME_ONLINE__START_TRACKING_FILTER_ERROR";
export const TIME_ONLINE__START_TRACKING_CHECKED = "TIME_ONLINE__START_TRACKING_CHECKED";

export const TIME_ONLINE__SET_TRACKING_REQUEST = "TIME_ONLINE__SET_TRACKING_REQUEST";
export const TIME_ONLINE__SET_TRACKING_SUCCESS = "TIME_ONLINE__SET_TRACKING_SUCCESS";
export const TIME_ONLINE__SET_TRACKING_ERROR = "TIME_ONLINE__SET_TRACKING_ERROR";

export const TIME_ONLINE__COMPARE_TIME_REQUEST = "TIME_ONLINE__COMPARE_TIME_REQUEST";
export const TIME_ONLINE__COMPARE_TIME_SUCCESS = "TIME_ONLINE__COMPARE_TIME_SUCCESS";
export const TIME_ONLINE__COMPARE_TIME_ERROR = "TIME_ONLINE__COMPARE_TIME_ERROR";
export const TIME_ONLINE__COMPARE_TIME_SHOW_MORE = "TIME_ONLINE__COMPARE_TIME_SHOW_MORE";
export const TIME_ONLINE__COMPARE_TIME_INFO_REQUEST = "TIME_ONLINE__COMPARE_TIME_INFO_REQUEST";
export const TIME_ONLINE__COMPARE_TIME_INFO_SUCCESS = "TIME_ONLINE__COMPARE_TIME_INFO_SUCCESS";
export const TIME_ONLINE__COMPARE_TIME_INFO_ERROR = "TIME_ONLINE__COMPARE_TIME_INFO_ERROR";
export const TIME_ONLINE__COMPARE_TIME_INFO_SHOW_MORE = "TIME_ONLINE__COMPARE_TIME_INFO_SHOW_MORE";
export const TIME_ONLINE__COMPARE_TIME_INFO_SET_PERIOD =
  "TIME_ONLINE__COMPARE_TIME_INFO_SET_PERIOD";

// trap
export const TRAP__CONVERT_LINK_REQUEST = "TRAP__CONVERT_LINK_REQUEST";
export const TRAP__CONVERT_LINK_SUCCESS = "TRAP__CONVERT_LINK_SUCCESS";
export const TRAP__CONVERT_LINK_ERROR = "TRAP__CONVERT_LINK_ERROR";
export const TRAP__CONVERT_SELF_LINK_REQUEST = "TRAP__CONVERT_SELF_LINK_REQUEST";
export const TRAP__CONVERT_SELF_LINK_SUCCESS = "TRAP__CONVERT_SELF_LINK_SUCCESS";
export const TRAP__CONVERT_SELF_LINK_ERROR = "TRAP__CONVERT_SELF_LINK_ERROR";
export const TRAP__WHO_CROSSED_OVER_REQUEST = "TRAP__WHO_CROSSED_OVER_REQUEST";
export const TRAP__WHO_CROSSED_OVER_SUCCESS = "TRAP__WHO_CROSSED_OVER_SUCCESS";
export const TRAP__WHO_CROSSED_OVER_ERROR = "TRAP__WHO_CROSSED_OVER_ERROR";

// whoNotOnline
export const WHO_NOT_ONLINE_REQUEST = "WHO_NOT_ONLINE_REQUEST";
export const WHO_NOT_ONLINE_SUCCESS = "WHO_NOT_ONLINE_SUCCESS";
export const WHO_NOT_ONLINE_ERROR = "WHO_NOT_ONLINE_ERROR";
export const WHO_NOT_ONLINE_SHOW_MORE = "WHO_NOT_ONLINE_SHOW_MORE";
export const WHO_NOT_ONLINE_SEARCH_REQUEST = "WHO_NOT_ONLINE_SEARCH_REQUEST";
export const WHO_NOT_ONLINE_SEARCH_SUCCESS = "WHO_NOT_ONLINE_SEARCH_SUCCESS";
export const WHO_NOT_ONLINE_SEARCH_ERROR = "WHO_NOT_ONLINE_SEARCH_ERROR";
export const WHO_NOT_ONLINE_FILTER_REQUEST = "WHO_NOT_ONLINE_FILTER_REQUEST";
export const WHO_NOT_ONLINE_FILTER_SUCCESS = "WHO_NOT_ONLINE_FILTER_SUCCESS";
export const WHO_NOT_ONLINE_FILTER_ERROR = "WHO_NOT_ONLINE_FILTER_ERROR";

// mention
export const MENTION_REQUEST = "MENTION_REQUEST";
export const MENTION_SUCCESS = "MENTION_SUCCESS";
export const MENTION_ERROR = "MENTION_ERROR";
export const MENTION_SEARCH_REQUEST = "MENTION_SEARCH_REQUEST";
export const MENTION_SEARCH_SUCCESS = "MENTION_SEARCH_SUCCESS";
export const MENTION_SEARCH_ERROR = "MENTION_SEARCH_ERROR";
export const MENTION_FILTER_REQUEST = "MENTION_FILTER_REQUEST";
export const MENTION_FILTER_SUCCESS = "MENTION_FILTER_SUCCESS";
export const MENTION_FILTER_ERROR = "MENTION_FILTER_ERROR";
export const MENTION_SHOW_MORE = "MENTION_SHOW_MORE";

export const MENTION__INFO_REQUEST = "MENTION__INFO_REQUEST";
export const MENTION__INFO_SUCCESS = "MENTION__INFO_SUCCESS";
export const MENTION__INFO_ERROR = "MENTION__INFO_ERROR";
export const MENTION__INFO_LOAD_MORE_REQUEST = "MENTION__INFO_LOAD_MORE_REQUEST";
export const MENTION__INFO_LOAD_MORE_SUCCESS = "MENTION__INFO_LOAD_MORE_SUCCESS";
export const MENTION__INFO_LOAD_MORE_ERROR = "MENTION__INFO_LOAD_MORE_ERROR";
export const MENTION__INFO_SET_PERIOD = "MENTION__INFO_SET_PERIOD";

// marital
export const MARITAL_REQUEST = "MARITAL_REQUEST";
export const MARITAL_SUCCESS = "MARITAL_SUCCESS";
export const MARITAL_ERROR = "MARITAL_ERROR";
export const MARITAL_SEARCH_REQUEST = "MARITAL_SEARCH_REQUEST";
export const MARITAL_SEARCH_SUCCESS = "MARITAL_SEARCH_SUCCESS";
export const MARITAL_SEARCH_ERROR = "MARITAL_SEARCH_ERROR";
export const MARITAL_FILTER_REQUEST = "MARITAL_FILTER_REQUEST";
export const MARITAL_FILTER_SUCCESS = "MARITAL_FILTER_SUCCESS";
export const MARITAL_FILTER_ERROR = "MARITAL_FILTER_ERROR";
export const MARITAL_SHOW_MORE = "MARITAL_SHOW_MORE";

export const MARITAL__INFO_REQUEST = "MARITAL__INFO_REQUEST";
export const MARITAL__INFO_SUCCESS = "MARITAL__INFO_SUCCESS";
export const MARITAL__INFO_ERROR = "MARITAL__INFO_ERROR";

export const MARITAL_SET_RELATION_BACKGROUND = "MARITAL_SET_RELATION_BACKGROUND";
export const MARITAL__GEO_SETTINGS_REQUEST = "MARITAL__GEO_SETTINGS_REQUEST";
export const MARITAL__GEO_SETTINGS_SUCCESS = "MARITAL__GEO_SETTINGS_SUCCESS";
export const MARITAL__GEO_SETTINGS_ERROR = "MARITAL__GEO_SETTINGS_ERROR";
export const MARITAL__GEO_SEARCH_REQUEST = "MARITAL__GEO_SEARCH_REQUEST";
export const MARITAL__GEO_SEARCH_SUCCESS = "MARITAL__GEO_SEARCH_SUCCESS";
export const MARITAL__GEO_SEARCH_ERROR = "MARITAL__GEO_SEARCH_ERROR";
export const MARITAL__GEO_SEARCH_SHOW_MORE = "MARITAL__GEO_SEARCH_SHOW_MORE";

// New friends
export const NEW_FRIENDS_REQUEST = "NEW_FRIENDS_REQUEST";
export const NEW_FRIENDS_SUCCESS = "NEW_FRIENDS_SUCCESS";
export const NEW_FRIENDS_ERROR = "NEW_FRIENDS_ERROR";
export const NEW_FRIENDS_SEARCH_REQUEST = "NEW_FRIENDS_SEARCH_REQUEST";
export const NEW_FRIENDS_SEARCH_SUCCESS = "NEW_FRIENDS_SEARCH_SUCCESS";
export const NEW_FRIENDS_SEARCH_ERROR = "NEW_FRIENDS_SEARCH_ERROR";
export const NEW_FRIENDS_FILTER_REQUEST = "NEW_FRIENDS_FILTER_REQUEST";
export const NEW_FRIENDS_FILTER_SUCCESS = "NEW_FRIENDS_FILTER_SUCCESS";
export const NEW_FRIENDS_FILTER_ERROR = "NEW_FRIENDS_FILTER_ERROR";
export const NEW_FRIENDS_SHOW_MORE = "NEW_FRIENDS_SHOW_MORE";

export const NEW_FRIENDS__INFO_REQUEST = "NEW_FRIENDS__INFO_REQUEST";
export const NEW_FRIENDS__INFO_SUCCESS = "NEW_FRIENDS__INFO_SUCCESS";
export const NEW_FRIENDS__INFO_ERROR = "NEW_FRIENDS__INFO_ERROR";
export const NEW_FRIENDS__INFO_SET_PERIOD = "NEW_FRIENDS__INFO_SET_PERIOD";

// deleted friends
export const DELETED_FRIENDS_REQUEST = "DELETED_FRIENDS_REQUEST";
export const DELETED_FRIENDS_SUCCESS = "DELETED_FRIENDS_SUCCESS";
export const DELETED_FRIENDS_ERROR = "DELETED_FRIENDS_ERROR";
export const DELETED_FRIENDS_SEARCH_REQUEST = "DELETED_FRIENDS_SEARCH_REQUEST";
export const DELETED_FRIENDS_SEARCH_SUCCESS = "DELETED_FRIENDS_SEARCH_SUCCESS";
export const DELETED_FRIENDS_SEARCH_ERROR = "DELETED_FRIENDS_SEARCH_ERROR";
export const DELETED_FRIENDS_FILTER_REQUEST = "DELETED_FRIENDS_FILTER_REQUEST";
export const DELETED_FRIENDS_FILTER_SUCCESS = "DELETED_FRIENDS_FILTER_SUCCESS";
export const DELETED_FRIENDS_FILTER_ERROR = "DELETED_FRIENDS_FILTER_ERROR";
export const DELETED_FRIENDS_SHOW_MORE = "DELETED_FRIENDS_SHOW_MORE";

// friendsInApp
export const FRIENDS_IN_APP_POPULAR_REQUEST = "FRIENDS_IN_APP_POPULAR_REQUEST";
export const FRIENDS_IN_APP_POPULAR_PROGRESS = "FRIENDS_IN_APP_POPULAR_PROGRESS";
export const FRIENDS_IN_APP_POPULAR_CANCEL = "FRIENDS_IN_APP_POPULAR_CANCEL";
export const FRIENDS_IN_APP_POPULAR_SHOW_MORE = "FRIENDS_IN_APP_POPULAR_SHOW_MORE";
export const FRIENDS_IN_APP_POPULAR_SUCCESS = "FRIENDS_IN_APP_POPULAR_SUCCESS";
export const FRIENDS_IN_APP_POPULAR_ERROR = "FRIENDS_IN_APP_POPULAR_ERROR";
export const FRIENDS_IN_APP__INFO_REQUEST = "FRIENDS_IN_APP__INFO_REQUEST";
export const FRIENDS_IN_APP__INFO_SHOW_MORE = "FRIENDS_IN_APP__INFO_SHOW_MORE";
export const FRIENDS_IN_APP__INFO_PROGRESS = "FRIENDS_IN_APP__INFO_PROGRESS";
export const FRIENDS_IN_APP__INFO_CANCEL = "FRIENDS_IN_APP__INFO_CANCEL";
export const FRIENDS_IN_APP__INFO_SUCCESS = "FRIENDS_IN_APP__INFO_SUCCESS";
export const FRIENDS_IN_APP__INFO_ERROR = "FRIENDS_IN_APP__INFO_ERROR";
export const FRIENDS_IN_APP__APPS_FROM_FRIENDS_REQUEST =
  "FRIENDS_IN_APP__APPS_FROM_FRIENDS_REQUEST";
export const FRIENDS_IN_APP__APPS_FROM_FRIENDS_PROGRESS =
  "FRIENDS_IN_APP__APPS_FROM_FRIENDS_PROGRESS";
export const FRIENDS_IN_APP__APPS_FROM_FRIENDS_CANCEL = "FRIENDS_IN_APP__APPS_FROM_FRIENDS_CANCEL";
export const FRIENDS_IN_APP__APPS_FROM_FRIENDS_SHOW_MORE =
  "FRIENDS_IN_APP__APPS_FROM_FRIENDS_SHOW_MORE";
export const FRIENDS_IN_APP__APPS_FROM_FRIENDS_SUCCESS =
  "FRIENDS_IN_APP__APPS_FROM_FRIENDS_SUCCESS";
export const FRIENDS_IN_APP__APPS_FROM_FRIENDS_ERROR = "FRIENDS_IN_APP__APPS_FROM_FRIENDS_ERROR";
export const FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_REQUEST =
  "FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_REQUEST";
export const FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_PROGRESS =
  "FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_PROGRESS";
export const FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_CANCEL =
  "FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_CANCEL";
export const FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_SHOW_MORE =
  "FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_SHOW_MORE";
export const FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_SUCCESS =
  "FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_SUCCESS";
export const FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_ERROR =
  "FRIENDS_IN_APP__APPS_FROM_FRIENDS_INFO_ERROR";

// subscribersFilter
export const SUBSCRIBERS_FILTER_REQUEST = "SUBSCRIBERS_FILTER_REQUEST";
export const SUBSCRIBERS_FILTER_SUCCESS = "SUBSCRIBERS_FILTER_SUCCESS";
export const SUBSCRIBERS_FILTER_ERROR = "SUBSCRIBERS_FILTER_ERROR";
export const SUBSCRIBERS_FILTER_SHOW_MORE = "SUBSCRIBERS_FILTER_SHOW_MORE";
export const SUBSCRIBERS_SET_FILTER = "SUBSCRIBERS_SET_FILTER";

// commonFriends
export const COMMON_FRIENDS_REQUEST = "COMMON_FRIENDS_REQUEST";
export const COMMON_FRIENDS_SUCCESS = "COMMON_FRIENDS_SUCCESS";
export const COMMON_FRIENDS_ERROR = "COMMON_FRIENDS_ERROR";
export const COMMON_FRIENDS_CANCEL = "COMMON_FRIENDS_CANCEL";
export const COMMON_FRIENDS_SHOW_MORE = "COMMON_FRIENDS_SHOW_MORE";
export const COMMON_FRIENDS_SEARCH_REQUEST = "COMMON_FRIENDS_SEARCH_REQUEST";
export const COMMON_FRIENDS_SEARCH_SUCCESS = "COMMON_FRIENDS_SEARCH_SUCCESS";
export const COMMON_FRIENDS_SEARCH_ERROR = "COMMON_FRIENDS_SEARCH_ERROR";
export const COMMON_FRIENDS_FILTER_REQUEST = "COMMON_FRIENDS_FILTER_REQUEST";
export const COMMON_FRIENDS_FILTER_SUCCESS = "COMMON_FRIENDS_FILTER_SUCCESS";
export const COMMON_FRIENDS_FILTER_ERROR = "COMMON_FRIENDS_FILTER_ERROR";

// commonCommunity
export const COMMON_COMMUNITY_REQUEST = "COMMON_COMMUNITY_REQUEST";
export const COMMON_COMMUNITY_SUCCESS = "COMMON_COMMUNITY_SUCCESS";
export const COMMON_COMMUNITY_ERROR = "COMMON_COMMUNITY_ERROR";
export const COMMON_COMMUNITY_CANCEL = "COMMON_COMMUNITY_CANCEL";
export const COMMON_COMMUNITY_SHOW_MORE = "COMMON_COMMUNITY_SHOW_MORE";
export const COMMON_COMMUNITY_SEARCH_REQUEST = "COMMON_COMMUNITY_SEARCH_REQUEST";
export const COMMON_COMMUNITY_SEARCH_SUCCESS = "COMMON_COMMUNITY_SEARCH_SUCCESS";
export const COMMON_COMMUNITY_SEARCH_ERROR = "COMMON_COMMUNITY_SEARCH_ERROR";
export const COMMON_COMMUNITY_FILTER_REQUEST = "COMMON_COMMUNITY_FILTER_REQUEST";
export const COMMON_COMMUNITY_FILTER_SUCCESS = "COMMON_COMMUNITY_FILTER_SUCCESS";
export const COMMON_COMMUNITY_FILTER_ERROR = "COMMON_COMMUNITY_FILTER_ERROR";

// posting
export const POSTING_REQUEST = "POSTING_REQUEST";
export const POSTING_SUCCESS = "POSTING_SUCCESS";
export const POSTING_ERROR = "POSTING_ERROR";
export const POSTING_SHOW_MORE = "POSTING_SHOW_MORE";
export const POSTING_SEARCH_REQUEST = "POSTING_SEARCH_REQUEST";
export const POSTING_SEARCH_SUCCESS = "POSTING_SEARCH_SUCCESS";
export const POSTING_SEARCH_ERROR = "POSTING_SEARCH_ERROR";
export const POSTING_FILTER_REQUEST = "POSTING_FILTER_REQUEST";
export const POSTING_FILTER_SUCCESS = "POSTING_FILTER_SUCCESS";
export const POSTING_FILTER_ERROR = "POSTING_FILTER_ERROR";
export const POSTING__INFO_REQUEST = "POSTING__INFO_REQUEST";
export const POSTING__INFO_SUCCESS = "POSTING__INFO_SUCCESS";
export const POSTING__INFO_ERROR = "POSTING__INFO_ERROR";
export const POSTING__INFO_LOAD_MORE_REQUEST = "POSTING__INFO_LOAD_MORE_REQUEST";
export const POSTING__INFO_LOAD_MORE_SUCCESS = "POSTING__INFO_LOAD_MORE_SUCCESS";
export const POSTING__INFO_LOAD_MORE_ERROR = "POSTING__INFO_LOAD_MORE_ERROR";
export const POSTING__INFO_SET_PERIOD = "POSTING__INFO_SET_PERIOD";

// pair
export const PAIR_REQUEST = "PAIR_REQUEST";
export const PAIR_SUCCESS = "PAIR_SUCCESS";
export const PAIR_ERROR = "PAIR_ERROR";
export const PAIR_SHOW_MORE = "PAIR_SHOW_MORE";
export const PAIR_SEARCH_REQUEST = "PAIR_SEARCH_REQUEST";
export const PAIR_SEARCH_SUCCESS = "PAIR_SEARCH_SUCCESS";
export const PAIR_SEARCH_ERROR = "PAIR_SEARCH_ERROR";
export const PAIR_FILTER_REQUEST = "PAIR_FILTER_REQUEST";
export const PAIR_FILTER_SUCCESS = "PAIR_FILTER_SUCCESS";
export const PAIR_FILTER_ERROR = "PAIR_FILTER_ERROR";
export const PAIR__INFO_REQUEST = "PAIR__INFO_REQUEST";
export const PAIR__INFO_SUCCESS = "PAIR__INFO_SUCCESS";
export const PAIR__INFO_ERROR = "PAIR__INFO_ERROR";

// popular
export const TOOL_LIST_POPULAR_REQUEST = "TOOL_LIST_POPULAR_REQUEST";
export const TOOL_LIST_POPULAR_SUCCESS = "TOOL_LIST_POPULAR_SUCCESS";
export const TOOL_LIST_POPULAR_ERROR = "TOOL_LIST_POPULAR_ERROR";

// roulette
export const ROULETTE_GIFT_REQUEST = "ROULETTE_GIFT_REQUEST";
export const ROULETTE_GIFT_SUCCESS = "ROULETTE_GIFT_SUCCESS";
export const ROULETTE_GIFT_ERROR = "ROULETTE_GIFT_ERROR";

// download history
export const DOWNLOAD_HISTORY_REQUEST = "DOWNLOAD_HISTORY_REQUEST";
export const DOWNLOAD_HISTORY_SUCCESS = "DOWNLOAD_HISTORY_SUCCESS";
export const DOWNLOAD_HISTORY_ERROR = "DOWNLOAD_HISTORY_ERROR";
export const DOWNLOAD_HISTORY_SEARCH_REQUEST = "DOWNLOAD_HISTORY_SEARCH_REQUEST";
export const DOWNLOAD_HISTORY_SEARCH_SUCCESS = "DOWNLOAD_HISTORY_SEARCH_SUCCESS";
export const DOWNLOAD_HISTORY_SEARCH_ERROR = "DOWNLOAD_HISTORY_SEARCH_ERROR";

export const DOWNLOAD_HISTORY__INFO_REQUEST = "DOWNLOAD_HISTORY__INFO_REQUEST";
export const DOWNLOAD_HISTORY__INFO_SUCCESS = "DOWNLOAD_HISTORY__INFO_SUCCESS";
export const DOWNLOAD_HISTORY__INFO_ERROR = "DOWNLOAD_HISTORY__INFO_ERROR";

// history statistics
export const HISTORY_STATISTICS_REQUEST = "HISTORY_STATISTICS_REQUEST";
export const HISTORY_STATISTICS_SUCCESS = "HISTORY_STATISTICS_SUCCESS";
export const HISTORY_STATISTICS_ERROR = "HISTORY_STATISTICS_ERROR";

export const HISTORY_STATISTICS__INFO_REQUEST = "HISTORY_STATISTICS__INFO_REQUEST";
export const HISTORY_STATISTICS__INFO_PROGRESS = "HISTORY_STATISTICS__INFO_PROGRESS";
export const HISTORY_STATISTICS__INFO_SUCCESS = "HISTORY_STATISTICS__INFO_SUCCESS";
export const HISTORY_STATISTICS__INFO_ERROR = "HISTORY_STATISTICS__INFO_ERROR";
export const HISTORY_STATISTICS__INFO_CANCEL = "HISTORY_STATISTICS__INFO_CANCEL";
export const HISTORY_STATISTICS__INFO_SAVE_CACHE = "HISTORY_STATISTICS__INFO_SAVE_CACHE";
export const HISTORY_STATISTICS__INFO_SET_PERIOD = "HISTORY_STATISTICS__INFO_SET_PERIOD";
export const HISTORY_STATISTICS__INFO_TABS = "HISTORY_STATISTICS__INFO_TABS";
export const HISTORY_STATISTICS__INFO_TOP_SPECTATOR_READY =
  "HISTORY_STATISTICS__INFO_TOP_SPECTATOR_READY";
export const HISTORY_STATISTICS__INFO_TOP_SPECTATOR_TABS =
  "HISTORY_STATISTICS__INFO_TOP_SPECTATOR_TABS";

// popular community
export const POPULAR_COMMUNITY_REQUEST = "POPULAR_COMMUNITY_REQUEST";
export const POPULAR_COMMUNITY_SUCCESS = "POPULAR_COMMUNITY_SUCCESS";
export const POPULAR_COMMUNITY_ERROR = "POPULAR_COMMUNITY_ERROR";
export const POPULAR_COMMUNITY_CANCEL = "POPULAR_COMMUNITY_CANCEL";
export const POPULAR_COMMUNITY_SHOW_MORE = "POPULAR_COMMUNITY_SHOW_MORE";

// whoListenFriends
export const WHO_LISTEN_FRIENDS_POPULAR_AUDIO_REQUEST = "WHO_LISTEN_FRIENDS_POPULAR_AUDIO_REQUEST";
export const WHO_LISTEN_FRIENDS_POPULAR_AUDIO_SUCCESS = "WHO_LISTEN_FRIENDS_POPULAR_AUDIO_SUCCESS";
export const WHO_LISTEN_FRIENDS_POPULAR_AUDIO_ERROR = "WHO_LISTEN_FRIENDS_POPULAR_AUDIO_ERROR";
export const WHO_LISTEN_FRIENDS_POPULAR_AUDIO_SHOW_MORE =
  "WHO_LISTEN_FRIENDS_POPULAR_AUDIO_SHOW_MORE";
export const WHO_LISTEN_FRIENDS_POPULAR_AUDIO_INFO_REQUEST =
  "WHO_LISTEN_FRIENDS_POPULAR_AUDIO_INFO_REQUEST";
export const WHO_LISTEN_FRIENDS_POPULAR_AUDIO_INFO_SUCCESS =
  "WHO_LISTEN_FRIENDS_POPULAR_AUDIO_INFO_SUCCESS";
export const WHO_LISTEN_FRIENDS_POPULAR_AUDIO_INFO_ERROR =
  "WHO_LISTEN_FRIENDS_POPULAR_AUDIO_INFO_ERROR";
export const WHO_LISTEN_FRIENDS_COMMON_AUDIO_REQUEST = "WHO_LISTEN_FRIENDS_COMMON_AUDIO_REQUEST";
export const WHO_LISTEN_FRIENDS_COMMON_AUDIO_SUCCESS = "WHO_LISTEN_FRIENDS_COMMON_AUDIO_SUCCESS";
export const WHO_LISTEN_FRIENDS_COMMON_AUDIO_ERROR = "WHO_LISTEN_FRIENDS_COMMON_AUDIO_ERROR";
export const WHO_LISTEN_FRIENDS_COMMON_AUDIO_SHOW_MORE =
  "WHO_LISTEN_FRIENDS_COMMON_AUDIO_SHOW_MORE";
export const WHO_LISTEN_FRIENDS_COMMON_AUDIO_SEARCH_REQUEST =
  "WHO_LISTEN_FRIENDS_COMMON_AUDIO_SEARCH_REQUEST";
export const WHO_LISTEN_FRIENDS_COMMON_AUDIO_SEARCH_SUCCESS =
  "WHO_LISTEN_FRIENDS_COMMON_AUDIO_SEARCH_SUCCESS";
export const WHO_LISTEN_FRIENDS_COMMON_AUDIO_SEARCH_ERROR =
  "WHO_LISTEN_FRIENDS_COMMON_AUDIO_SEARCH_ERROR";
export const WHO_LISTEN_FRIENDS_COMMON_AUDIO_FILTER_REQUEST =
  "WHO_LISTEN_FRIENDS_COMMON_AUDIO_FILTER_REQUEST";
export const WHO_LISTEN_FRIENDS_COMMON_AUDIO_FILTER_SUCCESS =
  "WHO_LISTEN_FRIENDS_COMMON_AUDIO_FILTER_SUCCESS";
export const WHO_LISTEN_FRIENDS_COMMON_AUDIO_FILTER_ERROR =
  "WHO_LISTEN_FRIENDS_COMMON_AUDIO_FILTER_ERROR";
export const WHO_LISTEN_FRIENDS_COMMON_AUDIO_FOR_ME_REQUEST =
  "WHO_LISTEN_FRIENDS_COMMON_AUDIO_FOR_ME_REQUEST";
export const WHO_LISTEN_FRIENDS_COMMON_AUDIO_FOR_ME_SUCCESS =
  "WHO_LISTEN_FRIENDS_COMMON_AUDIO_FOR_ME_SUCCESS";
export const WHO_LISTEN_FRIENDS_COMMON_AUDIO_FOR_ME_ERROR =
  "WHO_LISTEN_FRIENDS_COMMON_AUDIO_FOR_ME_ERROR";
export const WHO_LISTEN_FRIENDS_COMMON_AUDIO_FOR_ME_SHOW_MORE =
  "WHO_LISTEN_FRIENDS_COMMON_AUDIO_FOR_ME_SHOW_MORE";
export const WHO_LISTEN_FRIENDS_COMMON_AUDIO_WITH_REQUEST =
  "WHO_LISTEN_FRIENDS_COMMON_AUDIO_WITH_REQUEST";
export const WHO_LISTEN_FRIENDS_COMMON_AUDIO_WITH_SUCCESS =
  "WHO_LISTEN_FRIENDS_COMMON_AUDIO_WITH_SUCCESS";
export const WHO_LISTEN_FRIENDS_COMMON_AUDIO_WITH_ERROR =
  "WHO_LISTEN_FRIENDS_COMMON_AUDIO_WITH_ERROR";
export const WHO_LISTEN_FRIENDS_COMMON_AUDIO_WITH_SHOW_MORE =
  "WHO_LISTEN_FRIENDS_COMMON_AUDIO_WITH_SHOW_MORE";
