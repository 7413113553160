import {
  Icon24AddSquareOutline,
  Icon24CommentOutline,
  Icon24GiftOutline,
  Icon24Recent,
  Icon24Search,
  Icon24UserAddOutline,
  Icon28ListLikeOutline,
} from "@vkontakte/icons";
import { Div } from "@vkontakte/vkui";
import ToolItemV2 from "../ToolItem/ToolItem";
import styles from "./Monitoring.module.scss";
import MaritalStatusIcon from "assets/img/tool/main/maritalStatus.svg";
import {
  PANEL_ACTIVITY,
  PANEL_GIFT,
  PANEL_JOIN_COMMUNITY,
  PANEL_MARTIAL_STATUS,
  PANEL_MENTION,
  PANEL_NEW_FRIENDS,
  PANEL_TIME_ONLINE,
  PANEL_TOTAL_SURVEILLANCE,
} from "views/tool/panels/constPanels";
import { DESKTOP_WEB } from "api/vk/constPlatform";
import { getSearchObject } from "api";

function Monitoring(props) {
  const isDesktop = () => {
    return getSearchObject().vk_platform === DESKTOP_WEB ? true : false;
  };

  return (
    <Div>
      <h2 className={styles.header}>Мониторинг</h2>
      <div className={styles.container}>
        <div className={styles.section}>
          <ToolItemV2
            title={
              <>
                Тотальная<br></br> слежка
              </>
            }
            icon={
              isDesktop() ? (
                <Icon24Search width={48} height={48} fill={"#7845E5"} />
              ) : (
                <Icon24Search width={37} height={37} fill={"#7845E5"} />
              )
            }
            openView={props.openView}
            to={PANEL_TOTAL_SURVEILLANCE}
          ></ToolItemV2>
          <ToolItemV2
            title={<>Время онлайн</>}
            icon={
              isDesktop() ? (
                <Icon24Recent width={48} height={48} fill={"#3191FF"} />
              ) : (
                <Icon24Recent width={37} height={37} fill={"#3191FF"} />
              )
            }
            openView={props.openView}
            to={PANEL_TIME_ONLINE}
          ></ToolItemV2>
          <ToolItemV2
            title={<>Активности</>}
            icon={
              isDesktop() ? (
                <Icon28ListLikeOutline width={48} height={48} fill={"#F83A73"} />
              ) : (
                <Icon28ListLikeOutline width={37} height={37} fill={"#F83A73"} />
              )
            }
            openView={props.openView}
            to={PANEL_ACTIVITY}
          ></ToolItemV2>
          <ToolItemV2
            title={<>Новые друзья</>}
            icon={
              isDesktop() ? (
                <Icon24UserAddOutline width={48} height={48} fill={"#FF6433"} />
              ) : (
                <Icon24UserAddOutline width={37} height={37} fill={"#FF6433"} />
              )
            }
            openView={props.openView}
            to={PANEL_NEW_FRIENDS}
          ></ToolItemV2>
        </div>
        <div className={styles.section}>
          <ToolItemV2
            title={
              <>
                Семейное<br></br> положение
              </>
            }
            icon={
              isDesktop() ? (
                <img src={MaritalStatusIcon} width={48} height={48} alt=""></img>
              ) : (
                <img src={MaritalStatusIcon} width={37} height={37} alt=""></img>
              )
            }
            openView={props.openView}
            to={PANEL_MARTIAL_STATUS}
          ></ToolItemV2>
          <ToolItemV2
            title={
              <>
                Вступления/<br></br> выход
              </>
            }
            icon={
              isDesktop() ? (
                <Icon24AddSquareOutline width={48} height={48} fill={"#19BF15"} />
              ) : (
                <Icon24AddSquareOutline width={37} height={37} fill={"#19BF15"} />
              )
            }
            openView={props.openView}
            to={PANEL_JOIN_COMMUNITY}
          ></ToolItemV2>
          <ToolItemV2
            title={<>Упоминания</>}
            icon={
              isDesktop() ? (
                <Icon24CommentOutline width={48} height={48} fill={"#3B8FF1"} />
              ) : (
                <Icon24CommentOutline width={37} height={37} fill={"#3B8FF1"} />
              )
            }
            openView={props.openView}
            to={PANEL_MENTION}
          ></ToolItemV2>
          <ToolItemV2
            title={<>Подарки</>}
            icon={
              isDesktop() ? (
                <Icon24GiftOutline width={48} height={48} fill={"#ED5CCD"} />
              ) : (
                <Icon24GiftOutline width={37} height={37} fill={"#ED5CCD"} />
              )
            }
            openView={props.openView}
            to={PANEL_GIFT}
          ></ToolItemV2>
        </div>
      </div>
    </Div>
  );
}

export default Monitoring;
