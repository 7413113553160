import React from "react";
import { Group } from "@vkontakte/vkui";
import PSUserItem from "../PSUserItem/PSUserItem";

const PSUserList = (props) => {
  return (
    <div>
      <Group style={{ marginBottom: "20px" }}>
        <PSUserItem
          title={"Стартапы и бизнес"}
          description={"165 000 участников"}
          openView={props.openView}
        />
        <PSUserItem
          title={"Cinemaholics"}
          description={"165 000 участников"}
          openView={props.openView}
        />
        <PSUserItem
          title={"Cinemaholics"}
          description={"165 000 участников"}
          openView={props.openView}
        />
      </Group>
    </div>
  );
};

export default PSUserList;
