import React from "react";
import { Link, SimpleCell } from "@vkontakte/vkui";
import Icon28ArticleOutline from "@vkontakte/icons/dist/28/article_outline";

import { COMMENT, COMMENT_PHOTO, POST } from "../../constComment";

const CommentItem = (props) => {
  const getTypeText = () => {
    switch (props.type) {
      case COMMENT:
        return props.sex === "1" ? "Прокомментировала запись " : "Прокомментировал запись ";
      case COMMENT_PHOTO:
        return props.sex === "1" ? "Прокомментировала фото " : "Прокомментировал фото ";
      case POST:
        return props.sex === "1" ? "Написала пост " : "Написал пост ";
      default:
        return "";
    }
  };

  const getInfoText = () => {
    let replace = /[[](id|public|club|event)([0-9]{1,9})+[|](.+?)[\]]/giu;
    let infoTextResult = null;

    if (props.infoText) {
      infoTextResult = props.infoText.replace(replace, "$3");
    }

    return infoTextResult ? "«" + infoTextResult + "»" : "";
  };

  const getTypeAsideIcon = () => {
    const ImgUrl = (props) => {
      return (
        <Link href={props.infoLink ? props.infoLink : null} target="_blank">
          {props.children}
        </Link>
      );
    };

    switch (props.type) {
      case COMMENT:
      case COMMENT_PHOTO:
      case POST:
        return props.infoPhoto ? (
          <ImgUrl infoLink={props.infoLink}>
            <img src={props.infoPhoto} width={28} height={28} alt=""></img>
          </ImgUrl>
        ) : (
          <ImgUrl infoLink={props.infoLink}>
            <Icon28ArticleOutline fill={"#3F8AE0"} />
          </ImgUrl>
        );
      default:
        return "";
    }
  };

  return (
    <SimpleCell disabled after={getTypeAsideIcon()} description={"в " + props.time}>
      <span style={{ color: "#818C99" }}>{getTypeText()}</span>
      <Link href={props.infoLink ? props.infoLink : null} target="_blank">
        {getInfoText()}
      </Link>
    </SimpleCell>
  );
};

export default CommentItem;
