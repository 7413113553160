// Меню инструментов
export const PANEL_MAIN = "main";
// Поклоники
export const PANEL_FAN = "fan";
export const PANEL_FAN__TOP = "fan_top";
// Тотальная слежка
export const PANEL_TOTAL_SURVEILLANCE = "total_surveillance"; // Тотальная слежка
export const PANEL_TS__START_TRACKING = "total_surveillance_start_tracking"; // Начать отслеживать
export const PANEL_TS__SETTING_NOTIFICATION = "total_surveillance_setting_notification"; // Настройка уведомлений
// Кто скрыл
export const PANEL_WHO_HIDDEN = "who_hidden";
export const PANEL_WH__WHO_HIDDEN_ME = "who_hidden_me";
export const PANEL_WH__WHO_HIDDEN_ANOTHER_USER = "who_hidden_another_user";
export const PANEL_WH__WHO_HIDDEN_ANOTHER_USER_INFO = "who_hidden_another_user_info";
export const PANEL_WH__WHOM_HIDDEN_IM = "whom_hidden_im";
export const PANEL_WH__WHOM_HIDDEN_ANOTHER_USER = "whom_hidden_another_user";
export const PANEL_WH__WHOM_HIDDEN_ANOTHER_USER_INFO = "whom_hidden_another_user_info";
// Кто добавил в ЧС
export const PANEL_WHO_ADDED_CHS = "who_added_chs";
// Дата регистрации
export const PANEL_REGISTRATION_DATE = "registration_date";
// Ловушка
export const PANEL_TRAP = "trap";
export const PANEL_T__CONVERT_LINK = "trap_convert_link";
export const PANEL_T__LINK_DONE = "trap_link_done";
export const PANEL_T__SELF_LINK_DONE = "trap_self_link_done";
// Семейное положение
export const PANEL_MARTIAL_STATUS = "marital_status";
export const PANEL_MS__INFO = "marital_status_info";
export const PANEL_MS__GEO = "marital_status_geo";
export const PANEL_MS__GEO_SETTINGS = "marital_status_geo_settings";
export const PANEL_MS__GEO_SEARCH = "marital_status_geo_search";
// Активности
export const PANEL_ACTIVITY = "activity";
export const PANEL_A__USER = "activity_user"; // Активности пользователя
export const PANEL_A__USER_INFO = "activity_user_info"; // Активности пользователя (подробно)
export const PANEL_A__OTHER_USER = "activity_other_user"; // Активности у пользователя
export const PANEL_A__OTHER_USER_PAGE = "activity_other_user_page"; // Активности у пользователя (активность на странице)
export const PANEL_A__OTHER_USER_ACTIVE = "activity_other_user_active"; // Активности у пользователя (активные пользователи)
export const PANEL_A__OTHER_USER_INFO = "activity_other_user_info"; // Активности у пользователя (подробно)
// Новые друзья
export const PANEL_NEW_FRIENDS = "new_friends";
export const PANEL_NEW_FRIENDS__INFO = "new_friends_info";
// Время онлайн (Когда был онлайн)
export const PANEL_TIME_ONLINE = "time_online";
export const PANEL_TO__TRACKING_USER = "time_online_tracking_user";
export const PANEL_TO__TRACKING_USER_INFO = "time_online_tracking_user_info";
export const PANEL_TO__START_TRACKING = "time_online_start_tracking";
export const PANEL_TO__COMPARE_TIME = "time_online_compare_time";
export const PANEL_TO__COMPARE_TIME_INFO = "time_online_compare_time_info";
// Упоминания
export const PANEL_MENTION = "mention";
export const PANEL_MENTION__INFO = "mention_info";
// Администрируемые (Администрируемые сообщества)
export const PANEL_ADMIN_COMMUNITY = "admin_community";
export const PANEL_AC_INFO = "admin_community_info";
// Вступления (Вступления в сообщества)
export const PANEL_JOIN_COMMUNITY = "join_community";
export const PANEL_JC_INFO = "join_community_info";
// Подарки
export const PANEL_GIFT = "gift";
export const PANEL_GIFT__INFO = "gift_info";
// Пара
export const PANEL_PAIR = "pair";
export const PANEL_PAIR__INFO = "pair_info";
// Что слушают друзья
export const PANEL_WHO_LISTEN_FRIENDS = "who_listen_friends";
export const PANEL_WLF_POPULAR_AUDIO = "who_listen_friends_popular_audio";
export const PANEL_WLF_COMMON_AUDIO = "who_listen_friends_common_audio";
export const PANEL_WLF_COMMON_AUDIO_FOR_ME = "who_listen_friends_common_audio_for_me";
export const PANEL_WLF_COMMON_AUDIO_WITH = "who_listen_friends_common_audio_with";
// Кто давно не был онлайн
export const PANEL_WHO_NOT_ONLINE = "who_not_online";
// Общие сообщества
export const PANEL_COMMON_COMMUNITY = "common_community";
// Общие друзья
export const PANEL_COMMON_FRIENDS = "common_friends";
// Популярные друзья
export const PANEL_POPULAR_FRIENDS = "popular_friends";
// Удаленные
export const PANEL_DELETED_FRIENDS = "deleted_driends";
// Друзья в приложении
export const PANEL_FRIENDS_IN_APP = "friends_in_app";
export const PANEL_FIA__APPS_FROM_FRIENDS = "friends_in_app_apps_from_friends";
export const PANEL_FIA__APPS_FROM_FRIENDS_INFO = "friends_in_app_apps_from_friends_info";
export const PANEL_FIA__INFO = "friends_in_app_info";
export const PANEL_FIA__WHAT_IS_APP_ID = "friends_in_app_what_is_app_id";
// Популярные подписчики
export const PANEL_POPULAR_SUBSCRIBER = "popular_subscriber";
export const PANEL_PS__GROUP_INFO = "popular_subscriber_group_info";
// Фильтр подписчиков
export const PANEL_SUBSCRIBER_FILTER = "subscriber_filter";
export const PANEL_SF__SEARCH_RESULT = "subscriber_filter_search_result";
// Скачивание историй
export const PANEL_DOWNLOAD_HISTORY = "download_history";
export const PANEL_DH__INFO = "download_history_info";
// Статистика историй
export const PANEL_HISTORY_STATISTICS = "history_statistics";
export const PANEL_HS__INFO = "history_statistics_info";
export const PANEL_HS__INFO_TOP_SPECTATOR = "history_statistics_info_top_spectator";
// Популярные сообщества
export const PANEL_POPULAR_COMMUNITY = "popular_community";
// Активность сообществ
export const PANEL_COMMUNITY_ACTIVITY = "community_activity";
// Постинг
export const PANEL_POSTING = "posting";
export const PANEL_POSTING__INFO = "posting_info";
