import React from "react";
import { Group } from "@vkontakte/vkui";
import CAUserItem from "../CAUserItem/CAUserItem";

const CAUserList = (props) => {
  return (
    <div>
      <Group style={{ marginBottom: "20px" }}>
        <CAUserItem title={"Стартапы и бизнес"} description={"последний пост 3 года назад"} />
      </Group>
    </div>
  );
};

export default CAUserList;
