import React from "react";
import { useSelector } from "react-redux";
import { Root } from "@vkontakte/vkui";
import { VIEW_MOY_CRUSH } from "views/constViews";

import MoyCrushView from "views/moycrush";

const MoyCrushStory = (props) => {
  const activeView = useSelector((state) => state.page.activeView);
  const activePopout = useSelector((state) =>
    state.page.popouts[state.page.activeView] === undefined
      ? null
      : state.page.popouts[state.page.activeView]
  );

  return (
    <Root id={props.id} activeView={activeView} popout={activePopout}>
      <MoyCrushView id={VIEW_MOY_CRUSH} />
    </Root>
  );
};

export default MoyCrushStory;
