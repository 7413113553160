import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Panel } from "@vkontakte/vkui";
import PanelHeaderWitchoutPath from "template/components/PanelHeaderWitchoutPath/PanelHeaderWitchoutPath";

import GuestList from "./components/GuestList/GuestList";

import { fetchGuestV2 } from "store/actions/guestV2";
import { renderErrorPlaceholder } from "helpers/views";
import { withRouter } from "hoc";

const MainPanel = (props) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.guestV2.loading);
  const error = useSelector((state) => state.guestV2.error);

  const handleFetchGuestV2 = useCallback(() => dispatch(fetchGuestV2()), [dispatch]);

  useEffect(() => {
    handleFetchGuestV2();
  }, [handleFetchGuestV2]);

  return (
    <Panel id={props.id}>
      <PanelHeaderWitchoutPath>Гости</PanelHeaderWitchoutPath>

      {renderErrorPlaceholder({
        loading: loading,
        error: error,
        isTryAgainHandler: handleFetchGuestV2,
      })}

      {error === null && (
        <GuestList
          openView={props.openView}
          openModal={props.openModal}
          closeModal={props.closeModal}
          openPopout={props.openPopout}
          closePopout={props.closePopout}
        />
      )}
    </Panel>
  );
};

export default withRouter(MainPanel);
