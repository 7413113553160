import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import WhoHiddenAnotherUserInfoItem from "../WhoHiddenAnotherUserInfoItem/WhoHiddenAnotherUserInfoItem";
import WhoHiddenInfo from "../../../components/WHInfo/WHInfo";

import {
  fetchWhoHiddenAnotherUserInfo,
  cancelWhoHiddenAnotherUserInfo,
} from "store/actions/tool/whoHidden";

import { declOfNum } from "helpers";
import LoaderWithProgressBar from "template/components/LoaderWithProgressBar/LoaderWithProgressBar";
import { renderErrorPlaceholder } from "helpers/views";
import HeadTitle from "template/components/HeadTitle/HeadTitle";

const WhoHiddenAnotherUserInfo = (props) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.toolWhoHidden.whoHiddenAnotherUserInfo.loading);
  const error = useSelector((state) => state.toolWhoHidden.whoHiddenAnotherUserInfo.error);
  const hiddenUsers = useSelector((state) =>
    state.toolWhoHidden.whoHiddenAnotherUserInfo.payload
      ? state.toolWhoHidden.whoHiddenAnotherUserInfo.payload
      : null
  );
  const progressValue = useSelector(
    (state) => state.toolWhoHidden.whoHiddenAnotherUserInfo.progressValue
  );
  const handleFetchWhoHiddenAnotherUserInfo = useCallback(
    () => dispatch(fetchWhoHiddenAnotherUserInfo()),
    [dispatch]
  );
  const handleCancelWhoHiddenAnotherUserInfo = useCallback(
    () => dispatch(cancelWhoHiddenAnotherUserInfo()),
    [dispatch]
  );

  useEffect(() => {
    return () => {
      handleCancelWhoHiddenAnotherUserInfo();
    };
  }, [handleCancelWhoHiddenAnotherUserInfo]);

  useEffect(() => {
    handleFetchWhoHiddenAnotherUserInfo();
  }, [handleFetchWhoHiddenAnotherUserInfo]);

  const renderHiddenUserList = () => {
    return hiddenUsers.length > 0
      ? hiddenUsers.map((user, i) => {
          const id = user.vk_id;
          const name = user.name;
          const city = user.city ? user.city : "";
          const age = user.age ? user.age : "";
          const photo = user.photo_url ? user.photo_url : "";

          const ageStrFormat = age ? age + " " + declOfNum(age, ["год", "года", "лет"]) : "";
          const comma = ageStrFormat && city ? ", " : "";

          return (
            <WhoHiddenAnotherUserInfoItem
              key={i}
              id={id}
              title={name}
              description={ageStrFormat + comma + city}
              photo={photo}
              openView={props.openView}
            />
          );
        })
      : "";
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      <HeadTitle>Кто скрыл другого пользователя</HeadTitle>
      {hiddenUsers !== null && error === null ? renderHiddenUserList() : ""}
      {loading ? <LoaderWithProgressBar progressValue={progressValue} /> : ""}
      {hiddenUsers !== null &&
      Object.prototype.toString.call(hiddenUsers) === "[object Array]" &&
      !loading &&
      hiddenUsers.length <= 0 &&
      error === null ? (
        <WhoHiddenInfo description="Скрытые пользователи не найдены" />
      ) : (
        ""
      )}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchWhoHiddenAnotherUserInfo,
      })}
    </div>
  );
};

export default WhoHiddenAnotherUserInfo;
