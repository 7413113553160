import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Group, Panel, SimpleCell } from "@vkontakte/vkui";
import WLFCommonAudioList from "./WLFCommonAudioList/WLFCommonAudioList";
import { VIEW_TOOL } from "../../../../constViews";
import { PANEL_WLF_COMMON_AUDIO_FOR_ME } from "../../constPanels";
import Icon16Chevron from "@vkontakte/icons/dist/16/chevron";
import { Icon28UsersOutline } from "@vkontakte/icons";
import HeadTitle from "template/components/HeadTitle/HeadTitle";
import { withRouter } from "hoc";

const PanelWLFCommonAudio = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Что слушают друзья</PanelHeaderWithPath>
      <Group separator="show">
        <HeadTitle>Общие аудио</HeadTitle>
        <SimpleCell
          before={<Icon28UsersOutline />}
          after={<Icon16Chevron fill="#B8C1CC" />}
          onClick={() => props.openView({ view: VIEW_TOOL, panel: PANEL_WLF_COMMON_AUDIO_FOR_ME })}
        >
          Общие аудио со мной
        </SimpleCell>
      </Group>

      <WLFCommonAudioList
        openView={props.openView}
        openModal={props.openModal}
        closeModal={props.closeModal}
      />
    </Panel>
  );
};

export default withRouter(PanelWLFCommonAudio);
