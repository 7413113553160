import React from "react";

import styles from "./HoroscopeSignItem.module.css";

import AquariusFIcon from "assets/img/profile/horoscope/sign/aquarius-f.svg";
import AriesFIcon from "assets/img/profile/horoscope/sign/aries-f.svg";
import CancerFIcon from "assets/img/profile/horoscope/sign/cancer-f.svg";
import CapricornFIcon from "assets/img/profile/horoscope/sign/capricorn-f.svg";
import GeminiFIcon from "assets/img/profile/horoscope/sign/gemini-f.svg";
import LeoFIcon from "assets/img/profile/horoscope/sign/leo-f.svg";
import LibraFIcon from "assets/img/profile/horoscope/sign/libra-f.svg";
import PiscesFIcon from "assets/img/profile/horoscope/sign/pisces-f.svg";
import SagittariusFIcon from "assets/img/profile/horoscope/sign/sagittarius-f.svg";
import ScorpioFIcon from "assets/img/profile/horoscope/sign/scorpio-f.svg";
import TaurusFIcon from "assets/img/profile/horoscope/sign/taurus-f.svg";
import VirgoFIcon from "assets/img/profile/horoscope/sign/virgo-f.svg";

import AquariusMIcon from "assets/img/profile/horoscope/sign/aquarius-m.svg";
import AriesMIcon from "assets/img/profile/horoscope/sign/aries-m.svg";
import CancerMIcon from "assets/img/profile/horoscope/sign/cancer-m.svg";
import CapricornMIcon from "assets/img/profile/horoscope/sign/capricorn-m.svg";
import GeminiMIcon from "assets/img/profile/horoscope/sign/gemini-m.svg";
import LeoMIcon from "assets/img/profile/horoscope/sign/leo-m.svg";
import LibraMIcon from "assets/img/profile/horoscope/sign/libra-m.svg";
import PiscesMIcon from "assets/img/profile/horoscope/sign/pisces-m.svg";
import SagittariusMIcon from "assets/img/profile/horoscope/sign/sagittarius-m.svg";
import ScorpioMIcon from "assets/img/profile/horoscope/sign/scorpio-m.svg";
import TaurusMIcon from "assets/img/profile/horoscope/sign/taurus-m.svg";
import VirgoMIcon from "assets/img/profile/horoscope/sign/virgo-m.svg";

import {
  AQUARIUS,
  ARIES,
  CANCER,
  CAPRICORN,
  GEMINI,
  LEO,
  LIBRA,
  PISCES,
  SAGITTARIUS,
  SCORPIO,
  TAURUS,
  VIRGO,
} from "../../constSign";

const HoroscopeSignItem = (props) => {
  const getSignTitle = () => {
    switch (props.sign) {
      case AQUARIUS:
        return "Водолей";
      case PISCES:
        return "Рыбы";
      case ARIES:
        return "Овен";
      case TAURUS:
        return "Телец";
      case GEMINI:
        return "Близнецы";
      case CANCER:
        return "Рак";
      case LEO:
        return "Лев";
      case VIRGO:
        return "Дева";
      case LIBRA:
        return "Весы";
      case SCORPIO:
        return "Скорпион";
      case SAGITTARIUS:
        return "Стрелец";
      case CAPRICORN:
        return "Козерог";
      default:
        return "";
    }
  };

  const getSignImg = () => {
    if (props.sex === 1) {
      switch (props.sign) {
        case AQUARIUS:
          return AquariusFIcon;
        case PISCES:
          return PiscesFIcon;
        case ARIES:
          return AriesFIcon;
        case TAURUS:
          return TaurusFIcon;
        case GEMINI:
          return GeminiFIcon;
        case CANCER:
          return CancerFIcon;
        case LEO:
          return LeoFIcon;
        case VIRGO:
          return VirgoFIcon;
        case LIBRA:
          return LibraFIcon;
        case SCORPIO:
          return ScorpioFIcon;
        case SAGITTARIUS:
          return SagittariusFIcon;
        case CAPRICORN:
          return CapricornFIcon;
        default:
          return null;
      }
    } else if (props.sex === 2) {
      switch (props.sign) {
        case AQUARIUS:
          return AquariusMIcon;
        case PISCES:
          return PiscesMIcon;
        case ARIES:
          return AriesMIcon;
        case TAURUS:
          return TaurusMIcon;
        case GEMINI:
          return GeminiMIcon;
        case CANCER:
          return CancerMIcon;
        case LEO:
          return LeoMIcon;
        case VIRGO:
          return VirgoMIcon;
        case LIBRA:
          return LibraMIcon;
        case SCORPIO:
          return ScorpioMIcon;
        case SAGITTARIUS:
          return SagittariusMIcon;
        case CAPRICORN:
          return CapricornMIcon;
        default:
          return null;
      }
    } else {
      return null;
    }
  };

  return (
    <div className={styles.itemContainer}>
      <span className={styles.itemTopText}>{props.title}</span>
      <div className={styles.itemCircle}>
        <img src={getSignImg()} style={{ width: "48px", height: "48px" }} alt=""></img>
      </div>
      <span className={styles.itemBottomText}>{getSignTitle()}</span>
    </div>
  );
};

HoroscopeSignItem.defaultProps = {
  title: "",
  sign: "",
  sex: null,
};

export default HoroscopeSignItem;
