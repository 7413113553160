import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Group, Placeholder } from "@vkontakte/vkui";
import FIAAppsFromFriendsInfoItem from "../FIAAppsFromFriendsInfoItem/FIAAppsFromFriendsInfoItem";
import FIAAppsFromFriendsInfoTopPanel from "../FIAAppsFromFriendsInfoTopPanel/FIAAppsFromFriendsInfoTopPanel";

import {
  fetchAppsFromFriendsInfo,
  showMoreAppsFromFriendsInfo,
  cancelAppsFromFriendsInfo,
} from "store/actions/tool/friendsInApp";
import { declOfNum } from "helpers";
import { renderErrorPlaceholder } from "helpers/views";
import LoaderWithProgressBar from "template/components/LoaderWithProgressBar/LoaderWithProgressBar";
import { useShowMore } from "hooks";
import Icon56ServicesOutline from "@vkontakte/icons/dist/56/services_outline";
import HeadTitle from "template/components/HeadTitle/HeadTitle";

const FIAAppsFromFriendsInfoList = (props) => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const activeUserId = useSelector(
    (state) => state.toolFriendsInApp.appsFromFriendsInfo.activeUserId
  );
  const activeUserTitle = useSelector(
    (state) => state.toolFriendsInApp.appsFromFriendsInfo.activeUserTitle
  );
  const activeUserDescription = useSelector(
    (state) => state.toolFriendsInApp.appsFromFriendsInfo.activeUserDescription
  );
  const activeUserPhoto = useSelector(
    (state) => state.toolFriendsInApp.appsFromFriendsInfo.activeUserPhoto
  );
  const loading = useSelector((state) => state.toolFriendsInApp.appsFromFriendsInfo.loading);
  const error = useSelector((state) => state.toolFriendsInApp.appsFromFriendsInfo.error);
  const showCount = useSelector((state) => state.toolFriendsInApp.appsFromFriendsInfo.showCount);
  const appsFromFriends = useSelector(
    (state) => state.toolFriendsInApp.appsFromFriendsInfo.payload
  );
  const progressValue = useSelector(
    (state) => state.toolFriendsInApp.appsFromFriendsInfo.progressValue
  );

  const handleFetchAppsFromFriendsInfo = useCallback(
    () => dispatch(fetchAppsFromFriendsInfo()),
    [dispatch]
  );
  const handleShowMoreAppsFromFriendsInfo = useCallback(
    () => dispatch(showMoreAppsFromFriendsInfo()),
    [dispatch]
  );
  const handleCancelAppsFromFriendsInfo = useCallback(
    () => dispatch(cancelAppsFromFriendsInfo()),
    [dispatch]
  );

  useShowMore({
    items: appsFromFriends,
    showCount: showCount,
    maxLength: appsFromFriends !== null ? appsFromFriends.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMoreAppsFromFriendsInfo,
  });

  useEffect(() => {
    handleFetchAppsFromFriendsInfo();
  }, [handleFetchAppsFromFriendsInfo]);

  useEffect(() => {
    return () => {
      handleCancelAppsFromFriendsInfo();
    };
  }, [handleCancelAppsFromFriendsInfo]);

  const renderAppsFromFriendsInfo = () => {
    return appsFromFriends.length > 0
      ? appsFromFriends.map((friend, i) => {
          const id = friend.app_id;
          const title = friend.title;
          const photo = friend.photo_url;
          const friendCount = friend.friend_count;

          const friendCountStrFormat = friendCount
            ? friendCount + " " + declOfNum(friendCount, ["друг", "друга", "друзей"])
            : "0 друзей";

          if (i < showCount) {
            return (
              <FIAAppsFromFriendsInfoItem
                key={i}
                id={id}
                title={title}
                photo={photo}
                description={friendCountStrFormat}
                openView={props.openView}
              />
            );
          }

          return false;
        })
      : "";
  };

  return (
    <>
      <HeadTitle>Приложения у друзей</HeadTitle>
      <Group separator="show">
        <FIAAppsFromFriendsInfoTopPanel
          id={activeUserId}
          title={activeUserTitle}
          photo={activeUserPhoto}
          description={activeUserDescription}
          openView={props.openView}
        />
      </Group>
      {loading ? <LoaderWithProgressBar progressValue={progressValue} /> : ""}
      {appsFromFriends !== null && error === null ? (
        <Group style={{ marginBottom: "20px" }}>{renderAppsFromFriendsInfo()}</Group>
      ) : (
        ""
      )}
      {Object.prototype.toString.call(appsFromFriends) === "[object Array]" &&
      !loading &&
      appsFromFriends.length <= 0 &&
      error === null ? (
        <Placeholder icon={<Icon56ServicesOutline />}>Приложения не найдены</Placeholder>
      ) : (
        ""
      )}

      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchAppsFromFriendsInfo,
      })}
    </>
  );
};

export default FIAAppsFromFriendsInfoList;
