import React from "react";
import { Avatar, SimpleCell } from "@vkontakte/vkui";
import Icon16Chevron from "@vkontakte/icons/dist/16/chevron";

const ACInfoItem = (props) => {
  return (
    <SimpleCell
      before={<Avatar size={48} src={props.photo} />}
      after={<Icon16Chevron fill="#B8C1CC" />}
      onClick={() => {
        window.open("https://vk.com/club" + props.id, "_blank");
      }}
      description={props.description}
    >
      {props.title}
    </SimpleCell>
  );
};

export default ACInfoItem;
