import React, { useCallback, useEffect, useState } from "react";
import { Group, Placeholder, Search } from "@vkontakte/vkui";
import Icon24Filter from "@vkontakte/icons/dist/24/filter";
import CCUserItem from "../CCUserItem/CCUserItem";
import { Icon56Users3Outline } from "@vkontakte/icons";
import {
  fetchCommonCommunity,
  filterCommonCommunity,
  searchCommonCommunity,
  showMoreCommonCommunity,
  cancelCommonCommunity,
} from "store/actions/tool/commonCommunity";
import { useDispatch, useSelector } from "react-redux";
import { useDebounceSearch, useShowMore } from "hooks";
import { declOfNum } from "helpers";
import { MODAL_PAGE_TEMPLATE_FILTER_SEX_AGE_CITY } from "template/modals/constModals";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";

const CCUserList = (props) => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const search = useSelector((state) => state.toolCommonCommunity.search);
  const filter = useSelector((state) => state.toolCommonCommunity.filter);
  const showCount = useSelector((state) => state.toolCommonCommunity.showCount);
  const loading = useSelector((state) => state.toolCommonCommunity.loading);
  const error = useSelector((state) => state.toolCommonCommunity.error);
  const groups = useSelector((state) => state.toolCommonCommunity.payload);

  const handleFetchCommonCommunity = useCallback(
    () => dispatch(fetchCommonCommunity()),
    [dispatch]
  );

  const handleSearchCommonCommunity = useCallback(
    (search) => dispatch(searchCommonCommunity(search)),
    [dispatch]
  );
  const handleFilterCommonCommunity = useCallback(
    (filter) => dispatch(filterCommonCommunity(filter)),
    [dispatch]
  );
  const handleShowMoreCommonCommunity = useCallback(
    () => dispatch(showMoreCommonCommunity()),
    [dispatch]
  );
  const handleCancelCommonCommunity = useCallback(
    () => dispatch(cancelCommonCommunity()),
    [dispatch]
  );

  const [searchTerm, setSearchTerm] = useState(search);

  useEffect(() => {
    handleFetchCommonCommunity();
  }, [handleFetchCommonCommunity]);

  useDebounceSearch({
    search: search,
    searchTerm: searchTerm,
    handler: handleSearchCommonCommunity,
  });

  useEffect(() => {
    return () => {
      handleCancelCommonCommunity();
    };
  }, [handleCancelCommonCommunity]);

  useShowMore({
    items: groups,
    showCount: showCount,
    maxLength: groups !== null ? groups.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMoreCommonCommunity,
  });

  const handleFilter = () => {
    if (loading || error !== null) return;

    props.openModal({
      id: MODAL_PAGE_TEMPLATE_FILTER_SEX_AGE_CITY,
      param: {
        filter: filter,
        closeModal: props.closeModal,
        applyFilter: handleFilterCommonCommunity,
      },
    });
  };

  const renderGroupList = () => {
    return groups.length > 0 ? (
      groups.map((group, i) => {
        const id = group.vk_id;
        const name = group.name;
        const photo = group.photo_url;
        const commonCount = group.common_count;
        const commonCountStrFormat =
          commonCount !== 0
            ? commonCount + " " + declOfNum(commonCount, ["общее", "общих", "общих"])
            : "0 общих";

        const commonGroups = group.common_groups;

        if (i < showCount) {
          return (
            <CCUserItem
              key={i}
              id={id}
              title={name}
              photo={photo}
              commonCommunity={commonGroups}
              commonCount={commonCountStrFormat}
              openModal={props.openModal}
            />
          );
        }

        return false;
      })
    ) : (
      <Placeholder icon={<Icon56Users3Outline />}>Сообщества не найдены</Placeholder>
    );
  };

  return (
    <div>
      <Search
        value={searchTerm}
        icon={<Icon24Filter />}
        placeholder={"Имя или ссылка на страницу"}
        onIconClick={handleFilter}
        onChange={(e) => {
          if (loading || error !== null) return;

          setSearchTerm(e.target.value);
        }}
      />
      <Group style={{ marginBottom: "20px" }}>
        {groups !== null && error === null ? renderGroupList() : ""}
      </Group>
      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchCommonCommunity,
      })}
    </div>
  );
};

export default CCUserList;
