import React from "react";
import { useSelector } from "react-redux";
import { ModalPage, ModalPageHeader, Cell, List, Avatar } from "@vkontakte/vkui";

import { declOfNum } from "helpers";

const MutualCommunityModal = (props) => {
  const profile = useSelector((state) => (state.profile.payload ? state.profile.payload : null));
  const mutualGroups = profile ? profile.mutual_groups : [];
  const commonStrFormat = declOfNum(mutualGroups.length, ["общее", "общих", "общих"]);
  const groupStrFormat = declOfNum(mutualGroups.length, ["сообщество", "сообщества", "сообществ"]);

  const renderMutualGroup = () => {
    return mutualGroups.map((group, i) => {
      //const id = group.vk_id;
      const photo = group.photo_url;
      const name = group.name;

      return (
        <Cell key={i} before={<Avatar size={40} src={photo} />}>
          {name}
        </Cell>
      );
    });
  };

  return (
    <ModalPage
      id={props.id}
      header={
        <ModalPageHeader>
          <span
            style={{
              fontWeight: "500",
              fontSize: "13px",
              lineHeight: "16px",
              letterSpacing: "0.2px",
              textTransform: "uppercase",
              color: "#818C99",
            }}
          >
            {mutualGroups.length + " " + commonStrFormat + " " + groupStrFormat}
          </span>
        </ModalPageHeader>
      }
      settlingHeight={80}
    >
      <List>{profile ? renderMutualGroup() : ""}</List>
    </ModalPage>
  );
};

export default MutualCommunityModal;
