import { queryEye3 } from "api";

async function APIEye3ActiveInPageTrackActive({
  userId,
  resultType,
  activeType,
  timeMin,
  timeMax,
}) {
  try {
    const response = await queryEye3({
      method: "trackactive.active_in_page",
      get: {
        user_id: userId,
        result_type: resultType,
        active_type: activeType,
        time_min: timeMin,
        time_max: timeMax,
      },
      access: { isBotConnect: true, isLicense: true, isLimit: true },
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3ActiveInPageTrackActive;
