import React from "react";
import { Avatar, SimpleCell } from "@vkontakte/vkui";

const FIAInfoTopPanel = (props) => {
  return (
    <SimpleCell
      before={<Avatar size={48} src={props.photo} mode="app" />}
      disabled
      style={{ marginTop: "8px", marginBottom: "8px" }}
      description={props.description}
    >
      {props.title}
    </SimpleCell>
  );
};

export default FIAInfoTopPanel;
