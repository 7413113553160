import { Div } from "@vkontakte/vkui";
import { convertMonthDay, unixTimeToDate } from "helpers";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import { useLoadMore } from "hooks";
import React from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBalanceHistory, loadMoreBalanceHistory } from "store/actions/shop";
import HeadTitle from "template/components/HeadTitle/HeadTitle";
import HistoryItem from "../HistoryItem/HistoryItem";
import styles from "./HistoryList.module.scss";

const HistoryList = () => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const loading = useSelector((state) => state.shop.balanceHistory.loading);
  const error = useSelector((state) => state.shop.balanceHistory.error);
  const history = useSelector((state) => state.shop.balanceHistory.payload);
  const offset = useSelector((state) => state.shop.balanceHistory.offset);
  const maxLength = useSelector((state) => state.shop.balanceHistory.maxLength);
  const handleFetchBalanceHistory = useCallback(() => dispatch(fetchBalanceHistory()), [dispatch]);
  const handleLoadMoreBalanceHistory = useCallback(
    () => dispatch(loadMoreBalanceHistory()),
    [dispatch]
  );

  let lastAt = new Date();

  useLoadMore({
    items: history,
    offset: offset,
    loading: loading,
    isTransitionEnd: isTransitionEnd,
    maxLength: maxLength,
    handler: handleLoadMoreBalanceHistory,
  });

  useEffect(() => {
    handleFetchBalanceHistory();
  }, [handleFetchBalanceHistory]);

  const getSeparate = (value) => {
    return (
      <Div>
        <div className={styles.separate}>{convertMonthDay(value)}</div>
      </Div>
    );
  };

  const renderHistory = () => {
    return history.map((item, i) => {
      const createAt = unixTimeToDate(item.create_at);
      const count = item.count;
      const txt = item.txt;

      let separate = getSeparate(createAt);

      if (lastAt.getDate() === createAt.getDate() && i !== 0) {
        separate = "";
      }

      lastAt = createAt;

      return (
        <div key={i}>
          {separate}
          <HistoryItem key={i} count={count} txt={txt} />
        </div>
      );
    });
  };

  return (
    <>
      <HeadTitle>История операций</HeadTitle>
      {renderErrorPlaceholder({
        loading: loading,
        error: error,
        isTryAgainHandler: handleFetchBalanceHistory,
      })}
      {history !== null && renderHistory()}
      {renderLoader({
        loading: loading,
      })}
    </>
  );
};

export default HistoryList;
