import React from "react";
import { Avatar, SimpleCell } from "@vkontakte/vkui";
import HeartFill from "assets/img/moycrush/heartFill.svg";
import { VIEW_MOY_CRUSH } from "views/constViews";
import { PANEL_PROFILE } from "views/profile/panels/constPanels";

const MyCrushItem = (props) => {
  const goProfile = () => {
    props.openView({
      view: VIEW_MOY_CRUSH,
      panel: PANEL_PROFILE,
      param: { activeProfileId: props.id },
    });
  };

  return (
    <SimpleCell
      disabled
      before={<Avatar size={48} src={props.photo} onClick={goProfile} />}
      after={
        <img src={HeartFill} alt="" onClick={() => props.handleDelete(props.id, props.title)} />
      }
      description={<span onClick={goProfile}> {props.description}</span>}
    >
      <span onClick={goProfile}> {props.title} </span>
    </SimpleCell>
  );
};

export default MyCrushItem;
