import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Group, Search, Tabs, TabsItem, Placeholder } from "@vkontakte/vkui";
import AOtherUserInfoItem from "../AOtherUserInfoItem/AOtherUserInfoItem";
import Icon24Filter from "@vkontakte/icons/dist/24/filter";
import SeparateFilter from "template/components/SeparateFilter/SeparateFilter";
import AOtherUserInfoTopPanel from "../AOtherUserInfoTopPanel/AOtherUserInfoTopPanel";
import Icon56LikeOutline from "@vkontakte/icons/dist/56/like_outline";
import { unixTimeToDate, convertMonthDay, convertTime } from "helpers";
import { renderPeriodV2 } from "template/popouts/DateChangePeriod/views";
import PopoutTemplateDateChangePeriod from "template/popouts/DateChangePeriod/DateChangePeriod";

import {
  fetchOtherUserInfo,
  filterOtherUserInfo,
  searchOtherUserInfo,
  cancelOtherUserInfo,
  setPeriodOtherUserInfo,
  tabsOtherUserInfo,
} from "store/actions/tool/activity";
import { PANEL_A__OTHER_USER_INFO } from "views/tool/panels/constPanels";
import { TABS_ALL, TABS_COMMENT, TABS_LIKE } from "../../../constTabs";
import { VIEW_TOOL } from "views/constViews";
import { MODAL_PAGE_TEMPLATE_FILTER_SEX_AGE_CITY } from "template/modals/constModals";

import LoaderWithProgressBar from "template/components/LoaderWithProgressBar/LoaderWithProgressBar";
import { renderErrorPlaceholder } from "helpers/views";
import { useDebounceSearch } from "hooks";

const AOtherUserInfoList = (props) => {
  const dispatch = useDispatch();
  const activity = useSelector((state) => state.toolActivity.otherUserInfo.payload);
  const progressValue = useSelector((state) => state.toolActivity.otherUserInfo.progressValue);
  const tabs = useSelector((state) => state.toolActivity.otherUserInfo.tabs);
  const period = useSelector((state) => state.toolActivity.otherUserInfo.period);
  const filter = useSelector((state) => state.toolActivity.otherUserInfo.filter);
  const search = useSelector((state) => state.toolActivity.otherUserInfo.search);
  const error = useSelector((state) => state.toolActivity.otherUserInfo.error);
  const loading = useSelector((state) => state.toolActivity.otherUserInfo.loading);
  const activeUserId = useSelector((state) => state.toolActivity.otherUserPage.activeUserId);
  const activeUserTitle = useSelector((state) => state.toolActivity.otherUserPage.activeUserTitle);
  const activeUserDescription = useSelector(
    (state) => state.toolActivity.otherUserPage.activeUserDescription
  );
  const activeUserPhoto = useSelector((state) => state.toolActivity.otherUserPage.activeUserPhoto);

  const [searchTerm, setSearchTerm] = useState(search);

  const handleFetchOtherUserInfo = useCallback(() => dispatch(fetchOtherUserInfo()), [dispatch]);
  const handleCancelOtherUserInfo = useCallback(() => dispatch(cancelOtherUserInfo()), [dispatch]);
  const handleSetPeriodOtherUserInfo = useCallback(
    (name, atMin = null, atMax = null) => dispatch(setPeriodOtherUserInfo(name, atMin, atMax)),
    [dispatch]
  );
  const handleTabsOtherUserInfo = useCallback(
    (tabs) => dispatch(tabsOtherUserInfo(tabs)),
    [dispatch]
  );
  const handleFilterOtherUserInfo = useCallback(
    (filter) => dispatch(filterOtherUserInfo(filter)),
    [dispatch]
  );
  const handleSearchOtherUserInfo = useCallback(
    (search) => dispatch(searchOtherUserInfo(search)),
    [dispatch]
  );

  let lastAt = new Date();

  useEffect(() => {
    return () => {
      handleCancelOtherUserInfo();
    };
  }, [handleCancelOtherUserInfo]);

  useEffect(() => {
    handleFetchOtherUserInfo();
  }, [handleFetchOtherUserInfo, period]);

  useDebounceSearch({
    search: search,
    searchTerm: searchTerm,
    handler: handleSearchOtherUserInfo,
  });

  const setTabsActivity = (tabs) => {
    if (!loading) {
      handleTabsOtherUserInfo(tabs);
      handleFetchOtherUserInfo();
    }
  };

  const renderUser = () => {
    return (
      <AOtherUserInfoTopPanel
        id={activeUserId}
        title={activeUserTitle}
        description={activeUserDescription}
        photo={activeUserPhoto}
        openView={props.openView}
      />
    );
  };

  const renderActivity = () => {
    return activity.map((a, i) => {
      const id = a.vk_id;
      const at = unixTimeToDate(a._at);
      const type = a.type;
      const infoText = a.info_text;
      const infoPhoto = a.info_photo;
      const infoLink = a.info_link;

      const name = a.name;
      const photoUrl = a.photo_url;
      const sex = a.sex;

      let separate = <SeparateFilter>{convertMonthDay(at)}</SeparateFilter>;

      if (lastAt.getDate() === at.getDate() && i !== 0) {
        separate = "";
      }

      lastAt = at;

      return (
        <div key={i}>
          {separate}
          <AOtherUserInfoItem
            id={id}
            name={name}
            photoUrl={photoUrl}
            sex={sex}
            time={convertTime(at)}
            type={type}
            infoText={infoText}
            infoPhoto={infoPhoto}
            infoLink={infoLink}
          />
        </div>
      );
    });
  };

  const handleFilter = () => {
    if (loading) return;

    props.openModal({
      id: MODAL_PAGE_TEMPLATE_FILTER_SEX_AGE_CITY,
      param: {
        filter: filter,
        closeModal: props.closeModal,
        applyFilter: handleFilterOtherUserInfo,
      },
    });
  };

  const handlePeriod = () => {
    if (loading) return false;

    props.openPopout({
      popout: (
        <PopoutTemplateDateChangePeriod
          openView={props.openView}
          period={period} // Период из redux store
          setPeriod={handleSetPeriodOtherUserInfo} // action из redux
          backPanel={PANEL_A__OTHER_USER_INFO}
          backView={VIEW_TOOL}
          panelTitle={"Активности"}
          closePopout={props.closePopout}
        />
      ),
    });
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      {renderPeriodV2({
        title: "Активности у пользователя",
        period: period,
        handler: handlePeriod,
      })}
      {renderUser()}
      {error === null && (
        <Search
          value={searchTerm}
          icon={<Icon24Filter />}
          onIconClick={handleFilter}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      )}
      {error === null && (
        <Tabs>
          <TabsItem
            disabled={loading}
            onClick={() => {
              if (tabs !== TABS_ALL) setTabsActivity(TABS_ALL);
            }}
            selected={tabs === TABS_ALL}
          >
            Все
          </TabsItem>
          <TabsItem
            disabled={loading}
            onClick={() => {
              if (tabs !== TABS_LIKE) setTabsActivity(TABS_LIKE);
            }}
            selected={tabs === TABS_LIKE}
          >
            Лайки
          </TabsItem>
          <TabsItem
            disabled={loading}
            onClick={() => {
              if (tabs !== TABS_COMMENT) setTabsActivity(TABS_COMMENT);
            }}
            selected={tabs === TABS_COMMENT}
          >
            Комментарии
          </TabsItem>
        </Tabs>
      )}
      <Group>
        {loading ? <LoaderWithProgressBar progressValue={progressValue} /> : ""}
        {activity !== null && error === null ? renderActivity() : ""}
        {!loading &&
        activity !== null &&
        Object.prototype.toString.call(activity) === "[object Array]" &&
        error === null &&
        activity.length <= 0 ? (
          <Placeholder icon={<Icon56LikeOutline />}>Активности не найдены</Placeholder>
        ) : (
          ""
        )}
      </Group>
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchOtherUserInfo,
      })}
    </div>
  );
};

export default AOtherUserInfoList;
