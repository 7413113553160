import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Panel } from "@vkontakte/vkui";
import WhoHiddenMeList from "./components/WhoHiddenMeList/WhoHiddenMeList";
import { withRouter } from "hoc";

const PanelWHwhoHiddenMe = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Скрытые пользователи</PanelHeaderWithPath>
      <WhoHiddenMeList openView={props.openView} />
    </Panel>
  );
};

export default withRouter(PanelWHwhoHiddenMe);
