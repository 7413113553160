import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";

import CommentList from "./components/CommentList/CommentList";
import { Panel } from "@vkontakte/vkui";
import { withRouter } from "hoc";

const PanelComment = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Профиль</PanelHeaderWithPath>
      <CommentList />
    </Panel>
  );
};

export default withRouter(PanelComment);
