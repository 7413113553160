import React, { useState } from "react";
import styles from "./AffiliateLink.module.scss";
import copy from "assets/img/myProfile/copy.svg";
import { useSelector } from "react-redux";
import SnakbarCopy from "template/components/SnakbarCopy/SnakbarCopy";

const AffiliateLink = () => {
  const payloadEYE3 = useSelector((state) =>
    state.myProfile.payloadEYE3 ? state.myProfile.payloadEYE3 : null
  );
  const link = payloadEYE3 ? payloadEYE3.ref.link : "";
  const [snakCopy, setSnakCopy] = useState(null);

  return (
    <div className={styles.container}>
      <p className={styles.title}>Партнёрская ссылка</p>
      <div className={styles.linkSection}>
        <span className={styles.link}>{link}</span>
        <SnakbarCopy copy={snakCopy} setCopy={setSnakCopy} link={link}>
          <span>
            <img src={copy} alt=""></img>
          </span>
        </SnakbarCopy>
      </div>
    </div>
  );
};

export default AffiliateLink;
