import React, { useCallback } from "react";
import { SimpleCell, Switch } from "@vkontakte/vkui";
import { Icon28SettingsOutline } from "@vkontakte/icons";
import Icon16Chevron from "@vkontakte/icons/dist/16/chevron";
import { useDispatch, useSelector } from "react-redux";
import { VIEW_TOOL } from "views/constViews";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Panel } from "@vkontakte/vkui";

import HeadTitle from "template/components/HeadTitle/HeadTitle";
import { setRelationBackground, fetchGeoSettings } from "store/actions/tool/maritalStatus";
import { useEffect } from "react";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import { PANEL_MS__GEO_SETTINGS } from "../../constPanels";
import { withRouter } from "hoc";

const PanelMSGeo = (props) => {
  const dispatch = useDispatch();
  const geoSettings = useSelector((state) => state.toolMaritalStatus.geoSettings.payload);
  const loading = useSelector((state) => state.toolMaritalStatus.geoSettings.loading);
  const error = useSelector((state) => state.toolMaritalStatus.geoSettings.error);
  const isEnable = geoSettings !== null ? geoSettings.is_enable : false;
  const handleSetRelationBackground = useCallback(
    () => dispatch(setRelationBackground()),
    [dispatch]
  );
  const handleFetchGeoSettings = useCallback(() => dispatch(fetchGeoSettings()), [dispatch]);

  useEffect(() => {
    handleFetchGeoSettings();
  }, [handleFetchGeoSettings]);

  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Семейное положение</PanelHeaderWithPath>
      {geoSettings !== null && error === null ? (
        <>
          <HeadTitle>Семейное положение по гео</HeadTitle>
          <SimpleCell
            before={<Icon28SettingsOutline />}
            onClick={() =>
              props.openView({
                view: VIEW_TOOL,
                panel: PANEL_MS__GEO_SETTINGS,
                param: {
                  isEnable: isEnable,
                },
              })
            }
            after={<Icon16Chevron fill="#B8C1CC" />}
          >
            Параметры поиска
          </SimpleCell>
          <SimpleCell
            disabled
            multiline
            after={<Switch defaultChecked={isEnable} onClick={handleSetRelationBackground} />}
          >
            Получать уведомления о появлении новых пользователей по заданным параметрам
          </SimpleCell>
        </>
      ) : (
        ""
      )}
      {renderLoader({
        loading: loading,
      })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchGeoSettings,
      })}
    </Panel>
  );
};

export default withRouter(PanelMSGeo);
