import { Button, Panel } from "@vkontakte/vkui";
import { APIEye3EducationProfile } from "api/eye3";
import { gotoUrl } from "helpers";
import { withRouter } from "hoc";
import CrushImg from "assets/img/education/step7/crush.png";
import { useDispatch, useSelector } from "react-redux";
import { checkedAccessProc } from "store/actions/myProfile";
import EducationStepWithLogo from "views/education/components/EducationStepWithLogo/EducationStepWithLogo";
import { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import styles from "./step7.module.scss";

const PanelStep7 = (props) => {
  const dispatch = useDispatch();
  const payloadEYE3 = useSelector((state) =>
    state.myProfile.payloadEYE3 ? state.myProfile.payloadEYE3 : null
  );

  const loading = useSelector((state) => state.myProfile.checkedAccess.loading);

  const [inAnimation, setInAnimation] = useState(false);

  useEffect(() => {
    setInAnimation(true);
  }, []);

  const finish = () => {
    APIEye3EducationProfile({ education: 1 }).then(() => {
      // Если не подключен бот попытка авторизации
      if (!payloadEYE3.user.is_bot_connect) {
        let encode = encodeURIComponent(window.location.href);
        let url = "https://mycrush.ru/login_auth_link_app?redirect_uri=" + encode;
        gotoUrl(url);
      }
      if (!loading) dispatch(checkedAccessProc());
    });
  };

  return (
    <Panel id={props.id}>
      <EducationStepWithLogo
        header={
          <CSSTransition
            in={inAnimation}
            timeout={200}
            classNames={{
              enterActive: styles.enterActive,
              enterDone: styles.enterDone,
            }}
          >
            <div>И последнее!</div>
          </CSSTransition>
        }
        description={
          <CSSTransition
            in={inAnimation}
            timeout={600}
            classNames={{
              enterActive: styles.enterActive,
              enterDone: styles.enterDone,
            }}
          >
            <div>
              Крашу нужен доступ к списку твоих друзей и иным данным, иначе приложение не сможет
              работать
            </div>
          </CSSTransition>
        }
        icon={<img src={CrushImg} width={238} height={260} alt=""></img>}
        action={
          <CSSTransition
            in={inAnimation}
            timeout={1000}
            classNames={{
              enterActive: styles.enterActive,
              enterDone: styles.enterDone,
            }}
          >
            <Button mode="primary" size="xl" style={{ width: "100%" }} onClick={finish}>
              Выдать разрешение
            </Button>
          </CSSTransition>
        }
      />
    </Panel>
  );
};

export default withRouter(PanelStep7);
