import React from "react";
import { ModalCard } from "@vkontakte/vkui";

import Icon28FireOutline from "@vkontakte/icons/dist/28/fire_outline";
import Icon28AdvertisingOutline from "@vkontakte/icons/dist/28/advertising_outline";
import Icon28SlidersOutline from "@vkontakte/icons/dist/28/sliders_outline";
import { PANEL_POPULAR_COMMUNITY } from "views/tool/panels/constPanels";
import ToolModalItem from "views/tool/panels/main/components/ToolModalItem/ToolModalItem";

const MenuToolListCommunityModal = (props) => {
  const caption = props.modalParam ? props.modalParam.caption : "";

  return (
    <ModalCard
      id={props.id}
      caption={caption}
      onClose={props.handleCloseModal}
      header={
        <span
          style={{
            color: "#818C99",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "13px",
            lineHeight: "16px",
            letterSpacing: "0.2px",
            textTransform: "uppercase",
          }}
        >
          СООБЩЕСТВА
        </span>
      }
    >
      <ToolModalItem
        title={"Популярные сообщества"}
        icon={<Icon28FireOutline width={30} height={30} fill={"#FB8643"} />}
        openView={props.openView}
        closeModal={props.handleCloseModal}
        to={PANEL_POPULAR_COMMUNITY}
      />
      <ToolModalItem
        title={"Активность сообществ"}
        openView={props.openView}
        icon={<Icon28AdvertisingOutline width={30} height={30} fill={"#1BB60E"} />}
        closeModal={props.handleCloseModal}
      />
      <ToolModalItem
        title={"Популярные подписчики"}
        icon={<Icon28SlidersOutline width={30} height={30} fill={"#3F8AE0"} />}
        openView={props.openView}
        closeModal={props.handleCloseModal}
      />
    </ModalCard>
  );
};

export default MenuToolListCommunityModal;
