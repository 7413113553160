import {
  PROFILE_LIKE_REQUEST,
  PROFILE_LIKE_SUCCESS,
  PROFILE_LIKE_ERROR,
  PROFILE_LIKE_PROGRESS,
  PROFILE_LIKE_SHOW_MORE,
  PROFILE_LIKE_CANCEL,
} from "../../constType";

import { delay } from "helpers";

import { APIEye3ActiveMyPageTrackActive } from "api/eye3";
import { openModal } from "../page";
import { checkEYE3ApiError, defaultErrorProperty } from "api/eye3/responseHandler";

export function fetchLike() {
  return async (dispatch, getState) => {
    try {
      const state = getState().profileLike;
      const page = getState().page;
      const profile = getState().profile;
      const id = profile.history[page.activeStory].user.id;

      // Если предыдущий открытый профиль равен текущему открытому профилю
      if (
        state.activeUserId === profile.history[page.activeStory].user.id &&
        !state.isStopLoading
      ) {
        return false;
      }

      dispatch(request(id));

      async function process() {
        try {
          if (!getState().profileLike.loading) return;

          let result = [];

          const likes = await APIEye3ActiveMyPageTrackActive({
            userId: id,
            activeType: "like",
          });

          //console.log('likes: ', likes);

          // Проверка ошибок в API
          const errorAPI = checkEYE3ApiError(likes);
          if (errorAPI !== false) {
            errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
            dispatch(error({ ...errorAPI }));
            return;
          }

          if (likes.status_task === "work") {
            dispatch(progress(likes.percent));
            await delay(2000);
            await process();
          } else if (likes.status_task === "success") {
            likes.result.forEach((like) => {
              result.push({
                vk_id: like.vk_id,
                type: like.type,
                _at: like._at,
                info_link: like.info_link,
                info_photo: like.info_photo,
                info_text: like.info_text,
              });
            });

            dispatch(success(result));
          }
        } catch (e) {
          dispatch(error({ ...defaultErrorProperty, message: e, isTryAgain: false }));
        }
      }

      await process();
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e, isTryAgain: false }));
    }
  };

  function request(id) {
    return { type: PROFILE_LIKE_REQUEST, id };
  }

  function progress(progressValue) {
    return { type: PROFILE_LIKE_PROGRESS, progressValue };
  }

  function success(payload) {
    return { type: PROFILE_LIKE_SUCCESS, payload };
  }

  function error(error) {
    return { type: PROFILE_LIKE_ERROR, error };
  }
}

export function cancelLike() {
  return { type: PROFILE_LIKE_CANCEL };
}

export function showMoreLike() {
  return { type: PROFILE_LIKE_SHOW_MORE };
}
