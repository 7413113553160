import {
  SHOP_BALANCE_REPLENISHMENT_CREATE_PAYMENT_REQUEST,
  SHOP_BALANCE_REPLENISHMENT_CREATE_PAYMENT_SUCCESS,
  SHOP_BALANCE_REPLENISHMENT_CREATE_PAYMENT_ERROR,
  SHOP_BALANCE_REPLENISHMENT_CREATE_UNITPAY_PAYMENT_REQUEST,
  SHOP_BALANCE_REPLENISHMENT_CREATE_UNITPAY_PAYMENT_SUCCESS,
  SHOP_BALANCE_REPLENISHMENT_CREATE_UNITPAY_PAYMENT_ERROR,
  SHOP_BALANCE_REPLENISHMENT_SAVE_PAYMENT,
  SHOP_CONNECT_TARIFF_REQUEST,
  SHOP_CONNECT_TARIFF_SUCCESS,
  SHOP_CONNECT_TARIFF_ERROR,
  SHOP_SAVE_TARIFF,
  SHOP_BALANCE_CHECKED_PAYMENT_REQUEST,
  SHOP_BALANCE_CHECKED_PAYMENT_SUCCESS,
  SHOP_BALANCE_CHECKED_PAYMENT_PROGRESS,
  SHOP_BALANCE_CHECKED_PAYMENT_ERROR,
  SHOP_BALANCE_CHECKED_PAYMENT_CANCEL,
  SHOP_BALANCE_HISTORY_REQUEST,
  SHOP_BALANCE_HISTORY_SUCCESS,
  SHOP_BALANCE_HISTORY_ERROR,
  SHOP_BALANCE_HISTORY_LOAD_MORE_REQUEST,
  SHOP_BALANCE_HISTORY_LOAD_MORE_SUCCESS,
  SHOP_BALANCE_HISTORY_LOAD_MORE_ERROR,
} from "../constType";

import BalanceReplenishedIcon from "assets/img/shop/modal/balanceReplenished.png";
import SomethingWentWrongIcon from "assets/img/shop/modal/somethingWentWrong.png";

import {
  APIEye3BalanceHistoryProfile,
  APIEye3BuyLicense,
  APIEye3CreatePayment,
  APIEye3CreateUnitpayPayment,
  APIEye3GetProfile,
} from "api/eye3";
import { openModal, openStory, openView } from "./page";
import { PANEL_BALANCE_REPLENISHMENT, PANEL_MAIN } from "views/shop/panels/constPanels";
import { STORY_SHOP } from "stories/constStories";
import {
  MODAL_CARD_BALANCE_STATUS,
  MODAL_CARD_TARIFF_CONNECTED,
} from "views/shop/modals/constModals";
import { fetchLicense, updateMyProfile } from "./myProfile";
import { delay, declOfNum, gotoUrl } from "helpers";
import { VIEW_SHOP } from "views/constViews";
import { defaultErrorProperty } from "api/eye3/responseHandler";

export function cancelCheckedPayment() {
  return { type: SHOP_BALANCE_CHECKED_PAYMENT_CANCEL };
}

export function checkedPayment() {
  return async (dispatch, getState) => {
    try {
      await dispatch(request());

      const needSum =
        getState().page.param !== null && getState().page.param.hasOwnProperty("needSum")
          ? getState().page.param
          : null;

      const lastPayAt = getState().myProfile.payloadEYE3.user.last_pay_at;
      //console.log('lastPayAt: ', lastPayAt);
      //console.log('needSum: ', needSum);

      // Если платеж прошел
      async function payment() {
        if (needSum === null) {
          const count = getState().shop.payment.count;
          const countStr = count + " " + declOfNum(count, ["монета", "монеты", "монет"]);

          await dispatch(
            openModal({
              id: MODAL_CARD_BALANCE_STATUS,
              param: {
                header: "Баланс пополнен",
                caption: "Ваш баланс был успешно пополнен на " + countStr,
                icon: BalanceReplenishedIcon,
                btnTitle: "Понятно",
              },
            })
          );
        }

        await dispatch(openView({ view: VIEW_SHOP, panel: PANEL_MAIN }));

        // Если идет пополнение недостающего баланса для подключения тарифа
        if (needSum !== null) {
          await dispatch(
            connectTariff(
              getState().shop.tariff.tariffType,
              getState().shop.tariff.dayCount,
              getState().shop.tariff.autopayment,
              getState().shop.tariff.tariffCaption
            )
          );
        } else {
          await dispatch(updateMyProfile());
        }
      }

      async function process() {
        try {
          if (getState().shop.checkedPayment.loading) {
            const profileInfo = await APIEye3GetProfile();
            //console.log(profileInfo);

            if (profileInfo.status && lastPayAt === profileInfo.user.last_pay_at) {
              await dispatch(progress(profileInfo));
              await delay(1000);
              await process();
            } else {
              await dispatch(success());
              await payment();
            }
          }
        } catch (e) {
          dispatch(error(e));
        }
      }

      await process();
    } catch (e) {
      dispatch(error(e));
    }
  };

  function request() {
    return { type: SHOP_BALANCE_CHECKED_PAYMENT_REQUEST };
  }

  function progress(payload) {
    return { type: SHOP_BALANCE_CHECKED_PAYMENT_PROGRESS, payload };
  }

  function success() {
    return { type: SHOP_BALANCE_CHECKED_PAYMENT_SUCCESS };
  }

  function error(e) {
    return { type: SHOP_BALANCE_CHECKED_PAYMENT_ERROR, error: e };
  }
}

export function createPayment(email, count, autopayment) {
  return async (dispatch) => {
    try {
      dispatch(request());

      const res = await APIEye3CreatePayment({
        email: email,
        count: count,
        autopayment: +autopayment,
      });

      //console.log('res: ', res);

      /*if (res.status) {
                window.open(res.url, '_blank');
            }*/

      dispatch(success(res));
    } catch (e) {
      dispatch(error(e));
    }
  };

  function request() {
    return { type: SHOP_BALANCE_REPLENISHMENT_CREATE_PAYMENT_REQUEST };
  }

  function success(payload) {
    return { type: SHOP_BALANCE_REPLENISHMENT_CREATE_PAYMENT_SUCCESS, payload };
  }

  function error(e) {
    return { type: SHOP_BALANCE_REPLENISHMENT_CREATE_PAYMENT_ERROR, error: e };
  }
}

export function createUnitpayPayment(email, count, type) {
  return async (dispatch) => {
    try {
      dispatch(request());

      //console.log('platform: ', platform);

      const res = await APIEye3CreateUnitpayPayment({
        email: email,
        count: count,
        type: type,
      });

      //console.log('res: ', res);

      if (!res.status) {
        dispatch(success(res));
        return;
      }

      gotoUrl(res.url);

      dispatch(success(res));
    } catch (e) {
      dispatch(error(e));
    }
  };

  function request() {
    return { type: SHOP_BALANCE_REPLENISHMENT_CREATE_UNITPAY_PAYMENT_REQUEST };
  }

  function success(payload) {
    return { type: SHOP_BALANCE_REPLENISHMENT_CREATE_UNITPAY_PAYMENT_SUCCESS, payload };
  }

  function error(e) {
    return { type: SHOP_BALANCE_REPLENISHMENT_CREATE_UNITPAY_PAYMENT_ERROR, error: e };
  }
}

export function savePayment(email, count, paymentType) {
  return { type: SHOP_BALANCE_REPLENISHMENT_SAVE_PAYMENT, email, count, paymentType };
}

export function saveTariff(tariffType, dayCount, autopayment, tariffCaption) {
  return { type: SHOP_SAVE_TARIFF, tariffType, dayCount, autopayment, tariffCaption };
}

export function winningTariff(tariffType, dayCount, autopayment, tariffCaption) {
  return async (dispatch) => {
    try {
      await dispatch(request());
      await dispatch(saveTariff(tariffType, dayCount, autopayment, tariffCaption));
      await dispatch(
        openStory({
          story: STORY_SHOP,
          panel: PANEL_BALANCE_REPLENISHMENT,
          param: {
            needSum: 1,
            autopayment: true,
            isRoullete: true,
          },
        })
      );
      await dispatch(success(true));
    } catch (e) {
      await dispatch(error(e));
      await dispatch(
        openModal({
          id: MODAL_CARD_BALANCE_STATUS,
          param: {
            header: "Что то пошло не так :(",
            icon: SomethingWentWrongIcon,
            caption: "Попробуйте еще раз",
            btnTitle: "Ок",
          },
        })
      );
    }
  };

  function request() {
    return { type: SHOP_CONNECT_TARIFF_REQUEST };
  }

  function success(payload) {
    return { type: SHOP_CONNECT_TARIFF_SUCCESS, payload };
  }

  function error(e) {
    return { type: SHOP_CONNECT_TARIFF_ERROR, error: e };
  }
}

export function connectTariff(tariffType, dayCount, autopayment, tariffCaption) {
  return async (dispatch) => {
    try {
      await dispatch(request());

      await dispatch(saveTariff(tariffType, dayCount, autopayment, tariffCaption));

      const res = await APIEye3BuyLicense({
        type: tariffType,
        dayCount,
        autopayment: +autopayment, // false to 0, true to 1
      });

      //console.log('connectTariff: ', res);

      if (!res.status) {
        await dispatch(
          openModal({
            id: MODAL_CARD_BALANCE_STATUS,
            param: {
              header: "Что то пошло не так :(",
              icon: SomethingWentWrongIcon,
              caption: "Попробуйте еще раз",
              btnTitle: "Ок",
            },
          })
        );
        return;
      }

      await dispatch(success(res));

      // Если у пользователя не достаточно монет на счету и автоплатеж не подключен
      if (res.hasOwnProperty("type") && res.type === "need_sum" && !autopayment) {
        await dispatch(
          openView({
            view: VIEW_SHOP,
            panel: PANEL_BALANCE_REPLENISHMENT,
            param: {
              needSum: res.sum,
              autopayment: autopayment,
            },
          })
        );
      }
      // Если у пользователя не достаточно монет на счету и он выбрал включить автоплатеж
      else if (res.hasOwnProperty("type") && res.type === "need_sum" && autopayment) {
        await dispatch(
          openView({
            view: VIEW_SHOP,
            panel: PANEL_BALANCE_REPLENISHMENT,
            param: {
              needSum: res.sum,
              autopayment: autopayment,
            },
          })
        );
      }
      // Если у пользователя достаточно монет
      else if (res.hasOwnProperty("type") && res.type === "success") {
        await dispatch(updateMyProfile());
        await dispatch(fetchLicense());
        await dispatch(openModal({ id: MODAL_CARD_TARIFF_CONNECTED }));
      }
    } catch (e) {
      await dispatch(error(e));
      await dispatch(
        openModal({
          id: MODAL_CARD_BALANCE_STATUS,
          param: {
            header: "Что то пошло не так :(",
            icon: SomethingWentWrongIcon,
            caption: "Попробуйте еще раз",
            btnTitle: "Ок",
          },
        })
      );
    }
  };

  function request() {
    return { type: SHOP_CONNECT_TARIFF_REQUEST };
  }

  function success(payload) {
    return { type: SHOP_CONNECT_TARIFF_SUCCESS, payload };
  }

  function error(e) {
    return { type: SHOP_CONNECT_TARIFF_ERROR, error: e };
  }
}

async function getBalanceHistory(dispatch, getState, error) {
  try {
    const state = getState().shop;
    const count = state.balanceHistory.count;
    const offset = state.balanceHistory.offset;

    const balance = await APIEye3BalanceHistoryProfile({
      count,
      offset,
    });

    // console.log("balance: ", balance);

    let result = [];

    if (!balance.status) {
      dispatch(
        error({
          ...defaultErrorProperty,
          message: "",
        })
      );

      return {
        result: result,
        maxLength: 0,
      };
    }

    const balanceItems = balance.items;
    const maxLength = balance.max_length;

    for (let item of balanceItems) {
      result.push({
        count: item.count,
        create_at: item.create_at,
        txt: item.txt,
      });
    }

    // console.log("result: ", result);

    return {
      result: result,
      maxLength: maxLength,
    };
  } catch (e) {
    throw new Error("getBalanceHistory::: " + e);
  }
}

export function fetchBalanceHistory() {
  return async (dispatch, getState) => {
    try {
      /*const state = getState().toolShop;
            const pageParam = getState().page.param;*/

      await dispatch(request());

      const { result, maxLength } = await getBalanceHistory(dispatch, getState, error);

      if (getState().shop.balanceHistory.error === null) await dispatch(success(result, maxLength));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: SHOP_BALANCE_HISTORY_REQUEST };
  }

  function success(payload, maxLength) {
    return { type: SHOP_BALANCE_HISTORY_SUCCESS, payload, maxLength };
  }

  function error(error) {
    return { type: SHOP_BALANCE_HISTORY_ERROR, error };
  }
}

export function loadMoreBalanceHistory() {
  return async (dispatch, getState) => {
    try {
      await dispatch(request());

      const { result } = await getBalanceHistory(dispatch, getState, error);

      // console.log("result: ", result);

      await dispatch(success(result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: SHOP_BALANCE_HISTORY_LOAD_MORE_REQUEST };
  }

  function success(payload) {
    return { type: SHOP_BALANCE_HISTORY_LOAD_MORE_SUCCESS, payload };
  }

  function error(error) {
    return { type: SHOP_BALANCE_HISTORY_LOAD_MORE_ERROR, error };
  }
}
