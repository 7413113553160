import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Group, Header, Placeholder } from "@vkontakte/vkui";
import FIAInfoItem from "../FIAInfoItem/FIAInfoItem";
import FIAInfoTopPanel from "../FIAInfoTopPanel/FIAInfoTopPanel";
import Icon56UsersOutline from "@vkontakte/icons/dist/56/users_outline";

import { fetchInfoApp, showMoreInfoApp, cancelInfoApp } from "store/actions/tool/friendsInApp";
import { declOfNum } from "helpers";
import { renderErrorPlaceholder } from "helpers/views";
import { useShowMore } from "hooks";
import Icon56ServicesOutline from "@vkontakte/icons/dist/56/services_outline";
import HeadTitle from "template/components/HeadTitle/HeadTitle";
import LoaderWithProgressBar from "template/components/LoaderWithProgressBar/LoaderWithProgressBar";

const FIAInfoList = (props) => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const loading = useSelector((state) => state.toolFriendsInApp.appsInfo.loading);
  const error = useSelector((state) => state.toolFriendsInApp.appsInfo.error);
  const appsInfo = useSelector((state) => state.toolFriendsInApp.appsInfo.payload);
  const showCount = useSelector((state) => state.toolFriendsInApp.appsInfo.showCount);
  const progressValue = useSelector((state) => state.toolFriendsInApp.appsInfo.progressValue);
  const app = appsInfo !== null && appsInfo.hasOwnProperty("app") ? appsInfo.app : {};
  const friends = appsInfo !== null && appsInfo.hasOwnProperty("friends") ? appsInfo.friends : [];
  const friendsCount = friends.length;
  const friendsCountStrFormat = friendsCount
    ? friendsCount + " " + declOfNum(friendsCount, ["друг", "друга", "друзей"])
    : "0 друзей";

  const handleFetchInfoApp = useCallback(() => dispatch(fetchInfoApp()), [dispatch]);
  const handleShowMoreInfoApp = useCallback(() => dispatch(showMoreInfoApp()), [dispatch]);
  const handleCancelInfoApp = useCallback(() => dispatch(cancelInfoApp()), [dispatch]);

  useShowMore({
    items: appsInfo,
    showCount: showCount,
    maxLength: appsInfo !== null ? appsInfo.friends.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMoreInfoApp,
  });

  useEffect(() => {
    handleFetchInfoApp();
  }, [handleFetchInfoApp]);

  useEffect(() => {
    return () => {
      handleCancelInfoApp();
    };
  }, [handleCancelInfoApp]);

  const renderApp = () => {
    if (Object.keys(app).length !== 0) {
      const title = app.hasOwnProperty("title") ? app.title : "";
      const photoUrl = app.hasOwnProperty("icon_75") ? app.icon_75 : null;

      const membersCount = app.hasOwnProperty("members_count") ? app.members_count : 0;
      const memberDisc = membersCount.toLocaleString();

      const membersCountStrFormat = membersCount
        ? memberDisc +
          " " +
          declOfNum(membersCount, ["пользователь", "пользователя", "пользователей"])
        : "0 пользователей";

      return <FIAInfoTopPanel title={title} photo={photoUrl} description={membersCountStrFormat} />;
    }

    if (!loading && error === null) {
      return <Placeholder icon={<Icon56ServicesOutline />}>Приложение не найдено</Placeholder>;
    }
  };

  const renderFriends = () => {
    return friends.length > 0
      ? friends.map((friend, i) => {
          const id = friend.vk_id;
          const name = friend.name;
          const photo = friend.photo_url ? friend.photo_url : "";
          const appCount = friend.count;

          const appCountStrFormat = appCount
            ? appCount + " " + declOfNum(appCount, ["приложение", "приложения", "приложений"])
            : "0 приложений";

          if (i < showCount) {
            return (
              <FIAInfoItem
                key={i}
                id={id}
                title={name}
                description={appCountStrFormat}
                photo={photo}
                openView={props.openView}
              />
            );
          }

          return false;
        })
      : "";
  };

  return (
    <>
      <HeadTitle>Друзья в приложении</HeadTitle>
      {renderApp()}

      {loading ? <LoaderWithProgressBar progressValue={progressValue} /> : ""}

      {appsInfo !== null && error === null && Object.values(app).length !== 0 ? (
        <Group
          header={<Header mode="secondary">{friendsCountStrFormat}</Header>}
          style={{ marginBottom: "20px" }}
        >
          {renderFriends()}

          {Object.prototype.toString.call(friends) === "[object Array]" &&
          !loading &&
          friends.length <= 0 &&
          error === null ? (
            <Placeholder icon={<Icon56UsersOutline />}>Друзья не найдены</Placeholder>
          ) : (
            ""
          )}
        </Group>
      ) : (
        ""
      )}

      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchInfoApp,
      })}
    </>
  );
};

export default FIAInfoList;
