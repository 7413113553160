import {
  APIEye3EnableTrackOnline,
  APIEye3GetChangesTrackOnline,
  APIEye3GetCompareChanges2TrackOnline,
  APIEye3GetUsersTrackOnline,
  APIEye3SetUsersTrackOnline,
} from "api/eye3";
import { calcAge, convertTime, unixTimeToDate } from "helpers";
import {
  TIME_ONLINE_TRACKING_USER_ERROR,
  TIME_ONLINE_TRACKING_USER_REQUEST,
  TIME_ONLINE_TRACKING_USER_SUCCESS,
  TIME_ONLINE__SET_TRACKING_USER_INFO_ERROR,
  TIME_ONLINE__SET_TRACKING_USER_INFO_REQUEST,
  TIME_ONLINE__SET_TRACKING_USER_INFO_SUCCESS,
  TIME_ONLINE__TRACKING_USER_INFO_ERROR,
  TIME_ONLINE__TRACKING_USER_INFO_REQUEST,
  TIME_ONLINE__TRACKING_USER_INFO_SET_PERIOD,
  TIME_ONLINE__TRACKING_USER_INFO_SHOW_MORE,
  TIME_ONLINE__TRACKING_USER_INFO_SUCCESS,
  TIME_ONLINE__START_TRACKING_REQUEST,
  TIME_ONLINE__START_TRACKING_ERROR,
  TIME_ONLINE__START_TRACKING_SUCCESS,
  TIME_ONLINE__START_TRACKING_SHOW_MORE,
  TIME_ONLINE__START_TRACKING_SEARCH_REQUEST,
  TIME_ONLINE__START_TRACKING_SEARCH_SUCCESS,
  TIME_ONLINE__START_TRACKING_SEARCH_ERROR,
  TIME_ONLINE__START_TRACKING_FILTER_REQUEST,
  TIME_ONLINE__START_TRACKING_FILTER_SUCCESS,
  TIME_ONLINE__START_TRACKING_FILTER_ERROR,
  TIME_ONLINE__START_TRACKING_CHECKED,
  TIME_ONLINE__SET_TRACKING_REQUEST,
  TIME_ONLINE__SET_TRACKING_SUCCESS,
  TIME_ONLINE__SET_TRACKING_ERROR,
  TIME_ONLINE__COMPARE_TIME_ERROR,
  TIME_ONLINE__COMPARE_TIME_REQUEST,
  TIME_ONLINE__COMPARE_TIME_SUCCESS,
  TIME_ONLINE__COMPARE_TIME_INFO_REQUEST,
  TIME_ONLINE__COMPARE_TIME_INFO_SUCCESS,
  TIME_ONLINE__COMPARE_TIME_INFO_ERROR,
  TIME_ONLINE__COMPARE_TIME_INFO_SET_PERIOD,
  TIME_ONLINE__COMPARE_TIME_INFO_SHOW_MORE,
} from "store/constType";
import { getPeriod } from "template/popouts/DateChangePeriod/actions";
import { checkLicenseEYE3Api, checkLimitEYE3Api, delayMethodGetUsersVKApi } from "api";
import { openModal, openView } from "../page";
import {
  MODAL_CARD_TEMPLATE_TARIFF_LIMIT,
  MODAL_CARD_TEMPLATE_TARIFF_WORK,
} from "template/modals/constModals";
import { getSearchFilterFriendsV2 } from "helpers/actions";
import { PANEL_TIME_ONLINE } from "views/tool/panels/constPanels";
import { VIEW_TOOL } from "views/constViews";
import { checkEYE3ApiError, defaultErrorProperty } from "api/eye3/responseHandler";

export function fetchTrackingUser() {
  return async (dispatch) => {
    try {
      dispatch(request());

      let result = [];

      const trackOnlineUser = await APIEye3GetUsersTrackOnline(); // Получить список отслеживаемых пользователей
      //console.log('trackOnlineUser: ', trackOnlineUser);

      if (!trackOnlineUser.status) {
        dispatch(error({ ...defaultErrorProperty, message: "" }));
        return;
      } else if (trackOnlineUser.hasOwnProperty("users") && trackOnlineUser.users.length === 0) {
        dispatch(success(result, trackOnlineUser.allow_count));
        return;
      }

      const joinUsers = trackOnlineUser.users.map((user) => user.vk_id);

      const userInfo = await delayMethodGetUsersVKApi({
        userIds: joinUsers,
        fields: ["photo_100", "bdate", "city", "country", "age", "last_seen", "online"],
      });

      let userItems = userInfo;

      for (let userTrack of trackOnlineUser.users) {
        for (let user of userItems) {
          if (userTrack.vk_id === user.id && !user.hasOwnProperty("deactivated")) {
            result.push({
              vk_id: user.id,
              name: user.first_name + " " + user.last_name,
              age: user.bdate ? calcAge(user.bdate) : null, // Расчет возраста по дате рождения
              city: user.city ? user.city.title : null, // Город
              city_id: user.city ? user.city.id.toString() : null, // Город id
              photo_url: user.photo_100 ? user.photo_100 : "", // Фото
              country: user.country ? user.country.title : null, // Страна
              country_id: user.country ? user.country.id.toString() : null, // Страна id
              last_seen: user.hasOwnProperty("last_seen") && user.last_seen ? user.last_seen : null,
              type: userTrack.type,
              online: user.online,
            });
          }
        }
      }

      dispatch(success(result, trackOnlineUser.allow_count));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: TIME_ONLINE_TRACKING_USER_REQUEST };
  }

  function success(payload, allowCount) {
    return { type: TIME_ONLINE_TRACKING_USER_SUCCESS, payload, allowCount };
  }

  function error(error) {
    return { type: TIME_ONLINE_TRACKING_USER_ERROR, error };
  }
}

export function fetchTrackingUserInfo() {
  return async (dispatch, getState) => {
    try {
      const state = getState().toolTimeOnline;
      const pageParam = getState().page.param;

      const id = pageParam ? pageParam.activeUserId : state.trackingUserInfo.activeUserId;
      const title = pageParam ? pageParam.activeUserTitle : state.trackingUserInfo.activeUserTitle;
      const description = pageParam
        ? pageParam.activeUserDescription
        : state.trackingUserInfo.activeUserDescription;
      const photo = pageParam ? pageParam.activeUserPhoto : state.trackingUserInfo.activeUserPhoto;
      const isTracking = pageParam
        ? pageParam.activeUserIsTracking
        : state.trackingUserInfo.activeUserIsTracking;
      const typeTracking = pageParam
        ? pageParam.activeUserTypeTracking
        : state.trackingUserInfo.activeUserTypeTracking;

      const period = state.trackingUserInfo.period;

      dispatch(request(id, title, description, photo, isTracking, typeTracking));

      const { atMin, atMax } = getPeriod(period);

      let result = await APIEye3GetChangesTrackOnline({
        userId: id,
        startAt: atMin,
        endAt: atMax,
      });

      //console.log('result: ', result);

      // Проверка ошибок в API
      const errorAPI = checkEYE3ApiError(result);
      if (errorAPI !== false) {
        errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
        dispatch(error({ ...errorAPI }));
        return;
      }

      if (result.hasOwnProperty("is_active") && !result.is_active) {
        dispatch(
          error({
            ...defaultErrorProperty,
            message: "Пользователь не активен",
            isVisibleMessage: true,
            isTryAgain: false,
          })
        );

        return;
      } else if (result.hasOwnProperty("new_track") && result.new_track) {
        dispatch(
          error({
            ...defaultErrorProperty,
            message:
              "У пользователя еще не собралась статистика по времени онлайн, так как вы его добавили только сейчас",
            isVisibleMessage: true,
            isTryAgain: false,
          })
        );

        return;
      }

      result = result.hasOwnProperty("online_data") ? result.online_data : null;

      dispatch(success(result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(id, title, description, photo, isTracking, typeTracking) {
    return {
      type: TIME_ONLINE__TRACKING_USER_INFO_REQUEST,
      id,
      title,
      description,
      photo,
      isTracking,
      typeTracking,
    };
  }

  function success(payload) {
    return { type: TIME_ONLINE__TRACKING_USER_INFO_SUCCESS, payload };
  }

  function error(error) {
    return { type: TIME_ONLINE__TRACKING_USER_INFO_ERROR, error };
  }
}

export function setTrackingUserInfo(id, isEnable) {
  return async (dispatch) => {
    try {
      dispatch(request());
      const trackingInfo = await APIEye3EnableTrackOnline({ userId: id, enable: +isEnable });
      //console.log(trackingInfo);

      if (!checkLicenseEYE3Api(trackingInfo)) {
        dispatch(openModal({ id: MODAL_CARD_TEMPLATE_TARIFF_WORK }));
        dispatch(success(0));
        return;
      }

      dispatch(success(+isEnable));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: TIME_ONLINE__SET_TRACKING_USER_INFO_REQUEST };
  }
  function success(isEnable) {
    return { type: TIME_ONLINE__SET_TRACKING_USER_INFO_SUCCESS, isEnable };
  }
  function error(error) {
    return { type: TIME_ONLINE__SET_TRACKING_USER_INFO_ERROR, error };
  }
}

export function setPeriodTrackingUserInfo(name, atMin = null, atMax = null) {
  return { type: TIME_ONLINE__TRACKING_USER_INFO_SET_PERIOD, name, atMin, atMax };
}

export function showMoreTrackingUserInfo() {
  return { type: TIME_ONLINE__TRACKING_USER_INFO_SHOW_MORE };
}

// Получить выбранных (отслеживаемых) пользователей
function getSelectedFriends(selectedItems, getState) {
  let selected = [];

  if (selectedItems === null) {
    selected = getState()
      .toolTimeOnline.trackingUser.payload.filter((user) => user.type === "online")
      .map((user) => user.vk_id);

    //console.log('trackingUser selected: ', selected);
  } else {
    selected = selectedItems;
    //console.log('default selected: ', selected);
  }

  return selected;
}

// Получить отслеживаемых пользователей по тотальной слежке (для исключения из списка)
function getTrackingTotalSurveillanceFriends(getState) {
  let result = [];

  result = getState()
    .toolTimeOnline.trackingUser.payload.filter((user) => user.type === "track")
    .map((user) => user.vk_id);

  return result;
}

function getCallbackResult(usersItems, getState) {
  let result = [];
  let trackingTotalSurveillanceIds = getTrackingTotalSurveillanceFriends(getState);

  //console.log('trackingTotalSurveillanceIds: ', trackingTotalSurveillanceIds);

  usersItems.forEach((user) => {
    if (!trackingTotalSurveillanceIds.includes(user.id) && !user.hasOwnProperty("deactivated")) {
      result.push({
        vk_id: user.id,
        name: user.first_name + " " + user.last_name,
        age: user.bdate ? calcAge(user.bdate) : null, // Расчет возраста по дате рождения
        sex: user.sex ? user.sex.toString() : null,
        city: user.city ? user.city.title : null, // Город
        city_id: user.city ? user.city.id.toString() : null, // Город id
        photo_url: user.photo_100 ? user.photo_100 : "", // Фото
        country: user.country ? user.country.title : null, // Страна
        country_id: user.country ? user.country.id.toString() : null, // Страна id
      });
    }
  });

  return result;
}

// Добавить свойство selected для friends
function addPropertySelected(friends, selected) {
  let result = [];

  //console.log('selectedItems: ', selected);

  for (let friend of friends) {
    let isSelected = false;

    for (let selectedId of selected) {
      if (selectedId === friend.vk_id) {
        isSelected = true;
        break;
      }
    }

    result.push({
      ...friend,
      selected: isSelected,
    });
  }

  return result;
}

export function fetchStartTracking() {
  return async (dispatch, getState) => {
    try {
      dispatch(request());

      const state = getState().toolTimeOnline;

      const selected = getSelectedFriends(state.startTracking.selected, getState);

      const friends = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.startTracking.search,
        filter: state.startTracking.filter,
        cache: state.startTracking.cachePayload,
        callbackResult: (usersItems) => {
          return getCallbackResult(usersItems, getState);
        },
      });

      const res = addPropertySelected(friends, selected);

      //console.log('res: ', res);

      dispatch(success(res, selected));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: TIME_ONLINE__START_TRACKING_REQUEST };
  }

  function success(payload, selected) {
    return { type: TIME_ONLINE__START_TRACKING_SUCCESS, payload, selected };
  }

  function error(error) {
    return { type: TIME_ONLINE__START_TRACKING_ERROR, error };
  }
}

export function showMoreStartTracking() {
  return { type: TIME_ONLINE__START_TRACKING_SHOW_MORE };
}

export function searchStartTracking(search = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(request(search));

      const state = getState().toolTimeOnline;

      const selected = getSelectedFriends(state.startTracking.selected, getState);

      const friends = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.startTracking.search,
        filter: state.startTracking.filter,
        cache: state.startTracking.cachePayload,
        callbackResult: (usersItems) => {
          return getCallbackResult(usersItems, getState);
        },
      });

      const res = addPropertySelected(friends, selected);

      //console.log('res: ', res);

      dispatch(success(res, selected));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(search) {
    return { type: TIME_ONLINE__START_TRACKING_SEARCH_REQUEST, search };
  }

  function success(payload, selected) {
    return { type: TIME_ONLINE__START_TRACKING_SEARCH_SUCCESS, payload, selected };
  }

  function error(error) {
    return { type: TIME_ONLINE__START_TRACKING_SEARCH_ERROR, error };
  }
}

export function filterStartTracking(filter = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(request(filter));

      const state = getState().toolTimeOnline;

      const selected = getSelectedFriends(state.startTracking.selected, getState);

      const friends = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.startTracking.search,
        filter: state.startTracking.filter,
        cache: state.startTracking.cachePayload,
        callbackResult: (usersItems) => {
          return getCallbackResult(usersItems, getState);
        },
      });

      const res = addPropertySelected(friends, selected);

      //console.log('res: ', res);

      dispatch(success(res, selected));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(filter) {
    return { type: TIME_ONLINE__START_TRACKING_FILTER_REQUEST, filter };
  }

  function success(payload, selected) {
    return { type: TIME_ONLINE__START_TRACKING_FILTER_SUCCESS, payload, selected };
  }

  function error(error) {
    return { type: TIME_ONLINE__START_TRACKING_FILTER_ERROR, error };
  }
}

export function checkedStartTracking(value, checked) {
  return { type: TIME_ONLINE__START_TRACKING_CHECKED, value, checked };
}

export function setTracking(ids) {
  return async (dispatch) => {
    try {
      await dispatch(request());
      const tracking = await APIEye3SetUsersTrackOnline({ userIds: ids });
      //console.log('setTracking: ', tracking);

      if (!checkLicenseEYE3Api(tracking)) {
        dispatch(openModal({ id: MODAL_CARD_TEMPLATE_TARIFF_WORK }));
        dispatch(success());
        return;
      } else if (!checkLimitEYE3Api(tracking)) {
        dispatch(openModal({ id: MODAL_CARD_TEMPLATE_TARIFF_LIMIT }));
        dispatch(success());
        return;
      }

      await dispatch(success());

      await dispatch(openView({ view: VIEW_TOOL, panel: PANEL_TIME_ONLINE }));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: TIME_ONLINE__SET_TRACKING_REQUEST };
  }
  function success() {
    return { type: TIME_ONLINE__SET_TRACKING_SUCCESS };
  }
  function error(error) {
    return { type: TIME_ONLINE__SET_TRACKING_ERROR, error };
  }
}

function convertToHourMin(time) {
  let hour = (time / 3600) ^ 0;
  let min = ((time - hour * 3600) / 60) ^ 0;
  //let sec = time - hour * 3600 - min * 60;
  let result = "";

  if (hour > 0) result = `Вместе онлайн ${hour} ч ${min} мин`;
  else result = `Вместе онлайн ${min} мин`;

  return result;
}

export function fetchCompareTime() {
  return async (dispatch, getState) => {
    try {
      const state = getState().toolTimeOnline;
      const pageParam = getState().page.param;

      const id = pageParam ? pageParam.activeUserId : state.trackingUserInfo.activeUserId;
      const title = pageParam ? pageParam.activeUserTitle : state.trackingUserInfo.activeUserTitle;
      const description = pageParam
        ? pageParam.activeUserDescription
        : state.trackingUserInfo.activeUserDescription;
      const photo = pageParam ? pageParam.activeUserPhoto : state.trackingUserInfo.activeUserPhoto;

      dispatch(request(id, title, description, photo));

      let result = [];

      const apiData = await APIEye3GetUsersTrackOnline({ userId: id });
      //console.log('apiData: ', apiData);

      // Проверка ошибок в API
      const errorAPI = checkEYE3ApiError(apiData);
      if (errorAPI !== false) {
        errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
        dispatch(error({ ...errorAPI }));
        return;
      }

      if (!apiData.status) {
        dispatch(error({ ...defaultErrorProperty, message: "" }));
        return;
      } else if (apiData.users.length === 0) {
        dispatch(success(result));
        return;
      }

      const usersIds = apiData.users.map((user) => user.vk_id);

      const userInfo = await delayMethodGetUsersVKApi({
        userIds: usersIds,
        fields: ["photo_100"],
      });

      //console.log('userInfo: ', userInfo);

      for (let apiUser of apiData.users) {
        const time = convertToHourMin(apiUser.seconds);

        for (let userItem of userInfo) {
          if (apiUser.vk_id === userItem.id && apiUser.vk_id !== id) {
            //console.log(userItem.first_name + ' ' + userItem.last_name + ': ' + apiUser.seconds);

            result.push({
              vk_id: userItem.id,
              name: userItem.first_name + " " + userItem.last_name,
              photo_url: userItem.photo_100 ? userItem.photo_100 : "",
              time: time,
            });

            break;
          }
        }
      }

      dispatch(success(result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(id, title, description, photo) {
    return { type: TIME_ONLINE__COMPARE_TIME_REQUEST, id, title, description, photo };
  }

  function success(payload) {
    return { type: TIME_ONLINE__COMPARE_TIME_SUCCESS, payload };
  }

  function error(error) {
    return { type: TIME_ONLINE__COMPARE_TIME_ERROR, error };
  }
}

export function fetchCompareTimeInfo() {
  return async (dispatch, getState) => {
    try {
      const state = getState().toolTimeOnline;
      const pageParam = getState().page.param;

      let result = [];
      const compareUsers = pageParam ? pageParam : state.compareTimeInfo.compareUsers;
      const period = state.compareTimeInfo.period;

      dispatch(request(compareUsers));

      const { atMin, atMax } = getPeriod(period);

      const compareRes = await APIEye3GetCompareChanges2TrackOnline({
        userId1: compareUsers.one.id,
        userId2: compareUsers.two.id,
        startAt: atMin,
        endAt: atMax,
      });

      //console.log('compareRes: ', compareRes);

      // Проверка ошибок в API
      const errorAPI = checkEYE3ApiError(compareRes);
      if (errorAPI !== false) {
        errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
        dispatch(error({ ...errorAPI }));
        return;
      }

      if (!compareRes.status) {
        dispatch(error({ ...defaultErrorProperty, message: "" }));
        return;
      } else if (compareRes.stats.length === 0) {
        dispatch(success(result));
        return;
      }

      function convertTimePeriod(periodStart, periodEnd) {
        periodStart = unixTimeToDate(periodStart);
        periodStart = convertTime(periodStart);
        periodEnd = unixTimeToDate(periodEnd);
        periodEnd = convertTime(periodEnd);

        return `${periodStart} - ${periodEnd}`;
      }

      for (let item of compareRes.stats) {
        result.push({
          period_user_1: convertTimePeriod(
            item.user_id_1_period.period_start,
            item.user_id_1_period.period_end
          ),
          period_user_1_is_mobile: item.user_id_1_period.period_is_mobile,
          period_user_2: convertTimePeriod(
            item.user_id_2_period.period_start,
            item.user_id_2_period.period_end
          ),
          period_user_2_is_mobile: item.user_id_2_period.period_is_mobile,
          period_compare: convertTimePeriod(item.period_compare.start, item.period_compare.end),
          period_compare_end: item.period_compare.end,
        });
      }

      dispatch(success(result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(compareUsers) {
    return { type: TIME_ONLINE__COMPARE_TIME_INFO_REQUEST, compareUsers };
  }

  function success(payload) {
    return { type: TIME_ONLINE__COMPARE_TIME_INFO_SUCCESS, payload };
  }

  function error(error) {
    return { type: TIME_ONLINE__COMPARE_TIME_INFO_ERROR, error };
  }
}

export function setPeriodCompareTimeInfo(name, atMin = null, atMax = null) {
  return { type: TIME_ONLINE__COMPARE_TIME_INFO_SET_PERIOD, name, atMin, atMax };
}

export function showMoreCompareTimeInfo() {
  return { type: TIME_ONLINE__COMPARE_TIME_INFO_SHOW_MORE };
}
