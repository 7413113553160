import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import SFSearchResultList from "./components/SFSearchResultList/SFSearchResultList";
import { Panel } from "@vkontakte/vkui";
import { withRouter } from "hoc";
const PanelSFSearchResult = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Фильтр подписчиков</PanelHeaderWithPath>
      <SFSearchResultList openView={props.openView} />
    </Panel>
  );
};

export default withRouter(PanelSFSearchResult);
