import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Search, Group, Placeholder } from "@vkontakte/vkui";
import AOtherUserItem from "../AOtherUserItem/AOtherUserItem";
import Icon24Filter from "@vkontakte/icons/dist/24/filter";
import Icon56UsersOutline from "@vkontakte/icons/dist/56/users_outline";

import {
  fetchOtherUserActivity,
  searchOtherUserActivity,
  filterOtherUserActivity,
  showMoreOtherUserActivity,
} from "store/actions/tool/activity";

import { declOfNum } from "helpers";
import { MODAL_PAGE_TEMPLATE_FILTER_SEX_AGE_CITY } from "template/modals/constModals";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import HeadTitle from "template/components/HeadTitle/HeadTitle";
import { useDebounceSearch, useShowMore } from "hooks";
import SecondaryTitle from "template/components/SecondaryTitle/SecondaryTitle";

const AOtherUserList = (props) => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const loading = useSelector((state) => state.toolActivity.otherUsers.loading);
  const search = useSelector((state) => state.toolActivity.otherUsers.search);
  const filter = useSelector((state) => state.toolActivity.otherUsers.filter);
  const isOnce = useSelector((state) => state.toolActivity.otherUsers.isOnce);
  const error = useSelector((state) => state.toolActivity.otherUsers.error);
  const showCount = useSelector((state) => state.toolActivity.otherUsers.showCount);
  const otherUsers = useSelector((state) => state.toolActivity.otherUsers.payload);

  const [searchTerm, setSearchTerm] = useState(search);

  const handleFetchOtherUserActivity = useCallback(
    () => dispatch(fetchOtherUserActivity()),
    [dispatch]
  );
  const handleSearchOtherUserActivity = useCallback(
    (search) => dispatch(searchOtherUserActivity(search)),
    [dispatch]
  );
  const handleFilterOtherUserActivity = useCallback(
    (filter) => dispatch(filterOtherUserActivity(filter)),
    [dispatch]
  );
  const handleShowMoreOtherUserActivity = useCallback(
    () => dispatch(showMoreOtherUserActivity()),
    [dispatch]
  );

  const renderOtherUserList = () => {
    return otherUsers.length > 0 ? (
      otherUsers.map((otherUser, i) => {
        const id = otherUser.vk_id;
        const name = otherUser.name;
        const sex = otherUser.sex;
        const city = otherUser.city ? otherUser.city : "";
        const age = otherUser.age ? otherUser.age : "";
        const photo = otherUser.photo_url ? otherUser.photo_url : "";
        const ageStrFormat = age ? age + " " + declOfNum(age, ["год", "года", "лет"]) : "";
        const comma = ageStrFormat && city ? ", " : "";

        if (i < showCount) {
          return (
            <AOtherUserItem
              key={i}
              id={id}
              sex={sex}
              title={name}
              description={ageStrFormat + comma + city}
              photo={photo}
              openView={props.openView}
            />
          );
        }

        return false;
      })
    ) : (
      <Placeholder icon={<Icon56UsersOutline />}>Друзья не найдены</Placeholder>
    );
  };

  useShowMore({
    items: otherUsers,
    showCount: showCount,
    maxLength: otherUsers !== null ? otherUsers.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMoreOtherUserActivity,
  });

  useEffect(() => {
    if (!isOnce) handleFetchOtherUserActivity();
  }, [handleFetchOtherUserActivity, isOnce]);

  useDebounceSearch({
    search: search,
    searchTerm: searchTerm,
    handler: handleSearchOtherUserActivity,
  });

  const handleFilter = () => {
    if (loading) return;

    props.openModal({
      id: MODAL_PAGE_TEMPLATE_FILTER_SEX_AGE_CITY,
      param: {
        filter: filter,
        closeModal: props.closeModal,
        applyFilter: handleFilterOtherUserActivity,
      },
    });
  };

  return (
    <div>
      <HeadTitle>Активности у пользователя</HeadTitle>
      <SecondaryTitle>
        Выберите пользователя из списка друзей или воспользуйтесь глобальным поиском
      </SecondaryTitle>
      <Search
        value={searchTerm}
        icon={<Icon24Filter />}
        placeholder={"Имя или ссылка на страницу"}
        onIconClick={handleFilter}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <Group style={{ marginBottom: "20px" }}>
        {otherUsers !== null && error === null ? renderOtherUserList() : ""}
      </Group>
      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchOtherUserActivity,
      })}
    </div>
  );
};

export default AOtherUserList;
