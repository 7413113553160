import { queryEye3 } from "api";

async function APIEye3GetGiftsTool({ userId, timeMin = null, timeMax = null }) {
  try {
    const response = await queryEye3({
      method: "tool.get_gifts",
      get: { user_id: userId, time_min: timeMin, time_max: timeMax },
      access: { isBotConnect: true, isLicense: true, isLimit: true },
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3GetGiftsTool;
