import {
  MARITAL_REQUEST,
  MARITAL_SUCCESS,
  MARITAL_ERROR,
  MARITAL_SEARCH_REQUEST,
  MARITAL_SEARCH_SUCCESS,
  MARITAL_SEARCH_ERROR,
  MARITAL_SHOW_MORE,
  MARITAL_FILTER_ERROR,
  MARITAL_FILTER_SUCCESS,
  MARITAL_FILTER_REQUEST,
  MARITAL__INFO_REQUEST,
  MARITAL__INFO_SUCCESS,
  MARITAL__INFO_ERROR,
  MARITAL_SET_RELATION_BACKGROUND,
  MARITAL__GEO_SETTINGS_REQUEST,
  MARITAL__GEO_SETTINGS_SUCCESS,
  MARITAL__GEO_SETTINGS_ERROR,
  MARITAL__GEO_SEARCH_REQUEST,
  MARITAL__GEO_SEARCH_SUCCESS,
  MARITAL__GEO_SEARCH_ERROR,
  MARITAL__GEO_SEARCH_SHOW_MORE,
} from "../../constType";

const initialState = {
  friends: {
    payload: null,
    cachePayload: null,
    showCount: 20,
    filter: "",
    isOnce: false,
    search: "",
    error: null,
    loading: false,
  },
  marital: {
    payload: null,
    activeUserId: null, // Параметры для сохранения пользователя
    activeUserDescription: null,
    activeUserTitle: null,
    activeUserPhoto: null,
    activeUserSex: null,
    error: null,
    loading: false,
  },
  geoSettings: {
    payload: null,
    loading: null,
    error: null,
  },
  geoSearch: {
    payload: null,
    loading: null,
    showCount: 20,
    error: null,
  },
};

export default function maritalReducer(state = initialState, action) {
  switch (action.type) {
    case MARITAL_REQUEST:
      return {
        ...state,
        friends: {
          ...state.friends,
          showCount: 20,
          payload: null,
          cachePayload:
            state.friends.search === "" && state.friends.filter === ""
              ? null
              : state.friends.cachePayload,
          isOnce: true,
          loading: true,
          error: null,
        },
      };
    case MARITAL_SUCCESS:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: action.payload,
          cachePayload:
            state.friends.search === "" && state.friends.filter === ""
              ? action.payload
              : state.friends.cachePayload,
          loading: false,
          error: null,
        },
      };
    case MARITAL_ERROR:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          error: action.error,
        },
      };
    case MARITAL_SHOW_MORE:
      return {
        ...state,
        friends: {
          ...state.friends,
          showCount: parseInt(state.friends.showCount) + 20,
        },
      };
    case MARITAL_SEARCH_REQUEST:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: null,
          showCount: 20,
          search: action.search,
          loading: true,
          error: null,
        },
      };
    case MARITAL_SEARCH_SUCCESS:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: action.payload,
          loading: false,
        },
      };
    case MARITAL_SEARCH_ERROR:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          error: action.error,
        },
      };
    case MARITAL_FILTER_REQUEST:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: null,
          showCount: 20,
          filter: action.filter,
          loading: true,
          error: null,
        },
      };
    case MARITAL_FILTER_SUCCESS:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          error: null,
          payload: action.payload,
        },
      };
    case MARITAL_FILTER_ERROR:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          error: action.error,
        },
      };
    case MARITAL__INFO_REQUEST:
      return {
        ...state,
        marital: {
          ...state.marital,
          payload: null,
          activeUserId: action.id,
          activeUserDescription: action.description,
          activeUserTitle: action.title,
          activeUserPhoto: action.photo,
          activeUserSex: action.sex,
          loading: true,
          error: null,
        },
      };
    case MARITAL__INFO_SUCCESS:
      return {
        ...state,
        marital: {
          ...state.marital,
          payload: action.payload,
          loading: false,
        },
      };
    case MARITAL__INFO_ERROR:
      return {
        ...state,
        marital: {
          ...state.marital,
          loading: false,
          error: action.error,
        },
      };
    case MARITAL_SET_RELATION_BACKGROUND:
      return {
        ...state,
      };
    case MARITAL__GEO_SETTINGS_REQUEST:
      return {
        ...state,
        geoSettings: {
          ...state.geoSettings,
          loading: true,
          payload: null,
          error: null,
        },
      };
    case MARITAL__GEO_SETTINGS_SUCCESS:
      return {
        ...state,
        geoSettings: {
          ...state.geoSettings,
          loading: false,
          payload: action.payload,
        },
      };
    case MARITAL__GEO_SETTINGS_ERROR:
      return {
        ...state,
        geoSettings: {
          ...state.geoSettings,
          loading: false,
          error: action.error,
        },
      };
    case MARITAL__GEO_SEARCH_REQUEST:
      return {
        ...state,
        geoSearch: {
          ...state.geoSearch,
          loading: true,
          payload: null,
          error: null,
        },
      };
    case MARITAL__GEO_SEARCH_SUCCESS:
      return {
        ...state,
        geoSearch: {
          ...state.geoSearch,
          loading: false,
          payload: action.payload,
        },
      };
    case MARITAL__GEO_SEARCH_ERROR:
      return {
        ...state,
        geoSearch: {
          ...state.geoSearch,
          loading: false,
          error: action.error,
        },
      };
    case MARITAL__GEO_SEARCH_SHOW_MORE:
      return {
        ...state,
        geoSearch: {
          ...state.geoSearch,
          showCount: parseInt(state.geoSearch.showCount) + 20,
        },
      };
    default:
      return state;
  }
}
