import config from "../config";
import querystring from "query-string";
import { delay } from "../helpers";
import apiEye3 from "./axios";
import { VkBridgeSend } from "api/vk";
import { responseHandler } from "./eye3/responseHandler";
import { APIEye3VkApiUser } from "./eye3";

let vkToken = null;

//
export const getSearchObject = () => {
  return querystring.parse(window.location.search);
};

const vkScope =
  getSearchObject().vk_access_token_settings &&
  getSearchObject().vk_access_token_settings !== "" &&
  getSearchObject().vk_access_token_settings !== null
    ? getSearchObject().vk_access_token_settings.split(",")
    : [];

export const setVKToken = (response) => {
  vkToken = response.access_token;
};

export const getVKToken = () => {
  return vkToken;
};

export const getVKScope = () => {
  return vkScope;
};

export const checkVKMethodError = (method) => {
  if (method.hasOwnProperty("error_type")) {
    return true;
  }

  return false;
};

export const getVKApiVersion = () => {
  return config.vkApiVersion;
};

// Добавление разрешений
export const addVKScope = async (newScope) => {
  if (getVKScope().includes(newScope)) {
    // Если разрешение уже есть
    return true;
  }

  const payloadVKToken = await VkBridgeSend("VKWebAppGetAuthToken", {
    app_id: parseInt(getSearchObject().vk_app_id),
    scope: getVKScope().concat(newScope),
  });

  if (payloadVKToken.hasOwnProperty("access_token")) {
    // Токен получен
    setVKToken(payloadVKToken);
    return true;
  }

  return false;
};

// Функция для проверки license
export const checkLicenseEYE3Api = (res) => {
  // Если являеться объектом
  return !(
    res !== null &&
    typeof res === "object" &&
    res.hasOwnProperty("status") &&
    res.hasOwnProperty("license") &&
    res.status === false &&
    res.license === false
  );
};

// Функция для проверки limit
export const checkLimitEYE3Api = (res) => {
  // Если являеться объектом
  return !(
    res !== null &&
    typeof res === "object" &&
    res.hasOwnProperty("status") &&
    res.hasOwnProperty("limit") &&
    res.status === false &&
    res.limit === false
  );
};

export const parseQuery = (params) => {
  return Object.entries(params)
    .map((item) => item.join("="))
    .join("&");
};

export const buildFormData = (data) => {
  let formData = new FormData();

  for (let key in data) {
    formData.set(key, data[key]);
  }

  return formData;
};

export const buildQuery = (params) => {
  let clearData = {};

  Object.entries(params).forEach(([key, value]) => {
    if (value === undefined || value === null) return;

    clearData[key] = value;
  });

  return parseQuery({
    ...clearData,
  });
};

// Новый метод для обращения к API
export const queryEye3 = async ({ method, get, post, access }) => {
  try {
    const getWithSearch = { ...get, ...getSearchObject() };

    const query = `?${buildQuery(getWithSearch)}`;
    let response = "";

    if (post !== null) {
      const postData = buildFormData({ ...post });
      response = await apiEye3.post(`${method}${query}`, postData);
    } else {
      response = await apiEye3.get(`${method}${query}`);
    }

    console.log(response.data);

    if (process.env.NODE_ENV === "development") {
      console.log("(eye3 " + response.status + "): " + config.eye3ApiHost + method + query);
    }

    return responseHandler(method, response.data, access);
  } catch (e) {
    throw e;
  }
};

export const isEye3VkMethodError = (method) => {
  if (method === null) return true;
  else if (method.result.hasOwnProperty("error")) return true;

  return false;
};

// Функция задержки для получения больше 900 пользователей от VK API
export const delayMethodGetUsersVKApi = async ({ userIds, fields, count = 900 }) => {
  try {
    let result = [];

    if (userIds.length === 0) return result;

    let prepareUserIds = userIds.filter((item) => item > 0);

    let ids = prepareUserIds.reduce(function (result, value, index, array) {
      if (index % count === 0) result.push(array.slice(index, index + count));

      return result;
    }, []);

    let maxCount = ids.length;
    let index = 0;

    if (maxCount === 0) return result;

    const part = async (ids) => {
      const usersAPI = await APIEye3VkApiUser({
        method: "users.get",
        params: {
          user_ids: ids[index].join(","),
          fields: fields.join(","),
        },
      });

      if (isEye3VkMethodError(usersAPI)) {
        throw JSON.stringify(usersAPI);
      }

      /*const usersAPI = await APIVkGetUsers({
        userIds: ids[index].join(","),
        fields: fields.join(","),
      });

      if (checkVKMethodError(usersAPI)) {
        throw JSON.stringify(usersAPI);
      }*/

      const usersItem = usersAPI.result.response;

      index++;

      for (let user of usersItem) {
        result.push(user);
      }

      if (userIds.length > count) await delay(400);
      if (maxCount > index) await part(ids);

      // console.log("userAPI: ", userAPI);
    };

    await part(ids);

    return result;
  } catch (e) {
    throw new Error("GetUsersVKApi::: " + e);
    // throw e;
  }
};

// Функция задержки для получения больше 500 групп от VK API
export const delayMethodGetByIdGroupsVKApi = async ({ groupIds, fields = [], count = 490 }) => {
  try {
    let result = [];

    let items = groupIds.reduce(function (result, value, index, array) {
      if (index % count === 0) result.push(array.slice(index, index + count));

      return result;
    }, []);

    let maxCount = items.length;
    let index = 0;

    if (maxCount === 0) return result;

    const part = async (ids) => {
      /*const groupInfo = await APIVkGetByIdGroups({
        groupIds: ids !== null && ids.length !== 0 ? ids[index].join(",") : "",
        fields: fields.join(","),
      });*/

      const groupsAPI = await APIEye3VkApiUser({
        method: "groups.getById",
        params: {
          group_ids: ids[index].join(","),
          fields: fields.join(","),
        },
      });

      if (isEye3VkMethodError(groupsAPI)) {
        throw JSON.stringify(groupsAPI);
      }

      //console.log("groupsAPI: ", groupsAPI);

      const groupsItem = groupsAPI.result.response;

      // console.log("groupsItem: ", groupsItem);

      index++;

      for (let group of groupsItem) {
        result.push(group);
      }

      if (groupIds.length > count) await delay(500);
      if (maxCount > index) await part(ids);
    };

    await part(items);

    return result;
  } catch (e) {
    throw new Error("GetByIdGroupsVKApi::: " + e);
    // throw e;
  }
};
