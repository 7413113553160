import { Div } from "@vkontakte/vkui";
import React from "react";
import AffiliateBalance from "../../../../components/AffiliateBalance/AffiliateBalance";
import AffiliateLink from "../AffiliateLink/AffiliateLink";
//  import { MODAL_CARD_MAKE_MONEY_WITH_CRUSH } from "views/myProfile/modals/constModals";
// import AffiliatePromo from "../AffiliatePromo/AffiliatePromo";
import styles from "./AffiliateProgram.module.scss";

const AffiliateProgram = (props) => {
  return (
    <Div style={{ paddingTop: "0px" }}>
      <div className={styles.container}>
        <p className={styles.title}>Партнёрская программа</p>
        <p className={styles.subtitle}>Зарабатывай 50% с привлеченного клиента</p>
        <AffiliateBalance isWithdrawal={true} openView={props.openView} />
        <AffiliateLink />
        {/*<AffiliatePromo openView={props.openView} />*/}
        {/*<div>
          <Button
            mode="tertiary"
            className={styles.button}
            onClick={() => props.openModal({ id: MODAL_CARD_MAKE_MONEY_WITH_CRUSH })}
          >
            Подробнее о партнёрской программе
          </Button>
        </div>*/}
      </div>
    </Div>
  );
};

export default AffiliateProgram;
