import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Panel } from "@vkontakte/vkui";
import AUserList from "./components/AUserList/AUserList";
import { withRouter } from "hoc";

const PanelAUser = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Активности</PanelHeaderWithPath>
      <AUserList
        openView={props.openView}
        openModal={props.openModal}
        closeModal={props.closeModal}
      />
    </Panel>
  );
};

export default withRouter(PanelAUser);
