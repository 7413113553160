import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Group, Header, Placeholder } from "@vkontakte/vkui";
import Icon56UsersOutline from "@vkontakte/icons/dist/56/users_outline";
import SettingNotificationItem from "../TSSettingNotificationItem/TSSettingNotificationItem";

import { declOfNum } from "helpers";
import { setNotification } from "store/actions/tool/totalSurveillance";

const TSSettingNotificationList = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.toolTotalSurveillance.settingNotification.payload);

  const handleSetNotification = useCallback(
    (id, isEnable) => dispatch(setNotification(id, isEnable)),
    [dispatch]
  );

  const renderSettingNotification = () => {
    return users.length > 0 ? (
      users.map((user, i) => {
        const id = user.vk_id;
        const name = user.name;
        const city = user.city ? user.city : "";
        const age = user.age ? user.age : "";
        const photo = user.photo_url ? user.photo_url : "";
        const isNotification = user.is_notification ? user.is_notification : false;

        const ageStrFormat = age ? age + " " + declOfNum(age, ["год", "года", "лет"]) : "";
        const comma = ageStrFormat && city ? ", " : "";

        return (
          <SettingNotificationItem
            id={id}
            key={i}
            title={name}
            description={ageStrFormat + comma + city}
            photo={photo}
            handleSetNotification={handleSetNotification}
            isNotification={isNotification}
          />
        );
      })
    ) : (
      <Placeholder icon={<Icon56UsersOutline />}>Отслеживаемые пользователи не найдены</Placeholder>
    );
  };

  return (
    <>
      <Group
        header={<Header mode="secondary">Уведомления от пользователей</Header>}
        className="start-tracking-list"
      >
        {users !== null && renderSettingNotification()}
      </Group>
    </>
  );
};

export default TSSettingNotificationList;
