import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Search, Group, Placeholder } from "@vkontakte/vkui";
import AUserItem from "../AUserItem/AUserItem";
import Icon24Filter from "@vkontakte/icons/dist/24/filter";
import Icon56UsersOutline from "@vkontakte/icons/dist/56/users_outline";

import {
  fetchUserActivity,
  searchUserActivity,
  filterUserActivity,
  showMoreUserActivity,
} from "store/actions/tool/activity";

import { declOfNum } from "helpers";
import { MODAL_PAGE_TEMPLATE_FILTER_SEX_AGE_CITY } from "template/modals/constModals";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import HeadTitle from "template/components/HeadTitle/HeadTitle";
import { useDebounceSearch, useShowMore } from "hooks";
import SecondaryTitle from "template/components/SecondaryTitle/SecondaryTitle";

const AUserList = (props) => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const loading = useSelector((state) => state.toolActivity.users.loading);
  const search = useSelector((state) => state.toolActivity.users.search);
  const filter = useSelector((state) => state.toolActivity.users.filter);
  const isOnce = useSelector((state) => state.toolActivity.users.isOnce);
  const error = useSelector((state) => state.toolActivity.users.error);
  const showCount = useSelector((state) => state.toolActivity.users.showCount);
  const users = useSelector((state) => state.toolActivity.users.payload);

  const [searchTerm, setSearchTerm] = useState(search);

  const handleFetchUserActivity = useCallback(() => dispatch(fetchUserActivity()), [dispatch]);
  const handleSearchUserActivity = useCallback(
    (search) => dispatch(searchUserActivity(search)),
    [dispatch]
  );
  const handleFilterUserActivity = useCallback(
    (filter) => dispatch(filterUserActivity(filter)),
    [dispatch]
  );
  const handleShowMoreUserActivity = useCallback(
    () => dispatch(showMoreUserActivity()),
    [dispatch]
  );

  const renderUserList = () => {
    return users.length > 0 ? (
      users.map((user, i) => {
        const id = user.vk_id;
        const name = user.name;
        const sex = user.sex;
        const city = user.city ? user.city : "";
        const age = user.age ? user.age : "";
        const photo = user.photo_url ? user.photo_url : "";
        const ageStrFormat = age ? age + " " + declOfNum(age, ["год", "года", "лет"]) : "";
        const comma = ageStrFormat && city ? ", " : "";

        if (i < showCount) {
          return (
            <AUserItem
              key={i}
              id={id}
              sex={sex}
              title={name}
              description={ageStrFormat + comma + city}
              photo={photo}
              openView={props.openView}
            />
          );
        }

        return false;
      })
    ) : (
      <Placeholder icon={<Icon56UsersOutline />}>Друзья не найдены</Placeholder>
    );
  };

  useShowMore({
    items: users,
    showCount: showCount,
    maxLength: users !== null ? users.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMoreUserActivity,
  });

  useEffect(() => {
    if (!isOnce) handleFetchUserActivity();
  }, [handleFetchUserActivity, isOnce]);

  useDebounceSearch({
    search: search,
    searchTerm: searchTerm,
    handler: handleSearchUserActivity,
  });

  const handleFilter = () => {
    if (loading) return;

    props.openModal({
      id: MODAL_PAGE_TEMPLATE_FILTER_SEX_AGE_CITY,
      param: {
        filter: filter,
        closeModal: props.closeModal,
        applyFilter: handleFilterUserActivity,
      },
    });
  };

  return (
    <div>
      <HeadTitle>Активности пользователя</HeadTitle>
      <SecondaryTitle>
        Выберите пользователя из списка друзей или воспользуйтесь глобальным поиском
      </SecondaryTitle>
      <Search
        value={searchTerm}
        icon={<Icon24Filter />}
        placeholder={"Имя или ссылка на страницу"}
        onIconClick={() => handleFilter()}
        onChange={(e) => {
          if (loading) return;

          setSearchTerm(e.target.value);
        }}
      />
      <Group style={{ marginBottom: "20px" }}>
        {users !== null && error === null ? renderUserList() : ""}
      </Group>
      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchUserActivity,
      })}
    </div>
  );
};

export default AUserList;
