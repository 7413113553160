import React from "react";
import { Avatar, Link, SimpleCell } from "@vkontakte/vkui";
import Icon28ArrowLeftOutline from "@vkontakte/icons/dist/28/arrow_left_outline";
import Icon28ArrowRightOutline from "@vkontakte/icons/dist/28/arrow_right_outline";
import styles from "./GInfoItem.module.css";

const GIFT_OUT = "out";
const GIFT_IN = "in";

const GInfoItem = (props) => {
  const getTypeText = () => {
    switch (props.type) {
      case GIFT_IN:
        return getTypeSex() + " подарок от ";
      case GIFT_OUT:
        return getTypeSex() + " подарок";
      default:
        return "";
    }
  };

  const getTypeSex = () => {
    if (props.type === GIFT_IN) {
      switch (props.sex) {
        case "1":
          return "Получила";
        case "2":
          return "Получил";
        default:
          return "Получил(а)";
      }
    } else if (props.type === GIFT_OUT) {
      switch (props.sex) {
        case "1":
          return "Отправила";
        case "2":
          return "Отправил";
        default:
          return "Отправил(а)";
      }
    }
  };

  const getTypeIcon = () => {
    switch (props.type) {
      case GIFT_IN:
        return (
          <Avatar size={48} src={props.photoUser}>
            <div className={styles.in}>
              <Icon28ArrowLeftOutline width={12} height={12} fill="var(--white)" />
            </div>
          </Avatar>
        );
      case GIFT_OUT:
        return (
          <Avatar size={48} src={props.photoUser}>
            <div className={styles.out}>
              <Icon28ArrowRightOutline width={12} height={12} fill="var(--white)" />
            </div>
          </Avatar>
        );
      default:
        return "";
    }
  };

  return (
    <SimpleCell
      disabled
      before={getTypeIcon()}
      after={<img src={props.photoGift} width={48} height={48} alt=""></img>}
      description={"в " + props.time}
      multiline
    >
      <span style={{ color: "var(--text_secondary)" }}>{getTypeText()} </span>
      <Link href={"https://vk.com/id" + props.id} target="_blank">
        {" "}
        {props.title}{" "}
      </Link>{" "}
      {props.text ? "«" + props.text + "»" : ""}
    </SimpleCell>
  );
};

export default GInfoItem;
