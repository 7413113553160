import React from "react";
import { Banner } from "@vkontakte/vkui";
import PopularIcon from "../../../../../../assets/img/tool/popularSubscriber/banner/popularSubscriber.png";

const PSBanner = () => {
  return (
    <Banner
      size="m"
      mode="image"
      asideMode="dismiss"
      imageTheme="dark"
      background={
        <div
          style={{
            background: "radial-gradient(109.55% 109.55% at 0% 0%, #358DFF 0%, #0336FF 100%",
          }}
        ></div>
      }
      before={<img width={25} height={28} src={PopularIcon} alt="" />}
      text={
        <span style={{ fontSize: "16px" }}>
          Узнавайте кто из подписчиков ваших сообществ самый популярный
        </span>
      }
    />
  );
};

export default PSBanner;
