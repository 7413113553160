import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import WhoHiddenMeItem from "../WhoHiddenMeItem/WhoHiddenMeItem";
import WhoHiddenInfo from "../../../components/WHInfo/WHInfo";

import { cancelWhoHiddenMe, fetchWhoHiddenMe } from "store/actions/tool/whoHidden";

import { declOfNum } from "helpers";
import LoaderWithProgressBar from "template/components/LoaderWithProgressBar/LoaderWithProgressBar";
import { renderErrorPlaceholder } from "helpers/views";
import HeadTitle from "template/components/HeadTitle/HeadTitle";

const WhoHiddenMeList = (props) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.toolWhoHidden.whoHiddenMe.loading);
  const error = useSelector((state) => state.toolWhoHidden.whoHiddenMe.error);
  const hiddenUsers = useSelector((state) => state.toolWhoHidden.whoHiddenMe.payload);
  const progressValue = useSelector((state) => state.toolWhoHidden.whoHiddenMe.progressValue);
  const handleFetchWhoHiddenMe = useCallback(() => dispatch(fetchWhoHiddenMe()), [dispatch]);
  const handleCancelWhoHiddenMe = useCallback(() => dispatch(cancelWhoHiddenMe()), [dispatch]);

  useEffect(() => {
    return () => {
      handleCancelWhoHiddenMe();
    };
  }, [handleCancelWhoHiddenMe]);

  useEffect(() => {
    handleFetchWhoHiddenMe();
  }, [handleFetchWhoHiddenMe]);

  const renderHiddenUserList = () => {
    return hiddenUsers.length > 0
      ? hiddenUsers.map((user, i) => {
          const id = user.vk_id;
          const name = user.name;
          const city = user.city ? user.city : "";
          const age = user.age ? user.age : "";
          const photo = user.photo_url ? user.photo_url : "";

          const ageStrFormat = age ? age + " " + declOfNum(age, ["год", "года", "лет"]) : "";
          const comma = ageStrFormat && city ? ", " : "";

          return (
            <WhoHiddenMeItem
              key={i}
              id={id}
              title={name}
              description={ageStrFormat + comma + city}
              photo={photo}
              openView={props.openView}
            />
          );
        })
      : "";
  };

  return (
    <>
      <HeadTitle>Кто скрыл меня</HeadTitle>
      {hiddenUsers !== null && error === null ? renderHiddenUserList() : ""}
      {loading ? <LoaderWithProgressBar progressValue={progressValue} /> : ""}
      {Object.prototype.toString.call(hiddenUsers) === "[object Array]" &&
      !loading &&
      hiddenUsers.length <= 0 &&
      error === null ? (
        <WhoHiddenInfo description="Вас не скрыл ни один пользователь" />
      ) : (
        ""
      )}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchWhoHiddenMe,
      })}
    </>
  );
};

export default WhoHiddenMeList;
