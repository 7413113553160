import {
  Button,
  Div,
  FormLayout,
  FormLayoutGroup,
  FormStatus,
  Input,
  Panel,
} from "@vkontakte/vkui";
import { withRouter } from "hoc";
import * as Yup from "yup";
import { Formik } from "formik";
import React, { useCallback } from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import AffiliateBalance from "views/myProfile/components/AffiliateBalance/AffiliateBalance";
import { VIEW_MY_PROFILE } from "views/constViews";
import { PANEL_AFFILIATE_WITHDRAW_TO_COIN } from "../constPanels";
import styles from "./index.module.scss";
import { useDispatch } from "react-redux";
import { clsWithdraw, withdrawToMoney } from "store/actions/partner";
import { useSelector } from "react-redux";

const PanelAffiliateWithdrawToMoney = (props) => {
  const dispatch = useDispatch();
  const payload = useSelector((state) => state.partner.withdrawToMoney.payload);
  const loading = useSelector((state) => state.partner.withdrawToMoney.loading);
  const error = useSelector((state) => state.partner.withdrawToMoney.error);
  const errorMessage = error !== null && typeof error === "object" ? error.message : "";
  const isVisibleMessage =
    error !== null && typeof error === "object" ? error.isVisibleMessage : false;

  const handleWithdrawToMoney = useCallback(
    (card, sum) => {
      dispatch(withdrawToMoney(card, sum));
    },
    [dispatch]
  );

  const handleClsWithdraw = useCallback(() => {
    dispatch(clsWithdraw());
  }, [dispatch]);

  const handleChangeOnlyNumber = (e, fieldName, setFieldValue) => {
    e.preventDefault();

    if (error !== null || payload !== null) handleClsWithdraw();

    const { value } = e.target;
    const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
    if (!value || regex.test(value.toString())) {
      setFieldValue(fieldName, value);
    }
  };

  const handleChangeCardNumber = (e, fieldName, setFieldValue) => {
    e.preventDefault();
    if (error !== null || payload !== null) handleClsWithdraw();

    const { value } = e.target;
    const regex = /^(?=.*\d)[\d ]+$/;
    if (!value || regex.test(value.toString())) {
      setFieldValue(fieldName, value);
    }
  };

  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack} isVisibleBalance={false}>
        Вывод средств
      </PanelHeaderWithPath>
      <Div>
        <AffiliateBalance style={{ marginBottom: "0px" }} />
      </Div>
      <Formik
        initialValues={{ sum: "", card: "", isValidServer: payload === null ? false : true }}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          sum: Yup.number()
            .positive()
            .min(1000, "Введите минимальную сумму")
            .required("Введите сумму"),
          card: Yup.string()
            .min(19, "Введите номер карты состоящей из 16 симоволов")
            .required("Введите номер карты"),
        })}
        onSubmit={(values, { resetForm }) => {
          const card = values.card.split(" ").join("");
          handleWithdrawToMoney(card, values.sum);
        }}
      >
        {({ values, errors, touched, handleBlur, handleSubmit, setFieldValue }) => (
          <>
            <FormLayout>
              <FormLayoutGroup
                top="Сумма"
                status={
                  !errors.sum && touched.sum ? "valid" : errors.sum && touched.sum ? "error" : null
                }
                bottom={touched.sum && errors.sum ? errors.sum : ""}
                className={styles.formLayoutGroup}
              >
                <div className={styles.inputSection}>
                  <Input
                    name="sum"
                    maxLength="7"
                    pattern="[0-9]*"
                    value={values.sum}
                    onBlur={handleBlur}
                    status={
                      !errors.sum && touched.sum
                        ? "valid"
                        : errors.sum && touched.sum
                        ? "error"
                        : null
                    }
                    onChange={(e) => {
                      handleChangeOnlyNumber(e, "sum", setFieldValue);
                    }}
                  ></Input>
                </div>
                <div className={styles.cnt}>
                  <p className={styles.subtitle}>Минимальная сумма для вывода 1000 ₽</p>
                </div>
              </FormLayoutGroup>
              <Input
                top="Номер карты для зачисления"
                name="card"
                placeholder="0000 0000 0000 0000"
                maxLength="19"
                pattern="[0-9]*"
                value={values.card
                  .replace(/\s/g, "")
                  .replace(/(\d{4})/g, "$1 ")
                  .trim()}
                status={
                  !errors.card && touched.card
                    ? "valid"
                    : errors.card && touched.card
                    ? "error"
                    : null
                }
                bottom={touched.card && errors.card ? errors.card : ""}
                onBlur={handleBlur}
                onChange={(e) => handleChangeCardNumber(e, "card", setFieldValue)}
              ></Input>
              {error !== null && (
                <FormStatus mode="error">{isVisibleMessage ? errorMessage : ""}</FormStatus>
              )}
              {payload === true && (
                <FormStatus style={{ color: "var(--field_valid_border)" }} mode="default">
                  Вывод средств прошел успешно
                </FormStatus>
              )}
            </FormLayout>
            <Div>
              <div style={{ marginBottom: "10px" }}>
                <Button
                  type="submit"
                  disabled={loading}
                  onClick={handleSubmit}
                  size="xl"
                  mode="primary"
                >
                  Вывести средства
                </Button>
              </div>
              <div>
                <Button
                  size="xl"
                  mode="tertiary"
                  disabled={loading}
                  onClick={() =>
                    props.openView({
                      view: VIEW_MY_PROFILE,
                      panel: PANEL_AFFILIATE_WITHDRAW_TO_COIN,
                    })
                  }
                >
                  Купить монеты
                </Button>
              </div>
            </Div>
          </>
        )}
      </Formik>
    </Panel>
  );
};

export default withRouter(PanelAffiliateWithdrawToMoney);
