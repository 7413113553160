import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { ModalCard } from "@vkontakte/vkui";
import CoinIcon from "../../../../assets/img/shop/coin.svg";

import { connectTariff } from "store/actions/shop";

const TariffConnectionModal = (props) => {
  const tariffCaption = props.modalParam ? props.modalParam.tariffCaption : "";
  const timeCaption = props.modalParam ? props.modalParam.timeCaption : "";
  const sum = props.modalParam ? props.modalParam.sum : "";

  const tariffType = props.modalParam ? props.modalParam.tariffType : "";
  const dayCount = props.modalParam ? props.modalParam.dayCount : "";

  const dispatch = useDispatch();
  const handleConnectTariff = useCallback(
    (tariffType, dayCount, autopayment, tariffCaption) =>
      dispatch(connectTariff(tariffType, dayCount, autopayment, tariffCaption)),
    [dispatch]
  );

  return (
    <ModalCard
      id={props.id}
      header={"Подключение тарифа"}
      onClose={props.handleCloseModal}
      icon={<img src={CoinIcon} alt="" />}
      caption={
        "Вы собираетесь подключить тариф «" +
        tariffCaption +
        "» на " +
        timeCaption +
        ". С вашего баланса будет снято " +
        sum +
        "."
      }
      actions={[
        {
          title: "Отмена",
          mode: "secondary",
          action: props.handleCloseModal,
        },
        {
          title: "Подключить",
          mode: "primary",
          action: () => {
            props.handleCloseModal();
            handleConnectTariff(tariffType, dayCount, true, tariffCaption);
          },
        },
      ]}
    />
  );
};

export default TariffConnectionModal;
