import React from "react";
import { Avatar, SimpleCell } from "@vkontakte/vkui";
import Icon16Chevron from "@vkontakte/icons/dist/16/chevron";
import { declOfNum } from "helpers";
import { MODAL_PAGE_TEMPLATE_MULTI } from "template/modals/constModals";

const WLFCommonAudioForMeItem = (props) => {
  const getDescription = () => {
    const commonCountStr =
      props.commonCount +
      " " +
      declOfNum(props.commonCount, ["общее", "общих", "общих"]) +
      " аудио";
    return commonCountStr;
  };

  const renderAudio = () => {
    return props.commonMusic.map((music, i) => {
      const id = music.vk_id;
      const title = music.title;
      const artist = music.artist;
      const duraction = music.duration;

      return (
        <SimpleCell
          key={i}
          id={id}
          after={
            <span style={{ fontSize: "13px", color: "var(--text_secondary)" }}>{duraction}</span>
          }
        >
          <span
            style={{
              fontWeight: "500",
              fontSize: "16px",
              marginTop: "4px",
              marginBottom: "10px",
              letterSpacing: "0.2px",
              color: "var(--text_primary)",
            }}
          >
            {title + " "}
            <span style={{ color: "var(--text_secondary)" }}>{artist}</span>
          </span>
        </SimpleCell>
      );
    });
  };

  return (
    <SimpleCell
      id={props.id}
      before={<Avatar size={48} src={props.photo} />}
      description={getDescription()}
      onClick={() => {
        props.openModal({
          id: MODAL_PAGE_TEMPLATE_MULTI,
          param: {
            header: (
              <span
                style={{
                  fontWeight: "500",
                  fontSize: "13px",
                  lineHeight: "16px",
                  letterSpacing: "0.2px",
                  textTransform: "uppercase",
                  color: "var(--text_secondary)",
                }}
              >
                {getDescription()}
              </span>
            ),
            content: <div>{props.commonMusic !== null && renderAudio()}</div>,
          },
        });
      }}
      after={<Icon16Chevron fill="#B8C1CC" />}
    >
      {props.title}
    </SimpleCell>
  );
};

export default WLFCommonAudioForMeItem;
