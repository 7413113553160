import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Panel } from "@vkontakte/vkui";
import TSSettingNotificationList from "./components/TSSettingNotificationList/TSSettingNotificationList";
import { withRouter } from "hoc";

const PanelTSSettingNotification = (props) => {
  return (
    <Panel id={props.id}>
      <div style={{ marginBottom: "20px" }}>
        <PanelHeaderWithPath goBack={props.goBack}>Тотальная слежка</PanelHeaderWithPath>
        <TSSettingNotificationList />
      </div>
    </Panel>
  );
};

export default withRouter(PanelTSSettingNotification);
