import React from "react";

import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { PANEL_A__OTHER_USER, PANEL_A__USER } from "../constPanels";
import { Group, Panel, SimpleCell } from "@vkontakte/vkui";
import Icon16Chevron from "@vkontakte/icons/dist/16/chevron";
import Icon28ArrowLeftOutline from "@vkontakte/icons/dist/28/arrow_left_outline";
import Icon28ArrowRightOutline from "@vkontakte/icons/dist/28/arrow_right_outline";
import { VIEW_TOOL } from "views/constViews";
import ActivityIcon from "assets/img/tool/activity/banner/activity.png";
import ToolBanner from "template/components/ToolBanner/ToolBanner";
import { withRouter } from "hoc";

const PanelActivity = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Активности</PanelHeaderWithPath>
      <ToolBanner
        tool="toolActivity"
        icon={ActivityIcon}
        text="Узнайте о том что лайкают и комментируют другие пользователи"
      />
      <Group>
        <SimpleCell
          multiline
          before={<Icon28ArrowRightOutline />}
          after={<Icon16Chevron fill="#B8C1CC" />}
          onClick={() => props.openView({ view: VIEW_TOOL, panel: PANEL_A__USER })}
          description="Показывает комментарии и лайки, которые пользователь оставляет
                        у других пользователей или в сообществах"
        >
          Активность пользователя
        </SimpleCell>
        <SimpleCell
          multiline
          before={<Icon28ArrowLeftOutline />}
          after={<Icon16Chevron fill="#B8C1CC" />}
          onClick={() => props.openView({ view: VIEW_TOOL, panel: PANEL_A__OTHER_USER })}
          description="Показывает комментарии и лайки, которые другие пользователи оставляют у выбранного"
        >
          Активности у пользователя
        </SimpleCell>
      </Group>
    </Panel>
  );
};

export default withRouter(PanelActivity);
