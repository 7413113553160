import bridge from "@vkontakte/vk-bridge";
import { getVKApiVersion, getVKToken } from "api";
import { errorHandler, responseHandler } from "../responseHandler";

async function APIVkGetStories({ ownerId = null, fields = null, extended = 0 } = null) {
  const method = "stories.get";
  try {
    let isOwnerId = ownerId !== null ? { owner_id: ownerId } : {};

    const response = await bridge.send("VKWebAppCallAPIMethod", {
      method: "stories.get",
      params: {
        ...isOwnerId,
        fields: fields,
        extended: extended,
        v: getVKApiVersion(),
        access_token: getVKToken(),
      },
    });

    responseHandler(method, response);

    return response;
  } catch (e) {
    errorHandler(method, e);
    return e;
  }
}

export default APIVkGetStories;
