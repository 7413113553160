import {
  ADMIN_COMMUNITY_REQUEST,
  ADMIN_COMMUNITY_SUCCESS,
  ADMIN_COMMUNITY_ERROR,
  ADMIN_COMMUNITY_SHOW_MORE,
  ADMIN_COMMUNITY_SEARCH_REQUEST,
  ADMIN_COMMUNITY_SEARCH_SUCCESS,
  ADMIN_COMMUNITY_SEARCH_ERROR,
  ADMIN_COMMUNITY_FILTER_REQUEST,
  ADMIN_COMMUNITY_FILTER_SUCCESS,
  ADMIN_COMMUNITY_FILTER_ERROR,
  ADMIN_COMMUNITY__INFO_GROUP_REQUEST,
  ADMIN_COMMUNITY__INFO_GROUP_SUCCESS,
  ADMIN_COMMUNITY__INFO_GROUP_ERROR,
} from "../../constType";

const initialState = {
  friends: {
    payload: null,
    showCount: 20,
    cachePayload: null,
    search: "",
    filter: "",
    loading: false,
    error: null,
    isOnce: false,
  },
  groups: {
    payload: null,
    activeUserId: null,
    activeUserPhoto: null,
    activeUserTitle: null,
    activeUserDescription: null,
    loading: false,
    error: null,
  },
};

export default function adminCommunityReducer(state = initialState, action) {
  switch (action.type) {
    case ADMIN_COMMUNITY_REQUEST:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: null,
          cachePayload:
            state.friends.search === "" && state.friends.filter === ""
              ? null // Если поле поиска и фильтра пустое то очистить кэш
              : state.friends.cachePayload,
          loading: true,
          error: null,
          isOnce: true,
        },
      };
    case ADMIN_COMMUNITY_SUCCESS:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: action.payload,
          loading: false,
          cachePayload:
            state.friends.search === "" && state.friends.filter === ""
              ? action.payload // Если поле поиска и фильтра пустое то сохранить результат
              : state.friends.cachePayload,
        },
      };
    case ADMIN_COMMUNITY_ERROR:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          error: action.error,
        },
      };
    case ADMIN_COMMUNITY_SHOW_MORE:
      return {
        ...state,
        friends: {
          ...state.friends,
          showCount: parseInt(state.friends.showCount) + 20,
        },
      };
    case ADMIN_COMMUNITY_SEARCH_REQUEST:
      return {
        ...state,
        friends: {
          ...state.friends,
          showCount: 20,
          search: action.search,
          loading: true,
          error: null,
        },
      };
    case ADMIN_COMMUNITY_SEARCH_SUCCESS:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          payload: [...action.payload],
        },
      };
    case ADMIN_COMMUNITY_SEARCH_ERROR:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          error: action.error,
        },
      };
    case ADMIN_COMMUNITY_FILTER_REQUEST:
      return {
        ...state,
        friends: {
          ...state.friends,
          showCount: 20,
          payload: null,
          filter: action.filter,
          loading: true,
          error: null,
        },
      };
    case ADMIN_COMMUNITY_FILTER_SUCCESS:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: [...action.payload],
          loading: false,
        },
      };
    case ADMIN_COMMUNITY_FILTER_ERROR:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          error: action.error,
        },
      };
    case ADMIN_COMMUNITY__INFO_GROUP_REQUEST:
      return {
        ...state,
        groups: {
          ...state.groups,
          loading: true,
          error: null,
          payload: null,
          activeUserId: action.id,
          activeUserDescription: action.description,
          activeUserTitle: action.title,
          activeUserPhoto: action.photo,
        },
      };
    case ADMIN_COMMUNITY__INFO_GROUP_SUCCESS:
      return {
        ...state,
        groups: {
          ...state.groups,
          payload: action.payload,
          loading: false,
        },
      };
    case ADMIN_COMMUNITY__INFO_GROUP_ERROR:
      return {
        ...state,
        groups: {
          ...state.groups,
          loading: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}
