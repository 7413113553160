import { queryEye3 } from "api";

async function APIEye3GetAppFriendsTool() {
  try {
    const response = await queryEye3({
      method: "tool.get_app_friends",
      access: { isBotConnect: true, isLicense: true, isLimit: true },
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3GetAppFriendsTool;
