import { useDebounce } from "hooks";
import { useEffect } from "react";

function useDebounceSearch({ search, searchTerm, handler }) {
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  useEffect(() => {
    if (debouncedSearchTerm || search) handler(searchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handler, debouncedSearchTerm]);
}

export default useDebounceSearch;
