import React from "react";
import { Avatar, SimpleCell } from "@vkontakte/vkui";
import { VIEW_TOOL } from "views/constViews";
import { PANEL_PROFILE } from "views/profile/panels/constPanels";
import { Icon28ChevronRightOutline } from "@vkontakte/icons";

const MSInfoTopPanel = (props) => {
  return (
    <SimpleCell
      id={props.id}
      before={<Avatar size={72} src={props.photo} />}
      description={<span style={{ color: "var(--color-custom-link)" }}> {props.description} </span>}
      onClick={() =>
        props.openView({
          view: VIEW_TOOL,
          panel: PANEL_PROFILE,
          param: {
            activeProfileId: props.id,
          },
        })
      }
      after={<Icon28ChevronRightOutline fill="#B8C1CC" />}
    >
      {props.title}
    </SimpleCell>
  );
};

export default MSInfoTopPanel;
