import React from "react";

import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import CABanner from "./components/CABanner/CABanner";
import CAUserList from "./components/CAUserList/CAUserList";
import { Panel } from "@vkontakte/vkui";
import { withRouter } from "hoc";

const PanelCommunityActivity = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Активность сообществ</PanelHeaderWithPath>
      <CABanner />
      <CAUserList openView={props.openView} />
    </Panel>
  );
};

export default withRouter(PanelCommunityActivity);
