import { queryEye3 } from "api";

async function APIEye3CreatePayment({ email, count, autopayment = null }) {
  try {
    const response = await queryEye3({
      method: "payment.create",
      get: { email: email, count: count, autopayment: autopayment },
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3CreatePayment;
