import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Input, FormLayout, FormLayoutGroup, Group, Button, Div, Panel } from "@vkontakte/vkui";

import { savePayment, createPayment, createUnitpayPayment } from "store/actions/shop";
import { APIEye3EmailPayment } from "api/eye3";
import { PANEL_TEMPLATE_USER_AGREEMENT } from "template/panels/constPanels";
import styles from "./index.module.css";

import {
  TINKOFF,
  UNITPAY_MOBILE,
  UNITPAY_PAYPAL,
  UNITPAY_QIWI,
  UNITPAY_SAMSUNG_PAY,
  UNITPAY_WEB_MONEY,
  UNITPAY_YANDEX_MONEY,
} from "../constPaymentType";

import CardPayList from "./components/CardPayList/CardPayList";
import CardPayItem from "./components/CardPayItem/CardPayItem";

import QiwiIcon from "assets/img/shop/balance-replenishment/qiwi.png";
import AGCardIcon from "assets/img/shop/balance-replenishment/agCard.png";
import YandexMoneyIcon from "assets/img/shop/balance-replenishment/yandexMoney.png";
import PayPalIcon from "assets/img/shop/balance-replenishment/paypal.png";
import WebMoneyIcon from "assets/img/shop/balance-replenishment/webmoney.png";
import SamsungPayIcon from "assets/img/shop/balance-replenishment/samsungpay.png";
import MobileIcon from "assets/img/shop/balance-replenishment/mobile.png";

import CoinIcon from "assets/img/panelHeader/coin.png";
import { PANEL_BALANCE_CHECKED_STATUS, PANEL_BALANCE_IFRAME } from "../../panels/constPanels";
import { VIEW_SHOP } from "views/constViews";
import { withRouter } from "hoc";

const PanelBalanceReplenishment = (props) => {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.shop.balanceReplenishment.loading);
  const pageParam = props.pageParam;
  const isRoullete =
    pageParam !== null && pageParam.hasOwnProperty("isRoullete") ? pageParam.isRoullete : false;
  const needSum =
    pageParam !== null && pageParam.hasOwnProperty("needSum") ? pageParam.needSum : "";
  const autopayment =
    pageParam !== null && pageParam.hasOwnProperty("autopayment") ? pageParam.autopayment : false;

  const handleSavePayment = useCallback(
    (email, count, paymentType) => dispatch(savePayment(email, count, paymentType)),
    [dispatch]
  );
  const handleCreatePayment = useCallback(
    (email, count, autopayment) => dispatch(createPayment(email, count, autopayment)),
    [dispatch]
  );
  const handleCreateUnitpayPayment = useCallback(
    (email, count, type) => dispatch(createUnitpayPayment(email, count, type)),
    [dispatch]
  );

  const [payData, setPayData] = useState({
    // Объект формы
    email: {
      value: "",
      pattern: /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i,
      patternMessage: "Пожалуйста, введите корректный email",
      valid: true,
      touch: false,
      maxLength: 320,
    },
    paymentType: {
      [TINKOFF]: {
        id: TINKOFF,
        // selected: needSum !== '' && autopayment ? true : false,
        selected: true,
        visible: true,
      },
      [UNITPAY_QIWI]: {
        id: UNITPAY_QIWI,
        selected: false,
        visible: false,
        // visible: needSum !== '' && autopayment ? false : true
      },
      [UNITPAY_YANDEX_MONEY]: {
        id: UNITPAY_YANDEX_MONEY,
        selected: false,
        visible: false,
        // visible: needSum !== '' && autopayment ? false : true
      },
      [UNITPAY_PAYPAL]: {
        id: UNITPAY_PAYPAL,
        selected: false,
        visible: false,
        // visible: needSum !== '' && autopayment ? false : true
      },
      [UNITPAY_WEB_MONEY]: {
        id: UNITPAY_WEB_MONEY,
        selected: false,
        visible: false,
        // visible: needSum !== '' && autopayment ? false : true
      },
      [UNITPAY_SAMSUNG_PAY]: {
        id: UNITPAY_SAMSUNG_PAY,
        selected: false,
        visible: false,
        // visible: needSum !== '' && autopayment ? false : true
      },
      [UNITPAY_MOBILE]: {
        id: UNITPAY_MOBILE,
        selected: false,
        visible: false,
        // visible: needSum !== '' && autopayment ? false : true
      },
      valid: true,
      touch: needSum !== "" && autopayment ? true : false,
    },
    count: {
      value: needSum,
      pattern: /^[1-9]\d*$/,
      patternMessage: "Пожалуйста, введите корректную сумму",
      maxLength: 7,
      valid: true,
      touch: needSum !== "" ? true : false,
    },
  });

  useEffect(() => {
    APIEye3EmailPayment()
      .then((res) => {
        setPayData((prevState) => ({
          ...prevState,
          email: {
            ...prevState.email,
            value: res.email !== "" ? res.email : prevState.email.value,
            touch: true,
          },
        }));
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const getStatus = (field) => {
    const targetField = payData[field];

    if (targetField.valid && targetField.value === "") return null;
    else if (targetField.valid && targetField.value !== "") return "valid";
    else return "error";
  };

  const onChangeField = (e, field) => {
    const targetField = payData[field];

    let newValue = null;

    newValue =
      targetField.hasOwnProperty("maxLength") && e.target.value.length > targetField.maxLength
        ? targetField.value
        : e.target.value;

    let isValid =
      (targetField.hasOwnProperty("pattern") && !e.target.value.match(targetField.pattern)) ||
      e.target.value.length === 0
        ? false
        : true;

    setPayData({
      ...payData,
      [field]: {
        ...payData[field],
        value: newValue,
        touch: true,
        valid: isValid,
      },
    });
  };

  const handleSelectCard = (card) => {
    setPayData({
      ...payData,
      paymentType: {
        ...payData.paymentType,
        [TINKOFF]: {
          ...payData.paymentType[TINKOFF],
          selected: false,
        },
        [UNITPAY_QIWI]: {
          ...payData.paymentType[UNITPAY_QIWI],
          selected: false,
        },
        [UNITPAY_YANDEX_MONEY]: {
          ...payData.paymentType[UNITPAY_YANDEX_MONEY],
          selected: false,
        },
        [UNITPAY_PAYPAL]: {
          ...payData.paymentType[UNITPAY_PAYPAL],
          selected: false,
        },
        [UNITPAY_WEB_MONEY]: {
          ...payData.paymentType[UNITPAY_WEB_MONEY],
          selected: false,
        },
        [UNITPAY_SAMSUNG_PAY]: {
          ...payData.paymentType[UNITPAY_SAMSUNG_PAY],
          selected: false,
        },
        [UNITPAY_MOBILE]: {
          ...payData.paymentType[UNITPAY_MOBILE],
          selected: false,
        },
        touch: true,
        valid: !payData.paymentType[card.id].selected,
        [card.id]: {
          ...payData.paymentType[card.id],
          selected: !payData.paymentType[card.id].selected,
        },
      },
    });
  };

  const handlePreparePay = () => {
    let paymentTypeValue = null;
    let paymentTypeValid = false;

    Object.entries(payData.paymentType).forEach(([key, value]) => {
      // Поиск выбранной карточки оплаты
      if (value.selected) {
        paymentTypeValue = value.id;
        paymentTypeValid = true;
        return;
      }
      //console.log(`${key}: ${value.selected}`)
    });

    setPayData({
      ...payData,
      paymentType: {
        ...payData.paymentType,
        valid: paymentTypeValid,
      },
    });

    if (
      payData.email.valid &&
      payData.email.touch &&
      payData.count.valid &&
      payData.count.touch &&
      paymentTypeValid &&
      paymentTypeValue !== null
    ) {
      //console.log('valid');

      handleSavePayment(payData.email.value, payData.count.value, paymentTypeValue);
      handlePay(paymentTypeValue);
    }

    /*console.log(paymentTypeValue);
        console.log(payData);*/
  };

  const handlePay = (paymentTypeValue) => {
    switch (paymentTypeValue) {
      case TINKOFF:
        handleCreatePayment(payData.email.value, payData.count.value, autopayment).then(() => {
          props.openView({
            view: VIEW_SHOP,
            panel: PANEL_BALANCE_IFRAME,
            param: needSum !== "" ? { needSum: needSum, isRoullete: isRoullete } : null,
          });
        });
        break;
      case UNITPAY_QIWI:
      case UNITPAY_YANDEX_MONEY:
      case UNITPAY_PAYPAL:
      case UNITPAY_WEB_MONEY:
      case UNITPAY_SAMSUNG_PAY:
      case UNITPAY_MOBILE:
        handleCreateUnitpayPayment(payData.email.value, payData.count.value, paymentTypeValue).then(
          () => {
            props.openView({
              view: VIEW_SHOP,
              panel: PANEL_BALANCE_CHECKED_STATUS,
              param: needSum !== "" ? { needSum: needSum } : null,
            });
          }
        );
        break;

      default:
        console.error("Error: not found paymentType");
        break;
    }
  };

  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Пополнить баланс</PanelHeaderWithPath>
      <div style={{ marginBottom: "20px" }}>
        {!isRoullete && needSum !== "" ? (
          <Div>
            <div className={styles.notCoinTitle}>Недостаточно монет</div>
            <div className={styles.needCoinTitle}>
              На вашем балансе не хватает {needSum} монет для покупки тарифа. Пополните баланс.
            </div>
          </Div>
        ) : (
          ""
        )}

        {isRoullete && needSum !== "" ? (
          <Div>
            <div className={styles.notCoinTitle}>Оплатите 1 ₽ для подключения автоплатежа</div>
          </Div>
        ) : (
          ""
        )}

        <FormLayout>
          <FormLayoutGroup
            status={getStatus("count")}
            top="Сумма"
            style={{ display: "flex", alignItems: "flex-start" }}
            bottom={!payData.count.valid ? payData.count.patternMessage : null}
          >
            <div style={{ width: "50%", minWidth: "200px", position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  zIndex: "5",
                  right: "22px",
                  top: "11px",
                }}
              >
                <img width="20" height="20" src={CoinIcon} alt=""></img>
              </div>
              <Input
                type="number"
                min="0"
                max="9999999"
                disabled={needSum !== "" ? true : false}
                value={payData.count.value}
                onChange={(e) => onChangeField(e, "count")}
                status={getStatus("count")}
              ></Input>
            </div>
            <div
              style={{ marginTop: "0px", width: "50%", marginRight: "100px", whiteSpace: "nowrap" }}
            >
              <p
                style={{
                  marginTop: "0px",
                  marginBottom: "2px",
                  fontWeight: "normal",
                  fontSize: "14px",
                  lineHeight: "18px",
                  letterSpacing: "0.2px",
                  color: "var(--text_secondary)",
                }}
              >
                К оплате:
              </p>
              <p
                style={{
                  margin: "0px",
                  fontWeight: "bold",
                  fontSize: "20px",
                  lineHeight: "24px",
                  letterSpacing: "0.38px",
                }}
              >
                {payData.count.value ? payData.count.value + " ₽" : "0 ₽"}
              </p>
            </div>
          </FormLayoutGroup>
          <Input
            top="Email для получения чека"
            placeholder="Введите email"
            value={payData.email.value}
            status={getStatus("email")}
            bottom={!payData.email.valid ? payData.email.patternMessage : null}
            onChange={(e) => onChangeField(e, "email")}
          ></Input>
        </FormLayout>
        <Group
          separator="hide"
          header={
            <Div style={{ paddingBottom: "4px" }}>
              <span
                style={{
                  fontSize: "14px",
                  marginTop: "0px",
                  lineHeight: "18px",
                  color: "var(--text_secondary)",
                }}
              >
                Способ оплаты
              </span>
            </Div>
          }
        >
          <CardPayList>
            <CardPayItem
              srcIcon={AGCardIcon}
              id={payData.paymentType[TINKOFF]}
              selected={payData.paymentType[TINKOFF].selected}
              visible={payData.paymentType[TINKOFF].visible}
              handleClick={handleSelectCard}
              size="m"
              // width={needSum !== "" && autopayment ? "100%" : "51%"}
              width="100%"
              widthIcon={"128px"}
              heightIcon={"48px"}
              text={
                <>
                  Банковская карта, <br></br> Apple Pay, Google Pay
                </>
              }
            />
            <CardPayItem
              srcIcon={QiwiIcon}
              id={payData.paymentType[UNITPAY_QIWI]}
              selected={payData.paymentType[UNITPAY_QIWI].selected}
              visible={payData.paymentType[UNITPAY_QIWI].visible}
              handleClick={handleSelectCard}
              widthIcon={"48px"}
              heightIcon={"48px"}
              width="25%"
              text={"Qiwi"}
            />
            <CardPayItem
              srcIcon={YandexMoneyIcon}
              id={payData.paymentType[UNITPAY_YANDEX_MONEY]}
              selected={payData.paymentType[UNITPAY_YANDEX_MONEY].selected}
              visible={payData.paymentType[UNITPAY_YANDEX_MONEY].visible}
              handleClick={handleSelectCard}
              widthIcon={"48px"}
              heightIcon={"48px"}
              width="24%"
              text={"Яндекс Деньги"}
            />
          </CardPayList>
          <CardPayList>
            <CardPayItem
              srcIcon={PayPalIcon}
              id={payData.paymentType[UNITPAY_PAYPAL]}
              selected={payData.paymentType[UNITPAY_PAYPAL].selected}
              visible={payData.paymentType[UNITPAY_PAYPAL].visible}
              handleClick={handleSelectCard}
              widthIcon={"48px"}
              heightIcon={"48px"}
              text={"PayPal"}
              width="25%"
            />
            <CardPayItem
              srcIcon={WebMoneyIcon}
              id={payData.paymentType[UNITPAY_WEB_MONEY]}
              selected={payData.paymentType[UNITPAY_WEB_MONEY].selected}
              visible={payData.paymentType[UNITPAY_WEB_MONEY].visible}
              handleClick={handleSelectCard}
              widthIcon={"48px"}
              heightIcon={"48px"}
              text={"Web Money"}
              width="25%"
            />
            <CardPayItem
              srcIcon={SamsungPayIcon}
              id={payData.paymentType[UNITPAY_SAMSUNG_PAY]}
              selected={payData.paymentType[UNITPAY_SAMSUNG_PAY].selected}
              visible={payData.paymentType[UNITPAY_SAMSUNG_PAY].visible}
              handleClick={handleSelectCard}
              widthIcon={"48px"}
              heightIcon={"48px"}
              text={"Samsung Pay"}
              width="25%"
            />
            <CardPayItem
              srcIcon={MobileIcon}
              id={payData.paymentType[UNITPAY_MOBILE]}
              selected={payData.paymentType[UNITPAY_MOBILE].selected}
              visible={payData.paymentType[UNITPAY_MOBILE].visible}
              handleClick={handleSelectCard}
              widthIcon={"48px"}
              heightIcon={"48px"}
              text={"Мобильные операторы"}
              width="25%"
            />
          </CardPayList>
          {!payData.paymentType.valid && (
            <Div
              style={{
                paddingTop: "0px",
                paddingBottom: "0px",
              }}
            >
              <span
                style={{
                  fontWeight: "normal",
                  fontSize: "13px",
                  lineHeight: "16px",
                  letterSpacing: "0.2px",
                  color: "#E64646",
                }}
              >
                Пожалуйста, выберите способ оплаты
              </span>
            </Div>
          )}
        </Group>
        <Div style={{ marginTop: "8px" }}>
          <Button
            size="xl"
            disabled={
              payData.email.value === "" || payData.count.value === "" || loading ? true : false
            }
            onClick={handlePreparePay}
          >
            Оплатить
          </Button>
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
            <span className={styles.bottomTitle}>admin@mycrush.ru</span>
            <span
              onClick={() =>
                props.openView({ view: VIEW_SHOP, panel: PANEL_TEMPLATE_USER_AGREEMENT })
              }
              className={styles.bottomTitle}
            >
              Пользовательское соглашение
            </span>
          </div>
        </Div>
      </div>
    </Panel>
  );
};

export default withRouter(PanelBalanceReplenishment);
