import "core-js/features/map";
import "core-js/features/set";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import App from "./App";

import { VkBridgeSend } from "api/vk";
import { store } from "./store/store";
import { openStory } from "./store/actions/page";
import { STORY_GUEST } from "stories/constStories";

// Установить начальный story
store.dispatch(openStory({ story: STORY_GUEST }));

// Инициализация VK Mini App
VkBridgeSend("VKWebAppInit");

const app = (
  <Provider store={store}>
    <App />
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

if (process.env.NODE_ENV === "development") {
  import("./eruda").then(({ default: eruda }) => {}); // runtime download
}
