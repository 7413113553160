import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import MartialList from "./components/MaritalList/MaritalList";
import { Panel } from "@vkontakte/vkui";
import { withRouter } from "hoc";

const PanelMaritalStatus = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Профиль</PanelHeaderWithPath>
      <MartialList />
    </Panel>
  );
};

export default withRouter(PanelMaritalStatus);
