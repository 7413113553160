import {
  PROFILE_VIEW_STORIES_REQUEST,
  PROFILE_VIEW_STORIES_SUCCESS,
  PROFILE_VIEW_STORIES_ERROR,
  PROFILE_VIEW_STORIES_PROGRESS,
  PROFILE_VIEW_STORIES_CANCEL,
  PROFILE_VIEW_STORIES_SHOW_MORE,
} from "../../constType";

const initialState = {
  activeUserId: null,
  payload: null,
  loading: false,
  showCount: 20,
  progressValue: 0,
  isStopLoading: false,
  error: null,
};

export default function viewHistoryReducer(state = initialState, action) {
  switch (action.type) {
    case PROFILE_VIEW_STORIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        payload: null,
        isStopLoading: false,
        progressValue: 0,
        activeUserId: action.id,
        showCount: 20,
      };
    case PROFILE_VIEW_STORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: action.payload,
      };
    case PROFILE_VIEW_STORIES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case PROFILE_VIEW_STORIES_SHOW_MORE:
      return {
        ...state,
        showCount: parseInt(state.showCount) + 20,
      };
    case PROFILE_VIEW_STORIES_PROGRESS:
      return {
        ...state,
        progressValue: action.progressValue,
        payload: action.payload,
      };
    case PROFILE_VIEW_STORIES_CANCEL:
      return {
        ...state,
        isStopLoading: state.loading ? true : false,
        loading: false,
      };
    default:
      return state;
  }
}
