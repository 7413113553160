import React from "react";

const GraphAge = (props) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "15px",
      }}
    >
      <div style={{ width: "100%", color: "var(--text_subhead)" }}>{props.title}</div>
      <div style={{ width: "100%" }}>
        <div style={{ display: "flex", alignItems: "center", marginBottom: "4px" }}>
          <div
            style={{
              width: `${props.male}px`,
              height: "4px",
              background: "#579BEB",
              borderRadius: "4px",
              marginRight: "4px",
            }}
          ></div>
          <span style={{ color: "var(--text_secondary)", fontSize: "13px" }}>{props.male}%</span>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: `${props.female}px`,
              height: "4px",
              background: "#EE94B4",
              borderRadius: "4px",
              marginRight: "4px",
            }}
          ></div>
          <span style={{ color: "var(--text_secondary)", fontSize: "13px" }}>{props.female}%</span>
        </div>
      </div>
    </div>
  );
};

GraphAge.defaultProps = {
  title: "",
  male: 0,
  female: 0,
};

export default GraphAge;
