import React from "react";
import { ModalCard } from "@vkontakte/vkui";
import TariffIcon from "assets/img/template/modals/tariffLimit.png";

const TariffLimit = (props) => {
  return (
    <ModalCard
      id={props.id}
      header="Лимит для отслеживания пользователей превышен"
      icon={<img src={TariffIcon} style={{ width: "50px", height: "50px" }} alt="" />}
      onClose={props.handleCloseModal}
      actions={[
        {
          title: "Понятно",
          mode: "primary",
          action: () => {
            props.handleCloseModal();
          },
        },
      ]}
    />
  );
};

export default TariffLimit;
