import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Group, Div, Alert } from "@vkontakte/vkui";
import MutualCrushItem from "../MutualCrushItem/MutualCrushItem";

import { declOfNum } from "helpers";
import { removeMyCrush } from "store/actions/tool/moyCrush";

const MutualCrushList = (props) => {
  const dispatch = useDispatch();
  const crush = useSelector((state) =>
    state.toolMoyCrush.myCrush.payload ? state.toolMoyCrush.myCrush.payload : null
  );
  const mutual = crush !== null ? crush.mutual : null;

  const handleRemoveMyCrush = useCallback((id) => dispatch(removeMyCrush(id)), [dispatch]);

  const handleDelete = (id, name) => {
    props.openPopout({
      popout: (
        <Alert
          actionsLayout="horizontal"
          actions={[
            {
              title: "Отмена",
              autoclose: true,
              mode: "cancel",
            },
            {
              title: "Удалить",
              autoclose: true,
              mode: "destructive",
              action: () => {
                handleRemoveMyCrush(id);
              },
            },
          ]}
          onClose={props.closePopout}
        >
          <p>{"Вы уверены, что хотите удалить " + name + " из списка крашей?"}</p>
        </Alert>
      ),
    });
  };

  const renderMutualCrushList = () => {
    return mutual.length > 0
      ? mutual.map((crush, i) => {
          const id = crush.vk_id;
          const name = crush.name;
          const city = crush.city ? crush.city : "";
          const age = crush.age ? crush.age : "";
          const photo = crush.photo_url ? crush.photo_url : "";

          const ageStrFormat = age ? age + " " + declOfNum(age, ["год", "года", "лет"]) : "";
          const comma = ageStrFormat && city ? ", " : "";

          return (
            <MutualCrushItem
              key={i}
              id={id}
              photo={photo}
              title={name}
              openView={props.openView}
              description={ageStrFormat + comma + city}
              handleDelete={handleDelete}
            />
          );
        })
      : "";
  };

  return (
    <div>
      {mutual !== null && mutual.length !== 0 && (
        <Div style={{ paddingBottom: "0px" }}>
          <span
            style={{
              fontSize: "20px",
              lineHeight: "24px",
              letterSpacing: "0.38px",
              color: "var(--text_primary)",
              fontStyle: "normal",
              marginTop: "14px",
              fontWeight: "bold",
            }}
          >
            Взаимные краши
          </span>
        </Div>
      )}
      <Group>{mutual !== null ? renderMutualCrushList() : ""}</Group>
    </div>
  );
};

export default MutualCrushList;
