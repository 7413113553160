import {
  MOY_CRUSH_MY_CRUSH_LIST_REQUEST,
  MOY_CRUSH_MY_CRUSH_LIST_SUCCESS,
  MOY_CRUSH_MY_CRUSH_LIST_ERROR,
  MOY_CRUSH_CHOOSE_CRUSH_REQUEST,
  MOY_CRUSH_CHOOSE_CRUSH_SUCCESS,
  MOY_CRUSH_CHOOSE_CRUSH_ERROR,
  MOY_CRUSH_CHOOSE_CRUSH_SHOW_MORE,
  MOY_CRUSH_CHOOSE_CRUSH_SEARCH_REQUEST,
  MOY_CRUSH_CHOOSE_CRUSH_SEARCH_SUCCESS,
  MOY_CRUSH_CHOOSE_CRUSH_SEARCH_ERROR,
  MOY_CRUSH_CHOOSE_CRUSH_FILTER_REQUEST,
  MOY_CRUSH_CHOOSE_CRUSH_FILTER_SUCCESS,
  MOY_CRUSH_CHOOSE_CRUSH_FILTER_ERROR,
  MOY_CRUSH_CHOOSE_CRUSH_CHECKED,
  MOY_CRUSH_CHOOSE_CRUSH_TABS,
  MOY_CRUSH_REMOVE_MY_CRUSH_REQUEST,
  MOY_CRUSH_REMOVE_MY_CRUSH_SUCCESS,
  MOY_CRUSH_REMOVE_MY_CRUSH_ERROR,
} from "../../constType";

import { TABS_ALL } from "views/moycrush/panels/chooseCrush/constTabs";

const initialState = {
  myCrush: {
    payload: null,
    loading: false,
    error: null,
  },
  chooseCrush: {
    payload: null,
    selected: null,
    showCount: 20,
    tabs: TABS_ALL,
    search: "",
    filter: "",
    loading: false,
    error: null,
  },
};

export default function moyCrushReducer(state = initialState, action) {
  switch (action.type) {
    case MOY_CRUSH_MY_CRUSH_LIST_REQUEST:
      return {
        ...state,
        myCrush: {
          ...state.myCrush,
          payload: null,
          loading: true,
          error: null,
        },
      };
    case MOY_CRUSH_MY_CRUSH_LIST_SUCCESS:
      return {
        ...state,
        myCrush: {
          ...state.myCrush,
          payload: action.payload,
          loading: false,
        },
        chooseCrush: {
          ...state.chooseCrush,
          selected: null,
          payload: null,
        },
      };
    case MOY_CRUSH_MY_CRUSH_LIST_ERROR:
      return {
        ...state,
        myCrush: {
          ...state.myCrush,
          loading: false,
          error: action.error,
        },
      };
    case MOY_CRUSH_REMOVE_MY_CRUSH_REQUEST:
      return {
        ...state,
        chooseCrush: {
          ...state.chooseCrush,
          error: null,
        },
      };
    case MOY_CRUSH_REMOVE_MY_CRUSH_SUCCESS:
      return {
        ...state,
        chooseCrush: {
          ...state.chooseCrush,
        },
      };
    case MOY_CRUSH_REMOVE_MY_CRUSH_ERROR:
      return {
        ...state,
        chooseCrush: {
          ...state.chooseCrush,
          loading: false,
          error: action.error,
        },
      };
    case MOY_CRUSH_CHOOSE_CRUSH_REQUEST:
      return {
        ...state,
        chooseCrush: {
          ...state.chooseCrush,
          showCount: 20,
          payload: null,
          error: null,
          loading: true,
        },
      };
    case MOY_CRUSH_CHOOSE_CRUSH_SUCCESS:
      return {
        ...state,
        chooseCrush: {
          ...state.chooseCrush,
          payload: action.payload,
          selected: action.selected,
          loading: false,
        },
      };
    case MOY_CRUSH_CHOOSE_CRUSH_ERROR:
      return {
        ...state,
        chooseCrush: {
          ...state.chooseCrush,
          loading: false,
          error: action.error,
        },
      };
    case MOY_CRUSH_CHOOSE_CRUSH_SHOW_MORE:
      return {
        ...state,
        chooseCrush: {
          ...state.chooseCrush,
          showCount: parseInt(state.chooseCrush.showCount) + 20,
        },
      };
    case MOY_CRUSH_CHOOSE_CRUSH_SEARCH_REQUEST:
      return {
        ...state,
        chooseCrush: {
          ...state.chooseCrush,
          payload: null,
          showCount: 20,
          search: action.search,
          loading: true,
          error: null,
        },
      };
    case MOY_CRUSH_CHOOSE_CRUSH_SEARCH_SUCCESS:
      return {
        ...state,
        chooseCrush: {
          ...state.chooseCrush,
          loading: false,
          payload: [...action.payload],
        },
      };
    case MOY_CRUSH_CHOOSE_CRUSH_SEARCH_ERROR:
      return {
        ...state,
        chooseCrush: {
          ...state.chooseCrush,
          loading: false,
          error: action.error,
        },
      };
    case MOY_CRUSH_CHOOSE_CRUSH_FILTER_REQUEST:
      return {
        ...state,
        chooseCrush: {
          ...state.chooseCrush,
          payload: null,
          showCount: 20,
          filter: action.filter,
          loading: true,
        },
      };
    case MOY_CRUSH_CHOOSE_CRUSH_FILTER_SUCCESS:
      return {
        ...state,
        chooseCrush: {
          ...state.chooseCrush,
          loading: false,
          payload: [...action.payload],
        },
      };
    case MOY_CRUSH_CHOOSE_CRUSH_FILTER_ERROR:
      return {
        ...state,
        chooseCrush: {
          ...state.chooseCrush,
          loading: false,
          error: action.error,
        },
      };
    case MOY_CRUSH_CHOOSE_CRUSH_CHECKED:
      return {
        ...state,
        chooseCrush: {
          ...state.chooseCrush,
          selected: action.checked
            ? [...state.chooseCrush.selected, action.value]
            : [...state.chooseCrush.selected.filter((item) => item !== action.value)],
        },
      };
    case MOY_CRUSH_CHOOSE_CRUSH_TABS:
      return {
        ...state,
        chooseCrush: {
          ...state.chooseCrush,
          tabs: action.tabs,
        },
      };
    default:
      return state;
  }
}
