import { APIEye3PostCountTool, APIEye3PostSearchTool } from "api/eye3";
import { getSearchFilterFriendsV2 } from "helpers/actions";
import { calcAge } from "helpers";
import {
  POSTING_REQUEST,
  POSTING_SUCCESS,
  POSTING_ERROR,
  POSTING_SHOW_MORE,
  POSTING__INFO_REQUEST,
  POSTING__INFO_SUCCESS,
  POSTING__INFO_ERROR,
  POSTING__INFO_SET_PERIOD,
  POSTING_FILTER_REQUEST,
  POSTING_FILTER_SUCCESS,
  POSTING_FILTER_ERROR,
  POSTING_SEARCH_REQUEST,
  POSTING_SEARCH_SUCCESS,
  POSTING_SEARCH_ERROR,
  POSTING__INFO_LOAD_MORE_REQUEST,
  POSTING__INFO_LOAD_MORE_SUCCESS,
  POSTING__INFO_LOAD_MORE_ERROR,
} from "../../constType";
import { openModal } from "../page";
import { getPeriod } from "template/popouts/DateChangePeriod/actions";
import { checkEYE3ApiError, defaultErrorProperty } from "api/eye3/responseHandler";

async function getCallbackResult(usersItems, dispatch, error) {
  try {
    let result = [];

    const posting = await APIEye3PostCountTool({
      userIds: usersItems.map((user) => user.id).join(","),
    });

    //console.log('posting: ', posting);

    // Проверка ошибок в API
    const errorAPI = checkEYE3ApiError(posting);
    if (errorAPI !== false) {
      errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
      dispatch(error({ ...errorAPI }));
      return;
    }

    if (!posting.status) {
      dispatch(
        error({
          ...defaultErrorProperty,
          message: "",
        })
      );

      return;
    }

    const postingItems = posting.items;

    usersItems.forEach((user) => {
      for (let post of postingItems) {
        if (user.id === post.vk_id) {
          result.push({
            vk_id: user.id,
            name: user.first_name + " " + user.last_name,
            age: user.bdate ? calcAge(user.bdate) : null, // Расчет возраста по дате рождения
            sex: user.sex ? user.sex.toString() : null,
            city: user.city ? user.city.title : null, // Город
            city_id: user.city ? user.city.id.toString() : null, // Город id
            photo_url: user.photo_100 ? user.photo_100 : "", // Фото
            country: user.country ? user.country.title : null, // Страна
            country_id: user.country ? user.country.id.toString() : null, // Страна id
            post_count: post.count,
          });

          break;
        }
      }
    });

    result = result.sort((a, b) => b.post_count - a.post_count);

    return result;
  } catch (e) {
    dispatch(
      error({
        ...defaultErrorProperty,
        message: e,
      })
    );
  }
}

export function fetchPosting() {
  return async (dispatch, getState) => {
    try {
      dispatch(request());

      const state = getState().toolPosting;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.friends.search,
        filter: state.friends.filter,
        cache: state.friends.cachePayload,
        asyncCallbackResult: (usersItems) => {
          return getCallbackResult(usersItems, dispatch, error);
        },
      });

      if (getState().toolPosting.friends.error === null) dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: POSTING_REQUEST };
  }

  function success(payload) {
    return { type: POSTING_SUCCESS, payload };
  }

  function error(error) {
    return { type: POSTING_ERROR, error };
  }
}

export function showMorePosting() {
  return { type: POSTING_SHOW_MORE };
}

export function filterPosting(filter = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(request(filter));

      const state = getState().toolPosting;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.friends.search,
        filter: state.friends.filter,
        cache: state.friends.cachePayload,
        asyncCallbackResult: (usersItems) => {
          return getCallbackResult(usersItems, dispatch);
        },
      });

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(filter) {
    return { type: POSTING_FILTER_REQUEST, filter };
  }

  function success(payload) {
    return { type: POSTING_FILTER_SUCCESS, payload };
  }

  function error(error) {
    return { type: POSTING_FILTER_ERROR, error };
  }
}

export function searchPosting(search = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(request(search));

      const state = getState().toolPosting;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.friends.search,
        filter: state.friends.filter,
        cache: state.friends.cachePayload,
        asyncCallbackResult: (usersItems) => {
          return getCallbackResult(usersItems, dispatch);
        },
      });

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(search) {
    return { type: POSTING_SEARCH_REQUEST, search };
  }

  function success(payload) {
    return { type: POSTING_SEARCH_SUCCESS, payload };
  }

  function error(error) {
    return { type: POSTING_SEARCH_ERROR, error };
  }
}

function getAttachments(post) {
  let countVideo = 0;
  let countPhoto = 0;
  let countLink = 0;
  let countAudio = 0;
  let countDocument = 0;

  if (post !== null && post.hasOwnProperty("attachments")) {
    for (let attachment of post.attachments) {
      switch (attachment.type) {
        case "video":
          countVideo++;
          break;
        case "photo":
          countPhoto++;
          break;
        case "link":
          countLink++;
          break;
        case "audio":
          countAudio++;
          break;
        case "doc":
          countDocument++;
          break;
        default:
          break;
      }
    }
  }

  return {
    all: countVideo + countPhoto + countLink + countAudio + countDocument,
    video: countVideo,
    photo: countPhoto,
    link: countLink,
    audio: countAudio,
    document: countDocument,
  };
}

async function getPostingInfo(dispatch, getState, error) {
  try {
    const state = getState().toolPosting;
    const pageParam = getState().page.param;

    const id = pageParam ? pageParam.activeUserId : state.posts.activeUserId;

    const period = state.posts.period;
    const count = state.posts.count;
    const offset = state.posts.offset;

    const { atMin, atMax } = getPeriod(period);

    const posts = await APIEye3PostSearchTool({
      userId: id,
      timeMin: atMin,
      timeMax: atMax,
      count: count,
      offset: offset,
    });

    let result = [];

    // Проверка ошибок в API
    const errorAPI = checkEYE3ApiError(posts);
    if (errorAPI !== false) {
      errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
      dispatch(error({ ...errorAPI }));
      return {
        result: result,
        maxLength: 0,
      };
    }

    //console.log('posts: ', posts);

    if (!posts.status) {
      dispatch(
        error({
          ...defaultErrorProperty,
          message: "",
        })
      );

      return {
        result: result,
        maxLength: 0,
      };
    }

    const postsItems = posts.items;
    const postsGroups = posts.groups;
    const maxLength = posts.max_length;

    for (let post of postsItems) {
      for (let group of postsGroups) {
        if (Math.abs(post.owner_id) === group.id) {
          const attachments = getAttachments(post);

          result.push({
            group_id: group.id,
            group_name: group.name,
            group_photo_url: group.photo_100,
            type_page: group.type === "page" ? "public" : "club",
            post_id: post.id,
            post_date: post.date,
            post_text: post.text,
            post_attachments: {
              ...attachments,
            },
          });

          break;
        }
      }
    }

    return {
      result: result,
      maxLength: maxLength,
    };
  } catch (e) {
    throw e;
  }
}

export function fetchPostingInfo() {
  return async (dispatch, getState) => {
    try {
      const state = getState().toolPosting;
      const pageParam = getState().page.param;

      const id = pageParam ? pageParam.activeUserId : state.posts.activeUserId;
      const title = pageParam ? pageParam.activeUserTitle : state.posts.activeUserTitle;
      const description = pageParam
        ? pageParam.activeUserDescription
        : state.posts.activeUserDescription;
      const photo = pageParam ? pageParam.activeUserPhoto : state.posts.activeUserPhoto;

      await dispatch(request(id, title, description, photo));

      const { result, maxLength } = await getPostingInfo(dispatch, getState, error);

      if (getState().toolPosting.posts.error === null) await dispatch(success(result, maxLength));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(id, title, description, photo) {
    return { type: POSTING__INFO_REQUEST, id, title, description, photo };
  }

  function success(payload, maxLength) {
    return { type: POSTING__INFO_SUCCESS, payload, maxLength };
  }

  function error(error) {
    return { type: POSTING__INFO_ERROR, error };
  }
}

export function setPeriodPostingInfo(name, atMin = null, atMax = null) {
  return { type: POSTING__INFO_SET_PERIOD, name, atMin, atMax };
}

export function loadMorePostingInfo() {
  return async (dispatch, getState) => {
    try {
      await dispatch(request());

      const { result } = await getPostingInfo(dispatch, getState, error);

      //console.log('result: ', result);

      await dispatch(success(result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: POSTING__INFO_LOAD_MORE_REQUEST };
  }

  function success(payload) {
    return { type: POSTING__INFO_LOAD_MORE_SUCCESS, payload };
  }

  function error(error) {
    return { type: POSTING__INFO_LOAD_MORE_ERROR, error };
  }
}
