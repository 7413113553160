import React from "react";
import { Avatar, RichCell } from "@vkontakte/vkui";

const RDItem = (props) => {
  return (
    <RichCell
      disabled
      before={<Avatar size={72} src={props.photo} />}
      caption={
        <div style={{ marginTop: "3px", marginBottom: "4px", color: "var(--color-custom-link)" }}>
          {props.description}
        </div>
      }
      bottom={
        <div style={{ fontSize: "13px", color: "var(--text_secondary)" }}>
          {props.bottomContent}
        </div>
      }
    >
      <span style={{ fontSize: "16px" }}>{props.title}</span>
    </RichCell>
  );
};

export default RDItem;
