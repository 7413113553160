import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Panel } from "@vkontakte/vkui";
import BalanceBanner from "views/shop/components/BalanceBanner/BalanceBanner";
import HistoryList from "./components/HistoryList/HistoryList";
import { withRouter } from "hoc";

const PanelBalanceHistory = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack} isVisibleBalance={false}>
        Баланс
      </PanelHeaderWithPath>
      <BalanceBanner openView={props.openView} />
      <HistoryList openView={props.openView} />
    </Panel>
  );
};

export default withRouter(PanelBalanceHistory);
