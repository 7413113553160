import bridge from "@vkontakte/vk-bridge";
import { getVKApiVersion, getVKToken } from "api";
import { errorHandler, responseHandler } from "../responseHandler";

async function APIVkGetByIdStories({ stories, extended = 0 }) {
  const method = "stories.getById";
  try {
    const response = await bridge.send("VKWebAppCallAPIMethod", {
      method: method,
      params: {
        stories: stories,
        extended: extended,
        v: getVKApiVersion(),
        access_token: getVKToken(),
      },
    });

    responseHandler(method, response);

    return response;
  } catch (e) {
    errorHandler(method, e);
    return e;
  }
}

export default APIVkGetByIdStories;
