import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import WhoAddedChsItem from "../WACItem/WACItem";
import WhoAddedChsInfo from "../WACInfo/WACInfo";

import { fetchWhoAddedChs } from "store/actions/tool/whoAddedChs";

import { declOfNum } from "helpers";
import LoaderWithProgressBar from "template/components/LoaderWithProgressBar/LoaderWithProgressBar";
import { renderErrorPlaceholder } from "helpers/views";
import HeadTitle from "template/components/HeadTitle/HeadTitle";

const WACList = (props) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.toolWhoAddedChs.loading);
  const error = useSelector((state) => state.toolWhoAddedChs.error);
  const chsList = useSelector((state) => state.toolWhoAddedChs.payload);
  const progressValue = useSelector((state) => state.toolWhoAddedChs.progressValue);
  const handleFetchWhoAddedChs = useCallback(() => dispatch(fetchWhoAddedChs()), [dispatch]);

  useEffect(() => {
    handleFetchWhoAddedChs();
  }, [handleFetchWhoAddedChs]);

  const renderChsList = () => {
    return (
      chsList &&
      chsList.map((user, i) => {
        const id = user.vk_id;
        const name = user.name;
        const city = user.city ? user.city : "";
        const age = user.age ? user.age : "";
        const photo = user.photo_url ? user.photo_url : "";

        const ageStrFormat = age ? age + " " + declOfNum(age, ["год", "года", "лет"]) : "";
        const comma = ageStrFormat && city ? ", " : "";

        return (
          <WhoAddedChsItem
            title={name}
            id={id}
            key={i}
            description={ageStrFormat + comma + city}
            photo={photo}
            openView={props.openView}
          />
        );
      })
    );
  };

  return (
    <>
      <HeadTitle>Кто добавил меня в ЧС</HeadTitle>
      {loading ? <LoaderWithProgressBar progressValue={progressValue} /> : ""}
      {chsList !== null && error === null ? renderChsList() : ""}
      {chsList !== null &&
      Object.prototype.toString.call(chsList) === "[object Array]" &&
      !loading &&
      chsList.length <= 0 &&
      error === null ? (
        <WhoAddedChsInfo description="Вас не добавил в ЧС ни один пользователь" />
      ) : (
        ""
      )}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchWhoAddedChs,
      })}
    </>
  );
};

export default WACList;
