import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Group, Header, Panel, SimpleCell } from "@vkontakte/vkui";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import Icon28UsersOutline from "@vkontakte/icons/dist/28/users_outline";
import Icon28NarrativeOutline from "@vkontakte/icons/dist/28/narrative_outline";
import Icon16Chevron from "@vkontakte/icons/dist/16/chevron";
import { PANEL_A__OTHER_USER_INFO, PANEL_A__OTHER_USER_ACTIVE } from "../../constPanels";

import { setDataOtherUserPage } from "store/actions/tool/activity";
import { VIEW_TOOL } from "views/constViews";
import { withRouter } from "hoc";

const PanelAOtherUserPage = (props) => {
  const dispatch = useDispatch();
  const activeUserId = useSelector((state) => state.toolActivity.otherUserPage.activeUserId);
  const activeUserTitle = useSelector((state) => state.toolActivity.otherUserPage.activeUserTitle);
  const activeUserDescription = useSelector(
    (state) => state.toolActivity.otherUserPage.activeUserDescription
  );
  const activeUserPhoto = useSelector((state) => state.toolActivity.otherUserPage.activeUserPhoto);

  const handleSetDataOtherUserPage = useCallback(
    () => dispatch(setDataOtherUserPage()),
    [dispatch]
  );

  useEffect(() => {
    handleSetDataOtherUserPage();
  }, [handleSetDataOtherUserPage]);

  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Активности</PanelHeaderWithPath>
      <Group header={<Header mode="secondary">Активности пользователя {activeUserTitle}</Header>}>
        <SimpleCell
          before={<Icon28NarrativeOutline />}
          multiline
          after={<Icon16Chevron fill="#B8C1CC" />}
          onClick={() =>
            props.openView({
              view: VIEW_TOOL,
              panel: PANEL_A__OTHER_USER_INFO,
              param: {
                activeUserId: activeUserId,
                activeUserPhoto: activeUserTitle,
                activeUserTitle: activeUserDescription,
                activeUserDescription: activeUserPhoto,
              },
            })
          }
        >
          Все активности
        </SimpleCell>
        <SimpleCell
          before={<Icon28UsersOutline />}
          multiline
          after={<Icon16Chevron fill="#B8C1CC" />}
          onClick={() => props.openView({ view: VIEW_TOOL, panel: PANEL_A__OTHER_USER_ACTIVE })}
        >
          Активные пользователи
        </SimpleCell>
      </Group>
    </Panel>
  );
};

export default withRouter(PanelAOtherUserPage);
