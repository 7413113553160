import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Group, Placeholder } from "@vkontakte/vkui";
import AOtherUserActiveTopPanel from "../AOtherUserActiveTopPanel/AOtherUserActiveTopPanel";
import AOtherUserActiveItem from "../AOtherUserActiveItem/AOtherUserActiveItem";
import Icon56LikeOutline from "@vkontakte/icons/dist/56/like_outline";

import { renderPeriodV2 } from "template/popouts/DateChangePeriod/views";
import PopoutTemplateDateChangePeriod from "template/popouts/DateChangePeriod/DateChangePeriod";

import { declOfNum } from "helpers";
import {
  fetchOtherUserActive,
  cancelOtherUserActive,
  setPeriodOtherUserActive,
} from "store/actions/tool/activity";
import { PANEL_A__OTHER_USER_ACTIVE } from "views/tool/panels/constPanels";
import { VIEW_TOOL } from "views/constViews";

import LoaderWithProgressBar from "template/components/LoaderWithProgressBar/LoaderWithProgressBar";
import { renderErrorPlaceholder } from "helpers/views";

const AOtherUserActiveList = (props) => {
  const dispatch = useDispatch();
  const activity = useSelector((state) => state.toolActivity.otherUserActive.payload);
  const activeUserId = useSelector((state) => state.toolActivity.otherUserPage.activeUserId);
  const activeUserTitle = useSelector((state) => state.toolActivity.otherUserPage.activeUserTitle);
  const activeUserDescription = useSelector(
    (state) => state.toolActivity.otherUserPage.activeUserDescription
  );
  const activeUserPhoto = useSelector((state) => state.toolActivity.otherUserPage.activeUserPhoto);
  const loading = useSelector((state) => state.toolActivity.otherUserActive.loading);
  const period = useSelector((state) => state.toolActivity.otherUserActive.period);
  const error = useSelector((state) => state.toolActivity.otherUserActive.error);
  const progressValue = useSelector((state) => state.toolActivity.otherUserActive.progressValue);

  const handleFetchOtherUserActive = useCallback(
    () => dispatch(fetchOtherUserActive()),
    [dispatch]
  );
  const handleCancelOtherUserActive = useCallback(
    () => dispatch(cancelOtherUserActive()),
    [dispatch]
  );
  const handleSetPeriodOtherUserActive = useCallback(
    (name, atMin = null, atMax = null) => dispatch(setPeriodOtherUserActive(name, atMin, atMax)),
    [dispatch]
  );

  useEffect(() => {
    return () => {
      handleCancelOtherUserActive();
    };
  }, [handleCancelOtherUserActive]);

  useEffect(() => {
    handleFetchOtherUserActive();
  }, [handleFetchOtherUserActive, period]);

  const renderUser = () => {
    return (
      <AOtherUserActiveTopPanel
        id={activeUserId}
        title={activeUserTitle}
        description={activeUserDescription}
        photo={activeUserPhoto}
        openView={props.openView}
      />
    );
  };

  const renderActivity = () => {
    return activity.map((a, i) => {
      const id = a.vk_id;
      const name = a.name;
      const count = a.count
        ? a.count + " " + declOfNum(a.count, ["активность", "активности", "активностей"])
        : "";
      const photo = a.photo_url;

      return (
        <AOtherUserActiveItem
          id={id}
          key={i}
          title={name}
          description={count}
          photo={photo}
          openView={props.openView}
        />
      );
    });
  };

  const handlePeriod = () => {
    if (loading) return false;

    props.openPopout({
      popout: (
        <PopoutTemplateDateChangePeriod
          openView={props.openView}
          period={period} // Период из redux store
          setPeriod={handleSetPeriodOtherUserActive} // action из redux
          backPanel={PANEL_A__OTHER_USER_ACTIVE}
          backView={VIEW_TOOL}
          panelTitle={"Активности"}
          closePopout={props.closePopout}
        />
      ),
    });
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      {renderPeriodV2({ title: "Активные пользователи", period: period, handler: handlePeriod })}
      {renderUser()}
      <Group>
        {loading ? <LoaderWithProgressBar progressValue={progressValue} /> : ""}
        {activity !== null && error === null ? renderActivity() : ""}
        {!loading &&
        activity !== null &&
        Object.prototype.toString.call(activity) === "[object Array]" &&
        error === null &&
        activity.length <= 0 ? (
          <Placeholder icon={<Icon56LikeOutline />}>Активные пользователи не найдены</Placeholder>
        ) : (
          ""
        )}
      </Group>
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchOtherUserActive,
      })}
    </div>
  );
};

export default AOtherUserActiveList;
