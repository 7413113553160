import { queryEye3 } from "api";

async function APIEye3GetLicense() {
  try {
    const response = await queryEye3({
      method: "license.get",
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3GetLicense;
