import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Placeholder, SimpleCell } from "@vkontakte/vkui";
import ViewPageItem from "../ViewPageItem/ViewPageItem";
import SeparateFilter from "template/components/SeparateFilter/SeparateFilter";
import Icon28ViewOutline from "@vkontakte/icons/dist/28/view_outline";

import { unixTimeToDate, convertMonthDay, convertTime } from "helpers";

import { fetchViewPage } from "store/actions/profile/viewPage";
import { declOfNum } from "helpers";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import HeadTitle from "template/components/HeadTitle/HeadTitle";

const ViewPageList = () => {
  const dispatch = useDispatch();
  const activeStory = useSelector((state) => state.page.activeStory);
  const profileHistory = useSelector((state) => state.profile.history);

  const views = useSelector((state) => state.profileViewPage.payload);
  const error = useSelector((state) => state.profileViewPage.error);
  const loading = useSelector((state) => state.profileViewPage.loading);

  const handleFetchViewPage = useCallback(() => dispatch(fetchViewPage()), [dispatch]);

  let lastAt = new Date();

  useEffect(() => {
    handleFetchViewPage();
  }, [handleFetchViewPage]);

  const renderTopPanel = () => {
    const user = profileHistory[activeStory].user;
    const name = user.name;
    const city = user.city ? user.city : "";
    const age = user.age ? user.age : "";
    const photo = user.photo_url ? user.photo_url : "";
    const ageStrFormat = age ? age + " " + declOfNum(age, ["год", "года", "лет"]) : "";
    const comma = ageStrFormat && city ? ", " : "";

    return (
      <SimpleCell
        disabled
        before={<Avatar size={72} src={photo} />}
        description={ageStrFormat + comma + city}
      >
        {name}
      </SimpleCell>
    );
  };

  const renderViewPage = () => {
    return views.length > 0 ? (
      views.map((view, i) => {
        const id = view.vk_id;
        const at = unixTimeToDate(view._at);
        const isMobile = view.hasOwnProperty("is_mobile") && view.is_mobile === true;
        const sex = profileHistory[activeStory].user.sex;

        let separate = <SeparateFilter>{convertMonthDay(at)}</SeparateFilter>;

        if (lastAt.getDate() === at.getDate() && i !== 0) {
          separate = "";
        }

        lastAt = at;

        return (
          <div key={i}>
            {separate}
            <ViewPageItem id={id} title={convertTime(at)} isOnlineMobile={isMobile} sex={sex} />
          </div>
        );
      })
    ) : (
      <Placeholder icon={<Icon28ViewOutline width={56} height={56} />}>
        Просмотры не найдены
      </Placeholder>
    );
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      <HeadTitle>Просмотры моего профиля</HeadTitle>
      {renderTopPanel()}
      {views !== null ? renderViewPage() : ""}
      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchViewPage,
      })}
    </div>
  );
};

export default ViewPageList;
