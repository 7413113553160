import { getSearchObject } from "api";
import { DESKTOP_WEB, MOBILE_ANDROID, MOBILE_IPHONE, MOBILE_WEB } from "api/vk/constPlatform";

// Преобразования даты в формат 00:00
export const convertTime = (date) => {
  let hour = date.getHours();
  let min = date.getMinutes();
  //let sec = date.getSeconds();

  min = min < 10 ? "0" + min : min;
  hour = hour < 10 ? "0" + hour : hour;
  //second = (sec < 10) ? '0' + sec : sec;

  let result = hour + ":" + min;

  return result;
};

// Расчет времени между
export const calcTimeBetween = (firstDate, secondDate) => {
  let different = (secondDate - firstDate) / 1000;

  let hour = (different / 3600) ^ 0;
  let min = ((different - hour * 3600) / 60) ^ 0;
  let result = "";

  if (hour > 0) result = `${hour} ч ${min} мин`;
  else result = `${min} мин`;

  return result;
};

// Получение строки врмени онлайна по дате
export const calcLastOnlineTime = (date) => {
  const currentDate = new Date();

  let min = date.getMinutes();
  let hour = date.getHours();
  const day = date.getDate();
  const month = date.getMonth();

  let time = null;
  let result = null;
  let days = Math.abs(Math.ceil((date.getTime() - currentDate.getTime()) / (1000 * 3600 * 24)));
  let weeks = Math.abs(
    Math.round((date.getTime() - currentDate.getTime()) / (24 * 3600 * 1000 * 7))
  );
  let months = Math.abs(
    Math.round(
      date.getMonth() +
        12 * date.getFullYear() -
        (currentDate.getMonth() + 12 * currentDate.getFullYear())
    )
  );

  const isYesterday = currentDate.getDate() - day; // Если вчерашний день

  if (currentDate.getDate() === day || (isYesterday === 1 && currentDate.getMonth() === month)) {
    // Если день один и тот же

    min = min < 10 ? "0" + min : min;
    hour = hour < 10 ? "0" + hour : hour;
    time = hour + ":" + min;

    if (isYesterday === 1) {
      result = "заходил(а) вчера в " + time;
    } else {
      result = "заходил(а) в " + time;
    }
  } else if (days < 7 && date.getFullYear() === currentDate.getFullYear()) {
    time = days;
    result = "заходил(а) " + time + declOfNum(time, [" день", " дня", " дней"]) + " назад";
  } else if (weeks < 4 && date.getFullYear() === currentDate.getFullYear()) {
    time = weeks;
    result = "заходил(а) " + time + declOfNum(time, [" неделю", " неделе", " неделе"]) + " назад";
  } else if (months < 12) {
    time = months;
    result = "заходил(а) " + time + declOfNum(time, [" месяц", " месяца", " месяцев"]) + " назад";
  } else if (currentDate.getFullYear() > date.getFullYear()) {
    time = currentDate.getFullYear() - date.getFullYear();
    result = "заходил(а) " + time + declOfNum(time, [" год", " года", " лет"]) + " назад";
  } else {
    result = "давно не был(а)";
  }

  return result;
};

// Получение месяца по дате
export const convertMonthDay = (date) => {
  let month = date.getMonth();
  let day = date.getDate();
  let year = date.getFullYear();
  let monthList = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
  ];
  let currentDate = new Date();

  let result = day + " " + monthList[month];

  if (currentDate.getFullYear() !== year) result += " " + year;

  if (
    currentDate.getDate() === day &&
    currentDate.getMonth() === month &&
    currentDate.getFullYear() === year
  )
    result = "Сегодня";
  else if (
    currentDate.getDate() - 1 === day &&
    currentDate.getMonth() === month &&
    currentDate.getFullYear() === year
  )
    result = "Вчера";

  return result;
};

// Расчет возраста по дате
export const calcAge = (date) => {
  let birthDate = date.split(".");

  if (birthDate.length === 3) {
    let age =
      ((new Date().getTime() -
        new Date(parseInt(birthDate[2]), parseInt(birthDate[1]), parseInt(birthDate[0]))) /
        (24 * 3600 * 365.25 * 1000)) |
      0;
    return age;
  }

  return null;
};

// Получить дату начала недели
export const getDateStartOfWeek = (date) => {
  date = date ? new Date(+date) : new Date();
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() - date.getDay() + 1);
  return date;
};

// Получить дату конца недели
export const getDateEndOfWeek = (date) => {
  date = getDateStartOfWeek(date);
  date.setDate(date.getDate() + 6);
  return date;
};

// Преобразование unixTime в дату
export const unixTimeToDate = (value) => {
  return new Date(value * 1000);
};

// Преобразование даты в unixTime
export const dateToUnixTime = (value) => {
  return Date.parse(value) / 1000;
};

// Задержка для промиса
export const delay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

// Функция преобразование строки в зависимости от числа
export const declOfNum = (n, textForms) => {
  n = Math.abs(n) % 100;
  let n1 = n % 10;
  if (n > 10 && n < 20) return textForms[2];
  if (n1 > 1 && n1 < 5) return textForms[1];
  if (n1 === 1) return textForms[0];
  return textForms[2];
};

// Функция сравнения
export const compareIf = (post, operator, value) => {
  switch (operator) {
    case ">":
      return post > value;
    case "<":
      return post < value;
    case ">=":
      return post >= value;
    case "<=":
      return post <= value;
    case "===":
      return post === value;
    case "!==":
      return post !== value;
    default:
      return false;
  }
};

export const getHashRef = () => {
  const hash = window.location.hash;
  const refIndex = hash.indexOf("#ref");
  //console.log("refIndex: ", refIndex);
  if (refIndex === 0) {
    //console.log("hash: ", hash.slice(4));
    return hash.slice(4);
  } else {
    return false;
  }
};

export const gotoUrl = (url) => {
  if (
    getSearchObject().vk_platform === DESKTOP_WEB ||
    getSearchObject().vk_platform === MOBILE_WEB
  ) {
    window.open(url, "_blank");
  } else if (getSearchObject().vk_platform === MOBILE_IPHONE) {
    window.location.href = url;
  } else if (getSearchObject().vk_platform === MOBILE_ANDROID) {
    window.open(url);
  }
};
