import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";

import { Panel } from "@vkontakte/vkui";
import TOUserList from "./components/TOUserList/TOUserList";
import ToolBanner from "template/components/ToolBanner/ToolBanner";
import TimeIcon from "assets/img/tool/timeOnline/banner/time.png";
import { withRouter } from "hoc";

const PanelTimeOnline = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Время онлайн</PanelHeaderWithPath>
      <ToolBanner
        tool="toolTimeOnline"
        icon={TimeIcon}
        text="Узнавайте в какое время пользователи были в сети и сравнивайте кто был онлайн в определенное время"
      />
      <TOUserList openView={props.openView} />
    </Panel>
  );
};

export default withRouter(PanelTimeOnline);
