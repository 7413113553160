import React, { useState, useEffect } from "react";
import {
  ModalPage,
  ModalPageHeader,
  Select,
  FormLayout,
  FormLayoutGroup,
  Div,
  PanelHeaderButton,
  Button,
} from "@vkontakte/vkui";
import { APIVkGetCountriesDatabase, APIVkGetCitiesDatabase } from "api/vk/apiMethods";
import Icon28ArrowLeftOutline from "@vkontakte/icons/dist/28/arrow_left_outline";

const FilterSexAgeCity = (props) => {
  const [cities, setCities] = useState("");
  const [countries, setCountries] = useState("");

  const modalParam = props.modalParam;
  const filter = modalParam ? modalParam.filter : null;

  const [selectedFilters, setSelectedFilters] = useState({
    // Объект фильтров
    age_from: filter ? filter.age_from : "",
    age_to: filter ? filter.age_to : "",
    country_id: filter ? filter.country_id : "",
    city_id: filter ? filter.city_id : "",
  });

  useEffect(() => {
    APIVkGetCountriesDatabase().then(async (country) => {
      setCountries(country.response);

      if (selectedFilters.country_id !== "") {
        const cities = await APIVkGetCitiesDatabase({
          countryId: selectedFilters.country_id,
        });
        setCities(cities.response);
      }
    });
  }, [selectedFilters.country_id]);

  const getAges = () => {
    let ages = [];
    for (let i = 14; i <= 80; i++) {
      ages.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }

    return ages;
  };

  const onChangeAgeFrom = (e) => {
    //console.log(e.target.value);
    setSelectedFilters({
      ...selectedFilters,
      age_from: e.target.value,
    });
  };

  const onChangeAgeTo = (e) => {
    //console.log(e.target.value);
    setSelectedFilters({
      ...selectedFilters,
      age_to: e.target.value,
    });
  };

  const onChangeCountry = async (e) => {
    setCities("");

    setSelectedFilters({
      ...selectedFilters,
      city_id: "",
      country_id: e.target.value,
    });

    if (e.target.value !== "") {
      const cities = await APIVkGetCitiesDatabase({
        countryId: e.target.value,
      });

      setCities(cities.response);
    }
  };

  const onChangeCity = (e) => {
    //console.log(e.target.value);

    setSelectedFilters({
      ...selectedFilters,
      city_id: e.target.value,
    });
  };

  const showResult = async () => {
    modalParam.closeModal();
    await modalParam.applyFilter(selectedFilters);
  };

  const reset = async () => {
    modalParam.closeModal();
    await modalParam.applyFilter("");
  };

  return (
    <ModalPage
      id={props.id}
      header={
        <ModalPageHeader
          separator={false}
          left={
            <PanelHeaderButton onClick={() => modalParam.closeModal()}>
              <Icon28ArrowLeftOutline />
            </PanelHeaderButton>
          }
          right={<PanelHeaderButton onClick={reset}>Очистить</PanelHeaderButton>}
        >
          Фильтры
        </ModalPageHeader>
      }
    >
      <FormLayout>
        <FormLayoutGroup
          top="Возраст"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            maxHeight: "44px",
          }}
        >
          <Select
            placeholder="От"
            style={{ width: "100%" }}
            value={selectedFilters.age_from}
            onChange={onChangeAgeFrom}
          >
            {getAges()}
          </Select>
          <Select
            placeholder="До"
            style={{ width: "100%" }}
            value={selectedFilters.age_to}
            onChange={onChangeAgeTo}
          >
            {getAges()}
          </Select>
        </FormLayoutGroup>
        <Select
          top="Страна"
          placeholder="Выбрать страну"
          defaultValue={selectedFilters.country_id}
          onChange={onChangeCountry}
        >
          {countries !== "" &&
            countries.items.map((country, i) => {
              return (
                <option key={i} value={country.id}>
                  {" "}
                  {country.title}{" "}
                </option>
              );
            })}
        </Select>
        <Select
          top="Город"
          placeholder="Выбрать город"
          defaultValue={selectedFilters.city_id}
          onChange={onChangeCity}
          disabled={cities !== "" ? false : true}
        >
          {cities !== "" &&
            cities.items.map((city, i) => {
              return (
                <option key={i} value={city.id}>
                  {" "}
                  {city.title}{" "}
                </option>
              );
            })}
        </Select>
      </FormLayout>

      <Div>
        <Button size="xl" stretched onClick={showResult}>
          Показать результаты
        </Button>
      </Div>
    </ModalPage>
  );
};

export default FilterSexAgeCity;
