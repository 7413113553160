import React, { useCallback, useEffect, useState } from "react";
import { Group, Placeholder, Search } from "@vkontakte/vkui";
import MentionUsersItem from "../MUserItem/MUserItem";
import { useDispatch, useSelector } from "react-redux";
import { declOfNum } from "helpers";
import {
  fetchMention,
  filterMention,
  searchMention,
  showMoreMention,
} from "store/actions/tool/mention";
import Icon24Filter from "@vkontakte/icons/dist/24/filter";
import Icon56UsersOutline from "@vkontakte/icons/dist/56/users_outline";
import { MODAL_PAGE_TEMPLATE_FILTER_SEX_AGE_CITY } from "template/modals/constModals";
import { useDebounceSearch, useShowMore } from "hooks";
import SecondaryTitle from "template/components/SecondaryTitle/SecondaryTitle";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";

const MUserList = (props) => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const loading = useSelector((state) => state.toolMention.friends.loading);
  const search = useSelector((state) => state.toolMention.friends.search);
  const friends = useSelector((state) => state.toolMention.friends.payload);
  const filter = useSelector((state) => state.toolMention.friends.filter);
  const isOnce = useSelector((state) => state.toolMention.friends.isOnce);
  const error = useSelector((state) => state.toolMention.friends.error);
  const showCount = useSelector((state) => state.toolMention.friends.showCount);
  const [searchTerm, setSearchTerm] = useState(search);

  const handleFilterMention = useCallback((filter) => dispatch(filterMention(filter)), [dispatch]);
  const handleSearchMention = useCallback((search) => dispatch(searchMention(search)), [dispatch]);
  const handleShowMoreMention = useCallback(() => dispatch(showMoreMention()), [dispatch]);
  const handleFetchMention = useCallback(() => dispatch(fetchMention()), [dispatch]);

  useShowMore({
    items: friends,
    showCount: showCount,
    maxLength: friends !== null ? friends.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMoreMention,
  });

  useEffect(() => {
    if (!isOnce) handleFetchMention();
  }, [handleFetchMention, isOnce]);

  useDebounceSearch({
    search: search,
    searchTerm: searchTerm,
    handler: handleSearchMention,
  });

  const renderMentionList = () => {
    return friends.length > 0 ? (
      friends.map((friend, i) => {
        const id = friend.vk_id;
        const name = friend.name;
        const city = friend.city ? friend.city : "";
        const age = friend.age ? friend.age : "";
        const photo = friend.photo_url ? friend.photo_url : "";

        const ageStrFormat = age ? age + " " + declOfNum(age, ["год", "года", "лет"]) : "";
        const comma = ageStrFormat && city ? ", " : "";

        if (i < showCount) {
          return (
            <MentionUsersItem
              title={name}
              id={id}
              key={i}
              description={ageStrFormat + comma + city}
              photo={photo}
              openView={props.openView}
            />
          );
        }

        return false;
      })
    ) : (
      <Placeholder icon={<Icon56UsersOutline />}>Друзья не найдены</Placeholder>
    );
  };

  const handleFilter = () => {
    if (loading) return;

    props.openModal({
      id: MODAL_PAGE_TEMPLATE_FILTER_SEX_AGE_CITY,
      param: {
        filter: filter,
        closeModal: props.closeModal,
        applyFilter: handleFilterMention,
      },
    });
  };
  return (
    <div>
      <SecondaryTitle style={{ marginTop: "5px" }}>
        Выберите пользователя из списка друзей или воспользуйтесь поиском.
      </SecondaryTitle>

      <Search
        value={searchTerm}
        icon={<Icon24Filter />}
        placeholder={"Имя или ссылка на страницу"}
        onIconClick={handleFilter}
        onChange={(e) => {
          if (loading) return;

          setSearchTerm(e.target.value);
        }}
      />

      <Group style={{ marginBottom: "20px" }}>
        {friends !== null && error === null ? renderMentionList() : ""}
      </Group>
      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchMention,
      })}
    </div>
  );
};

export default MUserList;
