import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Group, Search, Placeholder, Div, Button } from "@vkontakte/vkui";
import TOStartTrackingItem from "../TOStartTrackingItem/TOStartTrackingItem";
import Icon24Filter from "@vkontakte/icons/dist/24/filter";
import Icon56UsersOutline from "@vkontakte/icons/dist/56/users_outline";

import {
  fetchStartTracking,
  showMoreStartTracking,
  filterStartTracking,
  searchStartTracking,
  checkedStartTracking,
  setTracking,
} from "store/actions/tool/timeOnline";

import {
  MODAL_CARD_TEMPLATE_TARIFF_LIMIT,
  MODAL_PAGE_TEMPLATE_FILTER_SEX_AGE_CITY,
} from "template/modals/constModals";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import HeadTitle from "template/components/HeadTitle/HeadTitle";
import { useDebounceSearch, useShowMore } from "hooks";
import { declOfNum } from "helpers";
import SecondaryTitle from "template/components/SecondaryTitle/SecondaryTitle";

const TOStartTrackingList = (props) => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const handleFetchStartTracking = useCallback(() => dispatch(fetchStartTracking()), [dispatch]);
  const handleSetTracking = useCallback((ids) => dispatch(setTracking(ids)), [dispatch]);
  const loading = useSelector((state) => state.toolTimeOnline.startTracking.loading);
  const search = useSelector((state) => state.toolTimeOnline.startTracking.search);
  const filter = useSelector((state) => state.toolTimeOnline.startTracking.filter);
  const error = useSelector((state) => state.toolTimeOnline.startTracking.error);
  const allowCount = useSelector((state) => state.toolTimeOnline.trackingUser.allowCount);
  const showCount = useSelector((state) => state.toolTimeOnline.startTracking.showCount);
  const startTrackingSelected = useSelector((state) => state.toolTimeOnline.startTracking.selected);
  const startTrackingUser = useSelector((state) => state.toolTimeOnline.startTracking.payload);

  const [searchTerm, setSearchTerm] = useState(search);

  const handleSearchStartTracking = useCallback(
    (search) => dispatch(searchStartTracking(search)),
    [dispatch]
  );
  const handleCheckedStartTracking = useCallback(
    (value, checked) => dispatch(checkedStartTracking(value, checked)),
    [dispatch]
  );
  const handleFilterStartTracking = useCallback(
    (filter) => dispatch(filterStartTracking(filter)),
    [dispatch]
  );
  const handleShowMoreStartTracking = useCallback(
    () => dispatch(showMoreStartTracking()),
    [dispatch]
  );

  useShowMore({
    items: startTrackingUser,
    showCount: showCount,
    maxLength: startTrackingUser !== null ? startTrackingUser.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMoreStartTracking,
  });

  useEffect(() => {
    handleFetchStartTracking();
  }, [handleFetchStartTracking]);

  useDebounceSearch({
    search: search,
    searchTerm: searchTerm,
    handler: handleSearchStartTracking,
  });

  const onChangeUser = (e) => {
    const value = parseInt(e.target.value);
    const checked = e.target.checked;
    const selected = startTrackingSelected !== null ? startTrackingSelected.length : 0;

    if (checked && selected >= allowCount) {
      props.openModal({ id: MODAL_CARD_TEMPLATE_TARIFF_LIMIT });
    }

    //console.log(value + ':' + checked);
    handleCheckedStartTracking(value, checked);
  };

  const renderFreeSlot = () => {
    const current = startTrackingSelected !== null ? startTrackingSelected.length : 0;
    const free = allowCount;
    let res = free - current;

    const resStrFormat = res
      ? res + " " + declOfNum(res, ["пользователь", "пользователя", "пользователей"])
      : "0 пользователей";

    if (res < 0) res = 0;

    return "Вам доступно еще " + resStrFormat;
  };

  const renderStartTrackingUser = () => {
    return startTrackingUser.length > 0 ? (
      startTrackingUser.map((user, i) => {
        const id = user.vk_id;
        const name = user.name;
        const sex = user.sex;
        const photo = user.photo_url ? user.photo_url : "";
        const city = user.city ? user.city : "";
        const age = user.age ? user.age : "";
        const ageStrFormat = age ? age + " " + declOfNum(age, ["год", "года", "лет"]) : "";
        const comma = ageStrFormat && city ? ", " : "";

        const typeTracking = user.hasOwnProperty("type_tracking") ? user.type_tracking : "none";
        const selected = user.hasOwnProperty("selected") ? user.selected : false;

        if (i < showCount) {
          return (
            <TOStartTrackingItem
              key={i}
              id={id}
              title={name}
              sex={sex}
              description={ageStrFormat + comma + city}
              typeTracking={typeTracking}
              selected={selected}
              onChangeUser={onChangeUser}
              openView={props.openView}
              photo={photo}
            />
          );
        }

        return false;
      })
    ) : (
      <Placeholder icon={<Icon56UsersOutline />}>Друзья не найдены</Placeholder>
    );
  };

  const handleFilter = () => {
    if (loading) return;

    props.openModal({
      id: MODAL_PAGE_TEMPLATE_FILTER_SEX_AGE_CITY,
      param: {
        filter: filter,
        closeModal: props.closeModal,
        applyFilter: handleFilterStartTracking,
      },
    });
  };

  const handleSave = async () => {
    const selected = startTrackingSelected !== null ? startTrackingSelected.length : 0;
    if (selected > allowCount) {
      props.openModal({ id: MODAL_CARD_TEMPLATE_TARIFF_LIMIT });
      return;
    }

    handleSetTracking(startTrackingSelected.join(","));
  };

  return (
    <>
      <HeadTitle>Добавить на отслеживание</HeadTitle>
      <SecondaryTitle>
        Отметьте пользователей, чье время онлайн вы хотите отслеживать. Максимальное число
        пользователей для отслеживания на вашем тарифе – {allowCount}.
      </SecondaryTitle>
      <Search
        value={searchTerm}
        icon={<Icon24Filter />}
        placeholder={"Имя или ссылка на страницу"}
        onIconClick={handleFilter}
        onChange={(e) => {
          if (loading) return;

          setSearchTerm(e.target.value);
        }}
      />
      <SecondaryTitle style={{ textTransform: "uppercase", fontSize: "13px" }}>
        {!loading && renderFreeSlot()}
      </SecondaryTitle>
      <Group style={{ marginBottom: "20px" }}>
        {startTrackingUser !== null && error === null ? renderStartTrackingUser() : ""}
        {renderLoader({ loading: loading })}
      </Group>
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchStartTracking,
      })}
      <div
        style={{
          position: "fixed",
          width: "100%",
          bottom: "46px",
          zIndex: "10",
          backgroundColor: "var(--background_content)",
        }}
      >
        <Div>
          <Button size="xl" style={{ width: "100%" }} disabled={loading} onClick={handleSave}>
            Сохранить
          </Button>
        </Div>
      </div>
    </>
  );
};

export default TOStartTrackingList;
