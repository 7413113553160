import React from "react";
import {
  Icon28WalletOutline,
  Icon28GiftOutline,
  Icon24ArrowUturnLeftOutline,
  Icon28ShoppingCartOutline,
} from "@vkontakte/icons";
import { Avatar, SimpleCell } from "@vkontakte/vkui";
import {
  ACTIVITY,
  BALANCE_REPLENISHMENT,
  BUY_TARIFF,
  EXTENSION_TARIFF,
  REFUND,
} from "../../constHistoryType";
import { declOfNum } from "helpers";
import styles from "./HistoryItem.module.scss";

const HistoryItem = (props) => {
  const getBefore = () => {
    switch (props.txt) {
      case BALANCE_REPLENISHMENT:
      case EXTENSION_TARIFF:
        return (
          <Avatar size={40} style={{ background: "#B8C1CC" }}>
            <Icon28WalletOutline fill="var(--white)" />
          </Avatar>
        );
      case ACTIVITY:
        return (
          <Avatar size={40} style={{ background: "#B8C1CC" }}>
            <Icon28GiftOutline fill="var(--white)" />
          </Avatar>
        );
      case BUY_TARIFF:
        return (
          <Avatar size={40} style={{ background: "#B8C1CC" }}>
            <Icon28ShoppingCartOutline fill="var(--white)" />
          </Avatar>
        );
      case REFUND:
        return (
          <Avatar size={40} style={{ background: "#B8C1CC" }}>
            <Icon24ArrowUturnLeftOutline fill="var(--white)" width={28} height={28} />
          </Avatar>
        );
      default:
        return "";
    }
  };

  const getAfter = () => {
    const balanceStr = props.count + " " + declOfNum(props.count, ["монета", "монеты", "монет"]);
    return props.count > 0 ? (
      <span
        className={styles.title}
        style={{
          color: "#4BB34B",
        }}
      >
        {"+" + balanceStr}
      </span>
    ) : (
      <span className={styles.title}>{balanceStr}</span>
    );
  };

  return (
    <SimpleCell before={getBefore()} after={getAfter()} disabled multiline>
      <span className={styles.txt}>{props.txt}</span>
    </SimpleCell>
  );
};

HistoryItem.defaultProps = {
  count: 0,
  type: "",
  txt: "",
};

export default HistoryItem;
