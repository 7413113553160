import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Group, Placeholder } from "@vkontakte/vkui";
import FIAAppsFromFriendsItem from "../FIAAppsFromFriendsItem/FIAAppsFromFriendsItem";

import {
  fetchAppsFromFriends,
  showMoreAppsFromFriends,
  cancelAppsFromFriends,
} from "store/actions/tool/friendsInApp";

import LoaderWithProgressBar from "template/components/LoaderWithProgressBar/LoaderWithProgressBar";
import { renderErrorPlaceholder } from "helpers/views";
import { declOfNum } from "helpers";
import { useShowMore } from "hooks";
import Icon56UsersOutline from "@vkontakte/icons/dist/56/users_outline";
import HeadTitle from "template/components/HeadTitle/HeadTitle";

const FIAAppsFromFriendsList = (props) => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const loading = useSelector((state) => state.toolFriendsInApp.appsFromFriends.loading);
  const error = useSelector((state) => state.toolFriendsInApp.appsFromFriends.error);
  const showCount = useSelector((state) => state.toolFriendsInApp.appsFromFriends.showCount);
  const appsFromFriends = useSelector((state) => state.toolFriendsInApp.appsFromFriends.payload);
  const progressValue = useSelector(
    (state) => state.toolFriendsInApp.appsFromFriends.progressValue
  );
  const handleFetchAppsFromFriends = useCallback(
    () => dispatch(fetchAppsFromFriends()),
    [dispatch]
  );
  const handleShowMoreAppsFromFriends = useCallback(
    () => dispatch(showMoreAppsFromFriends()),
    [dispatch]
  );
  const handleCancelAppsFromFriends = useCallback(
    () => dispatch(cancelAppsFromFriends()),
    [dispatch]
  );

  useShowMore({
    items: appsFromFriends,
    showCount: showCount,
    maxLength: appsFromFriends !== null ? appsFromFriends.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMoreAppsFromFriends,
  });

  useEffect(() => {
    handleFetchAppsFromFriends();
  }, [handleFetchAppsFromFriends]);

  useEffect(() => {
    return () => {
      handleCancelAppsFromFriends();
    };
  }, [handleCancelAppsFromFriends]);

  const renderAppsFromFriends = () => {
    return appsFromFriends.length > 0
      ? appsFromFriends.map((friend, i) => {
          const id = friend.vk_id;
          const name = friend.name;
          const photoUrl = friend.photo_url;
          const appCount = friend.count;

          const appCountStrFormat = appCount
            ? appCount + " " + declOfNum(appCount, ["приложение", "приложения", "приложений"])
            : "0 приложений";

          if (i < showCount) {
            return (
              <FIAAppsFromFriendsItem
                key={i}
                id={id}
                title={name}
                photo={photoUrl}
                description={appCountStrFormat}
                openView={props.openView}
              />
            );
          }
          return false;
        })
      : "";
  };

  return (
    <>
      <HeadTitle>Приложения у друзей</HeadTitle>
      {loading ? <LoaderWithProgressBar progressValue={progressValue} /> : ""}

      {appsFromFriends !== null && error === null ? (
        <Group style={{ marginBottom: "20px" }}>{renderAppsFromFriends()}</Group>
      ) : (
        ""
      )}

      {Object.prototype.toString.call(appsFromFriends) === "[object Array]" &&
      !loading &&
      appsFromFriends.length <= 0 &&
      error === null ? (
        <Placeholder icon={<Icon56UsersOutline />}>Друзья не найдены</Placeholder>
      ) : (
        ""
      )}

      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchAppsFromFriends,
      })}
    </>
  );
};

export default FIAAppsFromFriendsList;
