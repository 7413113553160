import React from "react";
import { Avatar, Cell } from "@vkontakte/vkui";

const TOStartTrackingItem = (props) => {
  return (
    <Cell
      selectable
      onChange={(e) => props.onChangeUser(e)}
      value={props.id}
      defaultChecked={props.selected}
      before={<Avatar size={48} src={props.photo} />}
      description={props.description}
    >
      {props.title}
    </Cell>
  );
};

export default TOStartTrackingItem;
