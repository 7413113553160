import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMaritalInfo } from "store/actions/tool/maritalStatus";
import HeadTitle from "template/components/HeadTitle/HeadTitle";
import MSInfoTopPanel from "../MSInfoTopPanel/MSInfoTopPanel";

import SeparateFilter from "template/components/SeparateFilter/SeparateFilter";
import { convertMonthDay, convertTime, unixTimeToDate } from "helpers";
import { Placeholder } from "@vkontakte/vkui";
import MSInfoItem from "../MSInfoItem/MSInfoItem";
import { Icon56UsersOutline } from "@vkontakte/icons";

const MSInfoList = (props) => {
  const dispatch = useDispatch();
  const maritalStatus = useSelector((state) => state.toolMaritalStatus.marital.payload);
  const error = useSelector((state) => state.toolMaritalStatus.marital.error);
  const loading = useSelector((state) => state.toolMaritalStatus.marital.loading);
  const activeUserId = useSelector((state) => state.toolMaritalStatus.marital.activeUserId);
  const activeUserTitle = useSelector((state) => state.toolMaritalStatus.marital.activeUserTitle);
  const activeUserDescription = useSelector(
    (state) => state.toolMaritalStatus.marital.activeUserDescription
  );
  const activeUserPhoto = useSelector((state) => state.toolMaritalStatus.marital.activeUserPhoto);
  const activeUserSex = useSelector((state) => state.toolMaritalStatus.marital.activeUserSex);
  const handleFetchMaritalInfo = useCallback(() => dispatch(fetchMaritalInfo()), [dispatch]);

  let lastAt = new Date();

  useEffect(() => {
    handleFetchMaritalInfo();
  }, [handleFetchMaritalInfo]);

  const renderUser = () => {
    return (
      <MSInfoTopPanel
        id={activeUserId}
        title={activeUserTitle}
        description={activeUserDescription}
        photo={activeUserPhoto}
        openView={props.openView}
      />
    );
  };

  const renderMaritalStatus = () => {
    return maritalStatus.length > 0 ? (
      maritalStatus.map((status, i) => {
        const relation = status.hasOwnProperty("relation") ? status.relation : "";
        const partnerId = status.hasOwnProperty("relation_partner") ? status.relation_partner : "";
        const partnerName = status.hasOwnProperty("relation_partner") ? status.name : "";
        const partnerPhoto = status.hasOwnProperty("relation_partner") ? status.photo_url : "";
        const at = unixTimeToDate(status._at);
        const sex = activeUserSex;

        let separate = <SeparateFilter>{convertMonthDay(at)}</SeparateFilter>;

        if (lastAt.getDate() === at.getDate() && i !== 0) {
          separate = "";
        }

        lastAt = at;

        return (
          <div key={i}>
            {separate}
            <MSInfoItem
              key={i}
              partnerId={partnerId}
              relation={relation}
              partnerName={partnerName}
              partnerPhoto={partnerPhoto}
              time={convertTime(at)}
              sex={sex}
            />
          </div>
        );
      })
    ) : (
      <Placeholder icon={<Icon56UsersOutline />}>Смены семейного положения не найдены</Placeholder>
    );
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      <HeadTitle>Семейное положение</HeadTitle>
      {renderUser()}
      {maritalStatus !== null && error === null ? renderMaritalStatus() : ""}
      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchMaritalInfo,
      })}
    </div>
  );
};

export default MSInfoList;
