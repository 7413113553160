import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Group, Placeholder } from "@vkontakte/vkui";

import { fetchInfoGroup } from "store/actions/tool/adminCommunity";

import ACInfoTopPanel from "../ACInfoTopPanel/ACInfoTopPanel";
import ACInfoItem from "../ACInfoItem/ACInfoItem";
import Icon56Users3Outline from "@vkontakte/icons/dist/56/users_3_outline";

import { declOfNum } from "helpers";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import HeadTitle from "template/components/HeadTitle/HeadTitle";

const ACInfoList = (props) => {
  const dispatch = useDispatch();
  const activeUserId = useSelector((state) => state.toolAdminCommunity.groups.activeUserId);
  const activeUserTitle = useSelector((state) => state.toolAdminCommunity.groups.activeUserTitle);
  const activeUserDescription = useSelector(
    (state) => state.toolAdminCommunity.groups.activeUserDescription
  );
  const activeUserPhoto = useSelector((state) => state.toolAdminCommunity.groups.activeUserPhoto);
  const loading = useSelector((state) => state.toolAdminCommunity.groups.loading);
  const error = useSelector((state) => state.toolAdminCommunity.groups.error);
  const groups = useSelector((state) => state.toolAdminCommunity.groups.payload);
  const handleFetchInfoGroup = useCallback((id) => dispatch(fetchInfoGroup(id)), [dispatch]);

  useEffect(() => {
    handleFetchInfoGroup();
  }, [handleFetchInfoGroup]);

  const renderUser = () => {
    const groupsCount = groups.length;
    const groupsCountStrFormat = groupsCount
      ? groupsCount + " " + declOfNum(groupsCount, ["сообщество", "сообщества", "сообществ"])
      : "0 сообществ";

    return (
      <ACInfoTopPanel
        id={activeUserId}
        title={activeUserTitle}
        photo={activeUserPhoto}
        description={activeUserDescription}
        bottomContent={groupsCountStrFormat}
        openView={props.openView}
      />
    );
  };

  const renderGroup = () => {
    return groups.length > 0 ? (
      groups.map((group, i) => {
        const id = group.vk_id;
        const name = group.name;
        const photo = group.photo_url ? group.photo_url : "";
        const membersCount = group.members_count;
        const membersCountStrFormat = membersCount
          ? membersCount + " " + declOfNum(membersCount, ["участник", "участника", "участников"])
          : "";

        return (
          <ACInfoItem
            key={i}
            id={id}
            title={name}
            photo={photo}
            description={membersCountStrFormat}
          />
        );
      })
    ) : (
      <Placeholder icon={<Icon56Users3Outline />}>Сообщества не найдены</Placeholder>
    );
  };

  return (
    <>
      <HeadTitle>Администрируемые</HeadTitle>
      {groups !== null && error === null ? <Group separator="hide">{renderUser()}</Group> : ""}
      {groups !== null && error === null ? (
        <Group style={{ marginBottom: "20px" }}>{renderGroup()}</Group>
      ) : (
        ""
      )}
      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchInfoGroup,
      })}
    </>
  );
};

export default ACInfoList;
