import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Placeholder, SimpleCell, Avatar } from "@vkontakte/vkui";
import Icon28WriteOutline from "@vkontakte/icons/dist/28/write_outline";
import InfoHistoryItem from "../InfoHistoryItem/InfoHistoryItem";
import SeparateFilter from "template/components/SeparateFilter/SeparateFilter";

import { unixTimeToDate, convertMonthDay, convertTime } from "helpers";

import { fetchInfoHistory, showMoreInfoHistory } from "store/actions/profile/infoHistory";
import { declOfNum } from "helpers";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import { useShowMore } from "hooks";
import HeadTitle from "template/components/HeadTitle/HeadTitle";

const InfoHistoryList = () => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const activeStory = useSelector((state) => state.page.activeStory);
  const profileHistory = useSelector((state) => state.profile.history);

  const loading = useSelector((state) => state.profileInfoHistory.loading);
  const error = useSelector((state) => state.profileInfoHistory.error);
  const infoHistory = useSelector((state) => state.profileInfoHistory.payload);
  const showCount = useSelector((state) => state.profileInfoHistory.showCount);

  const handleFetchInfoHistory = useCallback(() => dispatch(fetchInfoHistory()), [dispatch]);
  const handleShowMoreInfoHistory = useCallback(() => dispatch(showMoreInfoHistory()), [dispatch]);

  let lastAt = new Date();

  useEffect(() => {
    handleFetchInfoHistory();
  }, [handleFetchInfoHistory]);

  useShowMore({
    items: infoHistory,
    showCount: showCount,
    maxLength: infoHistory !== null ? infoHistory.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMoreInfoHistory,
  });

  const renderTopPanel = () => {
    const user = profileHistory[activeStory].user;
    const name = user.name;
    const city = user.city ? user.city : "";
    const age = user.age ? user.age : "";
    const photo = user.photo_url ? user.photo_url : "";
    const ageStrFormat = age ? age + " " + declOfNum(age, ["год", "года", "лет"]) : "";
    const comma = ageStrFormat && city ? ", " : "";

    return (
      <SimpleCell
        disabled
        before={<Avatar size={72} src={photo} />}
        description={ageStrFormat + comma + city}
      >
        {name}
      </SimpleCell>
    );
  };

  const renderInfoHistory = () => {
    return infoHistory.length > 0 ? (
      infoHistory.map((info, i) => {
        const type = info.type_info;
        const itemKey = info.item_key;
        const itemValue = info[info.item_key];
        const itemNote = info.item_note;
        const at = unixTimeToDate(info.create_at);

        let separate = <SeparateFilter>{convertMonthDay(at)}</SeparateFilter>;

        if (lastAt.getDate() === at.getDate() && i !== 0) {
          separate = "";
        }

        lastAt = at;

        if (i < showCount) {
          return (
            <div key={i}>
              {separate}
              <InfoHistoryItem
                key={i}
                itemKey={itemKey}
                itemValue={itemValue}
                itemNote={itemNote}
                time={convertTime(at)}
                type={type}
              />
            </div>
          );
        }

        return false;
      })
    ) : (
      <Placeholder icon={<Icon28WriteOutline width={56} height={56} />}>
        Изменения в блоке информации не найдены
      </Placeholder>
    );
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      <HeadTitle>Изменение блока информации</HeadTitle>
      {renderTopPanel()}
      {infoHistory !== null && error === null ? renderInfoHistory() : ""}
      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchInfoHistory,
      })}
    </div>
  );
};

export default InfoHistoryList;
