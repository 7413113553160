import { ModalRoot } from "@vkontakte/vkui";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openModal, openStory } from "store/actions/page";

import {
  MODAL_CARD_TARIFF_CONNECTION,
  MODAL_CARD_TARIFF_CONNECTED,
  MODAL_CARD_CONNECT_AUTO_PAYMENT,
  MODAL_CARD_BALANCE_STATUS,
} from "./constModals";

import TariffConnectionModal from "./TariffConnectionModal/TariffConnectionModal";
import TariffConnectedModal from "./TariffConnectedModal/TariffConnectedModal";
import ConnectAutoPaymentModal from "./ConnectAutoPaymentModal/ConnectAutoPaymentModal";
import BalanceStatusModal from "./BalanceStatusModal/BalanceStatusModal";

const ModalList = () => {
  const dispatch = useDispatch();
  const handleOpenModal = useCallback(
    ({ id, param }) => dispatch(openModal({ id: id, param: param })),
    [dispatch]
  );
  const handleOpenStory = useCallback(
    ({ story, panel }) => dispatch(openStory({ story: story, panel: panel })),
    [dispatch]
  );
  const modalParam = useSelector((state) => state.page.modalParam);
  const activeModal = useSelector((state) =>
    state.page.activeModal[state.page.activeView] === undefined
      ? null
      : state.page.activeModal[state.page.activeView]
  );
  const handleCloseModal = useCallback(() => dispatch(closeModal()), [dispatch]);

  return (
    <ModalRoot activeModal={activeModal} onClose={handleCloseModal}>
      <TariffConnectionModal
        id={MODAL_CARD_TARIFF_CONNECTION}
        modalParam={modalParam}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
      />
      <TariffConnectedModal
        id={MODAL_CARD_TARIFF_CONNECTED}
        openStory={handleOpenStory}
        modalParam={modalParam}
        handleCloseModal={handleCloseModal}
      />
      <ConnectAutoPaymentModal
        id={MODAL_CARD_CONNECT_AUTO_PAYMENT}
        handleOpenModal={handleOpenModal}
        modalParam={modalParam}
        handleCloseModal={handleCloseModal}
      />
      <BalanceStatusModal
        id={MODAL_CARD_BALANCE_STATUS}
        modalParam={modalParam}
        handleCloseModal={handleCloseModal}
      />
    </ModalRoot>
  );
};

export default ModalList;
