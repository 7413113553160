import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Div, Button } from "@vkontakte/vkui";
import styles from "./PanelHeaderBalance.module.css";

import ShopingCartIcon from "assets/img/panelHeader/shoppingCart.svg";
import CoinIcon from "assets/img/panelHeader/coin.png";

import { openStory, openView } from "store/actions/page";
import { STORY_SHOP } from "stories/constStories";
import {
  PANEL_BALANCE_HISTORY,
  PANEL_BALANCE_REPLENISHMENT,
  PANEL_MAIN,
} from "views/shop/panels/constPanels";
import { declOfNum } from "helpers";
import { VIEW_SHOP } from "views/constViews";

const PanelHeaderBalance = () => {
  const dispatch = useDispatch();
  const handleOpenStory = useCallback(
    ({ story, panel }) => dispatch(openStory({ story: story, panel: panel })),
    [dispatch]
  );
  const handleOpenView = useCallback(
    ({ view, panel, param = null }) =>
      dispatch(openView({ view: view, panel: panel, param: param })),
    [dispatch]
  );

  const activePanel = useSelector((state) => state.page.activePanel);
  const activeStory = useSelector((state) => state.page.activeStory);

  const payloadEYE3 = useSelector((state) =>
    state.myProfile.payloadEYE3 ? state.myProfile.payloadEYE3 : null
  );
  const isBotConnect = payloadEYE3 ? payloadEYE3.user.is_bot_connect : false;

  const balance = payloadEYE3 ? payloadEYE3.user.balance : null;
  const balanceStr = balance
    ? +balance + " " + declOfNum(balance, ["монета", "монеты", "монет"])
    : "0 монет";

  const renderBtn = () => {
    return activeStory === STORY_SHOP &&
      activePanel === PANEL_MAIN &&
      activePanel !== PANEL_BALANCE_REPLENISHMENT ? (
      <Button
        onClick={() => handleOpenView({ view: VIEW_SHOP, panel: PANEL_BALANCE_REPLENISHMENT })}
        disabled={isBotConnect ? false : true}
        mode="commerce"
      >
        Пополнить
      </Button>
    ) : activeStory !== STORY_SHOP && activePanel !== PANEL_BALANCE_REPLENISHMENT ? (
      <Button
        onClick={() => handleOpenStory({ story: STORY_SHOP, panel: PANEL_MAIN })}
        before={<img style={{ paddingRight: "5px" }} src={ShopingCartIcon} alt="" />}
        disabled={isBotConnect ? false : true}
        mode="commerce"
      >
        Магазин
      </Button>
    ) : (
      ""
    );
  };

  const goBalance = () => {
    if (isBotConnect) handleOpenStory({ story: STORY_SHOP, panel: PANEL_BALANCE_HISTORY });
  };

  return (
    <>
      <Div className={styles.balance}>
        <div className={styles.balanceCoinCnt} onClick={goBalance}>
          <span className={styles.balanceMoneyCaption}>Баланс:</span>
          <span
            className={styles.balanceMoneyText}
            style={balance >= 10000 ? { fontSize: "17px" } : null}
          >
            <img className={styles.balanceMoneyImg} src={CoinIcon} alt="" /> {balanceStr}
          </span>
        </div>
        <div>{renderBtn()}</div>
      </Div>
    </>
  );
};

export default PanelHeaderBalance;
