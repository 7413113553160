import React from "react";
import { useSelector } from "react-redux";
import { ModalPage, ModalPageHeader, List, Avatar, SimpleCell } from "@vkontakte/vkui";

import { declOfNum } from "helpers";

const MutualFriendModal = (props) => {
  const profile = useSelector((state) => (state.profile.payload ? state.profile.payload : null));
  const mutualFriends = profile ? profile.mutual_friends : [];
  const commonStrFormat = declOfNum(mutualFriends.length, ["общий", "общих", "общих"]);
  const friendStrFormat = declOfNum(mutualFriends.length, ["друг", "друга", "друзей"]);

  const renderMutualFriend = () => {
    return mutualFriends.map((friend, i) => {
      //const id = friend.vk_id;
      const name = friend.name;
      const photo = friend.photo_url;

      return (
        <SimpleCell key={i} disabled before={<Avatar size={40} src={photo} />}>
          {name}
        </SimpleCell>
      );
    });
  };

  return (
    <ModalPage
      id={props.id}
      header={
        <ModalPageHeader>
          <span
            style={{
              fontWeight: "500",
              fontSize: "13px",
              lineHeight: "16px",
              letterSpacing: "0.2px",
              textTransform: "uppercase",
              color: "#818C99",
            }}
          >
            {mutualFriends.length + " " + commonStrFormat + " " + friendStrFormat}
          </span>
        </ModalPageHeader>
      }
      settlingHeight={80}
    >
      <List>{profile ? renderMutualFriend() : ""}</List>
    </ModalPage>
  );
};

export default MutualFriendModal;
