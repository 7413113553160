import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Banner } from "@vkontakte/vkui";

import { subscribeGroup } from "store/actions/myProfile";

const ProfileBanner = () => {
  const dispatch = useDispatch();
  const payloadEYE3 = useSelector((state) =>
    state.myProfile.payloadEYE3 ? state.myProfile.payloadEYE3 : null
  );
  const isGroupMember = payloadEYE3 ? payloadEYE3.user.group_member : true;

  const handleSubscribeGroup = useCallback(() => {
    dispatch(subscribeGroup());
  }, [dispatch]);

  return (
    <>
      {!isGroupMember ? (
        <Banner
          size="m"
          mode="image"
          asideMode="expand"
          imageTheme="dark"
          background={
            <div
              style={{
                background: "radial-gradient(109.55% 109.55% at 0% 0%, #358DFF 0%, #0336FF 100%",
              }}
            ></div>
          }
          header="Сообщество МойКраш"
          subheader="Вступайте в наше официальное сообщество и получайте дополнительные бонусы"
          actions={
            <Button mode="overlay_primary" size="l" onClick={handleSubscribeGroup}>
              Подписаться
            </Button>
          }
        />
      ) : (
        ""
      )}
    </>
  );
};

export default ProfileBanner;
