import React from "react";
import { Avatar, SimpleCell, UsersStack } from "@vkontakte/vkui";
import Icon16Chevron from "@vkontakte/icons/dist/16/chevron";
import { MODAL_PAGE_TEMPLATE_MUTUAL_COMMUNITY } from "template/modals/constModals";

const CCUserItem = (props) => {
  const photos = props.commonCommunity.map((community) => {
    return community.photo_url;
  });

  return (
    <SimpleCell
      id={props.id}
      before={<Avatar size={48} src={props.photo} />}
      description={
        <UsersStack size="s" visibleCount={3} photos={photos}>
          {props.commonCount}
        </UsersStack>
      }
      after={<Icon16Chevron fill="#B8C1CC" />}
      onClick={() => {
        props.openModal({
          id: MODAL_PAGE_TEMPLATE_MUTUAL_COMMUNITY,
          param: {
            community: props.commonCommunity,
          },
        });
      }}
    >
      {props.title}
    </SimpleCell>
  );
};

export default CCUserItem;
