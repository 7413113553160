import React from "react";
import { Avatar, Group, SimpleCell } from "@vkontakte/vkui";
import { useSelector } from "react-redux";

import { calcAge } from "helpers";
import { declOfNum } from "helpers";

const ProfileInfo = () => {
  const payloadVK = useSelector((state) =>
    state.myProfile.payloadVK ? state.myProfile.payloadVK : null
  );

  const renderProfileInfo = () => {
    const name = payloadVK.first_name + " " + payloadVK.last_name;
    const photo = payloadVK.photo_100;
    const city = payloadVK.hasOwnProperty("city") ? payloadVK.city.title : "";
    const bdate = payloadVK.hasOwnProperty("bdate") ? payloadVK.bdate : "";
    const age = bdate ? calcAge(bdate) : "";
    const ageStrFormat = age ? age + " " + declOfNum(age, ["год", "года", "лет"]) : "";
    const comma = ageStrFormat && city ? ", " : "";

    return (
      <SimpleCell
        disabled
        before={<Avatar size={72} src={photo} />}
        description={ageStrFormat + comma + city}
      >
        {name}
      </SimpleCell>
    );
  };

  return (
    <>
      <Group separator="hide">{payloadVK && renderProfileInfo()}</Group>
    </>
  );
};

export default ProfileInfo;
