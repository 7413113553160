import { PERIOD_MONTH } from "template/popouts/DateChangePeriod/constPeriod";
import {
  MENTION_REQUEST,
  MENTION_SUCCESS,
  MENTION_ERROR,
  MENTION_SHOW_MORE,
  MENTION_SEARCH_REQUEST,
  MENTION_SEARCH_SUCCESS,
  MENTION_SEARCH_ERROR,
  MENTION_FILTER_REQUEST,
  MENTION_FILTER_SUCCESS,
  MENTION_FILTER_ERROR,
  MENTION__INFO_REQUEST,
  MENTION__INFO_SUCCESS,
  MENTION__INFO_ERROR,
  MENTION__INFO_SET_PERIOD,
  MENTION__INFO_LOAD_MORE_REQUEST,
  MENTION__INFO_LOAD_MORE_SUCCESS,
  MENTION__INFO_LOAD_MORE_ERROR,
} from "../../constType";

const initialState = {
  friends: {
    payload: null,
    showCount: 20,
    cachePayload: null,
    search: "",
    filter: "",
    loading: false,
    error: null,
    isOnce: false,
  },
  mentions: {
    payload: null,
    maxLength: 0,
    count: 30,
    offset: 0,
    activeUserId: null, // Параметры для сохранения пользователя
    activeUserPhoto: null, //
    activeUserTitle: null, //
    activeUserDescription: null, //
    loading: false,
    error: null,
    period: {
      name: PERIOD_MONTH,
      atMin: null,
      atMax: null,
    },
  },
};

export default function mentionReducer(state = initialState, action) {
  switch (action.type) {
    case MENTION_REQUEST:
      return {
        ...state,
        friends: {
          ...state.friends,
          showCount: 20,
          payload: null,
          cachePayload:
            state.friends.search === "" && state.friends.filter === ""
              ? null // Если поле поиска и фильтра пустое то очистить кэш
              : state.friends.cachePayload,
          loading: true,
          error: null,
          isOnce: true,
        },
      };
    case MENTION_SUCCESS:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: action.payload,
          loading: false,
          cachePayload:
            state.friends.search === "" && state.friends.filter === ""
              ? action.payload // Если поле поиска и фильтра пустое то сохранить результат
              : state.friends.cachePayload,
        },
      };
    case MENTION_ERROR:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          error: action.error,
        },
      };
    case MENTION_SHOW_MORE:
      return {
        ...state,
        friends: {
          ...state.friends,
          showCount: parseInt(state.friends.showCount) + 20,
        },
      };
    case MENTION_SEARCH_REQUEST:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: null,
          showCount: 20,
          search: action.search,
          loading: true,
          error: null,
        },
      };
    case MENTION_SEARCH_SUCCESS:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: action.payload,
          loading: false,
        },
      };
    case MENTION_SEARCH_ERROR:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          error: action.error,
        },
      };
    case MENTION_FILTER_REQUEST:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: null,
          showCount: 20,
          filter: action.filter,
          loading: true,
          error: null,
        },
      };
    case MENTION_FILTER_SUCCESS:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          payload: action.payload,
        },
      };
    case MENTION_FILTER_ERROR:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          error: action.error,
        },
      };
    case MENTION__INFO_REQUEST:
      return {
        ...state,
        mentions: {
          ...state.mentions,
          showCount: 20,
          offset: 0,
          maxLength: 0,
          payload: null,
          activeUserId: action.id,
          activeUserDescription: action.description,
          activeUserTitle: action.title,
          activeUserPhoto: action.photo,
          loading: true,
          error: null,
        },
      };
    case MENTION__INFO_SUCCESS:
      return {
        ...state,
        mentions: {
          ...state.mentions,
          payload: action.payload,
          maxLength: action.maxLength,
          loading: false,
        },
      };
    case MENTION__INFO_ERROR:
      return {
        ...state,
        mentions: {
          ...state.mentions,
          loading: false,
          error: action.error,
        },
      };
    case MENTION__INFO_SET_PERIOD:
      return {
        ...state,
        mentions: {
          ...state.mentions,
          period: {
            ...state.mentions.period,
            name: action.name,
            atMin: action.atMin,
            atMax: action.atMax,
          },
        },
      };
    case MENTION__INFO_LOAD_MORE_REQUEST:
      return {
        ...state,
        mentions: {
          ...state.mentions,
          loading: true,
          offset:
            state.mentions.offset === 0
              ? (state.mentions.offset = state.mentions.count + 1)
              : (state.mentions.offset += 30), // Подгрузка упоминаний
        },
      };
    case MENTION__INFO_LOAD_MORE_SUCCESS:
      return {
        ...state,
        mentions: {
          ...state.mentions,
          loading: false,
          payload: [...state.mentions.payload, ...action.payload],
        },
      };
    case MENTION__INFO_LOAD_MORE_ERROR:
      return {
        ...state,
        mentions: {
          ...state.mentions,
          loading: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}
