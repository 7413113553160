import React from "react";
import { Avatar, Button, Div, SimpleCell, Group, Alert } from "@vkontakte/vkui";
import { Icon28ChevronRightOutline } from "@vkontakte/icons";
import { PANEL_TIME_ONLINE, PANEL_TO__COMPARE_TIME } from "../../../../constPanels";
import { VIEW_TOOL } from "views/constViews";
import { PANEL_PROFILE } from "views/profile/panels/constPanels";

const TOTrackingUserInfoTopPanel = (props) => {
  return (
    <Group separator="show">
      <SimpleCell
        before={<Avatar size={72} src={props.photo} />}
        description={props.description}
        onClick={() =>
          props.openView({
            view: VIEW_TOOL,
            panel: PANEL_PROFILE,
            param: { activeProfileId: props.id },
          })
        }
        after={<Icon28ChevronRightOutline fill="#B8C1CC" />}
      >
        {props.title}
      </SimpleCell>
      <Div>
        <Button
          mode="secondary"
          onClick={() =>
            props.openView({
              view: VIEW_TOOL,
              panel: PANEL_TO__COMPARE_TIME,
              param: {
                activeUserId: props.id,
                activeUserTitle: props.title,
                activeUserDescription: props.description,
                activeUserPhoto: props.photo,
              },
            })
          }
          disabled={props.isTracking && props.error === null ? false : true}
          size="l"
          style={{ marginBottom: "8px", width: "100%" }}
        >
          Сравнить время онлайн
        </Button>
        <Button
          mode="secondary"
          size="l"
          style={
            props.isTracking
              ? { color: "red", width: "100%" }
              : { color: "var(--button_secondary_foreground)", width: "100%" }
          }
          onClick={async () => {
            if (props.typeTracking === "track") {
              props.openView({
                view: VIEW_TOOL,
                panel: PANEL_PROFILE,
                param: { activeProfileId: props.id },
              });
              return;
            } else if (props.isTracking) {
              props.openPopout({
                popout: (
                  <Alert
                    actionsLayout="horizontal"
                    actions={[
                      {
                        title: "Отмена",
                        autoclose: true,
                        mode: "cancel",
                      },
                      {
                        title: "Не отслеживать",
                        autoclose: true,
                        mode: "destructive",
                        action: async () => {
                          await props.handleTracking(props.id, props.isTracking ? false : true);
                          await props.openView({ view: VIEW_TOOL, panel: PANEL_TIME_ONLINE });
                        },
                      },
                    ]}
                    onClose={props.closePopout}
                  >
                    <p>Вы уверены, что хотите перестать отслеживать этого пользователя?</p>
                  </Alert>
                ),
              });
            }
          }}
        >
          {props.isTracking && props.typeTracking === "track"
            ? "Включено в слежке"
            : props.isTracking
            ? "Не отслеживать"
            : "Отслеживать"}
        </Button>
      </Div>
    </Group>
  );
};

export default TOTrackingUserInfoTopPanel;
