import {
  COMMON_COMMUNITY_REQUEST,
  COMMON_COMMUNITY_SUCCESS,
  COMMON_COMMUNITY_ERROR,
  COMMON_COMMUNITY_CANCEL,
  COMMON_COMMUNITY_SHOW_MORE,
  COMMON_COMMUNITY_SEARCH_REQUEST,
  COMMON_COMMUNITY_SEARCH_SUCCESS,
  COMMON_COMMUNITY_SEARCH_ERROR,
  COMMON_COMMUNITY_FILTER_REQUEST,
  COMMON_COMMUNITY_FILTER_SUCCESS,
  COMMON_COMMUNITY_FILTER_ERROR,
} from "../../constType";

const initialState = {
  payload: null,
  showCount: 20,
  cachePayload: null,
  search: "",
  filter: "",
  loading: false,
  error: null,
};

export default function commonCommunityReducer(state = initialState, action) {
  switch (action.type) {
    case COMMON_COMMUNITY_REQUEST:
      return {
        ...state,
        payload: null,
        loading: true,
        error: null,
        progressValue: 0,
        showCount: 20,
        cachePayload:
          state.search === "" && state.filter === ""
            ? null // Если поле поиска и фильтра пустое то очистить кэш
            : state.cachePayload,
      };
    case COMMON_COMMUNITY_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        loading: false,
        cachePayload:
          state.search === "" && state.filter === ""
            ? action.payload // Если поле поиска и фильтра пустое то сохранить результат
            : state.cachePayload,
      };
    case COMMON_COMMUNITY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case COMMON_COMMUNITY_CANCEL:
      return {
        ...state,
        loading: false,
      };
    case COMMON_COMMUNITY_SHOW_MORE:
      return {
        ...state,
        showCount: parseInt(state.showCount) + 20,
      };
    case COMMON_COMMUNITY_SEARCH_REQUEST:
      return {
        ...state,
        payload: null,
        showCount: 20,
        search: action.search,
        loading: true,
        error: null,
      };
    case COMMON_COMMUNITY_SEARCH_SUCCESS:
      return {
        ...state,
        payload: [...action.payload],
        loading: false,
      };
    case COMMON_COMMUNITY_SEARCH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case COMMON_COMMUNITY_FILTER_REQUEST:
      return {
        ...state,
        payload: null,
        showCount: 20,
        filter: action.filter,
        loading: true,
        error: null,
      };
    case COMMON_COMMUNITY_FILTER_SUCCESS:
      return {
        ...state,
        payload: [...action.payload],
        loading: false,
      };
    case COMMON_COMMUNITY_FILTER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
