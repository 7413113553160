import React from "react";
import TariffIcon from "assets/img/template/modals/tariffWork.png";
import {
  MODAL_CARD_TEMPLATE_TARIFF_LIMIT,
  MODAL_CARD_TEMPLATE_TARIFF_WORK,
} from "template/modals/constModals";
import { Link } from "@vkontakte/vkui";
import BotIcon from "assets/img/bot.png";
import { DESKTOP_WEB } from "api/vk/constPlatform";
import { getSearchObject } from "api";

export const defaultErrorProperty = {
  message: "",
  modal: null,
  icon: null,
  isVisibleModal: false,
  isVisibleMessage: false,
  isTryAgain: true,
};

export function checkEYE3ApiError(response) {
  if (!response.hasOwnProperty("api_client")) return false;
  const hasError = response.api_client.has_error;
  const errors = response.api_client.errors;

  if (hasError) return errors[0];

  return false;
}

// Проверка доступа к методу
function checkLicense(response) {
  if (
    response.hasOwnProperty("status") &&
    response.status === false &&
    response.hasOwnProperty("license") &&
    response.license === false
  ) {
    return {
      ...defaultErrorProperty,
      message: "Для использования инструмента необходимо подключить тариф",
      icon: <img src={TariffIcon} width={50} height={50} alt="" />,
      modal: MODAL_CARD_TEMPLATE_TARIFF_WORK,
      isVisibleModal: true,
      isVisibleMessage: true,
      isTryAgain: false,
    };
  } else {
    return false;
  }
}

// Проверка лимита у метода
function checkLimit(response) {
  if (
    response.hasOwnProperty("status") &&
    response.status === false &&
    response.hasOwnProperty("limit") &&
    response.limit === false
  ) {
    return {
      ...defaultErrorProperty,
      message: "Лимит для отслеживания пользователей превышен",
      modal: MODAL_CARD_TEMPLATE_TARIFF_LIMIT,
      isVisibleModal: true,
      isVisibleMessage: false,
      isTryAgain: false,
    };
  } else {
    return false;
  }
}

// Проверка подключения к боту
function checkIsBotConnect(response) {
  if (
    response.hasOwnProperty("status") &&
    response.status === false &&
    response.hasOwnProperty("is_bot_connect") &&
    response.is_bot_connect === false
  ) {
    return {
      ...defaultErrorProperty,
      icon: <img src={BotIcon} width={238} height={260} alt=""></img>,
      message: (
        <>
          Для использования приложения необходимо подключение к боту. Для этого перейдите в чат и
          напишите «старт» <br />
          {getSearchObject().vk_platform === DESKTOP_WEB ? (
            <Link href={"https://vk.com/im?sel=-194197472"} target="_blank">
              Перейти в чат
            </Link>
          ) : (
            <Link href={"https://vk.com/im?sel=-194197472"}>Перейти в чат</Link>
          )}
        </>
      ),
      isTryAgain: false,
      isVisibleModal: false,
      isVisibleMessage: true,
    };
  } else {
    return false;
  }
}

function checkAccessMethod(access, response, method) {
  if (!access) return false;
  const res = method(response);
  return res;
}

export function responseHandler(
  method,
  response,
  access = { isBotConnect: false, isLicense: false, isLimit: false }
) {
  if (typeof response !== "object" || response === null) {
    console.error("responseHandler: response field not object");
    return;
  }

  if (typeof access !== "object" || access === null) {
    console.error("responseHandler: access field not object");
    return;
  }

  let errors = [];
  let hasError = false;
  let result = response;

  const isBotConnect = checkAccessMethod(access.isBotConnect, response, checkIsBotConnect);
  const isLicense = checkAccessMethod(access.isLicense, response, checkLicense);
  const isLimit = checkAccessMethod(access.isLimit, response, checkLimit);

  const methods = [isBotConnect, isLicense, isLimit];

  for (let method of methods) {
    if (method !== false) {
      hasError = true;
      errors.push(method);
    }
  }

  result.api_client = {
    has_error: hasError,
    errors: errors,
  };

  if (process.env.NODE_ENV === "development") {
    console.log(`(${method}): `, response);
  }

  return result;
}
