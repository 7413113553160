import {
  GIFT_REQUEST,
  GIFT_SUCCESS,
  GIFT_ERROR,
  GIFT_SHOW_MORE,
  GIFT_SEARCH_REQUEST,
  GIFT_SEARCH_SUCCESS,
  GIFT_SEARCH_ERROR,
  GIFT_FILTER_REQUEST,
  GIFT_FILTER_SUCCESS,
  GIFT_FILTER_ERROR,
  GIFT__INFO_SET_PERIOD,
  GIFT__INFO_TABS,
  GIFT__INFO_REQUEST,
  GIFT__INFO_SUCCESS,
  GIFT__INFO_ERROR,
} from "../../constType";

import { getFilterTabsFriends, getSearchFilterFriendsV2 } from "helpers/actions";
import { getPeriod } from "template/popouts/DateChangePeriod/actions";
import { APIEye3GetGiftsTool } from "api/eye3";
import { openModal } from "../page";
import { delayMethodGetUsersVKApi } from "api";
import { checkEYE3ApiError, defaultErrorProperty } from "api/eye3/responseHandler";

export function fetchGift() {
  return async (dispatch, getState) => {
    try {
      dispatch(request());

      const state = getState().toolGift;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.friends.search,
        filter: state.friends.filter,
        cache: state.friends.cachePayload,
      });

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: GIFT_REQUEST };
  }

  function success(payload) {
    return { type: GIFT_SUCCESS, payload };
  }

  function error(error) {
    return { type: GIFT_ERROR, error };
  }
}

export function showMoreGift() {
  return { type: GIFT_SHOW_MORE };
}

export function filterGift(filter = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(request(filter));

      const state = getState().toolGift;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.friends.search,
        filter: state.friends.filter,
        cache: state.friends.cachePayload,
      });

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(filter) {
    return { type: GIFT_FILTER_REQUEST, filter };
  }

  function success(payload) {
    return { type: GIFT_FILTER_SUCCESS, payload };
  }

  function error(error) {
    return { type: GIFT_FILTER_ERROR, error };
  }
}

export function searchGift(search = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(request(search));

      const state = getState().toolGift;

      const users = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.friends.search,
        filter: state.friends.filter,
        cache: state.friends.cachePayload,
      });

      dispatch(success(users));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(search) {
    return { type: GIFT_SEARCH_REQUEST, search };
  }

  function success(payload) {
    return { type: GIFT_SEARCH_SUCCESS, payload };
  }

  function error(error) {
    return { type: GIFT_SEARCH_ERROR, error };
  }
}

export function fetchInfoGift() {
  return async (dispatch, getState) => {
    try {
      const state = getState().toolGift;
      const pageParam = getState().page.param;

      // Задаем pageParam в зависимости от его наличия (когда загружен новый пользователь при изменении периода используются значения из state.gifts)
      const id = pageParam ? pageParam.activeUserId : state.gifts.activeUserId;
      const title = pageParam ? pageParam.activeUserTitle : state.gifts.activeUserTitle;
      const description = pageParam
        ? pageParam.activeUserDescription
        : state.gifts.activeUserDescription;
      const photo = pageParam ? pageParam.activeUserPhoto : state.gifts.activeUserPhoto;
      const sex = pageParam ? pageParam.activeUserSex : state.gifts.activeUserSex;

      const period = state.gifts.period;
      const tabs = state.gifts.tabs;

      dispatch(request(id, title, description, photo, sex));

      const { atMin, atMax } = getPeriod(period);

      let result = [];
      const gifts = await APIEye3GetGiftsTool({ userId: id, timeMin: atMin, timeMax: atMax });
      //console.log(gifts);

      // Проверка ошибок в API
      const errorAPI = checkEYE3ApiError(gifts);
      if (errorAPI !== false) {
        errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
        dispatch(error({ ...errorAPI }));
        return;
      }

      let userIds = gifts.items.map((user) => user.vk_id);

      const userInfo = await delayMethodGetUsersVKApi({
        userIds,
        fields: ["photo_100"],
      });

      const userItems = userInfo;

      gifts.items.forEach((gift) => {
        userItems.forEach((user) => {
          if (gift.vk_id === user.id) {
            result.push({
              vk_id: user.id,
              name: user.first_name + " " + user.last_name,
              date: gift.date,
              type: gift.type,
              text: gift.text,
              photo_user_url: user.photo_100,
              photo_gift_url: gift.photo,
            });
          }
        });
      });

      result = getFilterTabsFriends({
        data: result,
        filter: "",
        tabsKey: ["type"],
        tabsValue: tabs !== "all" ? tabs : "",
        template: [
          {
            first: "type",
            compare: "===",
            two: "type",
          },
        ],
      });

      dispatch(success(result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(id, title, description, photo, sex) {
    return { type: GIFT__INFO_REQUEST, id, title, description, photo, sex };
  }

  function success(payload) {
    return { type: GIFT__INFO_SUCCESS, payload };
  }

  function error(error) {
    return { type: GIFT__INFO_ERROR, error };
  }
}

export function setPeriodInfoGift(name, atMin = null, atMax = null) {
  return { type: GIFT__INFO_SET_PERIOD, name, atMin, atMax };
}

export function tabsInfoGift(tabs) {
  return { type: GIFT__INFO_TABS, tabs };
}
