import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View } from "@vkontakte/vkui";

import {
  PANEL_MAIN,
  PANEL_TOTAL_SURVEILLANCE,
  PANEL_TS__START_TRACKING,
  PANEL_TS__SETTING_NOTIFICATION,
  PANEL_WHO_HIDDEN,
  PANEL_WH__WHO_HIDDEN_ME,
  PANEL_WH__WHOM_HIDDEN_IM,
  PANEL_WH__WHOM_HIDDEN_ANOTHER_USER,
  PANEL_WH__WHOM_HIDDEN_ANOTHER_USER_INFO,
  PANEL_WH__WHO_HIDDEN_ANOTHER_USER,
  PANEL_WH__WHO_HIDDEN_ANOTHER_USER_INFO,
  PANEL_WHO_ADDED_CHS,
  PANEL_REGISTRATION_DATE,
  PANEL_TRAP,
  PANEL_T__CONVERT_LINK,
  PANEL_T__LINK_DONE,
  PANEL_T__SELF_LINK_DONE,
  PANEL_MARTIAL_STATUS,
  PANEL_NEW_FRIENDS,
  PANEL_NEW_FRIENDS__INFO,
  PANEL_TIME_ONLINE, // time-online
  PANEL_TO__START_TRACKING,
  PANEL_TO__TRACKING_USER_INFO,
  PANEL_TO__COMPARE_TIME,
  PANEL_TO__COMPARE_TIME_INFO,
  PANEL_MENTION,
  PANEL_ADMIN_COMMUNITY,
  PANEL_AC_INFO,
  PANEL_JOIN_COMMUNITY,
  PANEL_JC_INFO,
  PANEL_GIFT,
  PANEL_GIFT__INFO,
  PANEL_ACTIVITY, // activity
  PANEL_A__USER,
  PANEL_A__USER_INFO,
  PANEL_A__OTHER_USER,
  PANEL_A__OTHER_USER_PAGE,
  PANEL_A__OTHER_USER_ACTIVE,
  PANEL_A__OTHER_USER_INFO, // activity
  PANEL_MS__GEO_SETTINGS,
  PANEL_MS__GEO_SEARCH,
  PANEL_PAIR,
  PANEL_WHO_LISTEN_FRIENDS,
  PANEL_WHO_NOT_ONLINE,
  PANEL_COMMON_COMMUNITY,
  PANEL_COMMON_FRIENDS,
  PANEL_POPULAR_FRIENDS,
  PANEL_DELETED_FRIENDS,
  PANEL_FRIENDS_IN_APP,
  PANEL_POPULAR_SUBSCRIBER,
  PANEL_SUBSCRIBER_FILTER,
  PANEL_DOWNLOAD_HISTORY,
  PANEL_DH__INFO,
  PANEL_HISTORY_STATISTICS,
  PANEL_POPULAR_COMMUNITY,
  PANEL_COMMUNITY_ACTIVITY,
  PANEL_PAIR__INFO,
  PANEL_WLF_POPULAR_AUDIO,
  PANEL_WLF_COMMON_AUDIO,
  PANEL_WLF_COMMON_AUDIO_FOR_ME,
  PANEL_WLF_COMMON_AUDIO_WITH,
  PANEL_FIA__APPS_FROM_FRIENDS,
  PANEL_FIA__APPS_FROM_FRIENDS_INFO,
  PANEL_FIA__INFO,
  PANEL_FIA__WHAT_IS_APP_ID,
  PANEL_SF__SEARCH_RESULT,
  PANEL_PS__GROUP_INFO,
  PANEL_FAN,
  PANEL_FAN__TOP,
  PANEL_POSTING,
  PANEL_POSTING__INFO,
  PANEL_HS__INFO,
  PANEL_HS__INFO_TOP_SPECTATOR,
  PANEL_MENTION__INFO,
  PANEL_MS__INFO,
  PANEL_MS__GEO,
} from "./panels/constPanels";

import { PANEL_TEMPLATE_DATE_CHANGE_PERIOD } from "template/panels/constPanels";

import { onTransitionPage } from "store/actions/page";

import PanelMain from "./panels/main";

import PanelTotalSurveillance from "./panels/totalSurveillance/totalSurveillance";
import PanelTSStartTracking from "./panels/totalSurveillance/TSStartTracking/TSStartTracking";
import PanelTSSettingNotification from "./panels/totalSurveillance/TSSettingNotification/TSSettingNotification";
// Кто скрыл
import PanelWhoHidden from "./panels/whoHidden/whoHidden";
import PanelWHWhoHiddenAnotherUser from "./panels/whoHidden/whoHiddenAnotherUser/whoHiddenAnotherUser";
import PanelWHWhoHiddenAnotherUserInfo from "./panels/whoHidden/whoHiddenAnotherUserInfo/whoHiddenAnotherUserInfo";
import PanelWHWhomHiddenIm from "./panels/whoHidden/whomHiddenIm/whomHiddenIm";
import PanelWHWhomHiddenAnotherUser from "./panels/whoHidden/whomHiddenAnotherUser/whomHiddenAnotherUser";
import PanelWHWhomHiddenAnotherUserInfo from "./panels/whoHidden/whomHiddenAnotherUserInfo/whomHiddenAnotherUserInfo";
import PanelWHWhoHiddenMe from "./panels/whoHidden/whoHiddenMe/whoHiddenMe";
// Кто добавил в ЧС
import PanelWhoAddedChs from "./panels/whoAddedChs";
//дата регистрации
import PanelRegistrationDate from "./panels/registrationDate";
// Ловушка
import PanelTrap from "./panels/trap/trap";
import PanelTLinkDone from "./panels/trap/TLinkDone/TLinkDone";
import PanelTSelfLinkDone from "./panels/trap/TSelfLinkDone/TSelfLinkDone";
import PanelTConvertLink from "./panels/trap/TConvertLink/TConvertLink";
// Семейное положение
import PanelMaritalStatus from "./panels/maritalStatus/maritalStatus";
// Подарки
import PanelGift from "./panels/gift/gift";
import PanelGInfo from "./panels/gift/GInfo/GInfo";
// Активности
import PanelActivity from "./panels/activity/activity";
import PanelAUser from "./panels/activity/AUser/AUser";
import PanelAUserInfo from "./panels/activity/AUserInfo/AUserInfo";
import PanelAOtherUser from "./panels/activity/AOtherUser/AOtherUser";
import PanelAOtherUserPage from "./panels/activity/AOtherUserPage/AOtherUserPage";
import PanelAOtherUserActive from "./panels/activity/AOtherUserActive/AOtherUserActive";
import PanelAOtherUserInfo from "./panels/activity/AOtherUserInfo/AOtherUserInfo";
// Новые друзья
import PanelNewFriends from "./panels/newFriends/newFriends";
import PanelNFInfo from "./panels/newFriends/NFInfo/NFInfo";
// Время онлайн (Когда был онлайн)
import PanelTimeOnline from "./panels/timeOnline/timeOnline";
import PanelTOTrackingUserInfo from "./panels/timeOnline/TOTrackingUserInfo/TOTrackingUserInfo";
import PanelTOStartTracking from "./panels/timeOnline/TOStartTracking/TOStartTracking";
import PanelTOCompareTime from "./panels/timeOnline/TOCompareTime/TOCompareTime";
import PanelTOCompareTimeInfo from "./panels/timeOnline/TOCompareTimeInfo/TOCompareTimeInfo";
// Упоминания
import PanelMention from "./panels/mention/mention";
// Администрируемые (Администрируемые сообщества)
import PanelAdminCommunity from "./panels/adminCommunity/adminCommunity";
import PanelACInfo from "./panels/adminCommunity/ACInfo/ACInfo";
// Вступления (Вступления в сообщества)
import PanelJoinCommunity from "./panels/joinCommunity/joinCommunity";
import PanelJCInfo from "./panels/joinCommunity/JCInfo/JCInfo";
// Семейное положение
import PanelMSInfo from "./panels/maritalStatus/MSInfo/MSInfo";
import PanelMSGeo from "./panels/maritalStatus/MSGeo/MSGeo";
import PanelMSGeoSettings from "./panels/maritalStatus/MSGeoSetting/MSGeoSetting";
import PanelMSGeoSearch from "./panels/maritalStatus/MSGeoSearch/MSGeoSearch";
// Упоминания
import PanelMInfo from "./panels/mention/MInfo/MInfo";
// Пары
import PanelPair from "./panels/pair/pair";
import PanelPairInfo from "./panels/pair/PInfo/PInfo";
// Что слушают друзья
import PanelWhoListenFriends from "./panels/whoListenFriends/whoListenFriends";
import PanelWLFPopularAudio from "./panels/whoListenFriends/WLFPopularAudio/WLFPopularAudio";
import PanelWLFCommonAudio from "./panels/whoListenFriends/WLFCommonAudio/WLFCommonAudio";
import PanelWLFCommonAudioForMe from "./panels/whoListenFriends/WLFCommonAudioForMe/WFLCommonAudioForMe";
import PanelWLFCommonAudioWith from "./panels/whoListenFriends/WLFCommonAudioWith/WLFCommonAudioWith";
// Кто не онлайн
import PanelWhoNotOnline from "./panels/whoNotOnline";
// Общие подписчики
import PanelCommonCommunity from "./panels/commonCommunity/commonCommunity";
// Общие друзья
import PanelCommonFriends from "./panels/commonFriends/commonFriends";
// Популярные друзья
import PanelPopularFriends from "./panels/popularFriends";
// Удаленные
import PanelDeletedFriends from "./panels/deletedFriends";
// Друзья в приложениях
import PanelFriendsInApp from "./panels/friendsInApp/friendsInApp";
import PanelFIAAppsFromFriends from "./panels/friendsInApp/FIAAppsFromFriends/FIAAppsFromFriends";
import PanelFIAAppsFromFriendsInfo from "./panels/friendsInApp/FIAAppsFromFriendsInfo/FIAAppsFromFriendsInfo";
import PanelFIAInfo from "./panels/friendsInApp/FIAInfo/FIAInfo";
import PanelFIAWhatIsAppId from "./panels/friendsInApp/FIAWhatIsAppId/FIAWhatIsAppId";
// Популярные подписчики
import PanelPopularSubscribers from "./panels/popularSubscribers/popularSubscribers";
import PanelPSGroupInfo from "./panels/popularSubscribers/PSGroupInfo/PSGroupInfo";
// Фильтр подписчиков
import PanelSubscribersFilter from "./panels/subscribersFilter/subscribersFilter";
import PanelSFSearchResult from "./panels/subscribersFilter/SFSearchResult/SFSearchResult";
// Скачивание историй
import PanelDownloadHistory from "./panels/downloadHistory/downloadHistory";
import PanelDHInfo from "./panels/downloadHistory/DHInfo/DHInfo";
// Статистика историй
import PanelHistoryStatistics from "./panels/historyStatistics/historyStatistics";
import PanelHSInfo from "./panels/historyStatistics/HSInfo/HSInfo";
import PanelHSInfoTopSpectator from "./panels/historyStatistics/HSInfoTopSpectator/HSInfoTopSpectator";
// Популярные группы
import PanelPopularCommunity from "./panels/popularCommunity/popularCommunity";
// Активность сообществ
import PanelCommunityActivity from "./panels/communityActivity";
// Поклонники
import PanelFan from "./panels/fan/fan";
import PanelFTop from "./panels/fan/FTop/FTop";
// Постинг
import PanelPosting from "./panels/posting/posting";
import PanelPostingInfo from "./panels/posting/PInfo/PInfo";

import PanelTemplateDateChangePeriod from "../../template/panels/DateChangePeriod/DateChangePeriod";

import {
  PANEL_PROFILE,
  PANEL_PROFILE_VIEW_PAGE,
  PANEL_PROFILE_VIEW_STORIES,
  PANEL_PROFILE_LIKE,
  PANEL_PROFILE_COMMENT,
  PANEL_PROFILE_TIME_ONLINE,
  PANEL_PROFILE_INFO_HISTORY,
  PANEL_PROFILE_MARITAL_STATUS,
  PANEL_PROFILE_FRIEND_HISTORY,
  PANEL_PROFILE_JOIN_COMMUNITY,
  PANEL_PROFILE_ALL_COMMENT,
  PANEL_PROFILE_ALL_LIKE,
  PANEL_PROFILE_GIFT,
  PANEL_PROFILE_HOROSCOPE,
  PANEL_PROFILE_PSYCHOTYPE,
} from "views/profile/panels/constPanels";

// Профиль
import PanelProfile from "views/profile/panels/profile";
import PanelProfileViewPage from "views/profile/panels/viewPage";
import PanelProfileViewStories from "views/profile/panels/viewStories";
import PanelProfileLike from "views/profile/panels/like";
import PanelProfileComment from "views/profile/panels/comment";
import PanelProfileTimeOnline from "views/profile/panels/timeOnline";
import PanelProfileInfoHistory from "views/profile/panels/infoHistory";
import PanelProfileMaritalStatus from "views/profile/panels/maritalStatus";
import PanelProfileFriendHistory from "views/profile/panels/friendHistory";
import PanelProfileJoinCommunity from "views/profile/panels/joinCommunity";
import PanelProfileAllComment from "views/profile/panels/allComment";
import PanelProfileAllLike from "views/profile/panels/allLike";
import PanelProfileGift from "views/profile/panels/gift";
import PanelProfileHoroscope from "views/profile/panels/horoscope";
import PanelProfilePsychotype from "views/profile/panels/psychotype";
//

import ModalList from "./modals";

const ToolView = (props) => {
  const dispatch = useDispatch();
  const activePanel = useSelector((state) => state.page.activePanel);
  const history = useSelector((state) =>
    state.page.panelsHistory[state.page.activeView] === undefined
      ? [state.page.activeView]
      : state.page.panelsHistory[state.page.activeView]
  );
  const handleOnTransitionPage = useCallback(
    (param) => dispatch(onTransitionPage(param)),
    [dispatch]
  );

  return (
    <View
      id={props.id}
      activePanel={activePanel}
      history={history}
      onTransition={handleOnTransitionPage}
      modal={<ModalList />}
    >
      {/* Профиль */}

      <PanelProfile id={PANEL_PROFILE} />
      <PanelProfileViewPage id={PANEL_PROFILE_VIEW_PAGE} />
      <PanelProfileViewStories id={PANEL_PROFILE_VIEW_STORIES} />
      <PanelProfileLike id={PANEL_PROFILE_LIKE} />
      <PanelProfileComment id={PANEL_PROFILE_COMMENT} />
      <PanelProfileTimeOnline id={PANEL_PROFILE_TIME_ONLINE} />
      <PanelProfileInfoHistory id={PANEL_PROFILE_INFO_HISTORY} />
      <PanelProfileMaritalStatus id={PANEL_PROFILE_MARITAL_STATUS} />
      <PanelProfileFriendHistory id={PANEL_PROFILE_FRIEND_HISTORY} />
      <PanelProfileJoinCommunity id={PANEL_PROFILE_JOIN_COMMUNITY} />
      <PanelProfileAllComment id={PANEL_PROFILE_ALL_COMMENT} />
      <PanelProfileAllLike id={PANEL_PROFILE_ALL_LIKE} />
      <PanelProfileGift id={PANEL_PROFILE_GIFT} />
      <PanelProfileHoroscope id={PANEL_PROFILE_HOROSCOPE} />
      <PanelProfilePsychotype id={PANEL_PROFILE_PSYCHOTYPE} />

      {/* /Профиль */}

      <PanelMain id={PANEL_MAIN} />
      {/* Тотальная слежка */}
      <PanelTotalSurveillance id={PANEL_TOTAL_SURVEILLANCE} />
      <PanelTSStartTracking id={PANEL_TS__START_TRACKING} />
      <PanelTSSettingNotification id={PANEL_TS__SETTING_NOTIFICATION} />
      {/* Кто скрыл */}
      <PanelWhoHidden id={PANEL_WHO_HIDDEN} />
      <PanelWHWhoHiddenMe id={PANEL_WH__WHO_HIDDEN_ME} />
      <PanelWHWhoHiddenAnotherUser id={PANEL_WH__WHO_HIDDEN_ANOTHER_USER} />
      <PanelWHWhoHiddenAnotherUserInfo id={PANEL_WH__WHO_HIDDEN_ANOTHER_USER_INFO} />
      <PanelWHWhomHiddenIm id={PANEL_WH__WHOM_HIDDEN_IM} />
      <PanelWHWhomHiddenAnotherUser id={PANEL_WH__WHOM_HIDDEN_ANOTHER_USER} />
      <PanelWHWhomHiddenAnotherUserInfo id={PANEL_WH__WHOM_HIDDEN_ANOTHER_USER_INFO} />
      {/* Кто добавил в ЧС */}
      <PanelWhoAddedChs id={PANEL_WHO_ADDED_CHS} />
      {/* Дата регистрации */}
      <PanelRegistrationDate id={PANEL_REGISTRATION_DATE} />
      {/* Семейное положение */}
      <PanelMaritalStatus id={PANEL_MARTIAL_STATUS} />
      <PanelMSInfo id={PANEL_MS__INFO} />
      <PanelMSGeo id={PANEL_MS__GEO} />
      <PanelMSGeoSettings id={PANEL_MS__GEO_SETTINGS} />
      <PanelMSGeoSearch id={PANEL_MS__GEO_SEARCH} />
      {/* Подарки */}
      <PanelGift id={PANEL_GIFT} />
      <PanelGInfo id={PANEL_GIFT__INFO} />
      {/* Активности */}
      <PanelActivity id={PANEL_ACTIVITY} />
      <PanelAUser id={PANEL_A__USER} />
      <PanelAUserInfo id={PANEL_A__USER_INFO} />
      <PanelAOtherUser id={PANEL_A__OTHER_USER} />
      <PanelAOtherUserPage id={PANEL_A__OTHER_USER_PAGE} />
      <PanelAOtherUserActive id={PANEL_A__OTHER_USER_ACTIVE} />
      <PanelAOtherUserInfo id={PANEL_A__OTHER_USER_INFO} />
      {/* Новые друзья */}
      <PanelNewFriends id={PANEL_NEW_FRIENDS} />
      <PanelNFInfo id={PANEL_NEW_FRIENDS__INFO} />
      {/* Время онлайн */}
      <PanelTimeOnline id={PANEL_TIME_ONLINE} />
      <PanelTOTrackingUserInfo id={PANEL_TO__TRACKING_USER_INFO} />
      <PanelTOStartTracking id={PANEL_TO__START_TRACKING} />
      <PanelTOCompareTime id={PANEL_TO__COMPARE_TIME} />
      <PanelTOCompareTimeInfo id={PANEL_TO__COMPARE_TIME_INFO} />
      {/* Упоминания */}
      <PanelMention id={PANEL_MENTION} />
      <PanelMInfo id={PANEL_MENTION__INFO} />
      {/* Администрируемые */}
      <PanelAdminCommunity id={PANEL_ADMIN_COMMUNITY} />
      <PanelACInfo id={PANEL_AC_INFO} />
      {/* Вступления */}
      <PanelJoinCommunity id={PANEL_JOIN_COMMUNITY} />
      <PanelJCInfo id={PANEL_JC_INFO} />
      {/* Ловушка */}
      <PanelTrap id={PANEL_TRAP} />
      <PanelTConvertLink id={PANEL_T__CONVERT_LINK} />
      <PanelTSelfLinkDone id={PANEL_T__SELF_LINK_DONE} />
      <PanelTLinkDone id={PANEL_T__LINK_DONE} />
      {/* Пара */}
      <PanelPair id={PANEL_PAIR} />
      <PanelPairInfo id={PANEL_PAIR__INFO} />
      {/* Что слушают друзья */}
      <PanelWhoListenFriends id={PANEL_WHO_LISTEN_FRIENDS} />
      <PanelWLFPopularAudio id={PANEL_WLF_POPULAR_AUDIO} />
      <PanelWLFCommonAudio id={PANEL_WLF_COMMON_AUDIO} />
      <PanelWLFCommonAudioForMe id={PANEL_WLF_COMMON_AUDIO_FOR_ME} />
      <PanelWLFCommonAudioWith id={PANEL_WLF_COMMON_AUDIO_WITH} />
      {/* Давно не был онлайн */}
      <PanelWhoNotOnline id={PANEL_WHO_NOT_ONLINE} />
      {/* Общие сообщества */}
      <PanelCommonCommunity id={PANEL_COMMON_COMMUNITY} />
      {/* Общие друзья */}
      <PanelCommonFriends id={PANEL_COMMON_FRIENDS} />
      {/* Популярные друзья */}
      <PanelPopularFriends id={PANEL_POPULAR_FRIENDS} />
      {/* Удаленные друзья */}
      <PanelDeletedFriends id={PANEL_DELETED_FRIENDS} />
      {/* Друзья в приложении */}
      <PanelFriendsInApp id={PANEL_FRIENDS_IN_APP} />
      <PanelFIAAppsFromFriends id={PANEL_FIA__APPS_FROM_FRIENDS} />
      <PanelFIAAppsFromFriendsInfo id={PANEL_FIA__APPS_FROM_FRIENDS_INFO} />
      <PanelFIAInfo id={PANEL_FIA__INFO} />
      <PanelFIAWhatIsAppId id={PANEL_FIA__WHAT_IS_APP_ID} />
      {/* Популярные подписчики */}
      <PanelPopularSubscribers id={PANEL_POPULAR_SUBSCRIBER} />
      <PanelPSGroupInfo id={PANEL_PS__GROUP_INFO} />
      {/* Фильтр подписчиков */}
      <PanelSubscribersFilter id={PANEL_SUBSCRIBER_FILTER} />
      <PanelSFSearchResult id={PANEL_SF__SEARCH_RESULT} />
      {/* Скачивание историй */}
      <PanelDownloadHistory id={PANEL_DOWNLOAD_HISTORY} />
      <PanelDHInfo id={PANEL_DH__INFO} />
      {/* Статистика историй */}
      <PanelHistoryStatistics id={PANEL_HISTORY_STATISTICS} />
      <PanelHSInfo id={PANEL_HS__INFO} />
      <PanelHSInfoTopSpectator id={PANEL_HS__INFO_TOP_SPECTATOR} />
      {/* Популярные сообщества */}
      <PanelPopularCommunity id={PANEL_POPULAR_COMMUNITY} />
      {/* Активность сообществ */}
      <PanelCommunityActivity id={PANEL_COMMUNITY_ACTIVITY} />
      {/* Поклонники */}
      <PanelFan id={PANEL_FAN} />
      <PanelFTop id={PANEL_FAN__TOP} />
      {/* Постинг */}
      <PanelPosting id={PANEL_POSTING} />
      <PanelPostingInfo id={PANEL_POSTING__INFO} />
      {/* Шаблоны */}
      <PanelTemplateDateChangePeriod id={PANEL_TEMPLATE_DATE_CHANGE_PERIOD} />
    </View>
  );
};

export default ToolView;
