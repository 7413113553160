import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Panel } from "@vkontakte/vkui";
import WhomHiddenImList from "./components/WhomHiddenImList/WhomHiddenImList";
import { withRouter } from "hoc";

const PanelWHwhomHiddenIm = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Скрытые пользователи</PanelHeaderWithPath>
      <WhomHiddenImList
        openView={props.openView}
        openModal={props.openModal}
        closeModal={props.closeModal}
        openPopout={props.openPopout}
        closePopout={props.closePopout}
      />
    </Panel>
  );
};

export default withRouter(PanelWHwhomHiddenIm);
