import React from "react";

import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Panel } from "@vkontakte/vkui";
import NewFriendsUsersList from "./components/NFUserList/NFUserList";
import NewFriendIcon from "assets/img/tool/newFriends/banner/newFriends.png";
import ToolBanner from "template/components/ToolBanner/ToolBanner";
import { withRouter } from "hoc";

const PanelNewFriends = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Новые друзья</PanelHeaderWithPath>
      <ToolBanner
        tool="toolNewFriends"
        icon={NewFriendIcon}
        text="Узнавайте кого добавляет или удаляет выбранный пользователь"
      />
      <NewFriendsUsersList
        openView={props.openView}
        openModal={props.openModal}
        closeModal={props.closeModal}
      />
    </Panel>
  );
};

export default withRouter(PanelNewFriends);
