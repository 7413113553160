import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Panel } from "@vkontakte/vkui";
import RegistrationDateList from "./components/RDList/RDList";
import RegistrationDateTopPanel from "./components/RDTopPanel/RDTopPanel";

import { fetchRegistrationDate } from "store/actions/tool/registrationDate";

import { renderLoader } from "helpers/views";
import { withRouter } from "hoc";

const PanelRegistrationDate = (props) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.toolRegistrationDate.my.loading);
  const my = useSelector((state) =>
    state.toolRegistrationDate.my.payload ? state.toolRegistrationDate.my.payload : null
  );
  const handleFetchRegistrationDate = useCallback(
    () => dispatch(fetchRegistrationDate()),
    [dispatch]
  );

  const regDateShow = my ? my.reg_date_show : "";
  const regAgoTime = my ? my.reg_ago_time : "";
  const regPhoto = my ? my.photo_url : "";
  //const regPhoto200 = my ? my.photo_url_200 : '';

  const handlePostWall = async () => {
    // await VKWebAppShowWallPostBox({ message: "Тест - " + regDateShow });
  };

  const handlePostHistory = async () => {
    /*await VKWebAppShowStoryBox({
      backgroundType: "image",
      url: "https://sun9-65.userapi.com/c850136/v850136098/1b77eb/0YK6suXkY24.jpg",
    });*/
  };

  useEffect(() => {
    handleFetchRegistrationDate();
  }, [handleFetchRegistrationDate]);

  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Дата регистрации</PanelHeaderWithPath>

      {my !== null && (
        <RegistrationDateTopPanel
          description={regDateShow}
          bottomContent={regAgoTime}
          photo={regPhoto}
          handlePostWall={handlePostWall}
          handlePostHistory={handlePostHistory}
        />
      )}
      {renderLoader({ loading: loading })}

      <RegistrationDateList openModal={props.openModal} closeModal={props.closeModal} />
    </Panel>
  );
};

export default withRouter(PanelRegistrationDate);
