import React, { useState } from "react";
import { Button, Link, Panel, Placeholder } from "@vkontakte/vkui";
import { withRouter } from "hoc";
import BotIcon from "assets/img/bot.png";
import SnakbarCopy from "template/components/SnakbarCopy/SnakbarCopy";

const PanelNotSupported = (props) => {
  const [snakCopy, setSnakCopy] = useState(null);

  return (
    <Panel id={props.id}>
      <Placeholder
        icon={<img src={BotIcon} width={238} height={260} alt=""></img>}
        stretched
        header="Данное приложение переехало на новый адрес"
      >
        Переходи по ссылке{" "}
        <Link href={"https://app.mycrush.ru/"} target="_blank">
          https://app.mycrush.ru/
        </Link>{" "}
        или скопируй её, вставив в адресную строку браузера
        <div>
          <SnakbarCopy copy={snakCopy} setCopy={setSnakCopy} link={"https://app.mycrush.ru/"}>
            <Button mode="tertiary" style={{ marginTop: "20px" }}>
              Скопировать ссылку
            </Button>
          </SnakbarCopy>
        </div>
      </Placeholder>
    </Panel>
  );
};

export default withRouter(PanelNotSupported);
