import {
  APIEye3ActiveRefPartner,
  APIEye3RefererHistoryPartner,
  APIEye3WithdrawToCoinPartner,
  APIEye3WithdrawToMoneyPartner,
} from "api/eye3";
import { defaultErrorProperty } from "api/eye3/responseHandler";
import {
  PARTNER_REFERER_HISTORY_REQUEST,
  PARTNER_REFERER_HISTORY_SUCCESS,
  PARTNER_REFERER_HISTORY_ERROR,
  PARTNER_ACTIVE_REF_REQUEST,
  PARTNER_ACTIVE_REF_SUCCESS,
  PARTNER_ACTIVE_REF_ERROR,
  PARTNER_WITHDRAW_TO_COIN_REQUEST,
  PARTNER_WITHDRAW_TO_COIN_SUCCESS,
  PARTNER_WITHDRAW_TO_COIN_ERROR,
  PARTNER_WITHDRAW_TO_MONEY_REQUEST,
  PARTNER_WITHDRAW_TO_MONEY_SUCCESS,
  PARTNER_WITHDRAW_TO_MONEY_ERROR,
  PARTNER_CLS_WITHDRAW,
  PARTNER_REFERER_HISTORY_SHOW_MORE,
} from "../constType";
import { updateMyProfile } from "./myProfile";

export function clsWithdraw() {
  return { type: PARTNER_CLS_WITHDRAW };
}

export function activeRef(ref) {
  return async (dispatch, getState) => {
    try {
      await dispatch(request());

      await APIEye3ActiveRefPartner({
        ref: ref,
      });

      await dispatch(success(true));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: PARTNER_ACTIVE_REF_REQUEST };
  }

  function success(payload) {
    return { type: PARTNER_ACTIVE_REF_SUCCESS, payload };
  }

  function error(error) {
    return { type: PARTNER_ACTIVE_REF_ERROR, error };
  }
}

export function fetchRefererHistory() {
  return async (dispatch, getState) => {
    try {
      await dispatch(request());

      let result = [];
      const response = await APIEye3RefererHistoryPartner();

      if (!response.status) {
        dispatch(
          error({
            ...defaultErrorProperty,
            message: "",
          })
        );

        return result;
      }

      const balanceItems = response.items;
      for (let item of balanceItems) {
        result.push({
          count: item.count,
          _at: item._at,
          txt: item.txt,
        });
      }

      await dispatch(success(result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: PARTNER_REFERER_HISTORY_REQUEST };
  }

  function success(payload) {
    return { type: PARTNER_REFERER_HISTORY_SUCCESS, payload };
  }

  function error(error) {
    return { type: PARTNER_REFERER_HISTORY_ERROR, error };
  }
}

export function showMoreRefererHistory() {
  return { type: PARTNER_REFERER_HISTORY_SHOW_MORE };
}

export function withdrawToMoney(card, sum) {
  return async (dispatch, getState) => {
    try {
      await dispatch(request());

      const response = await APIEye3WithdrawToMoneyPartner({
        card: card,
        sum: sum,
      });

      if (!response.status) {
        await dispatch(
          error({ ...defaultErrorProperty, message: response.text, isVisibleMessage: true })
        );
        return;
      }

      await dispatch(success(true));
      await dispatch(updateMyProfile());
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: PARTNER_WITHDRAW_TO_MONEY_REQUEST };
  }

  function success(payload) {
    return { type: PARTNER_WITHDRAW_TO_MONEY_SUCCESS, payload };
  }

  function error(error) {
    return { type: PARTNER_WITHDRAW_TO_MONEY_ERROR, error };
  }
}

export function withdrawToCoin(sum) {
  return async (dispatch, getState) => {
    try {
      await dispatch(request());

      const response = await APIEye3WithdrawToCoinPartner({
        sum: sum,
      });

      if (!response.status) {
        await dispatch(
          error({ ...defaultErrorProperty, message: response.text, isVisibleMessage: true })
        );
        return;
      }

      await dispatch(success(true));
      await dispatch(updateMyProfile());
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: PARTNER_WITHDRAW_TO_COIN_REQUEST };
  }

  function success(payload) {
    return { type: PARTNER_WITHDRAW_TO_COIN_SUCCESS, payload };
  }

  function error(error) {
    return { type: PARTNER_WITHDRAW_TO_COIN_ERROR, error };
  }
}
