import React from "react";

import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Panel } from "@vkontakte/vkui";
import HSIcon from "assets/img/tool/historyStatistics/historyStatistics.png";
import ToolBanner from "template/components/ToolBanner/ToolBanner";
import HSUserList from "./components/HSUserList/HSUserList";
import { withRouter } from "hoc";

const PanelHistoryStatistics = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Статистика историй</PanelHeaderWithPath>
      <ToolBanner
        tool="toolHistoryStatistics"
        icon={HSIcon}
        text="Узнавайте статистику историй за неделю либо статистику отдельной истории"
      />
      <HSUserList
        openView={props.openView}
        openPopout={props.openPopout}
        closePopout={props.closePopout}
      />
    </Panel>
  );
};

export default withRouter(PanelHistoryStatistics);
