import { ModalRoot } from "@vkontakte/vkui";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openStory } from "store/actions/page";

import FilterAgeCity from "template/modals/FilterAgeCity/FilterAgeCity";
import { MODAL_PAGE_TEMPLATE_FILTER_AGE_CITY } from "template/modals/constModals";
import { MODAL_CARD_CRUSH_POST } from "./constModals";
import CrushPost from "./CrushPostModal/CrushPostModal";

// Профиль

import ProfileMutualFriendModal from "views/profile/modals/ProfileMutualFriendModal/ProfileMutualFriendModal";
import ProfileMutualCommunityModal from "views/profile/modals/ProfileMutualCommunityModal/ProfileMutualCommunityModal";
import HoroscopeDateBirthModal from "views/profile/modals/HoroscopeDateBirthModal/HoroscopeDateBirthModal";

import {
  MODAL_PAGE_MUTUAL_FRIEND,
  MODAL_PAGE_MUTUAL_COMMUNITY,
  MODAL_CARD_HOROSCOPE_DATE_BIRTH,
} from "views/profile/modals/constModals";

import {
  MODAL_PAGE_TEMPLATE_TRACKING_OPTION,
  MODAL_CARD_TEMPLATE_TARIFF_WORK,
  MODAL_CARD_TEMPLATE_TARIFF_LIMIT,
} from "template/modals/constModals";
import TrackingOptionModal from "template/modals/TrackingOption/TrackingOption";
import TariffWorkModal from "template/modals/TariffWork/TariffWork";
import TariffLimitModal from "template/modals/TariffLimit/TariffLimit";

//

const ModalList = () => {
  const dispatch = useDispatch();
  const handleOpenStory = useCallback(
    ({ story, panel }) => dispatch(openStory({ story: story, panel: panel })),
    [dispatch]
  );
  const modalParam = useSelector((state) => state.page.modalParam);
  const activeModal = useSelector((state) =>
    state.page.activeModal[state.page.activeView] === undefined
      ? null
      : state.page.activeModal[state.page.activeView]
  );
  const handleCloseModal = useCallback(() => dispatch(closeModal()), [dispatch]);

  return (
    <ModalRoot activeModal={activeModal} onClose={handleCloseModal}>
      <FilterAgeCity id={MODAL_PAGE_TEMPLATE_FILTER_AGE_CITY} modalParam={modalParam} />
      <CrushPost
        id={MODAL_CARD_CRUSH_POST}
        modalParam={modalParam}
        handleCloseModal={handleCloseModal}
      />
      <TariffWorkModal
        id={MODAL_CARD_TEMPLATE_TARIFF_WORK}
        openStory={handleOpenStory}
        handleCloseModal={handleCloseModal}
        modalParam={modalParam}
      />
      <TariffLimitModal
        id={MODAL_CARD_TEMPLATE_TARIFF_LIMIT}
        openStory={handleOpenStory}
        handleCloseModal={handleCloseModal}
        modalParam={modalParam}
      />

      {/* Профиль */}

      <ProfileMutualFriendModal id={MODAL_PAGE_MUTUAL_FRIEND} />
      <ProfileMutualCommunityModal id={MODAL_PAGE_MUTUAL_COMMUNITY} />
      <TrackingOptionModal id={MODAL_PAGE_TEMPLATE_TRACKING_OPTION} modalParam={modalParam} />
      <HoroscopeDateBirthModal
        id={MODAL_CARD_HOROSCOPE_DATE_BIRTH}
        modalParam={modalParam}
        handleCloseModal={handleCloseModal}
      />

      {/* /Профиль */}
    </ModalRoot>
  );
};

export default ModalList;
