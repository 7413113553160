import {
  TRAP__CONVERT_LINK_REQUEST,
  TRAP__CONVERT_LINK_SUCCESS,
  TRAP__CONVERT_LINK_ERROR,
  TRAP__CONVERT_SELF_LINK_REQUEST,
  TRAP__CONVERT_SELF_LINK_SUCCESS,
  TRAP__CONVERT_SELF_LINK_ERROR,
  TRAP__WHO_CROSSED_OVER_REQUEST,
  TRAP__WHO_CROSSED_OVER_SUCCESS,
  TRAP__WHO_CROSSED_OVER_ERROR,
} from "../../constType";

const initialState = {
  convertLink: {
    payload: null,
    loading: false,
    error: null,
  },
  convertSelfLink: {
    payload: null,
    loading: false,
    error: null,
  },
  whoCrossedOver: {
    payload: null,
    loading: false,
    error: null,
  },
};

export default function trapReducer(state = initialState, action) {
  switch (action.type) {
    case TRAP__CONVERT_LINK_REQUEST:
      return {
        ...state,
        convertLink: {
          ...state.convertLink,
          payload: null,
          loading: true,
          error: null,
        },
      };
    case TRAP__CONVERT_LINK_SUCCESS:
      return {
        ...state,
        convertLink: {
          ...state.convertLink,
          payload: action.payload,
          loading: false,
        },
      };
    case TRAP__CONVERT_LINK_ERROR:
      return {
        ...state,
        convertLink: {
          ...state.convertLink,
          error: action.error,
          loading: false,
        },
      };
    case TRAP__CONVERT_SELF_LINK_REQUEST:
      return {
        ...state,
        convertSelfLink: {
          ...state.convertSelfLink,
          payload: null,
          loading: true,
          error: null,
        },
      };
    case TRAP__CONVERT_SELF_LINK_SUCCESS:
      return {
        ...state,
        convertSelfLink: {
          ...state.convertSelfLink,
          payload: action.payload,
          loading: false,
        },
      };
    case TRAP__CONVERT_SELF_LINK_ERROR:
      return {
        ...state,
        convertSelfLink: {
          ...state.convertSelfLink,
          error: action.error,
          loading: false,
        },
      };
    case TRAP__WHO_CROSSED_OVER_REQUEST:
      return {
        ...state,
        whoCrossedOver: {
          ...state.whoCrossedOver,
          payload: null,
          loading: true,
          error: null,
        },
      };
    case TRAP__WHO_CROSSED_OVER_SUCCESS:
      return {
        ...state,
        whoCrossedOver: {
          ...state.whoCrossedOver,
          payload: action.payload,
          loading: false,
        },
      };
    case TRAP__WHO_CROSSED_OVER_ERROR:
      return {
        ...state,
        whoCrossedOver: {
          ...state.whoCrossedOver,
          error: action.error,
          loading: false,
        },
      };
    default:
      return state;
  }
}
