import { queryEye3 } from "api";

async function APIEye3EnableTrackOnline({ userId = null, userIds = null, enable = null }) {
  try {
    const response = await queryEye3({
      method: "trackonline.enable",
      get: {
        user_id: userId,
        user_ids: userIds,
        enable,
      },
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3EnableTrackOnline;
