import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Placeholder, SimpleCell } from "@vkontakte/vkui";
import SeparateFilter from "template/components/SeparateFilter/SeparateFilter";
import GiftItem from "../GiftItem/GiftItem";
import Icon56GiftOutline from "@vkontakte/icons/dist/56/gift_outline";

import { unixTimeToDate, convertMonthDay, convertTime } from "helpers";

import { fetchGift, showMoreGift } from "store/actions/profile/gift";
import { declOfNum } from "helpers";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import { useShowMore } from "hooks";
import HeadTitle from "template/components/HeadTitle/HeadTitle";

const GiftList = () => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const activeStory = useSelector((state) => state.page.activeStory);
  const profileHistory = useSelector((state) => state.profile.history);
  const loading = useSelector((state) => state.profileGift.loading);
  const error = useSelector((state) => state.profileGift.error);
  const gifts = useSelector((state) => state.profileGift.payload);
  const showCount = useSelector((state) => state.profileGift.showCount);

  const handleFetchGift = useCallback(() => dispatch(fetchGift()), [dispatch]);
  const handleShowMoreGift = useCallback(() => dispatch(showMoreGift()), [dispatch]);

  let lastAt = new Date();

  useEffect(() => {
    handleFetchGift();
  }, [handleFetchGift]);

  useShowMore({
    items: gifts,
    showCount: showCount,
    maxLength: gifts !== null ? gifts.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMoreGift,
  });

  const renderTopPanel = () => {
    const user = profileHistory[activeStory].user;
    const name = user.name;
    const city = user.city ? user.city : "";
    const age = user.age ? user.age : "";
    const photo = user.photo_url ? user.photo_url : "";
    const ageStrFormat = age ? age + " " + declOfNum(age, ["год", "года", "лет"]) : "";
    const comma = ageStrFormat && city ? ", " : "";

    return (
      <SimpleCell
        disabled
        before={<Avatar size={72} src={photo} />}
        description={ageStrFormat + comma + city}
      >
        {name}
      </SimpleCell>
    );
  };

  const renderGift = () => {
    return gifts.length > 0 ? (
      gifts.map((gift, i) => {
        const id = gift.vk_id;
        const name = gift.name;
        const type = gift.type;
        const text = gift.text;
        const photoUser = gift.photo_user_url;
        const photoGift = gift.photo_gift_url;
        const date = unixTimeToDate(gift.date);
        const sex = profileHistory[activeStory].user.sex;

        let separate = <SeparateFilter>{convertMonthDay(date)}</SeparateFilter>;

        if (lastAt.getDate() === date.getDate() && i !== 0) {
          separate = "";
        }

        lastAt = date;

        if (i < showCount) {
          return (
            <div key={i}>
              {separate}
              <GiftItem
                key={i}
                time={convertTime(date)}
                id={id}
                title={name}
                type={type}
                sex={sex}
                text={text}
                photoUser={photoUser}
                photoGift={photoGift}
              />
            </div>
          );
        }

        return false;
      })
    ) : (
      <Placeholder icon={<Icon56GiftOutline />}>Подарки не найдены</Placeholder>
    );
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      <HeadTitle>Подарки</HeadTitle>
      {renderTopPanel()}
      {gifts !== null && error === null ? renderGift() : ""}
      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchGift,
      })}
    </div>
  );
};

export default GiftList;
