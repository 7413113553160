import { Button, Panel } from "@vkontakte/vkui";
import { withRouter } from "hoc";
import React, { useEffect, useState } from "react";
import { VIEW_EDUCATION } from "views/constViews";
import EducationStep from "views/education/components/EducationStep/EducationStep";
import { PANEL_STEP6 } from "../constPanels";
import lessonGIF from "assets/img/education/step5/1.gif";
import { CSSTransition } from "react-transition-group";
import styles from "./step5.module.scss";
import { getSearchObject } from "api";
import { DESKTOP_WEB } from "api/vk/constPlatform";

const PanelStep5 = (props) => {
  const [inAnimation, setInAnimation] = useState(false);

  const back = () => {
    props.goBack();
  };

  const next = () => {
    props.openView({ view: VIEW_EDUCATION, panel: PANEL_STEP6 });
  };

  useEffect(() => {
    setInAnimation(true);
  }, []);

  return (
    <Panel id={props.id}>
      <EducationStep
        description={
          <CSSTransition
            in={inAnimation}
            timeout={500}
            classNames={{
              enterActive: styles.enterActive,
              enterDone: styles.enterDone,
            }}
          >
            <div>
              Кто добавил в ЧС и увидишь какие друзья скрыты. <br /> <br /> И это лишь малая часть
              возможностей!
            </div>
          </CSSTransition>
        }
        icon={
          getSearchObject().vk_platform === DESKTOP_WEB ? (
            <img src={lessonGIF} width={226} height={290} alt=""></img>
          ) : (
            <img src={lessonGIF} width="100%" height="100%" alt=""></img>
          )
        }
        action={
          <div style={{ display: "flex" }}>
            <CSSTransition
              in={inAnimation}
              timeout={2000}
              classNames={{
                enterActive: styles.enterActive,
                enterDone: styles.enterDone,
              }}
            >
              <Button
                mode="secondary"
                size="xl"
                style={{ width: "100%", marginRight: "16px" }}
                onClick={back}
              >
                Назад
              </Button>
            </CSSTransition>
            <CSSTransition
              in={inAnimation}
              timeout={2200}
              classNames={{
                enterActive: styles.enterActive,
                enterDone: styles.enterDone,
              }}
            >
              <Button mode="primary" size="xl" style={{ width: "100%" }} onClick={next}>
                Понятно!
              </Button>
            </CSSTransition>
          </div>
        }
      />
    </Panel>
  );
};

export default withRouter(PanelStep5);
