import React from "react";
import { Div } from "@vkontakte/vkui";
import Icon12OnlineMobile from "@vkontakte/icons/dist/12/online_mobile";
import OnlinePCIcon from "assets/img/onlinePC.svg";

const TOTrackingUserInfoItem = (props) => {
  return (
    <Div>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "14px" }}>
        <div
          style={{
            display: "flex",
            fontSize: "16px",
            lineHeight: "20px",
            letterSpacing: "0.1px",
          }}
        >
          {props.children}
          {props.isOnlineMobile ? (
            <Icon12OnlineMobile fill={"#4bb34b"} style={{ marginLeft: "2px", marginTop: "3px" }} />
          ) : (
            <img src={OnlinePCIcon} style={{ marginLeft: "2px", marginBottom: "2px" }} alt="" />
          )}
        </div>
        <div
          style={{
            fontSize: "13px",
            lineHeight: "16px",
            letterSpacing: "0.2px",
            color: "var(--text_secondary)",
          }}
        >
          {props.periodBetween}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "8px",
        }}
      >
        <div
          style={{
            fontSize: "13px",
            width: "120px",
            lineHeight: "16px",
            letterSpacing: "0.2px",
            color: "var(--text_secondary)",
            marginRight: "2px",
          }}
        >
          Просмотр ленты
        </div>
        <div style={{ width: "25%", height: "3px", position: "relative" }}>
          <div
            style={{
              width: `${props.widthContentLine}px`,
              maxWidth: "100%",
              height: "3px",
              backgroundColor: "#FFA000",
              borderRadius: "4px",
              marginBottom: "2px",
            }}
          ></div>
        </div>
        <div
          style={{
            fontSize: "13px",
            lineHeight: "16px",
            letterSpacing: "0.2px",
            color: "var(--text_secondary)",
            textAlign: "right",
            width: "80px",
            marginLeft: "2px",
          }}
        >
          {props.periodContent}
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div
          style={{
            fontSize: "13px",
            lineHeight: "16px",
            marginRight: "2px",
            width: "120px",
            letterSpacing: "0.2px",
            color: "var(--text_secondary)",
          }}
        >
          Переписка
        </div>
        <div style={{ width: "25%", height: "3px", position: "relative" }}>
          <div
            style={{
              width: `${props.widthMsgLine}px`,
              maxWidth: "100%",
              height: "3px",
              backgroundColor: "#3F8AE0",
              borderRadius: "4px",
              marginBottom: "2px",
            }}
          ></div>
        </div>
        <div
          style={{
            fontSize: "13px",
            lineHeight: "16px",
            letterSpacing: "0.2px",
            textAlign: "right",
            color: "var(--text_secondary)",
            width: "80px",
            marginLeft: "2px",
          }}
        >
          {props.periodMsg}
        </div>
      </div>
    </Div>
  );
};

export default TOTrackingUserInfoItem;
