import {
  TRAP__CONVERT_LINK_REQUEST,
  TRAP__CONVERT_LINK_SUCCESS,
  TRAP__CONVERT_LINK_ERROR,
  TRAP__CONVERT_SELF_LINK_REQUEST,
  TRAP__CONVERT_SELF_LINK_SUCCESS,
  TRAP__CONVERT_SELF_LINK_ERROR,
  TRAP__WHO_CROSSED_OVER_REQUEST,
  TRAP__WHO_CROSSED_OVER_SUCCESS,
  TRAP__WHO_CROSSED_OVER_ERROR,
} from "../../constType";

import { APIEye3CreateSelfTrap, APIEye3CreateTrap, APIEye3GetTrap } from "api/eye3";
import { delayMethodGetUsersVKApi } from "api";
import { defaultErrorProperty } from "api/eye3/responseHandler";

export function fetchConvertLink(url) {
  return async (dispatch) => {
    try {
      dispatch(request());

      let result = {};

      const trap = await APIEye3CreateTrap({
        url: url,
      });

      //console.log('trap: ', trap);

      if (!trap.status) {
        throw new Error("error");
      }

      result.link = trap.link;

      dispatch(success(result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: TRAP__CONVERT_LINK_REQUEST };
  }

  function success(payload) {
    return { type: TRAP__CONVERT_LINK_SUCCESS, payload };
  }

  function error(error) {
    return { type: TRAP__CONVERT_LINK_ERROR, error };
  }
}

export function fetchConvertSelfLink() {
  return async (dispatch, getState) => {
    try {
      const state = getState().toolTrap;

      if (state.convertSelfLink.error === null && state.convertSelfLink.payload !== null) return;

      dispatch(request());

      let result = {};

      const trap = await APIEye3CreateSelfTrap();

      //console.log('trap: ', trap);

      if (!trap.status) {
        throw new Error("error");
      }

      result.link = trap.link;

      dispatch(success(result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: TRAP__CONVERT_SELF_LINK_REQUEST };
  }

  function success(payload) {
    return { type: TRAP__CONVERT_SELF_LINK_SUCCESS, payload };
  }

  function error(error) {
    return { type: TRAP__CONVERT_SELF_LINK_ERROR, error };
  }
}

export function fetchWhoCrossedOver() {
  return async (dispatch, getState) => {
    try {
      const state = getState().toolTrap;

      if (state.whoCrossedOver.loading) return false;

      dispatch(request());

      let result = [];
      const trap = await APIEye3GetTrap();

      if (trap.users.length === 0) {
        return dispatch(success(result));
      }

      const usersInfo = await delayMethodGetUsersVKApi({
        userIds: trap.users.map((item) => item.vk_id),
        fields: ["photo_100"],
      });

      const usersItems = usersInfo;

      trap.users.forEach((t) => {
        usersItems.forEach((u) => {
          if (t.vk_id === u.id) {
            result.push({
              vk_id: t.vk_id,
              name: u.first_name + " " + u.last_name,
              url: t.url,
              _at: t._at,
              photo_url: u.photo_100 ? u.photo_100 : "", // Фото
            });

            return;
          }
        });
      });

      dispatch(success(result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: TRAP__WHO_CROSSED_OVER_REQUEST };
  }

  function success(payload) {
    return { type: TRAP__WHO_CROSSED_OVER_SUCCESS, payload };
  }

  function error(error) {
    return { type: TRAP__WHO_CROSSED_OVER_ERROR, error };
  }
}
