import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Group, Header } from "@vkontakte/vkui";

import { fetchHistoryStatistics } from "store/actions/tool/historyStatistics";
import HSUserItem from "../HSUserItem/HSUserItem";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import { STORY_TYPE_USER } from "../../constStoryType";

const HSUserList = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.toolHistoryStatistics.stories.payload);
  const loading = useSelector((state) => state.toolHistoryStatistics.stories.loading);
  const error = useSelector((state) => state.toolHistoryStatistics.stories.error);
  const handleFetchHistoryStatistics = useCallback(
    () => dispatch(fetchHistoryStatistics()),
    [dispatch]
  );

  useEffect(() => {
    handleFetchHistoryStatistics();
  }, [handleFetchHistoryStatistics]);

  const renderUser = () => {
    const id = user.vk_id;
    const name = user.name;
    const photo = user.photo_url;

    return (
      <HSUserItem
        id={id}
        title={name}
        photo={photo}
        storyType={STORY_TYPE_USER}
        openView={props.openView}
      />
    );
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      {user !== null && error === null ? (
        <Group header={<Header mode="secondary">Мои истории</Header>} separator="hide">
          {renderUser()}
        </Group>
      ) : (
        ""
      )}
      {/*<Group header={<Header mode='secondary'>Истории в сообществах</Header>} separator='hide'>
                
            </Group>*/}
      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchHistoryStatistics,
      })}
    </div>
  );
};

export default HSUserList;
