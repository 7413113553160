import React from "react";

import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Panel } from "@vkontakte/vkui";
import PInfoList from "./components/PInfoList/PInfoList";
import { withRouter } from "hoc";

const PanelPInfo = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Постинг</PanelHeaderWithPath>
      <PInfoList
        openView={props.openView}
        openPopout={props.openPopout}
        closePopout={props.closePopout}
      />
    </Panel>
  );
};

export default withRouter(PanelPInfo);
