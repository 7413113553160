import { queryEye3 } from "api";

async function APIEye3GetMutualTool({ userId }) {
  try {
    const response = await queryEye3({
      method: "tool.get_mutual",
      get: { user_id: userId },
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3GetMutualTool;
