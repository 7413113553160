import React, { useCallback, useEffect, useState } from "react";
import { Button, Div, FormLayout, FormLayoutGroup, Select, Group } from "@vkontakte/vkui";
import { PANEL_SF__SEARCH_RESULT } from "../../../constPanels";
import { VIEW_TOOL } from "views/constViews";
import { APIVkGetCitiesDatabase, APIVkGetCountriesDatabase } from "api/vk/apiMethods";
import { useDispatch, useSelector } from "react-redux";
import { setSubscribersFilter } from "store/actions/tool/subscribersFilter";

const CITY_ID = "cityId";
const COUNTRY_ID = "countryId";
const SEX = "sex";
const AGE_FROM = "ageFrom";
const AGE_TO = "ageTo";
const RELATION = "relation";

const SFFilter = (props) => {
  const dispatch = useDispatch();
  const handleSetSubscribersFilter = useCallback(
    (filter) => dispatch(setSubscribersFilter(filter)),
    [dispatch]
  );
  const filter = useSelector((state) => state.toolSubscribersFilter.filter);

  const [cities, setCities] = useState("");
  const [countries, setCountries] = useState("");

  useEffect(() => {
    APIVkGetCountriesDatabase().then(async (country) => {
      setCountries(country.response);

      if (filter.countryId !== "") {
        const cities = await APIVkGetCitiesDatabase({
          countryId: filter.countryId,
        });
        setCities(cities.response);
      }
    });
  }, [filter.countryId]);

  const onChangeOption = async (e, type) => {
    switch (type) {
      case COUNTRY_ID:
        setCities("");

        handleSetSubscribersFilter({
          ...filter,
          cityId: "",
          countryId: e.target.value,
        });

        if (e.target.value !== "") {
          const cities = await APIVkGetCitiesDatabase({
            countryId: e.target.value,
          });

          setCities(cities.response);
        }
        break;
      case CITY_ID:
        handleSetSubscribersFilter({
          ...filter,
          cityId: e.target.value,
        });
        break;
      case RELATION:
        handleSetSubscribersFilter({
          ...filter,
          relation: e.target.value,
        });
        break;
      case SEX:
        handleSetSubscribersFilter({
          ...filter,
          sex: e.target.value,
        });
        break;
      case AGE_FROM:
        handleSetSubscribersFilter({
          ...filter,
          ageFrom: e.target.value,
        });
        break;
      case AGE_TO:
        handleSetSubscribersFilter({
          ...filter,
          ageTo: e.target.value,
        });
        break;
      default:
        break;
    }
  };

  const getAges = () => {
    let ages = [];
    for (let i = 14; i <= 80; i++) {
      ages.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }

    return ages;
  };

  const showResult = async () => {
    props.openView({ view: VIEW_TOOL, panel: PANEL_SF__SEARCH_RESULT });
  };

  return (
    <>
      <Group style={{ marginBottom: "20px" }}>
        <Div>
          <div style={{ color: "#818C99", fontSize: "13px", textTransform: "uppercase" }}>
            Параметры фильтра
          </div>
        </Div>

        <FormLayout>
          <Select top="Пол" defaultValue={filter.sex} onChange={(e) => onChangeOption(e, SEX)}>
            <option value={""}>Любой</option>
            <option value={1}>Женский</option>
            <option value={2}>Мужской</option>
          </Select>
          <FormLayoutGroup
            top="Возраст"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
              maxHeight: "44px",
            }}
          >
            <Select
              placeholder="От"
              style={{ width: "100%" }}
              defaultValue={filter.ageFrom}
              onChange={(e) => onChangeOption(e, AGE_FROM)}
            >
              {getAges()}
            </Select>
            <Select
              placeholder="До"
              style={{ width: "100%" }}
              defaultValue={filter.ageTo}
              onChange={(e) => onChangeOption(e, AGE_TO)}
            >
              {getAges()}
            </Select>
          </FormLayoutGroup>
          <Select
            top="Страна"
            placeholder="Выбрать страну"
            defaultValue={filter.countryId}
            onChange={(e) => onChangeOption(e, COUNTRY_ID)}
          >
            {countries !== "" &&
              countries.items.map((country, i) => {
                return (
                  <option key={i} value={country.id}>
                    {" "}
                    {country.title}{" "}
                  </option>
                );
              })}
          </Select>
          <Select
            top="Город"
            placeholder="Выбрать город"
            defaultValue={filter.cityId}
            onChange={(e) => onChangeOption(e, CITY_ID)}
            disabled={cities !== "" ? false : true}
          >
            {cities !== "" &&
              cities.items.map((city, i) => {
                return (
                  <option key={i} value={city.id}>
                    {" "}
                    {city.title}{" "}
                  </option>
                );
              })}
          </Select>
          <Select
            defaultValue={filter.relation}
            top="Семейное положение"
            onChange={(e) => onChangeOption(e, RELATION)}
          >
            <option value={""}>Любое</option>
            <option value={1}>не женат/не замужем</option>
            <option value={2}>есть друг/есть подруга</option>
            <option value={3}>помолвлен/помолвлена</option>
            <option value={4}>женат/замужем</option>
            <option value={5}>всё сложно</option>
            <option value={6}>в активном поиске</option>
            <option value={7}>влюблён/влюблена</option>
            <option value={8}>в гражданском браке</option>
          </Select>
        </FormLayout>
        <Div>
          <Button style={{ width: "100%" }} onClick={showResult} size="xl" stretched>
            Применить фильтр
          </Button>
        </Div>
      </Group>
    </>
  );
};

export default SFFilter;
