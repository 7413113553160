import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Placeholder, SimpleCell } from "@vkontakte/vkui";
import Icon56LikeOutline from "@vkontakte/icons/dist/56/like_outline";
import AllLikeItem from "../AllLikeItem/AllLikeItem";
import SeparateFilter from "template/components/SeparateFilter/SeparateFilter";

import { unixTimeToDate, convertMonthDay, convertTime } from "helpers";

import { fetchAllLike, cancelAllLike, showMoreAllLike } from "store/actions/profile/allLike";
import { declOfNum } from "helpers";
import LoaderWithProgressBar from "template/components/LoaderWithProgressBar/LoaderWithProgressBar";
import { renderErrorPlaceholder } from "helpers/views";
import { useShowMore } from "hooks";
import HeadTitle from "template/components/HeadTitle/HeadTitle";

const AllLikeList = () => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const activeStory = useSelector((state) => state.page.activeStory);
  const profileHistory = useSelector((state) => state.profile.history);

  const allLikes = useSelector((state) => state.profileAllLike.payload);
  const progressValue = useSelector((state) => state.profileAllLike.progressValue);
  const error = useSelector((state) => state.profileAllLike.error);
  const loading = useSelector((state) => state.profileAllLike.loading);
  const showCount = useSelector((state) => state.profileAllLike.showCount);

  const handleFetchAllLike = useCallback(() => dispatch(fetchAllLike()), [dispatch]);
  const handleCancelAllLike = useCallback(() => dispatch(cancelAllLike()), [dispatch]);
  const handleShowMoreAllLike = useCallback(() => dispatch(showMoreAllLike()), [dispatch]);

  let lastAt = new Date();

  useEffect(() => {
    return () => {
      handleCancelAllLike();
    };
  }, [handleCancelAllLike]);

  useEffect(() => {
    handleFetchAllLike();
  }, [handleFetchAllLike]);

  useShowMore({
    items: allLikes,
    showCount: showCount,
    maxLength: allLikes !== null ? allLikes.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMoreAllLike,
  });

  const renderTopPanel = () => {
    const user = profileHistory[activeStory].user;
    const name = user.name;
    const city = user.city ? user.city : "";
    const age = user.age ? user.age : "";
    const photo = user.photo_url ? user.photo_url : "";
    const ageStrFormat = age ? age + " " + declOfNum(age, ["год", "года", "лет"]) : "";
    const comma = ageStrFormat && city ? ", " : "";

    return (
      <SimpleCell
        disabled
        before={<Avatar size={72} src={photo} />}
        description={ageStrFormat + comma + city}
      >
        {name}
      </SimpleCell>
    );
  };

  const renderAllLike = () => {
    return allLikes.map((like, i) => {
      const id = like.vk_id;
      const at = unixTimeToDate(like._at);
      const type = like.type;
      const infoText = like.info_text;
      const infoPhoto = like.info_photo;
      const infoLink = like.info_link;
      const sex = profileHistory[activeStory].user.sex;

      let separate = <SeparateFilter>{convertMonthDay(at)}</SeparateFilter>;

      if (lastAt.getDate() === at.getDate() && i !== 0) {
        separate = "";
      }

      lastAt = at;

      if (i < showCount) {
        return (
          <div key={i}>
            {separate}
            <AllLikeItem
              id={id}
              time={convertTime(at)}
              type={type}
              infoText={infoText}
              infoPhoto={infoPhoto}
              infoLink={infoLink}
              sex={sex}
            />
          </div>
        );
      }

      return false;
    });
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      <HeadTitle>Все лайки</HeadTitle>
      {renderTopPanel()}
      {loading ? <LoaderWithProgressBar progressValue={progressValue} /> : ""}
      {allLikes !== null && error === null ? renderAllLike() : ""}

      {!loading &&
      allLikes !== null &&
      Object.prototype.toString.call(allLikes) === "[object Array]" &&
      error === null &&
      allLikes.length <= 0 ? (
        <Placeholder icon={<Icon56LikeOutline />}>Лайки не найдены</Placeholder>
      ) : (
        ""
      )}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchAllLike,
      })}
    </div>
  );
};

export default AllLikeList;
