import React from "react";

import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import GiftIcon from "assets/img/tool/gift/banner/gift.png";
import GUserList from "./components/GUserList/GUserList";
import { Panel } from "@vkontakte/vkui";
import ToolBanner from "template/components/ToolBanner/ToolBanner";
import { withRouter } from "hoc";

const PanelGift = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Подарки</PanelHeaderWithPath>
      <ToolBanner
        tool="toolGift"
        icon={GiftIcon}
        text="Узнавайте кто из друзей отправлял или получал подарки"
      />
      <GUserList
        openView={props.openView}
        openModal={props.openModal}
        closeModal={props.closeModal}
      />
    </Panel>
  );
};

export default withRouter(PanelGift);
