import { queryEye3 } from "api";

async function APIEye3SetEnableTrackActive({ userId, enable, settings }) {
  try {
    const response = await queryEye3({
      method: "trackactive.set_enable",
      get: {
        user_id: userId,
        enable: enable,
        setting_likes: settings ? +settings.likes : 1,
        setting_comments: settings ? +settings.comments : 1,
        setting_friends: settings ? +settings.friends : 1,
        setting_groups: settings ? +settings.groups : 1,
        setting_relation: settings ? +settings.relation : 1,
        setting_online: settings ? +settings.online : 1,
        setting_info: settings ? +settings.info : 1,
      },
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3SetEnableTrackActive;
