import {
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_ERROR,
  PROFILE_SET_TRACKING_REQUEST,
  PROFILE_SET_TRACKING_SUCCESS,
  PROFILE_SET_TRACKING_ERROR,
  PROFILE_SET_NOTIFICATION_REQUEST,
  PROFILE_SET_NOTIFICATION_SUCCESS,
  PROFILE_SET_NOTIFICATION_ERROR,
  PROFILE_TRACK_ACTIVE_REQUEST,
  PROFILE_TRACK_ACTIVE_SUCCESS,
  PROFILE_TRACK_ACTIVE_ERROR,
  PROFILE_SET_CRUSH_REQUEST,
  PROFILE_SET_CRUSH_SUCCESS,
  PROFILE_SET_CRUSH_ERROR,
} from "../../constType";

import { calcAge } from "helpers";

import {
  APIEye3AddMyCrushMoyCrush,
  APIEye3GetMutualTool,
  APIEye3GetTrackActive,
  APIEye3RemoveMyCrushMoyCrush,
  APIEye3SetEnableTrackActive,
  APIEye3SetNotificationTrackActive,
} from "api/eye3";

import { APIVkGetByIdGroups } from "api/vk/apiMethods";
import { checkLicenseEYE3Api, checkLimitEYE3Api, delayMethodGetUsersVKApi } from "api";
import {
  MODAL_CARD_TEMPLATE_TARIFF_LIMIT,
  MODAL_CARD_TEMPLATE_TARIFF_WORK,
} from "template/modals/constModals";
import { openModal } from "../page";
import { defaultErrorProperty } from "api/eye3/responseHandler";

async function getMutualFriendsAndUser(profileId, friendsIds, trackActive) {
  try {
    let userIds = [];

    if (friendsIds.length === 0) {
      userIds.push(profileId);
    } else {
      userIds.push(profileId);
      userIds.push(...friendsIds);
    }

    const usersInfo = await delayMethodGetUsersVKApi({
      userIds: userIds,
      fields: ["photo_100", "bdate", "city", "country", "sex"],
    }); // Получить информацию о пользователе и общих друзьях

    const userItems = usersInfo;
    let isCurrentUser = false;

    let userData = userItems.map((user) => {
      let bdate = user.bdate;

      if (!isCurrentUser) {
        bdate = user.bdate ? user.bdate : trackActive.bdate;
        isCurrentUser = true;
      }

      return {
        vk_id: user.id,
        name: user.first_name + " " + user.last_name,
        age: bdate ? calcAge(bdate) : null, // Расчет возраста по дате рождения
        sex: user.sex ? user.sex.toString() : null,
        city: user.city ? user.city.title : null, // Город
        city_id: user.city ? user.city.id.toString() : null, // Город id
        photo_url: user.photo_100 ? user.photo_100 : "", // Фото
        country: user.country ? user.country.title : null, // Страна
        country_id: user.country ? user.country.id.toString() : null, // Страна id
      };
    });

    const currentUser = userData[0]; // Сохраняем данные пользователя из общего массива
    userData.shift(); // Удаляем данные пользователя
    const mutualFriendsList = userData; // Сохраняем общих друзей пользователя

    return { user: currentUser, friends: mutualFriendsList };
  } catch (e) {
    throw new Error("getMutualFriendsAndUser::: " + e);
  }
}

async function getMutualGroups(groupIds) {
  try {
    if (groupIds.length === 0) return [];

    const groupsInfo = await APIVkGetByIdGroups({
      groupIds: groupIds.join(","),
    });

    const groupsItems = groupsInfo.response.hasOwnProperty("items")
      ? groupsInfo.response.items
      : groupsInfo.response;

    let groupsData = groupsItems.map((group) => {
      return {
        vk_id: group.id,
        name: group.name,
        photo_url: group.photo_100,
      };
    });

    return groupsData;
  } catch (e) {
    throw new Error("getMutualGroups::: " + e);
  }
}

export function setTracking(isEnable, options = null) {
  return async (dispatch, getState) => {
    try {
      dispatch(request());

      const state = getState().profile;
      const tracking = await APIEye3SetEnableTrackActive({
        userId: state.activeProfileId,
        enable: +isEnable,
        settings: options,
      });

      //console.log(tracking);

      if (!checkLicenseEYE3Api(tracking)) {
        dispatch(openModal({ id: MODAL_CARD_TEMPLATE_TARIFF_WORK }));
        return;
      } else if (!checkLimitEYE3Api(tracking)) {
        dispatch(openModal({ id: MODAL_CARD_TEMPLATE_TARIFF_LIMIT }));
        return;
      }

      dispatch(success(tracking));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: PROFILE_SET_TRACKING_REQUEST };
  }
  function success(tracking) {
    return { type: PROFILE_SET_TRACKING_SUCCESS, tracking };
  }
  function error(error) {
    return { type: PROFILE_SET_TRACKING_ERROR, error };
  }
}

export function setNotification(isEnable) {
  return async (dispatch, getState) => {
    try {
      dispatch(request());
      const state = getState().profile;
      await APIEye3SetNotificationTrackActive({
        userId: state.activeProfileId,
        isNotification: +isEnable,
      });
      dispatch(success(isEnable));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: PROFILE_SET_NOTIFICATION_REQUEST };
  }
  function success(isEnable) {
    return { type: PROFILE_SET_NOTIFICATION_SUCCESS, isEnable };
  }
  function error(error) {
    return { type: PROFILE_SET_NOTIFICATION_ERROR, error };
  }
}

export function setCrush() {
  return async (dispatch, getState) => {
    try {
      dispatch(request());
      const isCrush = getState().profile.payload.track_active.is_crush;

      if (!isCrush) {
        const res = await APIEye3AddMyCrushMoyCrush({
          userIds: getState().profile.activeProfileId,
        });

        if (res.status) {
          dispatch(success(true));
          return { status: true };
        } else if (res.hasOwnProperty("users_limit") && res.users_limit === false) {
          return res;
        }
      } else {
        const res = await APIEye3RemoveMyCrushMoyCrush({
          userIds: getState().profile.activeProfileId,
        });

        if (res.status) {
          dispatch(success(false));
          return { status: true };
        }
      }
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: PROFILE_SET_CRUSH_REQUEST };
  }
  function success(isCrush) {
    return { type: PROFILE_SET_CRUSH_SUCCESS, isCrush };
  }
  function error(error) {
    return { type: PROFILE_SET_CRUSH_ERROR, error };
  }
}

export function fetchProfile() {
  return async (dispatch, getState) => {
    try {
      const state = getState().profile;
      const page = getState().page;
      const pageParam = getState().page.param;
      const id =
        pageParam !== null ? pageParam.activeProfileId : state.history[page.activeStory].user.id;

      if (id === state.activeProfileId) {
        dispatch(fetchTrackActive(id));
        return;
      }

      dispatch(request(id));

      const mutualFriendsGroupsIds = await APIEye3GetMutualTool({ userId: id });

      const trackActive = await APIEye3GetTrackActive({ userId: id }); // Обращение к API
      const mutualFriendsAndUser = await getMutualFriendsAndUser(
        id,
        mutualFriendsGroupsIds.friends,
        trackActive
      );
      const mutualGroups = await getMutualGroups(mutualFriendsGroupsIds.groups);

      const assignProfile = Object.assign(
        // Выводим все полученные данные в общий объект
        { user: mutualFriendsAndUser.user },
        { mutual_friends: mutualFriendsAndUser.friends },
        { mutual_groups: mutualGroups },
        { track_active: trackActive ? { ...trackActive } : null }
      );

      let history = state.history;

      if (
        !state.history.hasOwnProperty(page.activeStory) ||
        (state.history.hasOwnProperty(page.activeStory) && state.history[page.activeStory] !== id)
      ) {
        history = {
          ...history,
          [page.activeStory]: {
            user: {
              id: id,
              name: mutualFriendsAndUser.user.name,
              photo_url: mutualFriendsAndUser.user.photo_url,
              age: mutualFriendsAndUser.user.age,
              city: mutualFriendsAndUser.user.city,
              sex: mutualFriendsAndUser.user.sex,
            },
          },
        };
      }

      dispatch(success(assignProfile, history));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(id) {
    return { type: PROFILE_REQUEST, id };
  }

  function success(payload, history) {
    return { type: PROFILE_SUCCESS, payload, history };
  }

  function error(error) {
    return { type: PROFILE_ERROR, error };
  }
}

function fetchTrackActive(id) {
  return async (dispatch, getState) => {
    try {
      dispatch(request());
      const trackActive = await APIEye3GetTrackActive({ userId: id }); // Обращение к API

      if (trackActive.status) {
        dispatch(success(trackActive));
      }
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: PROFILE_TRACK_ACTIVE_REQUEST };
  }

  function success(payload) {
    return { type: PROFILE_TRACK_ACTIVE_SUCCESS, payload };
  }

  function error(error) {
    return { type: PROFILE_TRACK_ACTIVE_ERROR, error };
  }
}
