import React from "react";
import { Avatar, SimpleCell } from "@vkontakte/vkui";
import Icon16Chevron from "@vkontakte/icons/dist/16/chevron";

import { VIEW_TOOL } from "../../../../../constViews";

import { PANEL_FIA__INFO } from "../../../constPanels";

const FIAUserItem = (props) => {
  return (
    <SimpleCell
      before={<Avatar size={48} src={props.photo} mode="app" />}
      onClick={() =>
        props.openView({
          view: VIEW_TOOL,
          panel: PANEL_FIA__INFO,
          param: {
            activeAppId: props.id,
          },
        })
      }
      description={props.description}
      after={<Icon16Chevron fill="#B8C1CC" />}
    >
      {props.title}
    </SimpleCell>
  );
};

export default FIAUserItem;
