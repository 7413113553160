import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Group, Search, Tabs, TabsItem, Placeholder } from "@vkontakte/vkui";
import AUserInfoItem from "../AUserInfoItem/AUserInfoItem";
import Icon24Filter from "@vkontakte/icons/dist/24/filter";
import SeparateFilter from "template/components/SeparateFilter/SeparateFilter";
import Icon56LikeOutline from "@vkontakte/icons/dist/56/like_outline";

import { unixTimeToDate, convertMonthDay, convertTime } from "helpers";
import AUserInfoTopPanel from "../AUserInfoTopPanel/AUserInfoTopPanel";
import { renderPeriodV2 } from "template/popouts/DateChangePeriod/views";
import PopoutTemplateDateChangePeriod from "template/popouts/DateChangePeriod/DateChangePeriod";

import {
  fetchUserInfo,
  filterUserInfo,
  searchUserInfo,
  cancelUserInfo,
  setPeriodUserInfo,
  tabsUserInfo,
} from "store/actions/tool/activity";
import { PANEL_A__USER_INFO } from "views/tool/panels/constPanels";
import { TABS_ALL, TABS_COMMENT, TABS_LIKE } from "../../../constTabs";
import { VIEW_TOOL } from "views/constViews";
import { MODAL_PAGE_TEMPLATE_FILTER_SEX_AGE_CITY } from "template/modals/constModals";

import LoaderWithProgressBar from "template/components/LoaderWithProgressBar/LoaderWithProgressBar";
import { renderErrorPlaceholder } from "helpers/views";
import { useDebounceSearch } from "hooks";

const AUserInfoList = (props) => {
  const dispatch = useDispatch();
  const activity = useSelector((state) => state.toolActivity.userInfo.payload);
  const progressValue = useSelector((state) => state.toolActivity.userInfo.progressValue);
  const tabs = useSelector((state) => state.toolActivity.userInfo.tabs);
  const filter = useSelector((state) => state.toolActivity.userInfo.filter);
  const search = useSelector((state) => state.toolActivity.userInfo.search);
  const period = useSelector((state) => state.toolActivity.userInfo.period);
  const error = useSelector((state) => state.toolActivity.userInfo.error);
  const loading = useSelector((state) => state.toolActivity.userInfo.loading);
  const activeUserId = useSelector((state) => state.toolActivity.userInfo.activeUserId);
  const activeUserTitle = useSelector((state) => state.toolActivity.userInfo.activeUserTitle);
  const activeUserDescription = useSelector(
    (state) => state.toolActivity.userInfo.activeUserDescription
  );
  const activeUserPhoto = useSelector((state) => state.toolActivity.userInfo.activeUserPhoto);

  const [searchTerm, setSearchTerm] = useState(search);

  const handleFetchUserInfo = useCallback(() => dispatch(fetchUserInfo()), [dispatch]);
  const handleCancelUserInfo = useCallback(() => dispatch(cancelUserInfo()), [dispatch]);
  const handleSetPeriodUserInfo = useCallback(
    (name, atMin = null, atMax = null) => dispatch(setPeriodUserInfo(name, atMin, atMax)),
    [dispatch]
  );
  const handleTabsUserInfo = useCallback((tabs) => dispatch(tabsUserInfo(tabs)), [dispatch]);
  const handleFilterUserInfo = useCallback(
    (filter) => dispatch(filterUserInfo(filter)),
    [dispatch]
  );
  const handleSearchUserInfo = useCallback(
    (search) => dispatch(searchUserInfo(search)),
    [dispatch]
  );

  let lastAt = new Date();

  useEffect(() => {
    return () => {
      handleCancelUserInfo();
    };
  }, [handleCancelUserInfo]);

  useEffect(() => {
    handleFetchUserInfo();
  }, [handleFetchUserInfo, period]);

  useDebounceSearch({
    search: search,
    searchTerm: searchTerm,
    handler: handleSearchUserInfo,
  });

  const setTabsActivity = (tabs) => {
    if (!loading) {
      handleTabsUserInfo(tabs);
      handleFetchUserInfo();
    }
  };

  const renderUser = () => {
    return (
      <AUserInfoTopPanel
        id={activeUserId}
        title={activeUserTitle}
        description={activeUserDescription}
        photo={activeUserPhoto}
        openView={props.openView}
      />
    );
  };

  const renderActivity = () => {
    return activity.map((a, i) => {
      const id = a.vk_id;
      const at = unixTimeToDate(a._at);
      const type = a.type;
      const infoText = a.info_text;
      const infoPhoto = a.info_photo;
      const infoLink = a.info_link;
      const ownerId = a.owner_id;
      const ownerName = a.owner_name;
      const ownerPhotoUrl = a.owner_photo_url;
      const sex = a.sex;

      let separate = <SeparateFilter>{convertMonthDay(at)}</SeparateFilter>;

      if (lastAt.getDate() === at.getDate() && i !== 0) {
        separate = "";
      }

      lastAt = at;

      return (
        <div key={i}>
          {separate}
          <AUserInfoItem
            id={id}
            sex={sex}
            ownerId={ownerId}
            ownerName={ownerName}
            ownerPhotoUrl={ownerPhotoUrl}
            time={convertTime(at)}
            type={type}
            infoText={infoText}
            infoPhoto={infoPhoto}
            infoLink={infoLink}
          />
        </div>
      );
    });
  };

  const handleFilter = () => {
    if (loading) return;

    props.openModal({
      id: MODAL_PAGE_TEMPLATE_FILTER_SEX_AGE_CITY,
      param: {
        filter: filter,
        closeModal: props.closeModal,
        applyFilter: handleFilterUserInfo,
      },
    });
  };

  const handlePeriod = () => {
    if (loading) return false;

    props.openPopout({
      popout: (
        <PopoutTemplateDateChangePeriod
          openView={props.openView}
          period={period} // Период из redux store
          setPeriod={handleSetPeriodUserInfo} // action из redux
          backView={VIEW_TOOL}
          backPanel={PANEL_A__USER_INFO}
          panelTitle={"Активности"}
          closePopout={props.closePopout}
        />
      ),
    });
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      {renderPeriodV2({ title: "Активности пользователя", period: period, handler: handlePeriod })}
      {renderUser()}
      {error === null && (
        <Search
          value={searchTerm}
          icon={<Icon24Filter />}
          onIconClick={handleFilter}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      )}
      {error === null && (
        <Tabs>
          <TabsItem
            disabled={loading}
            onClick={() => {
              if (tabs !== TABS_ALL) setTabsActivity(TABS_ALL);
            }}
            selected={tabs === TABS_ALL}
          >
            Все
          </TabsItem>
          <TabsItem
            disabled={loading}
            onClick={() => {
              if (tabs !== TABS_LIKE) setTabsActivity(TABS_LIKE);
            }}
            selected={tabs === TABS_LIKE}
          >
            Лайки
          </TabsItem>
          <TabsItem
            disabled={loading}
            onClick={() => {
              if (tabs !== TABS_COMMENT) setTabsActivity(TABS_COMMENT);
            }}
            selected={tabs === TABS_COMMENT}
          >
            Комментарии
          </TabsItem>
        </Tabs>
      )}
      <Group>
        {loading ? <LoaderWithProgressBar progressValue={progressValue} /> : ""}
        {activity !== null && error === null ? renderActivity() : ""}
        {!loading &&
        activity !== null &&
        Object.prototype.toString.call(activity) === "[object Array]" &&
        error === null &&
        activity.length <= 0 ? (
          <Placeholder icon={<Icon56LikeOutline />}>Активности не найдены</Placeholder>
        ) : (
          ""
        )}
      </Group>
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchUserInfo,
      })}
    </div>
  );
};

export default AUserInfoList;
