import React from "react";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { Div, Link, Panel } from "@vkontakte/vkui";
import { withRouter } from "hoc";

const PrivacyPolicy = (props) => {
  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Политика конфиденциальности</PanelHeaderWithPath>
      <Div>
        <p style={{ marginTop: "0px", textAlign: "center", fontSize: "18px" }}>
          Политика конфиденциальности
        </p>
        <p style={{ fontSize: "18px" }}>1. Основные понятия</p>
        <p>
          <b>1.1. Сайт</b> – сайт, расположенный в сети Интернет по адресу{" "}
          <Link href={"https://vk.com"} target="_blank">
            http://vk.com/
          </Link>
          .
        </p>
        <p>
          <b>1.2. Разработчик</b> – физическое или юридическое лицо, размещающее приложение на Сайте
          и использующее его в соответствии с Условиями размещения приложений и Правилами размещения
          приложений на Сайте. Фамилия, имя и отчество либо наименование Разработчика, а также иная
          информация о нем указаны в разделе «Информация о разработчике» в окне запуска Приложения и
          в настройках Приложения.
        </p>
        <p>
          <b>1.3. Администрация Сайта</b> – общество с ограниченной ответственностью «В Контакте»,
          расположенное по адресу: 191024, Санкт-Петербург, ул. Херсонская, д. 12-14, литер А,
          помещение 1-Н, ОГРН 1079847035179, ИНН 7842349892.
        </p>
        <p>
          <b>1.4. Приложение</b> – программный сервис, размещенный Разработчиком на Сайте в разделе
          «Приложения», включенный в каталог приложений.
        </p>
        <p>
          <b>1.5. Пользователь</b> – Пользователь Сайта, зарегистрированный в установленном порядке
          и использующий Приложение.
        </p>
        <p>
          <b>1.6. Политика</b> – настоящая Типовая политика конфиденциальности разработчиков
          приложений для Сайта.
        </p>
        <p style={{ fontSize: "18px" }}>2. Общие положения</p>
        <p>
          <b>2.1. </b>
          Настоящая Политика является официальным типовым документом Разработчиков, и определяет
          порядок обработки и защиты информации о физических лицах, использующих Приложения.
          Настоящая Политика не распространяет свое действие на Разработчиков, которые опубликовали
          и применяют свой собственный документ, определяющий политику в отношении обработки
          персональных данных Пользователей.
        </p>
        <p>
          <b>2.2. </b>
          Целью настоящей Политики является обеспечение надлежащей защиты информации о
          Пользователях, в том числе их персональных данных, от несанкционированного доступа и
          разглашения.
        </p>
        <p>
          <b>2.3. </b>
          Отношения, связанные со сбором, хранением, распространением и защитой информации о
          Пользователях, регулируются настоящей Политикой, иными официальными документами
          Разработчиков и действующим российским законодательством.
        </p>
        <p>
          <b>2.4. </b>
          Действующая редакция Политики, являющейся публичным документом, разработана Администрацией
          Сайта и доступна любому Пользователю сети Интернет при переходе по гипертекстовой ссылке
          «Политика конфиденциальности разработчика приложения».
        </p>
        <p>
          Администрация Сайта вправе вносить изменения в настоящую Политику. При внесении изменений
          в Политику Администрация Сайта размещает новую редакцию Политики на Сайте по постоянному
          адресу{" "}
          <Link href={"https://vk.com/dev/uprivacy"} target="_blank">
            http://vk.com/dev/uprivacy
          </Link>
          .
        </p>
        <p>
          Администрация Сайта рекомендует Пользователям регулярно проверять условия настоящих Правил
          на предмет их изменения и/или дополнения. Продолжение использования Приложений
          Пользователем после внесения изменений и/или дополнений в настоящие Правила означает
          принятие и согласие Пользователя с такими изменениями и/или дополнениями.
        </p>
        <p>
          <b>2.5. </b>
          Настоящая Политика разработана и используется в соответствии с Правилами пользования
          сайтом ВКонтакте, размещенными на Сайте по адресу{" "}
          <Link href={"https://vk.com/terms"} target="_blank">
            http://vk.com/terms
          </Link>
          , а также Правилами защиты информации о Пользователях сайта ВКонтакте, размещенными на
          Сайте по адресу
          <Link href={"https://vk.com/privacy"} target="_blank">
            {" "}
            http://vk.com/privacy
          </Link>
          .
        </p>
        <p>
          <b>2.6. </b>
          Используя Приложения, Пользователь выражает свое согласие с условиями настоящей Политики.
        </p>
        <p>
          <b>2.7. </b>В случае несогласия Пользователя с условиями настоящей Политики использование
          Приложений должно быть немедленно прекращено.
        </p>
        <p style={{ fontSize: "18px" }}>3. Условия пользования Приложений</p>
        <p>
          <b>3.1. </b>
          Оказывая услуги по использованию Приложений, Разработчик, действуя разумно и
          добросовестно, считает, что Пользователь:
        </p>
        <p>
          – обладает всеми необходимыми правами, позволяющими ему осуществлять запуск и
          использование Приложений.
        </p>
        <p>
          – указывает достоверную информацию о себе в объемах, необходимых для пользования
          Приложений;
        </p>
        <p>
          – осознает, что информация, размещаемая в результате использования Пользователем
          Приложений, может становиться доступной для других Пользователей Приложений, может быть
          скопирована и распространена такими Пользователями;
        </p>
        <p>
          – осознает, что некоторые виды информации, переданные им другим Пользователям при
          использовании Приложений, не могут быть удалены самим Пользователем;
        </p>
        <p>
          – ознакомлен с настоящей Политикой, выражает свое согласие с ней и принимает на себя
          указанные в ней права и обязанности.
        </p>
        <p>
          <b>3.2. </b>
          Разработчик не проверяет достоверность получаемой (собираемой) информации о Пользователях.
        </p>
        <p style={{ fontSize: "18px" }}>4. Цели обработки информации</p>
        <p>
          Разработчик осуществляет обработку информации о Пользователях, в том числе их персональных
          данных, в целях выполнения обязательств Разработчика перед Пользователями в отношении
          использования Приложения.
        </p>
        <p style={{ fontSize: "18px" }}>5. Состав информации о Пользователях</p>
        <p>Разработчик обрабатывает информацию о Пользователях, которая включает в себя:</p>
        <p>
          <b>5.1. </b>
          Персональные данные Пользователей, предоставляемые Администрацией Сайта с согласия
          Пользователей и необходимые для использования Приложений: имя, фамилия, дата рождения,
          профильная фотография, список друзей, а также информация, размещаемая Пользователем на
          персональной странице (например, информация, указанная в поле «страна», «интересы»,
          «любимая музыка», «образование» и т.д.);
        </p>
        <p>
          <b>5.2. </b>
          Дополнительная информация о Пользователях, предоставляемая Администрацией Сайта с согласия
          Пользователей, если такая информация необходима для использования Приложения (в частности:
          фотографии, заметки, аудиозаписи, видеозаписи, предложения, вопросы, сообщества, в которых
          состоит Пользователь);
        </p>
        <p>
          <b>5.3. </b>
          Информация, предоставляемая Пользователями при использовании Приложений (например,
          никнэйм);
        </p>
        <p>
          <b>5.4. </b>
          Информация, которая может быть получена Разработчиком в результате действий Пользователей
          при использовании Приложений (IP-адреса, cookies, статистика использования приложения и
          т.д.).
        </p>
        <p style={{ fontSize: "18px" }}>6. Обработка персональных данных Пользователей</p>
        <p>
          <b>6.1. </b>
          Обработка персональных данных осуществляется на основе принципов:
        </p>
        <p>а) законности целей и способов обработки персональных данных и добросовестности;</p>
        <p>
          б) соответствия целей обработки персональных данных целям, заранее определенным и
          заявленным при сборе персональных данных, а также полномочиям Разработчиков;
        </p>
        <p>
          в) соответствия объема и характера обрабатываемых персональных данных, способов обработки
          персональных данных целям обработки персональных данных;
        </p>
        <p>
          г) недопустимости объединения созданных для несовместимых между собой целей баз данных,
          содержащих персональные данные.
        </p>
        <p>
          <b>6.1.1. </b>
          Условия и цели обработки персональных и иных данных
        </p>
        <p>
          Разработчик осуществляет обработку персональных и иных данных Пользователя с его согласия,
          предусмотренного п. 6.1.2 настоящей Политики, в целях оказания услуг Пользователю по
          использованию Приложений.
        </p>
        <p>
          <b>6.1.2. </b>
          Сбор персональных и иных данных
        </p>
        <p>
          Персональные данные Пользователя и информация, предусмотренные пунктами 5.1, 5.3 настоящей
          Политики, передаются Разработчику Администрацией Сайта с согласия Пользователя,
          предоставляемого в форме конклюдентного действия при первом запуске и/или использовании
          Приложения (в частности, при нажатии соответствующей кнопки (при ее наличии) при первом
          запуске и/или использовании Приложения).
        </p>
        <p>
          Дополнительная информация о Пользователе, предусмотренная пунктом 5.2 настоящей Политики,
          передаются Разработчику Администрацией Сайта с согласия Пользователя, предоставляемого в
          форме конклюдентного действия в виде нажатия на специальную кнопку «Разрешить»,
          появляющейся при первом запуске и/или использовании Приложения, использование которого
          предполагает получение доступа к информации, предусмотренной пунктом 5.2 настоящих Правил.
        </p>
        <p>
          Информация, предусмотренная п. 5.4 настоящей Политики, собираются Разработчиком без
          участия Администрацией Сайта с согласия Пользователя, предоставляемого в форме
          конклюдентного действия при первом запуске и/или использовании Пользователем Приложения (в
          частности, при нажатии соответствующей кнопки (при ее наличии) при первом запуске и/или
          использовании Приложения).
        </p>
        <p>
          <b>6.1.3. </b>
          Передача персональных данных
        </p>
        <p>
          Персональные данные Пользователей не передаются каким-либо третьим лицам, за исключением
          случаев, прямо предусмотренных настоящей Политикой.
        </p>
        <p>
          Предоставление персональных данных Пользователей по запросу государственных органов
          (органов местного самоуправления) осуществляется в порядке, предусмотренном
          законодательством.
        </p>
        <p>
          <b>6.2. </b>
          Поскольку Приложения являются одним из средств коммуникации и поддержанием связей со
          старыми и новыми знакомыми, следующая информация может быть доступна другим Пользователям
          данного Приложения:
        </p>
        <p>
          <b>6.2.1. </b>
          фамилия и имя Пользователя,
        </p>
        <p>
          <b>6.2.2. </b>
          профильная фотография Пользователя,
        </p>
        <p>
          <b>6.2.3. </b>
          информация об использовании Пользователем Приложения, а также о действиях Пользователя при
          использовании Приложения.
        </p>
        <p>
          Пользователям, имеющим на Сайте статус друзей конкретного Пользователя, может передаваться
          информация о факте использования таким Пользователем Приложения
        </p>
        <p>
          <b>6.3. </b>
          Хранение персональных и иных необходимых данных Пользователя осуществляется в течение
          срока использования Приложения, а после прекращения использования Приложения – в течение
          необходимого и установленного действующим законодательством Российской Федерации срока.
        </p>
        <p style={{ fontSize: "18px" }}>7. Права и обязанности Пользователей</p>
        <p>
          <b>7.1. </b>
          Пользователи вправе:
        </p>
        <p>
          <b>7.1.1. </b>
          осуществлять свободный бесплатный доступ к информации о себе посредством запуска
          используемых ими Приложений на Сайте;
        </p>
        <p>
          <b>7.1.2. </b>
          на основании запроса получать от Разработчика информацию, касающуюся обработки его
          персональных данных.
        </p>
        <p style={{ fontSize: "18px" }}>8. Меры по защите информации о Пользователях</p>
        <p>
          Разработчик принимает технические и организационно-правовые меры в целях обеспечения
          защиты персональных данных Пользователя от неправомерного или случайного доступа к ним,
          уничтожения, изменения, блокирования, копирования, распространения, а также от иных
          неправомерных действий. Описание соответствующих технических и организационно-правовых мер
          содержится в локальных нормативных актах Разработчика.
        </p>
        <p style={{ fontSize: "18px" }}>9. Ограничение действия Политики</p>
        <p>
          Действие настоящей Политики не распространяется на действия и интернет-ресурсы третьих
          лиц.
        </p>
        <p>
          Разработчик не несет ответственности за действия третьих лиц, получивших в результате
          использования Приложения доступ к информации о Пользователе, которая, в силу природы
          Сайта, доступна любому Пользователю сети Интернет. Разработчик рекомендует Пользователям
          ответственно подходить к решению вопроса об объеме информации о себе, размещаемой на
          Сайте.
        </p>
        <p style={{ fontSize: "18px" }}>10. Обращения Пользователей</p>
        <p>
          <b>10.1. </b>
          Пользователи вправе направлять Разработчику свои запросы, в том числе запросы относительно
          использования их персональных данных, предусмотренные п. 7.1.2 настоящей Политики, в
          письменной форме по адресу, указанному в разделе «Настройки» Приложения, или в форме
          электронного документа, подписанного квалифицированной электронной подписью в соответствии
          с законодательством Российской Федерации, по адресу электронной почты, указанному в
          разделе «Настройки» Приложения.
        </p>
        <p>
          <b>10.2. </b>
          Запрос, направляемый Пользователем, должен содержать следующую информацию:
        </p>
        <p>
          – номер основного документа, удостоверяющего личность Пользователя или его представителя;
        </p>
        <p>– сведения о дате выдачи указанного документа и выдавшем его органе;</p>
        <p>
          – сведения, подтверждающие участие Пользователя в отношениях с Разработчиком (в частности,
          сведения о загрузке приложения на персональную страницу, никнейм, используемый
          Пользователем при использовании Приложения);
        </p>
        <p>– подпись Пользователя или его представителя.</p>
        <p>
          <b>10.3. </b>
          Разработчик обязуется рассмотреть и направить ответ на поступивший запрос Пользователя в
          течение 30 дней с момента поступления обращения.
        </p>
        <p>
          <b>10.4. </b>
          Вся корреспонденция, полученная Разработчиком от Пользователей (обращения в письменной или
          электронной форме), относится к информации ограниченного доступа и не разглашается без
          письменного согласия Пользователя. Персональные данные и иная информация о Пользователе,
          направившем запрос, не могут быть без специального согласия Пользователя использованы
          иначе, как для ответа по теме полученного запроса или в случаях, прямо предусмотренных
          законодательством.
        </p>
      </Div>
    </Panel>
  );
};

export default withRouter(PrivacyPolicy);
