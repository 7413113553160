import React from "react";
import { Avatar, SimpleCell } from "@vkontakte/vkui";
import Icon16Chevron from "@vkontakte/icons/dist/16/chevron";

import { PANEL_JC_INFO } from "views/tool/panels/constPanels";
import { VIEW_TOOL } from "views/constViews";

const JCItem = (props) => {
  return (
    <SimpleCell
      before={<Avatar size={48} src={props.photo} />}
      after={<Icon16Chevron fill="#B8C1CC" />}
      description={props.description}
      onClick={() =>
        props.openView({
          view: VIEW_TOOL,
          panel: PANEL_JC_INFO,
          param: {
            activeUserId: props.id,
            activeUserPhoto: props.photo,
            activeUserTitle: props.title,
            activeUserDescription: props.description,
            activeUserSex: props.sex,
          },
        })
      }
    >
      {props.title}
    </SimpleCell>
  );
};

export default JCItem;
