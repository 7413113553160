import { Group, Panel, Tabs, TabsItem } from "@vkontakte/vkui";
import LoaderWithProgressBar from "template/components/LoaderWithProgressBar/LoaderWithProgressBar";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";
import { renderErrorPlaceholder } from "helpers/views";
import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchInfoStatistics,
  tabsInfoStatistics,
  setPeriodInfoStatistics,
} from "store/actions/tool/historyStatistics";
import PopoutTemplateDateChangePeriod from "template/popouts/DateChangePeriod/DateChangePeriod";
import { renderPeriodV2 } from "template/popouts/DateChangePeriod/views";
import { VIEW_TOOL } from "views/constViews";
import { PANEL_HS__INFO } from "../../constPanels";
import { TABS_HISTORY, TABS_SPECTATORS } from "../constTabs";
import SpectatorTab from "./tabs/SpectatorTab/SpectatorTab";
import StatsTab from "./tabs/StatsTab/StatsTab";
import { withRouter } from "hoc";

const PanelHSInfo = (props) => {
  const dispatch = useDispatch();
  const tabs = useSelector((state) => state.toolHistoryStatistics.stats.tabs);
  const isOnce = useSelector((state) => state.toolHistoryStatistics.stats.isOnce);
  const error = useSelector((state) => state.toolHistoryStatistics.stats.error);
  const period = useSelector((state) => state.toolHistoryStatistics.stats.period);
  const progressValue = useSelector((state) => state.toolHistoryStatistics.stats.progressValue);
  const loading = useSelector((state) => state.toolHistoryStatistics.stats.loading);
  const handleFetchInfoStatistics = useCallback(() => dispatch(fetchInfoStatistics()), [dispatch]);
  const handleTabsInfoStatistics = useCallback(
    (tabs) => dispatch(tabsInfoStatistics(tabs)),
    [dispatch]
  );
  const handleSetPeriodInfoStatistics = useCallback(
    (name, atMin = null, atMax = null) => dispatch(setPeriodInfoStatistics(name, atMin, atMax)),
    [dispatch]
  );

  useEffect(() => {
    if (!isOnce) handleFetchInfoStatistics();
  }, [handleFetchInfoStatistics, isOnce]);

  const handlePeriod = () => {
    if (loading) return false;

    props.openPopout({
      popout: (
        <PopoutTemplateDateChangePeriod
          openView={props.openView}
          period={period} // Период из redux store
          setPeriod={handleSetPeriodInfoStatistics} // action из redux
          backPanel={PANEL_HS__INFO}
          backView={VIEW_TOOL}
          panelTitle={"Статистика историй"}
          closePopout={props.closePopout}
        />
      ),
    });
  };

  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Статистика историй</PanelHeaderWithPath>
      {renderPeriodV2({ title: "Статистика историй", period: period, handler: handlePeriod })}
      <Group separator="hide">
        <Tabs>
          <TabsItem
            onClick={() => {
              if (tabs !== TABS_HISTORY) handleTabsInfoStatistics(TABS_HISTORY);
            }}
            selected={tabs === TABS_HISTORY}
          >
            Истории
          </TabsItem>
          <TabsItem
            onClick={() => {
              if (tabs !== TABS_SPECTATORS) handleTabsInfoStatistics(TABS_SPECTATORS);
            }}
            selected={tabs === TABS_SPECTATORS}
          >
            Зрители
          </TabsItem>
        </Tabs>
      </Group>
      {loading && <LoaderWithProgressBar progressValue={progressValue} />}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchInfoStatistics,
      })}
      {tabs === TABS_HISTORY && !loading && error === null ? (
        <StatsTab openView={props.openView} />
      ) : (
        ""
      )}
      {tabs === TABS_SPECTATORS && !loading && error === null ? (
        <SpectatorTab openView={props.openView} />
      ) : (
        ""
      )}
    </Panel>
  );
};

export default withRouter(PanelHSInfo);
