import {
  PROFILE_FRIEND_HISTORY_REQUEST,
  PROFILE_FRIEND_HISTORY_SUCCESS,
  PROFILE_FRIEND_HISTORY_ERROR,
  PROFILE_FRIEND_HISTORY_SHOW_MORE,
} from "../../constType";

import { APIEye3FriendsHistoryTrackActive } from "api/eye3";
import { openModal } from "../page";
import { delayMethodGetUsersVKApi } from "api";
import { checkEYE3ApiError, defaultErrorProperty } from "api/eye3/responseHandler";

export function fetchFriendHistory() {
  return async (dispatch, getState) => {
    try {
      const state = getState().profileFriendHistory;
      const page = getState().page;
      const profile = getState().profile;

      const id = profile.history[page.activeStory].user.id;

      if (state.activeUserId === profile.history[page.activeStory].user.id) {
        // Если предыдущий открытый профиль равен текущему открытому профилю
        return false;
      }

      dispatch(request(id));

      let result = [];

      const friendHistory = await APIEye3FriendsHistoryTrackActive({
        userId: id,
      });

      //console.log('friendHistory: ', friendHistory);

      // Проверка ошибок в API
      const errorAPI = checkEYE3ApiError(friendHistory);
      if (errorAPI !== false) {
        errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
        dispatch(error({ ...errorAPI }));
        return;
      }

      if (
        friendHistory.hasOwnProperty("is_track_active") &&
        friendHistory.is_track_active === false
      ) {
        dispatch(
          error({
            ...defaultErrorProperty,
            message: "Для получения данных необходимо поставить пользователя на слежение",
            isTryAgain: false,
            isVisibleMessage: true,
          })
        );

        return;
      }

      const changes = friendHistory.changes;
      const friendIds = changes.map((friend) => friend.vk_id);
      //console.log('friendIds: ', friendIds);

      let userInfo = await delayMethodGetUsersVKApi({
        userIds: friendIds,
        fields: ["photo_100"],
      });

      //userInfo = userInfo.response.hasOwnProperty('items') ? userInfo.response.items : userInfo.response;

      //console.log('userInfo: ', userInfo);

      changes.forEach((change) => {
        userInfo.forEach((user) => {
          if (change.vk_id === user.id) {
            result.push({
              vk_id: user.id,
              name: user.first_name + " " + user.last_name,
              photo_url: user.photo_100,
              status: change.status,
              friend_type: change.friend_type,
              _at: change._at,
            });
          }
        });
      });

      //console.log('result: ', result);

      dispatch(success(result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e, isTryAgain: false }));
    }
  };

  function request(id) {
    return { type: PROFILE_FRIEND_HISTORY_REQUEST, id };
  }

  function success(payload) {
    return { type: PROFILE_FRIEND_HISTORY_SUCCESS, payload };
  }

  function error(error) {
    return { type: PROFILE_FRIEND_HISTORY_ERROR, error };
  }
}

export function showMoreFriendHistory() {
  return { type: PROFILE_FRIEND_HISTORY_SHOW_MORE };
}
