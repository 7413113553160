import React from "react";
import styles from "./TopItem.module.css";
import {
  Icon16View,
  Icon16Like,
  Icon24Message,
  Icon28StoryAddOutline,
  Icon16Share,
} from "@vkontakte/icons";
import { Link } from "@vkontakte/vkui";

const TopItem = (props) => {
  const getTypeIcon = () => {
    switch (props.type) {
      case "views":
        return <Icon16View fill="var(--text_secondary)" width={14} height={14} />;
      case "likes":
        return <Icon16Like fill="var(--text_secondary)" width={14} height={14} />;
      case "answers":
        return <Icon24Message fill="var(--text_secondary)" width={14} height={14} />;
      case "replies":
        return <Icon28StoryAddOutline fill="var(--text_secondary)" width={14} height={14} />;
      case "shares":
      default:
        return <Icon16Share fill="var(--text_secondary)" width={14} height={14} />;
    }
  };

  return (
    <div className={styles.topItemCnt}>
      <Link href={`https://vk.com/feed?w=story${props.userId}_${props.storyId}`} target="_blank">
        <img src={props.photo} width={79} height={130} alt=""></img>
      </Link>
      <div className={styles.topItemType}>
        {getTypeIcon()}
        <span style={{ marginLeft: "2px" }}>{props.title}</span>
      </div>
    </div>
  );
};

TopItem.defaultProps = {
  title: "",
  type: "",
  photo: null,
  storyId: null,
  userId: null,
};

export default TopItem;
