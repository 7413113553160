import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Placeholder, SimpleCell, Avatar } from "@vkontakte/vkui";
import SeparateFilter from "template/components/SeparateFilter/SeparateFilter";
import MaritalItem from "../MaritalItem/MaritalItem";
import Icon56UsersOutline from "@vkontakte/icons/dist/56/users_outline";

import { unixTimeToDate, convertMonthDay, convertTime } from "helpers";

import { fetchMaritalStatus, showMoreMaritalStatus } from "store/actions/profile/maritalStatus";
import { declOfNum } from "helpers";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import { useShowMore } from "hooks";
import HeadTitle from "template/components/HeadTitle/HeadTitle";

const MaritalList = () => {
  const dispatch = useDispatch();
  const isTransitionEnd = useSelector((state) => state.page.isTransitionEnd);
  const activeStory = useSelector((state) => state.page.activeStory);
  const profileHistory = useSelector((state) => state.profile.history);

  const loading = useSelector((state) => state.profileMaritalStatus.loading);
  const error = useSelector((state) => state.profileMaritalStatus.error);
  const maritalStatus = useSelector((state) => state.profileMaritalStatus.payload);
  const showCount = useSelector((state) => state.profileMaritalStatus.showCount);

  const handleFetchMaritalStatus = useCallback(() => dispatch(fetchMaritalStatus()), [dispatch]);
  const handleShowMoreMaritalStatus = useCallback(
    () => dispatch(showMoreMaritalStatus()),
    [dispatch]
  );

  let lastAt = new Date();

  useEffect(() => {
    handleFetchMaritalStatus();
  }, [handleFetchMaritalStatus]);

  useShowMore({
    items: maritalStatus,
    showCount: showCount,
    maxLength: maritalStatus !== null ? maritalStatus.length : 0,
    isTransitionEnd: isTransitionEnd,
    handler: handleShowMoreMaritalStatus,
  });

  const renderTopPanel = () => {
    const user = profileHistory[activeStory].user;
    const name = user.name;
    const city = user.city ? user.city : "";
    const age = user.age ? user.age : "";
    const photo = user.photo_url ? user.photo_url : "";
    const ageStrFormat = age ? age + " " + declOfNum(age, ["год", "года", "лет"]) : "";
    const comma = ageStrFormat && city ? ", " : "";

    return (
      <SimpleCell
        disabled
        before={<Avatar size={72} src={photo} />}
        description={ageStrFormat + comma + city}
      >
        {name}
      </SimpleCell>
    );
  };

  const renderMaritalStatus = () => {
    return maritalStatus.length > 0 ? (
      maritalStatus.map((status, i) => {
        const relation = status.hasOwnProperty("relation") ? status.relation : "";
        const partnerId = status.hasOwnProperty("relation_partner") ? status.relation_partner : "";
        const partnerName = status.hasOwnProperty("relation_partner") ? status.name : "";
        const partnerPhoto = status.hasOwnProperty("relation_partner") ? status.photo_url : "";
        const at = unixTimeToDate(status._at);
        const sex = profileHistory[activeStory].user.sex;

        let separate = <SeparateFilter>{convertMonthDay(at)}</SeparateFilter>;

        if (lastAt.getDate() === at.getDate() && i !== 0) {
          separate = "";
        }

        lastAt = at;

        if (i < showCount) {
          return (
            <div key={i}>
              {separate}
              <MaritalItem
                key={i}
                partnerId={partnerId}
                relation={relation}
                partnerName={partnerName}
                partnerPhoto={partnerPhoto}
                time={convertTime(at)}
                sex={sex}
              />
            </div>
          );
        }

        return false;
      })
    ) : (
      <Placeholder icon={<Icon56UsersOutline />}>Смены семейного положения не найдены</Placeholder>
    );
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      <HeadTitle>Семейное положение</HeadTitle>
      {renderTopPanel()}
      {maritalStatus !== null && error === null ? renderMaritalStatus() : ""}
      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchMaritalStatus,
      })}
    </div>
  );
};

export default MaritalList;
