import { Group, Placeholder } from "@vkontakte/vkui";
import { renderErrorPlaceholder, renderLoader } from "helpers/views";
import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchInfoPair } from "store/actions/tool/pair";

import PInfoTopPanel from "../PInfoTopPanel/PInfoTopPanel";
import PInfoItem from "../PInfoItem/PInfoItem";
import Icon56Users3Outline from "@vkontakte/icons/dist/56/users_3_outline";
import HeadTitle from "template/components/HeadTitle/HeadTitle";

const PInfoList = (props) => {
  const dispatch = useDispatch();
  const activeUserId = useSelector((state) => state.toolPair.pairs.activeUserId);
  const activeUserTitle = useSelector((state) => state.toolPair.pairs.activeUserTitle);
  const activeUserDescription = useSelector((state) => state.toolPair.pairs.activeUserDescription);
  const activeUserPhoto = useSelector((state) => state.toolPair.pairs.activeUserPhoto);
  const activeUserSex = useSelector((state) => state.toolPair.pairs.activeUserSex);
  const loading = useSelector((state) => state.toolPair.pairs.loading);
  const error = useSelector((state) => state.toolPair.pairs.error);
  const pairs = useSelector((state) => state.toolPair.pairs.payload);
  const self = pairs !== null && pairs.hasOwnProperty("self_relation") ? pairs.self_relation : null;
  const selfRelation = self !== null && self.hasOwnProperty("relation") ? self.relation : 0;
  const targetRelation = pairs !== null && pairs.hasOwnProperty("items") ? pairs.items : [];

  const handleFetchInfoPair = useCallback(() => dispatch(fetchInfoPair()), [dispatch]);

  useEffect(() => {
    handleFetchInfoPair();
  }, [handleFetchInfoPair]);

  const renderUser = () => {
    return (
      <PInfoTopPanel
        id={activeUserId}
        title={activeUserTitle}
        photo={activeUserPhoto}
        description={activeUserDescription}
        sex={activeUserSex}
        selfRelation={selfRelation}
        openView={props.openView}
      />
    );
  };

  const renderPair = () => {
    return targetRelation.length > 0 ? (
      targetRelation.map((pair, i) => {
        const id = pair.vk_id;
        const name = pair.name;
        const photo = pair.photo_url ? pair.photo_url : "";
        const relation = pair.relation;
        const sex = pair.sex;

        return (
          <PInfoItem
            key={i}
            id={id}
            title={name}
            photo={photo}
            relation={relation}
            sex={sex}
            selfTitle={activeUserTitle}
            openView={props.openView}
          />
        );
      })
    ) : (
      <Placeholder icon={<Icon56Users3Outline />}>Пары не найдены</Placeholder>
    );
  };

  return (
    <>
      <HeadTitle>Пара</HeadTitle>
      {pairs !== null && error === null ? <Group separator="hide">{renderUser()}</Group> : ""}
      {pairs !== null && error === null ? (
        <Group style={{ marginBottom: "20px" }}>{renderPair()}</Group>
      ) : (
        ""
      )}
      {renderLoader({ loading: loading })}
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchInfoPair,
      })}
    </>
  );
};

export default PInfoList;
