import React from "react";
import Icon16CheckCircle from "@vkontakte/icons/dist/16/check_circle";
import styles from "./CardPayItem.module.css";

const CardPayItem = (props) => {
  return (
    <>
      {props.visible && (
        <div
          onClick={() => props.handleClick(props.id)}
          className={styles.outContainer}
          style={{
            width: props.width,
            minWidth: props.size === "m" ? "154px" : "70px",
            background: props.selected
              ? "var(--bg-card-pay-item-selected)"
              : "var(--background_content)",
          }}
        >
          {props.selected ? (
            <span style={{ position: "absolute", right: "4px" }}>
              <Icon16CheckCircle fill="#4BB34B" />
            </span>
          ) : (
            ""
          )}

          <div className={styles.inContainer}>
            <img src={props.srcIcon} width={props.widthIcon} height={props.heightIcon} alt=""></img>
            <p className={styles.title}>{props.text}</p>
          </div>
        </div>
      )}
    </>
  );
};

CardPayItem.defaultProps = {
  width: "100%",
  size: "s",
  selected: false,
  visible: false,
};

export default CardPayItem;
