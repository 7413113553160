import React from "react";
import { Avatar, SimpleCell } from "@vkontakte/vkui";
import Icon16Chevron from "@vkontakte/icons/dist/16/chevron";
import { VIEW_TOOL } from "views/constViews";
import { PANEL_PS__GROUP_INFO } from "views/tool/panels/constPanels";

const PSUserItem = (props) => {
  return (
    <SimpleCell
      id={props.id}
      before={<Avatar size={48} />}
      description={<span>{props.description}</span>}
      after={<Icon16Chevron fill="#B8C1CC" />}
      onClick={() => props.openView({ view: VIEW_TOOL, panel: PANEL_PS__GROUP_INFO })}
    >
      {props.title}
    </SimpleCell>
  );
};

export default PSUserItem;
