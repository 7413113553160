import React from "react";
import { Group } from "@vkontakte/vkui";
import PSGroupInfoItem from "../PSGroupInfoItem/PSGroupInfoItem";
import PSGroupInfoTopPanel from "../PSGroupInfoTopPanel/PSGroupInfoTopPanel";
import HeadTitle from "template/components/HeadTitle/HeadTitle";

const PSGroupInfoList = (props) => {
  return (
    <div>
      <Group separator="show">
        <HeadTitle>Популярные подписчики</HeadTitle>
        <PSGroupInfoTopPanel
          title={"Figma Design"}
          description={"4 000 участников"}
          openView={props.openView}
        />
      </Group>
      <Group style={{ marginBottom: "20px" }}>
        <PSGroupInfoItem
          title={"Данил Даньшин"}
          description={"50 друзей | 2456 подписчиков"}
          openView={props.openView}
        />

        <PSGroupInfoItem
          title={"Данил Даньшин"}
          description={"50 друзей | 2456 подписчиков"}
          openView={props.openView}
        />

        <PSGroupInfoItem
          title={"Данил Даньшин"}
          description={"50 друзей | 2456 подписчиков"}
          openView={props.openView}
        />
      </Group>
    </div>
  );
};

export default PSGroupInfoList;
