import { queryEye3 } from "api";

async function APIEye3SetRenewLicense({ id, renew }) {
  try {
    const response = await queryEye3({
      method: "license.set_renew",
      get: { id: id, renew: renew },
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3SetRenewLicense;
