import { APIVkGetUsers } from "api/vk/apiMethods";

import { calcAge } from "helpers";

import { checkVKMethodError, delayMethodGetUsersVKApi, getSearchObject } from "api";

import { compareIf } from "helpers";
import { APIEye3GetSortFriends } from "api/eye3";

// Востановление позиции скролла
export async function setRestoreScroll({ scrollPositions, story, panel }) {
  const view = story; // views cовпадает со story
  const id = story + "/" + view + "/" + panel;

  for (let key in scrollPositions) {
    if (key === id) {
      window.scrollTo(0, scrollPositions[key]);
      break;
    }
  }
}

// Фильтр с tabs
export function getFilterTabsFriends({
  data,
  filter,
  tabsKey,
  tabsValue,
  template = [
    {
      first: "sex",
      compare: "===",
      two: "sex",
    },
    {
      first: "age",
      compare: ">=",
      two: "age_from",
    },
    {
      first: "age",
      compare: "<=",
      two: "age_to",
    },
    {
      first: "country_id",
      compare: "===",
      two: "country_id",
    },
    {
      first: "city_id",
      compare: "===",
      two: "city_id",
    },
  ],
}) {
  let currentFilter = filter;

  if (currentFilter !== null && typeof filter === "object")
    // Если переменная объект
    currentFilter[tabsKey] = tabsValue;
  else if (currentFilter === "") {
    // Если переменная пустая строка (преобразовать в объект)
    currentFilter = {};
    currentFilter[tabsKey] = tabsValue;
  }

  let result = [];

  //console.log('filter: ', currentFilter);

  result = data.filter((item) => {
    let is = [];

    template.forEach((t) => {
      is.push(
        currentFilter.hasOwnProperty(t.two) && currentFilter[t.two] !== ""
          ? compareIf(item[t.first], t.compare, currentFilter[t.two])
          : true
      );
    });

    if (!is.includes(false)) return item;

    return false;
  });

  return result;
}

// Фильтр без tabs
export function getFilterFriends({
  data,
  filter,
  template = [
    {
      first: "sex",
      compare: "===",
      two: "sex",
    },
    {
      first: "age",
      compare: ">=",
      two: "age_from",
    },
    {
      first: "age",
      compare: "<=",
      two: "age_to",
    },
    {
      first: "country_id",
      compare: "===",
      two: "country_id",
    },
    {
      first: "city_id",
      compare: "===",
      two: "city_id",
    },
  ],
}) {
  if (filter === "") return data;

  let result = [];

  //console.log('filter: ', filter);

  result = data.filter((item) => {
    let is = [];

    template.forEach((t) => {
      is.push(
        filter.hasOwnProperty(t.two) && filter[t.two] !== ""
          ? compareIf(parseInt(item[t.first]), t.compare, parseInt(filter[t.two]))
          : true
      );
    });

    if (!is.includes(false)) return item;

    return false;
  });

  return result;
}

// Поиск по URL
export function getSearchFriends({ data, search, isSearchVkId, field = "name" }) {
  return search !== "" && !isSearchVkId
    ? data.filter((item) => item[field].toLowerCase().indexOf(search.toLowerCase()) > -1)
    : data;
}

// Проверка на URL в поиске друзей
export function checkSearchURL({ search }) {
  const searchRegExp = /vk\.com\/id(\d+)|vk\.com\/([a-zA-Z0-9._]+)/;

  if (search !== "" && searchRegExp.test(search)) {
    let matchValues = search.match(searchRegExp);
    let searchValue = null;

    if (matchValues[1] !== null && matchValues[1] !== undefined) {
      searchValue = matchValues[1];
    } else if (matchValues[2] !== null && matchValues[2] !== undefined) {
      searchValue = matchValues[2];
    }

    return { isSearchVkId: true, searchValue };
  } else {
    return { isSearchVkId: false, searchValue: null };
  }
}

// Проверка на URL в поиске друзей и получение списка пользователей
export async function getSearchURLUsers({
  search,
  fields = ["photo_100", "bdate", "city", "country", "sex"],
}) {
  try {
    const isCheckSearchUrl = checkSearchURL({ search });

    if (isCheckSearchUrl.isSearchVkId) {
      const users = await APIVkGetUsers({
        userIds: isCheckSearchUrl.searchValue,
        fields: fields.join(","),
      });

      return { isSearchVkId: isCheckSearchUrl.isSearchVkId, users };
    } else {
      return { isSearchVkId: isCheckSearchUrl.isSearchVkId, users: null };
    }
  } catch (e) {
    throw new Error("getSearchURLUsers::: " + e);
  }
}

// Функция для получения популярных друзей без повторений с обычным списком друзей
export async function getFriendsComparePopular({ friends, fields, isSelf = false }) {
  try {
    const friendsPopular = await APIEye3GetSortFriends();
    let friendsPopularUsers = friendsPopular.users;

    // Получить данные пользователя в текущем списке
    if (isSelf) {
      friendsPopularUsers.push({ id: parseInt(getSearchObject().vk_user_id) });
    }

    //console.log('friendsPopularUsers: ', friendsPopularUsers);

    let friendsItems = friendsPopularUsers.length !== 0 ? friendsPopularUsers : [];
    let friendsPopularIds = friendsItems.map((friend) => friend.id);

    for (let friend of friends) {
      if (!friendsPopularIds.includes(friend.id)) {
        friendsItems.push(friend);
      }
    }

    let friendsIds = friendsItems.map((friend) => friend.id);

    const userInfo = await delayMethodGetUsersVKApi({
      userIds: friendsIds,
      fields: fields,
    });

    let result = [];

    for (let i of userInfo) {
      for (let j of friendsItems) {
        if (i.id === j.id) {
          const bdate = i.bdate ? i.bdate : j.bdate;

          result.push({
            ...i,
            bdate: bdate ? bdate : null,
          });

          break;
        }
      }
    }

    return {
      response: {
        items: result,
      },
    };
  } catch (e) {
    throw new Error("getFriendsComparePopular::: " + e);
  }
}

// Стандартная функция для получения списка друзей V2 (c фильтрацией и поиском)
export async function getSearchFilterFriendsV2({
  friends,
  search,
  filter,
  cache,
  fields = ["photo_100", "bdate", "city", "country", "sex", "relation", "last_seen", "online"],
  callbackResult = null,
  asyncCallbackResult = null,
}) {
  try {
    let usersItems = cache;
    let { users, isSearchVkId } = await getSearchURLUsers({
      search,
      fields,
    });

    let result = [];

    if (users !== null && checkVKMethodError(users)) {
      // Если в методе возникла ошибка
      return result;
    }

    if (cache === null && !isSearchVkId) {
      users = await getFriendsComparePopular({
        friends: friends,
        fields: fields,
      });
    }

    if (cache === null || isSearchVkId) {
      // Если кэш === null или идет поиск по id
      usersItems = users.response.hasOwnProperty("items") ? users.response.items : users.response;

      if (callbackResult !== null) {
        result = callbackResult(usersItems);
      } else if (asyncCallbackResult !== null) {
        result = await asyncCallbackResult(usersItems);
      } else {
        usersItems.forEach((user) => {
          if (!user.hasOwnProperty("deactivated")) {
            result.push({
              vk_id: user.id,
              name: user.first_name + " " + user.last_name,
              age: user.bdate ? calcAge(user.bdate) : null, // Расчет возраста по дате рождения
              sex: user.sex ? user.sex.toString() : null,
              city: user.city ? user.city.title : null, // Город
              city_id: user.city ? user.city.id.toString() : null, // Город id
              photo_url: user.photo_100 ? user.photo_100 : "", // Фото
              country: user.country ? user.country.title : null, // Страна
              country_id: user.country ? user.country.id.toString() : null, // Страна id
              relation: user.relation ? user.relation : null,
              last_seen: user.hasOwnProperty("last_seen") && user.last_seen ? user.last_seen : null,
              online: user.online,
            });
          }
        });
      }
    } else {
      // Вернуть прежний результат
      result = usersItems;
    }

    result = getFilterFriends({
      data: result,
      filter: filter,
    });

    result = getSearchFriends({
      data: result,
      search: search,
      isSearchVkId,
    });

    return result;
  } catch (e) {
    throw new Error("getSearchFilterFriendsV2::: " + e);
  }
}
