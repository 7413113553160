import {
  MARITAL_REQUEST,
  MARITAL_SUCCESS,
  MARITAL_ERROR,
  MARITAL_FILTER_REQUEST,
  MARITAL_FILTER_SUCCESS,
  MARITAL_FILTER_ERROR,
  MARITAL_SEARCH_REQUEST,
  MARITAL_SEARCH_SUCCESS,
  MARITAL_SEARCH_ERROR,
  MARITAL_SHOW_MORE,
  MARITAL__INFO_REQUEST,
  MARITAL__INFO_SUCCESS,
  MARITAL__INFO_ERROR,
  MARITAL_SET_RELATION_BACKGROUND,
  MARITAL__GEO_SETTINGS_REQUEST,
  MARITAL__GEO_SETTINGS_SUCCESS,
  MARITAL__GEO_SETTINGS_ERROR,
  MARITAL__GEO_SEARCH_REQUEST,
  MARITAL__GEO_SEARCH_SUCCESS,
  MARITAL__GEO_SEARCH_ERROR,
  MARITAL__GEO_SEARCH_SHOW_MORE,
} from "../../constType";

import { APIVkGetUsers } from "api/vk/apiMethods";
import {
  APIEye3GetRelationHistoryTool,
  APIEye3SearchRelationBackgroundTool,
  APIEye3SearchRelationSettingsTool,
  APIEye3SearchRelationTool,
} from "../../../api/eye3";
import { getSearchFilterFriendsV2 } from "../../../helpers/actions";
import { delayMethodGetUsersVKApi } from "../../../api";
import { openModal } from "../page";
import { checkEYE3ApiError, defaultErrorProperty } from "api/eye3/responseHandler";
import { calcAge } from "helpers";

export function fetchMaritalStatus() {
  return async (dispatch, getState) => {
    try {
      dispatch(request());

      const state = getState().toolMaritalStatus;

      const friends = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.friends.search,
        filter: state.friends.filter,
        cache: state.friends.cachePayload,
      });

      dispatch(success(friends));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: MARITAL_REQUEST };
  }

  function success(payload) {
    return { type: MARITAL_SUCCESS, payload };
  }

  function error(error) {
    return { type: MARITAL_ERROR, error };
  }
}

export function showMoreMaritalStatus() {
  return { type: MARITAL_SHOW_MORE };
}

export function filterMaritalStatus(filter = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(request(filter));

      const state = getState().toolMaritalStatus;

      const friends = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.friends.search,
        filter: state.friends.filter,
        cache: state.friends.cachePayload,
      });

      dispatch(success(friends));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(filter) {
    return { type: MARITAL_FILTER_REQUEST, filter };
  }

  function success(payload) {
    return { type: MARITAL_FILTER_SUCCESS, payload };
  }
  function error(error) {
    return { type: MARITAL_FILTER_ERROR, error };
  }
}

export function searchMaritalStatus(search = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(request(search));
      const state = getState().toolMaritalStatus;

      const friends = await getSearchFilterFriendsV2({
        friends: getState().friend.payload,
        search: state.friends.search,
        filter: state.friends.filter,
        cache: state.friends.cachePayload,
      });

      dispatch(success(friends));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(search) {
    return { type: MARITAL_SEARCH_REQUEST, search };
  }

  function success(payload) {
    return { type: MARITAL_SEARCH_SUCCESS, payload };
  }
  function error(error) {
    return { type: MARITAL_SEARCH_ERROR, error };
  }
}

export function fetchMaritalInfo() {
  return async (dispatch, getState) => {
    try {
      const pageParam = getState().page.param;
      const state = getState().toolMaritalStatus;

      const id = pageParam ? pageParam.activeUserId : state.marital.activeUserId;
      const title = pageParam ? pageParam.activeUserTitle : state.marital.activeUserTitle;
      const description = pageParam
        ? pageParam.activeUserDescription
        : state.marital.activeUserDescription;
      const photo = pageParam ? pageParam.activeUserPhoto : state.marital.activeUserPhoto;
      const sex = pageParam ? pageParam.activeUserSex : state.marital.activeUserSex;

      dispatch(request(id, title, description, photo, sex));

      let result = [];

      const maritalStatus = await APIEye3GetRelationHistoryTool({ userId: id });
      // console.log('maritalStatus: ', maritalStatus);

      // Проверка ошибок в API
      const errorAPI = checkEYE3ApiError(maritalStatus);
      if (errorAPI !== false) {
        errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
        dispatch(error({ ...errorAPI }));
        return;
      }

      const changes = maritalStatus.changes;
      const relationPartnersIds = changes // Проверка на список партнеров из СП
        .filter((change) => change.hasOwnProperty("relation_partner"))
        .map((change) => change.relation_partner)
        .join(",");

      //console.log('relationPartnersIds: ', relationPartnersIds);

      let userInfo = [];

      if (relationPartnersIds) {
        userInfo = await APIVkGetUsers({
          userIds: relationPartnersIds,
          fields: ["photo_100"].join(","),
        });

        userInfo = userInfo.response.hasOwnProperty("items")
          ? userInfo.response.items
          : userInfo.response;
      }

      changes.forEach((change) => {
        let data = {};

        if (change.hasOwnProperty("relation")) data.relation = change.relation;

        data._at = change._at;

        if (userInfo.length !== 0) {
          userInfo.forEach((user) => {
            let partner = {};

            if (user.id === change.relation_partner) {
              partner = {
                relation_partner: change.relation_partner,
                name: user.first_name + " " + user.last_name,
                photo_url: user.photo_100,
              };
            }

            result.push({
              ...data,
              ...partner,
            });
          });
        } else {
          result.push({
            ...data,
          });
        }
      });

      dispatch(success(result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request(id, title, description, photo, sex) {
    return { type: MARITAL__INFO_REQUEST, id, title, description, photo, sex };
  }

  function success(payload) {
    return { type: MARITAL__INFO_SUCCESS, payload };
  }

  function error(error) {
    return { type: MARITAL__INFO_ERROR, error };
  }
}

export function fetchGeoSettings() {
  return async (dispatch, getState) => {
    try {
      dispatch(request());

      const res = await APIEye3SearchRelationSettingsTool();
      //console.log('res: ', res);

      if (!res.status) {
        dispatch(error({ ...defaultErrorProperty, message: "" }));
        return;
      }

      const result = res.settings;

      dispatch(success(result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: MARITAL__GEO_SETTINGS_REQUEST };
  }

  function success(payload) {
    return { type: MARITAL__GEO_SETTINGS_SUCCESS, payload };
  }

  function error(error) {
    return { type: MARITAL__GEO_SETTINGS_ERROR, error };
  }
}

export function setRelationBackground() {
  return async (dispatch, getState) => {
    try {
      if (getState().toolMaritalStatus.geoSettings.payload === null) return;

      const enabled = getState().toolMaritalStatus.geoSettings.payload.is_enable ? 0 : 1; // Если настройка включена то выключить

      const res = await APIEye3SearchRelationBackgroundTool({ isEnable: enabled });
      // console.log('res: ', res);

      if (res.status) {
        dispatch(exec());
      }
    } catch (e) {
      console.error(e);
    }
  };

  function exec() {
    return { type: MARITAL_SET_RELATION_BACKGROUND };
  }
}

export function fetchGeoSearch(selectedFilters) {
  return async (dispatch, getState) => {
    try {
      dispatch(request());

      //console.log('selectedFilters: ', selectedFilters);

      const {
        sex,
        age_from,
        age_to,
        country_id,
        city_ids,
        relation_from,
        relation_on,
        period_day_count,
      } = selectedFilters;

      const ageFrom = age_from;
      const ageTo = age_to;
      const countryId = country_id;
      const cityIds = city_ids.filter((id) => id !== ""); // Чистка массива от пустых полей
      const relationFrom = relation_from.filter((id) => id !== ""); // Чистка массива от пустых полей
      const relationOn = relation_on.filter((id) => id !== ""); // Чистка массива от пустых полей
      const periodDayCount = period_day_count;

      const relationSearch = await APIEye3SearchRelationTool({
        sex,
        ageFrom,
        ageTo,
        countryId,
        cityIds,
        relationFrom,
        relationOn,
        periodDayCount,
      });

      // Проверка ошибок в API
      const errorAPI = checkEYE3ApiError(relationSearch);
      if (errorAPI !== false) {
        errorAPI.modal !== null && dispatch(openModal({ id: errorAPI.modal }));
        dispatch(error({ ...errorAPI }));
        return;
      }

      if (!relationSearch.status) {
        dispatch(error({ ...defaultErrorProperty, message: "" }));
        return;
      }

      // console.log('relationSearch: ', relationSearch);

      let result = [];
      const userInfo = await delayMethodGetUsersVKApi({
        userIds: relationSearch.users,
        fields: ["photo_100", "bdate", "city", "country"],
      });

      // console.log('userInfo: ', userInfo);

      for (let user of userInfo) {
        if (!user.hasOwnProperty("deactivated")) {
          result.push({
            vk_id: user.id,
            name: user.first_name + " " + user.last_name,
            age: user.bdate ? calcAge(user.bdate) : null, // Расчет возраста по дате рождения
            sex: user.sex ? user.sex.toString() : null,
            city: user.city ? user.city.title : null, // Город
            city_id: user.city ? user.city.id.toString() : null, // Город id
            photo_url: user.photo_100 ? user.photo_100 : "", // Фото
            country: user.country ? user.country.title : null, // Страна
            country_id: user.country ? user.country.id.toString() : null, // Страна id
          });
        }
      }

      dispatch(success(result));
    } catch (e) {
      dispatch(error({ ...defaultErrorProperty, message: e }));
    }
  };

  function request() {
    return { type: MARITAL__GEO_SEARCH_REQUEST };
  }

  function success(payload) {
    return { type: MARITAL__GEO_SEARCH_SUCCESS, payload };
  }

  function error(error) {
    return { type: MARITAL__GEO_SEARCH_ERROR, error };
  }
}

export function showMoreGeoSearch() {
  return { type: MARITAL__GEO_SEARCH_SHOW_MORE };
}
