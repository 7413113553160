import React from "react";

const GraphGeo = (props) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "16px",
      }}
    >
      <div style={{ width: "100%", color: "var(--text_subhead)" }}>{props.title}</div>
      <div style={{ width: "100%" }}>
        <div style={{ display: "flex", alignItems: "center", marginBottom: "4px" }}>
          <div
            style={{
              width: `${props.percent}px`,
              height: "4px",
              background: "#579BEB",
              borderRadius: "4px",
              marginRight: "4px",
            }}
          ></div>
          <span style={{ color: "var(--text_secondary)", fontSize: "13px" }}>{props.percent}%</span>
        </div>
      </div>
    </div>
  );
};

GraphGeo.defaultProps = {
  title: "",
  percent: 0,
};

export default GraphGeo;
