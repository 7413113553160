import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PanelHeaderWithPath from "template/components/PanelHeaderWithPath/PanelHeaderWithPath";

import { Panel } from "@vkontakte/vkui";

import ProfileControlList from "./components/ProfileControlList/ProfileControlList";
import ProfileActivity from "./components/ProfileActivity/ProfileActivity";

import { fetchProfile } from "store/actions/profile/profile";
import { renderErrorPlaceholder } from "helpers/views";
import { withRouter } from "hoc";

const PanelProfile = (props) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile.payload);
  const error = useSelector((state) => state.profile.error);
  const loading = useSelector((state) => state.profile.loading);

  const handleFetchProfile = useCallback(() => dispatch(fetchProfile()), [dispatch]);

  useEffect(() => {
    handleFetchProfile(); // Получение информации о профиле
  }, [handleFetchProfile]);

  return (
    <Panel id={props.id}>
      <PanelHeaderWithPath goBack={props.goBack}>Профиль</PanelHeaderWithPath>
      {renderErrorPlaceholder({
        error: error,
        loading: loading,
        isTryAgainHandler: handleFetchProfile,
      })}

      {error === null && (
        <ProfileControlList
          openView={props.openView}
          openModal={props.openModal}
          closeModal={props.closeModal}
          openPopout={props.openPopout}
          closePopout={props.closePopout}
        />
      )}
      {error === null && profile !== null ? (
        <ProfileActivity
          openView={props.openView}
          openPopout={props.openPopout}
          closePopout={props.closePopout}
        />
      ) : (
        ""
      )}
    </Panel>
  );
};

export default withRouter(PanelProfile);
