import {
  REGISTRATION_DATE_REQUEST,
  REGISTRATION_DATE_SUCCESS,
  REGISTRATION_DATE_ERROR,
  REGISTRATION_DATE_FRIEND_SHOW_MORE,
  REGISTRATION_DATE_FRIEND_SEARCH_REQUEST,
  REGISTRATION_DATE_FRIEND_SEARCH_SUCCESS,
  REGISTRATION_DATE_FRIEND_SEARCH_ERROR,
  REGISTRATION_DATE_FRIEND_FILTER_REQUEST,
  REGISTRATION_DATE_FRIEND_FILTER_SUCCESS,
  REGISTRATION_DATE_FRIEND_FILTER_ERROR,
} from "../../constType";

const initialState = {
  my: {
    payload: null,
    loading: false,
    error: null,
  },
  friends: {
    payload: null,
    showCount: 20,
    search: "",
    filter: "",
    loading: false,
    error: null,
  },
  isOnce: false,
};

export default function registrationDateReducer(state = initialState, action) {
  switch (action.type) {
    case REGISTRATION_DATE_REQUEST:
      return {
        ...state,
        isOnce: true,
        my: {
          ...state.my,
          payload: null,
          loading: true,
          error: null,
        },
        friends: {
          ...state.friends,
          loading: true,
          error: null,
          payload: null,
        },
      };
    case REGISTRATION_DATE_SUCCESS:
      return {
        ...state,
        my: {
          ...state.my,
          loading: false,
          payload: action.myPayload,
        },
        friends: {
          ...state.friends,
          loading: false,
          payload: action.friendsPayload,
        },
      };
    case REGISTRATION_DATE_ERROR:
      return {
        ...state,
        my: {
          ...state.my,
          loading: false,
          error: action.error,
        },
        friends: {
          ...state.friends,
          loading: false,
          error: action.error,
        },
      };
    case REGISTRATION_DATE_FRIEND_SHOW_MORE:
      return {
        ...state,
        friends: {
          ...state.friends,
          showCount: parseInt(state.friends.showCount) + 20,
        },
      };
    case REGISTRATION_DATE_FRIEND_SEARCH_REQUEST:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: true,
          error: null,
          payload: null,
          showCount: 20,
          search: action.search,
        },
      };
    case REGISTRATION_DATE_FRIEND_SEARCH_SUCCESS:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          payload: [...action.payload],
        },
      };
    case REGISTRATION_DATE_FRIEND_SEARCH_ERROR:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          error: action.error,
        },
      };
    case REGISTRATION_DATE_FRIEND_FILTER_REQUEST:
      return {
        ...state,
        friends: {
          ...state.friends,
          payload: null,
          showCount: 20,
          loading: true,
          error: null,
          filter: action.filter,
        },
      };
    case REGISTRATION_DATE_FRIEND_FILTER_SUCCESS:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          payload: [...action.payload],
        },
      };
    case REGISTRATION_DATE_FRIEND_FILTER_ERROR:
      return {
        ...state,
        friends: {
          ...state.friends,
          loading: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}
