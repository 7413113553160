import { queryEye3 } from "api";

async function APIEye3PopularGroupsTool() {
  try {
    const response = await queryEye3({
      method: "tool.popular_groups",
      access: { isBotConnect: true, isLicense: true, isLimit: true },
    });

    return response;
  } catch (e) {
    throw e;
  }
}

export default APIEye3PopularGroupsTool;
